import dayjs from "dayjs";
import { _TGenericComponent, arraySum, clamp, parseIntOr, TailwindBreakpoint, UiOption, useWindowSize, vOptT, vReqT } from "src/helpers/utils";
import { ilDropTarget, vueDirective_ilDraggable, vueDirective_ilDropTarget } from "src/modules/ilDraggable";
import { computed, defineComponent, onUnmounted, ref, watch } from "vue";
import { Datelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1";

import * as cal from "./CalendarLayout"
import { NoRender } from "src/helpers/NoRender";
import { vueDirective_ilOnClickOutside } from "src/helpers/OnClickOutside";
import { routeRootScrollPos } from "src/router/RouterScrollBehavior";

export const RootCalendarLayoutElement = defineComponent({
  props: {
    forceRenderKey: vReqT<string>(), // games.__vueKey
    px_totalTableWidth: vReqT<number>(), // px_totalTableWidth.value
    px_cellBorderAndGridlineThickness: vReqT<number>(),
    px_leftColWidth: vReqT<number>(),
    px_perFieldColWidth: vReqT<number>(), // px_perFieldColWidth.renderValue
    px_perHourCellHeight: vReqT<number>(),
    hours: vReqT<number[]>(),
    gridSlicesPerHour: vReqT<number>(),
    focusOnBracketGames: vReqT<boolean>(),
    byDateByField: vReqT<Map<Datelike, Map<FieldUID, cal.LayoutNodeRoot<_TGenericComponent>>>>(),
    dateFieldColDropTargetFactory: vReqT<null | ((_: {hour: number, hourRowIdx: number, date: Datelike, fieldUID: Guid}) => ilDropTarget | null)>(),
    tableRootDropTarget: vReqT<ilDropTarget | null>(),
  },
  emits: {
    drop_A: () => true,
  },
  directives: {
    ilDraggable: vueDirective_ilDraggable,
    ilDropTarget: vueDirective_ilDropTarget,
    ilOnClickOutside: vueDirective_ilOnClickOutside,
  },
  setup(props, ctx) {
    /**
     * Lookup table for e.g. 13->"1 pm", 14->"2 pm", ...
     */
    const _24hTo12h = (() => {
      const result : string[] = []
      const d = dayjs()
      for (let i = 0; i < 24; i++) {
        result.push(d.hour(i).format("h a"))
      }
      return result;
    })()

    // "scoped" css
    const randomTableClass = `il-f12a5794-0f5f-40f4-b1c3-d8ca4741ac8f`

    return () => <div>
        <style>
          {`
            .${randomTableClass} > .row > .cell {
              border-right: ${props.px_cellBorderAndGridlineThickness}px solid gray;
              border-bottom: ${props.px_cellBorderAndGridlineThickness}px solid gray;
              padding: 0;
            }
            .${randomTableClass} > .row > .cell:last-child {
              border-right: none;
            }

            .${randomTableClass} > .row:last-child > .cell {
              border-bottom: none;
            }
          `}
        </style>
        <div
          key={props.forceRenderKey}
          class={`rounded-md border shadow-md ${randomTableClass}`}
          style={{
            width: `${props.px_totalTableWidth}px`,
            boxSizing: "content-box",
            contain: "paint", // don't draw off-screen stuff, this container can be huge and (most) everything within it is absolutely positioned
          }}
          // as an affordance we allow drops on the table;
          // It might feel better to allow drops anywhere on the page, for the "infinite corner" treatment
          // It would be nice to respect mouse pos during a drag outside of the container, too.
          // And then the ultimate -- outside of the browser window?
          v-ilDropTarget={props.tableRootDropTarget}
        >
          {/*blurb -- callback of each entry ...*/}
          <div class="w-full flex row">
            <div style={`width:${props.px_leftColWidth}px; padding: 0 1em;`} class="rounded-tl-md flex justify-center items-center bg-white cell">Date</div>
            {
              [...props.byDateByField.entries()].map(([date, fields], i, a) => {
                const isLast = i === a.length - 1;
                const borderRadius = isLast ? "rounded-tr-md" : ""
                return (
                  <div style={`display:inline-block; width:${fields.size * props.px_perFieldColWidth}px;`} class={`cell bg-white text-center ${borderRadius}`}>
                    <div>{dayjs(date).format("dddd")}</div>
                    <div>{date}</div>
                  </div>
                )
              })
            }
          </div>

          {(ctx.slots as RootCalendarLayoutElementSlots).header1?.()}
          {(ctx.slots as RootCalendarLayoutElementSlots).header2?.()}

          {
            // Currently we _do_ render every row for which have an hour as its own <tr>,
            // But, really everything interesting gets rendered via absolute positioning from the first row.
            // So we could maybe do away with the <table> entirely, or thereabouts.
            props.hours.slice().map((hour, hourRowIdx) => {
              const isFirstRow = hourRowIdx === 0;
              const isLastRow = hourRowIdx === props.hours.length - 1;
              const hourCellBorderRadius = isLastRow ? "rounded-bl-md" : ""
              return (
                <div class="w-full flex row" style={`height:${props.px_perHourCellHeight + props.px_cellBorderAndGridlineThickness}px;`}>
                  <div style={`position:relative; width: ${props.px_leftColWidth}px;`} class={`flex bg-white pl-4 cell ${hourCellBorderRadius}`}>
                    {isFirstRow
                      ? <div style={`pointer-events:none; z-index: 1; position:absolute; top: 0; left; 0; width:100%; height: ${props.px_perHourCellHeight * props.hours.length}px`}>
                        <GridLines
                          hours={props.hours.length}
                          perHourCellHeight={props.px_perHourCellHeight}
                          gridSlicesPerHour={props.gridSlicesPerHour}
                          cellBorderAndGridlineThickness={props.px_cellBorderAndGridlineThickness}
                        />
                      </div>
                      : null
                    }
                    <div style="padding:.5em;">{_24hTo12h[hour]}</div>
                  </div>
                  {
                    [...props.byDateByField.entries()].flatMap(([date, nodesForFieldsByFieldUID]) => {
                      return [...nodesForFieldsByFieldUID.entries()].map(([fieldUID, layoutNode], i, a) => {
                        const dateFieldKey = `${date}/${fieldUID}`
                        return (
                          <div
                            class="cell"
                            style={`display:inline-block; width:${props.px_perFieldColWidth}px;`}
                            key={`${hour}/${date}`}
                          >
                            <div class="flex" style={`height: ${props.px_perHourCellHeight}px`}>
                              <div key={dateFieldKey} style={{
                                  position: `relative`,
                                  flexGrow: 1,
                                  // In the first row we don't muss with this;
                                  // for all other rows, we need the rows themselves to transparent to mouse events
                                  // so that the divs performing cell layout don't eat mouse clicks (the absolute positioned
                                  // divs from the first row are the ones containing important game info)
                                  pointerEvents: isFirstRow ? undefined : "none",
                                }}
                              >
                                {
                                  isFirstRow
                                    ? (
                                      // This is an invisible div that only serves to be a drop target for the whole column
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: 0,
                                          height: `${(props.px_perHourCellHeight * props.hours.length) + (props.px_cellBorderAndGridlineThickness * props.hours.length)}px`,
                                          width: "100%",
                                        }}
                                        v-ilDropTarget={props.dateFieldColDropTargetFactory?.({hour, hourRowIdx, date, fieldUID})}
                                      >
                                      </div>
                                    )
                                    : null
                                  }
                                {
                                  isFirstRow
                                    ? <GridLines
                                      hours={props.hours.length}
                                      perHourCellHeight={props.px_perHourCellHeight}
                                      gridSlicesPerHour={props.gridSlicesPerHour}
                                      cellBorderAndGridlineThickness={props.px_cellBorderAndGridlineThickness}
                                    />
                                    : null
                                }
                                {
                                  isFirstRow
                                    ? (ctx.slots as RootCalendarLayoutElementSlots).renderLayoutNodeRoot?.({date, fieldUID, layoutNodeRoot: layoutNode})
                                    : null
                                }
                              </div>
                            </div>
                          </div>
                        )
                      })
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
  }
})

/**
 * Draw all the gridlines for a given column.
 */
const GridLines = defineComponent({
  props: {
    hours: vReqT<number>(),
    perHourCellHeight: vReqT<number>(),
    gridSlicesPerHour: vReqT<number>(),
    cellBorderAndGridlineThickness: vReqT<number>(),
  },
  setup(props) {
    const tableHeight = computed(() => props.hours * props.perHourCellHeight)
    const totalGridCount = computed(() => props.gridSlicesPerHour * props.hours)
    const perGridHeight = computed(() => tableHeight.value / totalGridCount.value)
    return () => {
      return (
        <div class="relative">
          {
            (() => {
              const result : JSX.Element[] = []

              for (let i = 0; i < totalGridCount.value; i++) {
                if (i % props.gridSlicesPerHour === 0) {
                  // don't show "per hour subdivision" lines on top of "lines that separate hours"
                  continue;
                }

                // each row's border adds `cellBorderAndGridlineThickness` to overall offset from the top
                // (n.b. currently borders take up physical space, whereas the gridlines are absolutely positioned and take up zero space.
                const borderAdjust = Math.floor(i / props.gridSlicesPerHour) * props.cellBorderAndGridlineThickness

                // nudge 1px up so that it doesn't align directly with game boundaries (1px above games on this line)
                const topOffset = ((perGridHeight.value * i) + borderAdjust) - 1
                result.push(<div style={`position:absolute; top: ${topOffset}px; left:0; width: 100%; height:0;"`} class="border-b border-dashed border-gray-400"></div>);
              }

              return result;
            })()
          }
        </div>
      )
    }
  }
})

export interface RootCalendarLayoutElementSlots<T = _TGenericComponent> {
  renderLayoutNodeRoot?: (_: {date: Datelike, fieldUID: Guid, layoutNodeRoot: cal.LayoutNodeRoot<T>}) => JSX.Element | null
  // header1/2 are cop-outs, couldn't find a good abstraction when we started trying to make this a little more generic.
  // On the game scheduler calendar, it represents the the "all dates" (header1) and "all fields per date" (header2) header rows
  header1?: () => JSX.Element | null,
  header2?: () => JSX.Element | null,
}

type FieldUID = Guid

export interface SchedulerControlsSlots {
  default?: (_: {useSideBySideLayout: boolean}) => JSX.Element | null,
}

/**
 * This handles the scheduler calendar having an element above it that is sticky with respect to horizontal scroll.
 * We couldn't get it to work with "just" css sticky annotations, we needed a little more work to get it to stay put.
 */
export const SchedulerControlsElement = defineComponent({
  props: {
    px_controlsElemPadding: vOptT<number>(32),
    /**
     * caller's route root element (the topmost element of the currently displayed router component)
     */
    routeRootRef: vReqT<HTMLElement | null>(),

  },
  emits: {
    layoutChange: (_: {sideBySide: boolean}) => true
  },
  setup(props, ctx) {
    // Adjustment that seems necessary for some not-yet-understood reason ... in a small view, scrolling all the way to the right
    // will introduce a small region (roughly this slop size) where scrolling moves the sticky element.
    const px_slopNeededForSomeReasonOnAtLeastChromeInSub768PxView = 10

    const px_controlsElemLeft = ref(-1)
    const px_scrollBar = getClientScrollbarWidth()

    /**
     * We have to do a little kludge dance to layout the controls element (display options, actions), owing to the way scroll containers and sticky are interacting.
     * Probably our scroll container is a bit weird -- it doesn't seem it's the page, but maybe it can't be, if we want a side bar
     * that both takes up space does not appear horizontally scrollable.
     * What we have currently is a "very wide container (VWC)" and a child element that is position sticky, sticking to the left
     * of the VWC. We want the the sticky element to be like `min(100% of __remaining__ screen width with some padding adjustments, MAX_CONTROLS_CONTAINER_WIDTH)`.
     * But we seem to be lacking a pure CSS way to say "this element extends to the edge of screen from where it started, but not necessarly 100%, because it's
     * container may be wider than the viewport." So this asks for boundClientRect info every few render frames.
     */
    useAnimationFrame((() => {
      // sort of expensive, don't need to run this every frame.
      // And the left pos will typically not change often (sidebar dissapears, etc.)
      const runEveryNFrames = 8
      let iter = 0
      return () => {
        if (iter === 0) {
          if (props.routeRootRef) {
            const rootLeft = props.routeRootRef.getBoundingClientRect().left
            const scrollAdjust = routeRootScrollPos.value.left
            px_controlsElemLeft.value = Math.round(rootLeft + scrollAdjust)
          }
        }
        iter = (iter + 1) % runEveryNFrames;
      }
    })())

    const windowSize = useWindowSize()
    const useSideBySideOptionsLayout = computed(() => windowSize.width >= TailwindBreakpoint["2xl"]);
    watch(() => useSideBySideOptionsLayout.value, () => {
      if (useSideBySideOptionsLayout.value) {
        ctx.emit("layoutChange", {sideBySide: useSideBySideOptionsLayout.value})
      }
    })

    const slotArgs = computed(() => ({useSideBySideLayout: useSideBySideOptionsLayout.value}))

    return () => {
      return <div style={{
        position: "sticky",
        left: `${props.px_controlsElemPadding}px`,
        // Dynamic width, which we want to say like "min of either (horizontal start of element to horizontal end of viewport) or (auto)"
        // We assume a vertical scrollbar is always present taking up some horizontal space. Weird behavior if we drop that -
        // when horizontally scrolling, the sticky goes funky and stops applying, and then calculations start getting new values
        // for `px_controlsElemLeft`, and the whole thing starts sliding left on its own (_not_ desirable).
        width: `calc(100vw - ${px_controlsElemLeft.value}px - ${props.px_controlsElemPadding}px - ${px_scrollBar}px - ${px_slopNeededForSomeReasonOnAtLeastChromeInSub768PxView}px)`,
        display: `inline-grid`,
        gridTemplateColumns: useSideBySideOptionsLayout.value ? "400px minmax(auto, 1000px)" : "1fr",
        gridGap: "1em"
      }}>
        {(ctx.slots as SchedulerControlsSlots).default?.(slotArgs.value)}
      </div>
    }
  }
})

function useAnimationFrame(f: () => void) {
  let handle = -1;
  const runner = () => {
    f();
    handle = requestAnimationFrame(runner)
  }

  handle = requestAnimationFrame(runner)

  onUnmounted(() => cancelAnimationFrame(handle))
}

function getClientScrollbarWidth() {
  // Create a temporary element
  const div = document.createElement('div');

  // Add styles to ensure it has a scrollbar
  div.style.overflowY = 'scroll';
  div.style.width = '100px';
  div.style.height = '100px';

  // Hide it off-screen
  div.style.position = 'absolute';
  div.style.top = '-9999px';

  document.body.appendChild(div);

  // Measure the width difference
  const scrollbarWidth = div.offsetWidth - div.clientWidth;

  // Remove the temporary element
  document.body.removeChild(div);

  return scrollbarWidth;
}

export function CommonCalendarLayoutData() {
  const px_leftColWidth = 100
  const px_perFieldColWidthMinMax = {min: 300, max: 600} as const
  /**
   * "cell border and gridline thickness" are currently the same,
   * esp. since it looks "right" for them to track each other.
   * Note that currently, cell borders take up physical space (literal css borders on <td>s), whereas
   * gridlines take up zero physical space (absolutely positioned <div>s).
   */
  const px_cellBorderAndGridlineThickness = 1
  const px_perHourCellHeightMinMax = {min: 150, max: 300} as const

  const px_perHourCellHeight = (() => {
    const formValue = ref<Integerlike>(px_perHourCellHeightMinMax.min)
    const renderValue = computed(() => {
      return clamp(parseIntOr(formValue.value, px_perHourCellHeightMinMax.min), px_perHourCellHeightMinMax)
    })
    return {formValue, get renderValue() { return renderValue.value }}
  })()

  const px_perFieldColWidth = (() => {
    const formValue = ref<Integerlike>(px_perFieldColWidthMinMax.min)
    const renderValue = computed(() => {
      return clamp(parseIntOr(formValue.value, px_perFieldColWidthMinMax.min), px_perFieldColWidthMinMax)
    })
    return {formValue, get renderValue() { return renderValue.value }}
  })();

  const gridSlicesPerHour = ref<Integerlike>(60/15)
  const gridSlicesPerHourOptions : UiOption<Integerlike>[] = [
    // options to slice hours into "every N" minutes
    {label: "5 minutes", value: 60/5},
    {label: "10 minutes", value: 60/10},
    {label: "15 minutes", value: 60/15},
    {label: "30 minutes", value: 60/30},
  ]

  return {
    px_leftColWidth,
    px_perFieldColWidthMinMax,
    px_cellBorderAndGridlineThickness,
    px_perHourCellHeightMinMax,
    px_perHourCellHeight,
    px_perFieldColWidth,
    gridSlicesPerHour,
    gridSlicesPerHourOptions,
  } as const
}
