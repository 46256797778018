import { defineComponent, computed, watch, onBeforeMount, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { axiosAuthBackgroundInstance, freshAxiosInstance } from 'boot/axios'

// Investigate: Do we need the side-effects of this import, for mobile builds?
// If we do need it, does it need to live here - can it be in the App main component?
import '@capacitor/core'

import iziToast from 'izitoast'
import PortletElem from 'src/components/Portlets/Portlet.vue'

import * as ilvolunteer from "src/composables/InleagueApiV1.Volunteer"
import { defaultCoachPhotoUploadModalController } from '../UserInterface/Modal.photoUpload.coach'
import { AutoModal } from "src/components/UserInterface/Modal"

import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

import { Notifications } from "src/components/Navigational/Notifications"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faHome } from '@fortawesome/pro-solid-svg-icons'
import { Btn2, BusyBtn2, BusyBtn2Slots } from '../UserInterface/Btn2'
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'
import { Competition, Division, Guid, Season } from 'src/interfaces/InleagueApiV1'
import { makeTabDefMapping, TabDef, Tabs } from '../UserInterface/Tabs'
import { getMungedPortlets, PortletsByID } from './Portlets.io'

import { assertTruthy, exhaustiveCaseGuard, FkOwnedUiOptions, forceCheckedIndexedAccess, maybeParseJSON, noAvailableOptions, UiOption, useAbortController, vReqT } from 'src/helpers/utils'

import VolunteerCertifications from 'src/components/Portlets/VolunteerCertifications.vue'
import { UpcomingGamesPortletElement } from 'src/components/Portlets/UpcomingGames'
import UpcomingEvents from 'src/components/Portlets/UpcomingEvents.vue'
import RefereeAssignments from 'src/components/Portlets/RefereeAssignments.vue'
import VolunteerStatus from 'src/components/Portlets/VolunteerStatus'
import DivisionStatistics from 'src/components/Portlets/DivisionStatistics'
import TeamAssignments from 'src/components/Portlets/TeamAssignments.vue'
import PlayersWithUnmetBirthCertificateRequirement from "src/components/Portlets/PlayersWithUnmetBirthCertificateRequirement"
import { DashboardRegistrationReport, DashboardOpenOrSoonToBeOpen } from "src/components/Portlets/DashboardRegistrationReport"
import * as ilportlet from 'src/composables/InleagueApiV1.Portlet'
import authService from 'src/helpers/authService'
import { AxiosInstance } from 'axios'
import { SoccerBall } from '../SVGs'
import { RegistrationOverviewElem, RegistrationOverviewElemSlots } from './Dashboard.RegistrationOverview'
import { Type, Static } from '@sinclair/typebox'
import { Value } from '@sinclair/typebox/value'
import { queryGuid } from 'src/boot/TypeBoxSetup'
import { DashboardRegistrationOverview_MenuOptions, getRegistrationOverviewMenuOptions, DashboardRegistrationOverview, getRegistrationOverview } from './Dashboard.io'
import { CompSeasonDivWelcomeEmailController } from './CompSeasonDivWelcomeEmail'
import { UpcomingPracticesPortletElement } from '../Portlets/UpcomingPractices'

export default defineComponent({
  name: 'Dashboard',
  setup() {
    const router = useRouter()

    onBeforeMount(async () => {
      if (System.value.isMobile && !User.isLoggedIn) {
        await router.push({ name: 'mobile-landing' })
      }
    })

    const abortController = useAbortController()
    const silentLoggedInNoToastAx = () => freshAxiosInstance({useCurrentBearerToken: true, signal: abortController.signal, requestInterceptors: [], responseInterceptors: []})

    const portletResolver = PortletResolver()
    const dashboardRegistrationReportResolver = DashboardRegistrationReportResolver()
    const {
      coachPhotoUploadModalController,
      needsCoachPhotoInfoResolver,
      coachPhotoUploadButton
    } = CoachPhotoInfo()

    const registrationOverview = RegistrationOverviewController()

    /**
     * reload all the things when userID changes (i.e. login/logout, impersonation)
     */
    watch(() => User.value.userID, () => {
      coachPhotoUploadModalController.close()

      // TOOD: make this make sense to be called along with the other resolvers based on either "isLoggedIn" or "not isLoggedIn"
      if (authZ_dashboardRegistrationReport()) {
        dashboardRegistrationReportResolver.load(silentLoggedInNoToastAx());
      }
      else {
        dashboardRegistrationReportResolver.reset()
      }

      if (User.isLoggedIn) {
        registrationOverview.load(silentLoggedInNoToastAx())
        needsCoachPhotoInfoResolver.load(silentLoggedInNoToastAx(), {userID: User.value.userID})
        portletResolver.load(silentLoggedInNoToastAx())
      }
      else {
        registrationOverview.reset()
        needsCoachPhotoInfoResolver.reset()
        portletResolver.reset()
      }
    }, {
      immediate: true
    })

    const compSeasonDivWelcomeEmailController = CompSeasonDivWelcomeEmailController()

    const shouldShowManagedProgramsTab = computed<boolean>(() => {
      const portlets = portletResolver.value.status === "resolved"
        ? portletResolver.value.data
        : null

      return authZ_dashboardRegistrationReport()
        || !!portlets?.divisionStats
        || registrationOverview.canShow
    })

    const Loading = defineComponent(() => () => (
      <div class="p-2 flex items-center gap-2">
        <SoccerBall/>
        Loading...
      </div>
    ));

    const selectedTabIdx = ref(0)
    type DashboardTabDef = TabDef<"myInLeague" | "managedPrograms">
    const tabDefs = computed<DashboardTabDef[]>(() => {
      const result : DashboardTabDef[] = []

      if (/*always available*/ true) {
        result.push({
          id: "myInLeague",
          label: "My inLeague",
          "data-test": "myInLeague-button",
          render: () => {
            switch (portletResolver.value.status) {
              case "idle": return null
              case "pending": return <Loading/>
              case "error": return null // could show something...
              case "resolved": {
                const portlets = portletResolver.value.data

                return <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 px-1 pb-1">
                  {coachPhotoUploadButton.value.shouldShow
                    ? <CoachPhotoPortlet
                      userID={coachPhotoUploadButton.value.userID}
                      onOpenModal={args => {coachPhotoUploadModalController.open(args.userID)}}
                    />
                    : null}

                  {portlets.playersWithUnmetBirthCertificateRequirement
                    ? <PlayersWithUnmetBirthCertificateRequirement portlet={portlets.playersWithUnmetBirthCertificateRequirement}/>
                    : null}

                  {portlets.volStatus
                    ? <VolunteerStatus portlet={portlets.volStatus}/>
                    : null}

                  {portlets.certifications
                    ? <VolunteerCertifications showContent={true} certifications={portlets.certifications}/>
                    : null}

                  {portlets.upcomingGames
                    ? <UpcomingGamesPortletElement showContent={true} portlet={portlets.upcomingGames}/>
                    : null}

                  {portlets.upcomingGames
                    ? <UpcomingPracticesPortletElement portlet={portlets.upcomingGames}/>
                    : null}

                  {portlets.upcomingEvents
                    ? <UpcomingEvents showContent={true} upcomingEvents={portlets.upcomingEvents}/>
                    : null}

                  {portlets.refereeAssignments
                    ? <RefereeAssignments showContent={true} assignments={portlets.refereeAssignments}/>
                    : null}

                  {portlets.playerTeamAssignments
                    ? <TeamAssignments showContent={true} children={portlets.playerTeamAssignments} data-test="TeamAssignmentsPortlet"/>
                    : null}
                </div>
              }
              default: exhaustiveCaseGuard(portletResolver.value)
            }
          }
        })
      }

      if (shouldShowManagedProgramsTab.value) {
        result.push({
          id: "managedPrograms",
          label: "Managed Programs",
          "data-test": "managedPrograms-button",
          render: () => {
            const hasSomePending = dashboardRegistrationReportResolver.value.status === "pending"
              || portletResolver.value.status === "pending"

            const resolvedReport = dashboardRegistrationReportResolver.value.getOrNull()
            const resolvedPortlets = portletResolver.value.getOrNull()

            return <div class="grid grid-cols-1 gap-4 lg:grid-cols-2 px-1 pb-1">
              {registrationOverview.canShow
                ? <div class="p-2 bg-white rounded-md border" style="grid-column:-1/1; overflow-x:auto;">
                  <RegistrationOverviewElem
                    seasonUidOptions={registrationOverview.seasonUidOptions}
                    competitionUidOptions={registrationOverview.competitionUidOptions}
                    divIdOptions={registrationOverview.divIdOptions}

                    onUpdate:selectedSeasonUID={seasonUID => {
                      registrationOverview.selectedSeasonUID.value = seasonUID
                      registrationOverview.updateCompetitionOptions()
                      registrationOverview.updateDivisionOptions()
                      registrationOverview.tryPersistSelections()
                      registrationOverview.tryReloadReport(silentLoggedInNoToastAx())
                    }}
                    selectedSeason={registrationOverview.selectedSeason}

                    selectedCompetition={registrationOverview.selectedCompetition}
                    onUpdate:selectedCompetitionUID={competitionUID => {
                      registrationOverview.selectedCompetitionUID.value = competitionUID
                      registrationOverview.updateDivisionOptions()
                      registrationOverview.tryPersistSelections()
                      registrationOverview.tryReloadReport(silentLoggedInNoToastAx())
                    }}

                    selectedDiv={registrationOverview.selectedDiv}
                    onUpdate:selectedDivID={divID => {
                      registrationOverview.selectedDivID.value = divID
                      registrationOverview.tryPersistSelections()
                      registrationOverview.tryReloadReport(silentLoggedInNoToastAx())
                    }}

                    report={registrationOverview.reportResolver.value}
                  >
                    {{
                      aboveTable: () => <BusyBtn2
                        data-test="compSeasonDivWelcomeEmailModal-button"
                        class="px-2 py-1"
                        style="contain: strict; height: 2em; width: 16em;"
                        disabled={compSeasonDivWelcomeEmailController.modalController.isOpen || !registrationOverview.selectedSeason || !registrationOverview.selectedCompetition || !registrationOverview.selectedDiv}
                        onClick={() => {
                          if (!registrationOverview.selectedSeason || !registrationOverview.selectedCompetition || !registrationOverview.selectedDiv) {
                            return
                          }

                          compSeasonDivWelcomeEmailController.tryOpenCompSeasonDivWelcomeEmailModal({
                            competition: registrationOverview.selectedCompetition,
                            season: registrationOverview.selectedSeason,
                            division: registrationOverview.selectedDiv,
                          })
                        }}
                        busy={compSeasonDivWelcomeEmailController.formResolver.underlying.status === "pending"}
                      >
                        {{
                          busy: () => <div class={`flex items-center gap-2`}><SoccerBall size="1.5em" color="black"/>Loading...</div>,
                          notBusy: () => <div class={`flex items-center gap-2`}>Manage Division Welcome Email</div>,
                        } satisfies BusyBtn2Slots}
                      </BusyBtn2>
                    } satisfies RegistrationOverviewElemSlots}
                  </RegistrationOverviewElem>
                </div>
                : null
              }

              {hasSomePending
                ? <div style="grid-column:1/-1">
                  <Loading/>
                </div>
                : <>
                  {resolvedReport
                    ? <>
                      <DashboardOpenOrSoonToBeOpen style="grid-column:-1/1;" class="bg-white rounded-md border p-2" report={resolvedReport}/>
                      <DashboardRegistrationReport report={resolvedReport}/>
                    </>
                    : null
                  }

                  {resolvedPortlets
                    ? <>
                      {resolvedPortlets.divisionStats
                        ? <DivisionStatistics showContent={true} divisionStats={resolvedPortlets.divisionStats}/>
                        : null}
                    </>
                    : null
                  }
                </>
              }
            </div>
          }
        })
      }

      return result;
    })

    const tabDefMapping = computed(() => makeTabDefMapping(tabDefs.value))

    /**
     * we expect that this changes infrequently, in response to user login/impersonation triggering changes to what data is (and so what tabs are) available.
     * When what tabs are available changes, we set the selected tab to some particular default tab.
     */
    watch(() => shouldShowManagedProgramsTab.value, () => {
      if (shouldShowManagedProgramsTab.value) {
        selectedTabIdx.value = tabDefMapping.value.id2Idx.managedPrograms
      }
      else {
        selectedTabIdx.value = tabDefMapping.value.id2Idx.myInLeague
      }
    }, {immediate: true})

    return () => {
      return <div data-cy="dashboard">
        <AutoModal data-test="CoachPhotoUploadModalController" controller={coachPhotoUploadModalController}/>
        <AutoModal data-test="CompSeasonDivWelcomeEmailModal" controller={compSeasonDivWelcomeEmailController.modalController} class="max-w-4xl"/>
        <h1 class="text-4xl self-end font-medium">
          <FontAwesomeIcon class="mr-2" icon={faHome}/>
          Dashboard
        </h1>
        <div class="il-new-stacking-context">
          <Notifications/>
        </div>
        <Tabs
          class="mt-2"
          selectedIndex={selectedTabIdx.value}
          onChangeSelectedIndex={newIdx => selectedTabIdx.value = newIdx}
          tabDefs={tabDefs.value}
        />
      </div>
    }
  }
})

const CoachPhotoPortlet = defineComponent({
  props: {
    userID: vReqT<Guid>(),
  },
  emits: {
    openModal: (_: {userID: Guid}) => true,
  },
  setup(props, ctx) {
    return () => {
      return <PortletElem prefix="fas" icon="cog" label="Coach photo">
        <div class="p-2">
          <Btn2
            class="px-2 py-1"
            data-test="coachPhotoUploadButton"
            onClick={() => ctx.emit("openModal", {userID: props.userID})}
          >
            Upload photo
          </Btn2>
        </div>
      </PortletElem>
    }
  }
})

function CoachPhotoInfo() {
  const needsCoachPhotoInfoResolver = (() => {
    const resolver = ReactiveReifiedPromise<ilvolunteer.NeedsCoachPhotoInfo[]>()
    return {
      get value() { return resolver.underlying },
      load(ax: AxiosInstance, args: {userID: Guid}) {
        resolver.run(() => ilvolunteer.getWhoNeedsCoachPhotos(
          ax, {
          seasonUID: Client.value.instanceConfig.currentseasonuid,
          userIDs: [args.userID]
        }))
      },
      reset() {
        resolver.reset()
      }
    }
  })()

  const coachPhotoUploadButton = computed<{shouldShow: false} | {shouldShow: true, userID: Guid}>(() => {
    if (needsCoachPhotoInfoResolver.value.status !== "resolved") {
      return {shouldShow: false}
    }

    return needsCoachPhotoInfoResolver.value.data.find(v => v.userID === User.value.userID && !v.isPhotoLocked && v.hasAtLeastOneCoachAssignmentForCompRequiringPhoto)
      ? {shouldShow: true, userID: User.value.userID}
      : {shouldShow: false}
  })

  const coachPhotoUploadModalController = reactive(
    defaultCoachPhotoUploadModalController(
      axiosAuthBackgroundInstance,
      (userID) => {
        iziToast.success({message: "Photo uploaded"})
        if (needsCoachPhotoInfoResolver.value.status !== "resolved") {
          return
        }

        // on success, simulate receiving an update from the backend that this user's photo status is now locked
        const localUpdateable = needsCoachPhotoInfoResolver.value.data.find(_ => _.userID === userID);
        if (localUpdateable) {
          localUpdateable.isPhotoLocked = true;
        }
      }
    ));

  return {
    needsCoachPhotoInfoResolver,
    coachPhotoUploadButton,
    coachPhotoUploadModalController
  }
}

function DashboardRegistrationReportResolver() {
  const resolver = ReactiveReifiedPromise<ilportlet.DashboardRegistrationReport>()

  // typically this resolves instantly
  // but it can sit and wait until the backend initializes a cache,
  // and that cache might be being initialized by someone else, and it is slow to init
  const pollUntilResolvedOrUnmounted = async (ax: AxiosInstance) => {
    while (true) {
      const result = await ilportlet.getDashboardRegistrationReport(ax)
      if (result === "EBUSY") {
        await new Promise(resolve => setTimeout(resolve, 15_000))
      }
      else {
        return result;
      }
    }
  }

  return {
    get value() { return resolver.underlying },
    load: (ax: AxiosInstance) => resolver.run(() => pollUntilResolvedOrUnmounted(ax)),
    reset: () => resolver.reset()
  }
}

function PortletResolver() {
  const resolver = ReactiveReifiedPromise<PortletsByID>()
  return {
    get value() { return resolver.underlying },
    load: (ax: AxiosInstance) => resolver.run(() => getMungedPortlets(ax)),
    reset: () => resolver.reset()
  }
}


function authZ_dashboardRegistrationReport() {
  const isLoggedIn = User.isLoggedIn;
  const isRegistrar = authService(User.value.roles, "registrar")
  const managesSomeCompetition = typeof User.value.userData === "object" && User.value.userData.competitionsMemento.length > 0;
  return isLoggedIn && (isRegistrar || managesSomeCompetition)
}


export function RegistrationOverviewController() {
  const freshState = () => {
    const seasons = {
      options: noAvailableOptions("Loading..."),
      source: [] as Season[],
    } as const
    const competitions = {
      options: noAvailableOptions("Loading..."),
      source: [] as Competition[],
    } as const
    const divisions = {
      options: noAvailableOptions("Loading..."),
      source: [] as Division[]
    } as const

    return {
      data: null as DashboardRegistrationOverview_MenuOptions | null,
      seasons,
      competitions,
      divisions,
      canShow: false,
      selectedSeasonUID: "",
      selectedCompetitionUID: "",
      selectedDivID: "",
    }
  }

  const state = ref(freshState())

  const reset = () => {
    state.value = freshState()
  }

  const selectedSeason = computed<Season | null>(() => state.value.seasons.source.find(v => v.seasonUID === state.value.selectedSeasonUID) || null)
  const selectedCompetition = computed<Competition | null>(() => state.value.competitions.source.find(v => v.competitionUID === state.value.selectedCompetitionUID) || null)
  const selectedDiv = computed(() => state.value.divisions.source.find(v => v.divID === state.value.selectedDivID) || null)

  function updateCompetitionOptions() {
    if (!state.value.data) {
      return
    }

    const dependentCompUIDs = new Set(state.value.data.validTuples.filter(v => v.seasonUID === state.value.selectedSeasonUID).map(v => v.competitionUID))
    state.value.competitions = toOpts(state.value.data.competitions.filter(v => dependentCompUIDs.has(v.competitionUID)), comp => ({label: comp.competition, value: comp.competitionUID}))
    state.value.selectedCompetitionUID = state.value.competitions.options.options.find(v => v.value === state.value.selectedCompetitionUID)?.value // current value
      || forceCheckedIndexedAccess(state.value.competitions.options.options, 0)?.value // first in list
      || "" // shouldn't happen
  }

  function updateDivisionOptions() {
    if (!state.value.data) {
      return
    }

    const dependentDivIDs = new Set(state.value.data.validTuples.filter(v => v.seasonUID === state.value.selectedSeasonUID && v.competitionUID === state.value.selectedCompetitionUID).map(v => v.divID))
    state.value.divisions = toOpts(state.value.data.divisions.filter(v => dependentDivIDs.has(v.divID)), div => ({label: div.displayName || div.division, value: div.divID}))
    state.value.selectedDivID = state.value.divisions.options.options.find(v => v.value === state.value.selectedDivID)?.value // current value, if it exists
      || "" // no initial default selection, users must engage
  }

  const load = async (ax: AxiosInstance) : Promise<void> => {
    state.value.data = await getRegistrationOverviewMenuOptions(ax)

    if (!state.value.data.authZ || state.value.data.validTuples.length === 0) {
      state.value.canShow = false
      return
    }

    const persisted = selectedOptionsPersistence.load()

    state.value.canShow = true

    {
      const allRootSeasonUIDs = new Set(state.value.data.seasons.map(v => v.seasonUID))
      const firstSeasonInSeasonsHavingChildOptions = (() => {
        const validSeasonUIDs = new Set(state.value.data.validTuples.map(v => v.seasonUID))
        return state.value.data.seasons.find(v => validSeasonUIDs.has(v.seasonUID))
      })()

      state.value.seasons = toOpts(state.value.data.seasons.filter(v => allRootSeasonUIDs.has(v.seasonUID)), s => ({label: s.seasonName, value: s.seasonUID}))
      state.value.selectedSeasonUID = state.value.seasons.options.options.find(opt => opt.value === persisted?.seasonUID)?.value // last value we knew about for current user
        || state.value.seasons.options.options.find(opt => opt.value === Client.value.instanceConfig.currentseasonuid)?.value // application current season
        || firstSeasonInSeasonsHavingChildOptions?.seasonUID // first option in list having child options
        || "" // shouldn't happen
    }

    {
      updateCompetitionOptions()
      const lastPersistedValueForCurrentUser = state.value.competitions.options.options.find(opt => opt.value === persisted?.competitionUID)?.value
      if (lastPersistedValueForCurrentUser) {
        state.value.selectedCompetitionUID = lastPersistedValueForCurrentUser
      }
    }

    {
      updateDivisionOptions()
    }
  }

  function toOpts<T>(vs: T[], f: (v: T) => UiOption) {
    return {
      options: vs.length === 0 ? noAvailableOptions() : {disabled: false, options: vs.map(f)},
      source: vs
    }
  }

  const reportResolver = (() => {
    const resolver = ReactiveReifiedPromise<DashboardRegistrationOverview>(undefined, {defaultDebounce_ms: 250})
    return {
      get value() { return resolver.underlying },
      load: (ax: AxiosInstance, args: {competitionUID: Guid, divID: Guid, seasonUID: Guid}) => {
        resolver.run(() => getRegistrationOverview(ax, args))
      },
      reset: () => resolver.reset()
    }
  })()

  const selectedOptionsPersistence = (() => {
    function key() {
      return `dashboard/registrationReport/${User.value.userID}`
    }

    function schema() {
      return Type.Object({
        competitionUID: queryGuid(),
        seasonUID: queryGuid(),
        divID: queryGuid()
      })
    }

    type SchemaT = Static<ReturnType<typeof schema>>

    function store(args: SchemaT) : void {
      localStorage.setItem(key(), JSON.stringify(args));
    }

    function load() : SchemaT | null {
      const data = maybeParseJSON(localStorage.getItem(key()) || "")
      if (Value.Check(schema(), data)) {
        return data
      }
      else {
        return null
      }
    }

    return {
      store,
      load
    }
  })()

  const tryPersistSelections = () => {
    const seasonUID = state.value.selectedSeasonUID
    const competitionUID = state.value.selectedCompetitionUID
    const divID = state.value.selectedDivID
    if (!seasonUID || !competitionUID || !divID) {
      return
    }
    selectedOptionsPersistence.store({
      competitionUID,
      divID,
      seasonUID
    })
  }

  const tryReloadReport = (ax: AxiosInstance) => {
    const seasonUID = state.value.selectedSeasonUID
    const competitionUID = state.value.selectedCompetitionUID
    const divID = state.value.selectedDivID
    if (!seasonUID || !competitionUID || !divID) {
      reportResolver.reset()
    }
    else {
      reportResolver.load(ax, {competitionUID, divID, seasonUID})
    }
  }

  return {
    get seasonUidOptions() : FkOwnedUiOptions { return state.value.seasons.options },
    get competitionUidOptions() : FkOwnedUiOptions { return state.value.competitions.options },
    get divIdOptions() : FkOwnedUiOptions { return state.value.divisions.options },
    selectedSeasonUID: {
      get value() { return state.value.selectedSeasonUID },
      set value(fresh) { state.value.selectedSeasonUID = fresh }
    },
    get selectedSeason() { return selectedSeason.value },
    selectedCompetitionUID: {
      get value() { return state.value.selectedCompetitionUID },
      set value(fresh) { state.value.selectedCompetitionUID = fresh }
    },
    get selectedCompetition() { return selectedCompetition.value },
    selectedDivID: {
      get value() { return state.value.selectedDivID },
      set value(fresh) { state.value.selectedDivID = fresh }
    },
    get selectedDiv() { return selectedDiv.value },
    reportResolver,
    selectedOptionsPersistence,
    get canShow() { return state.value.canShow },
    load,
    reset,
    tryReloadReport,
    updateCompetitionOptions,
    updateDivisionOptions,
    tryPersistSelections
  }
}
