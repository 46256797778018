<template lang="pug">
.flex.flex-col.items-center
  h1.mt-4.text-center All Referee Assignments
  div(class='sm:mx-auto sm:w-full sm:max-w-md')
    FormKit.m-2(
      v-model='fkDummySink_selectedCompID',
      :options='competitionSelectOptions',
      type='select',
      label='Competition',
      placeholder='Select a Competition',
      data-cy='refCompetitions'
      @input="(value) => handleUpdateSelectedCompetitionID(value)"
    )
    FormKit.m-2(
      v-model='fkDummySink_selectedDivID',
      :options='divisionSelectOptions',
      type='select',
      placeholder='Select a Division',
      label='Division',
      data-cy='refDivisions'
      :disabled='!fkDummySink_selectedCompID',
      @input="(value) => handleUpdateSelectedDivID(value)"
    )
  .flex.justify-center.w-full
    .q-pa-md.quasar-style-wrap.mt-8(
      v-if='games.length',
      data-cy='fieldKeys',
    )
      q-table(
        :rows='keys',
        :columns='keyColumns',
        row-key='fieldID',
        @row-click='toMap',
        :rows-per-page-options='[0]',
        hide-pagination,
        dense
      )
        template(v-slot:body-cell-fieldID='props')
          q-td(:auto-width='true')
            div {{ props.row.fieldAbbrev }}
        template(v-slot:body-cell-fieldName='props')
          q-td.row.full-width(:props='props')
            .col-auto.q-pr-sm
              font-awesome-icon(:icon='["fas", "map-marked-alt"]')
            .col {{ props.row.fieldName }}

  .quasar-style-wrap.mt-8(
    v-if='games.length',
    data-cy='allRefAssignmentsTable',
  )
    .q-pa-md
      q-table(
        :rows='games',
        :columns='columns',
        row-key='gameID',
        :rows-per-page-options='[0]',
        hide-pagination,
        dense
      )
        template(v-slot:body-cell-gameStart='props')
          q-td(:props='props')
            .row
              .col-xs-12 {{ formatDateAsNums(props.value) }}
              .col-xs-12 {{ formatTime(props.value) }}
  .m-2(v-else-if='isDataLoaded', data-cy='noRefs')
    h4.text-center.italic.mt-2 Sorry, no referee assignments matched your query
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onMounted, computed } from 'vue'

import { formatDateAsNums, formatTime } from 'src/helpers/formatDate'
import { Guid, Integerlike, RefDetails, RefFieldDetails } from 'src/interfaces/InleagueApiV1'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import * as ilapi from "src/composables/InleagueApiV1"
import { User } from 'src/store/User'
import { forceCheckedIndexedAccess, sortBy, sortByDayJS, vOptT, vReqT } from 'src/helpers/utils'
import { CommonGameScheduleUrlParts, CompDivMenu, GameScheduleMenu } from './R_GameSchedules.shared'
import { QuasarColDef, quasarColumnSortAdapter } from 'src/helpers/Quasar'

export default defineComponent({
  name: 'allRefAssignments',
  props: {
    menu: vReqT<GameScheduleMenu>(),
    initialCompetitionID: vOptT<Integerlike>(),
    initialDivID: vOptT<Guid>(),
  },
  emits: {
    updateURL: (_: CommonGameScheduleUrlParts) => true,
  },
  setup(props, ctx) {
    const compDivMenu = CompDivMenu({get value() { return props.menu; }})
    const games = ref([]) as Ref<RefDetails[]>
    const keys = ref([]) as Ref<RefFieldDetails[]>
    const isDataLoaded = ref(false)

    const columns = ref<QuasarColDef<RefDetails>[]>([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (ref: RefDetails) => ref.game.field.fieldAbbrev,
        style: 'vertical-align: top; font-size: 16px',
        headerStyle: 'font-size: 16px',
        sortable: true,
        sort: quasarColumnSortAdapter(sortBy(v => v.game.field.fieldAbbrev)),
      },
      {
        name: 'gameStart',
        required: false,
        label: 'Date',
        align: 'left',
        field: (ref: RefDetails) => {
          return ref.game.gameStart
        },
        style: 'vertical-align: top; font-size: 16px;',
        headerStyle: 'font-size: 16px',
        sortable: true,
        sort: quasarColumnSortAdapter(sortByDayJS(v => v.game.gameStart)),
      },
      {
        name: 'Teams',
        align: 'left',
        label: 'Teams',
        field: (ref: RefDetails) => {
          return ref.game.teamVersus
        },
        style: 'vertical-align: top; font-size: 16px;',
        sortable: false,
      },
      {
        name: 'Referee',
        align: 'left',
        label: 'Referee',
        field: (ref: RefDetails) => ref.lastName,
        style: 'vertical-align: top; font-size: 16px;',
        sortable: true,
        sort: quasarColumnSortAdapter(sortByDayJS(v => v.lastName))
      },
    ])

    const keyColumns = ref<QuasarColDef<RefFieldDetails>[]>([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (ref: RefFieldDetails) => ref.fieldAbbrev,
        style: 'vertical-align: top; font-size: 16px;',
        classes: 'q-table',
        sortable: true,
        sort: quasarColumnSortAdapter(sortBy(v => v.fieldAbbrev))
      },
      {
        name: 'fieldName',
        required: false,
        label: 'Full Name',
        align: 'left',
        field: (ref: RefFieldDetails) => {
          return ref.fieldName
        },
        style: 'vertical-align: top; font-size: 16px;',
        classes: 'q-table',
        sortable: true,
        sort: quasarColumnSortAdapter(sortBy(v => v.fieldName))
      },
    ])

    const updateUrlFromCurrentState = () : void => {
      if (!compDivMenu.selectedCompetitionID) {
        // shouldn't happen
        return;
      }

      ctx.emit("updateURL", {
        path: {
          competitionID: compDivMenu.selectedCompetitionID,
          divID: compDivMenu.selectedDivID || undefined,
        },
        query: {}
      })
    }

    const userAddress = computed(() => {
      return User.value.userAddress
    })

    const createKeys = () => {
      const gameKeys: { [key: number]: RefFieldDetails } = {}
      for (let i = 0; i < games.value.length; i++) {
        const game = games.value[i].game
        gameKeys[game.fieldID] = game.field
      }
      keys.value = []
      for (const key in gameKeys) {
        keys.value.push(gameKeys[key])
      }
    }

    const toMap = (evt: Event, row: RefFieldDetails) => {
      const fieldAddress = `${row.fieldStreet} ${row.fieldCity} ${row.fieldState} ${row.fieldZip}`
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
          userAddress.value
        )}&destination=${encodeURIComponent(fieldAddress)}`,
        '_blank'
      )
    }

    const maybeLoadGamesFromCurrentSelection = async () : Promise<void> => {
      if (!compDivMenu.selectedCompetitionID || !compDivMenu.selectedDivID) {
        return
      }

      try {
        games.value = await ilapi.getRefereeAssignments(axiosInstance, {divID: compDivMenu.selectedDivID});
        isDataLoaded.value = true
        createKeys()
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    onMounted(async () => {
      if (props.initialCompetitionID && compDivMenu.competitionSelectOptions.value.find(v => v.value /*weakEq*/ == props.initialCompetitionID)) {
        compDivMenu.updateSelectedCompetitionID(props.initialCompetitionID.toString())
      }
      else {
        compDivMenu.updateSelectedCompetitionID(forceCheckedIndexedAccess(props.menu, 0)?.competition.competitionID.toString() ?? "")
      }

      if (props.initialDivID && compDivMenu.divisionSelectOptions.value.find(v => v.value === props.initialDivID)) {
        compDivMenu.updatedSelectedDivID(props.initialDivID)
      }
      else {
        compDivMenu.updatedSelectedDivID("ALL")
      }

      await maybeLoadGamesFromCurrentSelection()
    })

    return {
      formatDateAsNums,
      formatTime,
      games,
      keys,
      isDataLoaded,
      columns,
      keyColumns,
      createKeys,
      toMap,
      userAddress,
      handleUpdateSelectedCompetitionID: async (value: string) => {
        await compDivMenu.updateSelectedCompetitionID(value)
        await updateUrlFromCurrentState()
        await maybeLoadGamesFromCurrentSelection()
      },
      handleUpdateSelectedDivID: async (value: string) => {
        await compDivMenu.updatedSelectedDivID(value)
        await updateUrlFromCurrentState()
        await maybeLoadGamesFromCurrentSelection()
      },
      fkDummySink_selectedCompID: compDivMenu.fkDummySink_selectedCompID,
      competitionSelectOptions: compDivMenu.competitionSelectOptions,
      fkDummySink_selectedDivID: compDivMenu.fkDummySink_selectedDivID,
      divisionSelectOptions: compDivMenu.divisionSelectOptions,
    }
  },
})
</script>
