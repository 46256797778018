import { computed, ref } from "vue"
import dayjs, { Dayjs } from "dayjs"
import { axiosInstance } from "src/boot/AxiosInstances"
import { Field, getPlayingFields } from "src/composables/InleagueApiV1"
import { FieldBlockForGameSchedulerView, GameForGameSchedulerView, getGamesAndBlocksForGameSchedulerView } from "src/composables/InleagueApiV1.GameScheduler"
import { nextGlobalIntlike, accentAwareCaseInsensitiveCompare, requireNonNull, exhaustiveCaseGuard, arraySum, assertIs, nonThrowingExhaustiveCaseGuard, sortByMany, sortBy, weakEq } from "src/helpers/utils"
import { Datelike, Guid, CompetitionUID, DivID, Integerlike } from "src/interfaces/InleagueApiV1"
import * as cal from "./CalendarLayout"
import { GameCalendarUiElement, CompDivAuthZ, GameCalendarElement, DateFieldLayoutable, k_dayGroupKeyFormat } from "./GameScheduler.shared"
import { authZ_perAction } from "./R_GameSchedulerCalendar.route"
import { Client } from "src/store/Client"
import { AxiosInstance } from "axios"
import { DAYJS_FORMAT_IL_API_LOCALDATE } from "src/helpers/formatDate"

export type GameLayoutTreeStore = ReturnType<typeof GameLayoutTreeStore>

/**
 * Manages various aspects of initially loading games and fieldBlocks,
 * and treeifying the elements and manipulating the resulting tree.
 */
export function GameLayoutTreeStore() {
  /**
   * The main thing.
   *
   * A mapping of (date -> fieldUID -> LayoutNodeRoot)
   *
   * It is expected to have been generated in an appropriate insertion order,
   * so that iterating over it makes it easy to layout
   * "each date group (in ascending order) and for each date group each field (in some ascending order, probably alphabetical)"
   *
   * TODO: If leaves were Ref<cal.LayoutNodeRoot>, we could reassign them without triggering write-listeners on the owning maps?
   * This might result in reduced useless re-renders.
   */
  const byDateByField = ref(new Map<Datelike, Map<Guid, cal.LayoutNodeRoot<NodeT>>>())

  /**
   * For every fieldUID within `byDateByField` there should be a corresponding key/value pair here
   */
  const fieldsByFieldUID = ref(new Map<Guid, {fieldName: string, fieldAbbrev: string}>())

  /**
   * roughly "major version number", on full reloads should be bumped
   */
  const __vueKey = ref(nextGlobalIntlike())

  /**
   * authZ info for every unique comp/div that we know about
   * (that is, across all games contained in byDateByField)
   */
  const authZByCompDiv = ref(new Map<`${CompetitionUID}/${DivID}`, CompDivAuthZ>())

  const lastKnownLoadConfig = ref(LastKnownLoadConfig({
    selected_competitionUIDs: [],
    selected_divIDs: [],
    selected_fields: [],
    selected_startDateInclusive: "",
    selected_endDateInclusive: "",
    data_fields : [],
  }))

  /**
   * Sum of field counts of all dates we're looking at.
   * e.g. if date1 has fields (f1,f2) and date2 has fields (f2, f3, f4), that's 5 total fields to be rendered.
   * Note this is not "unique fields" but rather all fields that will be rendered (there can and will be dupes across dates).
   */
  const totalFieldRenderCount = computed(() => arraySum([
    ...byDateByField.value.values()
  ].map(fields => fields.size)))

  function LastKnownLoadConfig(z: {
    selected_competitionUIDs: Guid[],
    selected_divIDs: Guid[],
    selected_fields: {fieldUID: Guid, fieldAbbrev: string, fieldName: string, fieldID: Integerlike}[],
    selected_startDateInclusive: Datelike,
    selected_endDateInclusive: Datelike,
    data_fields : Field[],
  }) {
    return {
      selected_competitionUIDs: z.selected_competitionUIDs,
      selected_divIDs: z.selected_divIDs,
      selected_fields: z.selected_fields,
      selected_startDateInclusive: z.selected_startDateInclusive,
      selected_endDateInclusive: z.selected_endDateInclusive,
      get data_gamesAndBlocks() {
        // we need to consult the game tree because things may have been added and deleted,
        // and the tree is the source of truth, rather than some other list elsewhere
        return untreeifyEntireLayout();
      },
      data_fields: z.data_fields,
    }

    function untreeifyEntireLayout() {
      const games : GameForGameSchedulerView[] = []
      const fieldBlocks : FieldBlockForGameSchedulerView[] = []

      traverseAllGames(element => {
        if (element.data.type === "game") {
          games.push(element.data.data)
          return "continue"
        }
        else if (element.data.type === "fieldBlock") {
          fieldBlocks.push(element.data.data)
          return "continue"
        }
        else {
          exhaustiveCaseGuard(element.data)
        }
      })

      return {
        games,
        fieldBlocks
      }
    }
  }

  // TODO: caller should do the load, then we just init from what they provide; then we're not coupled to HTTP endpoints
  async function naiveFullReload(uiSelections: {
    competitionUIDs: Guid[],
    divIDs: Guid[],
    fields: {fieldUID: Guid, fieldAbbrev: string, fieldName: string, fieldID: Integerlike}[],
    startDateInclusive: Datelike,
    endDateInclusive: Datelike,
    onlyShowSelectedDatesAndFieldsHavingGames: boolean,
  }) : Promise<void> {
    const {competitionUIDs, divIDs, fields, startDateInclusive, endDateInclusive} = uiSelections;

    let _fields : Field[] = []
    let _gamesAndBlocks = {
      games: [] as GameForGameSchedulerView[],
      fieldBlocks: [] as FieldBlockForGameSchedulerView[]
    }

    if (competitionUIDs.length === 0 || divIDs.length === 0 || fields.length === 0) {
      // nothing to load
    }
    else {
      _gamesAndBlocks = await getGamesAndBlocksForGameSchedulerView(axiosInstance, {
        competitionUIDs: "*",
        divIDs: "*",
        fieldUIDs: fields.map(v => v.fieldUID),
        dateFromInclusive: startDateInclusive,
        dateToInclusive: endDateInclusive,
      });

      _fields = await getPlayingFields(axiosInstance)
    }

    await naiveFullReloadWorker(uiSelections, {fields: _fields, gamesAndBlocks: _gamesAndBlocks})

    lastKnownLoadConfig.value = LastKnownLoadConfig({
      selected_competitionUIDs: competitionUIDs,
      selected_divIDs: divIDs,
      selected_fields: fields,
      selected_startDateInclusive: startDateInclusive,
      selected_endDateInclusive: endDateInclusive,
      data_fields: _fields,
    })
  }

  async function reloadFieldByDate(ax: AxiosInstance, args: {date: Datelike | Dayjs, fieldUID: Guid}) {
    const byDate = byDateByField.value.get(dayjs(args.date).format(k_dayGroupKeyFormat));
    if (!byDate) {
      return
    }
    const field = byDate.get(args.fieldUID)
    if (!field) {
      return
    }

    const data = await getGamesAndBlocksForGameSchedulerView(ax, {
      competitionUIDs: "*",
      divIDs: "*",
      fieldUIDs: [args.fieldUID],
      dateFromInclusive: dayjs(args.date).format(DAYJS_FORMAT_IL_API_LOCALDATE),
      dateToInclusive: dayjs(args.date).format(DAYJS_FORMAT_IL_API_LOCALDATE)
    })

    const dummyRoot : cal.LayoutNodeRoot<any> = {parent: null, children: []}
    const flatLayoutNodes = flattenAndWrapIntoLayoutNodes(dummyRoot, data)
    const tree = cal.treeify(flatLayoutNodes)
    byDate.set(args.fieldUID, tree);
  }

  function flattenAndWrapIntoLayoutNodes(root: cal.LayoutNodeRoot<any>, args: {games: GameForGameSchedulerView[], fieldBlocks: FieldBlockForGameSchedulerView[]}) : cal.LayoutNode<NodeT>[] {
    return [
      ...args.games.map(v => ({
        parent: root,
        children: [],
        start: dayjs(v.gameStart).unix(),
        end: dayjs(v.gameEnd).unix(),
        precedence: k_gameCalendarPrecedence,
        data: GameCalendarUiElement("game", v)
      })),
      ...args.fieldBlocks.map(v => ({
        parent: root,
        children: [],
        start: dayjs(v.slotStart).unix(),
        end: dayjs(v.slotEnd).unix(),
        precedence: k_fieldBlockCalendarPrecedence,
        data: GameCalendarUiElement("fieldBlock", v)
      }))
    ]
  }

  function naiveFullReloadWorker(uiSelections: {
    competitionUIDs: Guid[],
    divIDs: Guid[],
    fields: MinField[],
    startDateInclusive: Datelike,
    endDateInclusive: Datelike,
    onlyShowSelectedDatesAndFieldsHavingGames: boolean,
  }, data: {
    fields: Field[],
    gamesAndBlocks: {games: GameForGameSchedulerView[], fieldBlocks: FieldBlockForGameSchedulerView[]},
  }) : void {
    const {competitionUIDs, divIDs, fields, startDateInclusive, endDateInclusive} = uiSelections;

    const dummyRoot : cal.LayoutNodeRoot<any> = {parent: null, children: []}

    const flatLayoutNodes = flattenAndWrapIntoLayoutNodes(dummyRoot, data.gamesAndBlocks)

    const freshAuthZByCompDiv : Map<`${CompetitionUID}/${DivID}`, CompDivAuthZ> = (() => {
      const result : [`${CompetitionUID}/${DivID}`, CompDivAuthZ][] = []
      for (const competitionUID of competitionUIDs) {
        for (const divID of divIDs) {
          const k = `${competitionUID}/${divID}` as const
          const compdiv = {competitionUID, divID}
          result.push([k, {
            canCrudGames: authZ_perAction.canCrudGames(compdiv),
            canEditGameTimes: authZ_perAction.canEditGameTimes(compdiv),
            canEditGameFields: authZ_perAction.canEditGameFields(compdiv),
            canEditGameTeams: authZ_perAction.canEditGameTeams(compdiv),
          }])
        }
      }
      return new Map(result)
    })()


    // Treeify the flat list of LayoutNode[] into a tree of (date -> field -> LayoutNode[])
    const freshGamesMapping = (() => {
      const allDateKeysSorted = SequentialDates(startDateInclusive, endDateInclusive).map(v => v.format(k_dayGroupKeyFormat))

      const allFieldsSorted = [...fields]
        .sort(sortByMany(
          sortBy(v => v.fieldID /*weakEq*/ == Client.value.instanceConfig.byefield ? 1 : 0, "asc"),
          (l,r) => accentAwareCaseInsensitiveCompare(l.fieldName,r.fieldName)
        ))

      return NodeSourceTree(
        allDateKeysSorted,
        allFieldsSorted,
        flatLayoutNodes,
        uiSelections.onlyShowSelectedDatesAndFieldsHavingGames
          ? (() => {
            const comps = new Set<Guid>(competitionUIDs)
            const divs = new Set<Guid>(divIDs);
            return (_date, _field, elems) => {
              const hasSomeInterestingGame = elems.some(v => v.data.type === "game" && comps.has(v.data.data.competitionUID) && divs.has(v.data.data.divID))
              return hasSomeInterestingGame ? "retain" : "trim"
            }
          })()
          : null
      )
    })()

    __vueKey.value = nextGlobalIntlike()
    byDateByField.value = freshGamesMapping
    fieldsByFieldUID.value = new Map(data.fields.map(v => [v.fieldUID, v])),
    authZByCompDiv.value = freshAuthZByCompDiv
  }

  /**
   * Rebuild the whole tree with some modified options.
   * This assumes selected dates/comps/divs/fields have not changed.
   */
  function localRebuild(args: {
    onlyShowSelectedDatesAndFieldsHavingGames: boolean
  }
  ) : void {
    naiveFullReloadWorker({
      competitionUIDs: lastKnownLoadConfig.value.selected_competitionUIDs,
      divIDs: lastKnownLoadConfig.value.selected_divIDs,
      startDateInclusive: lastKnownLoadConfig.value.selected_startDateInclusive,
      endDateInclusive: lastKnownLoadConfig.value.selected_endDateInclusive,
      fields: lastKnownLoadConfig.value.selected_fields,
      onlyShowSelectedDatesAndFieldsHavingGames: args.onlyShowSelectedDatesAndFieldsHavingGames,
    }, {
      gamesAndBlocks: lastKnownLoadConfig.value.data_gamesAndBlocks,
      fields: lastKnownLoadConfig.value.data_fields,
    })
  }

  type NodeT = GameCalendarUiElement

  function traverseAllGames(f: CalNodeVisitor<NodeT>) : void {
    return forAllCalendarNodes(byDateByField.value, f)
  }

  function traverseGameLayout(
    node: cal.LayoutNodeRoot<NodeT> | cal.LayoutNode<NodeT>,
    f: CalNodeVisitor<NodeT>
  ) : "continue" | "bail" {
    return forEachCalendarNode(node, f)
  }

  /**
   * Insert a new element into the tree as per `newElement`'s date and field.
   * If (date, field) does not currently exist, this is a no-op - which is intended to handle cases where something is moved
   * months into the future where we do not have that date loaded.
   * If an element is inserted, resorts the affected tree.
   */
  function maybeInsertAndResort(newElement: GameCalendarUiElement) : void {
    const startDateKey = dayjs(newElement.type === "game" ? newElement.data.gameStart : newElement.data.slotStart).format(k_dayGroupKeyFormat)
    const fieldUIDKey = newElement.data.fieldUID
    const fieldsForDate = byDateByField.value.get(startDateKey)

    if (!fieldsForDate) {
      // we're not {displaying, aware of} this date, so bail
      return;
    }

    const gamesForField = (() => {
      const gamesForField  = fieldsForDate?.get(fieldUIDKey)
      if (!gamesForField) {
        // we're displaying this date, but didn't know about this field.
        // That's OK, we can insert a root node for the field
        // TODO: this needs to resort the tree we insert into, if we want to preserve the intended display order of fields
        // (e.g. we want to resort so fields display in alphabetical ascending, or whatever)
        const freshRoot = cal.treeify<NodeT>([])
        fieldsForDate.set(fieldUIDKey, freshRoot)
        return freshRoot
      }
      else {
        return gamesForField
      }
    })();

    const fresh : cal.LayoutNode<NodeT> = {
      parent: gamesForField,
      children: [],
      start: newElement.uiState.time.start.unix(),
      end: newElement.uiState.time.end.unix(),
      precedence: newElement.type === "fieldBlock" ? k_fieldBlockCalendarPrecedence : k_gameCalendarPrecedence,
      data: newElement,
    }

    fieldsForDate.set(fieldUIDKey, cal.treeify([fresh, ...cal.untreeify(gamesForField)]))
  }

  function insertOrReplaceMany(elems: GameCalendarUiElement[]) : void {
    deleteFromTreeRetainingChildren(elems.map(findLayoutNode).filter(v => v !== null))
    // It would be nice to do all the inserts, then do all the resorts as necessary;
    // we potentially do too much resorting here (i.e. insert 2+ nodes into the same
    // (date,field) root, it will be sorted on each insert)
    elems.forEach(maybeInsertAndResort)
  }

  /**
   * It might be nice if this resorted, too. It would be convenient, but it doesn't need to be done if
   * we're deleting and then inserting and then restorting (where delete-sort-insert-sort is a wasted
   * sort right after delete). Probably it's not a perf problem and we could just always sort.
   */
  function deleteFromTreeRetainingChildren(nodes: cal.LayoutNode<NodeT> | cal.LayoutNode<NodeT>[]) : void {
    if (Array.isArray(nodes)) {
      for (const node of nodes) {
        cal.deleteFromTreeRetainingChildren(node)
      }
    }
    else {
      const node = nodes
      cal.deleteFromTreeRetainingChildren(node)
    }
  }

  /**
   * Resort the tree for (date, field)
   * The tree for (date, field) must exist, otherwise we throw.
   * Resulting layoutnodes are regenerated, so no existing nodes will point into the resulting tree.
   */
  function resort(date: Dayjs | Datelike, fieldUID: Guid) : void {
    const fieldsForDate = byDateByField.value.get(dayjs(date).format(k_dayGroupKeyFormat))
    const gamesForField = fieldsForDate?.get(fieldUID)

    if (!fieldsForDate || !gamesForField) {
      // doesn't exist, can't sort it
      return
    }
    else {
      fieldsForDate.set(fieldUID, cal.treeify(cal.untreeify(gamesForField)))
    }
  }

  /**
   * Resorts all trees owned by the the given {node, list of nodes}.
   * If 2+ nodes share a root, only resorts the shared root once.
   */
  function resortAllUniqueOwnersOf(nodes: cal.LayoutNode<NodeT> | cal.LayoutNode<NodeT>[]) : void {
    const seen = new Set<`${Datelike}/${Guid}`>()
    const keys : {date: Datelike, fieldUID: Guid}[] = []

    const nodeList = Array.isArray(nodes) ? nodes : [nodes]

    for (const node of nodeList) {
      const {startDateKey, fieldUIDKey} = dateFieldKey(node.data)
      const k = `${startDateKey}/${fieldUIDKey}` as const
      if (seen.has(k)) {
        continue;
      }
      seen.add(k)
      keys.push({date: startDateKey, fieldUID: fieldUIDKey})
    }

    for (const key of keys) {
      resort(key.date, key.fieldUID)
    }
  }

  function dateFieldKey(v: GameCalendarUiElement) {
    const startDateKey : Datelike = dayjs(v.type === "game" ? v.data.gameStart : v.data.slotStart).format(k_dayGroupKeyFormat)
    const fieldUIDKey = v.data.fieldUID
    return {startDateKey, fieldUIDKey}
  }

  function sharesSameDateField(a: GameCalendarUiElement, b: GameCalendarUiElement) : boolean {
    const {startDateKey: a1, fieldUIDKey: a2} = dateFieldKey(a)
    const {startDateKey: b1, fieldUIDKey: b2} = dateFieldKey(b)
    return a1 === b1 && a2 === b2
  }

  /**
   * find a layoutNode within the tree using structural identity comparison
   * (that is, by object's key value, rather than by object identity)
   */
  function findLayoutNode(args: GameCalendarElement) : cal.LayoutNode<NodeT> | null {
    const startDateKey = dayjs(startDate(args)).format(k_dayGroupKeyFormat)
    const fieldUIDKey = args.data.fieldUID
    const root = byDateByField.value.get(startDateKey)?.get(fieldUIDKey)
    if (!root) {
      return null
    }
    let node : cal.LayoutNode<NodeT> | null = null
    traverseGameLayout(root, element => {
      switch (element.data.type) {
        case "game": {
          if (args.type === "game" && element.data.data.gameID === args.data.gameID) {
            node = element;
            return "bail"
          }
          else {
            return "continue"
          }
        }
        case "fieldBlock": {
          if (args.type === "fieldBlock" && element.data.data.id === args.data.id) {
            node = element;
            return "bail"
          }
          else {
            return "continue"
          }
        }
        default: exhaustiveCaseGuard(element.data)
      }
    })
    return node;
  }

  return {
    get __vueKey() { return __vueKey.value },
    get authZByCompDiv() { return authZByCompDiv.value },
    get byDateByField() { return byDateByField.value },
    // TODO: make this getFieldOrFail -- need to prove that for all fieldUIDs in the tree we have a mapping here
    // sometimes, in "oops we loaded nothing" and myabe some other error cases, we can get a miss here.
    getField: (fieldUID: Guid) => fieldsByFieldUID.value.get(fieldUID),
    deleteFromTreeRetainingChildren,
    findLayoutNode,
    insertOrReplaceMany,
    localRebuild,
    maybeInsertAndResort,
    naiveFullReload,
    resort,
    resortAllUniqueOwnersOf,
    sharesSameDateField,
    get totalFieldRenderCount() { return totalFieldRenderCount.value },
    traverseAllGames,
    forEachGame: (args: {date: Datelike | Dayjs, fieldUID: Guid}, f: CalNodeVisitor<NodeT>) : void => {
      const root = byDateByField.value.get(dayjs(args.date).format(k_dayGroupKeyFormat))?.get(args.fieldUID)
      if (!root) {
        return;
      }
      traverseGameLayout(root, f)
    },
    isEmpty: () => {
      let isEmpty = true
      traverseAllGames(() => {
        isEmpty = false
        return "bail"
      })
      return isEmpty
    },
    reloadFieldByDate,
  }
}

/**
 * highest precedence means "blocks own games"
 */
const k_fieldBlockCalendarPrecedence = 0
const k_gameCalendarPrecedence = 1

function startDate(v: GameCalendarElement) : string {
  switch (v.type) {
    case "game": {
      return v.data.gameStart
    }
    case "fieldBlock": {
      return v.data.slotStart
    }
    default: exhaustiveCaseGuard(v)
  }
}

interface MinField {
  fieldUID: Guid,
  fieldAbbrev: string,
  fieldName: string,
  fieldID: Integerlike
}

export function SequentialDates(startDateInclusive: Datelike, endDateInclusive: Datelike) : Dayjs[] {
  let working = dayjs(startDateInclusive)
  const end = dayjs(endDateInclusive)
  const result : Dayjs[] = []
  while (working.isSameOrBefore(end, "day")) {
    result.push(working)
    working = working.add(1, "day")
  }
  return result;
}

/**
 * @param trim used to drop "empty" (date,field) pairs, after treeification, for a caller defined notion of "empty"
 */
export function NodeSourceTree<T extends DateFieldLayoutable>(
  allDateKeysSorted: Datelike[],
  sortedFields: MinField[],
  flatLayoutNodes: cal.LayoutNode<T>[],
  trim: null | ((date: Datelike, fieldUID: Guid, nodes: cal.LayoutNode<T>[]) => "trim" | "retain")
) {
  const allFieldKeysSorted = sortedFields.map(_ => _.fieldUID)

  const result = new Map<Datelike, Map<Guid, cal.LayoutNode<T>[]>>()

  for (const date of allDateKeysSorted) {
    const nodeSourcesByField = new Map(allFieldKeysSorted.map(fieldUID => [fieldUID, [] as cal.LayoutNode<T>[]]))
    result.set(date, nodeSourcesByField)
  }

  for (const node of flatLayoutNodes) {
    const unixSecondsToUnixMilliseconds = node.start * 1000

    // safe-nav here ... we can get things that span multiple days ... and then the element overlaps our time range,
    // but does not start on a date in our time range ... which, does such a thing only happen in tests? Such tests should be fixed.
    // So, these should generally be non-null but we can't quite assert on it; if we don't find we're looking for, just skip it.
    const fieldsByDate = result.get(dayjs(unixSecondsToUnixMilliseconds).format(k_dayGroupKeyFormat))
    const nodeSourcesByField = fieldsByDate?.get(node.data.calKeys.fieldUID)
    nodeSourcesByField?.push(node)
  }

  if (trim) {
    for (const [date, fields] of result.entries()) {
      for (const [fieldUID, nodes] of fields.entries()) {
        if (trim(date, fieldUID, nodes) === "trim") {
          fields.delete(fieldUID)
        }
      }
    }

    for (const [date, fields] of result.entries()) {
      if (fields.size === 0) {
        result.delete(date)
      }
    }
  }

  return treeBuilderToTree(result)

  /**
   * Convert a (date -> field -> LayoutNode[]) tree into a (date -> field -> LayoutNodeRoot)
   */
  function treeBuilderToTree<T>(treeBuilder: Map<Datelike, Map<Guid, cal.LayoutNode<T>[]>>) {
    const freshGamesMapping = new Map<Datelike, Map<Guid, cal.LayoutNodeRoot<T>>>();
    for (const [dateKey, nodeSourcesByField] of treeBuilder.entries()) {
      const nodesByField = new Map<Guid, cal.LayoutNodeRoot<T>>()
      for (const [fieldUID, nodeSources] of nodeSourcesByField.entries()) {
        const layoutRoot = cal.treeify(nodeSources)
        nodesByField.set(fieldUID, layoutRoot)
      }
      freshGamesMapping.set(dateKey, nodesByField)
    }
    return freshGamesMapping
  }
}

export type CalNodeVisitor<T> = (_: cal.LayoutNode<T>) => "continue" | "bail"

export function forAllCalendarNodes<T>(byDateByField: Map<Guid, Map<Guid, cal.LayoutNodeRoot<T>>>, f: CalNodeVisitor<NoInfer<T>>) : void {
  for (const gamesByField of byDateByField.values()) {
    for (const gameLayoutNodeRoot of gamesByField.values()) {
      for (const node of gameLayoutNodeRoot.children) {
        if (forEachCalendarNode(node, f) === "bail") {
          return;
        }
      }
    }
  }
}

export function forEachCalendarNode<T>(
  node: cal.LayoutNodeRoot<T> | cal.LayoutNode<T>,
  f: CalNodeVisitor<T>
) : "continue" | "bail" {
  if (node.parent) {
    if (f(node) === "bail") {
      return "bail";
    }
  }
  for (const child of node.children) {
    if (forEachCalendarNode(child, f) === "bail") {
      return "bail"
    }
  }
  return "continue"
}
