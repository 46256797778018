<template lang="pug">
.flex.flex-col.items-center(v-if="ready")
  h1 Score Input
  div(class='sm:mx-auto sm:w-full sm:max-w-md' value='formValues')
    .bg-green-00.py-3.px-3(class='sm:px-10')
      .form-group.block.text-sm.font-medium.leading-5
        .mt-1.rounded-md.shadow-sm
          FormKit.m-2(
            v-model='selectedCompetitionUID',
            :options='competitionSelectOptions',
            type='select',
            label='Program',
            :disabled='competitionSelectOptions.length === 0',
            placeholder='Select a Program',
            input-class='text-black form-input block w-full',
            data-test='competition-select',
            value='selectCompetition'
          )
        .rounded-md.shadow-sm.form-group.mt-6
          FormKit.m-2(
            v-model='selectedDivID',
            :options='divisionSelectOptions',
            type='select',
            :disabled='!selectedCompetitionUID',
            placeholder='Select a Division',
            input-class='text-black form-input block w-full',
            label='Division',
            data-test='division-select',
            value='selectDivision'
          )
      .text-sm.leading-5.mt-6
        FormKit.m-2(
          v-model='selectedDateRange',
          :options='dateRangeSelectOptions',
          type='select',
          :disabled='!selectedCompetitionUID || !selectedDivID',
          placeholder='Select a Date Range',
          input-class='text-black form-input block w-full',
          label='Date Range',
          data-test='date-select'
          value='selectSeason'
        )
      div(v-if="divisionStandingsURL" class="my-3 text-sm")
        a(
          :href="divisionStandingsURL"
          target="_blank"
          class="il-link"
        )
          | View Division Standings
      div(class="text-sm")
        div(class="mt-1 flex items-center")
          Switch(
            v-model="filter.doPointsCountVisibility"
            :on="DoPointsCountVisibility.SHOW_ALL" :off="DoPointsCountVisibility.ONLY_SHOW_WHERE_POINTS_COUNT"
            data-test="doPointsCountVisibilityToggle"
          )
          span(class="ml-2") Include games where points don't count

  .q-pa-md.quasar-style-wrap.mt-8(v-if='!filteredGames.length && !loading')
    h5 No Games Found
  .quasar-style-wrap.mt-8(
    v-else-if='filteredGames.length',
    data-cy='scoresTable',
    class="AllScores-quasar-table-kludge"
  )
    .q-pa-md(class="w-full")
      q-table(
        v-model:pagination="quasarTablePagination"
        :binary-state-sort="true"
        :rows='filteredGames',
        :columns='columns',
        row-key='gameID',
        :rows-per-page-options='[0]',
        hide-pagination,
        dense
      )
        template(v-slot:body='props')
          q-tr.cursor-pointer(
            class="hover:bg-black/[.05] active:bg-black/[.1]"
            @click='toDetail(props.row.gameID)'
            :data-test="props.row.gameID"
          )
            q-td.q-py-md(style="font-size:1rem;")
              div(v-if="isMobile")
                div {{ props.cols[0].value.date }}
                div {{ props.cols[0].value.time }}
              div.flex(v-else)
                span {{ props.cols[0].value.date }}
                span.pl-2.ml-auto @ {{ props.cols[0].value.time }}
            q-td(style="font-size:1rem;")
              div(
                v-if='!props.row.homeTeam.team || !props.row.homeTeam.team || props.row.homeTeam.team.length + props.row.homeTeam.team.length < 15'
              )
                div {{ props.row.homeTeam.team ? props.row.homeTeam.team : "TBD" }} v. {{ props.row.visitorTeam.team ? props.row.visitorTeam.team : "TBD" }}
              div(v-else)
                div {{ props.row.homeTeam.team ? props.row.homeTeam.team : "TBD" }} v.
                div {{ props.row.visitorTeam.team ? props.row.visitorTeam.team : "TBD" }}
            q-td(data-test="score-cell" style="font-size:1rem;")
              div {{ props.cols[2].value }}
            q-td(style="font-size:1rem;")
              div {{ fieldsByFieldID.get(props.row.fieldID)?.fieldAbbrev }}
              div(class="text-sm") {{ fieldsByFieldID.get(props.row.fieldID)?.fieldName }}
            q-td(style="font-size:1rem;")
              div {{ props.row.gameNum }}
            q-td(style="font-size:1rem;")
              div.flex.justify-center
                //- Right margin is to get centering more visually correct, there is a hidden "sort arrow"
                //- that takes up some space in the column header and nudges the column label to the left a bit.
                //- flex centering is correct, but looks wrong here, and so needs this adjustment.
                //- "mr-4" is not a perfectly calculated centering, but it is better than no adjustment.
                font-awesome-icon.mr-4(
                  style='font-size: 16px',
                  :icon='["fas", "angle-double-right"]'
                )
</template>

<script lang="ts">
import {
  dayJSDate,
  dayJSTime,
} from 'src/helpers/formatDate'
import { axiosInstance } from 'src/boot/axios'
import { defineComponent, ref, onMounted, computed, watch } from 'vue'


import { useRouter, useRoute } from 'vue-router'
import { Competition, Division, Guid, isCfNull } from 'src/interfaces/InleagueApiV1'
import { ExpandedGame, FilterState, DoPointsCountVisibility } from './AllScores.ilx'

import dayjs from "dayjs";
import * as ilapi from "src/composables/InleagueApiV1"
import { QTableProps } from "quasar/dist/types"
import { exhaustiveCaseGuard, parseIntOr, sortBy, sortByDayJS, UiOption, useWatchLater, useWindowSize } from 'src/helpers/utils'
import { Switch } from "src/components/UserInterface/Switch"
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client } from 'src/store/Client'
import { getAllowedDivisions } from './R_GameScores.route'
import { Games } from 'src/store/Games'
import { quasarColumnSortAdapter, QuasarPaginationSortPortion } from 'src/helpers/Quasar'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { User } from 'src/store/User'
import { maybeComputeDivisionStandingsURL } from '../Schedule/page/schedules.ilx'
import { Field, getPlayingFields } from 'src/composables/InleagueApiV1'

// Is there a better 'canonical' definition for `columns`?
// This one is defined at its use site, union'd with undefined and wrapped in an array,
// we can extract it but it's ugly and do they guarantee this shape over version changes?
// Anyway, the main point is that the arg to `field` is strongly typed.
type QColumnDef = Exclude<QTableProps["columns"], undefined>[number];
interface ColumnDef<T, U = any> extends QColumnDef {
  field: (arg0: T) => U
  sort?: (fieldL: U, fieldR: U, lSource: T, rSource: T) => number
}

export default defineComponent({
  name: 'RegistrationForm',
  components: {
    Switch,
  },
  setup() {

    const $router = useRouter()
    const route = useRoute()
    const ready = ref(false)
    const playingFields = ref<Field[]>([]);

    // really, we should use fieldUID, but we need to check with backend to ensure
    // fieldUID is ALWAYS non-null (or atleast, fieldID non-null implies fieldUID non-null).
    const fieldsByFieldID = computed(() => {
      return new Map(playingFields.value.map(v => [v.fieldID, v]))
    })

    const filter = ref<FilterState>({
      doPointsCountVisibility: DoPointsCountVisibility.SHOW_ALL
    })
    const filteredGames = computed(() => {
      return applyShowDoPointsCount(games.value);

      function applyShowDoPointsCount(vs: ExpandedGame[]) {
        const filterValue = filter.value.doPointsCountVisibility;
        return vs.filter(v => {
          switch (filterValue) {
            case DoPointsCountVisibility.ONLY_SHOW_WHERE_POINTS_COUNT: return !!v.doPointsCount;
            case DoPointsCountVisibility.SHOW_ALL: return true;
            default: return exhaustiveCaseGuard(filterValue);
          }
        })
      }
    })

    const selectedCompetitionUID = ref('')
    const competitionSelectOptions = ref<UiOption[]>([])
    const selectedCompetition = ref<Competition | null>(null)
    watch(() => selectedCompetitionUID.value, async () => {
      selectedCompetition.value = (await Client.getCompetitionByUID(selectedCompetitionUID.value)) ?? null
    })

    const selectedDivID = ref('')
    const divisionSelectOptions = ref<UiOption[]>([])
    const selectedDivision = ref<Division | null>(null)
    watch(() => selectedDivID.value, async () => {
      selectedDivision.value = (await Client.getDivisionByID(selectedDivID.value)) ?? null
    })

    // not a ref, never changes
    const dateRangeSelectOptions = [
      {value: "week", label: "Past Week"},
      {value: "month", label: "Past Month"},
      {value: "season", label: "All Season"}
    ] as const;
    const selectedDateRange = ref('')

    const games = ref<ExpandedGame[]>([])
    const loading = ref(true)

    const updateGamesDisplayBasedOnCurrentSelections = async () : Promise<void> => {
      if (!selectedCompetitionUID.value || !selectedDivID.value || !selectedDateRange.value) {
        // this method should only be called when we have a "full" selection
        // if we get here, it's a bug
        return;
      }

      games.value = await ilapi.getGamesWhereUserHasPermissionToEditScores(axiosInstance, {
        competitionUID: selectedCompetitionUID.value,
        divID: selectedDivID.value,
        lookBackToThisDate: (() => {
          switch (selectedDateRange.value) {
            case "week": return dayjs().subtract(1, "week").toISOString()
            case "month": return dayjs().subtract(1, "month").toISOString()
            default: return undefined;
          }
        })(),
        expand: ["homeTeam", "visitorTeam"]
      });
      loading.value = false; // ??? uh we're responsible for that here ???
    }

    const columns : ColumnDef<ExpandedGame>[] = [
      {
        name: 'gameDate',
        required: true,
        label: 'Date',
        align: 'left',
        field: (game: ExpandedGame) : {date: string, time: string} => {
          const v = dayjs(game.gameStart);
          if (!v.isValid()) {
            // we don't expect this to happen, but if it does
            // the failure case is ugly (raw date to user) but doesn't crash
            return {date: game.gameStart, time: ""}
          }
          return {
            // Jan-1-70 (Thu)
            date: v.format("MMM-D-YY (ddd)"),
            // 1:00 PM
            time: v.format("h:mm A")
          }
        },
        sortable: true,
        sort: quasarColumnSortAdapter(sortByDayJS(v => v.gameStart)),
        style: 'vertical-align: top;',
        classes: 'q-table'
      },
      {
        name: 'teams',
        required: false,
        label: 'Teams',
        align: 'left',
        sortable: true,
        field: (game: ExpandedGame) => {
          return `${game.homeTeam.team ? game.homeTeam.team : 'TBD'} vs. ${
            game.visitorTeam.team ? game.visitorTeam.team : 'TBD'
          }`
        },
        style: 'vertical-align: top;',
      },
      {
        name: 'Score',
        align: 'center',
        label: 'Score',
        field: (game: ExpandedGame) => {
          // "one null and the other not" really shouldn't happen
          // But here we say that if either is cfnull, then we effectively don't
          // know anything about the scores
          if (isCfNull(game.homeGoals) || isCfNull(game.visitorGoals)) {
            return "TBD";
          }
          return `${game.homeGoals} vs. ${game.visitorGoals}`
        },
        style: 'vertical-align: top;',
        sortable: true,
      },
      {
        name: 'field',
        align: 'center',
        label: 'Field',
        field: (game: ExpandedGame) => {
          return game;
        },
        style: 'vertical-align: top;',
        sortable: true,
        sort: quasarColumnSortAdapter(sortBy(_ => fieldsByFieldID.value.get(_.fieldID)?.fieldAbbrev ?? "zzz")),
      },
      {
        name: 'gameNumber',
        align: 'center',
        label: 'Game No.',
        field: (game: ExpandedGame) => {
          return game;
        },
        style: 'vertical-align: top;',
        sortable: true,
        sort: quasarColumnSortAdapter(sortBy(_ => parseIntOr(_.gameNum, Infinity))),
      },
      {
        name: 'details',
        align: 'center',
        label: 'Details',
        field: (game: ExpandedGame) => {
          return game
        },
        style: 'vertical-align: top;',
        sortable: true,
      },
    ]

    const windowSize = useWindowSize()
    const isMobile = computed(() => windowSize.width < 768)

    const toDetail = async (gameID: Guid) => {
      await Games.setAllScoresComponentState({
        selectedCompetitionUID: selectedCompetitionUID.value,
        selectedDivID: selectedDivID.value,
        selectedDateRange: selectedDateRange.value,
        gamesListing: games.value,
        filterState: filter.value,
      })
      await $router.push({ name: 'score', params: { id: gameID } })
    }

    /**
     * If we have store state, use it to rehydrate.
     * If we don't have store state, it is not an error, we just start from scratch.
     */
    const maybeHydrateFromStoreState = () => {
      const oldState = Games.value.allScoresComponentState;

      if (!oldState) {
        return;
      }

      selectedCompetitionUID.value = oldState.selectedCompetitionUID;
      selectedDivID.value = oldState.selectedDivID;
      selectedDateRange.value = oldState.selectedDateRange;
      filter.value = oldState.filterState;

      games.value = oldState.gamesListing
    }

    /**
     * We must be careful to register these after hydrating the watch targets
     * from store state.
     * Otherwise, hydrate will trigger watches, which is undesirable.
     *
     * todo: should we update store state on watch updates? right now, we only update store state
     * in response to a route push to "update scores"
     *
     * Note we expect to kick these off after some async work, so we do the useWatchLater dance.
     */
    const registerSelectionWatchers = (() => {
      //
      // watch user selections (after having possibly updated watch targets earlier)
      // we have a selection path (competition -> division -> dateRange)
      //
      const compWatch = useWatchLater(selectedCompetitionUID, () => {
        if (selectedDivID.value) {
          selectedDivID.value = ''
          selectedDateRange.value = ''
          loading.value = true
          games.value = []
        }
      })

      const divWatch = useWatchLater(selectedDivID, async () => {
        if (selectedDivID.value && selectedDateRange.value) {
          games.value = []
          loading.value = true
          await updateGamesDisplayBasedOnCurrentSelections()
        }
      })

      const dateWatch = useWatchLater(selectedDateRange, async () => {
        if (selectedDivID.value && selectedDateRange.value) {
          games.value = []
          loading.value = true
          await updateGamesDisplayBasedOnCurrentSelections()
        }
      })

      return () => {
        compWatch.start()
        divWatch.start()
        dateWatch.start()
      }
    })();

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await configureSelectOptions()
        maybeHydrateFromStoreState();
        registerSelectionWatchers();
        playingFields.value = await Client.loadFields(axiosInstance);
        ready.value = true;
      })

      async function configureSelectOptions() {
        const comps = (await getCompetitionsOrFail()).value;
        const divs = await Client.loadDivisions();
        const allowedDivisions = getAllowedDivisions();

        competitionSelectOptions.value = comps
          .filter(v => v.useScores)
          .map(v => {
            return {
              value: v.competitionUID,
              label: v.competition
            }
          })

        divisionSelectOptions.value = divs
          .filter(div => allowedDivisions === "*" || allowedDivisions.find(divID => divID === div.divID))
          .map(div => {
            return {
              value: div.divID,
              label: div.displayName
            }
          })
      }
    })

    const quasarTablePagination = ref<QuasarPaginationSortPortion<string>>({sortBy: "gameDate", descending: true})

    const divisionStandingsURL = computed<string | null>(() => {
      return maybeComputeDivisionStandingsURL({
        competition: selectedCompetition.value,
        division: selectedDivision.value,
        gamelikes: games.value,
      })
    })

    return {
      selectedCompetitionUID,
      competitionSelectOptions,
      selectedDivID,
      divisionSelectOptions,
      selectedDateRange,
      dateRangeSelectOptions,
      columns,
      toDetail,
      loading,
      dayJSDate,
      dayJSTime,
      isMobile,
      filter,
      filteredGames,
      DoPointsCountVisibility,
      quasarTablePagination,
      divisionStandingsURL,
      fieldsByFieldID,
      ready,
    }
  },
})
</script>

<style>
.AllScores-quasar-table-kludge th {
  font-size: 1rem !important;
}
</style>
