<template lang="pug">
div(class="mb-6")
  AutoModal(
    data-test="aysoEpicProgramConfirmationModal"
    :controller="aysoEpicProgramConfirmationModalController"
  )
  RegistrationJourneyBreadcrumbElement(v-bind="registrationJourneyBreadcrumbProps")

router-view(
  v-if="ready"
  @competitionsSelected='handleCompetitionsSelected',
  @volunteer-roles-submit='handleVolunteerRolesSubmit'
  :player='player'
)
</template>

<script lang="tsx">
import { defineComponent, ref, Ref, onMounted, PropType, computed } from 'vue'
import { useRouter } from 'vue-router'
import Competitions from 'src/components/Registration/selections/competitions'
import SelectRolesImpl from 'src/components/VolunteerRegistration/SelectRolesImpl.vue'
import {
  processRoles,
  VolunteerRoleSelection,
} from 'src/components/VolunteerRegistration/SelectRolesUtils'

import { axiosAuthBackgroundInstance, AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import * as ilapi from 'src/composables/InleagueApiV1'
import { type Child, VolunteerRequirementKey, Guid, FamilySeasonCompetitionVolunteerRequirementsCheck, CompositeVolunteerRequirement, Competition, ContentChunk } from 'src/interfaces/InleagueApiV1'

import {
  hasRequirementType,
} from './common'
import { RegistrationI } from 'src/interfaces/Store/registration'
import { RegistrationVolunteerRequirementsFlow, mergeManyFamilySeasonCompVolReqChecks } from 'src/composables/registration'

import { assertNonNull, exhaustiveCaseGuard, parseIntOrFail, setIsSame, unsafe_objectKeys, vOptT, vReqT } from 'src/helpers/utils'
import { User } from 'src/store/User'

import * as R_RegistrationRelationshipCheck from 'src/components/Registration/playerUserRelations/R_RegistrationRelationshipCheck.route'
import { getEligibleCompetitions2, getInvoiceInstanceIDsByCompUID } from './Competitions.shared'
import { voidInvoice } from 'src/composables/InleagueApiV1'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import * as R_SelectCompetitions from "src/components/Registration/selections/R_SelectCompetitions.route"
import * as RegistrationJourneyBreadcrumb from 'src/components/Registration/RegistrationJourneyBreadcrumb'
import { DefaultModalController_r, DefaultTinySoccerballBusyOverlay } from 'src/components/UserInterface/Modal'
import ContentChunkDisplay from 'src/components/Admin/ContentChunks/ContentChunkDisplay'
import { Btn2, btn2_redEnabledClasses } from 'src/components/UserInterface/Btn2'
import { AutoModal } from 'src/components/UserInterface/Modal'
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'
import { EpicConfirmationModal } from './EpicConfirmationModal'
import { ContentChunkRequestInterface } from 'src/interfaces/Store/contentChunks'
import { ContentChunkStore } from 'src/store/ContentChunkStore'
import competitions from 'src/components/Registration/selections/competitions'

export default defineComponent({
  props: {
    seasonUID: vReqT<Guid>(),
    playerID: vReqT<Guid>(),
    // `competitionUIDs` is defined here IFF we are on the subroute where we need to satisfy some volunteer requirements
    // meaning, the "choose competition" portion is complete
    competitionUIDs: vOptT<Guid[]>(),
  },
  components: {
    Competitions: Competitions,
    SelectRolesImpl: SelectRolesImpl,
    RegistrationJourneyBreadcrumbElement: RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElement,
    AutoModal,
  },
  setup(props, context) {
    const player = ref({}) as Ref<Child>
    const router = useRouter()

    const userID = User.value.userID

    const aysoEpicProgramConfirmationModalController = (() => {
      const contentChunkResolver = (() => {
        const p = ReactiveReifiedPromise<ContentChunk>()
        return {
          p,
          loadIfNecessary: () => {
            if (p.underlying.status === "idle") {
              p.run(async () => {
                return await ContentChunkStore.getContentChunk(axiosAuthBackgroundInstance, {
                  id: "epicProgramConfirmationModalText",
                  interpolationArgs: {}
                });
              })
            }
          }
        }
      })();

      return DefaultModalController_r<Competition[]>({
        title: () => <>
          <div>AYSO EPIC</div>
          <div class="my-2 border-b"></div>
        </>,
        content: comps => {
          if (!comps) {
            return null
          }

          // render side-effects ... but should only actually do anything the first time around
          contentChunkResolver.loadIfNecessary()

          return <div>
            <EpicConfirmationModal
              contentChunk={contentChunkResolver.p.underlying}
              onConfirm={() => {
                aysoEpicProgramConfirmationModalController.close()
                handleCompetitionsSelectedWorker(comps)
              }}
              onCancel={() => aysoEpicProgramConfirmationModalController.close()}
            />
            <DefaultTinySoccerballBusyOverlay if={contentChunkResolver.p.underlying.status === "pending"}/>
          </div>
        }
      }, {withXButton: false})
    })();

    const handleCompetitionsSelected = async ({competitions}: {competitions: Competition[]}) => {
      if (competitions.find(v => /\bEPIC\b/i.test(v.competition))) {
        aysoEpicProgramConfirmationModalController.open(competitions);
      }
      else {
        handleCompetitionsSelectedWorker(competitions)
      }
    }

    const handleCompetitionsSelectedWorker = async (competitions: Competition[]) => {
      const familyID = player.value.familyID
      const competitionUIDs = competitions.map(v => v.competitionUID)

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        // TODO: we should have loaded from a call to `getEligibleCompetitions2` from this component already, and passed it down.
        // As it is currently, child components have loaded this and we are doing redundant work to reload this.
        const eligibilityInfo = await getEligibleCompetitions2(axiosInstance, {seasonUID: props.seasonUID, playerID: props.playerID, forSelection: null})
        const invoiceInstanceIDsByCompUID = getInvoiceInstanceIDsByCompUID(eligibilityInfo);

        // todo: do this on the backend at some appropriate time
        // if we selected a competition, then we assume:
        //  - because it was selectable, it either had no invoice or its invoice voidable
        //  - the backend will reject any incoherent attempts to void an unvoidable invoice
        //  - not doing this may allow users to create many invoices for the same compreg, or different mixtures of compregs
        const totalUniqueInvoices = new Set<number>(
          competitionUIDs
            .filter(compUID => !!invoiceInstanceIDsByCompUID[compUID])
            .map(compUID => parseIntOrFail(invoiceInstanceIDsByCompUID[compUID]))
        );

        for (const invoiceInstanceID of totalUniqueInvoices) {
          await voidInvoice(axiosInstance, {invoiceInstanceID});
        }
      });

      // TODO: Promise.all (i.e. not await on each iteration) with ratelimiting adjustments,
      // or a single backend call that accepts many competitionUIDs
      const volunteerRequirementsCheck : FamilySeasonCompetitionVolunteerRequirementsCheck[] = []
      for (const competitionUID of competitionUIDs) {
        volunteerRequirementsCheck.push(
          await ilapi.checkFamilySeasonCompetitionVolunteerRequirements(
            axiosInstance,
            familyID,
            props.seasonUID,
            competitionUID
          )
        )
      }

      const mergedVolReqChecks = mergeManyFamilySeasonCompVolReqChecks(volunteerRequirementsCheck);

      if (mergedVolReqChecks.satisfiesVolunteerRequirement) {
        await router.push(R_RegistrationRelationshipCheck.routeDetailToRouteLocation({
          seasonUID: props.seasonUID,
          competitionUIDs,
          playerID: props.playerID,
        }))
      } else {
        await router.push(R_SelectCompetitions.routeDetailToRouteLocation(R_SelectCompetitions.RouteNames.postSelectCompUpdateVolInfo, {
          seasonUID: props.seasonUID,
          playerID: player.value.childID,
          familyID: player.value.familyID,
          userID,
          competitionUIDs
        }))
      }
    }

    const handleVolunteerRolesSubmit = async (data: VolunteerRoleSelection) => {
      const submittableRoles = processRoles(data)
      const submittableComments = {
        volunteerComments: data.volunteerComments ?? '',
      }

      try {
        await ilapi.updateVolunteerDetails(
          axiosInstance,
          userID,
          props.seasonUID,
          submittableRoles
        )
        await ilapi.updateVolunteerComments(
          axiosInstance,
          userID,
          props.seasonUID,
          submittableComments
        )
      }
      catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);

        // don't nav to the next route
        return;
      }

      assertNonNull(props.competitionUIDs, "expected defined because we are 'choose volunteer roles' sub route");

      await router.push(R_RegistrationRelationshipCheck.routeDetailToRouteLocation({
        seasonUID: props.seasonUID,
        competitionUIDs: props.competitionUIDs,
        playerID: props.playerID,
      }))
    }

    const ready = ref(false);

    onMounted(async ()=> {
      player.value = await ilapi.getPlayer(axiosInstance, {childID: props.playerID})
      ready.value = true;
    })

    const registrationJourneyBreadcrumbProps = computed<RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps>(() => {
      return {
        detail: {
          step: RegistrationJourneyBreadcrumb.Step.selectProgram,
          seasonUID: props.seasonUID,
          playerID: props.playerID,
        }
      } satisfies RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps
    })

    return {
      ready,
      userID,
      handleCompetitionsSelected,
      handleVolunteerRolesSubmit,
      VolunteerRequirementKey,
      hasRequirementType,
      player,
      registrationJourneyBreadcrumbProps,
      aysoEpicProgramConfirmationModalController,
    }
  },
})
</script>
