import { ExtractPublicPropTypes, PropType, WritableComputedRef, computed, defineComponent } from "vue";
import { ExtractEmitsHandlers, UiOption } from "src/helpers/utils";
import { FormKit } from "@formkit/vue";

export const propsDef = {
  selectedKeys: {
    required: true,
    type: null as any as PropType<string[]>
  },
  options: {
    required: true,
    type: null as any as PropType<UiOption[]>
  },
  offerAllOption: {
    required: true,
    type: Boolean
  }
} as const;

export const emitsDef = {
  checkedOne: (_key: string, _freshIsCheckedValue: boolean) => true,
  checkedAll: (_freshValue: boolean) => true,
  /**
   * checked subsumes both "checkedOne" and "checkedAll". That is, if caller is handling the "onChecked" event,
   * they should not be handling either the "checkedOne" or "checkedAll" events.
   */
  checked: (_newVals: string[]) => true,
}

export type Props = ExtractPublicPropTypes<typeof propsDef>
export type Emits = ExtractEmitsHandlers<typeof emitsDef>

export const SelectMany = defineComponent({
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    const selectedByKey_ = computed(() => {
      const result : Record<string, undefined | true> = {}
      props.selectedKeys.forEach(key => result[key] = true)
      return result;
    })

    const selectedAll_ = computed(() => props.options.length > 0 && props.options.length === props.selectedKeys.length);

    // some formkit nonsense where value="..." isn't reactive so we use v-model (??)
    // but we need more control over v-model to avoid update loops with respect to "all" selected
    const selectedByKeyNilSink = computed(() => {
      const result : Record<string, WritableComputedRef<boolean>> = {}
      props.options.forEach(v => {
        const divID = v.value
        result[divID] = computed({
          get() { return !!selectedByKey_.value[divID] },
          set() { /* no-op, handled via onClick */ }
        })
      })
      return result;
    })

    const selectedAllNilSink = computed({
      get() { return selectedAll_.value },
      set() { /* no-op, handled via onClick */ }
    })

    const notifyChecked = (key: string, isChecked: boolean) => {
      if (isChecked) {
        emit("checked", [key, ...props.selectedKeys])
      }
      else {
        emit("checked", props.selectedKeys.filter(v => v !== key));
      }
    }

    const notifyCheckedAll = (isChecked: boolean) => {
      if (isChecked) {
        emit("checked", props.options.map(v => v.value))
      }
      else {
        emit("checked", []);
      }
    }

    return () => {
      if (props.options.length === 0) {
        return <div>No available options</div>
      }
      return (
        // max-width:infinite could maybe be word-break:break-words, or possibly be caller's responsibility
        <div style="--fk-max-width-input: none; --fk-margin-outer: 0">
          {
            props.offerAllOption
              ? (
                <div class="p-2">
                  <FormKit
                    type="checkbox"
                    key={`all`}
                    label="All"
                    v-model={selectedAllNilSink.value}
                    {...{
                      onClick: () => {
                        emit("checkedAll", !selectedAll_.value)
                        notifyCheckedAll(!selectedAll_.value)
                      }
                    }}
                    data-test="allOption"
                  />
                </div>
              )
              : null
          }
          {
            props.options.map((e,i) => {
              const key = e.value;
              const label = e.label;
              return <div class={`p-2 ${i % 2 === 0 ? "bg-gray-50" : ""}`}>
                <FormKit
                  key={`each/${key}`}
                  type="checkbox"
                  label={label}
                  v-model={selectedByKeyNilSink.value[key].value}
                  {...{
                    onClick: () => {
                      emit("checkedOne", key, !selectedByKey_.value[key])
                      notifyChecked(key, !selectedByKey_.value[key])
                    },
                    "data-test": e.value,
                    ...e.attrs // note: might overwrite data-test, this is intentional (caller can provide their own)
                  }}
                />
              </div>
            })
          }
        </div>
      )
    }
  }
})
