import { Guid, Season, WithDefinite } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, onMounted, ref } from "vue";
import { getInvoiceTemplateSeasonOptions, InvoiceTemplate, listInvoiceTemplates } from "./InvoiceTemplates.io";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { accentAwareCaseInsensitiveCompare, assertIsString, assertTruthy, forceCheckedIndexedAccess, noAvailableOptions, parseFloatOr, parseIntOr, routerGetQueryParamAsStringOrNull, sortBy, sortByDayJS, sortByMany, UiOptions } from "src/helpers/utils";
import { FormKit } from "@formkit/vue";
import { Client } from "src/store/Client";
import { ColDef, freshSortState } from "src/modules/TableUtils";
import { dayjsOr } from "src/helpers/formatDate";
import dayjs from "dayjs";
import { Btn2 } from "../UserInterface/Btn2";
import { BasicAutoTable2 } from "src/modules/BasicAutoTable";
import { RouterLink, useRouter } from "vue-router";
import * as R_InvoiceTemplateEditor from "./InvoiceTemplateEditor.route";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { SoccerBall } from "../SVGs";
import { withNoScroll } from "src/router/RouterScrollBehavior";
import * as R_InvoiceTemplateManager from "./InvoiceTemplatePayors.route"

export default defineComponent({
  setup(props) {
    const invoiceTemplates = ReactiveReifiedPromise<InvoiceTemplate[]>()
    const seasonOptMgr = InoviceTemplateSeasonOptionsManager();
    const router = useRouter()
    const ready = ref(false)

    const hydrateFromCurrentSelection = async () : Promise<void> => {
      const seasonUID = seasonOptMgr.selectedSeasonUID
      if (!seasonUID) {
        return
      }

      invoiceTemplates.run(() => listInvoiceTemplates(axiosAuthBackgroundInstance, {seasonUID}))
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await seasonOptMgr.init()

        {
          const seasonUID = routerGetQueryParamAsStringOrNull(router.currentRoute.value, "seasonUID")
          if (seasonUID && seasonOptMgr.isValidOption(seasonUID)) {
            seasonOptMgr.selectedSeasonUID = seasonUID
          }
        }

        await hydrateFromCurrentSelection()

        ready.value = true
      })
    })

    const colDefs = freshColDefs()
    const sortState = freshSortState(colDefs)

    const noData = () => {
      if (invoiceTemplates.underlying.status === "pending") {
        return <div class="p-4 flex items-center gap-2">
          <SoccerBall/>
          Loading...
        </div>
      }
      else {
        return <div class="p-4">No data</div>
      }
    }

    return () => {
      if (!ready.value) {
        return null;
      }

      return <div style="--fk-margin-outer:none; --fk-padding-input: .35em; --fk-bg-input:white;">
        <h2>Invoice Template Management</h2>
        <div class="mb-5 mt-5">Invoice templates represent a payment owed by one or more players for any purpose. To view the recipients and the payment status for an invoice, select the link under the Recipients column. To manage the name or email instructions for an invoice, click on the invoice name.</div>
        <div class="mb-5">View our <a href="https://gitlab.inleague.io/content/guides-and-documents/-/wikis/Invoicing-and-Payment-Templates" target="_blank">Community Guide</a> for more detailed documentation on invoice templates.</div>
        <div>
          <FormKit
            type="select"
            disabled={seasonOptMgr.options.disabled}
            options={seasonOptMgr.options.options}
            v-model={seasonOptMgr.selectedSeasonUID}
            onInput={seasonUID => {
              assertIsString(seasonUID)
              if (seasonOptMgr.selectedSeasonUID === seasonUID) {
                return;
              }
              seasonOptMgr.selectedSeasonUID = seasonUID
              hydrateFromCurrentSelection()
              withNoScroll(async () => {
                await router.replace({...router.currentRoute.value, query: {...router.currentRoute.value.query, seasonUID: seasonUID}})
              })
            }}
            label="Season"
          />
          <RouterLink to={R_InvoiceTemplateEditor.route({mode: "new", seasonUID: seasonOptMgr.selectedSeasonUID})}>
            <Btn2 class="mt-2 px-2 py-1" data-test="new-button">
              New Invoice Template
            </Btn2>
          </RouterLink>
        </div>
        <div class="mt-4 border rounded-md p-2 bg-white">
          <BasicAutoTable2
            colDefs={colDefs}
            sortState={sortState}
            rows={invoiceTemplates.underlying.status === "resolved" ? invoiceTemplates.underlying.data : []}
            paginationOptions={[50, 100, "ALL"]}
            asXLSX={{filename: `invoice-templates-${seasonOptMgr.selectedSeason?.seasonName}.xlsx`}}
            rowKey={row => (row as InvoiceTemplate).invoiceID}
            rowAttrs={row => ({"data-test": (row as InvoiceTemplate).invoiceID})}
            noData={noData}
          />
        </div>
      </div>
    }
  }
})

function freshColDefs() : ColDef<WithDefinite<InvoiceTemplate, "paymentGateway">>[] {
  const distantFuture = dayjs().add(100, "years")

  // Use exclude<T, ...primitives> instead of extract<T, object> because branded primitive types may extend object
  const objOr = <T extends object>(v: T) : Exclude<T, string | number | boolean> | null => typeof v === "object" ? v : null

  const nbsp = "\u00A0"

  return [
    {
      id: "label",
      label: "Label",
      headerClass: "p-1 border",
      html: v => <RouterLink class="il-link" to={R_InvoiceTemplateEditor.route({mode: "edit", invoiceTemplateID: v.invoiceID})}>
        {v.invoiceLabel}
      </RouterLink>,
      xlsx: v => v.invoiceLabel,
      sort: sortBy(v => v.invoiceLabel),
    },
    {
      id: "invoiceNum",
      label: `Invoice${nbsp}#`,
      headerClass: "border",
      html: v => v.invoiceNum,
      sort: sortBy(v => parseIntOr(v.invoiceNum, Infinity))
    },
    {
      id: "amount",
      label: "Amount",
      headerClass: "p-1 border",
      html: v => {
        const amt = parseFloatOr(v.amount, 0)
        return amt.toFixed(2);
      },
      sort: sortBy(v => parseFloatOr(v.amount, Infinity))
    },
    {
      id: "recipientCount",
      label: `#${nbsp}Recipients`,
      headerClass: "p-1 border",
      html: v => <RouterLink class="il-link" to={R_InvoiceTemplateManager.route({invoiceTemplateID: v.invoiceID})}>{v.instanceCount}</RouterLink>,
      xlsx: v => v.instanceCount,
      sort: sortBy(v => parseIntOr(v.instanceCount, Infinity)),
    },
    {
      id: "paidCount",
      label: `#${nbsp}Paid`,
      headerClass: "p-1 border",
      html: v => v.paidInstancesCount,
      sort: sortBy(v => parseIntOr(v.paidInstancesCount, Infinity)),
    },
    {
      id: "dueDate",
      label: "Due Date",
      headerClass: "p-1 border",
      html: v => dayjsOr(v.fixedDueDate)?.format("MMM/DD/YYYY") ?? "",
      sort: sortByDayJS(v => v.fixedDueDate || distantFuture)
    },
    {
      id: "createdDate",
      label: "Date Created",
      headerClass: "p-1 border",
      html: v => dayjs(v.dateCreated).format("MMM/DD/YYYY"),
      sort: sortByDayJS(v => v.dateCreated)
    },
    {
      id: "account",
      label: "Account",
      headerClass: "p-1 border",
      html: v => v.paymentGateway.name,
      sort: sortBy(v => v.paymentGateway.name)
    },
    {
      id: "createdBy",
      label: "Creator",
      headerClass: "p-1 border",
      html: v => {
        if (typeof v.createdBy === "string") {
          // this shouldn't happen here, we should always have the object variant
          return ""
        }
        return `${v.createdBy.firstName} ${v.createdBy.lastName}`
      },
      sort: sortByMany(
        (l,r) => accentAwareCaseInsensitiveCompare(objOr(l.createdBy)?.lastName || "zzz", objOr(r.createdBy)?.lastName || "zzz"),
        (l,r) => accentAwareCaseInsensitiveCompare(objOr(l.createdBy)?.firstName || "zzz", objOr(r.createdBy)?.firstName || "zzz"),
      )
    }
  ]
}

function InoviceTemplateSeasonOptionsManager() {
  const seasons = ref<Season[]>([])
  const seasonOptions = ref<UiOptions>(noAvailableOptions("Loading..."))
  const selectedSeasonUID = ref<"" | Guid>("")
  const selectedSeason = computed<Season | null>(() => {
    if (seasonOptions.value.disabled) {
      return null;
    }
    return seasons.value.find(season => season.seasonUID === selectedSeasonUID.value) ?? null
  })

  const init = async () : Promise<void> => {
    seasons.value = await getInvoiceTemplateSeasonOptions(axiosInstance).then(v => v.seasons);

    if (seasons.value.length === 0) {
      seasonOptions.value = noAvailableOptions()
    }
    else {
      seasonOptions.value = {
        disabled: false,
        options: seasons.value.map(v => ({label: v.seasonName, value: v.seasonUID}))
      }
    }

    selectedSeasonUID.value = seasons.value.find(v => v.seasonUID === Client.value.instanceConfig.currentseasonuid)?.seasonUID
      ?? forceCheckedIndexedAccess(seasonOptions.value.options, 0)?.value
      ?? "";
  }

  const isValidOption = (seasonUID: Guid) : boolean => {
    return !!seasonOptions.value.options.find(opt => opt.value === seasonUID)
  }

  return {
    get seasons() { return seasons.value },
    get options() { return seasonOptions.value },
    get selectedSeasonUID() { return selectedSeasonUID.value },
    set selectedSeasonUID(v) {
      assertTruthy(isValidOption(v));
      selectedSeasonUID.value = v
    },
    get selectedSeason() { return selectedSeason.value },
    isValidOption,
    init,
  }
}
