import { Sorter } from "./utils";

/**
 * A subset of quasar's "pagination" object; we're only interested in sorting.
 */
export interface QuasarPaginationSortPortion<T extends string> {
  /**
   * property name to sort by
   */
  sortBy: T,
  descending: boolean
}

/**
 * We need to drop the first 2 args so as to use sorters in our typical fashion.
 * See signature for ColumnDef<T>, and how its first two args are not `T` but rather whatever was returned from `field`.
 */
export const quasarColumnSortAdapter = <T,>(sorter: Sorter<T>) => (_1: unknown, _2: unknown, l: T, r: T) => sorter(l,r)

/**
 * `FieldResult` is the result of an invocation to `field`, when it is a function;
 * it is fed as the first arguments to sort; often we are only interested in the "whole row"
 */
export interface QuasarColDef<Row, FieldResult = unknown> {
  name?: string,
  sortable?: boolean,
  sort?: (_1: FieldResult, _2: FieldResult, l: Row, r: Row) => number,
  required?: boolean,
  label?: string,
  align?: string,
  style?: string,
  headerStyle?: string,
  field?: any,
  classes?: string,
}
