import { computed, defineComponent } from "vue";
import { PayableInvoicesResolver } from "src/store/EventuallyPinia";
import { dollarFormat, exhaustiveCaseGuard, parseFloatOr, parseFloatOrFail } from "src/helpers/utils";
import { RouteLocationRaw, RouterLink } from "vue-router";
import * as R_ChooseInvoiceTemplatePaymentMethod from "../InvoiceTemplates/ChooseInvoiceTemplatePaymentMethod.route"

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faFileInvoiceDollar } from "@fortawesome/pro-regular-svg-icons";
import { freshNoToastLoggedInAxiosInstance } from "src/boot/axios";

import * as R_Checkout from 'src/components/Payment/pages/Checkout.route'
import { Btn2 } from "../UserInterface/Btn2";
import { Invoice } from "src/interfaces/InleagueApiV1";
import { ReifiedPromise } from "src/helpers/ReifiedPromise";
import { isPureInvoiceTemplateBased } from "../Payment/InvoiceUtils";
import { isOldStyleInvoiceTemplateBasedInvoiceInstanceHavingZeroLineItems, oldStyleInvoiceLink } from "../Payment/pages/Checkout.elems";

export default defineComponent({
  setup() {
    const payableInvoices = computed<ReifiedPromise<Invoice[]>>(() => PayableInvoicesResolver.getOnce(freshNoToastLoggedInAxiosInstance()))

    const CheckoutLink = ({invoice}: {invoice: Invoice}) => {
      const target = checkoutRoute(invoice);

      const CommonButton = () => <Btn2 class="mt-2 px-2 py-1 text-sm">Pay Invoice</Btn2>

      switch (target.type) {
        case "app": return (
          <RouterLink to={target.route} data-test="checkout-button">
            <CommonButton/>
          </RouterLink>
        )
        case "url": return (
          <a href={target.url} target="_blank" data-test="legacy-checkout-button">
            <CommonButton/>
          </a>
        )
        default: exhaustiveCaseGuard(target)
      }

      function checkoutRoute(invoice: Invoice) : {type: "app", route: RouteLocationRaw} | {type: "url", url: string} {
        if (isPureInvoiceTemplateBased(invoice)) {
          return {
            type: "app",
            route: R_ChooseInvoiceTemplatePaymentMethod.route({invoiceInstanceID: invoice.instanceID})
          }
        }
        else if (isOldStyleInvoiceTemplateBasedInvoiceInstanceHavingZeroLineItems(invoice)) {
          return {
            type: "url",
            url: oldStyleInvoiceLink()
          }
        }
        else {
          return {
            type: "app",
            route: R_Checkout.routeDetailToRouteLocation({
              invoiceInstanceIDs: [invoice.instanceID]
            })
          }
        }
      }
    }

    const oldStyleInvoiceAmount = (invoice: Invoice) => {
      const amount = parseFloatOrFail(invoice.invoiceAmount)
      const percentDiscount = parseFloatOr(invoice.percentDiscount, 0)
      const dollarDiscount = parseFloatOr(invoice.dollarDiscount, 0)

      let effectiveDiscount : number

      if (percentDiscount) {
        effectiveDiscount = (percentDiscount / 100) * amount
      }
      else if (dollarDiscount) {
        effectiveDiscount = dollarDiscount
      }
      else {
        effectiveDiscount = 0
      }

      return dollarFormat(Math.max(amount - effectiveDiscount, 0))
    }

    const newStyleInvoiceAmount = (invoice: Invoice) => {
      return dollarFormat(invoice.lineItemSum)
    }

    return () => (
      <>
        <h1>
          <FontAwesomeIcon icon={faFileInvoiceDollar}/>
          <span class="ml-2">Current Outstanding Invoices</span>
        </h1>
        {
          (() => {
            switch (payableInvoices.value.status) {
              case "idle":
                // shouldn't happen, or only very briefly
                return null;
              case "error":
                return <div>Sorry, something went wrong</div>
              case "pending":
                return <div>Retrieving invoices...</div>
              case "resolved":
                return (
                  <div>
                    {
                      // shouldn't generally happen, we don't offer a link to this page if there aren't values to show
                      payableInvoices.value.data.length === 0
                        ? <div class="p-2">No current outstanding invoices.</div>
                        : null
                    }
                    {
                      payableInvoices.value.data.map(invoice => {
                        return (
                          <div class="bg-white p-2 m-2 border border-slate-100 shadow-md rounded-md" data-test={`invoiceInstanceID=${invoice.instanceID}`}>
                            <div>
                              {/* We don't expect to get a lot of the "old style" ones, but if we do we need to show its equivalent of a 'lineItemSum'*/}
                              {isOldStyleInvoiceTemplateBasedInvoiceInstanceHavingZeroLineItems(invoice)
                                ? <span>Invoice {invoice.instanceID} &ndash; ${oldStyleInvoiceAmount(invoice)}</span>
                                : <span>Invoice {invoice.instanceID} &ndash; ${newStyleInvoiceAmount(invoice)}</span>
                              }

                            </div>
                            {invoice.instanceDesc.trim()
                              ? <div>
                                  <div class="my-2 border-b"/>
                                  <div v-html={invoice.instanceDesc}/>
                              </div>
                              : null}
                            {invoice
                              .lineItems
                              .map(line => <div class="pl-1">{line.description}</div>)
                            }
                            <CheckoutLink invoice={invoice}/>
                          </div>
                        )
                      })
                    }
                  </div>
                );
              default: exhaustiveCaseGuard(payableInvoices.value)
            }
          })()
        }
      </>
    )
  }
})
