import { computed, nextTick, ref, watch } from "vue"
import { IL_PaymentMethod, stripe_deletePaymentMethod, stripe_deleteSetupIntentPendingAchVerification, stripe_listCustomerPaymentMethods } from "./Payments.io"
import { Invoice } from "src/interfaces/InleagueApiV1"
import { assertNonNull, forceCheckedIndexedAccess, parseFloatOrFail, Reflike, requireNonNull } from "src/helpers/utils"
import { Stripe as StripeJS, StripeElements, loadStripe, StripeElementStyle } from "@stripe/stripe-js"
import { AxiosInstance } from "axios"
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper"
import { Client } from "src/store/Client"

export enum CollectPaymentMethodDetailsType { card = "card", ach = "ach" }

export enum PaymentMethodUsability {
  ok = "ok",
  requiresNextAction = "requiresNextAction"
}

export interface AllowedPaymentMethods {
  card: boolean,
  us_bank_account: boolean,
}

/**
 * This produces state this is somewhat but not fully reactive to a single invoice.
 * The state will remain valid and appropriately update when invoice amounts change (as when coupons are applied, what payment methods are available, etc.),
 * but is not robust to an outright change to an invoice (e.g. a new separate invoice is loaded in the place of an earlier one).
 * For cases where an invoice might be swapped out entirely, in general, components that own this state are expected
 * to be keyed on invoiceInstanceID and fully re{mount,initialize} when an invoice is swapped out.
 *
 * Somewhat inconveniently, this "constructor" returns a promise. But this clearly indicates to users the current dependence on an async request,
 * required to get information we need about the invoice's associated stripe account, so that the resulting object is left initialized in a
 * useless or bugprone state.
 */
export const UserPaymentMethodsManagerState = async (ax: AxiosInstance, args: {
  inv: Reflike<Invoice>
}) => {
  const stripeAccountID = ref("")
  const paymentMethods = ref<IL_PaymentMethod[]>([])
  const achEnabled = ref(false)
  const achOfferInstantVerification = ref(false)
  const merchantOfRecord = ref("")

  const selectedCollectPaymentMethodDetailsType = ref(CollectPaymentMethodDetailsType.card)
  const selectedPaymentMethodID = ref("") // empty string or a stripe paymentMethodID matching the pattern "^pm_"
  const selectedManuallyEnterAchDetails = ref(false)

  const allowedPaymentMethods = computed<AllowedPaymentMethods>(() => {
    assertNonNull(args.inv.value.allowedPaymentMethods)
    return {
      card: !!args.inv.value.allowedPaymentMethods.includes("card"),
      us_bank_account: !!args.inv.value.allowedPaymentMethods.includes("us_bank_account")
    }
  })

  const hydratePaymentMethodsAndAssociatedStripeInfo = async (ax: AxiosInstance) => {
    try {
      const r = await stripe_listCustomerPaymentMethods(ax, {clientGatewayID: args.inv.value.paymentGatewayID}).then(data => {
        data.paymentMethods = data.paymentMethods.filter(v => {
          switch (v.type) {
            case "card": return allowedPaymentMethods.value.card
            case "us_bank_account": return allowedPaymentMethods.value.us_bank_account
            default: return false;
          }
        })
        return data;
      })

      // should remain constant across all reloads for a single invoice
      stripeAccountID.value = r.stripeAccountID;
      merchantOfRecord.value = r.ach.connectAccountMerchantOfRecord;

      // expected dynamic per reload
      paymentMethods.value = r.paymentMethods;
      achEnabled.value = r.ach.enabled
      achOfferInstantVerification.value = r.ach.offerInstantVerification

      // if payment methods changed, and we have a "current selection" of something that is no longer an option, update the current selection appropriately
      if (paymentMethods.value.length > 0) {
        const currentUiSelectionIsPresentInResponse = !!paymentMethods.value.find(pm => pm.id === selectedPaymentMethodID.value);
        if (!currentUiSelectionIsPresentInResponse) {
          selectedPaymentMethodID.value = forceCheckedIndexedAccess(paymentMethods.value, 0)?.id || "";
        }
      }
      else {
        selectedPaymentMethodID.value = ""
      }
    } catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err)
    }
  }

  const selectedPaymentMethodUnusable = computed(() : PaymentMethodUsability | null => {
    if (!selectedPaymentMethodID.value.startsWith("pm_")) {
      // "nothing selected"
      return null
    }

    const pm = paymentMethods.value.find(pm => pm.id === selectedPaymentMethodID.value)

    if (!pm) {
      return null
    }

    if (pm.type === "us_bank_account" && pm.setupIntent?.next_action) {
      // The selected paymentMethod is for a us_bank_account that is not yet verified,
      // so it cannot be used until it is verified.
      return PaymentMethodUsability.requiresNextAction
    }

    return PaymentMethodUsability.ok
  })

  /**
   * Allowed payment methods might change in the following scenario(s):
   *  - coupon is applied, changing amounts, which can change allowed payment methods
   */
  watch(() => allowedPaymentMethods.value, () => {
    const allowed : CollectPaymentMethodDetailsType[] = (() => {
      const result : CollectPaymentMethodDetailsType[] = []
      if (allowedPaymentMethods.value.us_bank_account) {
        result.push(CollectPaymentMethodDetailsType.ach)
      }
      if (allowedPaymentMethods.value.card) {
        result.push(CollectPaymentMethodDetailsType.card)
      }
      return result
    })()

    if (!allowed.find(v => v === selectedCollectPaymentMethodDetailsType.value)) {
      selectedCollectPaymentMethodDetailsType.value = forceCheckedIndexedAccess(allowed, 0) || CollectPaymentMethodDetailsType.card
    }
  }, {immediate: true})

  const doDeletePaymentMethod = async (ax: AxiosInstance, paymentMethod: IL_PaymentMethod) : Promise<void> => {
    try {
      if (paymentMethod.setupIntent?.next_action?.type === "verify_with_microdeposits") {
        await stripe_deleteSetupIntentPendingAchVerification(ax, {
          clientGatewayID: args.inv.value.paymentGatewayID,
          setupIntentID: paymentMethod.setupIntent.id
        })
      }
      else {
        await stripe_deletePaymentMethod(ax, {paymentGatewayID: args.inv.value.paymentGatewayID, paymentMethodID: paymentMethod.id})
      }
      await hydratePaymentMethodsAndAssociatedStripeInfo(ax)
    } catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err)
    }
  }

  // initialize
  await hydratePaymentMethodsAndAssociatedStripeInfo(ax)

  return {
    // this will re-init associated stripe info, but that info is expected to not have changed for a single invoice (what stripe account, connect account merchatn of record, etc)
    // callers don't care about re-initing stripe info, just reloading payment methods, so it is renamed here
    rehydratePaymentMethods: hydratePaymentMethodsAndAssociatedStripeInfo,
    doDeletePaymentMethod,
    get stripeAccountID() { return stripeAccountID.value },
    get paymentMethods() { return paymentMethods.value },
    get achEnabled() { return achEnabled.value },
    get achOfferInstantVerification() { return achOfferInstantVerification.value },
    get merchantOfRecord() { return merchantOfRecord.value },
    get selectedPaymentMethodUnusable() { return selectedPaymentMethodUnusable.value },
    get allowedPaymentMethods() { return allowedPaymentMethods.value },
    get invoice() { return args.inv.value },
    selectedPaymentMethodID,
    selectedCollectPaymentMethodDetailsType,
    selectedManuallyEnterAchDetails,
  }
}

export type UserPaymentMethodsManagerState = Awaited<ReturnType<typeof UserPaymentMethodsManagerState>>

export interface StripeConfig {
  stripeJS: StripeJS,
  elements: StripeElements,
}

UserPaymentMethodsManagerState.loadStripe = async (args: {stripeAccountID: string}) : Promise<StripeConfig | null> => {
  const stripeJS = await loadStripe(Client.value.stripePublicKey, {
    stripeAccount: args.stripeAccountID
  })
  if (!stripeJS) {
    return null
  }
  const elements = stripeJS?.elements()
  if (!elements) {
    return null
  }
  return {
    stripeJS: stripeJS,
    elements
  }
}

UserPaymentMethodsManagerState.initCardElement = (args: {container: HTMLElement, elements: StripeElements, onReady?: () => void, onChange?: () => void}) => {
  const cardElementsStyle : StripeElementStyle = {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  }

  const card = args.elements.create('card', {style: cardElementsStyle})

  card.once("ready", () => {
    args.onReady?.()
  })

  card.mount(args.container)

  card.on("change", () => {
    args.onChange?.()
  });

  return card;
}

UserPaymentMethodsManagerState.tryConfigureAppleOrGooglePay = async (inv: Invoice, stripeConfig: StripeConfig) => {
  const paymentRequest = stripeConfig.stripeJS.paymentRequest({
    country: 'US',
    currency: 'usd',
    total: {
      label: `${Client.value.instanceConfig.shortname}`,
      amount: parseFloatOrFail(inv.lineItemSum) * 100,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  })

  const result = await paymentRequest.canMakePayment() as null | Record<"applePay" | "googlePay", boolean>

  let appleOrGooglePay : "G" | "A" | null = null

  if (result) {
    if (result.applePay) {
      appleOrGooglePay = 'A'
      const prButton = stripeConfig.elements.create('paymentRequestButton', {
        paymentRequest: paymentRequest,
        style: {
          paymentRequestButton: {
            height: '48px',
          },
        },
      })
      //
      // #payment-request-button is more like "apple-pay-payment-request-button" ?
      //
      // wait for page reflow in response to setting AppleOrGooglePay.value = 'A'
      // otherwise, the element identified by #payment-request-button is not present in DOM, and
      // asking to mount stripe stuff against it fails. Out of an abundance of caution we double await,
      // with intent to "redraw everything" (is it guaranteed that a single await will redraw, or could
      // we already be after that phase when we await the first nexttick)
      //
      // This might not solve the problem but it shouldn't make anything worse.
      //
      // todo: test that this 100% solves the issue
      // related: https://sentry.io/organizations/inleague-llc/issues/3768001648/?project=5661592
      //
      await nextTick();
      await nextTick();

      const targetElementSelector = '#payment-request-button'
      if (document.querySelector(targetElementSelector)) {
        // cool, we're still mounted, and the target element exists
        prButton.mount(targetElementSelector)
      }
      else {
        //
        // Presumably here we have been unmounted, maybe a user hit "back" while we were waiting on one
        // of the awaits above. If we don't guard this, stripe will throw an exception when trying to work with a DOM
        // element that doesn't exist.
        //
        // There's nothing we can do here.
        //
        // see: https://inleague-llc.sentry.io/issues/4404557090/?alert_rule_id=4761582&alert_timestamp=1692373867165&alert_type=email&environment=production&project=5661592&referrer=alert_email
        //
      }
    }
    else if (result.googlePay) {
      appleOrGooglePay = 'G'
    }
    else {
      // no-op
    }
  } else {
    // this was an assignment to a non-null-asserted lefthand side, but the non-null assertion was not correct (i.e. obj still falsy)
    // what does it mean if this element is not present?
    const maybePaymentRequestButton = document.getElementById('payment-request-button');
    if (maybePaymentRequestButton) {
      maybePaymentRequestButton.style.display = 'none'
    }
  }

  return {
    appleOrGooglePay,
    paymentRequest
  }
}
