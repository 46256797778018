import dayjs from "dayjs";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { assertNonNull, dollarFormat, exhaustiveCaseGuard, nonThrowingExhaustiveCaseGuard, parseFloatOrFail, poll, requireNonNull, vReqT } from "src/helpers/utils";
import { Integerlike, Invoice } from "src/interfaces/InleagueApiV1";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { computed, defineComponent, onMounted, ref, shallowRef } from "vue";
import { getInvoice } from "src/composables/InleagueApiV1";
import { dayjsOr } from "src/helpers/formatDate";
import { UserPaymentMethodsManagerElement, UserPaymentMethodsManagerElementSlots } from "./UserPaymentMethodsManagerElement";
import { UserPaymentMethodsManagerState } from "./UserPaymentMethodsManagerState";
import { IL_PaymentMethod } from "./Payments.io";
import { Btn2 } from "../UserInterface/Btn2";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { __fixme__canonicalizeInleagueSubscriptionStatus, getSubscriptionForUpdate, SubscriptionForUpdate, updateSubscription } from "./SubscriptionUpdate.io";
import iziToast from "izitoast";


export default defineComponent({
  props: {
    subscriptionID: vReqT<Integerlike>(),
  },
  setup(props) {
    const invoiceInstance = ref<Invoice | null>(null)
    const subscription = ref<SubscriptionForUpdate | null>(null)
    const pmMgrState = shallowRef<UserPaymentMethodsManagerState | null>(null)
    const paymentInProgress = ref(false)
    const errors = ref("")
    const ready = ref(false)

    const paymentMethodBlurb = computed(() => {
      const pm = subscription.value?.stripeSubscription.default_payment_method

      if (!pm || typeof pm !== "object") {
        return ""
      }

      return pm.type === "card"
        ? `${pm.card?.brand || "(Unknown card issuer)"} ${pm.card?.last4 || ""}`
        : pm.type === "us_bank_account"
        ? `${pm.us_bank_account?.bank_name || "(Unknown bank)"} ${pm.us_bank_account?.last4 || ""}`
        : pm.type // rough fallback, we only really expect the types we explicitly matched
    })

    const deletePaymentMethod = async (paymentMethod: IL_PaymentMethod) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        assertNonNull(pmMgrState.value)
        await pmMgrState.value.doDeletePaymentMethod(axiosInstance, paymentMethod)
      })
    }

    const updatePaymentMethod = async (args: {paymentMethodID: string}) => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          paymentInProgress.value = true
          assertNonNull(subscription.value)
          await updateSubscription(axiosInstance, {subscriptionID: subscription.value.localSubscriptionID, default_payment_method: args.paymentMethodID})
          if (__fixme__canonicalizeInleagueSubscriptionStatus(subscription.value) === "suspended") {
            const localSubscriptionID = subscription.value.localSubscriptionID
            const p = await poll(30, 1000, async () => {
              const obj = await getSubscriptionForUpdate(axiosInstance, {subscriptionID: localSubscriptionID})
              const status = __fixme__canonicalizeInleagueSubscriptionStatus(obj)
              return {done: status !== "suspended", data: obj}
            })
            if (p.ok) {
              subscription.value = p.data
              iziToast.success({message: "Payment plan updated."})
            }
            else {
              iziToast.warning({message: "Payment plan was updated; payment of the current invoice is being processed, but is taking longer than expected - please check back in a few minutes."})
            }
          }
          else {
            iziToast.success({message: "Payment plan updated."})
          }
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
        finally {
          paymentInProgress.value = false
        }
      })
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        subscription.value = await getSubscriptionForUpdate(axiosInstance, {subscriptionID: props.subscriptionID})
        invoiceInstance.value = await getInvoice(axiosInstance, {instanceID: subscription.value.instanceID, expand: ["allowedPaymentMethods"]})

        pmMgrState.value = await UserPaymentMethodsManagerState(axiosAuthBackgroundInstance, {
          inv: computed(() => requireNonNull(invoiceInstance.value))
        })

        ready.value = true
      })
    })

    return () => {
      if (!ready.value) {
        return null
      }

      assertNonNull(subscription.value)
      assertNonNull(invoiceInstance.value)
      assertNonNull(pmMgrState.value)

      const sub = subscription.value
      const inv = invoiceInstance.value

      assertNonNull(inv)

      return <div>
        <div class="rounded-md bg-white">
          <div class="p-1 rounded-t-md bg-green-800 text-white">{sub.description}</div>
          <div class="p-2">
            <table>
              <tr>
                <td class="pr-2">Status:</td>
                <td data-test="status-cell">{uiStatus(sub)}</td>
              </tr>
              <tr>
                <td class="pr-2">Date Initiated:</td>
                <td>{dayjs(sub.dateCreated).format("MMM/DD/YYYY h:mm a")}</td>
              </tr>
              <tr>
                <td class="pr-2">Invoice Purpose:</td>
                <td>{sub.description}</td>
              </tr>
              <tr>
                <td class="pr-2">Payment Method:</td>
                <td>{paymentMethodBlurb.value}</td>
              </tr>
              <tr>
                <td class="pr-2">Remaining Balance:</td>
                <td>${dollarFormat(parseFloatOrFail(inv.lineItemSum) - inv.transactionBalance)}</td>
              </tr>
              <tr>
                <td class="pr-2">Payment Schedule:</td>
                <td>{sub.paymentMethod?.tailoredDescription}</td>
              </tr>
              <tr>
                <td class="pr-2">Number of Payments Made:</td>
                <td>{sub.paymentNum}</td>
              </tr>
              <tr>
                <td class="pr-2">Next Payment:</td>
                <td>{__fixme__canonicalizeInleagueSubscriptionStatus(sub) === "active" || __fixme__canonicalizeInleagueSubscriptionStatus(sub) === "suspended"
                  ? dayjs(sub.nextUpdate).format("MMMM DD, YYYY")
                  : null
                }</td>
              </tr>
              <tr>
                <td class="pr-2">Final Payment:</td>
                <td>{dayjsOr(sub.paymentMethod?.completionDate)?.format("MMMM DD, YYYY")}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="rounded-md bg-white my-4">
          <div class="p-1 rounded-t-md bg-green-800 text-white">Update Payment Plan Payment Method</div>
          <div class="p-2">
            {sub.stripeSubscription?.status === "past_due"
              ? <div>
                This subscription is past due. Updating this subscription's payment method will attempt
                to collect the current past due invoice of ${dollarFormat(sub.stripeSubscription?.latest_invoice.amount_due)}.
              </div>
              : null}
            <UserPaymentMethodsManagerElement
              state={pmMgrState.value}
              expiresColumnLabel="Expires"
              savePaymentMethod={null}
              errors={errors.value}
              paymentInProgress={paymentInProgress.value}
              cardSubmitLabel="Update Subscription"
              achStripeModalLabel="Update Subscription by Connecting a Bank Account..."
              achManualInputLabel="Update Subscription"
              onDeletePaymentMethod={pm => deletePaymentMethod(pm)}
              onCreatedNewPaymentMethod={async evt => {
                await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
                  assertNonNull(pmMgrState.value)
                  await pmMgrState.value.rehydratePaymentMethods(axiosInstance)
                  if (pmMgrState.value.paymentMethods.find(v => v.id === evt.paymentMethodID)) {
                    pmMgrState.value.selectedPaymentMethodID.value = evt.paymentMethodID
                  }
                  evt.finally?.()
                })
              }}
              onSubmitPayment={async evt => {
                await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
                  await updatePaymentMethod({paymentMethodID: evt.paymentMethodID})
                })
              }}
              onCardElemChanged={() => { errors.value = "" } }
            >
              {{
                afterPaymentMethodsListing: () => pmMgrState.value?.selectedPaymentMethodID.value.startsWith("pm_")
                  ? <Btn2
                    class="px-2 py-1"
                    onClick={() => updatePaymentMethod({paymentMethodID: requireNonNull(pmMgrState.value).selectedPaymentMethodID.value})}
                    data-test="submit-using-existing-payment-method"
                  >
                    Use Selected Payment Method
                  </Btn2>
                  : null
              } satisfies UserPaymentMethodsManagerElementSlots}
            </UserPaymentMethodsManagerElement>
          </div>
        </div>
      </div>
    }
  }
})

function uiStatus(sub: SubscriptionForUpdate) {
  const v = __fixme__canonicalizeInleagueSubscriptionStatus(sub)
  switch (v) {
    case "active": return "Active"
    case "cancelled": return "Cancelled"
    case "complete": return "Complete"
    case "suspended": return "Suspended"
    default:
      nonThrowingExhaustiveCaseGuard(v)
      return "" // shouldn't happen
  }
}
