import { computed, defineComponent } from "vue"
import { Btn2, btn2_redEnabledClasses } from "../UserInterface/Btn2"
import { assertNonNull, vReqT } from "src/helpers/utils"
import { PaymentMethod } from "@stripe/stripe-js"
import dayjs from "dayjs"
import { User } from "src/store/User"

export const AchMandateModal = defineComponent({
  props: {
    merchantOfRecord: vReqT<string>(),
    bankAccount: vReqT<PaymentMethod.UsBankAccount>(),
  },
  emits: {
    ok: () => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    return () => {
      assertNonNull(User.userData) // for every render, this must be true
      return (
        <div>
          <div style="display:grid; grid-template-columns: max-content auto; gap: 0 .35em;">
            <div>Agreement Date:</div>
            <div>{dayjs().format("MMMM DD, YYYY")}</div>

            <div style="grid-column:-1/1;" class="border-b"/>
            <div>Account Holder Name:</div>
            <div>{User.userData.firstName} {User.userData.lastName}</div>

            <div style="grid-column:-1/1;" class="border-b"/>
            <div>Financial Institution:</div>
            <div>{props.bankAccount.bank_name}</div>

            <div style="grid-column:-1/1;" class="border-b"/>
            <div>Routing number:</div>
            <div>{props.bankAccount.routing_number}</div>

            <div style="grid-column:-1/1;" class="border-b"/>
            <div>Account number:</div>
            <div>****{props.bankAccount.last4}</div>
          </div>
          <div class="mt-4">
            {AchMandate({acceptLabel: "Accept", merchantOfRecord: props.merchantOfRecord})}
          </div>
          <div class="mt-4 flex items-center gap-2">
            <Btn2 data-test="accept-button" class="px-2 py-1" onClick={() => ctx.emit("ok")}>Accept</Btn2>
            <Btn2 data-test="cancel-button" class="px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("cancel")}>Cancel</Btn2>
          </div>
        </div>
      )
    }
  }
})

/**
 * https://docs.stripe.com/payments/ach-direct-debit#recommended-mandate-text-(online)
 */
export const AchMandate = (args: {acceptLabel: string, merchantOfRecord: string}) => {
  const merchantOfRecordPossessive = args.merchantOfRecord.endsWith("s")
    ? (args.merchantOfRecord + "'")
    : (args.merchantOfRecord + "'s");

  return <div>
    <div>
      {`
        By clicking [${args.acceptLabel}], you authorize ${args.merchantOfRecord} to debit the bank
        account specified above for any amount owed for charges arising from
        your use of ${merchantOfRecordPossessive} services and/or purchase of products from ${args.merchantOfRecord},
        pursuant to ${merchantOfRecordPossessive} website and terms, until this authorization is revoked.
        You may amend or cancel this authorization at any time by providing notice
        to ${args.merchantOfRecord} with 30 (thirty) days notice.
      `}
    </div>
    <div>
      {`
        If you use ${merchantOfRecordPossessive} services or purchase additional products periodically pursuant to
        ${merchantOfRecordPossessive} terms, you authorize ${args.merchantOfRecord} to debit your bank account
        periodically. Payments that fall outside of the regular debits authorized above will only be debited after
        your authorization is obtained.
      `}
    </div>
  </div>
}
