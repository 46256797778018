import { Integerlike, Guid, Datelike, Competition, Division } from "src/interfaces/InleagueApiV1";

import * as t from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value";
import { queryBool, queryDateLike } from "src/boot/TypeBoxSetup"
import { RouteLocationNormalizedLoaded } from "vue-router";
import { computed, ref } from "vue";
import { Reflike, UiOption } from "src/helpers/utils";

export type CommonGameScheduleUrlParts = {
  path: {
    competitionID: Integerlike,
    divID?: Guid,
  },
  query: {
    gamesOnOrAfter?: Datelike,
    includeUnpublished?: "0" | "1",
  }
}

export enum TabID {
  allGames = "allGames",
  userGames = "userGames",
  allRefAssignments = "allRefAssignments",
  userRefAssignments = "userRefAssignments"
}

export function parseQuery_main(v: RouteLocationNormalizedLoaded) {
  const querySchema = t.Object({
    tab: t.Optional(t.Enum(TabID))
  })

  const q = v.query

  if (Value.Check(querySchema, q)) {
    return q;
  }

  return null
}

export function parseQuery_leagueGames(v: RouteLocationNormalizedLoaded) {
  const querySchema = t.Object({
    gamesOnOrAfter: t.Optional(queryDateLike()),
    includeUnpublished: t.Optional(queryBool())
  })

  const q = v.query

  if (Value.Check(querySchema, q)) {
    return q;
  }

  return null
}

export type GameScheduleMenu = {competition: Competition, divisions: Division[]}[]

export function CompDivMenu(menu: Reflike<GameScheduleMenu>) {
  const selectedCompetitionID = ref<Integerlike>(/*definitely assigned in onMounted*/ null as any)
  const selectedDivID = ref<"" | "ALL" | Guid>(/*definitely assigned in onMounted*/ null as any)
  /**
   * Will only contain a competition if a single competition is selected. `null` can mean either "no selection" or "the current selection is ALL"
   */
  const selectedCompetition = ref<Competition | null>(null)
  /**
   * Will only contain a division if a single division is selected. `null` can mean either "no selection" or "the current selection is ALL"
   */
  const selectedDivision = ref<Division | null>(null)

  const competitionSelectOptions = computed(() : UiOption[] => {
    if (menu.value.length === 0) {
      return [{label: "No available options", value: ""}]
    }
    return menu.value.map((comp, i) => {
      return {
        label: comp.competition.competition,
        value: comp.competition.competitionID.toString(),
      }
    })
  })

  const divisionSelectOptions = ref<UiOption[]>([])

  const updateSelectedCompetitionID = async (value: string) : Promise<void> => {
    if (selectedCompetitionID.value /*weakEq*/ == value) {
      return;
    }

    selectedCompetitionID.value = value as any
    selectedCompetition.value = menu.value
      .find((comp) => comp.competition.competitionID /*weakEq*/ == selectedCompetitionID.value)?.competition ?? null

    divisionSelectOptions.value = _noExport_rebuildDivisionSelectOptions()

    if (!divisionSelectOptions.value.find(opt => opt.value === selectedDivID.value)) {
      await updatedSelectedDivID("ALL")
    }
  }

  const updatedSelectedDivID = async (value: string) : Promise<void> => {
    if (selectedDivID.value === value) {
      return;
    }

    selectedDivID.value = value

    if (selectedDivID.value === "ALL") {
      selectedDivision.value = null
    }
    else {
      selectedDivision.value = menu.value
        .find(v => v.competition.competitionUID == selectedCompetition.value?.competitionUID)?.divisions
        .find(v => v.divID === selectedDivID.value)
        ?? null // shouldn't happen
    }
  }

  const _noExport_rebuildDivisionSelectOptions = () : UiOption[] => {
    const divisions = menu.value.find(v => v.competition.competitionUID === selectedCompetition.value?.competitionUID)?.divisions

    if (!divisions) {
      // well, shouldn't happen; if it doesn there's some filtering elsewhere that we should perform
      // (no comp should be an option if it has zero div options)
      return [{label: "No available options", value: ""}]
    }

    return [
      {label: "All", value: "ALL"},
      ...divisions.map(div => {
       return {
          label: div.displayName,
          value: div.divID
        }
      })
    ]
  }

  return {
    get selectedCompetitionID() { return selectedCompetitionID.value },
    get selectedCompetition() { return selectedCompetition.value },
    get selectedDivID() { return selectedDivID.value },
    get selectedDivision() { return selectedDivision.value },
    competitionSelectOptions: competitionSelectOptions,
    divisionSelectOptions: divisionSelectOptions,
    fkDummySink_selectedCompID: computed({
      get() { return selectedCompetitionID.value },
      set(_) { /* do nothing */ }
    }),
    fkDummySink_selectedDivID: computed({
      get() { return selectedDivID.value },
      set(_) { /* do nothing */ }
    }),
    updateSelectedCompetitionID,
    updatedSelectedDivID,
  }
}