import { FormKit } from "@formkit/vue";
import { SoccerBall } from "src/components/SVGs";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { SetEx, arrayFindOrFail, exhaustiveCaseGuard, vReqT } from "src/helpers/utils";
import { getLogger, maybeLog } from "src/modules/LoggerService";
import { LoggedinLogWriter } from "src/modules/Loggers";
import { Client } from "src/store/Client";
import { Ref, computed, defineComponent } from "vue";
import { CommonTeamAssignmentsPlayerListing } from "./CommonTeamAssignmentsPlayerListing";
import { PlayerForTeamAssignmentViewEx, SelectedQuestions, useShouldOfferTeamAssignmentDragAndDrop } from "./TeamAssignments.shared";
import { GetRegistrationQuestionAnswersResponse } from "src/composables/InleagueApiV1.Registration";
import { Guid, RegistrationQuestion } from "src/interfaces/InleagueApiV1";
import { Btn2 } from "src/components/UserInterface/Btn2";

export const PlayerLoanElement = defineComponent({
  props: {
    searchText: vReqT<Ref<string>>(),
    lookupResults: vReqT<ReactiveReifiedPromise<PlayerForTeamAssignmentViewEx[]>>(),
    selectedQuestions: vReqT<SelectedQuestions>(),
    registrationQuestions: vReqT<RegistrationQuestion[]>(),
    registrationAnswers: vReqT<GetRegistrationQuestionAnswersResponse>(),
    seasonName: vReqT<string>(),
    selectedPlayerIDs: vReqT<SetEx<Guid>>(),
  },
  emits: {
    loanPlayers: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true
  },
  setup(props, {emit}) {
    const offerDrag = useShouldOfferTeamAssignmentDragAndDrop();

    const selectedPlayers = computed(() => {
      if (props.lookupResults.underlying.status !== "resolved") {
        return []
      }
      else {
        const players = props.lookupResults.underlying.data
        return [...props.selectedPlayerIDs].map(playerID => arrayFindOrFail(players, p => p.apiData.child.childID === playerID))
      }
    })

    return () => {
      return (
        <div data-test="PlayerLoanElement">
          <div
            class={[`shadow-md rounded-md bg-white`]}
          >
            <div class="p-2 bg-gray-200 rounded-t-md">
              Player Loans
            </div>
            <div class="p-2" style="--fk-margin-outer:none;">
              <div class="mb-2">
                <div class="text-sm font-medium">Player name:</div>
                <FormKit type="text" v-model={props.searchText.value} placeholder="" data-test="playerLoanSearchText"/>
              </div>
              {
                (() => {
                  switch (props.lookupResults.underlying.status) {
                    case "idle":
                      return <div>Search for a player having a registration within {props.seasonName}</div>
                    case "error":
                      // likely 429 (too many requests) here via update-as-you-type (with some debounce)
                      void maybeLog(getLogger(LoggedinLogWriter), "warning", "PlayerLoanElement/search-resolver", props.lookupResults.underlying.error);
                      return <div>Sorry, something went wrong.</div>
                    case "pending":
                      return <div class="flex items-center gap-2">
                        <SoccerBall color={Client.value.clientTheme.color} width="1.35em" height="1.35em" timeForOneRotation="1.25s"/>
                      </div>
                    case "resolved":
                      return <div>
                        {
                          props.lookupResults.underlying.data.length > 0
                            ? (
                              <div class="my-2 text-xs">
                                {
                                  offerDrag.value
                                    ? <div >Select players and click "loan selected players" below (or drag and drop onto target teams) to create player loans.</div>
                                    : <div >Select players and click "loan selected players" below to create player loans.</div>
                                }
                                <div>Players having been loaned will continue to appear here, because they can be loaned to multiple teams.</div>
                              </div>
                            )
                            : null
                        }
                        <CommonTeamAssignmentsPlayerListing
                          selectedPlayerIDs={props.selectedPlayerIDs}
                          players_unfiltered={props.lookupResults.underlying.data}
                          players={props.lookupResults.underlying.data}
                          selectedQuestions={props.selectedQuestions}
                          registrationQuestions={props.registrationQuestions}
                          registrationQuestionAnswers={props.registrationAnswers}
                          busyByPlayerID={new SetEx()}
                          mode={"unassigned-listing/loan"}
                          showTentativeAssignmentInfo={false}
                          team={null as any}
                          showDragGrip={offerDrag.value}
                          filter={null}
                          hasSomeNonEmptyFilters={false}
                        />
                        <Btn2
                          class="mt-2 px-2 py-1 text-left"
                          disabled={props.selectedPlayerIDs.size === 0}
                          onClick={() => emit("loanPlayers", {players: selectedPlayers.value})}
                        >
                          <div>Loan selected players</div>
                        </Btn2>
                      </div>
                    default: exhaustiveCaseGuard(props.lookupResults.underlying);
                  }
                })()
              }
            </div>
          </div>
        </div>
      )
    }
  }
})
