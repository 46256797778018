<template lang="pug">
div(data-test="MasterInvoiceDisplay")
  .mt-6.flex.flex-col
  .-my-2.py-2.overflow-x-auto(class='md:-mx-6 md:px-6 lg:-mx-8 lg:px-8')
    .align-middle.inline-block.min-w-full.shadow.overflow-hidden.border-b.border-gray-200(
      class='sm:rounded-lg'
    )
      table.min-w-full.divide-y.divide-gray-200
        thead
          tr
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Status
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Creator
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Date
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider(v-if="eventID")
              | Master Invoice
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Amount Paid
            th.px-6.py-3.bg-gray-50.text-left
              | Invoice Balance
        //-
        //- An invoice is 1 or more line items, but we don't loop over them?
        //- This is just to support the (currently) more common case of 1 line item per invoice?
        //- Or does this just show "the invoice" and not its line items, and make an assumption that
        //- whatever the entity type of lineItem[0] is, is the entity type of the whole invoice (which is sort of true now,
        //- though breaks down in the face of an invoice for a compReg w/ a donation, and eventually probably a compReg w/ multiple event signups, or etc.)
        //-
        tbody.bg-white.divide-y.divide-gray-200
          tr
            td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.font-medium.text-gray-900(data-test="invoiceStatus")
              span(v-if="invoice.lastStatus === LastStatus_t.PROCESSING_ACH") Payment processing
              span(v-else) {{ invoice.status.slice(0, 1).toUpperCase() }}{{ invoice.status.slice(1) }}
            td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.font-medium.text-gray-900
              | {{ invoice.submitterFirstName }} {{ invoice.submitterLastName }}
            td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500
              | {{ formatDateWithoutTime(invoice.dateCreated) }}
            td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500(v-if="eventID")
              router-link(
                :to='{ name: "event-signup", params: { eventID: eventID } }'
              )
                | Event Details
                | {{ invoice.master }}
            td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500
              | {{ invoice.transactionBalance ? `$${invoice.transactionBalance}` : `$0.00` }}
            td.px-6.py-4.whitespace-nowrap.text-sm.leading-5.font-medium
              | ${{ invoice.transactionBalance ? (invoice.lineItemSum - invoice.transactionBalance).toFixed(2) : invoice.lineItemSum }}
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { useRoute } from 'vue-router'


import { Invoice, LastStatus_t, LineItemSpecialization } from 'src/interfaces/Store/checkout'
import { formatDateWithoutTime } from 'src/helpers/formatDate'

import * as iltypes from "src/interfaces/InleagueApiV1"
import { CheckoutStore } from "src/store/CheckoutStore"
import { forceCheckedIndexedAccess } from 'src/helpers/utils'

export default defineComponent({
  name: 'MasterInvoiceDisplay',
  props: {
    invoice: {
      required: true,
      type: Object as PropType<Invoice>
    },
  },
  setup(props) {
    const route = useRoute()

    const eventID = computed(() => {
      const lineItem = forceCheckedIndexedAccess(props.invoice.lineItems, 0)

      if (lineItem?.entity_type !== "qEventSignup") {
        // n.b. we have a hardcoded assumption here that the first line item is a qEventSignup, which seems probably buggy.
        return ""
      }

      if (lineItem) {
        return eventIdFromEventSignupID(lineItem.eventSignupID)
      }
      else {
        return "";
      }

      function eventIdFromEventSignupID(eventSignupID: iltypes.Guid) {
        // shouldn't ever end up in the falsy case here, it is a bug if either
        //  - the caller provides an invalid eventSignupID, or
        //  - the caller provides a valid eventSignupID but we don't have it on file
        //
        // TODO: This makes strong assumptions about where and when and how the component is used
        // that are not reflected in its external interface. We assume the checkoutStore DEFINITELY HAS this?
        // And no provision to load it if it we don't? What proof do we have that this is true? Anyway, if we need this,
        // (and it should be available on the lineItem.entity?...) then just load it.
        //
        return eventSignupID in CheckoutStore.value.registrants
          ? (CheckoutStore.value.registrants[eventSignupID]?.eventID || "")
          : "";
      }
    });

    return {
      route,
      formatDateWithoutTime,
      eventID,
      LastStatus_t: LastStatus_t
    }
  },
})
</script>
