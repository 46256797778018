
import { computed, defineComponent, ExtractPropTypes, getCurrentInstance, onMounted, PropType, ref, watch, reactive } from "vue";
import { exhaustiveCaseGuard, ExtractOnEmitsHandlers, useIziToast, nextGlobalIntlike, parseIntOr, Writeable } from "src/helpers/utils";
import { datePickerFormat } from "src/helpers/formatDate";

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { EventSelector, SeasonGroupedCompetitionSelector, UserOrPlayerLinkOption, UserOrPlayerLinkSelector } from "./CouponEditor";

import { AxiosErrorWrapper, axiosAuthBackgroundInstance, axiosInstance } from "src/boot/axios";
import { useRouter } from "vue-router";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { RouteDetail, propsDef } from "./R_CouponEditor.route"
import * as R_CouponManager from "./R_CouponManager.route";
import authService from "src/helpers/authService";


import { CouponEditorImpl, CouponFormData, CouponFormDataEdit, CouponFormDataNew, ExpandedCoupon } from "./CouponEditorImpl"

import { Modal, Slots as ModalSlots } from "src/components/UserInterface/Modal"
import { SoccerBall } from "../SVGs";
import { User } from "src/store/User";
import { Client } from "src/store/Client";

const deleteCouponModalPropsDef = {
  isOpen: {
    required: true,
    type: Boolean
  },
  deleteInFlight: {
    required: true,
    type: Boolean,
  },
  /**
   * Though this value can _be_ null, if it is null, `isOpen` should not be true
   */
  coupon: {
    required: true,
    type: Object as PropType<ExpandedCoupon | null>
  }
} as const;

const deleteCouponModalEmitsDef = {
  doDelete: (_: ExpandedCoupon) => true,
  cancel: () => true,
  close: () => true,
} as const;

interface DeleteCouponModal {
  Props: ExtractPropTypes<typeof deleteCouponModalPropsDef>,
  Emits: ExtractOnEmitsHandlers<typeof deleteCouponModalEmitsDef>
}

const DeleteCouponModal = defineComponent({
  props: deleteCouponModalPropsDef,
  emits: deleteCouponModalEmitsDef,
  setup(props, {emit}) {

    return () => (
      <Modal isOpen={props.isOpen} onClose={() => emit("close")} data-test="DeleteCouponModal">
        {{
          title: () => <div>Delete coupon {props.coupon!.couponCode}?</div>,
          content: () => (
            <div>
              <div class="mt-2">This cannot be undone.</div>
              <div class="mt-2 flex items-center">
                <div class={`${props.deleteInFlight ? "" : "invisible"}`}>
                  <SoccerBall color={Client.value.clientTheme.color} width=".375in" height=".375in" timeForOneRotation="1.25s"/>
                </div>
                <div class="flex gap-8 items-center justify-end ml-auto">
                  <t-btn data-test="yes" class={props.deleteInFlight ? "bg-gray-300" : ""} disable={props.deleteInFlight} margin={false} color="green" onClick={() => emit("doDelete", props.coupon!)}>Yes, delete</t-btn>
                  <t-btn data-test="no" class={props.deleteInFlight ? "bg-gray-300" : ""} disable={props.deleteInFlight} margin={false} color="red" onClick={() => emit("cancel")}>No</t-btn>
                </div>
              </div>
            </div>
          )
        } as ModalSlots}
      </Modal>
    )
  }
})

export default defineComponent({
  components: {
    CouponEditorImpl
  },
  props: propsDef,
  setup(props) {
    const ready = ref(false);
    const iziToast = useIziToast();
    const router = useRouter();


    const formData = ref<CouponFormData & {__vueKey: string}>(/* definitely assigned in onMounted */ null as any);

    const rebindFormProps = async () => {
      const freshFormData = await freshFormDataAsPerRoute(props.detail);
      formData.value = freshFormData;
    }

    const relevantUserPermissions = computed(() => {
      return {
        isRegistrar: authService(User.value.roles, "registrar")
      }
    });

    watch(() => props.detail, async () => {
      await rebindFormProps();
    }, {deep: true})

    onMounted(async () => {
      await rebindFormProps();
      ready.value = true;
    })

    const deleteCouponModalController = (() => {
      const props_ : Writeable<DeleteCouponModal["Props"]> = reactive({
        isOpen: false,
        deleteInFlight: false,
        coupon: computed(() => formData.value.type === "new" ? null : formData.value.underlyingCoupon)
      });

      const handlers : DeleteCouponModal["Emits"] = {
        onCancel: () => handlers.onClose(),
        onClose: () => {
          if (props_.deleteInFlight) {
            // don't allow leaving the modal if the delete request is in flight
            // worst case is we spin forever, but that's probably a bug on our part.
            // Generally should hit a timeout or error first.
            return;
          }
          else {
            props_.isOpen = false
          }
        },
        onDoDelete: async coupon => {
          try {
            props_.deleteInFlight = true;
            await ilapi.coupon.deleteCoupon(axiosAuthBackgroundInstance, {couponID: coupon.couponID})
            iziToast.success({message: "Coupon deleted."})
            await router.push(R_CouponManager.routeDetailToRoutePath({name: "coupon-manager.main"}));
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err)
          }
          finally {
            props_.deleteInFlight = false;
          }
        }
      }

      return {
        props: props_,
        handlers,
        show: () : void => { props_.isOpen = true },
      }
    })();

    const hasDeletePermission = computed(() => formData.value.type === "new" ? false : formData.value.underlyingCoupon.hasDeletePermission)

    return () => {
      if (!ready.value) {
        return <div></div>
      }
      return (
        <div data-test="R_CouponEditor">
          <DeleteCouponModal {...{...deleteCouponModalController.props, ...deleteCouponModalController.handlers}}/>
          {
            <>
              <h2 class="flex items-center mb-2">
                <FontAwesomeIcon icon={["fas", "tags"]} />
                <span class="ml-2">
                  {
                    props.detail.name === "coupon-editor.new"
                      ? "New coupon"
                      : "Edit coupon"
                  }
                </span>
              </h2>
              <div>
                {
                  formData.value.type === "new"
                    ? null
                    : formData.value.underlyingCoupon.definiteRedemptionCount === 0 && formData.value.underlyingCoupon.pendingRedemptionCount === 0
                    ? (
                      <div class="mb-2">
                        <t-btn data-test="delete" onClick={() => hasDeletePermission.value ? deleteCouponModalController.show() : void 0}
                          margin={false} class={`text-xs ${hasDeletePermission.value ? "" : "bg-gray-300"}`} disable={!hasDeletePermission.value}
                        >
                          <FontAwesomeIcon icon={"trash-alt"}/>
                          <span class="ml-2">Delete</span>
                        </t-btn>
                        {
                          !hasDeletePermission.value
                            // should be a tooltip, but need to figure out conditionally applying a v-tooltip directive
                            ? <div class="text-xs">This coupon can is deletable, but you don't have the right permissions.</div>
                            : null
                        }
                      </div>
                    )
                    : (() => {
                      const redemptionPlural = (n: number) => n === 1 ? "redemption" : "redemptions"
                      const pending = formData.value.underlyingCoupon.pendingRedemptionCount
                      const definite = formData.value.underlyingCoupon.definiteRedemptionCount
                      const redemptions = {
                        pending: `${pending} pending ${redemptionPlural(pending)}`,
                        definite: `${definite} definite ${redemptionPlural(definite)}`
                      } as const;
                      return (
                        <div class="mb-2">
                          <div>{formData.value.underlyingCoupon.couponCode}</div>
                          <div class="text-xs">This coupon has {redemptions.pending} and {redemptions.definite}, so some fields cannot be edited.</div>
                        </div>
                      )
                    })()
                }
              </div>
            </>
          }
          <CouponEditorImpl
            key={formData.value.__vueKey}
            formData={formData.value}
            relevantUserPermissions={relevantUserPermissions.value}
            onDoSubmit={async () => {
              switch (formData.value.type) {
                case "new": {
                  try {
                    const data = formDataToApiCreateCouponArgs(formData.value);
                    await ilapi.coupon.createCoupon(axiosInstance, data);
                    iziToast.success({message: "Coupon created."});
                    await router.push(R_CouponManager.routeDetailToRoutePath({name: "coupon-manager.main"}));
                  }
                  catch (err) {
                    AxiosErrorWrapper.rethrowIfNotAxiosError(err);
                  }
                  return;
                }
                case "edit": {
                  try {
                    const data = formDataToApiUpdateCouponArgs(formData.value);
                    await ilapi.coupon.updateCoupon(axiosInstance, data);
                    iziToast.success({message: "Coupon updated."});
                    await router.push(R_CouponManager.routeDetailToRoutePath({name: "coupon-manager.main"}));
                  }
                  catch (err) {
                    AxiosErrorWrapper.rethrowIfNotAxiosError(err);
                  }
                  return;
                }
                default: exhaustiveCaseGuard(formData.value);
              }
            }}
          />
        </div>
      )
    }
  }
})

async function freshFormDataAsPerRoute(v: RouteDetail) : Promise<CouponFormData & {__vueKey: string}> {
  switch (v.name) {
    case "coupon-editor.new": {
      const entityLinkageCandidates = await ilapi.coupon.getCouponEntityLinkageCandidates(axiosInstance);
      return {...freshFormData(entityLinkageCandidates), __vueKey: nextGlobalIntlike()};
    }
    case "coupon-editor.edit": {
      const entityLinkageCandidates = await ilapi.coupon.getCouponEntityLinkageCandidates(axiosInstance);
      //
      // We repeat the full type definition in the trailing assertion in order to get a type error when we change the definition of ExpandedCoupon
      //
      const coupon : ExpandedCoupon = await ilapi
        .coupon
        .getCoupon(
          axiosInstance, {
            couponID: v.couponID,
            expand: ["hasDeletePermission", "couponChildLinks", "couponUserLinks", "couponCompetitionSeasonLinks", "couponEventLinks", "definiteRedemptionCount", "pendingRedemptionCount"]
          }
        ) as iltypes.WithDefinite<ilapi.coupon.Coupon, "hasDeletePermission" | "couponChildLinks" | "couponUserLinks" | "couponCompetitionSeasonLinks" | "couponEventLinks" | "definiteRedemptionCount" | "pendingRedemptionCount">
        return {...freshFormDataFromExisting(coupon, entityLinkageCandidates), __vueKey: nextGlobalIntlike()};
    }
    default:
      exhaustiveCaseGuard(v);
  }
}

function formDataToApiCreateCouponArgs(form: CouponFormDataNew) : ilapi.coupon.CreateCouponArgs {
  if (form.discount.type === "") {
    throw "form.discount.type must not be `\"\"` here";
  }

  return {
    appliesTo_wildcard: form.appliesTo_wildcard,
    appliesTo_compSeason_competitionRegistration: form.appliesTo_compSeason_competitionRegistration,
    appliesTo_compSeason_tournamentTeamRegistration: form.appliesTo_compSeason_tournamentTeamRegistration,
    couponCode: form.couponCode,
    couponQuantity: form.couponQuantity.type === "unlimited" ? "" : form.couponQuantity.value,
    expiration_date: form.expiration_date.type === "unlimited" ? "" : form.expiration_date.value,
    entityTargets: {
      events: form.entityTargets.events.effectivelySelected(),
      competitionSeasons: form.entityTargets.competitionSeasons.effectivelySelected(),
      children: form.entityTargets.children.effectivelySelected(),
      users: form.entityTargets.users.effectivelySelected(),
    },
    discount: form.discount
  }
}

function formDataToApiUpdateCouponArgs(form: CouponFormDataEdit) : ilapi.coupon.UpdateCouponArgs {
  if (form.discount.type === "") {
    throw "We never expect an uninitialized `form.discount.type` here.";
  }

  return {
    couponID: form.underlyingCoupon.couponID,
    couponCode: form.couponCode,
    couponQuantity: form.couponQuantity.type === "unlimited" ? "" : form.couponQuantity.value,
    discount: form.discount,
    expiration_date: form.expiration_date.type === "unlimited" ? "" : form.expiration_date.value,
    appliesTo_compSeason_competitionRegistration: form.appliesTo_compSeason_competitionRegistration,
    appliesTo_compSeason_tournamentTeamRegistration: form.appliesTo_compSeason_tournamentTeamRegistration,
    entityTargets: {
      events: form.entityTargets.events.effectivelySelected(),
      competitionSeasons: form.entityTargets.competitionSeasons.effectivelySelected(),
      children: form.entityTargets.children.effectivelySelected(),
      users: form.entityTargets.users.effectivelySelected(),
    },
  }
}

const UserOrPlayerLinkSearchers = {
  findUsers: async (query: string) : Promise<UserOrPlayerLinkOption[]> => {
    return (await ilapi.findVolunteers(axiosInstance, {search: query})).map(v => ({
      type: "user",
      id: v.ID,
      firstName: v.firstName,
      lastName: v.lastName,
      displayName: `${v.firstName} ${v.lastName}`,
      mutable: true,
    }))
  },
  findPlayers: async (query: string) : Promise<UserOrPlayerLinkOption[]> => {
    return (await ilapi.findPlayers(axiosInstance, {search: query})).map(v => ({
      type: "player",
      id: v.childID,
      firstName: v.playerFirstName,
      lastName: v.playerLastName,
      displayName: `${v.playerFirstName} ${v.playerLastName}`,
      mutable: true,
    }))
  }
} as const;

function freshFormData(entityLinkageCandidates: ilapi.coupon.CouponEntityLinkageCandidates) : CouponFormData {
  return {
    type: "new",
    appliesTo_wildcard: false,
    appliesTo_compSeason_competitionRegistration: true,
    appliesTo_compSeason_tournamentTeamRegistration: false,
    couponCode: "",
    couponQuantity: {type: "int", value: ""},
    expiration_date: {type: "date", value: ""},
    entityTargets: {
      events: EventSelector(null, entityLinkageCandidates.events),
      competitionSeasons: SeasonGroupedCompetitionSelector(null, entityLinkageCandidates.competitionSeasons),
      children: UserOrPlayerLinkSelector(UserOrPlayerLinkSearchers.findPlayers, []),
      users: UserOrPlayerLinkSelector(UserOrPlayerLinkSearchers.findUsers, [])
    },
    discount: {
      type: "",
    }
  }
}

function freshFormDataFromExisting(coupon: ExpandedCoupon, entityLinkageCandidates: ilapi.coupon.CouponEntityLinkageCandidates) : CouponFormData {
  const mungedChildLinks : UserOrPlayerLinkOption[] = coupon.couponChildLinks.map(link => {
    return {
      type: "player",
      id: link.childID,
      firstName: link.playerFirstName,
      lastName: link.playerLastName,
      displayName: `${link.playerFirstName} ${link.playerLastName}`,
      mutable: link.redemptionInfo.length === 0
    }
  })
  const mungedUserLinks : UserOrPlayerLinkOption[] = coupon.couponUserLinks.map(link => {
    return {
      type: "user",
      id: link.userID,
      firstName: link.firstName,
      lastName: link.lastName,
      displayName: `${link.firstName} ${link.lastName}`,
      mutable: link.redemptionInfo.length === 0
    }
  })
  return {
    type: "edit",
    appliesTo_wildcard: coupon.appliesTo_wildcard,
    appliesTo_compSeason_competitionRegistration: coupon.appliesTo_compSeason_competitionRegistration,
    appliesTo_compSeason_tournamentTeamRegistration: coupon.appliesTo_compSeason_tournamentTeamRegistration,
    underlyingCoupon: coupon,
    couponCode: coupon.couponCode,
    couponQuantity: coupon.couponQuantity,
    expiration_date: coupon.expiration_date.type === "date" ? {type: "date", value: datePickerFormat(coupon.expiration_date.value)} : {type: "unlimited"},
    entityTargets: {
      events: EventSelector(coupon.couponEventLinks, entityLinkageCandidates.events),
      competitionSeasons: SeasonGroupedCompetitionSelector(coupon.couponCompetitionSeasonLinks, entityLinkageCandidates.competitionSeasons),
      children: UserOrPlayerLinkSelector(UserOrPlayerLinkSearchers.findPlayers, mungedChildLinks),
      users: UserOrPlayerLinkSelector(UserOrPlayerLinkSearchers.findUsers, mungedUserLinks),
    },
    discount: coupon.discount
  }
}