import { assertTruthy, dollarFormat, FK_nodeRef, FK_validation_strlen, parseFloatOr, useIziToast, vReqT } from "src/helpers/utils"
import { Child, Guid } from "src/interfaces/InleagueApiV1"
import { computed, defineComponent, onMounted, ref } from "vue"
import { createInvoiceInstancesForPlayerPayors, findInvoicePayorCandidates, getInvoiceTemplate, InvoiceInstanceForInvoiceTemplate, InvoiceTemplate, listInvoiceInstancesForInvoiceTemplate } from "./InvoiceTemplates.io"
import { axiosInstance } from "src/boot/AxiosInstances"
import { FormKit, FormKitMessages } from "@formkit/vue"
import { Btn2 } from "../UserInterface/Btn2"
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper"
import { ReactiveReifiedPromise, RP_Resolved } from "src/helpers/ReifiedPromise"
import { freshSortState } from "src/modules/TableUtils"
import { BasicAutoTable2 } from "src/modules/BasicAutoTable"
import { DiscountConfig, freshInvoiceTemplateRecipientForm, InvoiceTemplateRecipientFormElem } from "./InvoiceTemplatePayors.elems"
import { InvoiceColDefs, PayorCandidatesColDefs } from "./InvoiceTemplatePayors.elems"
import * as R_InvoiceTemplateListing from "./InvoiceTemplateListing.route"
import { RouterLink } from "vue-router"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

export default defineComponent({
  props: {
    invoiceTemplateID: vReqT<Guid>(),
  },
  setup(props) {
    const iziToast = useIziToast()
    const invoiceTemplate = ref<InvoiceTemplate>(/*definitely assigned in onMounted*/ null as any)
    const payorCandidates = ReactiveReifiedPromise<{search: string, searchResults: Child[]}>()
    const currentInvoiceInstances = ref<InvoiceInstanceForInvoiceTemplate[]>([])
    const existingPayorIDs = computed(() => new RP_Resolved(new Set(currentInvoiceInstances.value.map(v => v.entity.entityID))))
    const form = ref(freshInvoiceTemplateRecipientForm("singlePlayer"))

    const payorTableConfig = (() => {
      const colDefs = PayorCandidatesColDefs("singlePlayer", {get value() { return form.value }}, existingPayorIDs)
      const sortState = freshSortState(colDefs)
      sortState.reconfigure([{colID: "name", dir: "asc"}])
      return {colDefs, sortState}
    })();

    const currentInvoiceInstancesTableConfig = (() => {
      const colDefs = InvoiceColDefs()
      const sortState = freshSortState(colDefs)
      return {colDefs, sortState}
    })()

    const ready = ref(false)

    const playerSearch = ref({
      playerName: "",
      restrictToInvoiceSeason: true,
    })

    onMounted(async () => {
      invoiceTemplate.value = await getInvoiceTemplate(axiosInstance, {invoiceTemplateID: props.invoiceTemplateID})
      currentInvoiceInstances.value = await listInvoiceInstancesForInvoiceTemplate(axiosInstance, {invoiceTemplateID: props.invoiceTemplateID})
      discountConfig.value = {
        allowDollar: invoiceTemplate.value.paymentMethods.length === 0
          ? {ok: true}
          : {ok: false, why: "This invoice template has recurring payment plans. Only percentage discounts are supported."},
        allowPercentage: {ok: true},
      }
      ready.value = true;
    })

    const discountConfig = ref<DiscountConfig>({
      allowDollar: {ok: true},
      allowPercentage: {ok: true}
    })

    const doFindPayorCandidates = async () : Promise<void> => {
      try {
        payorCandidates.run(async () => {
          const data = {
            search: playerSearch.value.playerName,
            searchResults: await findInvoicePayorCandidates(axiosInstance, {
              invoiceTemplateID: invoiceTemplate.value.invoiceID,
              playerName: playerSearch.value.playerName,
              registeredOnly: playerSearch.value.restrictToInvoiceSeason
            })
          };

          if (!data.searchResults.find(row => form.value.childIDs.has(row.childID))) {
            form.value.childIDs.clear()
          }

          return data
        })
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const doApplyInvoiceToSelectedPlayer = async () : Promise<void> => {
      try {
        const childID = (() => {
          // our form here is expected to only support selecting one at a time
          const vs = [...form.value.childIDs]
          assertTruthy(vs.length === 1)
          return vs[0]
        })()
        const freshInvoices = await createInvoiceInstancesForPlayerPayors(axiosInstance, {
          invoiceTemplateID: props.invoiceTemplateID,
          childIDs: [childID],
          dollarDiscount: form.value.discount?.type === "dollar" ? parseFloatOr(form.value.discount.value, undefined) : undefined,
          percentDiscount: form.value.discount?.type === "percent" ? parseFloatOr(form.value.discount.value, undefined) : undefined,
          instanceDesc: form.value.instanceDesc,
          emailOnCreation: form.value.emailOnCreation,
        })
        form.value = freshInvoiceTemplateRecipientForm("singlePlayer")
        currentInvoiceInstances.value.unshift(...freshInvoices)
        iziToast.success({message: "Invoice created."})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const fkAddPayorFormRef = FK_nodeRef()

    return () => {
      if (!ready.value) {
        return null
      }

      return <div style="--fk-bg-input: white; --fk-margin-outer:none; --fk-margin-decorator: none; --fk-padding-input:.35em; max-width:1400px;">
        <div class="flex items-center gap-2">
          <RouterLink to={R_InvoiceTemplateListing.route({seasonUID: invoiceTemplate.value.season.seasonUID})} class="inline-block mb-4">
            <Btn2 class="px-2 py-1 flex gap-1 items-center text-sm">
              <FontAwesomeIcon icon={faArrowLeft}/>
              <p>Back to Invoice Templates Home</p>
            </Btn2>
          </RouterLink>
        </div>
        <h2 class="mb-4">{invoiceTemplate.value.invoiceLabel} (${dollarFormat(invoiceTemplate.value.amount)}) Recipients</h2>
        <div class="bg-white p-2 rounded-md border max-w-2xl">
          <FormKit type="form" actions={false} onSubmit={() => { doFindPayorCandidates() }}>
            <div style="display:grid; grid-template-columns: max-content auto; align-items: center; grid-gap: .25em .675em;">
              <div>Player Name:</div>
              <FormKit
                type="text"
                v-model={playerSearch.value.playerName}
                validation={[["required"], FK_validation_strlen(3, 9999)]}
                validationLabel="Player Name"
                data-test="playerName-input"
              />
              <Btn2 type="submit" data-test="playerSearch-submit" class="px-2 py-1 max-w-24">Search</Btn2>
              <label class="flex gap-2 items-center">
                <FormKit type="checkbox" v-model={playerSearch.value.restrictToInvoiceSeason} data-test="restrictToInvoiceSeason"/>
                <span class="text-sm">Restrict search to registered {invoiceTemplate.value.season.seasonName} players</span>
              </label>
            </div>
          </FormKit>
        </div>
        <FormKit type="form" ref={fkAddPayorFormRef} actions={false} onSubmit={() => doApplyInvoiceToSelectedPlayer()} key={form.value.__vueKey}>
          {payorCandidates.underlying.status === "pending"
            ? <div>Loading...</div>
            : payorCandidates.underlying.status === "resolved"
            ? <div>
              <div class="mt-4 border rounded-md p-2 bg-white">
                <BasicAutoTable2
                  colDefs={payorTableConfig.colDefs}
                  sortState={payorTableConfig.sortState}
                  rows={payorCandidates.underlying.data.searchResults}
                  rowKey={(row : (typeof payorCandidates.underlying.data.searchResults)[number]) => row.childID}
                  rowAttrs={(row : (typeof payorCandidates.underlying.data.searchResults)[number]) => ({
                    "data-test": row.childID,
                  })}
                  noData={() => {
                    return payorCandidates.underlying.status === "resolved"
                      ? <div class="p-2">No players found matching '{payorCandidates.underlying.data.search}'</div>
                      : null
                  }}
                />
              </div>
            </div>
            : null
          }

          {payorCandidates.underlying.status === "resolved" && payorCandidates.underlying.data.searchResults.length > 0
            ? <div class="mt-4 p-2 bg-white rounded-md border">
              <InvoiceTemplateRecipientFormElem
                form={form.value}
                discountConfig={discountConfig.value}
              />
              <FormKitMessages node={fkAddPayorFormRef.value?.node}/>
            </div>
            : null
          }
          <div class="mt-4 bg-white p-2 rounded-md border" style="width: max-content;">
            <div>
              Existing Payors
            </div>
            <BasicAutoTable2
              paginationOptions={[50, "ALL"]}
              colDefs={currentInvoiceInstancesTableConfig.colDefs}
              sortState={currentInvoiceInstancesTableConfig.sortState}
              rows={currentInvoiceInstances.value}
              rowKey={(row : (typeof currentInvoiceInstances.value)[number]) => row.invoiceInstanceID.toString()}
              rowAttrs={(row : (typeof currentInvoiceInstances.value)[number]) => ({
                "data-test": row.invoiceInstanceID,
                "data-entityID": row.entity.entityID
              })}
              noData={() => <div>No current payors.</div>}
            />
          </div>
        </FormKit>
      </div>
    }
  }
})
