import { computed, defineComponent, onMounted, reactive, ref, shallowRef, watch } from "vue";

import * as ilteam from "src/composables/InleagueApiV1.Teams"
import { EditTeamSeasonFormElement, TeamSeasonForm } from "./TeamSeason.editTeamSeason"
import { ContactInfoListingTable } from "./ContactListing"
import { MedicalReleaseListingTable } from "./MedicalReleaseListing"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { PlayerRosterSort, RouteName, propsDef, queryParams, routeDetailToRouteLocation } from "./R_TeamSeason.route";
import { EditTeamSeasonPlayersElement, PlayerAssignmentForm, PlayerLoanForm, TeamSeasonPlayersForm } from "./TeamSeason.editPlayers";
import { axiosInstance } from "src/boot/AxiosInstances";

import { accentAwareCaseInsensitiveCompare, arrayFindOrFail, assertNonNull, exhaustiveCaseGuard, isGuid, nonThrowingExhaustiveCaseGuard, parseIntOr, routerGetQueryParamAsStringArrayOrNull, routerGetQueryParamAsStringOrNull, sortBy, sortByMany, useAsyncState, useIziToast, vReqT } from "src/helpers/utils";
import { RouteLocationRaw, RouterLink, useRouter } from "vue-router";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { Client } from "src/store/Client";
import { __error__mungeBlobErrorToJson, defaultDoHandlePossibleAxiosError, defaultLoggedInErrorResponseHandler, freshNoToastLoggedInAxiosInstance } from "src/boot/axios";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import axios from "axios";
import { faArrowCircleRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { TeamChooserMenu, getTeamChooserMenu } from "src/composables/InleagueApiV1.TeamChooser2";
import { BasicTeamChooserSelectionManager, CompleteTeamChooserSelection, CompleteTeamChooserSelectionSingular, getTeamChooserMenuNode, longestValidMenuPath, pruneIncompleteMenuPaths, teamChooserSelection } from "../UserInterface/TeamChooser/TeamChooserUtils";
import { Guid, Team, TeamSeason } from "src/interfaces/InleagueApiV1";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { isInleagueApiError2 } from "src/composables/InleagueApiV1";
import iziToast from "izitoast";
import { SoccerBall } from "../SVGs";
import { buildLegacyLink } from 'src/boot/auth'
import { PictureDayRosterElement } from "./PictureDayRoster";
import { withNoScroll } from "src/router/RouterScrollBehavior";
import { TeamPlayerCoachSummaryElement } from "./TeamPlayerCoachSummary";
import { useTeamSelectionButtonsPaneSizeWatcher, TeamSelectionButtons } from "../UserInterface/TeamChooser/TeamChooserSelectButtons";
import { Tabs } from "src/components/UserInterface/Tabs";
import { RosterExcludedPlayerIDs, RosterExcludedPlayerIDs_initFromLocalStorage } from "./RosterExcludedPlayerIDs";
import { ExcludablePlayersElement } from "./ExcludablePlayers";
import { User } from "src/store/User";
import { Btn2 } from "../UserInterface/Btn2";
import { AutoModal, DefaultModalController } from "../UserInterface/Modal";
import { LineupCardsModal } from "./LineupCardsModal";
import { EmbargoDateInfo, getRoster_embargoDates } from "src/composables/InleagueApiV1.Teams";
import dayjs from "dayjs";
import { DAYJS_FORMAT_IL_FULL_1 } from "src/helpers/formatDate";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";

export default defineComponent({
  props: propsDef,
  setup(props) {
    const router = useRouter();

    interface AsyncState {
      menu: TeamChooserMenu,
      selectionManager: BasicTeamChooserSelectionManager
    }
    const asyncState = useAsyncState<AsyncState>()
    const menuSelection = ref(teamChooserSelection());
    const sortOrder = ref<PlayerRosterSort>("uniform")
    const rosterExcludedPlayerIDs = shallowRef<RosterExcludedPlayerIDs | null>(null)

    const completeSelectionOrNull = computed<CompleteTeamChooserSelection | null>(() => {
      const {seasonUID, competitionUID, divID, teamIDs} = menuSelection.value
      if (seasonUID && competitionUID && divID && teamIDs && teamIDs.length) {
        return {seasonUID, competitionUID, divID, teamIDs}
      }
      else {
        return null;
      }
    });

    const embargoInfoResolver = ReactiveReifiedPromise<EmbargoDateInfo>()
    watch(() => completeSelectionOrNull.value, () => {
      if (!completeSelectionOrNull.value) {
        embargoInfoResolver.reset()
        return
      }

      const {seasonUID, competitionUID, divID} = completeSelectionOrNull.value

      const silentAxios = freshNoToastLoggedInAxiosInstance()
      embargoInfoResolver.run(() => getRoster_embargoDates(silentAxios, {seasonUID, competitionUID, divID}))
    })

    watch(() => completeSelectionOrNull.value, () => {
      if (completeSelectionOrNull.value) {
        // Conceptually there are 4 cases here:
        // - do a "full load" from local storage during the first time a full selection is completed, or when a value comprising the PK (season/comp/div) changes
        // - load things from local storage, but drop players that are for (season,comp,div,team) where season/comp/div didn't change but team is no longer part of the selection
        // - load things from local storage where local storage exactly equals the current selection, so it's basically a no-op
        // - find nothing per the PK of (season,comp,div) and return an empty default initalized value
        rosterExcludedPlayerIDs.value = RosterExcludedPlayerIDs_initFromLocalStorage({userID: User.value.userID, ...completeSelectionOrNull.value})
      }
    }, {deep: true})

    const doUpdateTeamSeason = async (args: ilteam.UpdateTeamSeasonArgs) : Promise<{ok: boolean}> => {
      try {
        await ilteam.updateTeamSeason(axiosInstance, args)
        return {ok: true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }

    const doUpdateTeamSeasonPlayers = async (args: ilteam.UpdateTeamSeasonPlayersArgs) : Promise<{ok: boolean}> => {
      try {
        await ilteam.updateTeamSeasonPlayers(axiosInstance, args)
        return {ok: true}
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        return {ok: false}
      }
    }

    /**
     * The `TeamSeason.config` route can only have 1 team selected at a time, so just choose the first of the current selection.
     * We want to keep "the first", but the selection input tracks by either order of click or some other arbitrary ordering,
     * whereas we want the "visually first" so there is some work to find "the visually first selected teamID"
     */
    watch(() => props.detail.routeName, () => {
      if (!asyncState.value.ready) {
        return
      }

      if (props.detail.routeName === "TeamSeason.config") {
        if (menuSelection.value.teamIDs && menuSelection.value.teamIDs.length > 1) {
          const truncatedCompleteSelection = (() => {
            if (menuSelection.value.seasonUID && menuSelection.value.competitionUID && menuSelection.value.divID) {
              return {
                seasonUID: menuSelection.value.seasonUID,
                competitionUID: menuSelection.value.competitionUID,
                divID: menuSelection.value.divID,
              }
            }
            else {
              return null
            }
          })()

          if (!truncatedCompleteSelection) {
            // shouldn't happen
            return;
          }

          const divNode = getTeamChooserMenuNode(asyncState.value.menu, "division", truncatedCompleteSelection);

          if (!divNode) {
            // shouldn't happen
            return
          }

          const selectedTeamIDs = new Set(menuSelection.value.teamIDs)
          const firstOrderedTeamID = arrayFindOrFail([...divNode.orderedChildren.keys()], teamID => selectedTeamIDs.has(teamID))
          menuSelection.value.teamIDs = [firstOrderedTeamID]
        }
      }
    })

    onMounted(async () => {
      const menu = pruneIncompleteMenuPaths(await getTeamChooserMenu(axiosInstance, "team-rosters"))

      menuSelection.value = longestValidMenuPath(menu, {
        seasonUID: routerGetQueryParamAsStringOrNull(router.currentRoute.value, queryParams.seasonUID),
        competitionUID: routerGetQueryParamAsStringOrNull(router.currentRoute.value, queryParams.competitionUID),
        divID: routerGetQueryParamAsStringOrNull(router.currentRoute.value, queryParams.divID),
        teamIDs: (() => {
          const teamIDs = routerGetQueryParamAsStringArrayOrNull(router.currentRoute.value, queryParams.teamIDs)?.filter(s => isGuid(s))
          return !teamIDs
            ? null
            : teamIDs.length === 0
            ? null
            : teamIDs;
        })()
      })

      asyncState.value = {
        ready: true,
        menu,
        selectionManager: BasicTeamChooserSelectionManager({mut_selection: menuSelection, menu})
      }
    })

    const {openAbsolutePosPanes, rootRef} = useTeamSelectionButtonsPaneSizeWatcher()

    /**
     * season/comp/div is always "select one", but teams can be either select one or select many, depending on route
     */
    const menuTeamSelectMode = computed<"single" | "multi">(() => {
      switch (props.detail.routeName) {
        case "TeamSeason.config": return "single"
        case "TeamSeason.rosters": return "multi";
        default: exhaustiveCaseGuard(props.detail);
      }
    })

    const selectedConfiguratorTabIdx = ref(0);

    return () => {
      if (!asyncState.value.ready) {
        return null;
      }

      return (
        <div style="--fk-bg-input: white;" ref={rootRef} data-test="R_TeamSeason">
          {
            (() => {
              switch (props.detail.routeName) {
                case "TeamSeason.config":
                  return (
                    <>
                      <h1>Uniform Assignments, Team Colors &amp; Practice Times</h1>
                      <div class="mb-6">
                        <TeamSelectionButtons
                          teamSelectMode={menuTeamSelectMode.value}
                          selectionManager={asyncState.value.selectionManager}
                          menu={asyncState.value.menu}
                          levels={{season: true, competition: true, division: true, team: true, sort: true}}
                          mut_sort={sortOrder}
                          mut_openAbsolutePosPanes={openAbsolutePosPanes}
                        />
                      </div>
                      <ConfiguratorLoader
                        key={JSON.stringify(completeSelectionOrNull.value)}
                        menu={asyncState.value.menu}
                        selection={completeSelectionOrNull.value}
                        doUpdateTeamSeason={doUpdateTeamSeason}
                        doUpdateTeamSeasonPlayers={doUpdateTeamSeasonPlayers}
                        sort={sortOrder.value}
                        tabIndex={selectedConfiguratorTabIdx.value}
                        onChangeTabIndex={idx => {selectedConfiguratorTabIdx.value = idx}}
                      />
                    </>
                  );
                case "TeamSeason.rosters":
                  return (
                    <>
                      <h1>Team Rosters &amp; Reports</h1>
                      <div>
                        Generate team rosters, line-up cards, and reports for teams you manage below.
                      </div>
                      <div class="mb-6">
                        <TeamSelectionButtons
                          teamSelectMode={menuTeamSelectMode.value}
                          selectionManager={asyncState.value.selectionManager}
                          menu={asyncState.value.menu}
                          levels={{season: true, competition: true, division: true, team: true, sort: true}}
                          mut_sort={sortOrder}
                          mut_openAbsolutePosPanes={openAbsolutePosPanes}
                        />
                      </div>
                      <div class={`${commonMaxWidth} mx-auto flex justify-center text-lg`}>
                        {embargoInfoResolver.underlying.status === "resolved" && embargoInfoResolver.underlying.data.isEmbargoed
                          ? <div class="mb-6 flex gap-2 items-center rounded-md p-2 il-box-shadow-1">
                            <FontAwesomeIcon class="fa-xl text-yellow-500" icon={faTriangleExclamation}/>
                            <span>Rosters are embargoed until {dayjs(embargoInfoResolver.underlying.data.embargoDate).format(DAYJS_FORMAT_IL_FULL_1)}</span>
                          </div>
                          : null
                        }
                      </div>
                      <RosterLoader
                        selection={completeSelectionOrNull.value}
                        sort={sortOrder.value}
                        rosterExcludedPlayerIDs={rosterExcludedPlayerIDs.value}
                      />
                    </>
                  );
                default: exhaustiveCaseGuard(props.detail)
              }
            })()
          }
        </div>
      );
    }
  }
})

interface RouteData_Base {
  routeName: RouteName
}

interface RouteData_Configurator extends RouteData_Base {
  routeName: "TeamSeason.config",
  season: iltypes.SeasonTriple,
  teamID: Guid,
  team: Team,
  teamSeason: TeamSeasonForm,
  teamSeasonPlayers: TeamSeasonPlayersForm,
}

interface RouteData_Roster extends RouteData_Base {
  routeName: "TeamSeason.rosters",
  teamIDs: Guid[],
  selectedRosterDetail: null | RosterData
  /** @deprecated we're no longer using this, it's always true for everything, so can be deleted */
  authZ: ilteam.RosterAuthZ,
}

/**
 * The current approach is that if a user got to this page, they have perms to do the things for the offered teams.
 * @deprecated
 */
const roster_authZ = {
  affinity: true,
  coachIdCards: true,
  medicalRelease: true,
  playerIDCards: true,
  lineUpCards: true,
  eaysoRoster: true,
  contactInfo: true,
} as const

type RosterData =
  | RosterData_MedicalRelease
  | RosterData_ContactInfo
  | RosterData_PictureDay
  | RosterData_TeamPlayerCoachSummary
  | RosterData_ExcludablePlayers

type RosterFuncName =
  | "medicalRelease"
  | "contactInfo"
  | "pictureDay"
  | "teamPlayerCoachSummary"
  | "excludablePlayers"

function isKnownRosterFuncName(v: any) : v is RosterFuncName {
  const unsafe_maybeName = v as RosterFuncName;
  switch (unsafe_maybeName) {
    case "contactInfo": return true;
    case "medicalRelease": return true;
    case "pictureDay": return true;
    case "teamPlayerCoachSummary": return true;
    case "excludablePlayers": return true;
    default:
      nonThrowingExhaustiveCaseGuard(unsafe_maybeName)
      return false;
  }
}

interface RosterData_Base {
  type: RosterFuncName
}

interface RosterData_MedicalRelease extends RosterData_Base {
  type: "medicalRelease",
  data: ilteam.MedicalReleaseListingEntry[]
}

interface RosterData_ContactInfo extends RosterData_Base {
  type: "contactInfo",
  data: ilteam.TeamSeasonReportEntry[]
}

interface RosterData_PictureDay extends RosterData_Base {
  type: "pictureDay",
  data: ilteam.PictureDayRosterResponse
}
interface RosterData_TeamPlayerCoachSummary extends RosterData_Base {
  type: "teamPlayerCoachSummary",
  data: ilteam.TeamPlayerCoachSummaryResponse
}

interface RosterData_ExcludablePlayers extends RosterData_Base {
  type: "excludablePlayers",
  data: ilteam.ExcludablePlayer[]
}

const ConfiguratorLoader = defineComponent({
  props: {
    menu: vReqT<TeamChooserMenu>(),
    selection: vReqT<CompleteTeamChooserSelection | null>(),
    doUpdateTeamSeason: vReqT<(_: ilteam.UpdateTeamSeasonArgs) => Promise<{ok: boolean}>>(),
    doUpdateTeamSeasonPlayers: vReqT<(_: ilteam.UpdateTeamSeasonPlayersArgs) => Promise<{ok: boolean}>>(),
    sort: vReqT<PlayerRosterSort>(),
    tabIndex: vReqT<number>(),
  },
  emits: {
    changeTabIndex: (_idx: number) => true,
  },
  setup(props, {emit}) {
    const iziToast = useIziToast();
    const resolvedRouteData = ref<null | RouteData_Configurator>(null as any)

    const doResolveTeamSeason = async () : Promise<void> => {
      if (props.selection === null) {
        resolvedRouteData.value = null;
        return
      }

      const teamSelection = getDefinitelyCompleteSingularTeamSelectionOrFail(props.selection);

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        let teamSeason : TeamSeason | null = null;
        try {
          teamSeason = await ilteam.getTeamSeason(freshNoToastLoggedInAxiosInstance(), teamSelection);
        }
        catch (err) {
          defaultDoHandlePossibleAxiosError(iziToast, err)
          // if we got here, even if we didn't rethrow, we still can't proceed, because we don't know the state of the serverside teamseason
          return;
        }

        const season = await Client.getSeasonByUidOrFail(teamSelection.seasonUID);
        const team = await ilteam.getTeam(axiosInstance, teamSelection)
        const teamSeasonPlayers = await ilteam.getTeamSeasonPlayers(axiosInstance, teamSelection)

        if (!teamSeason) {
          // no team season exists on the backend, but we can default-init one locally to edit and then push to server
          teamSeason = {
            teamID: team.teamID,
            seasonUID: season.seasonUID,
            seasonID: season.seasonID,
            teamName: "",
            colorJersey: "",
            colorJerseySolidStripe: "",
            colorShorts: "",
            colorSocks: "",
            practiceTime: "",
            practiceLoc: "",
          }
        }

        resolvedRouteData.value = {
          routeName: "TeamSeason.config",
          teamID: teamSelection.teamID,
          season,
          team,
          teamSeason: freshTeamSeasonFormFromExisting(teamSeason),
          teamSeasonPlayers: doSortPlayersForm(freshTeamSeasonPlayersFormFromExisting(teamSeasonPlayers)),
        }
      });
    }

    watch(() => props.selection, () => {
      doResolveTeamSeason()
    }, {immediate: true, deep: true})

    const doUpdateTeamSeason = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          if (!resolvedRouteData.value) {
            throw Error("illegal state");
          }
          const result = await props.doUpdateTeamSeason({
            teamID: resolvedRouteData.value.teamID,
            seasonUID: resolvedRouteData.value.season.seasonUID,
            updatables: resolvedRouteData.value.teamSeason
          })

          if (result.ok) {
            iziToast.success({message: "Changes saved."})
          }
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const doUpdateTeamSeasonPlayers = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        if (!resolvedRouteData.value) {
          throw Error("illegal state")
        }
        try {
          const result = await props.doUpdateTeamSeasonPlayers({
            teamID: resolvedRouteData.value.teamID,
            seasonUID: resolvedRouteData.value.season.seasonUID,
            playerAssignments: resolvedRouteData.value.teamSeasonPlayers.playerAssignments.map(v => ({assignmentID: v.assignmentID, uniform: v.uniform})),
            playerLoans: resolvedRouteData.value.teamSeasonPlayers.playerLoans.map(v => ({loanID: v.loanID, uniform: v.uniform}))
          })

          if (result.ok) {
            // write things we know could have changed into pristine, since they've definitely been pushed to the db
            resolvedRouteData.value.teamSeasonPlayers.playerAssignments.forEach(v => {
              v.pristine.uniform = v.uniform
            });
            resolvedRouteData.value.teamSeasonPlayers.playerLoans.forEach(v => {
              v.pristine.uniform = v.uniform
            });

            iziToast.success({message: "Changes saved."})
          }
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      })
    }

    function freshTeamSeasonFormFromExisting(teamSeason: iltypes.TeamSeason) : TeamSeasonForm {
      return {
        teamName: teamSeason.teamName,
        colorJersey: teamSeason.colorJersey,
        colorJerseySolidStripe: teamSeason.colorJerseySolidStripe,
        colorShorts: teamSeason.colorShorts,
        colorSocks: teamSeason.colorSocks,
        practiceTime: teamSeason.practiceTime,
        practiceLoc: teamSeason.practiceLoc
      }
    }

    function freshTeamSeasonPlayersFormFromExisting(v: ilteam.GetTeamSeasonPlayersResponse) : TeamSeasonPlayersForm {
      return {
        playerAssignments: v
          .playerAssignments
          .map(v => ({type: "playerAssignment", assignmentID: v.assignmentID, uniform: v.uniform, pristine: v})),
        playerLoans: v
          .playerLoans
          .map(v => ({type: "playerLoan", loanID: v.loanID, uniform: v.uniform, pristine: v}))
      }
    }

    watch(() => props.sort, () => {
      if (resolvedRouteData.value) {
        doSortPlayersForm(resolvedRouteData.value.teamSeasonPlayers)
      }
    })

    const doSortPlayersForm = (form: TeamSeasonPlayersForm) : TeamSeasonPlayersForm => {
      const sorter = props.sort === "name"
        ? sortByMany<PlayerAssignmentForm | PlayerLoanForm>(
          (l,r) => accentAwareCaseInsensitiveCompare(l.pristine.child.playerLastName, r.pristine.child.playerLastName),
          (l,r) => accentAwareCaseInsensitiveCompare(l.pristine.child.playerFirstName, r.pristine.child.playerFirstName),
        )
        : props.sort === "uniform"
        ? sortBy<PlayerAssignmentForm | PlayerLoanForm>(_ => parseIntOr(_.uniform, _.uniform))
        : exhaustiveCaseGuard(props.sort)

      form.playerAssignments.sort(sorter);
      form.playerLoans.sort(sorter);

      return form;
    }

    return () => {
      if (!props.selection) {
        return null;
      }

      if (!resolvedRouteData.value) {
        return null;
      }

      return <Configurator
        routeData={resolvedRouteData.value}
        doUpdateTeamSeason={doUpdateTeamSeason}
        doUpdateTeamSeasonPlayers={doUpdateTeamSeasonPlayers}
        sort={props.sort}
        tabIndex={props.tabIndex}
        onChangeTabIndex={idx => emit("changeTabIndex", idx)}
      />
    }
  }
})

const Configurator = defineComponent({
  props: {
    routeData: vReqT<RouteData_Configurator>(),
    doUpdateTeamSeason: vReqT<() => Promise<void>>(),
    doUpdateTeamSeasonPlayers: vReqT<() => Promise<void>>(),
    sort: vReqT<PlayerRosterSort>(),
    tabIndex: vReqT<number>(),
  },
  emits: {
    selectRosterFunction: (_: RosterFuncName | null) => true,
    changeTabIndex: (_idx: number) => true,
  },
  setup(props, {emit}) {
    return () => (
      <div class={`${commonMaxWidth} mx-auto`}>
        <Tabs
          onChangeSelectedIndex={idx => emit("changeTabIndex", idx)}
          selectedIndex={props.tabIndex}
          tabDefs={[
            {
              "data-test": "tab/teamDetails",
              label: "Team Details",
              keepAlive: true,
              render: () => {
                return (
                  <div class="my-4">
                    <div class="bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5 p-4" style="--fk-max-width-input: none; --fk-margin-outer: none;">
                      <EditTeamSeasonFormElement
                        form={props.routeData.teamSeason}
                        doUpdate={props.doUpdateTeamSeason}
                      />
                    </div>
                  </div>
                )
              }
            },
            {
              "data-test": "tab/editPlayers",
              label: "Uniforms",
              keepAlive: true,
              render: () => {
                return (
                  <div class="my-4">
                    <div class="bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5 p-2">
                      <EditTeamSeasonPlayersElement
                        teamID={props.routeData.team.teamID}
                        seasonUID={props.routeData.season.seasonUID}
                        form={props.routeData.teamSeasonPlayers}
                        doUpdate={props.doUpdateTeamSeasonPlayers}
                      />
                    </div>
                  </div>
                )
              }
            }
          ]
        }/>
      </div>
    )
  }
})

const RosterLoader = defineComponent({
  props: {
    selection: vReqT<CompleteTeamChooserSelection | null>(),
    sort: vReqT<PlayerRosterSort>(),
    rosterExcludedPlayerIDs: vReqT<RosterExcludedPlayerIDs | null>(),
  },
  setup(props) {
    const router = useRouter();

    const selectedRosterFunc = ref<null | RosterFuncName>(null);

    const silentLoggedInAxios = freshNoToastLoggedInAxiosInstance();
    const mostRecentRouteDataRequest = ReactiveReifiedPromise<RouteData_Roster>()

    const doResolve = async () : Promise<void> => {
      const teamIDs = props.selection?.teamIDs
      const seasonUID = props.selection?.seasonUID
      if (!seasonUID || !teamIDs || teamIDs.length === 0) {
        selectedRosterFunc.value = null;
        return
      }

      if (!selectedRosterFunc.value) {
        mostRecentRouteDataRequest.forceResolve({
          routeName: "TeamSeason.rosters",
          teamIDs: teamIDs,
          selectedRosterDetail: null,
          authZ: roster_authZ,
        })
      }
      else {
        mostRecentRouteDataRequest.run(async () => {
          return {
            routeName: "TeamSeason.rosters",
            teamIDs: teamIDs,
            selectedRosterDetail: await getRosterData({teamIDs, seasonUID}),
            authZ: roster_authZ
          }
        });
      }

      async function getRosterData(teamSeason: {teamIDs: Guid[], seasonUID: Guid}) {
        if (!selectedRosterFunc.value) {
          throw Error("illegal state")
        }

        switch (selectedRosterFunc.value) {
          case "medicalRelease": {
            return {
              type: "medicalRelease",
              data: await ilteam.getRoster_medicalReleaseListing(silentLoggedInAxios, teamSeason),
            } as const
          }
          case "contactInfo": {
            return {
              type: "contactInfo",
              data: await ilteam.getRoster_contactInfo(silentLoggedInAxios, teamSeason),
            } as const
          }
          case "pictureDay": {
            return {
              type: "pictureDay",
              data: await ilteam.getRoster_pictureDay(silentLoggedInAxios, teamSeason),
            } as const
          }
          case "teamPlayerCoachSummary": {
            return {
              type: "teamPlayerCoachSummary",
              data: await ilteam.getRoster_teamPlayerCoachSummary_Data(silentLoggedInAxios, teamSeason)
            } as const
          }
          case "excludablePlayers": {
            return {
              type: "excludablePlayers",
              data: await ilteam.getRoster_excludablePlayers(silentLoggedInAxios, teamSeason),
            } as const
          }
          default: exhaustiveCaseGuard(selectedRosterFunc.value)
        }
      }
    }

    const doUpdateSelectedRosterFunction = async () : Promise<void> => {
      const name = router.currentRoute.value.query[queryParams.rosterFunc]
      selectedRosterFunc.value = isKnownRosterFuncName(name) ? name : null;

      await withNoScroll(async () => void await router.replace({
        ...router.currentRoute.value,
        query: {
          ...router.currentRoute.value.query,
          [queryParams.rosterFunc]: selectedRosterFunc.value || undefined,
          [queryParams.seasonUID]: props.selection?.seasonUID || undefined,
          [queryParams.competitionUID]: props.selection?.competitionUID || undefined,
          [queryParams.divID]: props.selection?.divID || undefined,
          [queryParams.teamIDs]: props.selection?.teamIDs || undefined,
        }
      }))
    }

    const getRosterFuncRouteLocation = (v: RosterFuncName) : RouteLocationRaw => {
      return {
        ...router.currentRoute.value,
        query: {
          ...router.currentRoute.value.query,
          [queryParams.rosterFunc]: v,
          [queryParams.seasonUID]: props.selection?.seasonUID || undefined,
          [queryParams.competitionUID]: props.selection?.competitionUID || undefined,
          [queryParams.divID]: props.selection?.divID || undefined,
          [queryParams.teamIDs]: props.selection?.teamIDs || undefined,
        }
      }
    }

    // fixme: calling `doUpdateSelectedRosterFunction` from our watch, re-triggers the watch,
    // because it updates the things we're watching. We can set a flag to identify and cut short
    // the recursion, but it seems like there is a better way. Without the flag, the watch runs twice,
    // and then finds a steady state.
    let isInUpdateSelectedRosterFunction = false;
    watch(() => [
      props.selection,
      router.currentRoute.value.query[queryParams.rosterFunc]
    ], async () => {
      if (isInUpdateSelectedRosterFunction) {
        return;
      }

      try {
        isInUpdateSelectedRosterFunction = true;
        await doUpdateSelectedRosterFunction()
      }
      finally {
        isInUpdateSelectedRosterFunction = false;
      }

      doResolve();
    }, {immediate: true, deep: true})

    return () => {
      if (!props.selection) {
        return null;
      }

      switch(mostRecentRouteDataRequest.underlying.status) {
        case "idle":
          return null;
        case "error": {
          const err = mostRecentRouteDataRequest.underlying.error
          if (axios.isAxiosError(err) && isInleagueApiError2(err)) {
            defaultLoggedInErrorResponseHandler(iziToast)(err);
          }
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
          return;
        }
        case "pending": {
          return (
            <div class="flex flex-col items-center justify-center">
              <SoccerBall width="1.75em" height="1.75em" color={Client.value.clientTheme.color}/>
              <div class="text-sm">Loading</div>
            </div>
          );
        }
        case "resolved":
          assertNonNull(props.rosterExcludedPlayerIDs, "set to non-null when there is a full selection")
          return (
            <Roster
              selection={props.selection}
              routeData={mostRecentRouteDataRequest.underlying.data}
              getRosterFuncRouteLocation={getRosterFuncRouteLocation}
              rosterExcludedPlayerIDs={props.rosterExcludedPlayerIDs}
              sort={props.sort}
            />
          )
        default: exhaustiveCaseGuard(mostRecentRouteDataRequest.underlying)
      }
    }
  }
})

const Roster = defineComponent({
  props: {
    selection: vReqT<CompleteTeamChooserSelection>(),
    routeData: vReqT<RouteData_Roster>(),
    getRosterFuncRouteLocation: vReqT<(_: RosterFuncName) => RouteLocationRaw>(),
    rosterExcludedPlayerIDs: vReqT<RosterExcludedPlayerIDs>(),
    sort: vReqT<PlayerRosterSort>(),
  },
  setup(props) {
    const affinityRosterLink = () => {
      const teamIDs = props.selection.teamIDs.join(",")
      const seasonUID = props.selection.seasonUID
      const excludedPlayerIDs = props.rosterExcludedPlayerIDs.asQueryParamList()
      return `${appOrigin()}/api/v1/teams/roster/affinity?teamIDs=${teamIDs}&seasonUID=${seasonUID}&sort=${props.sort}&excludedPlayerIDs=${excludedPlayerIDs}`
    }

    const lineUpCardsRosterLink = (args: {mode: number, includeBackPage: boolean}) => {
      const teamIDs = props.selection.teamIDs.join(",")
      const seasonUID = props.selection.seasonUID
      const excludedPlayerIDs = props.rosterExcludedPlayerIDs.asQueryParamList()
      return [
        `${appOrigin()}/api/v1/teams/roster/lineupCards?`,
        `teamIDs=${teamIDs}`,
        `&seasonUID=${seasonUID}`,
        `&mode=${args.mode}`,
        `&sort=${props.sort}`,
        `&excludedPlayerIDs=${excludedPlayerIDs}`,
        `&includeBackPage=${args.includeBackPage ? 1 : 0}`
      ].join("")
    }

    const playerIdCardsRosterLink = () => {
      const teamIDs = props.selection.teamIDs.join(",")
      const seasonUID = props.selection.seasonUID
      const excludedPlayerIDs = props.rosterExcludedPlayerIDs.asQueryParamList()
      return `${appOrigin()}/api/v1/teams/roster/playerIdCards?teamIDs=${teamIDs}&seasonUID=${seasonUID}&sort=${props.sort}&excludedPlayerIDs=${excludedPlayerIDs}`
    }

    const teamIdCardsRosterLink = () => {
      const teamIDs = props.selection.teamIDs.join(",")
      const seasonUID = props.selection.seasonUID
      const excludedPlayerIDs = props.rosterExcludedPlayerIDs.asQueryParamList()
      return `${appOrigin()}/api/v1/teams/roster/teamIdCards?teamIDs=${teamIDs}&seasonUID=${seasonUID}&sort=${props.sort}&excludedPlayerIDs=${excludedPlayerIDs}`
    }

    const openCoachIdCardsRoster = (args: {asstCoach: boolean}) => {
      const teamIDs = props.selection.teamIDs.join(",")
      const seasonUID = props.selection.seasonUID
      const asstCoach = args.asstCoach ? 1 : 0;
      return `${appOrigin()}/api/v1/teams/roster/coachIdCards?teamIDs=${teamIDs}&seasonUID=${seasonUID}&assistantCoach=${asstCoach}&sort=${props.sort}`
    }

    const eaysoRosterLink = () => {
      const teamIDs = props.selection.teamIDs.join(",")
      const seasonUID = props.selection.seasonUID
      const excludedPlayerIDs = props.rosterExcludedPlayerIDs.asQueryParamList()
      return `${appOrigin()}/api/v1/teams/roster/EaysoRoster?teamIDs=${teamIDs}&seasonUID=${seasonUID}&sort=${props.sort}&excludedPlayerIDs=${excludedPlayerIDs}`
    }

    const medicalReleaseMergedPdfByTeamsUrl = () : string => {
      if (props.routeData.selectedRosterDetail?.type === "medicalRelease") {
        const teamIDs = props.routeData.teamIDs.join(",");
        const seasonUID = props.selection.seasonUID
        const excludedPlayerIDs = props.rosterExcludedPlayerIDs.asQueryParamList()
        return `${appOrigin()}/api/v1/teams/roster/medicalRelease/pdf?keyType=teams&teamIDs=${teamIDs}&seasonUID=${seasonUID}&sort=${props.sort}&excludedPlayerIDs=${excludedPlayerIDs}`
      }
      else {
        return ""
      }
    }

    const medicalReleaseMergedPdfByCompSeasonDivUrl = () : string => {
      if (props.routeData.selectedRosterDetail?.type === "medicalRelease") {
        const competitionUID = props.selection.competitionUID
        const seasonUID = props.selection.seasonUID
        const divID = props.selection.divID
        const excludedPlayerIDs = props.rosterExcludedPlayerIDs.asQueryParamList()
        return `${appOrigin()}/api/v1/teams/roster/medicalRelease/pdf?keyType=compSeasonDiv&competitionUID=${competitionUID}&seasonUID=${seasonUID}&divID=${divID}&sort=${props.sort}&excludedPlayerIDs=${excludedPlayerIDs}`
      }
      else {
        return ""
      }
    }

    const backToRosterOptionsLink = () => {
      return routeDetailToRouteLocation({routeName: "TeamSeason.rosters"})
    }

    const lineUpCardsModalController = reactive((() => {
      return DefaultModalController<void>({
        title: () => <>
          <div>Lineup Cards</div>
          <div class="border-b my-2"/>
        </>,
        content: () => <LineupCardsModal lineUpCardsRosterLink={lineUpCardsRosterLink}/>
      })
    })());

    return () => {
      if (props.routeData.selectedRosterDetail === null) {
        return <RosterOptions/>
      }

      return (
        <div>
            <RouterLink to={backToRosterOptionsLink()}>
              <t-btn type="button">
                <span>Back to all roster options</span>
              </t-btn>
            </RouterLink>
            {
              props.routeData.selectedRosterDetail.type === "medicalRelease"
              ? <MedicalReleaseListingTable
                selection={props.selection}
                mergedPdfByTeamsURL={medicalReleaseMergedPdfByTeamsUrl()}
                mergedPdfByCompSeasonDivURL={medicalReleaseMergedPdfByCompSeasonDivUrl()}
                listing={props.routeData.selectedRosterDetail.data}
                sort={props.sort}
                rosterExcludedPlayerIDs={props.rosterExcludedPlayerIDs}
              />
              : props.routeData.selectedRosterDetail.type === "contactInfo"
              ? <ContactInfoListingTable data={props.routeData.selectedRosterDetail.data} sort={props.sort}/>
              : props.routeData.selectedRosterDetail.type === "pictureDay"
              ? <PictureDayRosterElement data={props.routeData.selectedRosterDetail.data} sort={props.sort}/>
              : props.routeData.selectedRosterDetail.type === "teamPlayerCoachSummary"
              ? <TeamPlayerCoachSummaryElement data={props.routeData.selectedRosterDetail.data} sort={props.sort}/>
              : props.routeData.selectedRosterDetail.type === "excludablePlayers"
              ? <ExcludablePlayersElement
                listing={props.routeData.selectedRosterDetail.data}
                selection={props.selection}
                sort={props.sort}
                rosterExcludedPlayerIDs={props.rosterExcludedPlayerIDs}
              />
              : exhaustiveCaseGuard(props.routeData.selectedRosterDetail)
            }
        </div>
      )

    }

    function RosterOptions() : JSX.Element {
      return (
        <div class={`${commonMaxWidth} flex justify-center m-auto`}>
          <AutoModal controller={lineUpCardsModalController}/>
          <div style="display:grid; grid-template-columns: max-content; gap: .5em;">
            <div class="flex w-full gap-2">
              <RouterLink to={props.getRosterFuncRouteLocation("excludablePlayers")} class="flex-grow">
                <Btn2 class="p-2 w-full">
                  <div class="block text-left">
                    <div>Exclude players from generated rosters</div>
                    <div class="text-xs">
                      {
                        props.rosterExcludedPlayerIDs.length === 0
                          ? "No current exclusions"
                          : `Current exclusions: ${props.rosterExcludedPlayerIDs.length}`
                      }
                    </div>
                  </div>
                </Btn2>
              </RouterLink>
              {
                props.rosterExcludedPlayerIDs.length > 0
                  ? (
                    <Btn2 class="p-2 flex items-center text-xs" onClick={() => {
                      props.rosterExcludedPlayerIDs.clear()
                      props.rosterExcludedPlayerIDs.persistToLocalStorage()
                    }}>
                      Clear exclusions
                    </Btn2>
                  )
                  : null
              }
            </div>

            {
              props.routeData.authZ.affinity
                ? (
                  <a href={affinityRosterLink()} target="_blank">
                    <t-btn margin={false} style="width:100%">
                      <div class="flex w-full">
                        <span>AYSO Official (Affinity) roster</span>
                        <OpensInAnotherWindowIndicator/>
                      </div>
                    </t-btn>
                  </a>
                )
                : null
            }

            {
              props.routeData.authZ.eaysoRoster
                ? (
                  <a href={eaysoRosterLink()} target="_blank">
                    <t-btn margin={false} style="width:100%;">
                      <div class="flex w-full">
                        <span>eAYSO Style Tournament Roster</span>
                        <OpensInAnotherWindowIndicator/>
                      </div>
                    </t-btn>
                  </a>
                )
                : null
            }

            <RouterLink to={props.getRosterFuncRouteLocation("medicalRelease")}>
              <t-btn margin={false} style="width:100%">E-Signed Medical Release Archive</t-btn>
            </RouterLink>
            <RouterLink to={props.getRosterFuncRouteLocation("contactInfo")} class="w-full">
              <t-btn margin={false} style="width:100%;">Team Information / Contact Info</t-btn>
            </RouterLink>
            <RouterLink to={props.getRosterFuncRouteLocation("pictureDay")} class="w-full">
              <t-btn margin={false} style="width:100%;">Picture day</t-btn>
            </RouterLink>
            <RouterLink to={props.getRosterFuncRouteLocation("teamPlayerCoachSummary")} class="w-full">
              <t-btn margin={false} style="width:100%;">Team / Player / Coach Summary</t-btn>
            </RouterLink>

            {
              props.routeData.authZ.lineUpCards
                ? <t-btn margin={false} style="width:100%" onClick={() => lineUpCardsModalController.open()}>
                  <div class="flex w-full">
                    <div class="flex flex-col items-start">
                      <div>Lineup Cards...</div>
                    </div>
                  </div>
                </t-btn>
                : null
            }

            {
              props.routeData.authZ.playerIDCards
                ? (
                  <a href={teamIdCardsRosterLink()} target="_blank">
                    <t-btn margin={false} style="width:100%">
                      <div class="flex w-full">
                        <span>Affinity-style Team ID Cards</span>
                        <OpensInAnotherWindowIndicator/>
                      </div>
                    </t-btn>
                  </a>
                )
                : null
            }

            {
              props.routeData.authZ.playerIDCards
                ? (
                  <a href={playerIdCardsRosterLink()} target="_blank">
                    <t-btn margin={false} style="width:100%">
                      <div class="flex w-full">
                        <span>Player ID Cards</span>
                        <OpensInAnotherWindowIndicator/>
                      </div>
                    </t-btn>
                  </a>
                )
                : null
            }

            {
              // head coach
              props.routeData.authZ.coachIdCards
                ? (
                  <a href={openCoachIdCardsRoster({asstCoach: false})} target="_blank">
                    <t-btn margin={false} style="width:100%">
                      <div class="flex w-full">
                        <span>Coach ID Cards</span>
                        <OpensInAnotherWindowIndicator/>
                      </div>
                    </t-btn>
                  </a>
                )
                : null
            }

            {
              // asst. coach
              props.routeData.authZ.coachIdCards
                ? (
                  <a href={openCoachIdCardsRoster({asstCoach: true})} target="_blank">
                    <t-btn margin={false} style="width:100%">
                      <div class="flex w-full">
                        <span>Asst Coach ID Cards</span>
                        <OpensInAnotherWindowIndicator/>
                      </div>
                    </t-btn>
                  </a>
                )
                : null
            }

          </div>
        </div>
      )
    }

    function OpensInAnotherWindowIndicator() {
      return (
        <span v-tooltip={{content: "Opens in another window"}} class="ml-auto">
          <FontAwesomeIcon icon={faArrowCircleRight} class="-rotate-45 transform"/>
        </span>
      )
    }
  }
})

function getDefinitelyCompleteSingularTeamSelectionOrFail(sel: CompleteTeamChooserSelection | null) : CompleteTeamChooserSelectionSingular {
  if (!sel) {
    throw Error(`expected a complete selection but got nothing`)
  }
  if (sel.teamIDs.length !== 1) {
    throw Error(`expected exactly 1 selected team but got ${sel.teamIDs.length}`)
  }
  return {
    ...sel,
    teamID: sel.teamIDs[0]
  }
}

function appOrigin() {
  return `https://${Client.value.instanceConfig.appdomain}`;
}

const commonMaxWidth = "max-w-6xl"
