import dayjs, { Dayjs } from "dayjs";
import { ilDropTarget, vueDirective_ilDraggable, vueDirective_ilDropTarget } from "src/modules/ilDraggable";
import { SetEx, TailwindBreakpoint, UiOption, UiOptions, arrayFindIndexOrFail, arrayFindOrFail, arraySum, assertIs, assertNonNull, assertTruthy, checkedObjectEntries, clamp, copyViaJsonRoundTrip, exhaustiveCaseGuard, forceCheckedIndexedAccess, noAvailableOptions, parseIntOr, parseIntOrFail, requireNonNull, sleep, unreachable, useIziToast, useWindowSize, vReqT } from "src/helpers/utils";
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, shallowReactive, watch } from "vue";
import { authZ_canDragOrResizeNode, bracketTeamLabel, coachBlurbForTeamName, CompDivAuthZ, CreateFieldBlockForm, CreateGameForm, EditFieldBlockForm, EditGameForm, EditGameTabID, GameCalendarElement, GameCalendarUiElement, isEffectivelyAllDay, k_POOL_ALL, k_TEAM_TBD, teamDesignationAndMaybeName } from "./GameScheduler.shared";
import { CompetitionUID, Datelike, DivID, Division, Guid, IL_DayOfWeek, Integerlike, SeasonTriple, SeasonUID } from "src/interfaces/InleagueApiV1";
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances";
import { DAYJS_FORMAT_HTML_DATE, DAYJS_FORMAT_IL_API_LOCALDATE } from "src/helpers/formatDate";
import { DisplayOptions } from "./GameSchedulerDisplayOptions";
import { EditFieldBlock, EditGame, Actions, TabID, ClearGameTeamAssignmentsState, gameSwapGameOptionLabel } from "./GameSchedulerActions";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { vueDirective_ilOnClickOutside } from "src/helpers/OnClickOutside";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { GameEditorPaneOrModal } from "./GameEditorPaneOrModal";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SoccerBall, X } from "src/components/SVGs";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

import { FieldBlockForGameSchedulerView, GameForGameSchedulerView, UpdateFieldBlockRequest, UpdateGameRequest, bulkUpdateGamesAndFieldBlocks, deleteGamesAndFieldBlocks, getGamesAndBlocksForGameSchedulerView, getCompDivSeasonDependentCrudGameOptions, updateFieldBlock, updateGame, createGames, CreateGameRequest, CreateFieldBlockRequest, createFieldBlocks, getCompDependentCrudGameOptions, GetCompDependentCrudGameOptionsResponse, GetCompDivSeasonDependentCrudGameOptionsResponse, GetNonDependentCrudAndViewOptionsResponse, getNonDependentCrudAndViewOptions, getCompDependentViewOptions, GetCompDependentViewOptionsResponse, clearGameTeamAssignments, swapGames } from "src/composables/InleagueApiV1.GameScheduler";
import { ANY, authZ_perAction, propsDef } from "./R_GameSchedulerCalendar.route";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { LayoutNode, LayoutNodeRoot } from "./CalendarLayout";
import { GameLayoutTreeStore } from "./GameLayoutTreeStore";
import { AutoModal, DefaultModalController, DefaultTinySoccerballBusyOverlay } from "src/components/UserInterface/Modal";
import { ConfirmDeleteCalendarElement } from "./ConfirmDeleteCalendarElement";

import { Type, type Static } from "@sinclair/typebox"
import { Value } from '@sinclair/typebox/value'
import { User } from "src/store/User";
import { maybeParseJSON } from "src/helpers/utils";
import { k_GUID } from "src/boot/TypeBoxSetup";
import { faUndo } from "@fortawesome/pro-solid-svg-icons";
import { Btn2 } from "src/components/UserInterface/Btn2";
import { bestUpcomingOrCurrentCompetitionStartDayOfWeek } from "src/helpers/Competition";
import { AxiosInstance } from "axios";
import { CalendarElementStyle, calendarElementStylingByDivision, calendarElementStylingDefault, randomCssColors } from "./CalendarElementStyle";
import { CalendarElementMover, CalendarElementVerticalResizer, CalendarGridElement, CalendarGridElementSlots, MinNode } from "./CalendarGridElement";
import { buildLegacyLink } from "src/boot/auth";
import { Client } from "src/store/Client";
import { createRound, listRounds, Round } from "src/composables/InleagueApiV1.Matchmaker";
import { RouterLink } from "vue-router";
import * as R_Matchmaker from "src/components/GameScheduler/matchmaker/R_Matchmaker.route"
import { CommonCalendarLayoutData, SchedulerControlsElement, SchedulerControlsSlots, RootCalendarLayoutElement, RootCalendarLayoutElementSlots } from "./RootCalendarLayoutElement";
import { NoRender } from "src/helpers/NoRender";
import { faEyeSlash, faTally, faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons"

export default defineComponent({
  props: propsDef,
  directives: {
    ilDraggable: vueDirective_ilDraggable,
    ilDropTarget: vueDirective_ilDropTarget,
    ilOnClickOutside: vueDirective_ilOnClickOutside,
  },
  setup(props) {
    const iziToast = useIziToast()

    const authZ = computed(() => {
      return {
        canCrudGames: authZ_perAction.canCrudGames({competitionUID: ANY, divID: ANY}),
        canCrudFieldBlocks: authZ_perAction.canCrudFieldBlocks(),
      }
    })

    const view_competitionOptions = ref<{startDayOfWeek: IL_DayOfWeek, competitionUID: Guid, competition: string}[]>([])
    const view_fieldOptions = ref<{fieldUID: Guid, fieldAbbrev: string, fieldName: string, fieldID: Integerlike, checked: boolean}[]>([])
    const view_selectedCompetitionUIDs = ref(new SetEx<Guid>())
    const view_selectedDivIDs = ref(new SetEx<Guid>())
    // will be later initalized in onMounted to an appropriate for-the-first- the "upcoming saturday"? there's some logic for this ... on the game layout table maybe?
    const view_dateFrom = ref(dayjs().format(DAYJS_FORMAT_HTML_DATE))
    const view_dateToInclusive = ref(dayjs().add(2, "days").format(DAYJS_FORMAT_HTML_DATE))
    const view_coloringScheme = ref(ColoringScheme.division)
    const view_onlyShowSelectedDatesAndFieldsHavingGames = ref(true)
    const view_cssUnit_calendarZoom = ref("100%")
    const view_focusOnBracketGames = ref(false)
    /**
     * if true, show all hours for each column; otherwise, we elide before 7, after 10 (or thereabouts)
     */
    const view_fullDayDisplay = ref(false)

    const noRelevantSelectionsMsg = computed(() => {
      if (view_selectedCompetitionUIDs.value.size === 0) {
        return "No selected programs."
      }
      if (view_selectedDivIDs.value.size === 0) {
        return "No selected divisions."
      }
      if (!view_fieldOptions.value.find(v => v.checked)) {
        return "No selected fields."
      }
      return null
    })

    const undoStack = UndoStack();
    const isRunningUndo = ref(false)

    const bulkSelectState = ref<null | {type: "generic" | "rounds", selectedNodes: LayoutNode<GameCalendarUiElement>[]}>(null);
    /**
     * @mode "current!" means "reset state but retain the current mode"
     */
    const resetBulkSelectState = (mode: "current!" | null | "generic" | "rounds") => {
      games.traverseAllGames(node => {
        node.data.uiState.noBulkSelect = null
        node.data.uiState.isBulkSelected = false
        return "continue"
      })

      const effectiveMode = mode === "current!"
        ? bulkSelectState.value?.type ?? null
        : mode;

      if (effectiveMode === null) {
        bulkSelectState.value = null
      }
      else {
        bulkSelectState.value = {
          type: effectiveMode,
          selectedNodes: []
        }
      }
    }

    const initBulkSelectRoundsMode = () : void => {
      assertIs(rounds_roundOptionsSource.underlying.status, "resolved", "can't get here without a resolved rounds option source")
      assertTruthy(rounds_selections.value.roundID)
      const selectedRound = arrayFindOrFail(rounds_roundOptionsSource.underlying.data, v => v.roundID === rounds_selections.value.roundID);

      resetBulkSelectState("rounds")

      games.traverseAllGames((node) => {
        if (node.data.type === "game") {
          const seasonOK = node.data.data.seasonUID === selectedRound.seasonUID
          const competitionOK = node.data.data.competitionUID === selectedRound.competitionUID
          const divOK = node.data.data.divID === selectedRound.divID
          const poolOK = node.data.data.poolID === "ALL" || node.data.data.poolID === selectedRound.poolID
          if (!seasonOK || !competitionOK || !divOK || !poolOK) {
            node.data.uiState.noBulkSelect = {msg: "Mismatch between selected round and this game's season/comp/div/pool"}
          }
        }
        else {
          node.data.uiState.noBulkSelect = {msg: "Field blocks cannot be selected in round assignment mode."}
        }
        return "continue"
      })
    }

    // persist view options to local storage on changes
    watch([
      view_selectedCompetitionUIDs,
      view_selectedDivIDs,
      view_fieldOptions,
      view_coloringScheme,
    ], () => {
      if (!User.userData?.userID) {
        return
      }
      viewOptions_localStorage_save(User.userData.userID, {
        competitionUIDs: [...view_selectedCompetitionUIDs.value],
        divIDs: [...view_selectedDivIDs.value],
        fieldUIDs: view_fieldOptions.value.filter(v => v.checked).map(v => v.fieldUID),
        coloringScheme: view_coloringScheme.value,
      })
    }, {deep:true})

    const view_coloringOptions : UiOption[] = [
      {label: "Division", value: ColoringScheme.division},
      {label: "Round", value: ColoringScheme.round},
    ]

    const expand_displayOptions = ref(true)
    const expand_actions = ref(true)

    /**
     * Lookup table for e.g. 13->"1 pm", 14->"2 pm", ...
     */
    const _24hTo12h = (() => {
      const result : string[] = []
      const d = dayjs()
      for (let i = 0; i < 24; i++) {
        result.push(d.hour(i).format("h a"))
      }
      return result;
    })()

    const action_selectedTabId = ref(TabID.createGames)

    // actually we need to initialize this after we have competition/div/field options, etc.
    const createGameForm = ref<CreateGameForm | null>(null)
    const createFieldBlockForm = ref<CreateFieldBlockForm | null>(null);

    const crud_competitionOptions = ref<UiOption<Guid>[]>([])
    const crud_fieldOptions = ref<UiOption<Guid>[]>([])

    const editGame_uiState = ref<Readonly<EditFormUiState>>({active: false, node: undefined, form: undefined})
    const editGame_busyUpdating = ref(false)


    const CompDependentCrudOptionsLoaderFactory = (() => {
      // cache lifetime is per component mount (not global); cache is shared across all instances created by the factory
      const cache = new Map<CompetitionUID, GetCompDependentCrudGameOptionsResponse>()
      return {create}

      function create() {
        const value = ReactiveReifiedPromise<GetCompDependentCrudGameOptionsResponse>(undefined, {onError: e => { throw e }})
        let runID = 0

        /**
         * In the "edit game" case, we need to know info about the "previously selected" season because we need to ensure that it is included in the results.
         * Available season options are "dependent on" competition, but not "constrained by" ... the seasons returned from the api are filtered for display purposes
         * only so we don't display too many seasons, but "the current selected" season is always valid, even if it wasn't returned by the api here.
         */
        const loadOptions = (args: {competitionUID: Guid, oldSeason: SeasonTriple | null, debounce?: boolean}, axios?: AxiosInstance) => {
          const maybeCached = cache.get(args.competitionUID)
          if (maybeCached) {
            // cached value is non-augmented; we may need to augment it; augmenting it WILL NOT mutate the cached value
            return value.forceResolve(maybeAugmentWithOldSeason(maybeCached, args.oldSeason))
          }

          value.run(async () => {
            if (args.debounce ?? true) {
              const thisID = runID++
              await new Promise(resolve => setTimeout(resolve, 500))
              if (thisID != runID - 1) {
                return empty();
              }
            }

            const r = await getCompDependentCrudGameOptions(axios ?? axiosAuthBackgroundInstance, args)

            // cache the "non augmented" value
            cache.set(args.competitionUID, r);

            return maybeAugmentWithOldSeason(r, args.oldSeason);
          })

          return value
        }

        return {
          value,
          loadOptions
        }
      }

      /**
       * WILL NOT mutate `v`
       */
      function maybeAugmentWithOldSeason(v: GetCompDependentCrudGameOptionsResponse, oldSeason: SeasonTriple | null) : GetCompDependentCrudGameOptionsResponse {
        if (!oldSeason) {
          return v
        }

        if (v.seasons.find(v => v.seasonUID === oldSeason.seasonUID)) {
          return v;
        }

        const copy = copyViaJsonRoundTrip(v)
        copy.seasons.unshift(oldSeason)
        return copy
      }

      function empty() : GetCompDependentCrudGameOptionsResponse {
        return {
          seasons: [],
          divisions: [],
          competition: {
            currentSeason: {
              seasonID: 0,
              seasonUID: "",
              seasonName: ""
            }
          }
        }
      }
    })();

    const CompDivSeasonDependentCrudOptionsLoaderFactory = (() => {
      // cache lifetime is per component mount (not global); cache is shared across all instances
      const cache = new Map<`${CompetitionUID}/${DivID}/${SeasonUID}`, GetCompDivSeasonDependentCrudGameOptionsResponse>()
      return {create}

      function create() {
        const value = ReactiveReifiedPromise<GetCompDivSeasonDependentCrudGameOptionsResponse>(undefined, {onError: e => { throw e }})
        let runID = 0

        const loadOptions = (args: {competitionUID: Guid, divID: Guid, seasonUID: Guid, debounce?: boolean}, axios?: AxiosInstance) => {
          const cacheKey = `${args.competitionUID}/${args.divID}/${args.seasonUID}` as const
          const maybeCached = cache.get(cacheKey)
          if (maybeCached) {
            return value.forceResolve(maybeCached)
          }

          value.run(async () => {
            if (args.debounce ?? true) {
              const thisID = runID++
              await new Promise(resolve => setTimeout(resolve, 500))
              if (thisID != runID - 1) {
                return empty();
              }
            }

            const r = await getCompDivSeasonDependentCrudGameOptions(axios ?? axiosAuthBackgroundInstance, args)

            cache.set(cacheKey, r);

            return r;
          })

          return value
        }

        return {
          value,
          loadOptions,
          forceResolveNoOptions: () => value.forceResolve({teams: [], pools: []})
        }
      }

      function empty() : GetCompDivSeasonDependentCrudGameOptionsResponse {
        return {
          teams: [],
          pools: [],
        }
      }
    })();

    const createGame_compDependentOptions = CompDependentCrudOptionsLoaderFactory.create()
    const createGame_compDivSeasonDependentOptions = CompDivSeasonDependentCrudOptionsLoaderFactory.create()
    const editGame_compDependentOptions = CompDependentCrudOptionsLoaderFactory.create()
    const editGame_compDivSeasonDependentOptions = CompDivSeasonDependentCrudOptionsLoaderFactory.create()

    const rounds_compDependentOptions = CompDependentCrudOptionsLoaderFactory.create()
    const rounds_compDivSeasonDependentOptions = CompDivSeasonDependentCrudOptionsLoaderFactory.create()
    const rounds_roundOptionsSource = ReactiveReifiedPromise<Round[]>()
    const rounds_selections = ref<RoundMenuSelection>({
      competitionUID: "",
      season: null,
      divID: "",
      poolID: "ALL",
      roundID: ""
    })

    const clearGameAssignmentsState = (() => {
      const busy = ref(false)
      const dateFromInclusive = ref<"" | Datelike>("")
      const dateToInclusive = ref<"" | Datelike>("")
      const selectedCompetitionUID = ref<"" | Guid>("")
      const selectedDivID = ref<"" | Guid>("")

      const competitionOptions = ref<UiOptions>(noAvailableOptions())
      const divisionOptions = ref<UiOptions>(noAvailableOptions("Select a program."))

      const divOptionsGetter = CompDependentCrudOptionsLoaderFactory.create()

      watch(() => view_competitionOptions.value, () => {
        if (view_competitionOptions.value.length === 0) {
          competitionOptions.value = noAvailableOptions()
          selectedCompetitionUID.value = ""
        }
        else {
          competitionOptions.value = {
            disabled: false,
            options: view_competitionOptions.value.map(v => ({label: v.competition, value: v.competitionUID}))
          }
          if (!competitionOptions.value.options.find(v => v.value === selectedCompetitionUID.value)) {
            selectedCompetitionUID.value = forceCheckedIndexedAccess(competitionOptions.value.options, 0)?.value ?? ""
            if (selectedCompetitionUID.value) {
              refreshDivOptions({competitionUID: selectedCompetitionUID.value})
            }
          }
        }
      }, {deep: true, immediate: true});

      const refreshDivOptions = async (_: {competitionUID: Guid}) => {
        const {competitionUID} = _

        if (!competitionUID) {
          divisionOptions.value = noAvailableOptions("Select a program.");
          selectedDivID.value = ""
          return;
        }

        divisionOptions.value = noAvailableOptions("Loading divisions...")
        const options = await divOptionsGetter.loadOptions({competitionUID, oldSeason: null}).getResolvedOrFail()
        if (options.divisions.length === 0) {
          divisionOptions.value = noAvailableOptions()
          selectedDivID.value = ""
        }
        else {
          divisionOptions.value = {disabled: false, options: options.divisions.map(v => ({label: v.displayName || v.division, value: v.divID}))}
          if (!divisionOptions.value.options.find(v => v.value === selectedDivID.value)) {
            selectedDivID.value = forceCheckedIndexedAccess(divisionOptions.value.options, 0)?.value ?? ""
          }
        }
      }

      const v : ClearGameTeamAssignmentsState = reactive({
          competitionOptions,
          divisionOptions,
          busy,
          dateFromInclusive,
          dateToInclusive,
          selectedCompetitionUID,
          selectedDivID,
          refreshDivisionOptions: () => refreshDivOptions({competitionUID: selectedCompetitionUID.value}),
          submit: async () => {
            try {
              busy.value = true
              await Promise.all([
                clearGameTeamAssignments(axiosAuthBackgroundInstance, {
                  competitionUID: selectedCompetitionUID.value,
                  divID: selectedDivID.value,
                  dateFromInclusive: dateFromInclusive.value,
                  dateToInclusive: dateToInclusive.value,
                }),
                sleep(250)
              ])
              iziToast.success({message: "Team assignments for requested date range cleared."})
              await doNaiveReloadFromCurrentViewOptions()
            }
            catch (err) {
              AxiosErrorWrapper.rethrowIfNotAxiosError(err)
            }
            finally {
              busy.value = false
            }
          }
      })

      return v;
    })()

    const maybeRefreshRoundOptions = async () : Promise<void> => {
      const seasonUID = rounds_selections.value.season?.seasonUID
      const competitionUID = rounds_selections.value.competitionUID
      const divID = rounds_selections.value.divID
      const poolID = rounds_selections.value.poolID

      if (seasonUID && competitionUID && divID && poolID) {
        const rounds = await rounds_roundOptionsSource.run(() => listRounds(axiosAuthBackgroundInstance, {seasonUID, competitionUID, divID, poolID})).getResolvedOrFail()
        if (!rounds.find(v => v.roundID === rounds_selections.value.roundID)) {
          rounds_selections.value.roundID = forceCheckedIndexedAccess(rounds, 0)?.roundID ?? ""
        }
        if (bulkSelectState.value?.type === "rounds") {
          if (rounds_selections.value.roundID) {
            resetBulkSelectState("rounds")
          }
          else {
            resetBulkSelectState(null)
          }
        }
      }
      else {
        if (bulkSelectState.value?.type === "rounds") {
          resetBulkSelectState(null)
        }
      }
    }

    const view_compDependentOptions = (() => {
      let runID = 0
      const p = ReactiveReifiedPromise<GetCompDependentViewOptionsResponse>()

      function loadOptions(args: {competitionUIDs: Guid[], debounce?: boolean}) {
        return p.run(async () => {
          if (args.debounce ?? true) {
            const thisID = runID++
            await new Promise(resolve => setTimeout(resolve, 500))
            if (thisID != runID - 1) {
              return {divisions: []}
            }
          }

          return await getCompDependentViewOptions(axiosAuthBackgroundInstance, {competitionUIDs: args.competitionUIDs});
        })
      }
      return {
        value: p,
        loadOptions
      }
    })();

    const ready = ref(false)

    const games = GameLayoutTreeStore()

    const roundColors = computed(() => {
      const colors = randomCssColors();

      const roundIDs = new Set<string>()

      // note: by using roundIDs for "games that are in the tree", colors can jump around
      // when games are inserted or deleted and they were the first / last of their roundID.
      // We might want to stabilize them for at least one session.
      games.traverseAllGames(node => {
        if (node.data.type === "game") {
          roundIDs.add(node.data.data.roundID)
        }
        return "continue"
      })

      // arbitrary but consistent sort
      return new Map<string, CalendarElementStyle>([...roundIDs].sort().map((roundID, i) => {
        const v = colors[i % colors.length]
        return [roundID, {body: v, title: v}]
      }))
    })

    /**
     * Is this node for a something that isn't interactive -- where "is not interactive" means "user didn't select to see it, or doesn't have permissions to do anything with it,
     * but we still want to display it because users will need to know what else is schedule for some field".
     */
    const isNonInteractiveLayoutNode = (layoutNode: LayoutNodeRoot<GameCalendarUiElement> | LayoutNode<GameCalendarUiElement>) => {
      if (!layoutNode.parent) {
        // this is the tree root node
        return false;
      }

      if (layoutNode.data.type === "fieldBlock") {
        return false
      }

      if (view_focusOnBracketGames.value && !layoutNode.data.data.bracketRoundSlot) {
        return true
      }

      return !view_selectedDivIDs.value.has(layoutNode.data.data.divID)
        || !view_selectedCompetitionUIDs.value.has(layoutNode.data.data.competitionUID)
    }

    const getCalendarElementStylesEx = (layoutNode: LayoutNodeRoot<GameCalendarUiElement> | LayoutNode<GameCalendarUiElement>) => {
      if (!layoutNode.parent) {
        // TODO: instead of null, return some default (which we'll never use anyway, because we don't draw root nodes)
        return null
      }

      if (isNonInteractiveLayoutNode(layoutNode)) {
        return {
          title: {backgroundColor: "black", color: "white"},
          body: {backgroundColor: "gray", color: "white"}
        };
      }

      return get(layoutNode.data)

      function get(elem: GameCalendarUiElement) : CalendarElementStyle {
        if (elem.type === "fieldBlock") {
          const v = {backgroundColor: "gray", color: "white"}
          return {title: v, body: v}
        }

        if (
          elem.type === "game"
            && view_selectedCompetitionUIDs.value.has(elem.data.competitionUID)
            && view_selectedDivIDs.value.has(elem.data.divID)
            && elem.data.hasSomeIntraFieldConflict
        ) {
          // This is a game, where the user has positively selected
          // (and implicitly has permission to select) the relevant comp/div,
          // and the game has some intrafield conflict that we want to visually indicate.
          return {
            title: {
              backgroundColor: "black",
              color: "white",
            },
            body: {
              backgroundColor: "yellow",
              color: "black",
            },
          }
        }

        if (view_coloringScheme.value === ColoringScheme.division) {
          return calendarElementStylingByDivision[elem.data.division] ?? calendarElementStylingDefault
        }
        else if (view_coloringScheme.value === ColoringScheme.round) {
          // should always find it, but fallback to something useful if we don't
          return roundColors.value.get(elem.data.roundID) ?? calendarElementStylingDefault
        }
        else {
          exhaustiveCaseGuard(view_coloringScheme.value)
        }
      }
    }

    /**
     * The hours we intend to draw for the calendar view.
     * We expect this to be contiguous (i.e. no holes).
     */
    const hours = computed<number[]>(() => {
      const minDisplayHour = view_fullDayDisplay.value ? 0 : 7;
      const v : number[] = []
      for (let i = minDisplayHour; i <= 23; i++) {
        v.push(i)
      }
      return v;
    })

    const {
      px_leftColWidth,
      px_perFieldColWidthMinMax,
      px_cellBorderAndGridlineThickness,
      px_perHourCellHeightMinMax,
      px_perHourCellHeight,
      px_perFieldColWidth,
      gridSlicesPerHour,
      gridSlicesPerHourOptions,
    } = CommonCalendarLayoutData()

    const elemVerticalResizer = CalendarElementVerticalResizer<GameCalendarUiElement>()
    const elemMover = CalendarElementMover<GameCalendarUiElement>()
    elemVerticalResizer.onResizeCommitted(async ({layoutNode: originalNode, date, field, preMutationGameDate}) => {
      // when a resize is committed, redo the layout in the affected (game, field) column
      const gameStart = originalNode.data.uiState.time.start
      const gameEnd = originalNode.data.uiState.time.end
      const durationMinutes = Math.floor((gameEnd.unix() - gameStart.unix()) / 60)

      let freshSource : GameCalendarUiElement

      const isNoOpMove = isSameFieldTime(
        {fieldUID: originalNode.data.data.fieldUID, startUnix: originalNode.data.uiState.time.start.unix(), endUnix: originalNode.data.uiState.time.end.unix()},
        // n.b. field cannot change in a resize, we only adjust time, so fieldUID is always the same for each here
        {fieldUID: originalNode.data.data.fieldUID, startUnix: preMutationGameDate.start.unix(), endUnix: preMutationGameDate.end.unix()},
      )

      if (isNoOpMove) {
        return false
      }

      try {
        try {
          originalNode.data.uiState.isSaving = true
          if (originalNode.data.type === "game") {
            const game = await updateGame(axiosAuthBackgroundInstance, {
              gameID: originalNode.data.data.gameID,
              startDate: gameStart.format(DAYJS_FORMAT_IL_API_LOCALDATE),
              startHour: gameStart.hour(),
              startMinute: gameStart.minute(),
              gameDurationMinutes: durationMinutes,
            })

            freshSource = GameCalendarUiElement("game", game)

            const wasBecame = {was: originalNode.data.data, became: game}
            undoStack.push({
              type: "histUpdated",
              fieldBlocks: [],
              games: [wasBecame],
              msg: undoStack.histMsg_update_verticalResize({type: "game", ...wasBecame})
            })
          }
          else if (originalNode.data.type === "fieldBlock") {
            const fieldBlock = await updateFieldBlock(axiosAuthBackgroundInstance, {
              id: originalNode.data.data.id,
              startDate: gameStart.format(DAYJS_FORMAT_IL_API_LOCALDATE),
              startHour: gameStart.hour(),
              startMinute: gameStart.minute(),
              lengthMinutes: durationMinutes
            })

            freshSource = GameCalendarUiElement("fieldBlock", fieldBlock)

            const wasBecame = {was: originalNode.data.data, became: fieldBlock}
            undoStack.push({
              type: "histUpdated",
              fieldBlocks: [wasBecame],
              games: [],
              msg: undoStack.histMsg_update_verticalResize({type: "fieldBlock", ...wasBecame})
            })
          }
          else {
            exhaustiveCaseGuard(originalNode.data)
          }
        }
        finally {
          originalNode.data.uiState.isSaving = false
        }

        {
          const date = gameStart.toString()
          games.forEachGame({date, fieldUID: originalNode.data.data.fieldUID}, node => {
            node.data.uiState.isBusy = true
            return "continue"
          })

          await games.reloadFieldByDate(axiosAuthBackgroundInstance, {date, fieldUID: originalNode.data.data.fieldUID})
        }

        return true
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        return false
      }
    })

    const isSameFieldTime = (a: {fieldUID: Guid, startUnix: number, endUnix: number}, b: typeof a) => {
      return a.fieldUID === b.fieldUID && a.startUnix === b.startUnix && a.endUnix === b.endUnix
    }

    const doHandleDropCompletingCalendarElementMove = async () : Promise<void> => {
      assertTruthy(elemMover.isMoving, "doesn't make sense to call this if no game is being moved");

      const sourceNode = requireNonNull(elemMover.maybeGetSourceNode())

      const oldFieldUID = sourceNode.data.data.fieldUID
      const newDate = elemMover.currentDate
      const newFieldUID = elemMover.currentFieldUID

      const movee = requireNonNull(elemMover.maybeGetMovee({date: newDate, fieldUID: newFieldUID}))

      const isNoOpMove = isSameFieldTime(
        {fieldUID: oldFieldUID, startUnix: sourceNode.data.uiState.time.start.unix(), endUnix: sourceNode.data.uiState.time.end.unix()},
        {fieldUID: newFieldUID, startUnix: movee.data.uiState.time.start.unix(), endUnix: movee.data.uiState.time.end.unix()},
      )

      if (authZ_canDragOrResizeNode(movee, games.authZByCompDiv)) {
        if (isNoOpMove) {
          // do nothing
        }
        else {
          await doit()
        }
      }

      //
      // This will do "the right thing" whether we errored or succeeded.
      // - In the error case, we clear out `elemMover` state, and reset the "source node"'s drag state.
      //   The source node will remain in the tree, unchanged by the drag.
      // - In the success case, we clear out `elemMover` state, and while that does reset "source node"'s
      //   drag state, the source node will no longer be in the tree. Instead we have an entirely new node
      //   where it was dragged to, constructed when rebuilding the target column's tree.
      //
      elemMover.tryReset()

      async function doit() {
        return await elemMover.withIsAsyncCompleting(async () => {
          movee.data.uiState.isSaving = true

          try {
            const start = movee.data.uiState.time.start
            const end = movee.data.uiState.time.end
            const durationMinutes = Math.floor((end.unix() - start.unix()) / 60)

            if (movee.data.type === "game") {
              const sourceNode = requireNonNull(elemMover.maybeGetSourceNode())
              assertIs(sourceNode.data.type, "game")
              const originalGame = copyViaJsonRoundTrip(sourceNode.data.data)

              const args : UpdateGameRequest = {
                gameID: movee.data.data.gameID,
                startDate: start.format(DAYJS_FORMAT_IL_API_LOCALDATE),
                startHour: start.hour(),
                startMinute: start.minute(),
                gameDurationMinutes: durationMinutes,
                fieldUID: newFieldUID,
              }

              const game = await updateGame(axiosAuthBackgroundInstance, args)
              const wasBecame = {was: originalGame, became: game}

              undoStack.push({
                type: "histUpdated",
                fieldBlocks: [],
                games: [wasBecame],
                msg: undoStack.histMsg_update_move({type: "game", ...wasBecame})
              })
            }
            else if (movee.data.type === "fieldBlock") {
              const sourceNode = requireNonNull(elemMover.maybeGetSourceNode())
              assertIs(sourceNode.data.type, "fieldBlock")
              const originalFieldBlock = copyViaJsonRoundTrip(sourceNode.data.data)

              const args : UpdateFieldBlockRequest = {
                id: movee.data.data.id,
                startDate: start.format(DAYJS_FORMAT_IL_API_LOCALDATE),
                startHour: start.hour(),
                startMinute: start.minute(),
                lengthMinutes: durationMinutes,
                fieldUID: newFieldUID,
              }

              const fieldBlock = await updateFieldBlock(axiosAuthBackgroundInstance, args)
              const wasBecame = {was: originalFieldBlock, became: fieldBlock}

              undoStack.push({
                type: "histUpdated",
                fieldBlocks: [wasBecame],
                games: [],
                msg: undoStack.histMsg_update_move({type: "fieldBlock", ...wasBecame})
              })
            }
            else {
              exhaustiveCaseGuard(movee.data)
            }
          }
          catch (err) {
            AxiosErrorWrapper.rethrowIfNotAxiosError(err)
            return
          }

          await updateSourceAndTargetTrees({
            sourceDate: elemMover.initialDate,
            sourceFieldUID: elemMover.initialFieldUID,
            targetDate: newDate,
            targetFieldUID: newFieldUID
          })
        })

      }
    }

    async function updateSourceAndTargetTrees(args: {
      sourceDate: Datelike | Dayjs,
      sourceFieldUID: Guid,
      targetDate: Datelike | Dayjs,
      targetFieldUID: Guid
    }) {
      // whatever source (date, field) we moved _from_ needs to be updated as its conflicts may have changed
      const needsReloadSource = true
      // if we moved across dates and/or fields, we also need to update the target (date, field)
      const needsReloadTarget = !dayjs(args.sourceDate).isSame(args.targetDate, "day") || args.sourceFieldUID !== args.targetFieldUID

      if (needsReloadSource) {
        games.forEachGame(
          {date: args.sourceDate, fieldUID: args.sourceFieldUID},
          node => {
            node.data.uiState.isBusy = true
            return "continue"
          },
        );
      }

      if (needsReloadTarget) {
        games.forEachGame(
          {date: args.targetDate, fieldUID: args.targetFieldUID},
          node => {
            node.data.uiState.isBusy = true
            return "continue"
          },
        );
      }

      if (needsReloadSource) {
        await games.reloadFieldByDate(axiosAuthBackgroundInstance, {date: args.sourceDate, fieldUID: args.sourceFieldUID});
      }
      if (needsReloadTarget) {
        await games.reloadFieldByDate(axiosAuthBackgroundInstance, {date: args.targetDate, fieldUID: args.targetFieldUID});
      }
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const viewOptions = await getNonDependentCrudAndViewOptions(axiosInstance);

        {
          // init view options
          const viewOptsFromLocalStorage = viewOptions_localStorage_load(requireNonNull(User.userData?.userID))

          view_focusOnBracketGames.value = props.queryParams?.focusOnBracketGames /*weakeq*/ == "1";

          view_coloringScheme.value = viewOptsFromLocalStorage.data.coloringScheme

          view_competitionOptions.value = viewOptions.competitions
          view_selectedCompetitionUIDs
            .value
            // add from queryparams or local storage
            .addMany(props.queryParams?.competitionUIDs ?? (viewOptsFromLocalStorage.didLoad ? viewOptsFromLocalStorage.data.competitionUIDs : []) ?? [])
            // but only keep the ones that make sense
            .intersect(view_competitionOptions.value.map(v => v.competitionUID))

          if (view_selectedCompetitionUIDs.value.size === 0) {
            // if we ended up with nothing selected, selected everything available
            view_selectedCompetitionUIDs.value.addMany(view_competitionOptions.value.map(v => v.competitionUID))
          }

          const {divisions} = await view_compDependentOptions.loadOptions({competitionUIDs: [...view_selectedCompetitionUIDs.value], debounce: false}).getResolvedOrFail()
          // options don't need to be assigned here, they're now in the loader object and passed directly via the loader object to use sites
          view_selectedDivIDs
            .value
            // add from queryparams or local storage
            .addMany(props.queryParams?.divIDs ?? (viewOptsFromLocalStorage.didLoad ? viewOptsFromLocalStorage.data.divIDs : []) ?? [])
            // but only keep the ones that make sense
            .intersect(divisions.map(v => v.divID))

          if (view_selectedDivIDs.value.size === 0) {
            // if we ended up with nothing selected, selected everything available
            view_selectedDivIDs.value.addMany(divisions.map(v => v.divID))
          }

          view_fieldOptions.value = viewOptions.fields.map((v, i) => {
            return {
              fieldUID: v.fieldUID,
              fieldAbbrev: v.fieldAbbrev,
              fieldName: v.fieldName,
              fieldID: v.fieldID,
              checked: (() => {
                if (props.queryParams?.fieldUIDs) {
                  return !!props.queryParams.fieldUIDs.includes(v.fieldUID)
                }

                return viewOptsFromLocalStorage.didLoad
                  ? !!viewOptsFromLocalStorage.data.fieldUIDs.find(fieldUID => fieldUID === v.fieldUID)
                  : true;
              })()
            }
          })

          if (!view_fieldOptions.value.find(v => v.checked)) {
            // if no field was checked, then check all the fields
            view_fieldOptions.value.forEach(v => { v.checked = true })
          }

          // Initialize the dateFrom/dateTo values appropriately, based on the first selected competition.
          const firstSelectedOrFirstComp = view_competitionOptions.value.find(v => view_selectedCompetitionUIDs.value.has(v.competitionUID))
            || forceCheckedIndexedAccess(view_competitionOptions.value, 0);

          if (firstSelectedOrFirstComp) {
            const from = props.queryParams?.dateFrom ?? bestUpcomingOrCurrentCompetitionStartDayOfWeek(firstSelectedOrFirstComp.startDayOfWeek)
            view_dateFrom.value = from.format(DAYJS_FORMAT_HTML_DATE)
            view_dateToInclusive.value = (props.queryParams?.dateFrom && props.queryParams?.dateTo
              ? props.queryParams.dateTo
              : from.add(2, "days")
            ).format(DAYJS_FORMAT_HTML_DATE);
          }
        }

        {
          // init some crud options - note we don't check for permissions first; these are cheap to do and
          // creating the options has no effect on what we offer to the user after later permissions checks.
          // n.b. shared w/ view options because comps are the "root" of the menu and so are not dependent on anything
          crud_competitionOptions.value = viewOptions.competitions.map(comp => {
            return {
              label: comp.competition,
              value: comp.competitionUID
            }
          })

          if (crud_competitionOptions.value.length === 0) {
            crud_competitionOptions.value = [{label: "No available options", value: ""}]
          }

          // n.b. shared w/ view options because fields are not dependent on anything
          crud_fieldOptions.value = viewOptions.fields.map(field => {
            return {
              label: field.fieldName + ' (' + field.fieldAbbrev + ')',
              value: field.fieldUID
            }
          })
        }

        if (authZ.value.canCrudGames) {
          const competitionUID = forceCheckedIndexedAccess(crud_competitionOptions.value, 0)?.value ?? ""

          if (competitionUID) {
            const {currentSeason, divID} = await (async () => {
              const {competition, divisions} = await createGame_compDependentOptions.loadOptions({competitionUID, oldSeason: null, debounce: false}).getResolvedOrFail()
              return {
                currentSeason: competition.currentSeason,
                // should always have at least one, right?
                divID: forceCheckedIndexedAccess(divisions, 0)?.divID ?? ""
              }
            })();

            if (divID) {
              const {poolID} = await (async () => {
                const {pools} = await createGame_compDivSeasonDependentOptions.loadOptions({competitionUID, divID, seasonUID: currentSeason.seasonUID, debounce: false}, axiosInstance).getResolvedOrFail()
                return {
                  poolID: pools[0]?.poolID || k_POOL_ALL
                } as const
              })();

              createGameForm.value = freshCreateGameForm({
                competitionUID,
                divID,
                season: currentSeason,
                fieldUID: forceCheckedIndexedAccess(crud_fieldOptions.value, 0)?.value ?? "",
                poolID: poolID,
              }, {startDate: view_dateFrom.value})
            }
            else {
              createGameForm.value = emptyForm();
            }
          }
          else {
            createGameForm.value = emptyForm();
          }

          function emptyForm() {
            // this is probably buggy, we might want to leave the create game from null in the "don't have enough info to create a create game form" case?
            return freshCreateGameForm({
              competitionUID,
              divID: "",
              season: {seasonID: 1, seasonName: "", seasonUID: ""},
              fieldUID: forceCheckedIndexedAccess(crud_fieldOptions.value, 0)?.value ?? "",
              poolID: "ALL",
            })
          }
        }

        if (authZ.value.canCrudFieldBlocks) {
          createFieldBlockForm.value = freshCreateFieldBlockForm({
            fieldUID: forceCheckedIndexedAccess(crud_fieldOptions.value, 0)?.value ?? ""
          }, {startDate: view_dateFrom.value})
        }

        // init "rounds" options
        {
          // const competitionUID = ""
          // const competitions = await rounds_compDependentOptions.loadOptions({competitionUID, oldSeason: null}).getResolvedOrFail()
        }

        await doNaiveReloadFromCurrentViewOptions()

        ready.value = true
      })
    })

    const doNaiveReloadFromCurrentViewOptions = async () : Promise<void> => {
      const competitionUIDs = [...view_selectedCompetitionUIDs.value]
      const divIDs = [...view_selectedDivIDs.value]
      const fields = view_fieldOptions.value.filter(v => v.checked)
      const startDate = view_dateFrom.value
      const endDate = view_dateToInclusive.value

      GlobalInteractionBlockingRequestsInFlight.withSpinner(() => Promise.all([
        games.naiveFullReload({
          competitionUIDs,
          divIDs,
          fields,
          startDateInclusive: startDate,
          endDateInclusive: endDate,
          onlyShowSelectedDatesAndFieldsHavingGames: view_onlyShowSelectedDatesAndFieldsHavingGames.value,
        }),
        sleep(250)
      ]))

      // We could be smarter here, and update bulk selections based on the intersection of the exisiting bulk selection
      // and the now-loaded games.
      resetBulkSelectState("current!")
    }

    const doRunNextUndoAction = async () : Promise<void> => {
      if (isRunningUndo.value) {
        return
      }

      const action = undoStack.top()

      if (!action) {
        // shouldn't happen
        return;
      }

      try {
        isRunningUndo.value = true
        switch (action.type) {
          case "histCreated": {
            // we might not find all or any of them, if the layout has changed to no longer include the target dates/fields
            const elems = [...action.createdGames, ...action.createdFieldBlocks]
            const nodes = elems.map(v => games.findLayoutNode(v)).filter(v => !!v)

            try {
              try {
                nodes.forEach(node => { node.data.uiState.isSaving = true })
                await deleteGamesAndFieldBlocks(axiosAuthBackgroundInstance, {
                  gameIDs: elems
                    .filter(v => v.type === "game")
                    .map(v => v.data.gameID),
                  fieldBlockIDs: elems
                    .filter(v => v.type === "fieldBlock")
                    .map(v => v.data.id),
                })
              }
              finally {
                nodes.forEach(node => { node.data.uiState.isSaving = false })
              }

              games.deleteFromTreeRetainingChildren(nodes)
              games.resortAllUniqueOwnersOf(nodes)
            }
            catch (err) {
              AxiosErrorWrapper.rethrowIfNotAxiosError(err)
            }
            undoStack.pop()
            return;
          }
          case "histUpdated": {
            const games = action.games.map(v => gameAsUpdateGameRequest(v.was))
            const fieldBlocks = action.fieldBlocks.map(v => fieldBlockAsUpdateFieldBlockRequest(v.was))
            await bulkUpdateGamesAndFieldBlocks(axiosAuthBackgroundInstance, {games, fieldBlocks})
            undoStack.pop()
            await doNaiveReloadFromCurrentViewOptions()
            return
          }
          case "histDeleted": {
            await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
              // Would be better if create games/fieldBlocks was 1 transactional network call
              const newFieldBlocks = await Promise.all(action.deletedFieldBlocks.map(async v => {
                const r = await createFieldBlocks(axiosInstance, v)
                // our requests should create one each
                assertTruthy(r.length === 1)
                return r[0];
              }))
              // exception at this point means zero-or-more of the field blocks were re-created.
              // Retrying the undo stack means we may recreate duplicate field blocks.
              const result = await createGames(axiosInstance, {mode: "game-scheduler-calendar", each: action.deletedGames})
              // The "create many" games endpoint is transactional; an exception at this point means fieldBlock changes
              // persisted but games did not, retrying the undo stack means we may recreate duplicate field blocks.
              await doNaiveReloadFromCurrentViewOptions()

              undoStack.pop()

              // createGames and fieldBlocks exactly match what we expect;
              // also the returned order MUST be the same as the requested order, to map "oldIDs" to "newIDs",
              // but we can't really check that here
              assertTruthy(result.games.length === action.deletedGames.length)
              assertTruthy(newFieldBlocks.length === action.deletedFieldBlocks.length)

              undoStack.patchRecreatedItems(
                result.games.map((e,i) => ({type: "game" as const, oldID: action.deletedGames[i].oldGameID, newID: e.gameID})),
                newFieldBlocks.map((e,i) => ({type: "fieldBlock" as const, oldID: action.deletedFieldBlocks[i].oldID, newID: e.id})),
              );
            });
            return
          }
          case "histGameSwap": {
            await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
              try {
                await swapGames(axiosInstance, {gameIDs: action.gameIDs})
                undoStack.pop()
                await doNaiveReloadFromCurrentViewOptions()
              }
              catch (err) {
                AxiosErrorWrapper.rethrowIfNotAxiosError(err)
              }
            })
            return;
          }
          default: exhaustiveCaseGuard(action);
        }
      }
      finally {
        isRunningUndo.value = false
      }
    }

    /**
     * If `args` is not provided, we will generate them from the "current create games form",
     * where it is assumed that there is a fully-filled out current create games form.
     */
    const doCreateGameSlots = async (_: {pushUndoFrame: boolean, args?: CreateGameRequest[]}) : Promise<void> => {
      if (!authZ.value.canCrudGames) {
        return
      }

      const {pushUndoFrame, args} = _

      if (!args) {
        assertNonNull(createGameForm.value, "non-null if user can create games")
        return await doCreateGameSlots({
          pushUndoFrame,
          args: [{
            ...createGameForm.value, scheduleEvenIfBlocked: false, acknowledgedConflicts: [], tags: []
          }]
        })
      }

      try {
        const {games, blocked, intraFieldConflictCount} = await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const result = await createGames(axiosInstance, {mode: "game-scheduler-calendar", each: args})
          const gameIDs = result.games.map(v => v.gameID).filter(v => v !== null);
          return {
            games: (await getGamesAndBlocksForGameSchedulerView(axiosInstance, {gameIDs, fieldBlockIDs: []})).games,
            blocked: result.blocked,
            intraFieldConflictCount: result.games.filter(v => v.hasSomeIntraFieldConflict).length
          }
        })

        // TODO: can we be more targeted?
        await doNaiveReloadFromCurrentViewOptions()

        if (pushUndoFrame && games.length > 0) {
          undoStack.push({
            type: "histCreated",
            createdGames: games.map(v => ({type: "game", data: v})),
            createdFieldBlocks: [],
            msg: undoStack.histMsg_createdGames(games)
          })
        }

        const pluralGames = games.length === 1 ? "" : "s";

        if (intraFieldConflictCount > 0) {
          iziToast.warning({message: `${intraFieldConflictCount} of the generated games overlap existing games.`});
        }

        if (games.length > 0) {
          iziToast.success({message: `${games.length} game${pluralGames} created.`})
        }

        if (blocked.length > 0) {
          const pluralBlocked = blocked.length === 1 ? "" : "s";
          const wasOrWere = blocked.length === 1 ? "was not created" : "were not created"
          iziToast.warning({message: `${blocked.length} game${pluralBlocked} ${wasOrWere} due to ${pluralBlocked ? "conflicts with one or more field blocks." : "a conflict with a field block."}`});
        }

      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doCreateFieldBlocks = async (_: {pushUndoFrame: boolean, args?: CreateFieldBlockRequest}) : Promise<void> => {
      if (!authZ.value.canCrudFieldBlocks) {
        return;
      }

      const {pushUndoFrame, args} = _

      if (!args) {
        const form = requireNonNull(createFieldBlockForm.value, "non-null if user can create field blocks")
        const time : Pick<CreateFieldBlockForm, "startHour" | "startMinute" | "lengthMinutes"> = form.blockEntireDay
          ? {startHour: 0, startMinute: 0, lengthMinutes: 24 * 60}
          : form

        return await doCreateFieldBlocks({
          pushUndoFrame,
          args: {
            fieldUID: form.fieldUID,
            startDate: form.startDate,
            startHour: time.startHour,
            startMinute: time.startMinute,
            lengthMinutes: time.lengthMinutes,
            repeatWeeks: form.repeatWeeks,
            comment: form.comment,
            scheduleEvenIfBlocked: true,
          }
        })
      }

      try {
        const result = await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const createResults = await createFieldBlocks(axiosInstance, args)
          const fieldBlockIDs = createResults.map(v => v.id)
          return (await getGamesAndBlocksForGameSchedulerView(axiosInstance, {gameIDs: [], fieldBlockIDs})).fieldBlocks
        });

        if (pushUndoFrame) {
          undoStack.push({
            type: "histCreated",
            createdGames: [],
            createdFieldBlocks: result.map(v => ({type: "fieldBlock", data: v})),
            msg: undoStack.histMsg_createdFieldBlocks(result),
          });
        }

        await doNaiveReloadFromCurrentViewOptions()

        const plural = result.length <= 1 ? "" : "s"
        iziToast.success({message: `${result.length} field block${plural} created`})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doBulkUpdateFieldUIDs = async (freshFieldUID: Guid) : Promise<void> => {
      assertNonNull(bulkSelectState.value)
      const field = requireNonNull(games.getField(freshFieldUID))
      const nodes = bulkSelectState.value.selectedNodes;
      const gamesToUpdate = nodes.map(node => node.data).filter(elem => elem.type === "game").map(elem => elem.data)
      const fieldBlocksToUpdate = nodes.map(node => node.data).filter(elem => elem.type === "fieldBlock").map(elem => elem.data)

      try {
        nodes.forEach(node => { node.data.uiState.isSaving = true })

        // TODO: "bulkBusy" or something so we can avoid the global spinner
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const updated = await bulkUpdateGamesAndFieldBlocks(axiosInstance, {
            games: gamesToUpdate.map(v => ({gameID: v.gameID, fieldUID: freshFieldUID})),
            fieldBlocks: fieldBlocksToUpdate.map(v => ({id: v.id, fieldUID: freshFieldUID}))
          })

          const undoAction : HistUpdated = {
            type: "histUpdated",
            games: (() => {
              assertTruthy(gamesToUpdate.length === updated.games.length)
              const result : Updated<GameForGameSchedulerView>[] = []
              for (let i = 0; i < gamesToUpdate.length; i++) {
                const was = gamesToUpdate[i]
                const became = updated.games[i]
                result.push({was, became})
              }
              return result;
            })(),
            fieldBlocks: (() => {
              assertTruthy(fieldBlocksToUpdate.length === updated.fieldBlocks.length)
              const result : Updated<FieldBlockForGameSchedulerView>[] = []
              for (let i = 0; i < fieldBlocksToUpdate.length; i++) {
                const was = fieldBlocksToUpdate[i]
                const became = updated.fieldBlocks[i]
                result.push({was, became})
              }
              return result;
            })(),
            msg: undoStack.histMsg_bulkReassignFields(gamesToUpdate.length, fieldBlocksToUpdate.length, field.fieldName),
          }

          // we just shuffled things all over the place, reload the whole view
          await doNaiveReloadFromCurrentViewOptions()

          undoStack.push(undoAction)
        })
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
      finally {
        nodes.forEach(node => { node.data.uiState.isSaving = false })
      }
    }

    const doUpdateGame = async () : Promise<void> => {
      assertIs(editGame_uiState.value.active, true)
      assertIs(editGame_uiState.value.form.type, "game")
      assertIs(editGame_uiState.value.node.data.type, "game")

      const originalGameNode = editGame_uiState.value.node
      const originalGameDate = editGame_uiState.value.node.data.uiState.time.start
      const originalGameFieldUID = editGame_uiState.value.node.data.data.fieldUID

      try {
        try {
          originalGameNode.data.uiState.isSaving = true
          editGame_busyUpdating.value = true

          const originalGameObj = copyViaJsonRoundTrip(editGame_uiState.value.node.data.data)

          const authZFilteredForm = filterUpdateGameRequestForAuthZ({
            req: editGameFormToUpdateGameRequest(editGame_uiState.value.form.data),
            originalCompetitionUID: editGame_uiState.value.node.data.data.competitionUID,
            newCompetitionUID: editGame_uiState.value.form.data.competitionUID,
            originalDivID: editGame_uiState.value.node.data.data.divID,
            newDivID: editGame_uiState.value.form.data.divID,
          })

          const game = await updateGame(axiosAuthBackgroundInstance, authZFilteredForm);

          undoStack.push({
            type: "histUpdated",
            fieldBlocks: [],
            games: [{
              was: originalGameObj,
              became: game,
            }],
            msg: undoStack.histMsg_update({type: "game", was: originalGameObj, became: game}),
          });

          await updateSourceAndTargetTrees({
            sourceDate: originalGameDate,
            sourceFieldUID: originalGameFieldUID,
            targetDate: game.gameStart,
            targetFieldUID: game.fieldUID,
          });

          editGame_uiState.value = {active: false, node: editGame_uiState.value.node, form: editGame_uiState.value.form};
        }
        finally {
          originalGameNode.data.uiState.isSaving = false
          editGame_busyUpdating.value = false
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doSwapGames = async ([gameID1, gameID2]: [Guid, Guid]) : Promise<void> => {
      assertIs(editGame_uiState.value.active, true)
      assertIs(editGame_uiState.value.form.type, "game")
      assertIs(editGame_uiState.value.node.data.type, "game")

      const originalGameNode = editGame_uiState.value.node

      try {
        try {
          originalGameNode.data.uiState.isSaving = true
          editGame_busyUpdating.value = true
          const swapResult = await swapGames(axiosAuthBackgroundInstance, {gameIDs: [gameID1, gameID2]})
          await doNaiveReloadFromCurrentViewOptions()
          editGame_uiState.value = {
            active: false,
            form: editGame_uiState.value.form,
            node: originalGameNode
          }

          undoStack.push({
            type: "histGameSwap",
            gameIDs: [gameID1, gameID2],
            msg: undoStack.histMsg_swapGame(swapResult)
          })
          iziToast.success({message: "Games swapped."})
        }
        finally {
          editGame_busyUpdating.value = false
          originalGameNode.data.uiState.isSaving = true
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doUpdateFieldBlock = async () : Promise<void> => {
      assertIs(editGame_uiState.value.active, true)
      assertIs(editGame_uiState.value.form.type, "fieldBlock")
      assertIs(editGame_uiState.value.node.data.type, "fieldBlock")

      const originalNode = editGame_uiState.value.node
      const originalGameDate = editGame_uiState.value.node.data.uiState.time.start
      const originalGameFieldUID = editGame_uiState.value.node.data.data.fieldUID

      try {
        try {
          originalNode.data.uiState.isSaving = true
          editGame_busyUpdating.value = true

          const originalFieldBlockObj = copyViaJsonRoundTrip(editGame_uiState.value.node.data.data)

          const fieldBlock = await updateFieldBlock(axiosAuthBackgroundInstance, editGame_uiState.value.form.data);

          games.deleteFromTreeRetainingChildren(originalNode)
          games.resort(originalGameDate, originalGameFieldUID)
          games.maybeInsertAndResort(GameCalendarUiElement("fieldBlock", fieldBlock))

          undoStack.push({
            type: "histUpdated",
            games: [],
            fieldBlocks: [{
              was: originalFieldBlockObj,
              became: fieldBlock,
            }],
            msg: undoStack.histMsg_update({type: "fieldBlock", was: originalFieldBlockObj, became: fieldBlock})
          })

          editGame_uiState.value = {
            active: false,
            node: editGame_uiState.value.node,
            form: editGame_uiState.value.form,
          };
        }
        finally {
          originalNode.data.uiState.isSaving = false
          editGame_busyUpdating.value = false
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doDeleteGamesAndFieldBlocks = async (nodes: LayoutNode<GameCalendarUiElement>[]) : Promise<void> => {
      try {
        const elems = nodes.map(v => v.data)
        const entities = {
          games: elems.filter(v => v.type === "game").map(v => v.data),
          fieldBlocks: elems.filter(v => v.type === "fieldBlock").map(v => v.data)
        }

        try {
          nodes.forEach(node => {node.data.uiState.isSaving = true})
          await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
            await deleteGamesAndFieldBlocks(axiosInstance, {gameIDs: entities.games.map(v => v.gameID), fieldBlockIDs: entities.fieldBlocks.map(v => v.id)})
          })
        }
        finally {
          nodes.forEach(node => {node.data.uiState.isSaving = false})
        }

        undoStack.push({
          type: "histDeleted",
          deletedGames: entities.games.map(gameAsCreateGameRequest),
          deletedFieldBlocks: entities.fieldBlocks.map(fieldBlockAsCreateFieldBlockRequest),
          msg: undoStack.histMsg_delete(entities.games, entities.fieldBlocks),
        })

        games.deleteFromTreeRetainingChildren(nodes)
        games.resortAllUniqueOwnersOf(nodes)
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doCancelEditGameOrFieldBlockState = () => {
      if (!editGame_uiState.value.active) {
        // nothing to do
        return;
      }
      editGame_uiState.value.node.data.uiState.isModalOrOverlayFocus = false
      editGame_uiState.value = {active: false, node: editGame_uiState.value.node, form: editGame_uiState.value.form}
    }

    const confirmDeleteModalController = reactive((() => {
      const busy = ref(false)
      let lastWorkingElement : null | GameCalendarUiElement = null

      const onOpenCB = (node: LayoutNode<GameCalendarUiElement>) => {
        node.data.uiState.isModalOrOverlayFocus = true
        lastWorkingElement = node.data
      }

      const onCloseCB = (close: () => void) => {
        if (busy.value) {
          return;
        }
        else {
          if (lastWorkingElement) {
            lastWorkingElement.uiState.isModalOrOverlayFocus = false
            lastWorkingElement = null
          }
          close()
        }
      }

      const doDeleteOne = async (layoutNode: LayoutNode<GameCalendarUiElement>) => {
        try {
          try {
            busy.value = true
            await doDeleteGamesAndFieldBlocks([layoutNode])
          }
          finally {
            busy.value = false
          }
          confirmDeleteModalController.close()
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      }

      return DefaultModalController<LayoutNode<GameCalendarUiElement>>({
        content: layoutNode => {
          return <>
            {layoutNode
              ? <ConfirmDeleteCalendarElement
                onCancel={() => confirmDeleteModalController.close()}
                onConfirm={async () => await doDeleteOne(layoutNode)}
                calendarElement={layoutNode.data}/>
              : null
            }
            {busy.value ? <DefaultTinySoccerballBusyOverlay/> : null}
          </>
        }
      }, {onOpenCB, onCloseCB})
    })());

    /**
     * Opens the editor pane for a single calendar element. We can only show one pane at a time, and the pane we show
     * should be the one the user clicked on last. Because opening the pane requires async data, we need to track which
     * request to open the pane was the most recent, and only "open the pane" for that one.
     */
    const openEditorPane = (() => {
      let mostRecentTarget : LayoutNode<GameCalendarUiElement> | null = null

      return async function(layoutNode: LayoutNode<GameCalendarUiElement>, domElement: HTMLElement) {
        if (mostRecentTarget) {
          mostRecentTarget.data.uiState.isOpeningEditPane = false
        }

        layoutNode.data.uiState.isOpeningEditPane = true
        mostRecentTarget = layoutNode

        try {
          if (layoutNode.data.type === "game") {
            const {seasonUID, competitionUID, divID} = layoutNode.data.data

            const [season] = await Promise.all([
              Client.getSeasonByUidOrFail(seasonUID, axiosAuthBackgroundInstance),
              editGame_compDependentOptions.loadOptions({competitionUID, oldSeason: null}).awaiter(),
              editGame_compDivSeasonDependentOptions.loadOptions({competitionUID, divID, seasonUID}).awaiter(),
            ])

            const editGameForm = freshEditGameForm(layoutNode.data, season)

            if (mostRecentTarget === layoutNode) {
              editGame_uiState.value = {
                active: true,
                form: {
                  type: "game",
                  data: editGameForm,
                  authZ: requireNonNull(games.authZByCompDiv.get(`${competitionUID}/${divID}`))
                },
                node: layoutNode,
                calendarElement: domElement
              }
            }
          }
          else if (layoutNode.data.type === "fieldBlock") {
            // no awaits, so we don't need to guard for "is most recent" here
            const editFieldBlockForm = freshEditFieldBlockForm(layoutNode.data)
            editGame_uiState.value = {
              active: true,
              form: {
                type: "fieldBlock",
                data: editFieldBlockForm,
              },
              node: layoutNode,
              calendarElement: domElement
            }
          }
          else {
            exhaustiveCaseGuard(layoutNode.data)
          }
        }
        finally {
          layoutNode.data.uiState.isOpeningEditPane = false
          if (mostRecentTarget === layoutNode) {
            mostRecentTarget = null
          }
        }
      }
    })();

    // "scoped" css
    const randomTableClass = `il-f12a5794-0f5f-40f4-b1c3-d8ca4741ac8e`

    const px_totalTableWidth = computed(() => px_leftColWidth + (games.totalFieldRenderCount * px_perFieldColWidth.renderValue))

    const rootRef = ref<HTMLElement | null>(null)

    const canDragOrResizeNode = (node: LayoutNodeRoot<any> | LayoutNode<any>) => {
      return !node.parent
        ? false
        : authZ_canDragOrResizeNode(node, games.authZByCompDiv) && !node.data.uiState.isBulkSelected
    }

    const authZ_canEditNodeViaOverlay = (node: LayoutNodeRoot<GameCalendarUiElement> | LayoutNode<GameCalendarUiElement>) : boolean => {
      if (!node.parent) {
        return false
      }

      switch (node.data.type) {
        case "game": {
          const authZ = games.authZByCompDiv.get(`${node.data.data.competitionUID}/${node.data.data.divID}`)
          // if they have any permission, they can "edit via the overlay"
          return !!authZ?.canCrudGames || !!authZ?.canEditGameFields || !!authZ?.canEditGameTeams || !!authZ?.canEditGameTimes;
        }
        case "fieldBlock": {
          return authZ_perAction.canCrudFieldBlocks()
        }
        default: exhaustiveCaseGuard(node.data)
      }
    }

    const layoutStrategy = {method: "warnOverlap"} as const;

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        // n.b. start a new z-layer to not fight with top nav bar or modals and etc.
        // We use z-index to stack the calendar elements (though we could maybe figure out how to draw them
        // in the right order so they don't need explicit Z)
        <div style={`--fk-margin-outer: none; position:relative; z-index:0; width: max(100%, ${px_totalTableWidth.value}px);`} ref={rootRef}>
          <AutoModal controller={confirmDeleteModalController}/>
          <GameEditorPaneOrModal
            uiState={editGame_uiState.value}
            label={(() => {
              if (editGame_uiState.value.active) {
                if (editGame_uiState.value.form.type === "game") {
                  return "Edit Game"
                }
                if (editGame_uiState.value.form.type === "fieldBlock") {
                  return "Edit Field Block"
                }
              }
              return ""
            })()}
            busyUpdating={editGame_busyUpdating.value}
            onCancel={() => doCancelEditGameOrFieldBlockState()}
            v-slots={{
              default: () => {
                assertNonNull(editGame_uiState.value.node, "should only be invoked/rendered when this is non-null")
                assertNonNull(editGame_uiState.value.form, "should only be invoked/rendered when this is non-null")
                if (editGame_uiState.value.form.type === "game") {
                  assertIs(editGame_uiState.value.node.data.type, "game")
                  return <EditGame
                    pristine={editGame_uiState.value.node.data.data}
                    editGameForm={editGame_uiState.value.form.data}
                    competitionOptions={crud_competitionOptions.value}
                    fieldOptions={crud_fieldOptions.value}
                    compDependentOptions={editGame_compDependentOptions.value}
                    compDivDependentOptions={editGame_compDivSeasonDependentOptions.value}
                    busyUpdating={editGame_busyUpdating.value}
                    authZ={editGame_uiState.value.form.authZ}
                    onUpdate:seasonUID={async (seasonUID) => {
                      assertIs(editGame_uiState.value.active, true)
                      assertIs(editGame_uiState.value.form.type, "game")
                      const form = editGame_uiState.value.form;
                      const season = arrayFindOrFail(
                        // is expected to already be resolved, but need to unwrap it
                        (await editGame_compDependentOptions.value.getResolvedOrFail()).seasons,
                        season => season.seasonUID === seasonUID
                      )
                      form.data.season = {...season}

                      editGame_compDivSeasonDependentOptions.loadOptions({
                        competitionUID: form.data.competitionUID,
                        divID: form.data.divID,
                        seasonUID: form.data.season.seasonUID
                      })
                    }}
                    onUpdate:competitionUID={async (competitionUID) => {
                      assertIs(editGame_uiState.value.active, true)
                      assertIs(editGame_uiState.value.form.type, "game")

                      const form = editGame_uiState.value.form;
                      form.data.competitionUID = competitionUID

                      editGame_compDivSeasonDependentOptions.value.forcePending();

                      const {divisions} = await editGame_compDependentOptions.loadOptions({
                        competitionUID: form.data.competitionUID,
                        oldSeason: form.data.season,
                      }).getResolvedOrFail()

                      updateEditGameFormCompDependentValues(form.data, divisions);

                      if (form.data.competitionUID && form.data.divID && form.data.season.seasonUID) {
                        const {pools, teams} = await editGame_compDivSeasonDependentOptions.loadOptions({
                          competitionUID: form.data.competitionUID,
                          divID: form.data.divID,
                          seasonUID: form.data.season.seasonUID,
                        }).getResolvedOrFail();

                        updateEditGameFormCompDivDependentValues(form.data, pools, teams)
                      }
                      else {
                        editGame_compDivSeasonDependentOptions.forceResolveNoOptions()
                        updateEditGameFormCompDivDependentValues(form.data, [], [])
                      }
                    }}
                    onUpdate:divID={async (divID) => {
                      if (divID === "") {
                        // it's possible to select a program for which there are no divisions having teams meaning no divisions will be available.
                        return
                      }
                      assertIs(editGame_uiState.value.active, true)
                      assertIs(editGame_uiState.value.form.type, "game")

                      const form = editGame_uiState.value.form;
                      form.data.divID = divID

                      const {pools, teams} = await editGame_compDivSeasonDependentOptions.loadOptions({
                        competitionUID: editGame_uiState.value.form.data.competitionUID,
                        divID: editGame_uiState.value.form.data.divID,
                        seasonUID: editGame_uiState.value.form.data.season.seasonUID,
                      }).getResolvedOrFail()

                      updateEditGameFormCompDivDependentValues(form.data, pools, teams);
                    }}
                    onCancel={() => doCancelEditGameOrFieldBlockState()}
                    onUpdateGame={() => doUpdateGame()}
                    onSwapGame={(args) => doSwapGames(args)}
                  />
                }
                else if (editGame_uiState.value.form.type === "fieldBlock") {
                  return <EditFieldBlock
                    editFieldBlockForm={editGame_uiState.value.form.data}
                    fieldOptions={crud_fieldOptions.value}
                    busyUpdating={editGame_busyUpdating.value}
                    onCancel={() => doCancelEditGameOrFieldBlockState()}
                    onUpdateFieldBlock={() => doUpdateFieldBlock()}
                  />
                }
                else {
                  exhaustiveCaseGuard(editGame_uiState.value.form)
                }
              }
            }}
          >
          </GameEditorPaneOrModal>
          <SchedulerControlsElement
            routeRootRef={rootRef.value}
            onLayoutChange={() => {
              expand_displayOptions.value = true
              expand_actions.value = true
            }}
          >
            {{
              default: ({useSideBySideLayout}) => <>
                <div style="grid-column:-1/1;">
                  <div class="text-sm rounded-md border flex justify-between my-2 h-full inline-flex">
                    <a
                      class="border-r hover:bg-[rgba(0,0,0,.03125)] active:bg-[rgba(0,0,0,.0625)] flex items-center px-2 il-link"
                      target="_blank"
                      href="https://gitlab.inleague.io/content/guides-and-documents/-/wikis/Game-Scheduling-Overview">Scheduling Help</a>
                    <a
                      class="border-r hover:bg-[rgba(0,0,0,.03125)] active:bg-[rgba(0,0,0,.0625)] flex items-center px-2 il-link"
                      target="_blank"
                      href={buildLegacyLink(Client.value.instanceConfig.appdomain, "/gameScheduler/clone-schedule", "")}>Clone Schedule</a>
                    <RouterLink
                      class="border-r hover:bg-[rgba(0,0,0,.03125)] active:bg-[rgba(0,0,0,.0625)] flex items-center px-2 il-link"
                      {...{target:"_blank"}}
                      to={R_Matchmaker.routeDetailToRouteLocation("Matchmaker")}>Generate Match-ups</RouterLink>
                    <a
                      class="hover:bg-[rgba(0,0,0,.03125)] active:bg-[rgba(0,0,0,.0625)] flex items-center px-2 il-link"
                      target="_blank"
                      href={buildLegacyLink(Client.value.instanceConfig.appdomain, "/Games/publish", "")}>Publish Schedules</a>
                  </div>
                </div>
                <div class="shadow-md border rounded-md h-full">
                  <div class="flex flex-col h-full">
                    <div class="p-1 rounded-t-md bg-gray-200">
                      <button disabled={useSideBySideLayout} type="button" style="padding:6px 6px;"
                        class={["flex", useSideBySideLayout ? undefined : `gap-2 rounded-md h-full hover:bg-[rgba(0,0,0,.0625)] active:[bg-rgba(0,0,0,.125)]`]}
                        onClick={() => {expand_displayOptions.value = !expand_displayOptions.value}}
                      >
                        {useSideBySideLayout
                          ? null
                          : <div class={`transition ease-out duration-75 transform ${expand_displayOptions.value ? "rotate-90" : ""}`}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                          </div>
                        }
                        <span class="flex items-center">Display Options</span>
                      </button>
                    </div>
                    {expand_displayOptions.value
                      ? <DisplayOptions
                        class="flex-grow p-2"
                        competitionOptions={view_competitionOptions.value}
                        selectedCompetitionUIDs={view_selectedCompetitionUIDs.value}
                        selectedDivIDs={view_selectedDivIDs.value}
                        compDependentViewOptions={view_compDependentOptions.value}
                        fields={view_fieldOptions.value}
                        dateFrom={view_dateFrom}
                        dateTo={view_dateToInclusive}
                        focusOnBracketGames={view_focusOnBracketGames}
                        fullDayDisplay={view_fullDayDisplay}
                        onlyShowSelectedDatesAndFieldsHavingGames={view_onlyShowSelectedDatesAndFieldsHavingGames.value}
                        onUpdate:onlyShowSelectedDatesAndFieldsHavingGames={async v => {
                          view_onlyShowSelectedDatesAndFieldsHavingGames.value = v
                          if (view_onlyShowSelectedDatesAndFieldsHavingGames.value) {
                            games.localRebuild({onlyShowSelectedDatesAndFieldsHavingGames: v})
                          }
                          else {
                            // Have to do a full reload, because with the current implementation we've dropped
                            // games locally that we don't want to show in the current tree.
                            // related: `GameLayoutTreeStore.data_gamesAndBlocks`
                            await doNaiveReloadFromCurrentViewOptions()
                          }
                        }}
                        gridSlicesPerHour={gridSlicesPerHour}
                        gridSlicesPerHourOptions={gridSlicesPerHourOptions}
                        colorScheme={view_coloringScheme}
                        colorSchemeOptions={view_coloringOptions}
                        px_perFieldColWidth={px_perFieldColWidth.formValue}
                        px_perFieldColWidthMinMax={px_perFieldColWidthMinMax}
                        px_perHourCellHeight={px_perHourCellHeight.formValue}
                        px_perHourCellHeightMinMax={px_perHourCellHeightMinMax}
                        onGetGamesAndFieldBlocks={async () => {
                          if (createGameForm.value) {
                            createGameForm.value.startDate = view_dateFrom.value
                          }
                          if (createFieldBlockForm.value) {
                            createFieldBlockForm.value.startDate = view_dateFrom.value
                          }
                          await doNaiveReloadFromCurrentViewOptions()
                        }}
                        onUpdateCompDependentViewOptions={() => {
                          view_compDependentOptions.loadOptions({competitionUIDs: [...view_selectedCompetitionUIDs.value]})
                        }}
                        onResetFieldCompDivFilters={async () => {
                          view_fieldOptions.value.forEach(v => {v.checked = true})
                          view_selectedCompetitionUIDs.value.addMany(view_competitionOptions.value.map(v => v.competitionUID))
                          view_compDependentOptions.loadOptions({competitionUIDs: [...view_selectedCompetitionUIDs.value]})
                          const divisions = (await view_compDependentOptions.value.getResolvedOrFail()).divisions
                          view_selectedDivIDs.value.addMany(divisions.map(v => v.divID))
                        }}
                      />
                      : null
                    }
                  </div>
                </div>
                <div class="shadow-md border rounded-md h-full">
                  <div class="p-1 rounded-t-md bg-gray-200">
                    <button disabled={useSideBySideLayout} type="button" style="padding:6px 6px;"
                      class={[`flex`, useSideBySideLayout ? undefined : `gap-2 rounded-md h-full hover:bg-[rgba(0,0,0,.0625)] active:[bg-rgba(0,0,0,.125)]`]}
                      onClick={() => {expand_actions.value = !expand_actions.value}}
                    >
                      {useSideBySideLayout
                        ? null
                        : <div class={`transition ease-out duration-75 transform ${expand_actions.value ? "rotate-90" : ""}`}>
                            <FontAwesomeIcon icon={faChevronRight}/>
                          </div>
                      }
                      <span class="flex items-center">Actions</span>
                    </button>
                  </div>
                  {expand_actions.value
                    ? <Actions
                      class="p-2"
                      selectedTabId={action_selectedTabId}
                      createGameForm={createGameForm.value}
                      createFieldBlockForm={createFieldBlockForm.value}
                      competitionOptions={crud_competitionOptions.value}
                      fieldOptions={crud_fieldOptions.value}
                      compDependentOptions={createGame_compDependentOptions.value}
                      compDivSeasonDependentOptions={createGame_compDivSeasonDependentOptions.value}
                      bulkSelectMode={bulkSelectState.value}
                      rounds_selections={rounds_selections.value}
                      rounds_compDependentOptions={rounds_compDependentOptions.value}
                      rounds_compDivSeasonDependentOptions={rounds_compDivSeasonDependentOptions.value}
                      rounds={rounds_roundOptionsSource}
                      clearGameAssignmentsState={clearGameAssignmentsState}
                      onCreateGames={() => doCreateGameSlots({pushUndoFrame: true})}
                      onCreateBlocks={() => doCreateFieldBlocks({pushUndoFrame: true})}
                      onUpdate:competitionUID={async (competitionUID) => {
                        // only called from the createGame action, so we only care about createGameFrom being non null
                        const form = requireNonNull(createGameForm.value)
                        form.competitionUID = competitionUID

                        const {competition, divisions} = await createGame_compDependentOptions.loadOptions({
                          competitionUID: form.competitionUID,
                          oldSeason: null,
                        }).getResolvedOrFail()

                        updateCreateGameFormCompDependentValues(form, competition.currentSeason, divisions)
                      }}
                      onUpdate:seasonUID={async (seasonUID) => {
                        // only called from the createGame action, so we only care about createGameFrom being non null
                        const form = requireNonNull(createGameForm.value)
                        const season = arrayFindOrFail(
                          // is expected to already be resolved, but need to unwrap it
                          (await createGame_compDependentOptions.value.getResolvedOrFail()).seasons,
                          season => season.seasonUID === seasonUID
                        )
                        form.season = {...season}
                      }}
                      onUpdate:divID={async (divID) => {
                        // only called from the createGame action, so we only care about createGameFrom being non null
                        const form = requireNonNull(createGameForm.value)

                        form.divID = divID

                        // We rely on the fact that createGame_compDependentOptions is expected to be already resolved here,
                        // coherent with the current state of createGame.
                        // TODO: we don't find this ... in tests at least sometimes ... is that expected? Maybe if there are no teams for the division ... it's a candidate but gets dropped.
                        // Honestly probably need to refactor all menu related updates to some object that manages async state, and look into onChange vs onInput on form elements
                        // so we don't get spurious updates events in response to programmatic updates not triggered by user input
                        const division = (await createGame_compDependentOptions.value.getResolvedOrFail()).divisions.find(div => div.divID === form.divID)

                        if (!division) {
                          updateCreateGameFormCompDivDependentValues(form, null, [])
                        }
                        else {
                          const {pools} = await createGame_compDivSeasonDependentOptions.loadOptions({
                            competitionUID: form.competitionUID,
                            divID: form.divID,
                            seasonUID: form.season.seasonUID,
                          }).getResolvedOrFail()
                          updateCreateGameFormCompDivDependentValues(form, division, pools)
                        }

                      }}
                      onUpdate:rounds_competitionUID={async competitionUID => {
                        rounds_selections.value.competitionUID = competitionUID
                        const comps = await rounds_compDependentOptions.loadOptions({competitionUID, oldSeason: null}).getResolvedOrFail()
                        updateRoundsCompDependentValues(rounds_selections.value, comps.competition.currentSeason, comps.divisions)
                        await maybeRefreshRoundOptions()
                      }}
                      onUpdate:rounds_seasonUID={async (seasonUID) => {
                        const season = arrayFindOrFail(
                          // is expected to already be resolved, but need to unwrap it
                          (await rounds_compDependentOptions.value.getResolvedOrFail()).seasons,
                          season => season.seasonUID === seasonUID
                        )
                        rounds_selections.value.season = {...season}
                        await maybeRefreshRoundOptions()
                      }}
                      onUpdate:rounds_divID={async (divID) => {
                        rounds_selections.value.divID = divID

                        // tricky - when we hit the first await and suspend to caller,
                        // we might not otherwise have marked this thing as having work being done on it.
                        // Really we are probably abusing "emit()" and working around the fact that we cannot
                        // await on it.
                        rounds_compDivSeasonDependentOptions.value.forcePending()

                        // We rely on the fact that createGame_compDependentOptions is expected to be already resolved here,
                        // coherent with the current state of createGame.
                        const division = arrayFindOrFail(
                          (await rounds_compDependentOptions.value.getResolvedOrFail()).divisions,
                          div => div.divID === divID
                        );

                        const {pools} = await rounds_compDivSeasonDependentOptions.loadOptions({
                          competitionUID: rounds_selections.value.competitionUID,
                          divID: rounds_selections.value.divID,
                          // we should have a selected seasonUID if we got here
                          seasonUID: requireNonNull(rounds_selections.value.season).seasonUID,
                        }).getResolvedOrFail()

                        updateRoundsCompDivDependentValues(rounds_selections.value, division, pools)
                        await maybeRefreshRoundOptions()
                      }}
                      onUpdate:rounds_roundID={roundID => {
                        rounds_selections.value.roundID = roundID
                        if (bulkSelectState.value?.type === "rounds") {
                          initBulkSelectRoundsMode()
                        }
                      }}
                      onStartBulkSelect={(mode) => {
                        switch (mode) {
                          case "generic": {
                            resetBulkSelectState("generic")
                            return
                          }
                          case "rounds": {
                            initBulkSelectRoundsMode()
                            return
                          }
                          default: exhaustiveCaseGuard(mode)
                        }
                      }}
                      onCancelBulkSelect={() => {
                        resetBulkSelectState(null)
                      }}
                      onDeleteBulkSelected={async () => {
                        assertNonNull(bulkSelectState.value)
                        await doDeleteGamesAndFieldBlocks(bulkSelectState.value.selectedNodes)
                        resetBulkSelectState(bulkSelectState.value.type)
                      }}
                      onBulkUpdateFields={async fieldUID => {
                        assertNonNull(bulkSelectState.value)
                        await doBulkUpdateFieldUIDs(fieldUID)
                        resetBulkSelectState(bulkSelectState.value.type)
                      }}
                      onCreateRound={async args => {
                        GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
                          const round = await createRound(axiosInstance, args)
                          await rounds_roundOptionsSource.run(() => listRounds(axiosAuthBackgroundInstance, args)).getResolvedOrFail()
                          rounds_selections.value.roundID = round.roundID
                        })
                      }}
                      onBulkAssignRounds={async () => {
                        assertNonNull(bulkSelectState.value)
                        assertTruthy(rounds_selections.value.roundID)

                        const roundID = rounds_selections.value.roundID
                        const updates = bulkSelectState.value.selectedNodes.map((v) => {
                          assertIs(v.data.type, "game") // should be allowing to select fields in "bulk rounds" mode
                          return {gameID: v.data.data.gameID, roundID}
                        })

                        GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
                          try {
                            const r = await bulkUpdateGamesAndFieldBlocks(axiosInstance, {
                              games: updates,
                              fieldBlocks: []
                            })

                            resetBulkSelectState("rounds")

                            games.insertOrReplaceMany(r.games.map(v => GameCalendarUiElement("game", v)))
                          }
                          catch (err) {
                            AxiosErrorWrapper.rethrowIfNotAxiosError(err)
                          }
                        })
                      }}
                    />
                    : null
                  }
                </div>
              </>
            } satisfies SchedulerControlsSlots}
          </SchedulerControlsElement>
          <div class="my-4 flex items-center gap-2 border p-1 rounded-md" style="grid-column: -1/1;">
            <Btn2 class="py-1 px-2" onClick={() => doRunNextUndoAction()} disabled={undoStack.size === 0 || isRunningUndo.value}>
              <div class="flex items-center gap-1">
                {isRunningUndo.value
                  ? <>
                    <SoccerBall color="lightgray" width="1.25em" height="1.25em"/>
                    <span>Working on it...</span>
                  </>
                  : <>
                    <div><FontAwesomeIcon icon={faUndo}/></div>
                    <div>Undo last action</div>
                  </>
                }
              </div>
            </Btn2>
            {undoStack.top()
              ? <div class="text-sm">{undoStack.top()!.msg}</div>
              : null
            }
          </div>
          {noRelevantSelectionsMsg.value
            ? <div class="my-4">{noRelevantSelectionsMsg.value}</div>
            : null
          }
          <div class="flex items-center gap-2 mt-2" style="width: 500px;">
            <div class="text-sm">Zoom</div>
            <select style="all:revert;" v-model={view_cssUnit_calendarZoom.value}>
              <option value="100%">100%</option>
              <option value="75%">75%</option>
              <option value="50%">50%</option>
            </select>
            <div class="text-sm">
              Hold ctrl to drag/resize calendar elements.
            </div>
          </div>
          <RootCalendarLayoutElement
            style={`display:inline-block; padding-right:1.5em; transform: scale(${view_cssUnit_calendarZoom.value}); transform-origin: top left;`} class={[noRelevantSelectionsMsg.value && games.isEmpty() ? "hidden" : undefined, "mt-2"]}
            data-test="calendarRoot"
            forceRenderKey={games.__vueKey}
            px_totalTableWidth={px_totalTableWidth.value}
            px_cellBorderAndGridlineThickness={px_cellBorderAndGridlineThickness}
            px_leftColWidth={px_leftColWidth}
            px_perFieldColWidth={px_perFieldColWidth.renderValue}
            px_perHourCellHeight={px_perHourCellHeight.renderValue}
            hours={hours.value}
            gridSlicesPerHour={parseIntOrFail(gridSlicesPerHour.value)}
            focusOnBracketGames={view_focusOnBracketGames.value}
            byDateByField={games.byDateByField}
            dateFieldColDropTargetFactory={({hour, hourRowIdx, date, fieldUID}) => ({
              onEnter: (dataTransfer, evt) => {
                // could maybe move all of this into something like `elemMover.handleMove(...)`

                if (!elemMover.isMoving) {
                  return false;
                }

                evt.stopPropagation()
                dataTransfer.dropEffect = "move";

                const freshY = (evt.offsetY + ((hourRowIdx) * px_perHourCellHeight.renderValue)) - elemMover.grabOffsetY;
                const freshTime = px2StartTime(px_perHourCellHeight.renderValue + px_cellBorderAndGridlineThickness, 60 / parseIntOrFail(gridSlicesPerHour.value), hours.value[0], freshY)

                elemMover.updateDateFieldOwner({date, field: fieldUID})
                const movee = requireNonNull(elemMover.maybeGetMovee({date, fieldUID: fieldUID}))

                const gameLengthSeconds = movee.data.uiState.time.end.unix() - movee.data.uiState.time.start.unix()
                const freshGameStart = dayjs(date).hour(freshTime.hr24).minute(freshTime.minutes).second(0)
                const freshGameEnd = freshGameStart.add(gameLengthSeconds, "seconds")

                // ceiling is "same as fresh game date, but the last hour of our calendar column"
                const ceiling = freshGameStart  // yes, use "start" to get "same date" even in cases of bleed into next day like onto "day+1 @ 12am"
                  .hour(hours.value[hours.value.length - 1] + 1) // adjust by one, because we're _showing_ hours.last() meaning we can go to hours.last() + 1
                  .minute(0)

                if (freshGameEnd.isAfter(ceiling)) {
                  // Don't update the game's time here (it's already at ceiling).
                  // But this is still a droppable place.
                  return true;
                }
                else {
                  movee.data.uiState.time = {
                    start: freshGameStart,
                    end: freshGameEnd,
                    isEffectivelyAllDay: isEffectivelyAllDay(freshGameStart.unix(), freshGameEnd.unix())
                  }
                  return true;
                }
              },
              onDragOver: "sameAsOnEnter",
              onDrop: (_, evt) => {
                if (!elemMover.isMoving) {
                  return;
                }
                evt.stopPropagation()
                doHandleDropCompletingCalendarElementMove()
              }
            }) satisfies ilDropTarget}
            tableRootDropTarget={{
              onEnter: (dataTransfer, evt) => {
                if (elemMover.isMoving) {
                  return true;
                }
                evt.stopPropagation()
                dataTransfer.dropEffect = "move"
                return false;
              },
              onDragOver: "sameAsOnEnter",
              onDrop: (_, evt) => {
                evt.stopPropagation()
                doHandleDropCompletingCalendarElementMove()
              }
            } satisfies ilDropTarget}
          >
            {{
              header1: () => {
                return <div class="w-full flex row">
                  <div style={`width:${px_leftColWidth}px;`} class="flex cell justify-center items-center p-1 bg-white">&nbsp;</div>
                  {
                    [...games.byDateByField.values()].map(fields => {
                      const all = [...fields.values()].map(countGamesAndBlocks)
                      const games = arraySum(all.map(v => v[0]))
                      const blocks = arraySum(all.map(v => v[1]))
                      return <div style={`display:inline-block; width: ${px_perFieldColWidth.renderValue * fields.size}px;`} class="bg-white text-center cell">
                        <div>
                          {games} game{games === 1 ? "" : "s"}
                        </div>
                      </div>
                    })
                  }
                </div>
              },
              header2: () => {
                return <div class="w-full flex row">
                  <div class="flex p-1 bg-white justify-center cell items-center" style={`width: ${px_leftColWidth}px;`}>Field</div>
                  {
                    [...games.byDateByField.entries()].map(([_, nodesForFieldsByFieldUID]) => {
                      return [...nodesForFieldsByFieldUID.keys()].flatMap((fieldUID) => {
                        const field = games.getField(fieldUID)
                        return <div class="inline-block cell" style={`width: ${px_perFieldColWidth.renderValue}px;`}>
                          <div style="display:flex;">
                            <div style={`flex-grow: 1;`} class={`p-1 bg-white text-center`}>{field?.fieldName}</div>
                          </div>
                        </div>
                      })
                    })
                  }
                </div>
              },
              renderLayoutNodeRoot: ({date, fieldUID, layoutNodeRoot}) => {
              return <CalendarGridElement
                date={date}
                fieldUID={fieldUID}
                layoutNode={layoutNodeRoot}
                layoutStrategy={layoutStrategy}
                px_containerHeight={(px_perHourCellHeight.renderValue * hours.value.length) + (px_cellBorderAndGridlineThickness * hours.value.length)}
                px_containerWidth={px_perFieldColWidth.renderValue}
                px_xOffset={0}
                px_cellBorderAndGridlineThickness={px_cellBorderAndGridlineThickness}
                startHour24Inc={hours.value[0]}
                endHour24Inc={hours.value[hours.value.length - 1]}
                focusOnBracketGames={view_focusOnBracketGames.value}
                px_heightPerHour={px_perHourCellHeight.renderValue}
                px_elemWidth={px_perFieldColWidth.renderValue - 1} // offset needed to not ride along the right border
                px_laneWidth={px_perFieldColWidth.renderValue - 1} // offset needed to not ride along the right border
                elemVerticalResizer={elemVerticalResizer}
                elemMover={elemMover}
                allowDragOps={true}
                z={1}
                moveeNode={elemMover.maybeGetMovee({date, fieldUID: fieldUID})}
                gridSlicesPerHour={parseIntOrFail(gridSlicesPerHour.value)}
                getCalendarElementStyles={getCalendarElementStylesEx}
                isInBulkSelectMode={!!bulkSelectState.value}
                selectedCompetitionUIDs={view_selectedCompetitionUIDs.value}
                selectedDivIDs={view_selectedDivIDs.value}
                isNonInteractiveLayoutNode={isNonInteractiveLayoutNode}
                authZ_canEditNodeViaOverlay={authZ_canEditNodeViaOverlay}
                onShowConfirmDeleteModal={layoutNode => {
                  confirmDeleteModalController.open(layoutNode as LayoutNode<GameCalendarUiElement>)
                }}
                canDragOrResizeNode={canDragOrResizeNode}
                onClick={({layoutNode, domElement}) => {
                  const node = layoutNode as LayoutNode<GameCalendarUiElement>
                  if (!authZ_canEditNodeViaOverlay(node)) {
                    return;
                  }

                  if (bulkSelectState.value) {
                    if (node.data.uiState.isBulkSelected) {
                      const idx = arrayFindIndexOrFail(bulkSelectState.value.selectedNodes, v => v === node)
                      bulkSelectState.value.selectedNodes.splice(idx, 1)
                      node.data.uiState.isBulkSelected = false
                    }
                    else {
                      node.data.uiState.isBulkSelected = true
                      bulkSelectState.value.selectedNodes.push(node)
                    }
                    return;
                  }

                  if (node.data.uiState.isSaving || node.data.uiState.isBusy) {
                    // already busy doing something else
                    return;
                  }

                  openEditorPane(layoutNode as LayoutNode<GameCalendarUiElement>, domElement)
                }}
              >
                {{
                body: ({layoutNode, elementStyle, nonInteractive}) => {
                  return <>
                  <div class="p-1 flex" style={elementStyle?.title}>
                    <div>
                      {(() => {
                        const t = `${layoutNode.data.uiState.time.start.format("h:mm a")} - ${layoutNode.data.uiState.time.end.format("h:mm a")}`
                        if (layoutNode.data.type === "fieldBlock") {
                          return `Blocked Time - ${t}`
                        }
                        else {
                          return `${layoutNode.data.data.division} - ${t}`
                        }
                      })()}
                    </div>
                    {!nonInteractive && (
                      (layoutNode.data.type === "game" && authZ.value.canCrudGames)
                      || (layoutNode.data.type === "fieldBlock" && authZ.value.canCrudFieldBlocks)
                    ) ? (
                        <button
                          style="z-index:1;"
                          type="button"
                          class="ml-auto"
                          onClick={(evt) => {
                            confirmDeleteModalController.open(layoutNode)

                            // TODO: parent element (slot renderer) shouldn't have a click handler on it, but it does.
                            evt.stopImmediatePropagation()
                            evt.preventDefault()
                          }}
                        >
                          <div class="hover:bg-[rgba(0,0,0,.125)] active:bg-[rgba(0,0,0,.25)] rounded-md flex items-center"
                            style="width:1.25em; height:1.25em; padding:.125em; box-sizing:content-box;"
                          >
                            <X penColor={elementStyle?.title.color} width="1.25em" height="1.125em"/>
                          </div>
                        </button>
                      )
                      : null
                    }
                  </div>
                  <div class="px-1 overflow-y-auto">
                    {layoutNode.data.uiState.isSaving || layoutNode.data.uiState.isBusy
                      ? <div class="flex items-center gap-2" style="z-index:1;">
                        <SoccerBall key={`isSaving/${layoutNode.data.uiState.__vueKey}`} color={Client.value.clientTheme.color} width="1.5em" height="1.5em"/>
                        {layoutNode.data.uiState.isSaving ? <span>Saving</span> : null}
                      </div>
                      : null
                    }
                    {layoutNode.data.uiState.isOpeningEditPane
                      ? <div class="flex items-center gap-2" style="z-index:1;">
                        <SoccerBall key={`isSaving/${layoutNode.data.uiState.__vueKey}`} color={Client.value.clientTheme.color} width="1.5em" height="1.5em"/>
                        <span>Loading details...</span>
                      </div>
                      : null
                    }
                    {layoutNode.data.type === "game"
                      ? <>
                        {(() => {
                          const data = layoutNode.data.data;
                          return <>
                            <div class="text-xs">
                              <span>Game {layoutNode.data.data.gameNum}</span>
                              {!nonInteractive && layoutNode.data.type === "game" && layoutNode.data.data.bracketRoundSlot
                                ? <span class="text-xs">
                                  , {layoutNode.data.data.bracketRoundSlot.bracketName}, {layoutNode.data.data.bracketRoundSlot.bracketRoundName}
                                </span>
                                : null
                              }
                            </div>
                            {!nonInteractive
                              ? <>
                                <div>
                                  {data.bracketRoundSlot
                                    ? bracketTeamLabel("home", data)
                                    : !data.homeTeam
                                    ? "TBD"
                                    : data.homeTeam.teamID === Client.value.instanceConfig.byeteam
                                    ? "Bye"
                                    : <span>
                                        <span>{teamDesignationAndMaybeName(data.homeTeam)}</span>
                                        <span class="text-xs"> ({coachBlurbForTeamName(data.coaches.filter(v => v.teamID === data.homeTeam?.teamID)) || "No current coaches"})</span>
                                    </span>
                                  }
                                </div>
                                <div>
                                  vs. {data.bracketRoundSlot
                                    ? bracketTeamLabel("visitor", data)
                                    : !data.visitorTeam
                                    ? "TBD"
                                    : data.visitorTeam.teamID === Client.value.instanceConfig.byeteam
                                    ? "Bye"
                                    : <span>
                                        <span>{teamDesignationAndMaybeName(data.visitorTeam)}</span>
                                        <span class="text-xs"> ({coachBlurbForTeamName(data.coaches.filter(v => v.teamID === data.visitorTeam?.teamID)) || "No current coaches"})</span>
                                    </span>
                                  }
                                </div>
                              </>
                              : null
                            }
                          </>
                        })()}
                        <pre class="text-xs">{layoutNode.data.data.comment}</pre>
                      </>
                      : null
                    }
                    {layoutNode.data.uiState.noBulkSelect
                      ? <>
                        <div class="text-xs">{layoutNode.data.uiState.noBulkSelect.msg}</div>
                        <div class="absolute top-0 left-0 w-full h-full bg-white opacity-50" style="z-index:1"></div>
                      </>
                      : null
                    }
                    <div class="mt-auto">
                      {!nonInteractive && layoutNode.data.type === "game" && layoutNode.data.data.hasSomeIntraFieldConflict
                        ? <div class="mt-1 text-lg flex items-center gap-1">
                          <FontAwesomeIcon icon={faTriangleExclamation}/>
                          <span class="text-xs">Game time overlaps with neighboring games on this field.</span>
                        </div>
                        : null
                      }
                      {!nonInteractive && layoutNode.data.type === "game" && layoutNode.data.data.pointsCount
                        ? <span class="text-lg"><FontAwesomeIcon icon={faTally}/></span>
                        : null
                      }
                      {!nonInteractive && layoutNode.data.type === "game" && layoutNode.data.data.blockFromMatchmaker
                        ? <span class="text-lg"><FontAwesomeIcon icon={faEyeSlash}/></span>
                        : null
                      }
                    </div>
                  </div>
                  </>
                }
              } satisfies CalendarGridElementSlots<GameCalendarUiElement>}
              </CalendarGridElement>
            }} satisfies RootCalendarLayoutElementSlots<GameCalendarUiElement>}
          </RootCalendarLayoutElement>
          {/* <NoRender>
          <div
            style={`display:inline-block; padding-right:1.5em; transform: scale(${view_cssUnit_calendarZoom.value}); transform-origin: top left;`} class={[noRelevantSelectionsMsg.value && games.isEmpty() ? "hidden" : undefined, "mt-2"]}
            data-test="calendarRoot"
          >
            <style>
              {`
                .${randomTableClass} > .row > .cell {
                  border-right: ${px_cellBorderAndGridlineThickness}px solid gray;
                  border-bottom: ${px_cellBorderAndGridlineThickness}px solid gray;
                  padding: 0;
                }
                .${randomTableClass} > .row > .cell:last-child {
                  border-right: none;
                }

                .${randomTableClass} > .row:last-child > .cell {
                  border-bottom: none;
                }
              `}
            </style>
            <div
              key={games.__vueKey}
              class={`rounded-md border shadow-md ${randomTableClass}`}
              style={`width: ${px_totalTableWidth.value}px; box-sizing:content-box;`}
              // as an affordance we allow drops on the table;
              // It might feel better to allow drops anywhere on the page, for the "infinite corner" treatment
              // It would be nice to respect mouse pos during a drag outside of the container, too.
              // And then the ultimate -- outside of the browser window?
              v-ilDropTarget={
                {
                  onEnter: (dataTransfer, evt) => {
                    if (elemMover.isMoving) {
                      return true;
                    }
                    evt.stopPropagation()
                    dataTransfer.dropEffect = "move"
                    return false;
                  },
                  onDragOver: "sameAsOnEnter",
                  onDrop: (_, evt) => {
                    evt.stopPropagation()
                    doHandleDropCompletingCalendarElementMove()
                  }
                } satisfies ilDropTarget
              }
            >
              <div class="w-full flex row">
                <div style={`width:${px_leftColWidth}px; padding: 0 1em;`} class="rounded-tl-md flex justify-center items-center bg-white cell">Date</div>
                {
                  [...games.byDateByField.entries()].map(([date, fields], i, a) => {
                    const isLast = i === a.length - 1;
                    const borderRadius = isLast ? "rounded-tr-md" : ""
                    return (
                      <div style={`display:inline-block; width:${fields.size * px_perFieldColWidth.renderValue}px;`} class={`cell bg-white text-center ${borderRadius}`}>
                        <div>{dayjs(date).format("dddd")}</div>
                        <div>{date}</div>
                      </div>
                    )
                  })
                }
              </div>

              <div class="w-full flex row">
                <div style={`width:${px_leftColWidth}px;`} class="flex cell justify-center items-center p-1 bg-white">&nbsp;</div>
                {
                  [...games.byDateByField.values()].map(fields => {
                    const all = [...fields.values()].map(countGamesAndBlocks)
                    const games = arraySum(all.map(v => v[0]))
                    const blocks = arraySum(all.map(v => v[1]))
                    return <div style={`display:inline-block; width: ${px_perFieldColWidth.renderValue * fields.size}px;`} class="bg-white text-center cell">
                      <div>
                        {games} game{games === 1 ? "" : "s"}
                      </div>
                    </div>
                  })
                }
              </div>

              <div class="w-full flex row">
                <div class="flex p-1 bg-white justify-center cell items-center" style={`width: ${px_leftColWidth}px;`}>Field</div>
                {
                  [...games.byDateByField.entries()].map(([_, nodesForFieldsByFieldUID]) => {
                    return [...nodesForFieldsByFieldUID.keys()].flatMap((fieldUID) => {
                      const field = games.getField(fieldUID)
                      return <div class="inline-block cell" style={`width: ${px_perFieldColWidth.renderValue}px;`}>
                        <div style="display:flex;">
                          <div style={`flex-grow: 1;`} class={`p-1 bg-white text-center`}>{field?.fieldName}</div>
                        </div>
                      </div>
                    })
                  })
                }
              </div>
              {
                // Currently we _do_ render every row for which have an hour as its own <tr>,
                // But, really everything interesting gets rendered via absolute positioning from the first row.
                // So we could maybe do away with the <table> entirely, or thereabouts.
                hours.value.slice().map((hour, hourRowIdx) => {
                  const isFirstRow = hourRowIdx === 0;
                  const isLastRow = hourRowIdx === hours.value.length - 1;
                  const hourCellBorderRadius = isLastRow ? "rounded-bl-md" : ""
                  return (
                    <div class="w-full flex row" style={`height:${px_perHourCellHeight.renderValue + px_cellBorderAndGridlineThickness}px;`}>
                      <div style={`position:relative; width: ${px_leftColWidth}px;`} class={`flex bg-white pl-4 cell ${hourCellBorderRadius}`}>
                        {isFirstRow
                          ? <div style={`pointer-events:none; z-index: 1; position:absolute; top: 0; left; 0; width:100%; height: ${px_perHourCellHeight.renderValue * hours.value.length}px`}>
                            <GridLines
                              hours={hours.value.length}
                              perHourCellHeight={px_perHourCellHeight.renderValue}
                              gridSlicesPerHour={parseIntOrFail(gridSlicesPerHour.value)}
                              cellBorderAndGridlineThickness={px_cellBorderAndGridlineThickness}
                            />
                          </div>
                          : null
                        }
                        <div style="padding:.5em;">{_24hTo12h[hour]}</div>
                      </div>
                      {
                        [...games.byDateByField.entries()].flatMap(([date, nodesForFieldsByFieldUID]) => {
                          return [...nodesForFieldsByFieldUID.entries()].map(([fieldUID, layoutNode], i, a) => {
                            const dateFieldKey = `${date}/${fieldUID}`
                            return (
                              <div
                                class="cell"
                                style={`display:inline-block; width:${px_perFieldColWidth.renderValue}px;`}
                                key={`${hour}/${date}`}
                              >
                                <div class="flex" style={`height: ${px_perHourCellHeight.renderValue}px`}>
                                  <div key={dateFieldKey} style={{
                                      position: `relative`,
                                      flexGrow: 1,
                                      // In the first row we don't muss with this;
                                      // for all other rows, we need the rows themselves to transparent to mouse events
                                      // so that the divs performing cell layout don't eat mouse clicks (the absolute positioned
                                      // divs from the first row are the ones containing important game info)
                                      pointerEvents: isFirstRow ? undefined : "none",
                                    }}
                                  >
                                    {
                                      isFirstRow
                                        ? (
                                          // This is an invisible div that only serves to be a drop target for the whole column
                                          <div
                                            style={{
                                              position: "absolute",
                                              top: 0,
                                              height: `${(px_perHourCellHeight.renderValue * hours.value.length) + (px_cellBorderAndGridlineThickness * hours.value.length)}px`,
                                              width: "100%",
                                            }}
                                            v-ilDropTarget={
                                              {
                                                onEnter: (dataTransfer, evt) => {
                                                  // could maybe move all of this into something like `elemMover.handleMove(...)`

                                                  if (!elemMover.isMoving) {
                                                    return false;
                                                  }

                                                  evt.stopPropagation()
                                                  dataTransfer.dropEffect = "move";

                                                  const freshY = (evt.offsetY + ((hourRowIdx) * px_perHourCellHeight.renderValue)) - elemMover.grabOffsetY;
                                                  const freshTime = px2StartTime(px_perHourCellHeight.renderValue + px_cellBorderAndGridlineThickness, 60 / parseIntOrFail(gridSlicesPerHour.value), hours.value[0], freshY)

                                                  elemMover.updateDateFieldOwner({date, field: fieldUID})
                                                  const movee = requireNonNull(elemMover.maybeGetMovee({date, fieldUID: fieldUID}))

                                                  const gameLengthSeconds = movee.data.uiState.time.end.unix() - movee.data.uiState.time.start.unix()
                                                  const freshGameStart = dayjs(date).hour(freshTime.hr24).minute(freshTime.minutes).second(0)
                                                  const freshGameEnd = freshGameStart.add(gameLengthSeconds, "seconds")

                                                  // ceiling is "same as fresh game date, but the last hour of our calendar column"
                                                  const ceiling = freshGameStart  // yes, use "start" to get "same date" even in cases of bleed into next day like onto "day+1 @ 12am"
                                                    .hour(hours.value[hours.value.length - 1] + 1) // adjust by one, because we're _showing_ hours.last() meaning we can go to hours.last() + 1
                                                    .minute(0)

                                                  if (freshGameEnd.isAfter(ceiling)) {
                                                    // Don't update the game's time here (it's already at ceiling).
                                                    // But this is still a droppable place.
                                                    return true;
                                                  }
                                                  else {
                                                    movee.data.uiState.time = {
                                                      start: freshGameStart,
                                                      end: freshGameEnd,
                                                      isEffectivelyAllDay: isEffectivelyAllDay(freshGameStart.unix(), freshGameEnd.unix())
                                                    }
                                                    return true;
                                                  }
                                                },
                                                onDragOver: "sameAsOnEnter",
                                                onDrop: (_, evt) => {
                                                  if (!elemMover.isMoving) {
                                                    return;
                                                  }
                                                  evt.stopPropagation()
                                                  doHandleDropCompletingCalendarElementMove()
                                                }
                                              } satisfies ilDropTarget
                                            }
                                          >
                                          </div>
                                        )
                                        : null
                                      }
                                    {
                                      isFirstRow
                                        ? <GridLines
                                          hours={hours.value.length}
                                          perHourCellHeight={px_perHourCellHeight.renderValue}
                                          gridSlicesPerHour={parseIntOrFail(gridSlicesPerHour.value)}
                                          cellBorderAndGridlineThickness={px_cellBorderAndGridlineThickness}
                                        />
                                        : null
                                    }
                                    {
                                      isFirstRow
                                        ? (
                                          <CalendarGridElement
                                            date={date}
                                            fieldUID={fieldUID}
                                            layoutNode={layoutNode}
                                            px_containerHeight={(px_perHourCellHeight.renderValue * hours.value.length) + (px_cellBorderAndGridlineThickness * hours.value.length)}
                                            px_containerWidth={px_perFieldColWidth.renderValue}
                                            px_xOffset={0}
                                            px_cellBorderAndGridlineThickness={px_cellBorderAndGridlineThickness}
                                            startHour24Inc={hours.value[0]}
                                            endHour24Inc={hours.value[hours.value.length - 1]}
                                            focusOnBracketGames={view_focusOnBracketGames.value}
                                            px_heightPerHour={px_perHourCellHeight.renderValue}
                                            // offset needed to not ride along the right border
                                            px_elemWidth={px_perFieldColWidth.renderValue - 1}
                                            elemVerticalResizer={elemVerticalResizer}
                                            elemMover={elemMover}
                                            z={1}
                                            moveeNode={elemMover.maybeGetMovee({date, fieldUID: fieldUID})}
                                            gridSlicesPerHour={parseIntOrFail(gridSlicesPerHour.value)}
                                            getCalendarElementStyles={getCalendarElementStyles}
                                            gamesTree={games}
                                            authZ={authZ.value}
                                            isInBulkSelectMode={!!bulkSelectState.value}
                                            selectedCompetitionUIDs={view_selectedCompetitionUIDs.value}
                                            selectedDivIDs={view_selectedDivIDs.value}
                                            onShowEditorPane={async ({layoutNode, domElement}) => {
                                              openEditorPane(layoutNode, domElement)
                                            }}
                                            onShowConfirmDeleteModal={layoutNode => {
                                              confirmDeleteModalController.open(layoutNode)
                                            }}
                                            onToggleBulkSelect={node => {
                                              assertNonNull(bulkSelectState.value)
                                              if (node.data.uiState.isBulkSelected) {
                                                const idx = arrayFindIndexOrFail(bulkSelectState.value.selectedNodes, v => v === node)
                                                bulkSelectState.value.selectedNodes.splice(idx, 1)
                                                node.data.uiState.isBulkSelected = false
                                              }
                                              else {
                                                node.data.uiState.isBulkSelected = true
                                                bulkSelectState.value.selectedNodes.push(node)
                                              }
                                            }}
                                          />
                                        )
                                        : null
                                    }
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        })
                      }
                    </div>
                  )
                })
              }
            </div>
          </div>
          </NoRender> */}
        </div>
      )
    }
  }
})

/**
 * Draw all the gridlines for a given column.
 */
const GridLines = defineComponent({
  props: {
    hours: vReqT<number>(),
    perHourCellHeight: vReqT<number>(),
    gridSlicesPerHour: vReqT<number>(),
    cellBorderAndGridlineThickness: vReqT<number>(),
  },
  setup(props) {
    const tableHeight = computed(() => props.hours * props.perHourCellHeight)
    const totalGridCount = computed(() => props.gridSlicesPerHour * props.hours)
    const perGridHeight = computed(() => tableHeight.value / totalGridCount.value)
    return () => {
      return (
        <div class="relative">
          {
            (() => {
              const result : JSX.Element[] = []

              for (let i = 0; i < totalGridCount.value; i++) {
                if (i % props.gridSlicesPerHour === 0) {
                  // don't show "per hour subdivision" lines on top of "lines that separate hours"
                  continue;
                }

                // each row's border adds `cellBorderAndGridlineThickness` to overall offset from the top
                // (n.b. currently borders take up physical space, whereas the gridlines are absolutely positioned and take up zero space.
                const borderAdjust = Math.floor(i / props.gridSlicesPerHour) * props.cellBorderAndGridlineThickness

                // nudge 1px up so that it doesn't align directly with game boundaries (1px above games on this line)
                const topOffset = ((perGridHeight.value * i) + borderAdjust) - 1
                result.push(<div style={`position:absolute; top: ${topOffset}px; left:0; width: 100%; height:0;"`} class="border-b border-dashed border-gray-400"></div>);
              }

              return result;
            })()
          }
        </div>
      )
    }
  }
})

function px2StartTime(pxPerHour: number, snapMinutes: number, hrStart24: number, px_y: number) : {hr24: number, minutes: number} {
  const effectiveZeroMinutes = hrStart24 * 60
  const pxPerMinute = 60 / pxPerHour
  const freshMinutes = effectiveZeroMinutes + (Math.max(0, px_y) * pxPerMinute)
  const snappedMinutes = Math.floor(freshMinutes / snapMinutes) * snapMinutes

  const hr24 = Math.floor(snappedMinutes / 60)
  const minutes = snappedMinutes - (hr24 * 60)

  return {hr24, minutes}
}

/**
 * Count the number of games contained in some layout tree.
 * TODO: Probably should count this before treeifying a list of games and store that number somewhere.
 */
function countGamesAndBlocks(v: LayoutNodeRoot<GameCalendarUiElement>) : [games: number, blocks: number] {
  return worker(v);
  function worker(node: LayoutNodeRoot<GameCalendarUiElement> | LayoutNode<GameCalendarUiElement>) : [number, number] {
    const isRoot = !node.parent;
    const n : [number, number] = isRoot ? [0,0] : [node.data.type === "game" ? 1 : 0, node.data.type === "fieldBlock" ? 1 : 0]
    for (const child of node.children) {
      const x = worker(child)
      n[0] += x[0]
      n[1] += x[1]
    }
    return n;
  }
}

const freshCreateGameForm = (
  required: Pick<CreateGameForm, "season" | "competitionUID" | "divID" | "fieldUID" | "poolID">,
  rest?: Partial<CreateGameForm>
) : CreateGameForm => {
  return {
    ...required,
    startDate: dayjs().format(DAYJS_FORMAT_HTML_DATE),
    startHour: 8,
    startMinute: 0,
    genderNeutral: false,
    playoff: false,
    pointsCount: true,
    blockFromMatchmaker: false,
    slotCount: 1,
    slotGameDurationMinutes: 60,
    repeatWeeks: 0,
    comment: "",
    ...rest,
  }
}

const freshCreateFieldBlockForm = (
  required: Pick<CreateFieldBlockForm, "fieldUID">,
  rest?: Partial<CreateFieldBlockForm>
) : CreateFieldBlockForm => {
  return {
    ...required,
    startDate: dayjs().format(DAYJS_FORMAT_HTML_DATE),
    startHour: 12,
    startMinute: 0,
    lengthMinutes: 120,
    repeatWeeks: 0,
    comment: "",
    blockEntireDay: false,
    ...rest
  }
}

function freshEditGameForm(v: GameCalendarUiElement, season: SeasonTriple) : EditGameForm {
  assertIs(v.type, "game")
  assertTruthy(v.data.seasonUID === season.seasonUID)
  return {
    gameID: v.data.gameID,
    startDate: dayjs(v.data.gameStart).format(DAYJS_FORMAT_HTML_DATE),
    startHour: dayjs(v.data.gameStart).hour(),
    startMinute: dayjs(v.data.gameStart).minute(),
    gameDurationMinutes: Math.floor((dayjs(v.data.gameEnd).unix() - dayjs(v.data.gameStart).unix()) / 60),
    homeTeamID: v.data.homeTeam?.teamID ?? "TBD",
    visitorTeamID: v.data.visitorTeam?.teamID ?? "TBD",
    season: season,
    competitionUID: v.data.competitionUID,
    divID: v.data.divID,
    fieldUID: v.data.fieldUID,
    poolID: v.data.poolID,
    genderNeutral: !!v.data.genderNeutral,
    playoff: !!v.data.playoff,
    pointsCount: !!v.data.pointsCount,
    blockFromMatchmaker: !!v.data.blockFromMatchmaker,
    comment: v.data.comment,
    roundID: v.data.roundID,
    editGameTab: EditGameTabID.editGame,
  }
}

function freshEditFieldBlockForm(v: GameCalendarUiElement) : EditFieldBlockForm {
  assertIs(v.type, "fieldBlock")

  const start = dayjs(v.data.slotStart)
  const end = dayjs(v.data.slotEnd)

  return {
    id: v.data.id,
    fieldUID: v.data.fieldUID,
    startDate: start.format(DAYJS_FORMAT_HTML_DATE),
    startHour: start.hour(),
    startMinute: start.minute(),
    lengthMinutes: Math.floor((end.unix() - start.unix()) / 60),
    comment: v.data.comment,
    blockEntireDay: isEffectivelyAllDay(start.unix(), end.unix()),
  }
}

export type EditFormUiState =
  | {
    active: false,
    /**
     * We need to keep around the "old" form from the last "active=true" state, for the modal,
     * which needs to keep rendering it until it is "disappear" animation completes.
     * However, will be undefined initially.
     */
    node: LayoutNode<GameCalendarUiElement> | undefined,
    form: {type: "game", data: EditGameForm, authZ: CompDivAuthZ} | {type: "fieldBlock", data: EditFieldBlockForm} | undefined
  }
  | {
    active: true,
    /** node represents the source calendar node and the data contained therein is "prstine" (not affected by current form edits) */
    node: LayoutNode<GameCalendarUiElement>,
    calendarElement: HTMLElement,
    /** form is mutable data */
    form: {type: "game", data: EditGameForm, authZ: CompDivAuthZ} | {type: "fieldBlock", data: EditFieldBlockForm}
  }

enum ColoringScheme {
  division = "division",
  round = "round",
}

function viewOptions_key(userID: Guid) {
  return `GameSchedulerCalendarViewOptions/${userID}`
}

type ViewOptions = Static<ReturnType<typeof viewOptions_schema>>
function viewOptions_schema() {
  return Type.Object({
    competitionUIDs: Type.Array(Type.String({format: k_GUID})),
    divIDs: Type.Array(Type.String({format: k_GUID})),
    fieldUIDs: Type.Array(Type.String({format: k_GUID})),
    coloringScheme: Type.Enum(ColoringScheme),
  })
}

function viewOptions_localStorage_load(userID: Guid) : {didLoad: boolean, data: ViewOptions} {
  const key = viewOptions_key(userID)
  const schema = viewOptions_schema()
  const data = maybeParseJSON(localStorage.getItem(key) ?? "")
  if (Value.Check(schema, data)) {
    return {didLoad: true, data};
  }
  else {
    return {
      didLoad: false,
      data: {
        competitionUIDs: [],
        divIDs: [],
        fieldUIDs: [],
        coloringScheme: ColoringScheme.division,
      }
    }
  }
}

function viewOptions_localStorage_save(userID: Guid, data: ViewOptions) {
  const key = viewOptions_key(userID)
  localStorage.setItem(key, JSON.stringify(data));
}

type UndoAction =
  | HistCreated
  | HistUpdated
  | HistDeleted
  | HistGameSwap

interface HistCreated {
  type: "histCreated",
  createdGames: Extract<GameCalendarElement, {type: "game"}>[],
  createdFieldBlocks: Extract<GameCalendarElement, {type: "fieldBlock"}>[],
  msg: string,
}

interface Updated<T> {
  was: T,
  became: T,
}

interface HistUpdated {
  type: "histUpdated",
  games: Updated<GameForGameSchedulerView>[],
  fieldBlocks: Updated<FieldBlockForGameSchedulerView>[],
  msg: string,
}

interface HistDeleted {
  type: "histDeleted",
  // retain old ids in order to patch up ids for other actions in the history stack after undoing a delete;
  // TODO: soft delete, so IDs don't change
  deletedGames: (CreateGameRequest & {oldGameID: Guid})[],
  deletedFieldBlocks: (CreateFieldBlockRequest & {oldID: Integerlike})[],
  msg: string,
}

interface HistGameSwap {
  type: "histGameSwap",
  gameIDs: [Guid, Guid],
  msg: string,
}


function UndoStack() {
  const stack = ref<UndoAction[]>([])

  const k_timeFormat = "h:mm a"

  function histMsg_createdGames(games: GameForGameSchedulerView[]) : string {
    assertTruthy(games.length > 0)

    // they should all be the same, so first is as good as any
    const field = requireNonNull(forceCheckedIndexedAccess(games, 0)?.fieldName ?? null)

    return `Created ${games.length} game${games.length === 1 ? "" : "s"} on field ${field}.`
  }

  function histMsg_createdFieldBlocks(fieldBlocks: FieldBlockForGameSchedulerView[]) : string {
    // they should all be the same, so first is as good as any
    const field = fieldBlocks[0].fieldName
    return `Created ${fieldBlocks.length} fieldBlock${fieldBlocks.length === 1 ? "" : "s"} on field ${field}.`
  }

  function histMsg_update(v:
    | ({type: "game"} & Updated<GameForGameSchedulerView>)
    | ({type: "fieldBlock"} & Updated<FieldBlockForGameSchedulerView>)
  ) : string {
    if (v.type === "game") {
      const {gameNum, fieldName} = v.became
      const start = dayjs(v.became.gameStart).format(k_timeFormat)
      const end = dayjs(v.became.gameEnd).format(k_timeFormat)

      const adjusted = {
        "season": v.was.seasonUID !== v.became.seasonUID,
        "field": v.was.fieldUID !== v.became.fieldUID,
        "competition": v.was.competitionUID !== v.became.competitionUID,
        "division": v.was.divID !== v.became.divID,
        "start time": !dayjs(v.was.gameStart).isSame(v.became.gameStart, "minute"),
        "end time": !dayjs(v.was.gameEnd).isSame(v.became.gameEnd, "minute"),
        "home team": v.was.homeTeam?.teamID !== v.became.homeTeam?.teamID,
        "visitor team": v.was.visitorTeam?.teamID !== v.became.visitorTeam?.teamID,
      }
      const adjustedUiNames = checkedObjectEntries(adjusted).filter(([_uiName, adjusted]) => adjusted).map(([uiName]) => uiName)

      if (adjustedUiNames.length === 0) {
        return `Game ${gameNum} on ${fieldName}@${start}-${end} - adjusted misc. properites}`
      }
      else {
        return `Game ${gameNum} on ${fieldName}@${start}-${end} - adjusted ${adjustedUiNames.join(", ")}`
      }
    }
    else if (v.type === "fieldBlock") {
      const field = v.became.fieldName
      const start = dayjs(v.became.slotStart).format(k_timeFormat)
      const end = dayjs(v.became.slotEnd).format(k_timeFormat)
      const adjusted = {
        "field": v.was.fieldUID !== v.became.fieldUID,
        "start time": !dayjs(v.was.slotStart).isSame(v.became.slotStart, "minute"),
        "end time": !dayjs(v.was.slotEnd).isSame(v.became.slotEnd, "minute"),
      }
      const adjustedUiNames = checkedObjectEntries(adjusted).filter(([_uiName, adjusted]) => adjusted).map(([uiName]) => uiName)
      if (adjustedUiNames.length === 0) {
        return `Field block on ${field}@${start}-${end} - adjusted misc. properties}`
      }
      else {
        return `Field block on ${field}@${start}-${end} - adjusted ${adjustedUiNames.join(", ")}`
      }
    }
    else {
      exhaustiveCaseGuard(v)
    }
  }

  function histMsg_update_verticalResize(v:
    | ({type: "game"} & Updated<GameForGameSchedulerView>)
    | ({type: "fieldBlock"} & Updated<FieldBlockForGameSchedulerView>)
  ) : string {
    if (v.type === "game") {
      const {gameNum, homeTeam, visitorTeam, fieldName} = v.became;
      const from = {
        start: dayjs(v.was.gameStart).format(k_timeFormat),
        end: dayjs(v.was.gameEnd).format(k_timeFormat),
      }
      const to = {
        start: dayjs(v.became.gameStart).format(k_timeFormat),
        end: dayjs(v.became.gameEnd).format(k_timeFormat),
      }
      return `Adjusted game ${gameNum} (${homeTeam?.teamDesignation ?? "TBD"} v. ${visitorTeam?.teamDesignation ?? "TBD"}) on field ${fieldName} from ${from.start}-${from.end} to ${to.start}-${to.end}.`
    }
    else if (v.type === "fieldBlock") {
      const {fieldName} = v.became;
      const from = {
        start: dayjs(v.was.slotStart).format(k_timeFormat),
        end: dayjs(v.was.slotEnd).format(k_timeFormat),
      }
      const to = {
        start: dayjs(v.became.slotStart).format(k_timeFormat),
        end: dayjs(v.became.slotEnd).format(k_timeFormat),
      }
      return `Adjusted field block on field ${fieldName} from ${from.start}-${from.end} to ${to.start}-${to.end}.`
    }
    else {
      exhaustiveCaseGuard(v)
    }
  }

  function histMsg_update_move(v:
    | ({type: "game"} & Updated<GameForGameSchedulerView>)
    | ({type: "fieldBlock"} & Updated<FieldBlockForGameSchedulerView>)
  ) : string {
    if (v.type === "game") {
      const oldField = v.was.fieldName;
      const {gameNum, homeTeam, visitorTeam, fieldName: newField} = v.became;

      const from = {
        start: dayjs(v.was.gameStart).format(k_timeFormat),
        end: dayjs(v.was.gameEnd).format(k_timeFormat),
      }
      const to = {
        start: dayjs(v.became.gameStart).format(k_timeFormat),
        end: dayjs(v.became.gameEnd).format(k_timeFormat),
      }
      return `Moved game ${gameNum} (${homeTeam?.teamDesignation ?? "TBD"} v. ${visitorTeam?.teamDesignation ?? "TBD"}) from ${oldField}@${from.start}-${from.end} to ${newField}@${to.start}-${to.end}`
    }
    else if (v.type === "fieldBlock") {
      const oldField = v.was.fieldName;
      const newField = v.became.fieldName;
      const from = {
        start: dayjs(v.was.slotStart).format(k_timeFormat),
        end: dayjs(v.was.slotEnd).format(k_timeFormat),
      }
      const to = {
        start: dayjs(v.became.slotStart).format(k_timeFormat),
        end: dayjs(v.became.slotEnd).format(k_timeFormat),
      }
      return `Moved field block from ${oldField}@${from.start}-${from.end} to ${newField}@${to.start}-${to.end}`
    }
    else {
      exhaustiveCaseGuard(v)
    }
  }

  function histMsg_bulkReassignFields(games: number, fieldBlocks: number, fieldName: string) : string {
    const gamesMsg = `${games} game${games === 1 ? "" : "s"}`
    const fieldBlockMsg = `${fieldBlocks} field block${fieldBlocks === 1 ? "" : "s"}`
    return games > 0 && fieldBlocks === 0 ? `Reassigned ${gamesMsg} to field ${fieldName}`
      : games === 0 && fieldBlocks > 0 ? `Reassigned ${fieldBlockMsg} to field ${fieldName}`
      : `Reassigned ${gamesMsg}, ${fieldBlockMsg} to field ${fieldName}`
  }

  function histMsg_delete(games: GameForGameSchedulerView[], fieldBlocks: FieldBlockForGameSchedulerView[]) : string {
    const gamesMsg = `${games.length} game${games.length === 1 ? "" : "s"}`
    const fieldBlockMsg = `${fieldBlocks.length} field block${fieldBlocks.length === 1 ? "" : "s"}`

    return games.length > 0 && fieldBlocks.length === 0 ? `Deleted ${gamesMsg}`
      : games.length === 0 && fieldBlocks.length > 0 ? `Deleted ${fieldBlockMsg}`
      : `Deleted ${gamesMsg}, ${fieldBlockMsg}`
  }

  function histMsg_swapGame(swapResult: {before: [GameForGameSchedulerView, GameForGameSchedulerView], after: [GameForGameSchedulerView, GameForGameSchedulerView]}) : string {
    return `Swapped ${gameSwapGameOptionLabel(swapResult.before[0])} with ${gameSwapGameOptionLabel(swapResult.before[1])}`
  }

  function push(action: UndoAction) : void {
    // sanity -- make a copy, we shouldn't ever directly reference objects passed in,
    // its likely callers will pass refs to live game/fieldBlock objs and we don't want that.
    // Data is expected to be fully json-roundtrippable
    stack.value.push(copyViaJsonRoundTrip(action))
  }

  function pop() : void {
    stack.value.pop()
  }

  function top() : UndoAction | undefined {
    return stack.value.length > 0 ? stack.value[stack.value.length - 1] : undefined
  }

  /**
   * When we un-delete an item, we need to patch old history state to give the new IDs
   * to the old history items, so that prior create/edit actions don't target the now-deleted thing.
   *
   * requires that there is only 1 patch per provided game/fieldBlock,
   * e.g. patch({gameX}, {gameX}, {gameY}) is not supported and will perform unexpectedly because gameX is patched twice.
   */
  function patchRecreatedItems(
    games: {type: "game", oldID: Guid, newID: Guid}[],
    fieldBlocks: {type: "fieldBlock", oldID: Integerlike, newID: Integerlike}[]
  ) : void {
    for (const item of stack.value) {
      switch (item.type) {
        case "histUpdated": {
          for (const game of item.games) {
            assertTruthy(game.was.gameID === game.became.gameID)
            const patch = games.find(v => v.oldID === game.was.gameID)
            if (patch) {
              game.was.gameID = patch.newID
              game.became.gameID = patch.newID
            }
          }
          for (const fieldBlock of item.fieldBlocks) {
            assertTruthy(fieldBlock.was.id === fieldBlock.became.id)
            const patch = fieldBlocks.find(v => v.oldID.toString() === fieldBlock.was.id.toString())
            if (patch) {
              fieldBlock.was.id = patch.newID
              fieldBlock.became.id = patch.newID
            }
          }
          break;
        }
        case "histCreated": {
          for (const game of item.createdGames) {
            const patch = games.find(v => v.oldID === game.data.gameID)
            if (patch) {
              game.data.gameID = patch.newID
            }
          }
          for (const fieldBlock of item.createdFieldBlocks) {
            const patch = fieldBlocks.find(v => v.oldID.toString() === fieldBlock.data.id.toString())
            if (patch) {
              fieldBlock.data.id = patch.newID
            }
          }
          continue;
        }
        case "histDeleted": {
          for (const game of item.deletedGames) {
            const patch = games.find(v => v.oldID === game.oldGameID)
            if (patch) {
              game.oldGameID = patch.newID
            }
          }
          for (const fieldBlock of item.deletedFieldBlocks) {
            const patch = fieldBlocks.find(v => v.oldID === fieldBlock.oldID)
            if (patch) {
              fieldBlock.oldID = patch.newID
            }
          }
          break;
        }
        case "histGameSwap": {
          assertTruthy(item.gameIDs.length === 2);
          for (const game of games) {
            if (item.gameIDs[0] === game.oldID) {
              item.gameIDs[0] = game.newID
            }
            if (item.gameIDs[1] === game.oldID) {
              item.gameIDs[1] = game.newID
            }
          }
          break;
        }
        default: exhaustiveCaseGuard(item)
      }
    }
  }

  return {
    histMsg_createdGames,
    histMsg_createdFieldBlocks,
    histMsg_update,
    histMsg_update_verticalResize,
    histMsg_update_move,
    histMsg_bulkReassignFields,
    histMsg_delete,
    histMsg_swapGame,
    patchRecreatedItems,
    pop,
    push,
    top,
    get size() { return stack.value.length }
  }
}

function gameAsCreateGameRequest(v: GameForGameSchedulerView) : CreateGameRequest & {oldGameID: Guid} {
  const startDatetime = dayjs(v.gameStart)
  const endDatetime = dayjs(v.gameEnd)
  const minutes = Math.round((endDatetime.unix() - startDatetime.unix()) / 60)
  return {
    oldGameID: v.gameID,
    competitionUID: v.competitionUID,
    divID: v.divID,
    fieldUID: v.fieldUID,
    slotCount: 1,
    dateTime: undefined,
    startDate: v.gameStart,
    startHour: startDatetime.hour(),
    startMinute: startDatetime.minute(),
    slotGameDurationMinutes: minutes,
    repeatWeeks: 0,
    comment: v.comment,
    playoff: !!v.playoff,
    pointsCount: !!v.pointsCount,
    genderNeutral: !!v.genderNeutral,
    blockFromMatchmaker: !!v.blockFromMatchmaker,
    poolID: v.poolID,
    scheduleEvenIfBlocked: true,
    acknowledgedConflicts: [],
    tags: [],
    homeTeamID: v.homeTeam?.teamID || undefined,
    visitorTeamID: v.visitorTeam?.teamID || undefined,
  }
}

function gameAsUpdateGameRequest(v: GameForGameSchedulerView) : UpdateGameRequest {
  const startDatetime = dayjs(v.gameStart)
  const endDatetime = dayjs(v.gameEnd)
  const minutes = Math.round((endDatetime.unix() - startDatetime.unix()) / 60)
  return {
    gameID: v.gameID,
    startDate: v.gameStart,
    startHour: startDatetime.hour(),
    startMinute: startDatetime.minute(),
    gameDurationMinutes: minutes,
    homeTeamID: v.homeTeam?.teamID ?? "TBD",
    visitorTeamID: v.visitorTeam?.teamID ?? "TBD",
    competitionUID: v.competitionUID,
    divID: v.divID,
    fieldUID: v.fieldUID,
    poolID: v.poolID,
    genderNeutral: !!v.genderNeutral,
    playoff: !!v.playoff,
    pointsCount: !!v.pointsCount,
    blockFromMatchmaker: !!v.blockFromMatchmaker,
    comment: v.comment,
    seasonUID: v.seasonUID,
  }
}

function fieldBlockAsCreateFieldBlockRequest(v: FieldBlockForGameSchedulerView) : CreateFieldBlockRequest & {oldID: Integerlike} {
  const startDatetime = dayjs(v.slotStart)
  const endDatetime = dayjs(v.slotEnd)
  const minutes = Math.round((endDatetime.unix() - startDatetime.unix()) / 60)
  return {
    oldID: v.id,
    fieldUID: v.fieldUID,
    dateTime: undefined,
    startDate: v.slotStart,
    startHour: startDatetime.hour(),
    startMinute: startDatetime.minute(),
    lengthMinutes: minutes,
    repeatWeeks: 0,
    comment: v.comment,
    scheduleEvenIfBlocked: true,
  }
}

function fieldBlockAsUpdateFieldBlockRequest(v: FieldBlockForGameSchedulerView) : UpdateFieldBlockRequest {
  const startDatetime = dayjs(v.slotStart)
  const endDatetime = dayjs(v.slotEnd)
  const minutes = Math.round((endDatetime.unix() - startDatetime.unix()) / 60)
  return {
    id: v.id,
    fieldUID: v.fieldUID,
    startDate: v.slotStart,
    startHour: startDatetime.hour(),
    startMinute: startDatetime.minute(),
    lengthMinutes: minutes,
    comment: v.comment,
  }
}

function filterUpdateGameRequestForAuthZ(args: {
  req: UpdateGameRequest,
  originalCompetitionUID: Guid,
  newCompetitionUID: Guid,
  originalDivID: Guid,
  newDivID: Guid,
}) : UpdateGameRequest {
  const {req, originalCompetitionUID, originalDivID, newCompetitionUID, newDivID} = args

  const runOldAndNew = (f: (_: {competitionUID: Guid, divID: Guid}) => boolean) : boolean => {
    var authZ_old = f({competitionUID: originalCompetitionUID, divID: originalDivID})
    var authZ_maybeNew = f({competitionUID: newCompetitionUID, divID: newDivID})
    return authZ_old && authZ_maybeNew
  }

  const allCrudOK = runOldAndNew(authZ_perAction.canCrudGames)
  const fieldsOK = allCrudOK || runOldAndNew(authZ_perAction.canEditGameFields)
  const teamsOK = allCrudOK || runOldAndNew(authZ_perAction.canEditGameTeams)
  const timesOK = allCrudOK || runOldAndNew(authZ_perAction.canEditGameTimes)

  const authZFilteredArgs : Record<keyof UpdateGameRequest, boolean> = {
    gameID: true,
    startDate: timesOK,
    startHour: timesOK,
    startMinute: timesOK,
    gameDurationMinutes: timesOK,
    homeTeamID: teamsOK,
    visitorTeamID: teamsOK,
    competitionUID: allCrudOK,
    divID: allCrudOK,
    fieldUID: fieldsOK,
    poolID: allCrudOK,
    genderNeutral: allCrudOK,
    playoff: allCrudOK,
    pointsCount: allCrudOK,
    blockFromMatchmaker: allCrudOK,
    comment: allCrudOK,
    seasonUID: allCrudOK,
    roundID: allCrudOK,
  };

  const formShallowCopy = {...req}
  for (const [k,v] of checkedObjectEntries<keyof UpdateGameRequest, boolean>(authZFilteredArgs)) {
    assertTruthy(k in formShallowCopy)
    if (!v) {
      (formShallowCopy as any)[k] = undefined
    }
  }
  return formShallowCopy
}

function updateCreateGameFormCompDependentValues(form: CreateGameForm, season: SeasonTriple, divisions: {divID: Guid}[]) : void {
  form.season = copyViaJsonRoundTrip(season) // paranoid copy
  if (!divisions.find(v => v.divID === form.divID)) {
    form.divID = forceCheckedIndexedAccess(divisions, 0)?.divID ?? ""
  }
}

function updateCreateGameFormCompDivDependentValues(
  form: CreateGameForm,
  division: {divID: Guid, slotHours: "" | number, slotMinutes: "" | number} | null,
  pools: {poolID: Integerlike}[]
) : void {
  assertTruthy(!division || form.divID === division.divID) // sanity check

  if (division) {
    const defaultDurationMinutes = ((division.slotHours || 0) * 60) + (division.slotMinutes || 0)
    form.slotGameDurationMinutes = defaultDurationMinutes === 0 ? 60 : defaultDurationMinutes;
  }

  if (!pools.find(v => v.poolID.toString() === form.poolID.toString())) {
    form.poolID = k_POOL_ALL
  }
}

function updateEditGameFormCompDependentValues(form: EditGameForm, divisions: {divID: Guid}[]) {
  if (!divisions.find(v => v.divID === form.divID)) {
    form.divID = forceCheckedIndexedAccess(divisions, 0)?.divID ?? ""
  }
}

function updateEditGameFormCompDivDependentValues(form: EditGameForm, pools: {poolID: Integerlike}[], teams: {teamID: string}[]) : void {
  if (!teams.find(v => v.teamID === form.homeTeamID)) {
    form.homeTeamID = k_TEAM_TBD
  }
  if (!teams.find(v => v.teamID === form.visitorTeamID)) {
    form.visitorTeamID = k_TEAM_TBD
  }
  if (!pools.find(v => v.poolID.toString() === form.poolID.toString())) {
    form.poolID = k_POOL_ALL
  }
}

function updateRoundsCompDependentValues(form: RoundMenuSelection, season: SeasonTriple, divisions: {divID: Guid}[]) : void {
  form.season = copyViaJsonRoundTrip(season) // paranoid copy
  if (!divisions.find(v => v.divID === form.divID)) {
    form.divID = forceCheckedIndexedAccess(divisions, 0)?.divID ?? ""
  }
}

function updateRoundsCompDivDependentValues(
  form: RoundMenuSelection,
  division: {divID: Guid, slotHours: "" | number, slotMinutes: "" | number},
  pools: {poolID: Integerlike}[]
) : void {
  assertTruthy(form.divID === division.divID) // sanity check

  if (!pools.find(v => v.poolID.toString() === form.poolID.toString())) {
    form.poolID = k_POOL_ALL
  }
}

function editGameFormToUpdateGameRequest(v: EditGameForm) : {[K in keyof UpdateGameRequest]-?: UpdateGameRequest[K]} {
  return {
    gameID: v.gameID,
    startDate: v.startDate,
    startHour: v.startHour,
    startMinute: v.startMinute,
    gameDurationMinutes: v.gameDurationMinutes,
    homeTeamID: v.homeTeamID,
    visitorTeamID: v.visitorTeamID,
    competitionUID: v.competitionUID,
    divID: v.divID,
    fieldUID: v.fieldUID,
    poolID: v.poolID,
    genderNeutral: v.genderNeutral,
    playoff: v.playoff,
    pointsCount: v.pointsCount,
    blockFromMatchmaker: v.blockFromMatchmaker,
    comment: v.comment,
    seasonUID: v.season.seasonUID,
    roundID: v.roundID,
  };
}

interface RoundMenuSelection {
  competitionUID: Guid | "",
  season: null | SeasonTriple,
  divID: Guid | "",
  poolID: "ALL" | Integerlike,
  roundID: Guid | ""
}
