import { computed, defineComponent, ref } from "vue";
import { SelectMany } from "src/components/RefereeSchedule/SelectMany";
import { dayjsOr, DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate";
import { assertTruthy, JS_DayOfWeek, rangeInc, sortByDayJS, UiOption, UiOptions, vReqT } from "src/helpers/utils";
import { Datelike, Integerlike } from "src/interfaces/InleagueApiV1";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { FormKit } from "@formkit/vue";
import { FkDynamicValidation } from "src/helpers/FkUtils";
import { Dayjs } from "dayjs";

export const EditDaysPerWeekModal = defineComponent({
  props: {
    baseDate: vReqT<Datelike>(),
    repeatWeeks: vReqT<Integerlike>(),
    repeatWeeksOptions: vReqT<UiOptions<Integerlike>>(),
    daysOfWeekPerWeek: vReqT<Integerlike<JS_DayOfWeek>[]>(),
    startDateWeekOptions: vReqT<UiOptions>(),
    initialWeekStart: vReqT<Datelike>(),
  },
  emits: {
    commit: (_: {repeatWeeks: Integerlike, daysOfWeekPerWeek: Integerlike<JS_DayOfWeek>[], initialWeekStart: Datelike}) => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    const repeatWeeks = ref(props.repeatWeeks)
    const daysOfWeekPerWeek = ref([...props.daysOfWeekPerWeek])
    const initialWeekStart = ref(props.initialWeekStart)

    const commit = () : void => {
      ctx.emit("commit", {
        repeatWeeks: repeatWeeks.value,
        daysOfWeekPerWeek: daysOfWeekPerWeek.value,
        initialWeekStart: initialWeekStart.value,
      })
    }

    const AtLeastOneDayOfWeekChecked = FkDynamicValidation(() => {
      if (daysOfWeekPerWeek.value.length === 0) {
        return {status: "invalid", msg: "Select at least one weekday"}
      }
      return {status: "valid"}
    })

    return () => {
      return <div>
        <FormKit
          type="form"
          actions={false}
          onSubmit={() => commit()}>
          <div class="font-medium text-sm mb-1">Initial Week:</div>
          <FormKit
            type="select"
            disabled={props.startDateWeekOptions.disabled}
            options={props.startDateWeekOptions.options}
            v-model={initialWeekStart.value}
            placeholder="Select an initial week"
            validation={[["required"]]}
            data-test="initialWeekStart"
            validationLabel="StartDate"
          />
          <div class="text-sm font-medium mb-1">Repeat for:</div>
          <FormKit
            type="select"
            disabled={props.repeatWeeksOptions.disabled}
            v-model={repeatWeeks.value}
            options={props.repeatWeeksOptions.options}
            data-test="repeatWeeks"
          />
          <div class="text-sm font-medium mb-1">Schedule on:</div>
          <DaysOfWeek
            baseDate={props.baseDate}
            repeatWeeks={repeatWeeks.value}
            daysOfWeekPerWeek={daysOfWeekPerWeek.value}
            onUpdate={fresh => {daysOfWeekPerWeek.value = fresh}}
          />
          <AtLeastOneDayOfWeekChecked/>
          <div class="mt-4 flex items-center gap-2">
            <Btn2
              type="submit"
              data-test="submit-button"
              class="px-2 py-1"
              disabled={daysOfWeekPerWeek.value.length === 0}
            >OK</Btn2>
            <Btn2
              class="px-2 py-1"
              enabledClasses={btn2_redEnabledClasses}
              onClick={() => ctx.emit("cancel")}
              data-test="cancel-button"
            >Cancel</Btn2>
          </div>
        </FormKit>

      </div>
    }
  }
})

const DaysOfWeek = defineComponent({
  props: {
    baseDate: vReqT<Datelike>(),
    repeatWeeks: vReqT<Integerlike>(),
    daysOfWeekPerWeek: vReqT<Integerlike<JS_DayOfWeek>[]>(),
  },
  emits: {
    update: (_: Integerlike<JS_DayOfWeek>[]) => true,
  },
  setup(props, ctx) {
    const opts = computed<UiOption<Integerlike<JS_DayOfWeek>>[]>(() => {
      const subsequentWeeks = props.repeatWeeks /*weakEq*/ == 0
        ? "and no subsequent weeks"
        : props.repeatWeeks /*weakEq*/ == 1
        ? "and 1 subsequent week"
        : `and ${props.repeatWeeks} subsequent weeks`

      const baseDate = dayjsOr(props.baseDate)

      if (!baseDate) {
        return [
          {label: "Mon", value: "-999" as any, attrs: {disabled: true}},
          {label: "Tue", value: "-999" as any, attrs: {disabled: true}},
          {label: "Wed", value: "-999" as any, attrs: {disabled: true}},
          {label: "Thu", value: "-999" as any, attrs: {disabled: true}},
          {label: "Fri", value: "-999" as any, attrs: {disabled: true}},
        ] satisfies UiOption<Integerlike<JS_DayOfWeek>>[]
      }
      else {
        // hard assumption that basedates are monday here, we will need a little addtional work to support
        // a dynamic base date
        assertTruthy(baseDate.day() === JS_DayOfWeek.MONDAY)

        const runOne = (v: Dayjs) : UiOption<Integerlike<JS_DayOfWeek>> => {
          return {
            label: `${v.format("ddd")}, ${v.format("MMM/DD/YYYY")} (${subsequentWeeks})`,
            value: v.day().toString() as Integerlike<JS_DayOfWeek>,
            attrs: {"data-test": v.format(DAYJS_FORMAT_HTML_DATE)}
          }
        };

        return [
          runOne(baseDate.add(0, "days")), // mon
          runOne(baseDate.add(1, "days")), // tue
          runOne(baseDate.add(2, "days")), // wed
          runOne(baseDate.add(3, "days")), // thu
          runOne(baseDate.add(4, "days")), // fri
        ] satisfies UiOption<Integerlike<JS_DayOfWeek>>[]
      }
    })

    const selected = computed<string[]>(() => props.daysOfWeekPerWeek.map(v => v.toString()))

    return () => {
      return <SelectMany
        data-test="DaysOfWeek"
        options={opts.value as UiOption<string>[]}
        selectedKeys={selected.value}
        offerAllOption={false}
        onCheckedOne={(fresh, isChecked) => {
          isChecked
            ? ctx.emit("update", [fresh as Integerlike<JS_DayOfWeek>, ...props.daysOfWeekPerWeek])
            : ctx.emit("update", props.daysOfWeekPerWeek.filter(v => v /*weakEq*/!=fresh))
        }}
        onCheckedAll={checked => {
          checked
            ? ctx.emit("update", opts.value.map(v => v.value))
            : ctx.emit("update", [])
        }}
      />
    }
  }
})
