import axios, { type AxiosError } from "axios"

/**
 * wraps an axios error shape (which is consistent, right? we need to clarify that...)
 * so that callers can try/catch axios requests and check if the error is "discardable"
 * (i.e. in most cases if an exception is `instanceof AxiosErrorWrapper`, we can silently discard it)
 * other errors would likely want to be rethrown ("accessing property of undefined" or whatever)
 *
 * We soak up some calls to things like toJSON, but most property accesses are delegated to the wrapped error.
 *
 * Note that we "are an AxiosError" rather than we are an Error that contains a nested AxiosError as its cause.
 * This is so we can mostly be used interchangeably (and lots of code assumes axios throws AxiosErrors rather than AxiosErrorWrappers,
 * so they must be compatible.))
 *
 * This is probably entirely replaceable with `axios.isAxiosError(someErr)`, which we didn't know about when we did this.
 * The following should hold, however: `axios.isAxiosError(someAxiosErrorWrapper) === true === axios.isAxiosError(someAxiosErrorWrapper.unwrap())`
 * So we can __probably__ stop using this and migrate away from it when convenient, though we need some inquiry into how to maintain the `toJSON`
 * behavior here, for error serialization purposes. Also, this does capture a stack, which may or may not be necessary for error serialization.
 */
export class AxiosErrorWrapper extends Error {
  private wrappedError : AxiosError
  private static DEPROXIFY = Symbol("AxiosErrorWrapper/deproxify");

  // gross hack to install magic tag property
  // prior to axios 0.27.2 this was picked it up via iterating over wrappedError's enumerable Object.keys()
  // In the long run, we should probably get rid of AxiosErrorWrapper and just use AxiosErrors directly
  readonly isAxiosError = true;

  constructor(v: AxiosError) {
    // inside axios error interceptor handler chains, the passed-in error/response objects are typed as any; so it's
    // likely callers may attempt to construct an AxiosErrorWrapper from an existing AxiosErrorWrapper.
    // We want to avoid creating unnecessarily nested things like AxiosErrorWrapper<AxiosErrorWrapper<AxiosError>>
    if (v instanceof AxiosErrorWrapper) {
      v = v.unwrap();
    }

    super(v.message);
    this.wrappedError = v;
    this.stack = v instanceof Error ? v.stack : /*hm, this is "current stack trace", which is not too helpful*/ getStackTrace();

    // we are also an AxiosError (i.e. accesing `this.<some-axios-error-prop>` is the same as `wrappedError[<some-axios-error-prop>]`),
    // but we want to discourage usage in that way
    // warnings are issued in dev mode if this is accessed in an unsafe way
    for (const key of Object.keys(v)) {
      if (key === "toJSON" || key === "stack") {
        // we want our toJSON not the wrapped error's toJSON
        continue;
      }
      (this as any)[key] = (v as any)[key];
    }

    // this machinery is just to catch places where we were relying on direct prop access
    // (old callers might be treating this as an AxiosError and not an AxiosErrorWrapper)
    // we allow it but warn on it
    // which means it was probably skipping checking if an error was an AxiosErrorWrapper
    // used "correctly", we unwrap the proxy to just "this" and the caller interacts only the type of this class
    // This proxy wrapper (and the key installation above) can probably be removed
    // once we've established that we are generally correctly checking that caught errors are AxiosErrorWrappers
    return new Proxy(this, {
      get(target, p, _receiver) {
        if (p === AxiosErrorWrapper.DEPROXIFY) {
          return target;
        }
        if (p !== "unwrap" && p !== "raw") {
          // if (process.env.NODE_ENV === "development") {
          //   // this log will be noisy on proxy access in debugger, when the debugger client touches properties for symbol display purposes
          //   console.warn("[inleague] AxiosErrorWrapper properties should not be accessed directly; consider using unwrap() or raw()")
          // }
        }
        return Reflect.get(target, p);
      }
    })
  }

  /**
   * To get at this without the Proxy wrapper
   */
  raw() : typeof this {
    // we may already be deproxified
    const deproxifiedThis = (this as any)[AxiosErrorWrapper.DEPROXIFY] ?? this;
    return deproxifiedThis;
  }

  /**
   * To get at the wrappedError without triggering warnings of direct-probably-wrong property access
   * (where the caller is treating this directly as an AxiosError)
   */
   unwrap() : AxiosError<any> {
    return this.raw().wrappedError;
  }

  /**
   * (re)throw `err` if `err` is not an instanceof `AxiosErrorWrapper`
   */
  static rethrowIfNotAxiosError(err: unknown) : asserts err is AxiosErrorWrapper {
    // consider both "wrapped" and "unwrapped" axios errors. It should always be the case
    // that a wrapped axios error would also have passed the `axios.isAxiosError(err)`.
    // At some point, if we have time to check out the knock-on effects, we can probably transition to just
    // using the `isAxiosError(err)` portion.
    if (err instanceof AxiosErrorWrapper || axios.isAxiosError(err)) {
      return;
    }
    else {
      throw err;
    }
  }

  /**
   * Check if the supplied value represents:
   *  - an axios error
   *  - but the error is something that we don't really care about
   */
  static isAxiosErrorNoise(v: unknown) : boolean {
    // axios cancellations (i.e. abortController.abort() was called) are not instances of AxiosError, so it gets checked for first
    if (axios.isCancel(v)) {
      return true;
    }

    const err = v instanceof AxiosErrorWrapper
      ? v.unwrap()
      : axios.isAxiosError(v)
      ? v
      : null;

    if (!err) {
      return false;
    }

    if (err.code === "ECONNABORTED") {
      //
      // It's not clear there's anything we can do about ECONNABORTED,
      // and in FireFox they are definitely fired incorrectly, when a page navigation (e.g. type into url bar and hit enter (possibly only to same-domain?))
      // happens and there are 1-or-more pending requests in the background.
      // https://bugzilla.mozilla.org/show_bug.cgi?id=486511
      // Chrome (and safari?) silently "cancel" the pending requests in such a case, no error (or success) handlers are called, whereas in firefox
      // we get an error handler being invoked.
      //
      return true;
    }

    if (/^network error$/i.test(err.message)) {
      // some kind of network error
      return true;
    }

    return false;
  }

  toJSON() {
    const requestConfig = this.wrappedError.response?.config ?? null;

    const requestData = (() => {
      if (requestConfig) {
        if (requestConfig.headers?.["Content-Type"] === "application/json") {
          const body = (() => {
            try { return JSON.parse(requestConfig.data);}
            catch (err) { return "<<couldn't parse json in presmudge>>"; }
          })();

          return JSON.parse(smudge(body));
        }
        else {
          return smudge(requestConfig.data);
        }
      }
    })();

    return {
      stack: {
        // these probably will only differ by a few frames, but
        // it would be interesting in async cases if they differ greatly.
        // we'll see.
        this: this.stack,
        wrapped: this.wrappedError.stack,
      },
      request: {
        headers: requestConfig?.headers ?? null,
        data: requestData ?? null,
        method: requestConfig?.method ?? null,
        url: requestConfig?.url ?? null,
        baseURL: requestConfig?.baseURL ?? null,
      },
      response: {
        headers: this.wrappedError.response?.headers ?? null,
        data: this.wrappedError.response?.data ?? null
      }
    }
  }
}

/**
 * Axios gets weird, unhelpful stack traces, from some async stack that doesn't include the important frames.
 * It seems we have to manually grab a useful stacktrace frome inside Axios error handlers; when we can do so (always? how's Safari?)
 * we stomp the existing `stack` property of the input `errorLike`, and overwrite it with a new stack trace, presumably a more
 * useful one. Because this is itself a function call, it would be nice to remove this frame from the resulting trace,
 * but there are differences between browsers that make that not a simple array slice.
 *
 * related: https://github.com/axios/axios/issues/2387
 *
 * returns void, side effect is mutate `errorLike` by writing a stack trace into it, on property `stack`
 */
export function getStackTrace() {
  return new Error().stack;
}

/**
 * Smudge out password fields, for example if they end up in request data.
 * This really should only be necessary from errors originating in login or new user forms.
 */
function smudge(obj: any) : string {
  return JSON.stringify(smudgeWorker(obj));

  function smudgeWorker(obj: any) : any {
    if (obj === undefined) {
      return "<<undefined>>";
    }
    else if (obj === null) {
      return null;
    }
    else if (Array.isArray(obj)) {
      return obj.map(smudgeWorker);
    }
    else if (typeof obj === "object") {
      const result : any = {};
      for (const key of Object.keys(obj)) {
        if (/password|secret/i.test(key)) {
          result[key] = "****";
        }
        else {
          result[key] = smudgeWorker(obj[key])
        }
      }
      return result;
    }
    else {
      if (/password|secret/i.test(obj.toString())) return "****";
      else return obj.toString();
    }
  }
}