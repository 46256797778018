import { defineComponent, ExtractPropTypes, PropType } from "vue";

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { ExtractEmitsHandlers } from "src/helpers/utils";

import { ExpandedPlayerSearchResult } from './PlayerLookup.shared';

export const propsDef = {
  displaySeasons: Boolean,
  eligibleIDs: {
    type: Array as PropType<string[]>,
    default: () => []
  },
  searchButtonLabel: {
    type: String,
    default: "Submit"
  },
  displayFilter: {
    required: false,
    type: Function as PropType<(value: ExpandedPlayerSearchResult) => boolean>
  },
  /**
   * We search against seasons.
   * The user may select "all seasons" or some particular season
   * On mount, we focus on a particular season.
   * "auto" --> on mount, focus on the current registration seasonID
   * "all" --> on mount, focus on "all" seasons
   */
  defaultSeason: {
    required: false,
    type: String as PropType<"auto" | "all">,
    default: "auto"
  },
  /**
   * this would be nicer as an actual slot, but we don't know how to get strongly typed slots
   * in the case where a slot is a child of a slot (this will go in a quasar table cell which is itself already a slot)
   */
  belowPlayerNameSlotlike: {
    required: false,
    type: Function as PropType<(props: {playerSearchResult: ExpandedPlayerSearchResult}) => JSX.Element>
  }
} as const;

export const emitsDef = {
  selected: (args: { row: ExpandedPlayerSearchResult, seasonID: iltypes.Integerlike | "ALL" }) => true,
  /**
   * We need consumers of this component to sometimes mutate the internal list of results.
   * When we get a new results listing, we emit this event and listeners can hold live references to it.
   */
  gotLookupResults: (value: {"liveRef!": ExpandedPlayerSearchResult[]}) => true,
} as const;

export type Props = ExtractPropTypes<typeof propsDef>
export type Emits = ExtractEmitsHandlers<typeof emitsDef>;

const NOEXPORT_jsx_sfc_shim__PlayerLookup = defineComponent({
  props: propsDef,
  emits: emitsDef
})
export type jsx_sfc_shim__PlayerLookup = typeof NOEXPORT_jsx_sfc_shim__PlayerLookup;
