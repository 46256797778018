import { computed, ComputedRef, ref, shallowRef, watch, WatchSource } from "vue";
import { assertTruthy, exhaustiveCaseGuard, sleep } from "./utils";
import { ExplodedPromise } from "./ExplodedPromise";

/**
 * A reified promise is intended to model a promise, but one that has an inspectable (and mutable) internal state.
 * Investigate: do we need the error type param (we always leave it defaulted to any, it's not really helpful)?
 */
export type ReifiedPromise<T,E=any> =
  | RP_Idle<T,E>
  | RP_Error<T,E>
  | RP_Pending<T,E>
  | RP_Resolved<T,E>

abstract class RP_ReifiedPromise_Base<T,E=any> {
  // conceptually sealed with the following closed set of subtypes
  abstract readonly status :
    | "idle" // the "null" or "zero" or "default initialized" state
    | "pending"
    | "resolved"
    | "error";

  /**
   * Returns the data if this represents a resolved promise, otherwise null
   */
  abstract getOrNull() : T | null
}

export class RP_Idle<T,E=any> extends RP_ReifiedPromise_Base<T,E> {
  readonly status = "idle";

  getOrNull() {
    return null
  }

  toJSON() {
    return {
      status: this.status
    }
  }
}

export class RP_Pending<T,E=any> extends RP_ReifiedPromise_Base<T,E> {
  readonly status = "pending"
  readonly promise : Promise<ReifiedPromise<T,E>>;

  constructor(v: Promise<ReifiedPromise<T,E>>) {
    super();
    this.promise = v;
  }

  getOrNull() {
    return null
  }

  toJSON() {
    return {
      status: this.status
    }
  }
}

export class RP_Resolved<T,E=any> extends RP_ReifiedPromise_Base<T,E> {
  readonly status = "resolved";
  readonly data : T;
  constructor(v: T) {
    super();
    this.data = v;
  }

  getOrNull() {
    return this.data
  }

  toJSON() {
    return {
      status: this.status,
      data: this.data,
    }
  }
}

export class RP_Error<T,E=any> extends RP_ReifiedPromise_Base<T,E> {
  readonly status = "error";
  readonly error : E;
  constructor (v: E) {
    super();
    this.error = v;
  }

  getOrNull() {
    return null
  }

  toJSON() {
    return {
      status: this.status,
      error: this.error,
    }
  }
}

export interface ReactiveReifiedPromise<T,E=any> {
  /**
   * Reset the underlying promise to "idle" state
   */
  readonly reset: () => ReactiveReifiedPromise<T,E>
  /**
   * Returns a Promise<this>, where:
   *  - if the underlying promise is "pending", awaits its resolution
   *  - otherwise, returns immediately
   */
  readonly awaiter: () => Promise<ReactiveReifiedPromise<T,E>>,
  /**
   * Run some function, placing the underlying promise in a pending state until the resulting promise resolves or rejects,
   * or another request replaces this request (e.g. a subsequent call to `run` prior to the first call to `run` completing)
   *
   * `debounce_ms` means "wait this long before firing the request, but enter pending state immediately;
   * if another request is fired during the debounce period of the first request, consider the first request stale"
   *
   * `minRuntime_ms` means "run the function but wait for at least minRuntime_ms to elapse before resolving."
   * If minRuntime_ms and debounce_ms are both specified, the debounce time is considered part of the runtime (so is subtracted from minRuntime)
   */
  readonly run: (f: () => Promise<T>, opts?: {debounce_ms?: number, minRuntime_ms?: number}) => ReactiveReifiedPromise<T,E>,
  /**
   * A reference to the underlying (reified) promise. This participates in vue's reactivity and always references the promise in its "current state"
   */
  readonly underlying: ReifiedPromise<T,E>,
  /**
   * Force the underlying promise into a "resolved" state, with some data.
   * The current pending request, if one exists, is discarded.
   */
  readonly forceResolve: (v: T) => ReactiveReifiedPromise<T,E>
  /**
   * Force the underlying promise into a rejected state, with some error.
   * The current pending request, if one exitsts, is discarded.
   */
  readonly forceReject: (v: E) => ReactiveReifiedPromise<T,E>
  /**
   * Force into pending state; the pending state is indefinite until additional actions are performed (e.g. calling run or forceResolve or etc.).
   */
  readonly forcePending: () => ReactiveReifiedPromise<T,E>
  /**
   * Extract a value or throw an error if no value is (or could possibly become) available as per the promises current state.
   */
  readonly getResolvedOrFail: () => Promise<T>
  /**
   * @deprecated use xMap or "computed" (and this isn't really a map method signature-wise anyway)
   */
  readonly map: <R>(f: (p: ReifiedPromise<T,E>) => R) => ComputedRef<R>
  /**
   * better map impl, (still not really a `map` in the sense that the signature is not "m a -> (a -> b) -> m b"),
   * this is more like extract-map or something
   * TODO: delete `map`, replace all usages with this?
   */
  readonly xMap: <R>(f: (p: T) => R) => ComputedRef<ReifiedPromise<R, E>>
}

type VueWatchSourceLike_ = (() => any) | (() => any)[]

/**
 * A value that represents the state of some possibly asynchronous computation,
 * where the results of both synchronous and asynchronous changes to that value are made
 * visibile through a single contained vue reactive object ("the promise"). All readers of the promise will receive notifications
 * (via vue's reactivity system) of changes to the promise.
 *
 * The strategy employed for request conflicts is "most recent request wins": each request to mutate the state
 * of the promise updates the single contained promise, and if that promise was in a pending state, all awaiters are resolved to
 * the new promise. E.g. component A says "resolve the promise with some promise for X", component B says "await the resolution or failure of X",
 * and subsequently component C says "resolve the promise with this promise for Y"; then all listeners receive the following:
 *  -> A sees pending state for X
 *  -> B awaiting X
 *  -> C pushes a promise for Y; Promise for X is updated to a promise for Y
 *     -> A sees no change (pending -> pending is not observable) (though it might react to a change in the .promise field if it is watching that)
 *        -> If A has reactive computed/watchers etc., if/when the promise changes to resolved, its payload will be Y
 *     -> B sees no change but when the await completes it will receive Y, not X
 */
export function ReactiveReifiedPromise<T,E=any>(
  immediateGetter?: (() => Promise<T>) | {deps: VueWatchSourceLike_, f: () => Promise<T>},
  handlers?: {
    onError?: (_: {currentRequestID: number, thisRequestID: number, error: E}) => void,
    onStale?: (_: {currentRequestID: number, thisRequestID: number, data: T}) => void,
    defaultDebounce_ms?: number | (() => number)
  },
  refMode : "ref" | "shallowRef" = "ref"
) : ReactiveReifiedPromise<T,E> {
  let requestID = 0;

  // This should never escape the local scope.
  // Local invariant that we can't quite typecheck:
  //  - workingPromise is non-null if v.value.status === "PENDING",
  //  - workingPromise is null     if v.value.status !== "PENDING"
  // This represents the promise contained inside the promise<> when it is pending, and can locally
  // be resolved with a result, or a new Promise (if some new request "stomps" a prior, incomplete request)
  let workingPromise_ : ExplodedPromise<ReifiedPromise<T,E>> | null = null;

  const v = refMode === "ref"
    ? ref<ReifiedPromise<T,E>>(new RP_Idle())
    : shallowRef<ReifiedPromise<T,E>>(new RP_Idle())
  /**
   * want to say
   * external: const x = future.value;
   * internally: v.value = {...newState} // assignment to value, v.value is a new object, technically meaning `x !== value` is no longer true
   * external: x.status === "some-new-state" // but we want to x to always point into v.value, as-if x.foo is exactly v.value.foo,, i.e. a direct read from v.value, whatever is the "current" object held there
   */
  const vProxy = new Proxy(v, {
    get(target, p, receiver) {
      return Reflect.get(target.value, p, receiver);
    },
    set() {
      throw Error("This proxy has no assignable members.")
    }
  }) as any as ReifiedPromise<T,E>

  const self : ReactiveReifiedPromise<T,E> = {
    reset() {
      requestID++;
      v.value = new RP_Idle()
      workingPromise_?.resolve(vProxy);
      workingPromise_ = null;
      return self;
    },
    async awaiter() {
      if (v.value.status === "pending") {
        await v.value.promise;
      }
      return self;
    },
    run(f: () => Promise<T>, opts?: {debounce_ms?: number, minRuntime_ms?: number}) {
      requestID += 1;
      const thisRequestID = requestID;
      const nextWorkingPromise = new ExplodedPromise<ReifiedPromise<T,E>>();

      const isStale = () => requestID !== thisRequestID;

      const runWithMinRuntime = opts?.minRuntime_ms
        ? async (ms: number) => {
          assertTruthy(ms >= 0)
          const [v] = await Promise.all([f(), sleep(ms)])
          return v;
        }
        : async () => await f()

      const runMaybeWithDebounce = (() => {
        const debounce_ms = opts?.debounce_ms
          ?? (typeof handlers?.defaultDebounce_ms === "function" ? handlers.defaultDebounce_ms() : handlers?.defaultDebounce_ms)
          ?? 0

        return debounce_ms ? async () => {
          await sleep(debounce_ms)
          if (isStale()) {
            return {type: "debounce-stale" as const}
          }
          else {
            return {type: "ok" as const, data: await runWithMinRuntime(Math.max(0, (opts?.minRuntime_ms ?? 0) - debounce_ms))}
          }
        }
        : async () => ({type: "ok" as const, data: await runWithMinRuntime(0)})
      })()

      void runMaybeWithDebounce()
        .then(data => {
          if (data.type === "debounce-stale") {
            return;
          }

          if (isStale()) {
            // mostly intended for testing/logging
            handlers?.onStale?.({currentRequestID: requestID, thisRequestID, data: data.data})
            return;
          }

          v.value = new RP_Resolved(data.data) /*unfortunate vue generics issue*/ as any;

          nextWorkingPromise.resolve(vProxy);
          workingPromise_ = null;
        })
        .catch(error => {
          // if `onError` throws, we need to hold onto it until after our bookkeeping is done
          let nestedException : any;
          try {
            if (handlers?.onError) {
              handlers?.onError?.({currentRequestID: requestID, thisRequestID, error});
            }
            else {
              console.error(error)
            }
          }
          catch (err) {
            nestedException = err
          }

          try {
            if (isStale()) {
              return;
            }

            v.value = new RP_Error(error)

            // n.b. NOT reject
            nextWorkingPromise.resolve(vProxy);
            workingPromise_ = null;
          }
          finally {
            if (nestedException) {
              throw nestedException
            }
          }
        });

      if (workingPromise_) {
        workingPromise_.resolve(nextWorkingPromise.promise)
      }

      workingPromise_ = nextWorkingPromise;

      v.value = new RP_Pending(nextWorkingPromise.promise);

      return self;
    },
    get underlying() : ReifiedPromise<T,E> { return vProxy; },
    forcePending() {
      return self.run(async () => {
        await new Promise(() => {/*never resolves*/});
        throw Error("unreachable");
      })
    },
    forceResolve(data: T) {
      requestID += 1;

      v.value = new RP_Resolved(data) /*unfortunate vue generics issue*/ as any

      workingPromise_?.resolve(vProxy);
      workingPromise_ = null;

      return self
    },
    forceReject(error: E) {
      requestID += 1;

      v.value = new RP_Error(error) /*unfortunate vue generics issue*/ as any

      // n.b. NOT reject
      workingPromise_?.resolve(vProxy);
      workingPromise_ = null;

      return self
    },
    async getResolvedOrFail() {
      switch (v.value.status) {
        case "idle":
          throw new UnresolvableReactiveReifiedPromiseError("resolvedOrFail -- no promise pending at time of call")
        case "pending":
          // resolve it
          const v2 = await v.value.promise
          switch (v2.status) {
            case "idle":
              throw new UnresolvableReactiveReifiedPromiseError("resolvedOrFail -- promise was reset to idle during await")
            case "pending":
              // impossible, yeah?
              // TODO: test case, 2+ requests while waiting can trigger this right? do we need to tail recurse here? with a max limit on retries?
              throw new UnresolvableReactiveReifiedPromiseError("resolvedOrFail -- promise still pending?")
            case "resolved":
              return v2.data
            case "error":
              throw v2.error
            default: exhaustiveCaseGuard(v2)
          }
        case "error":
          throw v.value.error
        case "resolved":
          return v.value.data /*unfortunate vue generics issue*/ as T
        default: exhaustiveCaseGuard(v.value)
      }
    },
    map(f) {
      return computed(() => f(v.value as ReifiedPromise<T,E>))
    },
    xMap<R>(f: (v: T) => R) {
      return computed<ReifiedPromise<R,E>>(() => {
        return v.value.status === "resolved"
          ? new RP_Resolved<R,E>(f(v.value.data /*unfortunate vue generics issue*/ as T))
          : v.value as ReifiedPromise<R, E>
      })
    }
  }
  if (immediateGetter) {
    if (typeof immediateGetter === "function") {
      self.run(immediateGetter)
    }
    else {
      watch(immediateGetter.deps, () => {
        self.run(immediateGetter.f)
      }, {immediate: true})
    }
  }

  return self;
}

// we could do a few add'l subtypes if we wanted to get real granular, but this is good enough
export class UnresolvableReactiveReifiedPromiseError extends Error {
  constructor(message?: string, options?: ErrorOptions) {
    super(message, options)
  }
}
