import { computed, defineComponent, ref, UnwrapRef } from "vue"
import dayjs, { Dayjs } from "dayjs"
import { ilDraggable, vueDirective_ilDraggable } from "src/modules/ilDraggable"
import { vReqT, exhaustiveCaseGuard, gatherByKey_manyPerKey, sortBy, assertNonNull, requireNonNull, assertTruthy, VueGenericRefKludge, _TGenericComponent, useWindowEventListener, max } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1"
import { CalendarElementStyle } from "./CalendarElementStyle"
import { LayoutNodeRoot, LayoutNode } from "./CalendarLayout"
import { CalendarUiState, isEffectivelyAllDay } from "./GameScheduler.shared"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faUpDownLeftRight } from "@fortawesome/pro-solid-svg-icons"

export {
  CalendarElementMover,
  CalendarElementVerticalResizer,
  // vite HMR not as nice exporting this way (triggers full caller reload)
  //CalendarGridElement,
}

export interface CalendarGridElementSlots<T = any> {
  /**
   * `domElem` here can be null during the first render, so typically we won't observe it in its null state
   */
  body?: (_: {
    layoutNode: LayoutNode<T>,
    elementStyle: CalendarElementStyle | null,
    nonInteractive: boolean,
    domElem: HTMLElement | null,
  }) => JSX.Element | null
}

// TODO: better name
export interface MinNode {
  uiState: CalendarUiState
}

// investigate: naming -- "RecursiveCalendarGridElement"
export const CalendarGridElement = defineComponent({
  props: {
    date: vReqT<string>(),
    layoutStrategy: vReqT<
      // TODO: should be width-per-node rather than "max width of entire lane" (i.e. "width of tree at this time slot")
      | {method: "overlapOK", forestMaxWidth: number}
      | {method: "warnOverlap"}
    >(),
    fieldUID: vReqT<Guid>(),
    // might be good to have separate elements, one for the "root", and one for the rest
    layoutNode: vReqT<LayoutNodeRoot<_TGenericComponent & MinNode> | LayoutNode<_TGenericComponent & MinNode>>(),
    px_containerHeight: vReqT<number>(),
    px_containerWidth: vReqT<number>(),
    px_xOffset: vReqT<number>(),
    startHour24Inc: vReqT<number>(),
    // endHour24 "inclusive", i.e. if it is 8, then we show 8, meaning we show all the way 8:59:59
    // so `(endHour24 - zeroHour24) + 1` is the "full span" of hours we expect to be showing
    endHour24Inc: vReqT<number>(),
    px_heightPerHour: vReqT<number>(),
    px_elemWidth: vReqT<number>(), // the width of the element to be drawn
    px_laneWidth: vReqT<number>(), // the width of the entire lane in which the element is to be drawn
    px_cellBorderAndGridlineThickness: vReqT<number>(),
    elemVerticalResizer: vReqT<CalendarElementVerticalResizer | null>(),
    elemMover: vReqT<CalendarElementMover<_TGenericComponent & MinNode> | null>(),
    z: vReqT<number>(),
    /**
     * The single global "this node is being dragged around", or null if no drag is in progress.
     */
    moveeNode: vReqT<LayoutNode<_TGenericComponent & MinNode> | null>(),
    gridSlicesPerHour: vReqT<number>(),
    getCalendarElementStyles: vReqT<(_: LayoutNodeRoot<any> | LayoutNode<any>) => CalendarElementStyle | null>(),
    isInBulkSelectMode: vReqT<boolean>(),
    selectedCompetitionUIDs: vReqT<Set<Guid>>(),
    selectedDivIDs: vReqT<Set<Guid>>(),
    focusOnBracketGames: vReqT<boolean>(),
    isNonInteractiveLayoutNode: vReqT<(_: LayoutNodeRoot<any> | LayoutNode<any>) => boolean>(),
    canDragOrResizeNode: vReqT<(_: LayoutNode<any> | LayoutNodeRoot<any>) => boolean>(),
    authZ_canEditNodeViaOverlay: vReqT<(_: LayoutNode<any> | LayoutNodeRoot<any>) => boolean>(),
    allowDragOps: vReqT<boolean>()
  },
  directives: {
    ilDraggable: vueDirective_ilDraggable
  },
  emits: {
    showConfirmDeleteModal: (_: LayoutNode<_TGenericComponent & MinNode>) => true,
    click: (_: {layoutNode: LayoutNode<_TGenericComponent & MinNode>, domElement: HTMLElement}) => true,
  },
  setup(props, {slots, emit}) {
    const endSameDayAsStart = computed(() => {
      if (!props.layoutNode.parent) {
        return dayjs() // dummy, shouldn't ever be used
      }
      return props.layoutNode.data.uiState.time.start.hour(props.endHour24Inc + 1)
    })

    const px_verticalSize = computed(() => {
      if (!props.layoutNode.parent) {
        return 0
      }

      const endClampedToStartDay = props.layoutNode.data.uiState.time.end.unix() > endSameDayAsStart.value.unix()
        ? endSameDayAsStart.value
        : props.layoutNode.data.uiState.time.end

      const startHourClamped = Math.max(props.layoutNode.data.uiState.time.start.hour(), props.startHour24Inc)

      const diffSeconds = endClampedToStartDay.unix() - props.layoutNode.data.uiState.time.start.hour(startHourClamped).unix()
      const diffHours = diffSeconds / 3600
      const hoursSpan = (props.endHour24Inc - props.startHour24Inc) + 1

      // offset of -1 to not cover the gridline itself
      return Math.round(props.px_containerHeight * (diffHours / hoursSpan)) - props.px_cellBorderAndGridlineThickness - 1;
    })

    const y_offset = computed(() => {
      if (!props.layoutNode.parent) {
        return 0
      }

      const startHourClamped = Math.max(props.layoutNode.data.uiState.time.start.hour(), props.startHour24Inc)
      const borderAdjust = startHourClamped - props.startHour24Inc
      const mins = (startHourClamped * 60) + props.layoutNode.data.uiState.time.start.minute()

      return Math.round(borderAdjust + ((mins / 60) - props.startHour24Inc) * props.px_heightPerHour)
    })

    const deltaPx2NewTime = (originalTime: {start: Dayjs, end: Dayjs}, deltaPx: number, adjusting: "start" | "end") : {start: Dayjs, end: Dayjs} => {
      const snapMinutes = 60 / props.gridSlicesPerHour
      const snapSeconds = snapMinutes * 60
      const hourSpan = (props.endHour24Inc - props.startHour24Inc) + 1
      const pxPerHour = props.px_containerHeight / hourSpan
      const pxPerMinute = pxPerHour / 60
      const deltaMinutes = deltaPx / pxPerMinute

      if (adjusting === "start") {
        const newUnix = originalTime.start.add(deltaMinutes, "minutes").unix()
        const snappedUnix = Math.round(newUnix / snapSeconds) * snapSeconds;
        const adjustedStart = dayjs(snappedUnix * 1000)

        const minStartTime = originalTime.start.hour(props.startHour24Inc).minute(0).second(0)
        const maxEndTime = originalTime.end.subtract(snapMinutes, "minutes")
        return {
          start: adjustedStart.isAfter(maxEndTime) ? maxEndTime : adjustedStart.isBefore(minStartTime) ? minStartTime : adjustedStart,
          end: originalTime.end
        }
      }
      else if (adjusting === "end") {
        const newUnix = originalTime.end.add(deltaMinutes, "minutes").unix()
        const snappedUnix = Math.round(newUnix / snapSeconds) * snapSeconds;
        const adjustedEnd = dayjs(snappedUnix * 1000)

        const minStartTime = originalTime.start.add(snapMinutes, "minutes")
        const maxEndTime = originalTime.end.hour(props.endHour24Inc + 1).minute(0)
        return {
          start: originalTime.start,
          end: adjustedEnd.isBefore(minStartTime) ? minStartTime : adjustedEnd.isAfter(maxEndTime) ? maxEndTime : adjustedEnd
        }
      }
      else {
        exhaustiveCaseGuard(adjusting)
      }
    }

    const effectiveZIndex = computed(() => {
      return !props.layoutNode.parent ? 0
        : props.layoutNode.data.uiState.isBeingVerticallyResized ? 999
        : props.layoutNode.data.uiState.dragState === "drag-handle" ? 999
        : props.layoutNode.data.uiState.isModalOrOverlayFocus ? 999
        : props.layoutNode.data.uiState.dragState === "stationary-drag-source" ? 99
        : props.z;
    })

    /**
     * Child nodes, grouped by start-time, where the groups themselves are sorted by startTime asc.
     * Items having the same start time should share the same y offsets. We assume the provided
     * list is already sorted in ascending and so the resulting per-group (i.e. 2nd level) lists
     * will also remain sorted.
     * Investigate: shouldn't we (can we?), uh, receive a data structure from `props` already like this?
     */
    const childNodesGroupedByStartTime = computed<LayoutNode<MinNode>[][]>(() => {
      const nodesPerStartTime = gatherByKey_manyPerKey(
        props.layoutNode.children,
        node => {
          return (node.data.uiState.time.start.hour() * 60) + node.data.uiState.time.start.minute()
        }
      );

      return [...nodesPerStartTime.entries()]
        .sort(sortBy(_ => /*startTime*/ _[0]),)
        .map(_ => /*nodes per startTime*/_[1])
    })

    const canDragOrResize = computed(() => {
      return props.canDragOrResizeNode(props.layoutNode)
    })

    /**
     * Is this node for a something that isn't interactive -- where "is not interactive" means "user didn't select to see it, or doesn't have permissions to do anything with it,
     * but we still want to display it because users will need to know what else is schedule for some field".
     */
    // @hoist
    // const isNonInteractiveSlotNode = computed(() => {
    //   if (!props.layoutNode.parent) {
    //     // false, this is the tree root node
    //     return false;
    //   }

    //   if (props.layoutNode.data.type === "fieldBlock") {
    //     return false
    //   }

    //   if (props.focusOnBracketGames && !props.layoutNode.data.data.bracketRoundSlot) {
    //     return true
    //   }

    //   return !props.selectedDivIDs.has(props.layoutNode.data.data.divID)
    //     || !props.selectedCompetitionUIDs.has(props.layoutNode.data.data.competitionUID)
    // })

    const elementStyle = computed<null | CalendarElementStyle>(() => {
      return props.getCalendarElementStyles(props.layoutNode)
    })

    /**
     * Size of each of the top and bottom regions contained inside a layed-out element,
     * where the mouse will act as a vertical resizer.
     */
    const px_verticalResizeGutter = 8;

    /**
     * DOM ref of the root of the calendar element for our node
     */
    const elemRef = ref<HTMLElement | null>(null)

    const isNonInteractiveLayoutNode = computed(() => props.isNonInteractiveLayoutNode(props.layoutNode))

    const ctrlPressed = ref(false)
    useWindowEventListener("keydown", evt => {
      if (evt.ctrlKey) {
        ctrlPressed.value = true
      }
    })
    useWindowEventListener("keyup", evt => {
      if (!evt.ctrlKey) {
        ctrlPressed.value = false
      }
    })
    const dragOverlayBgColor = "rgba(255,255,255,.5)"

    return () => {
      return (
        <>
          {
            // moveeNode gets special treatment, because it is "exotree" (not contained as a member of props.layoutNode)
            props.moveeNode
              ? <CalendarGridElement
                key={props.moveeNode.data.uiState.__vueKey}
                layoutStrategy={props.layoutStrategy}
                layoutNode={props.moveeNode}
                px_containerHeight={props.px_containerHeight}
                px_xOffset={0}
                startHour24Inc={props.startHour24Inc}
                endHour24Inc={props.endHour24Inc}
                px_heightPerHour={props.px_heightPerHour}
                px_containerWidth={props.px_containerWidth}
                px_elemWidth={props.px_containerWidth - props.px_cellBorderAndGridlineThickness}
                px_laneWidth={props.px_laneWidth}
                px_cellBorderAndGridlineThickness={props.px_cellBorderAndGridlineThickness}
                elemVerticalResizer={props.elemVerticalResizer}
                elemMover={props.elemMover}
                z={props.z+1}
                moveeNode={null}
                date={props.date}
                fieldUID={props.fieldUID}
                gridSlicesPerHour={props.gridSlicesPerHour}
                getCalendarElementStyles={props.getCalendarElementStyles}
                isInBulkSelectMode={props.isInBulkSelectMode}
                selectedCompetitionUIDs={props.selectedCompetitionUIDs}
                selectedDivIDs={props.selectedDivIDs}
                focusOnBracketGames={props.focusOnBracketGames}
                canDragOrResizeNode={props.canDragOrResizeNode}
                isNonInteractiveLayoutNode={props.isNonInteractiveLayoutNode}
                authZ_canEditNodeViaOverlay={props.authZ_canEditNodeViaOverlay}
                allowDragOps={props.allowDragOps}
              >{slots}</CalendarGridElement>
              : null
          }
          {
            //
            // This is "the element"
            // @grep the element theElement the main thing theMainThing
            //
            props.layoutNode.parent // i.e. "not the root element"
              ? (
                <div
                  ref={elemRef}
                  data-test={props.layoutNode.data.uiState.testID}
                  style={{
                    //padding: `0 .25em`,
                    position: `absolute`,
                    top: `${y_offset.value}px`,
                    height: `${px_verticalSize.value}px`,
                    width: props.layoutNode.data.uiState.isBeingVerticallyResized ? "100%" : `${props.px_elemWidth}px`,
                    left: props.layoutNode.data.uiState.isBeingVerticallyResized ? 0 : `${props.px_xOffset}px`,
                    contain: "strict", // we're absolutely positioned, and have explicit height/width calcs, contain:strict should be good here to not spend time painting offscreen elements
                    overflow: "hidden",
                    // It would probably be better to just straight up build
                    // the DOM in the correct zOrder, but explicitly setting z is good enough for now
                    zIndex: effectiveZIndex.value,
                    cursor: props.layoutNode.data.uiState.isBeingVerticallyResized ? "row-resize" : undefined,
                    ...elementStyle.value?.body, //@fixme @hoist? @slottify
                    ...(props.layoutNode.data.uiState.isBulkSelected ? {backgroundColor: "rgb(106, 163, 255)", color: "black"} : undefined),
                    // don't soak up pointer events if an element is being moved; we want drag events to go to the grid
                    // (uh ... shouldn't they do so naturally? we're not stopPropagating things right?)
                    pointerEvents: `${props.elemMover?.isMoving ? "none" : "auto"}`
                  }}
                  // TODO: remove this click handler, it should be the responsibility of any slots we end up rendering
                  onClick={() => {
                    assertNonNull(props.layoutNode.parent) // "is not root node"
                    if (!elemRef.value) {
                      return
                    }
                    emit("click", {layoutNode: props.layoutNode, domElement: elemRef.value})
                  }}
                  class={[
                    "text-sm",
                    "border border-white rounded-md",
                    "overflow",
                    props.layoutNode.data.uiState.isModalOrOverlayFocus
                      || props.layoutNode.data.uiState.isBulkSelected ? "outline outline-black outline-dashed outline-3" : "",
                  ]}
                >
                  {/*new stacking context _within_ absolute pos parent*/}
                  <div
                    class="flex flex-col h-full relative" // n.b. flex-col is required (why?) if we want the slot body __to be able to__ show a scrollbar (and it will still need to mark itself overflow-y-auto)
                    style="z-index:0;"
                  >
                    {(slots as CalendarGridElementSlots).body?.({
                      layoutNode: props.layoutNode,
                      elementStyle: elementStyle.value,
                      nonInteractive: isNonInteractiveLayoutNode.value,
                      domElem: elemRef.value
                    })}
                    {!props.allowDragOps || !ctrlPressed.value || isNonInteractiveLayoutNode.value
                      ? null
                      : <>
                        {/*adjust gameStart by dragging top*/}
                        <div
                          onClick={evt => {
                            // don't bubble into a click on the parent
                            evt.stopImmediatePropagation()
                          }}
                          onMousedown={evt => {
                            if (!props.elemVerticalResizer) {
                              return
                            }

                            if (!canDragOrResize.value) {
                              return;
                            }

                            assertNonNull(props.layoutNode.parent, "always remains true from outer flow type")

                            evt.preventDefault() // stop dragging mouse from selecting text
                            evt.stopImmediatePropagation()
                            props.elemVerticalResizer.startResizingGameVertically({
                              startPageY: evt.pageY,
                              node: props.layoutNode,
                              viewport: {
                                startHour24Inc: props.startHour24Inc,
                                endHour24Inc: props.endHour24Inc,
                              },
                              which: "start",
                              date: props.date,
                              field: props.fieldUID,
                              deltaPx2NewTime: deltaPx2NewTime,
                            })
                          }}
                          style={{
                            backgroundColor: dragOverlayBgColor,
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: `${px_verticalResizeGutter}px`,
                            cursor: canDragOrResize.value ? "row-resize" : undefined
                          }}>
                        </div>

                        {/*
                          draggable/clickable area
                          this eats mouse clicks on top of the body portion of each element,
                          can't seem to figure out a way to have an area that admits of being a drag handle
                          but that also allows clicks to peirce through to underlying elements. One solution
                          we have is to conditionally render this based on if we are in "drag mode"
                        */}
                        <div
                          data-test="primaryInteractor"
                          v-ilDraggable={!canDragOrResize.value ? null : {
                            dragHandleJsxFunc: () => null,
                            onDragStart: (_, evt) => {
                              if (!props.elemMover) {
                                return false
                              }

                              const elemMover = props.elemMover

                              assertNonNull(props.layoutNode.parent, "outer flow type remains valid here")
                              if (props.elemMover.isMoving) {
                                return false;
                              }

                              evt.stopPropagation()

                              const {offsetX, offsetY} = evt

                              setTimeout(() => {
                                // run this next tick, otherwise sync dom mutations end up immediately firing "dragend"
                                if (!props.layoutNode.parent) {
                                  // super unlikely here, but could happen if the timeout took longer than expected
                                  return
                                }
                                elemMover.startMovingGame(props.date, props.fieldUID, props.layoutNode, offsetX, offsetY)
                              }, 0)

                              return true
                            },
                            onLeaveOrEnd: () => {
                              props.elemMover?.tryReset()
                            }
                          } satisfies ilDraggable}
                          class={[
                            // TODO: we have problems setting the cursor globally when it would otherwise make sense to do so
                            // (e.g. when a node is being vertically resized, set the cursor globally to row-resize).
                            // Instead these values always override anything set from parent elements (as css tends to do).
                            // So anyway, we need to do a little dance here to try to get the right one to show up.
                            props.layoutNode.data.uiState.dragState === "drag-handle" ? "cursor-move"
                            : props.layoutNode.data.uiState.isBeingVerticallyResized ? undefined
                            : props.authZ_canEditNodeViaOverlay(props.layoutNode) ? "cursor-pointer"
                            : undefined
                          ]}
                          style={{
                            backgroundColor: dragOverlayBgColor,
                            position: "absolute",
                            top: `${px_verticalResizeGutter}px`,
                            left: "0",
                            bottom: `${px_verticalResizeGutter}px`,
                            width: "100%", // we once had "calc(100% - 15px)" to adjust for a scroll bar
                          }}>
                            <div class="flex items-center justify-center text-black w-full h-full text-xl">
                              <FontAwesomeIcon icon={faUpDownLeftRight}/>
                            </div>
                        </div>

                        {/*adjust gameEnd by dragging bottom*/}
                        <div
                          onClick={evt => {
                            // don't bubble into a click on the parent
                            evt.stopImmediatePropagation()
                          }}
                          onMousedown={evt => {
                            if (!props.elemVerticalResizer) {
                              return
                            }

                            if (!canDragOrResize.value) {
                              return;
                            }
                            assertNonNull(props.layoutNode.parent, "outer flow type remains valid here")

                            evt.preventDefault() // stop dragging mouse from selecting text
                            evt.stopImmediatePropagation()

                            props.elemVerticalResizer.startResizingGameVertically({
                              startPageY: evt.pageY,
                              node: props.layoutNode,
                              viewport: {
                                startHour24Inc: props.startHour24Inc,
                                endHour24Inc: props.endHour24Inc,
                              },
                              which: "end",
                              date: props.date,
                              field: props.fieldUID,
                              deltaPx2NewTime: deltaPx2NewTime,
                            })
                          }}
                          style={{
                            backgroundColor: dragOverlayBgColor,
                            position: "absolute",
                            bottom: "0",
                            left: 0,
                            width: "100%",
                            height: `${px_verticalResizeGutter}px`,
                            cursor: canDragOrResize.value ? "row-resize" : undefined
                          }}>
                        </div>
                      </>
                    }

                    {/*stationary drag source indicator*/}
                    {
                      props.layoutNode.data.uiState.dragState === "stationary-drag-source"
                        // google calendar sets the opacity of the drag source element to ~50% or so; but their layout is more conducive to this (elements are stacked but the things underneath don't take up 100%)
                        ? <div style="position:absolute; top: 0; left: 0; width: 100%; height:100%; background-color:rgba(0,0,0,.35);"></div>
                        : null
                    }

                    {/* isSaving=true overlay */}
                    {props.layoutNode.data.uiState.isSaving || props.layoutNode.data.uiState.isBusy
                      ? <div class="absolute top-0 left-0 h-full w-full bg-white opacity-30"></div>
                      : null
                    }
                  </div>
                </div>
              )
              : null
          }
          {
            childNodesGroupedByStartTime
              .value
              .flatMap(nodes => {
                const isRoot = !props.layoutNode.parent
                const offsetX = props.px_xOffset;

                return nodes.map((node, i) => {
                  let allocate_x_perElement : number
                  let px_freshOffset : number
                  let px_freshElemWidth : number

                  if (props.layoutStrategy.method === "overlapOK") {
                    const owningTreeWidth = props.layoutStrategy.forestMaxWidth
                    allocate_x_perElement = props.px_laneWidth / owningTreeWidth
                    px_freshOffset = isRoot ? 0 : (offsetX + allocate_x_perElement)
                    px_freshElemWidth = allocate_x_perElement
                  }
                  else if (props.layoutStrategy.method === "warnOverlap") {
                    const width = props.px_elemWidth;
                    const freshOffset = isRoot ? 0 : 48
                    const remaining = width - offsetX - freshOffset;
                    allocate_x_perElement = remaining / nodes.length;
                    px_freshElemWidth = props.px_elemWidth - freshOffset - (allocate_x_perElement * i)
                    px_freshOffset = props.px_xOffset + freshOffset + (allocate_x_perElement * i)
                  }
                  else {
                    exhaustiveCaseGuard(props.layoutStrategy)
                  }

                  return (
                    <CalendarGridElement
                      key={node.data.uiState.__vueKey}
                      layoutNode={node}
                      layoutStrategy={props.layoutStrategy}
                      px_containerHeight={props.px_containerHeight}
                      px_xOffset={px_freshOffset}
                      px_cellBorderAndGridlineThickness={props.px_cellBorderAndGridlineThickness}
                      startHour24Inc={props.startHour24Inc}
                      endHour24Inc={props.endHour24Inc}
                      px_heightPerHour={props.px_heightPerHour}
                      px_containerWidth={props.px_containerWidth}
                      px_elemWidth={px_freshElemWidth}
                      px_laneWidth={props.px_laneWidth}
                      elemVerticalResizer={props.elemVerticalResizer}
                      elemMover={props.elemMover}
                      z={props.z+1}
                      date={props.date}
                      fieldUID={props.fieldUID}
                      moveeNode={null}
                      gridSlicesPerHour={props.gridSlicesPerHour}
                      getCalendarElementStyles={props.getCalendarElementStyles}

                      onShowConfirmDeleteModal={args => emit("showConfirmDeleteModal", args)}
                      isInBulkSelectMode={props.isInBulkSelectMode}

                      selectedCompetitionUIDs={props.selectedCompetitionUIDs}
                      selectedDivIDs={props.selectedDivIDs}
                      focusOnBracketGames={props.focusOnBracketGames}
                      canDragOrResizeNode={props.canDragOrResizeNode}
                      isNonInteractiveLayoutNode={props.isNonInteractiveLayoutNode}
                      authZ_canEditNodeViaOverlay={props.authZ_canEditNodeViaOverlay}
                      onClick={args => emit("click", args)}
                      allowDragOps={props.allowDragOps}
                  >{slots}</CalendarGridElement>)
                });
              })
          }
        </>
      )
    }
  }
})


function CalendarElementVerticalResizer<T extends MinNode>() {
  let onResizeCommitted : undefined | ((_: {layoutNode: LayoutNode<T>, date: string, field: string, preMutationGameDate: {start: Dayjs, end: Dayjs}}) => boolean | Promise<boolean>)
  interface State {
    savedGlobalCursorStyle : string,
    node: LayoutNode<T>,
    preMutationGameDate: {start: Dayjs, end: Dayjs}
    viewport: {
      startHour24Inc: number,
      endHour24Inc: number,
    }
    startPageY: number
    convertDeltaPxToNewTime: (originalTime: {start: Dayjs, end: Dayjs}, px: number, which: "start" | "end") => {start: Dayjs, end: Dayjs};
    /**
     * Are we adjusting the start time, or the end time
     */
    which: "start" | "end"
    date: string,
    field: string,
    isAsyncCommitting: boolean,
  }

  const state = ref<null | State>(null)

  const clearResizeHandlers = () => {
    window.removeEventListener("mouseup", onMouseUpButtonRelease, {capture: true})
    window.removeEventListener("mousemove", onMouseMove, {capture: true})
    window.removeEventListener("keyup", onEsc, {capture: true})
  }

  const installResizeHandlers = () => {
    window.addEventListener("mouseup", onMouseUpButtonRelease, {capture: true})
    window.addEventListener("mousemove", onMouseMove, {capture: true})
    window.addEventListener("keyup", onEsc, {capture: true})
  }

  const onEsc = (evt: KeyboardEvent) => {
    if (evt.key === "Escape") {
      evt.preventDefault()
      evt.stopPropagation()
      clearResizeHandlers()
      cancelResize()
    }
  }

  const onMouseUpButtonRelease = (evt: MouseEvent) => {
    assertNonNull(state.value)

    evt.preventDefault()
    evt.stopImmediatePropagation()

    clearResizeHandlers()
    void tryCommitResize()
  }

  const cancelResize = () => {
    assertNonNull(state.value)

    clearResizeHandlers()

    state.value.node.data.uiState.isBeingVerticallyResized = false

    state.value.node.data.uiState.time = {
      start: state.value.preMutationGameDate.start,
      end: state.value.preMutationGameDate.end,
      isEffectivelyAllDay: isEffectivelyAllDay(state.value.preMutationGameDate.start.unix(), state.value.preMutationGameDate.end.unix())
    }

    document.body.style.cursor = state.value.savedGlobalCursorStyle

    state.value = null
  }

  const tryCommitResize = async () => {
    assertNonNull(state.value)
    state.value.isAsyncCommitting = true

    try {
      if (onResizeCommitted) {
        let ok : boolean
        try {
          ok = await onResizeCommitted({layoutNode: state.value.node as VueGenericRefKludge<LayoutNode<T>>, date: state.value.date, field: state.value.field, preMutationGameDate: {...state.value.preMutationGameDate}})
        }
        catch {
          ok = false
        }
        if (ok) {
          commit()
        }
        else {
          cancelResize()
        }
      }
      else {
        commit()
      }
    }
    finally {
      assertTruthy(!state.value, "state cleared out on all paths");
    }

    function commit() {
      assertNonNull(state.value)
      clearResizeHandlers()
      document.body.style.cursor = state.value.savedGlobalCursorStyle
      state.value.node.data.uiState.isBeingVerticallyResized = false
      state.value = null
    }
  }

  const onMouseMove = (evt: MouseEvent) => {
    assertNonNull(state.value)

    evt.preventDefault()
    evt.stopPropagation()

    const minStart = state.value.preMutationGameDate.start.hour(state.value.viewport.startHour24Inc)
    // yes, use "start" to get "same date" even in cases of bleed into next day like onto "day+1 @ 12am"
    const maxEnd = state.value.preMutationGameDate.start.hour(state.value.viewport.endHour24Inc + 1)

    const snappedPreMutationGameDate = {
      start: state.value.preMutationGameDate.start.isBefore(minStart) ? minStart : state.value.preMutationGameDate.start,
      end: state.value.preMutationGameDate.end.isAfter(maxEnd) ? maxEnd : state.value.preMutationGameDate.end,
    }
    const adjusted = state.value.convertDeltaPxToNewTime(snappedPreMutationGameDate, evt.pageY - state.value.startPageY, state.value.which)

    if (state.value.which === "start") {
      state.value.node.data.uiState.time = {
        start: adjusted.start,
        end: state.value.node.data.uiState.time.end,
        isEffectivelyAllDay: isEffectivelyAllDay(adjusted.start.unix(), state.value.node.data.uiState.time.end.unix())
      }
    }
    else {
      state.value.node.data.uiState.time = {
        start: state.value.node.data.uiState.time.start,
        end: adjusted.end,
        isEffectivelyAllDay: isEffectivelyAllDay(state.value.node.data.uiState.time.start.unix(), adjusted.end.unix())
      }
    }
  }

  return {
    /**
     * Callback for _after_ resize is committed.
     * Provides an opportunity for us to resort whichever list owns the resized game.
     * If the callback returns false, the resize is canceled (the resized object returns to its prior size)
     */
    onResizeCommitted: (f: (_: {layoutNode: LayoutNode<T>, date: string, field: string, preMutationGameDate: {start: Dayjs, end: Dayjs}}) => boolean | Promise<boolean>) => {
      onResizeCommitted = f
    },
    startResizingGameVertically(args: {
      startPageY: number,
      node: LayoutNode<T>,
      viewport: {
        startHour24Inc: number,
        endHour24Inc: number,
      }
      deltaPx2NewTime: (originalTime: {start: Dayjs, end: Dayjs}, px: number, which: "start" | "end") => {start: Dayjs, end: Dayjs},
      which: "start" | "end",
      date: string,
      field: string
    }) {
      if (state.value) {
        // probably waiting on some other resize to complete asynchronously
        return;
      }

      state.value = {
        savedGlobalCursorStyle: document.body.style.cursor,
        node: args.node as VueGenericRefKludge<UnwrapRef<LayoutNode<T>>>,
        convertDeltaPxToNewTime: args.deltaPx2NewTime,
        startPageY: args.startPageY,
        preMutationGameDate: {
          start: args.node.data.uiState.time.start,
          end: args.node.data.uiState.time.end
        },
        viewport: args.viewport,
        which: args.which,
        date: args.date,
        field: args.field,
        isAsyncCommitting: false,
      }

      args.node.data.uiState.isBeingVerticallyResized = true
      document.body.style.cursor = "row-resize"

      installResizeHandlers()
    }
  }
}
type CalendarElementVerticalResizer = ReturnType<typeof CalendarElementVerticalResizer>

function CalendarElementMover<T extends MinNode>() {
  interface State {
    /**
     * The freshNode is the one that gets dragged.
     * It starts life as a copy of the sourceNode
     */
    readonly freshNode: LayoutNode<T>,
    /**
     * The sourceNode remains in place during a drag operation,
     * to show "this is where the drag originated".
     */
    readonly sourceNode: LayoutNode<T>,
    readonly grabOffsetX: number,
    readonly grabOffsetY: number,
    /**
     * This updates as drags move left/right across dates/fields
     */
    currentDate: string,
    /**
     * This updates as drags move left/right across dates/fields
     */
    currentFieldUID: string,
    isAsyncCommitting: boolean,
    /**
     * The date of the drag started from (stored denormalized, should match what is in `sourceNode`)
     */
    readonly initialDate: string,
    /**
     * The field the drag started from (stored denormalized, should match what is in `sourceNode`)
     */
    readonly initialFieldUID: string,
  }

  const state = ref<null | State>(null)

  const clearMoveHandlers = () => {
    window.removeEventListener("keyup", onEsc, {capture: true})
  }

  const installMoveHandlers = () => {
    window.addEventListener("keyup", onEsc, {capture: true})
  }

  const onEsc = (evt: KeyboardEvent) => {
    if (evt.key === "Escape") {
      evt.preventDefault()
      evt.stopPropagation()
      clearMoveHandlers()
      tryReset()
    }
  }

  const reset = () => {
    assertNonNull(state.value)
    clearMoveHandlers()
    state.value.freshNode.data.uiState.dragState = null
    state.value.sourceNode.data.uiState.dragState = null
    state.value = null
  }

  const tryReset = () => {
    if (!state.value || state.value.isAsyncCommitting) {
      return
    }
    reset()
  }

  return {
    get isMoving() {
      return state.value !== null;
    },
    // T is covariant in its usage here because a caller is passing a T to us
    startMovingGame: <TCovariantHere extends T>(date: string, fieldUID: Guid, sourceNode: LayoutNode<TCovariantHere>, grabOffsetX: number, grabOffsetY: number) => {
      if (state.value) {
        // Don't allow to start drags if another drag is not complete
        // This is intended to help some async logic where a drop might not complete unless some HTTP requests complete.
        return;
      }

      const freshNode : LayoutNode<T> = {
        ...sourceNode,
        children: [],
        data: {
          ...sourceNode.data,
          uiState: {
            ...sourceNode.data.uiState,
            dragState: "drag-handle"
          }
        }
      };

      state.value = {
        freshNode: freshNode as VueGenericRefKludge<UnwrapRef<LayoutNode<T>>>,
        sourceNode: sourceNode as VueGenericRefKludge<UnwrapRef<LayoutNode<T>>>,
        grabOffsetX: grabOffsetX,
        grabOffsetY: grabOffsetY,
        currentDate: date,
        currentFieldUID: fieldUID,
        initialDate: date,
        initialFieldUID: fieldUID,
        isAsyncCommitting: false,
      }

      sourceNode.data.uiState.dragState = "stationary-drag-source"

      // TODO: this doesn't actually work.
      // We'd like to say "set the cursor to the 'move' cursor, and disregard any other element's cursor style"
      document.body.style.cursor = "move !important";

      installMoveHandlers()
    },
    updateDateFieldOwner(args: {date: string, field: string}) : void {
      assertNonNull(state.value)
      state.value.currentDate = args.date
      state.value.currentFieldUID = args.field
    },
    maybeGetSourceNode: () : LayoutNode<T> | null => {
      return state.value?.sourceNode as VueGenericRefKludge<LayoutNode<T>> || null
    },
    maybeGetMovee: (args: {date: string, fieldUID: string}) : LayoutNode<T> | null => {
      if (state.value?.currentDate === args.date && state.value.currentFieldUID === args.fieldUID) {
        return state.value.freshNode as VueGenericRefKludge<LayoutNode<T>>
      }
      return null;
    },
    get grabOffsetY() {
      return requireNonNull(state.value).grabOffsetY
    },
    get currentDate() {
      return requireNonNull(state.value).currentDate
    },
    get currentFieldUID() {
      return requireNonNull(state.value).currentFieldUID
    },
    get initialDate() {
      return requireNonNull(state.value).initialDate
    },
    get initialFieldUID() {
      return requireNonNull(state.value).initialFieldUID
    },
    tryReset,
    withIsAsyncCompleting: async <T,>(f: () => Promise<T>) : Promise<T> => {
      assertNonNull(state.value)
      try {
        state.value.isAsyncCommitting = true
        return await f()
      }
      finally {
        state.value.isAsyncCommitting = false
      }
    }
  }
}

// n.b. try to keep T covariant, minimizes need for casts when passing as a prop
type CalendarElementMover<out T extends MinNode> = ReturnType<typeof CalendarElementMover<T>>

/**
 * for some tree, we want the ability to ask "how wide is the subtree I am a member of",
 * where subtree means "one of the subtrees directly a child of a LayoutNodeRoot<>"
 */
export function treeMaxWidth(tree: LayoutNode<any> | LayoutNodeRoot<any>) : number {
  const isRoot = tree.parent === null
  if (tree.children.length === 0) {
    return 1
  }
  else {
    const maxs = tree.children.map(child => {
      return treeMaxWidth(child) + (isRoot ? 0 : 1)
    })
    return max(maxs)
  }
}
