import { defineComponent, getCurrentInstance, nextTick, ref, watch } from "vue";
import * as ilapi from 'src/composables/InleagueApiV1'
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'
import { useIziToast } from "src/helpers/utils";
import { IziToastSettings } from "izitoast";
import { AxiosInstance } from "axios";
import { User } from "src/store/User";
import { freshNoToastLoggedInAxiosInstance } from "src/boot/axios";

export const Notifications = defineComponent({
  name: "Notifications",
  setup() {
    const localInstance = getCurrentInstance()
    const iziToast = useIziToast()
    const elemID = `il-notifications-root`

    const notificationsResolver = (() => {
      const resolver = ReactiveReifiedPromise<ilapi.Notification[]>()

      const doGetNotifications = (ax: AxiosInstance) : void => {
        resolver.run(() => ilapi.getNotifications(ax))
      }

      return {
        get value() { return resolver.underlying },
        doGetNotifications,
        reset: () => resolver.reset()
      }
    })()

    /**
     * Controls the remounting the local iziToast root.
     * We write into this root programatically via the iziToast api.
     * If we want to destroy/recreate the container (and so destroy but _not_ recreate the child nodes (the iziToast elems)), we can increment this key.
     */
    const iziToastDomElementContainerRenderKey = (() => {
      const k = ref(0)
      return {
        get value() { return k.value },
        increment: () => { k.value++ }
      }
    })();

    // TODO: linkify via <a> or <RouterLink> or something, rather than procedurally invoke an outbound link
    const toLink = (url: string) => {
      localInstance?.appContext.config.globalProperties.$openLegacyLink(url)
    }

    /**
     * If there are notifications available, this will mount them as iziToast notifications.
     * Note: This makes direct DOM mutations via iziToast lib, rather than using the reactivity system.
     */
    const destroyAndMaybeRemountNotifications = async () : Promise<void> => {
      iziToastDomElementContainerRenderKey.increment()
      await nextTick() // wait next tick for render key update to do its DOM update thing

      if (notificationsResolver.value.status !== "resolved") {
        return
      }

      const notifications = notificationsResolver.value.data
      const CONTAINER_SELECTOR = "#" + elemID;

      if (!document.querySelector(CONTAINER_SELECTOR)) {
        //
        // iziToast makes direct writes into DOM, meaning it's not aware of component lifecycle.
        // If the component was unmounted, #notifications won't exist, and asking iziToast to do
        // work against the element will fail.
        //
        // Here, the component has unmounted (generally, during the await for the notifications that we intend to mount),
        // so there's nothing to do.
        //
        return;
      }

      for (const notification of notifications) {
        const options : IziToastSettings = {
          message: notification.text,
          close: notification.dismissible,
          icon: `fas fa-${notification.fontAwesome}`,
          imageWidth: 75,
          layout: 1,
          target: CONTAINER_SELECTOR,
          timeout: false,
          buttons: [
            [
              `<button> ${notification.buttonText}</button>`,
              () => {
                toLink(notification.buttonURL)
              },
              false,
            ],
          ],
        }

        if (notification.dismissible) {
          iziToast.warning(options)
        }
        else {
          iziToast.error(options)
        }
      }
    }

    watch([() => User.isLoggedIn, () => User.isImpersonating], async () => {
      if (User.isLoggedIn) {
        notificationsResolver.doGetNotifications(freshNoToastLoggedInAxiosInstance())
      }
      else {
        notificationsResolver.reset()
      }
    }, {
      immediate: true
    })

    watch(() => notificationsResolver.value.status, () => {
      destroyAndMaybeRemountNotifications()
    }, {
      immediate: true,
    })

    return () => {
      return <div
        id={elemID}
        key={iziToastDomElementContainerRenderKey.value}
        // force all descendant svgs to be "opacity: 1 !important", because
        // iziToast seems to be default configured to have a zero-opacity, meaning without this
        // the font awesome icon we expect to load would be invisible
        class="[&_svg]:!opacity-100"
      />
    }
  }
})
