import { PlayerForTeamAssignmentView, TeamForTeamAssignmentsView } from "src/composables/InleagueApiV1.Teams";
import { Optional, CheckedOmit, SetEx, TailwindBreakpoint, nextGlobalIntlike, useWindowSize } from "src/helpers/utils";
import { Competition, DateTimelike, Guid, RegistrationQuestion, TeamID, WithDefinite } from "src/interfaces/InleagueApiV1";
import { computed, reactive, ref } from "vue";
import { isObject } from "src/helpers/utils";
import { AxiosInstance, AxiosRequestConfig } from "axios";
import { GetRegistrationQuestionAnswersResponse } from "src/composables/InleagueApiV1.Registration";
import { ExplodedPromise } from "src/helpers/ExplodedPromise";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { getCompetitionOrFail } from "src/store/Competitions";
import { Client } from "src/store/Client";

export const k_selectedUnassignedLoans = Symbol("k_selectedUnassignedLoans");
export const k_selectedUnassignedAssignments = Symbol("k_selectedUnassignedAssignments");

/**
 * `assignments` and `loans` are very similar, but also different enough that they are stored separately.
 * Conceptually, both an "assignment" and a "loan" share a common supertype, of something like "Player assigned to a team in some way",
 * but they have differing constraints with respect to any given season. An "assignment" for a player exists for exactly 1 team for a single season,
 * whereas a loan for a player can exist for many teams for a single season. The two shouldn't ever overlap though -- that is, a a player cannot
 * have loans for a (season, team) for which they also have assignments, and vice versa.
 *
 * Each also has a similar-but-different storage approach here, where each has a different type of "source" pool due to their (team,season) cardinality.
 * "assignments" have an `unassignedPool` and are present either in the `unassignedPool` or in the `byTeam` mapping for their current (or tentative)
 * assignment. Whereas loans have a "searchResults" source representing the most recent search for loanable players, and can be present in the search results
 * as well as their current (or tentative) `byTeam` mapping (or many `byTeam` mappings at once).
 *
 * One big difference between the two is when deleting an assignment or loan -- a loan that was fully assigned as of page load will remain in place in its "byTeam"
 * mapping, whereas an assignment will be physically moved from its original team into the unassigned pool (rendering it available for reassignment).
 * Both would be marked "tentatively-unassigned" in this case.
 */
export interface ResolvedSelection {
  teams: TeamForTeamAssignmentsView[],
  assignments: {
    unassignedPool: PlayerForTeamAssignmentViewEx[],
    /**
     * Will always be players of type "assignment" (i.e., never loans here).
     * We could probably encode this in types, but some runtime asserts are enough for now.
     */
    byTeam: {[teamID: Guid]: PlayerForTeamAssignmentViewEx[]},
  },
  loans: {
    searchResults: PlayerLoanLookup,
    /**
     * Any assignments here are always of type "loan".
     */
    byTeam: {[teamID: Guid]: PlayerForTeamAssignmentViewEx[]}
  }
  registrationQuestions: RegistrationQuestion[],
  selectedQuestions: SelectedQuestions,
  /**
   * The contract is that all teamIDs present in `teams` are present here;
   * as well as a mappings for one each of the "unassigned" assignments and "unassigned" loans.
   */
  selectedPlayerIDsByTeamIDish: Map<TeamID | (typeof k_selectedUnassignedAssignments) | (typeof k_selectedUnassignedLoans), SetEx<Guid>>,
  /**
   * There was "single-assign" at one point, too, but no longer
   */
  mode: {type: "multi-assign"}
  registrationQuestionAnswers: GetRegistrationQuestionAnswersResponse,
  /**
   * Data related to being hydrated from a snapshot
   */
  snapshotConfig:
    | null
    | {
      // Any errors encountered while trying to load a snapshot (missing players or etc.)
      // This is informational for UI purposes
      warnings: string[],
      // the value of "assignments" prior to initializing a snapshot config
      savedAssignments: ResolvedSelection["assignments"],
      // the value of "loans" prior to initializing a snapshot config
      savedLoans: ResolvedSelection["loans"],
    },
  // render key, one major use case is to not run animations "on first render"
  // of elements against a "new" resolvedSelection
  version: number,
}

async function findPlayerForTeamAssignmentsViewPlayerLoanCandidate(
  ax: AxiosInstance,
  args: {
    seasonUID: Guid,
    competitionUID: Guid,
    divID: Guid,
    searchText: string
  },
  axArgs?: CheckedOmit<AxiosRequestConfig, "params">
) : Promise<PlayerForTeamAssignmentView[]> {
  const {seasonUID, competitionUID, divID, searchText} = args;
  const response = await ax.get(`v1/teams/loans/candidates`, {params: {
    seasonUID, competitionUID, divID, searchText
  }, ...axArgs})
  return response.data.data;
}

export function PlayerLoanLookup(ax: AxiosInstance) {
  const DEBOUNCE_MS = 1000;
  const timeoutHandle = ref<number | null>(null);

  let currentAbortController : AbortController | null = null;
  const getter = ReactiveReifiedPromise<PlayerForTeamAssignmentViewEx[]>()
  const alwaysUnresolved : typeof getter = ReactiveReifiedPromise(() => new ExplodedPromise<any>().promise)

  const debouncedDoRunSearch = async (args: {seasonUID: Guid, competitionUID: Guid, divID: Guid, searchText: Guid}, onComplete?: () => void, debounceMs = DEBOUNCE_MS) : Promise<void> => {
    clearTimeout(timeoutHandle.value ?? 0);

    if (args.searchText.trim() === "") {
      getter.reset();

      timeoutHandle.value = null;
      currentAbortController?.abort()
      currentAbortController = null

      return;
    }

    timeoutHandle.value = window.setTimeout(async () => {
      const freshAbortController = new AbortController()
      const signal = freshAbortController.signal

      getter.run(async () => {
        const vs = await findPlayerForTeamAssignmentsViewPlayerLoanCandidate(ax, args, {signal})
        const competition = await getCompetitionOrFail(args.competitionUID)
        onComplete?.();
        return vs.map(v => ({
          id: nextGlobalIntlike(),
          type: "unassigned",
          apiData: v,
          clientData: {
            type: "loan",
            disableAssignOrLoanDueToMissingBirthCert: disableAssignOrLoanDueToMissingBirthCert(competition, v),
            expirationDate: Optional.none(),
          }}
        ))
      })

      timeoutHandle.value = null;
      currentAbortController?.abort()
      currentAbortController = freshAbortController
    }, debounceMs);
  }

  const coalescedGetter = computed(() => {
    return timeoutHandle.value !== null
      ? alwaysUnresolved
      : getter
  })

  return {
    debouncedDoRunSearch,
    get currentRequest() {
      return coalescedGetter.value
    },
  }
}

export type PlayerLoanLookup = ReturnType<typeof PlayerLoanLookup>

export function getTeamName(team: TeamForTeamAssignmentsView) : string {
  const seasonalTeamName = team.teamSeason?.teamName || "";
  const simpleTeamName = team.team.team || "";
  if (!seasonalTeamName && !simpleTeamName) {
    return "" // shouldn't happen
  }
  else if (!seasonalTeamName && simpleTeamName) {
    return simpleTeamName
  }
  else if (seasonalTeamName && !simpleTeamName) {
    return seasonalTeamName
  }
  else if (seasonalTeamName && simpleTeamName) {
    return `${seasonalTeamName} (${simpleTeamName})`
  }
  else {
    throw Error("unreachable");
  }
}

export const unassignedPlayerDragMimeType = "application/il-unassigned-player"
export const assignedPlayerDragMimeType = "application/il-assigned-player"
export const k_activeDropTargetOutlineClasses = `outline outline-blue-600 outline-2`
export const k_allTeamsAvgTeamID = "ALL"

export const globalTeamAssignmentsDragData = reactive(
  new class GlobalDragData {
    unassignedPlayersBeingDragged : PlayerForTeamAssignmentViewEx[] | null = null
    assignedPlayersBeingDragged : {originTeam: TeamForTeamAssignmentsView, players: PlayerForTeamAssignmentViewEx[]} | null = null;
    loansBeingDragged : {
      originTeam: TeamForTeamAssignmentsView | null,
      players: PlayerForTeamAssignmentViewEx[]
    } | null = null;
    teamBeingDragged : {
      teamID: Guid,
      initialSort: {[teamID: Guid]: number | undefined}
      lastSwappedWithTeamID: Guid | null,
    } | null = null

    /**
     * Should be true if hovering over a team that already has some of the dragged players assigned.
     */
    showDuplicateWarning = false

    clear() {
      this.unassignedPlayersBeingDragged = null
      this.assignedPlayersBeingDragged = null
      this.loansBeingDragged = null
      this.teamBeingDragged = null
      this.showDuplicateWarning = true
    }
  }
)

export interface TeamAverageRatings {
  lifetimeByTeamID: {
    getRaw: (teamID: TeamID | typeof k_allTeamsAvgTeamID) => number | null,
    getDisplayValue: (teamID: TeamID | typeof k_allTeamsAvgTeamID) => string
  },
  mostRecentSeasonByTeamID: TeamAverageRatings["lifetimeByTeamID"],
}

export const wellKnownCols = {
  status: "status",
  playerName: "playerName",
  ratingAvg: "ratingAvg",
  newestRating: "newestRating",
  dob: "dob",
  regDate: "regDate",
  height: "height",
  weight: "weight",
  grade: "grade",
  school: "school",
  waitlisted: "waitlisted",
  city: "city",
  zip: "zip",
  street: "street",
  memberOfCoachesFamily: "memberOfCoachesFamily",
  noPracticeDay: "noPracticeDay",
  comments: "comments",
} as const;

export interface PlayerForTeamAssignmentViewExFilter {
  city: string,
  dob: string,
  grades: string[],
  playerName: string,
  school: string,
  zip: string[],
  street: string,
}

export interface SelectedQuestions {
  wellKnown: {
    playerName: boolean,
    ratingAvg: boolean,
    newestRating: boolean,
    dob: boolean,
    regDate: boolean,
    height: boolean,
    weight: boolean,
    grade: boolean,
    school: boolean,
    waitlisted: boolean,
    city: boolean,
    zip: boolean,
    street: boolean,
    memberOfCoachesFamily: boolean,
    noPracticeDay: boolean,
    comments: boolean,
  },
  customQuestionIDs: Guid[],
}

function selectedQuestions_localStorageKey(userID: Guid) {
  return `selectedQuestions/${userID}`
}

function selectedQuestions_default() : SelectedQuestions {
  return {
    wellKnown: {
      playerName: true,
      ratingAvg: true,
      newestRating: true,
      dob: true,
      regDate: true,
      height: true,
      weight: true,
      grade: true,
      school: true,
      waitlisted: true,
      city: true,
      zip: true,
      street: true,
      memberOfCoachesFamily: true,
      noPracticeDay: true,
      comments: true,
    },
    customQuestionIDs: []
  }
}

export function selectedQuestions_persist(userID: Guid, obj: SelectedQuestions) : void {
  localStorage.setItem(selectedQuestions_localStorageKey(userID), JSON.stringify(obj));
}

export function selectedQuestions_load(userID: Guid, defaultAutoSelectedCustomQuestionIDs: Guid[]) : SelectedQuestions {
  try {
    const s = localStorage.getItem(selectedQuestions_localStorageKey(userID));
    if (!s) {
      return selectedQuestions_default();
    }
    const obj = JSON.parse(s);

    if (!isObject(obj)) {
      return selectedQuestions_default()
    }

    const wellKnown : Partial<SelectedQuestions["wellKnown"]> = isObject(obj.wellKnown) ? obj.wellKnown : {}
    const customQuestionIDs = [...new Set([
      ...(Array.isArray(obj.customQuestionIDs) ? obj.customQuestionIDs : []),
      ...defaultAutoSelectedCustomQuestionIDs
    ])]

    return {
      wellKnown: {
        playerName: !!wellKnown.playerName,
        ratingAvg: !!wellKnown.ratingAvg,
        newestRating: !!wellKnown.newestRating,
        dob: !!wellKnown.dob,
        regDate: !!wellKnown.regDate,
        height: !!wellKnown.height,
        weight: !!wellKnown.weight,
        grade: !!wellKnown.grade,
        school: !!wellKnown.school,
        waitlisted: !!wellKnown.waitlisted,
        city: !!wellKnown.city,
        zip: !!wellKnown.zip,
        street: !!wellKnown.street,
        memberOfCoachesFamily: !!wellKnown.memberOfCoachesFamily,
        noPracticeDay: !!Client.value.instanceConfig.regnopractice && !!wellKnown.noPracticeDay,
        comments: !!wellKnown.comments,
      },
      customQuestionIDs,
    }
  }
  catch {
    return selectedQuestions_default();
  }
}

interface PlayerForTeamAssignmentViewEx_Base {
  /**
   * 'id' is a ui thing only -- for virtual scroll
   */
  id: string,
  type:
    | "unassigned"
    | "assigned"
    | "tentatively-assigned"
    | "assigned-but-tentatively-moved"
    | "assigned-but-tentatively-unassigned"
  apiData: PlayerForTeamAssignmentView
  /**
   * A `PlayerForTeamAssignmentView` represents a "potentially {assigned,loaned} player".
   * uiState.type should be in sync with apiData.assignment, which is either a loan or an assignment.
   * In the "unassigned" case (where an object in the UI is not yet assigned or loaned), the clientData.type value
   * can serve to distinguish between the two cases.
   */
  clientData:
    & {
      disableAssignOrLoanDueToMissingBirthCert: boolean,
    }
    & (
      | {
        type: "assignment"
      }
      | {
        type: "loan",
        /**
         * If present, should be formatted for HTML date forms.
         * We use the optionality to determine the difference between:
         *  - user says "no expiration date"
         *  - user says "has an expiration date and here is the value"
         */
        expirationDate: Optional<DateTimelike>,
      }
    )
}

export type PlayerForTeamAssignmentViewEx =
  | PlayerForTeamAssignmentViewEx_Unassigned
  | PlayerForTeamAssignmentViewEx_TentativelyAssigned
  | PlayerForTeamAssignmentViewEx_Assigned
  | PlayerForTeamAssignmentViewEx_AssignedButTentativelyMoved
  | PlayerForTeamAssignmentViewEx_AssignedButTentativelyUnassigned

export interface PlayerForTeamAssignmentViewEx_Unassigned extends PlayerForTeamAssignmentViewEx_Base {
  type: "unassigned",
  apiData: PlayerForTeamAssignmentView,
}

export interface PlayerForTeamAssignmentViewEx_Assigned extends PlayerForTeamAssignmentViewEx_Base {
  type: "assigned",
  apiData: WithDefinite<PlayerForTeamAssignmentView, "assignment">,
}

export interface PlayerForTeamAssignmentViewEx_TentativelyAssigned extends PlayerForTeamAssignmentViewEx_Base {
  type: "tentatively-assigned",
  apiData: PlayerForTeamAssignmentView,
}

export interface PlayerForTeamAssignmentViewEx_AssignedButTentativelyMoved extends PlayerForTeamAssignmentViewEx_Base {
  type: "assigned-but-tentatively-moved",
  apiData: WithDefinite<PlayerForTeamAssignmentView, "assignment">,
}

export interface PlayerForTeamAssignmentViewEx_AssignedButTentativelyUnassigned extends PlayerForTeamAssignmentViewEx_Base {
  type: "assigned-but-tentatively-unassigned",
  apiData: WithDefinite<PlayerForTeamAssignmentView, "assignment">,
}

/**
 * operates in-place
 */
export function unassignedToTentativelyAssigned(
  v: PlayerForTeamAssignmentViewEx_Unassigned
) : void {
  (v as PlayerForTeamAssignmentViewEx_Base).type = "tentatively-assigned"
}

/**
 * operates in-place
 */
export function tentativelyAssignedToUnassigned(
  v: PlayerForTeamAssignmentViewEx_TentativelyAssigned
) : void {
  (v as PlayerForTeamAssignmentViewEx_Base).type = "unassigned"
}

/**
 * operates in-place
 */
export function assignedToTentativelyMoved(v:
  | PlayerForTeamAssignmentViewEx_Assigned
  | PlayerForTeamAssignmentViewEx_AssignedButTentativelyMoved
  | PlayerForTeamAssignmentViewEx_AssignedButTentativelyUnassigned
) : void {
  (v as PlayerForTeamAssignmentViewEx_Base).type = "assigned-but-tentatively-moved"
}

/**
 * operates in-place
 */
export function assignedToTentativelyUnassigned(v:
  | PlayerForTeamAssignmentViewEx_Assigned
  | PlayerForTeamAssignmentViewEx_AssignedButTentativelyMoved
  | PlayerForTeamAssignmentViewEx_AssignedButTentativelyUnassigned
) : void {
  (v as PlayerForTeamAssignmentViewEx_Base).type = "assigned-but-tentatively-unassigned"
}

/**
 * operates in-place
 */
export function tentativelyMovedToAssigned(v:
  | PlayerForTeamAssignmentViewEx_Assigned
  | PlayerForTeamAssignmentViewEx_AssignedButTentativelyMoved
  | PlayerForTeamAssignmentViewEx_AssignedButTentativelyUnassigned
) : void {
  (v as PlayerForTeamAssignmentViewEx_Base).type = "assigned"
}

export function isTentativeAssignment(player: PlayerForTeamAssignmentViewEx) : boolean {
  return player.type === "assigned-but-tentatively-moved"
    || player.type === "assigned-but-tentatively-unassigned"
    || player.type === "tentatively-assigned";
}

export function useShouldOfferTeamAssignmentDragAndDrop() {
  const windowSize = useWindowSize()
  return computed(() => process.env.MODE === "spa" && windowSize.width >= TailwindBreakpoint.sm);
}

export function disableAssignOrLoanDueToMissingBirthCert({requireBirthCertificate}: Pick<Competition, "requireBirthCertificate">, player: PlayerForTeamAssignmentView) {
  if (requireBirthCertificate && !player.child.birthCertificate) {
    return true;
  }
  return false
}
