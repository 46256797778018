import { arrayFindOrFail, assertIs, assertTruthy, exhaustiveCaseGuard, FK_validation_strlen, forceCheckedIndexedAccess, nextGlobalIntlike, noAvailableOptions, parseIntOr, parseIntOrFail, requireNonNull, sortBy, UiOption, UiOptions, unreachable, useAutoFocusOnMount, vReqT, zipExact } from "src/helpers/utils"
import { Datelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1"
import { computed, defineComponent, onMounted, reactive, ref, Ref, shallowRef } from "vue"
import { Bracket, BracketPoolOption, BracketRound, BracketRoundSlot, BracketRoundSlotTeamOption, BracketRoundSlotTeamSource, GameForBracketBuilderView, getPoolOptionsForBracket } from "./Bracket.io"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faPencil } from "@fortawesome/free-solid-svg-icons"
import { FormKit } from "@formkit/vue"
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2"
import { ReactiveReifiedPromise, ReifiedPromise } from "src/helpers/ReifiedPromise"
import { coachBlurbForTeamName, CoachTriple, teamDesignationAndMaybeName } from "../calendar/GameScheduler.shared"
import { Client } from "src/store/Client"
import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances"
import dayjs from "dayjs"
import { DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate"
import { uid } from "quasar"
import { GameForGameSchedulerView, GameForGameSchedulerView_Team } from "src/composables/InleagueApiV1.GameScheduler"
import { ilDraggable, ilDropTarget, vueDirective_ilDraggable, vueDirective_ilDropTarget } from "src/modules/ilDraggable"
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons"
import { FormKitNode, FormKitAddress } from "@formkit/core"

/**
 * This draws the edges between the "nodes"(bracketRoundSlots) in our "tree"(bracket).
 * It does not perform anything like a smart graph placement, it simply assumes
 *  - bracket.rounds[0] is the first round, i.e. the leaf-most level of the tree
 *  - nodes in successive rounds are "parents of" (in bracket terms, subsequent-games-of) the immediately prior round
 *
 * Performance pitfalls:
 *  - use of getBoundingClientRect forces a style recalc each call; this is slow.
 *    Possible workaround: Consider using an IntersectionObserver to track element sizes?
 * - FontAwesome installs a MutationObserver on doc root for all SVG elements, which causes inserting/mutating SVG elements
 *   on the page to be extremely slow.
 *   see: https://github.com/FortAwesome/Font-Awesome/issues/14368
 *   This looks like it can be disabled via `noAuto` (i.e. `import { noAuto } from "@fortawesome/fontawesome-svg-core";`
 *   But there doesn't seem to an api to turn it back on? Do we need it? Can we disable it globally for the app and be OK?
 */
export const EdgeDrawer = defineComponent({
  props: {
    bracketContainerRef: vReqT<Ref<HTMLElement | null>>(),
    roundSlotElemRefTracker: vReqT<RoundSlotElemRefTracker>(),
    bracket: vReqT<Bracket>(),
    dir: vReqT<"lr" | "rl">(),
  },
  emits: {
    edgeLayoutInfo: (_: Map<BracketRoundSlotID, EdgeLayoutInfo>) => true,
  },
  setup(props, ctx) {
    return () => {
      // hm ... side effects of rendering ...
      // well, as long as we don't write to anything reactive, this should be OK.
      // Ideally, we would lift the "compute edge info" and "render edges" into separate steps.
      // We have some parents who would like to know this info; meaning, they should probably compute it,
      // and then pass it to us and we would use it to draw with; rather than we compute it to draw the edges,
      // and then pass it up when we've figured it out.
      const m = new Map<BracketRoundSlotID, EdgeLayoutInfo>()

      // emit next tick, after `m` has been filled in
      setTimeout(() => ctx.emit("edgeLayoutInfo", m), 0);

      return (() => {
        const result : JSX.Element[] = []
        for (let i = 1; i < props.bracket.bracketRounds.length; i++) {
          const round = props.bracket.bracketRounds[i]

          for (const slot of round.bracketRoundSlots) {
            // Does it make sense to draw edges for a "team" slot? Well, "team" slots will
            // always be round 1? So wouldn't have incoming edges? Anyway, for now, we draw them.
            const shouldDrawEdges = slot.type === "game" || slot.type === "team"

            if (!shouldDrawEdges) {
              continue;
            }

            const edgeLayoutInfo : EdgeLayoutInfo = {centerOfEdgeTo: new Map()}
            m.set(parseIntOrFail(slot.bracketRoundSlotID), edgeLayoutInfo)

            const self = props.roundSlotElemRefTracker.getOrFail(slot)
            const homeRef = slot.sourceLeft
              ? props.roundSlotElemRefTracker.maybeGet({bracketRoundSlotID: slot.sourceLeft.source_bracketRoundSlotID})
              : undefined
            const visitorRef = slot.sourceRight
              ? props.roundSlotElemRefTracker.maybeGet({bracketRoundSlotID: slot.sourceRight.source_bracketRoundSlotID})
              : undefined

            result.push(
              <OneEdgeSet
                bracketContainerRef={props.bracketContainerRef}
                homeSource={homeRef ? {bracketRoundSlotID: parseIntOrFail(slot.sourceLeft?.source_bracketRoundSlotID), elemRef: homeRef} : undefined}
                visitorSource={visitorRef ? {bracketRoundSlotID: parseIntOrFail(slot.sourceRight?.source_bracketRoundSlotID), elemRef: visitorRef} : undefined}
                sink={self}
                out_edgeLayoutInfo={edgeLayoutInfo}
                dir={props.dir}
              />
            )
          }
        }

        return result
      })()
    }
  }
})

const OneEdgeSet = defineComponent({
  props: {
    bracketContainerRef: vReqT<Ref<HTMLElement | null>>(),
    homeSource: vReqT<undefined | {bracketRoundSlotID: number, elemRef: Ref<HTMLElement | null>}>(),
    visitorSource: vReqT<undefined | {bracketRoundSlotID: number, elemRef: Ref<HTMLElement | null>}>(),
    sink: vReqT<Ref<HTMLElement | null>>(),
    out_edgeLayoutInfo: vReqT<EdgeLayoutInfo>(),
    dir: vReqT<"lr" | "rl">(),
  },
  setup(props) {
    const stroke = "black"
    const strokeWidth = "1"

    // need svg lines to be "in between" pixels for anti-aliasing reasons;
    // a line of width 1 placed "exactly on" a pixel looks blurry.
    // Hm, this seems to depend on stroke width, too. For a 1px stroke, we want it to fall on half-pixels.
    // For a 2px stroke, falling on whole pixels is fine.
    const pixelAdjust = (px: number) => {
      const int_strokeWidth = parseIntOrFail(strokeWidth)
      if (int_strokeWidth % 2 === 0) {
        return Math.round(px)
      }
      else {
        return Math.round(px) + .5
      }
    }

    return () => {
      const bracketContainer = props.bracketContainerRef.value
      const homeRef = props.homeSource?.elemRef.value
      const visitorRef = props.visitorSource?.elemRef.value
      const sink = props.sink?.value

      if (!bracketContainer || !homeRef || !visitorRef || !sink) {
        return null
      }

      const {above, below} = (() => {
        const m1Rect = relativeRect(homeRef, bracketContainer)
        const m2Rect = relativeRect(visitorRef, bracketContainer)
        return {
          above: m1Rect.y < m2Rect.y ? {rect: m1Rect, source: "home" as const} : {rect: m2Rect, source: "visitor" as const},
          below: m1Rect.y < m2Rect.y ? {rect: m2Rect, source: "visitor" as const} : {rect: m1Rect, source: "home" as const},
        }
      })();
      const sinkCoords = relativeRect(sink, bracketContainer)

      const radius = 10
      const r = radius

      const aMidY = pixelAdjust(above.rect.y + (above.rect.height / 2))
      const bMidY = pixelAdjust(below.rect.y + (below.rect.height / 2))
      const midMidY = pixelAdjust(sinkCoords.y + (sinkCoords.height / 2))

      let hx1 : number // xpos start of horizontal line out of {source1, source2}
      let hx2 : number // xpos end of horizontal line out of {source1, source2}, adjusted to not include radius of arc at end
      let hx3 : number // xpos end of horizontal line from end of line out of {source1,source2} to sink

      if (props.dir === "lr") {
        hx1 = pixelAdjust(above.rect.x + above.rect.width + 3)
        hx2 = hx1 + 20 - radius
        hx3 = sinkCoords.x - 3
      }
      else {
        hx1 = pixelAdjust(above.rect.x - 3)
        hx2 = hx1 - 20 - radius
        hx3 = (sinkCoords.x + sinkCoords.width) + 3
      }

      {
        const sinkRect = relativeRect(sink, bracketContainer)
        const aTextPos = sinkRect.top
        const bTextPos = sinkRect.bottom
        props.out_edgeLayoutInfo.centerOfEdgeTo.set(props.homeSource.bracketRoundSlotID, {x: hx2 + radius , y: above.source === "home" ? aTextPos : bTextPos})
        props.out_edgeLayoutInfo.centerOfEdgeTo.set(props.visitorSource.bracketRoundSlotID, {x: hx2 + radius, y: above.source === "visitor" ? aTextPos : bTextPos})
      }

      if (props.dir === "lr") {
        return <>
          <path d={`M ${hx1} ${aMidY} H ${hx2} a ${r},${r} 0 0 1, ${r},${r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
          <path d={`M ${hx1} ${bMidY} H ${hx2} a ${r},${r} 0 0 0, ${r},-${r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
          <path d={`M ${hx2 + r} ${aMidY + r} V ${bMidY - r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
          <path d={`M ${hx2 + r} ${midMidY} H ${hx3}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
        </>
      }
      else {
        return <>
          <path d={`M ${hx1} ${aMidY} H ${hx2} a ${r},${r} 0 0 0, ${-r},${r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
          <path d={`M ${hx1} ${bMidY} H ${hx2} a ${r},${r} 0 0 1, ${-r},-${r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
          <path d={`M ${hx2 - r} ${aMidY + r} V ${bMidY - r}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
          <path d={`M ${hx2 - r} ${midMidY} H ${hx3}`} fill="transparent" stroke={stroke} stroke-width={strokeWidth}/>
        </>
      }
    }
  }
})

/**
 * tracks DOM refs for each roundSlot element, used to later determine window positions
 * to draw SVG stuff between the elements.
 */
export type RoundSlotElemRefTracker = ReturnType<typeof RoundSlotElemRefTracker>
export function RoundSlotElemRefTracker() {
  type MinBracketRoundSlot = Pick<BracketRoundSlot, "bracketRoundSlotID">
  const bracketRoundDomRefKey = (slot: MinBracketRoundSlot) => slot.bracketRoundSlotID.toString()

  const domRefs = shallowRef(new Map<string, Ref<HTMLElement | null>>())

  const maybeGet = (slot: MinBracketRoundSlot) : undefined | Ref<HTMLElement | null> => {
    const m = domRefs.value
    const k = bracketRoundDomRefKey(slot)
    return m.get(k)
  }

  const getOrFail = (slot: MinBracketRoundSlot) : Ref<HTMLElement | null> => {
    return requireNonNull(maybeGet(slot))
  }

  const reinitFrom = (bracket: Bracket) : void => {
    const m = new Map<string, Ref<HTMLElement | null>>()
    for (const round of bracket.bracketRounds) {
      for (const slot of round.bracketRoundSlots) {
        const k = bracketRoundDomRefKey(slot)
        assertTruthy(!m.has(k))
        m.set(k, ref(null))
      }
    }
    domRefs.value = m;
  }

  const add = (slot: MinBracketRoundSlot) : void => {
    const k = bracketRoundDomRefKey(slot)
    const m = domRefs.value
    assertTruthy(!m.has(k))
    m.set(k, ref(null))
  }

  return {
    getOrFail,
    maybeGet,
    reinitFrom,
    add
  }
}

function relativeRect(elem: HTMLElement, relativeToThis: HTMLElement) {
  const b1 = elem.getBoundingClientRect()
  const b2 = relativeToThis.getBoundingClientRect()

  const x = b1.x - b2.x
  const y = b1.y - b2.y

  return {
    x,
    y,
    height: b1.height,
    width: b1.width,
    bottom: y + b1.height,
    top: y
  }
}

export type BracketRoundSlotID = Integerlike
export interface EdgeLayoutInfo {
  centerOfEdgeTo: Map<BracketRoundSlotID, {x: number,  y: number}>
}

export function TeamDragEventBus() {
  type Payload = {source: BracketRoundSlot, sourceWhich: "home" | "visitor", team: BracketBuilderFormTeam}
  const dragging = ref<Payload | null>(null)
  return {
    get() : Payload | null { return dragging.value },
    set(v: Payload) { dragging.value = v },
    clear() { dragging.value = null }
  }
}
export type TeamDragEventBus = ReturnType<typeof TeamDragEventBus>

export function FKNodeTracker() {
  const nodes = new Set<FormKitNode>()
  return {
    track: (node: FormKitNode) => {
      nodes.add(node)
    },
    allNodes: () => [...nodes]
  }
}

/**
 * FormKit nodes don't admit to object identity comparisons. They must be compared by their "address".
 */
FKNodeTracker.isSameNode = (l: FormKitNode, r: FormKitNode) => {
  const laddr = l.address
  const raddr = r.address
  if (laddr.length !== raddr.length) {
    return false
  }
  for (let i = 0; i < laddr.length; i++) {
    if (laddr[i].toString() !== raddr[i].toString()) {
      return false
    }
  }
  return true
}

export type FKNodeTracker = ReturnType<typeof FKNodeTracker>

export const EditBracketRoundSlot = defineComponent({
  props: {
    mode: vReqT<"new" | "existing">(),
    bracketRoundNum: vReqT<number>(),
    bracketRoundSlot: vReqT<BracketRoundSlot>(),
    fieldOptions: vReqT<UiOption[]>(),
    bracketRoundSlotUiData: vReqT<BracketRoundSlotUiData>(),
    selectedTeamIDsThisBracket: vReqT<Map<Guid, number>>(),
    teamDragEventBus: vReqT<TeamDragEventBus>(),
    allowDragAndDrop: vReqT<boolean>(),
    fkNodeTracker_teamInputs: vReqT<FKNodeTracker>(),
  },
  directives: {
    ilDraggable: vueDirective_ilDraggable,
    ilDropTarget: vueDirective_ilDropTarget,
  },
  emits: {
    updateGameID: (_gameID: "" | Guid) => true,
    updateFieldUID: (_fieldUID: Guid) => true,
    persistChangesToExisting: () => true,
    discardChangesForExisting: () => true,
    updateType: (_type: BracketRoundSlotUiData["form"]["type"]) => true,
    updateTeamID: (_teamID: Guid) => true, // probably unused, meant "update the whole slot placeholder teamID for type=team slots (which isn't a thing anymore"
    swapTeams: (_: {source: BracketRoundSlot, sourceWhich: "home" | "visitor", target: BracketRoundSlot, targetWhich: "home" | "visitor"}) => true,
    updateTeam: (_: {which: "home" | "visitor", teamID: Guid}) => true,
    showEditTeamModal: () => true,
  },
  setup(props, ctx) {
    const timeStartHourOptions = (() => {
      const result : UiOption[] = []
      for (let i = 7; i <= 23; i++) {
        result.push({label: dayjs().hour(i).format("h a"), value: i.toString()})
      }
      return result;
    })()

    const timeStartMinuteOptions = (() => {
      const result : UiOption[] = []
      for (let i = 0; i < 60; i += 5) {
        result.push({label: i.toString().padStart(2, "0"), value: i.toString()})
      }
      return result;
    })()

    const gameOptions = computed<UiOptions>(() => {
      const p = props.bracketRoundSlotUiData.gameOptionsForSelectedField.value
      if (p.status === "resolved") {
        return p.data.uiOptions
      }
      else {
        // well, error states too
        return noAvailableOptions("Loading games...")
      }
    })

    const teamOptions = computed<UiOptions>(() => {
      const p = props.bracketRoundSlotUiData.teamOptions.underlying
      if (p.status === "resolved") {
        return p.data
      }
      else {
        // well, error states too
        return noAvailableOptions("Loading teams...")
      }
    })

    // dummy sink -- get the value from actual source; writing is a no-op. We need to handle writes manually.
    const fk_selectedGameID_dummySink = {
      get value() {
        const v = props.bracketRoundSlotUiData.form
        return v.type === "game" ? v.gameID : ""
      },
      set value(_) {}
    }

    const fk_selectedFieldUID_dummySink = {
      get value() { return props.bracketRoundSlotUiData.selectedFieldUID },
      set value(_) {}
    }

    const fk_selectedTeamID_dummySink = {
      get value() {
        const v = props.bracketRoundSlotUiData.form
        return v.type === "team" ? v.teamID : ""
      },
      set value(_) {}
    }

    // for our purposes a "new" thing is never considered "dirty"
    const isDirty = computed(() => {
      const v = props.bracketRoundSlotUiData.form
      return props.mode === "existing" && (
        (v.type === "game" && v.gameID !== props.bracketRoundSlot.gameID)
        || (v.type === "team" && v.teamID !== props.bracketRoundSlot.teamID)
        || (v.type !== props.bracketRoundSlot.type)
      );
    })

    // it can happen that we end up on a disabled option, if an option list loads in, say after an error, where the
    // server said "hey, that option became invalid between an earlier options list load and the most recent load"
    // (e.g 2 people creating a bracket and both reference the same game)
    const selectedGameIdIsDisabled = computed(() => {
      return gameOptions.value.options.find(v => v.value === fk_selectedGameID_dummySink.value)?.attrs?.disabled
    })

    const radioID = nextGlobalIntlike()

    const isDuplicateTeamSelection = computed(() => {
      if (props.bracketRoundSlotUiData.form.type !== "team") {
        return false
      }

      const selectedTeamID = props.bracketRoundSlotUiData.form.teamID
      if (!selectedTeamID) {
        return false
      }

      if (selectedTeamID === Client.value.instanceConfig.byeteam) {
        // It's OK to have more than 1 "bye" team
        return false;
      }

      const selectionCount = props.selectedTeamIDsThisBracket.get(selectedTeamID);

      return selectionCount && selectionCount > 1

    })

    const teamBlurb = (source: BracketRoundSlotTeamSource | null, team: BracketBuilderTeamWrapper | null) => {
      if (source === null && team === null) {
        return "TBD"
      }
      else if (source === null && team !== null) {
        if (team.type === "placeholder") {
          return "..."
        }
        else {
          return definiteTeam(team.value)
        }
      }
      else if (source !== null && team === null) {
        const winlose = source.sourceType === "winner" ? "Winner of" : "Loser of"
        return `${winlose} prior game`
      }
      else if (source !== null && team !== null) {
        if (team.type === "placeholder") {
          return "..."
        }
        else {
          return definiteTeam(team.value)
        }
      }
      else {
        unreachable()
      }

      function definiteTeam(team: BracketBuilderFormTeam) {
        return <>
          <div>{teamDesignationAndMaybeName(team)}</div>
          <div>{team.seed === null ? "" : `seed ${team.seed}`}</div>
        </>
      }
    }

    const teamDragAndDrop = reactive({
      home: {
        draggingSource: false,
        hoveringTarget: false,
      },
      visitor: {
        draggingSource: false,
        hoveringTarget: false,
      }
    })

    const teamDragHoverTargetClass = "px-1 outline outline-2 outline-blue-700 bg-gray-400 text-white"
    const teamDragHoverPotentialTargetClass = "px-1 outline outline-2 outline-dashed outline-1 outline-blue-700"
    const teamDragSourceClass = "outline outline-2 outline-blue-700 bg-gray-400 text-white"

    const teamDragConfig = (which: "home" | "visitor") : ilDraggable => {
      const uiData = props.bracketRoundSlotUiData
      return {
        onDragStart: () => {
          if (uiData.form.type !== "game") {
            return false;
          }

          const currentThisTeam = uiData.form[`${which}Team`]

          if (!currentThisTeam) {
            return false;
          }

          if (currentThisTeam.type === "placeholder") {
            return false
          }

          teamDragAndDrop[which].draggingSource = true
          props.teamDragEventBus.set({source: props.bracketRoundSlot, sourceWhich: which, team: currentThisTeam.value})

          return true
        },
        dragHandleJsxFunc: () => {
          const teamName = (() => {
            if (uiData.form.type !== "game") {
              // shouldn't happen
              return "Swap with..."
            }

            const currentThisTeam = uiData.form[`${which}Team`]

            if (!currentThisTeam || currentThisTeam.type !== "team") {
              // shouldn't happen
              return "Swap with...";
            }

            const seed = currentThisTeam.value.seed === null ? "" : ` (seed ${currentThisTeam.value.seed})`

            return teamDesignationAndMaybeName(currentThisTeam.value) + seed
          })();

          return <div class="p-2 bg-blue-700 rounded-md shadow-md text-sm text-white">{teamName}</div>
        },
        onLeaveOrEnd: () => {
          teamDragAndDrop[which].draggingSource = false
          props.teamDragEventBus.clear()
        }
      }
    }

    const teamDropConfig = (which: "home" | "visitor") : ilDropTarget => {
      return {
        onEnter: () => {
          const dragging = currentDraggedTeamIfIsDroppable(which)

          if (!dragging) {
            return false
          }

          teamDragAndDrop[which].hoveringTarget = true

          return true;
        },
        onDragOver: "sameAsOnEnter",
        onLeaveOrEnd: () => {
          teamDragAndDrop[which].hoveringTarget = false
        },
        onDrop: () => {
          try {
            const dragging = currentDraggedTeamIfIsDroppable(which)

            if (!dragging) {
              return
            }

            ctx.emit("swapTeams", {
              source: dragging.source,
              sourceWhich: dragging.sourceWhich,
              target: props.bracketRoundSlot,
              targetWhich: which,
            })
          }
          finally {
            teamDragAndDrop[which].hoveringTarget = false
            props.teamDragEventBus.clear()
          }
        }
      }
    }

    const currentDraggedTeamIfIsDroppable = (which: "home" | "visitor") => {
      if (!props.allowDragAndDrop) {
        return false
      }

      const uiData = props.bracketRoundSlotUiData
      if (uiData.form.type !== "game") {
        return false
      }

      const dragging = props.teamDragEventBus.get()
      if (!dragging) {
        return false
      }

      const currentThisTeam = uiData.form[`${which}Team`]
      if (!currentThisTeam) {
        return false
      }

      if (currentThisTeam.type === "placeholder") {
        return false
      }

      if (currentThisTeam.value.teamID === dragging.team.teamID) {
        return false
      }

      return dragging
    }

    const selectedTeamIdDummySink = (which: "home" | "visitor") => {
      return {
        get value() {
          const uiData = props.bracketRoundSlotUiData
          if (uiData.form.type !== "game") {
            return ""
          }
          const form = uiData.form[`${which}Team`]

          if (!form) {
            return ""
          }
          return form.value?.teamID || ""
        },
        set value(v) {
          // no-op
        }
      }
    }

    const selectedHomeTeamIdDummySink = selectedTeamIdDummySink("home")
    const selectedVisitorTeamIdDummySink = selectedTeamIdDummySink("visitor")

    const uniqueTeamsValidator = computed(() => UniqueTeamsValidator(props.fkNodeTracker_teamInputs))

    return () => {
      const slot = props.bracketRoundSlot
      const uiData = props.bracketRoundSlotUiData
      if (uiData.form.disabled) {
        // offer "enable"?
        return <span>empty</span>;
      }
      return <div>
        {false && props.bracketRoundNum === 1 // disabled, type=team is going the way of fully unused
          ? <div class="flex items-center gap-1">
            <input
              type="radio"
              name={`il-bracketRoundSlot-type-${radioID}`}
              checked={uiData.form.type === "game"}
              onInput={() => ctx.emit("updateType", "game")}
              value={"game" satisfies BracketRoundSlotUiData["form"]["type"]}
            />
            <span class="mr-1">Matchup</span>
            <input
              type="radio"
              name={`il-bracketRoundSlot-type-${radioID}`}
              checked={uiData.form.type === "team"}
              onInput={() => ctx.emit("updateType", "team")}
              value={"team" satisfies BracketRoundSlotUiData["form"]["type"]}
            />
            <span>Team Placeholder / Bye</span>
          </div>
          : null
        }
        {uiData.form.type === "game"
          ? <div>
            <div style="display:grid; grid-template-columns: min-content 1fr; grid-gap: .35em .25em; align-items: center;">
              <div class={["font-medium", teamDragAndDrop.home.draggingSource ? teamDragSourceClass : ""]}>
                <div class="flex gap-1">
                  {props.allowDragAndDrop && uiData.form.homeTeam && uiData.form.homeTeam.type === "team"
                    ? <span
                      v-ilDraggable={teamDragConfig("home")}
                      style="padding: 0 .125em;"
                      class="cursor-grab"
                    >
                      <FontAwesomeIcon icon={faGripDotsVertical}/>
                    </span>
                    : null
                  }
                  <div>
                    {props.mode === "existing" && (uiData.form.gameID && slot.gameID === uiData.form.gameID)
                      ? <a class="il-link" onClick={() => ctx.emit("showEditTeamModal")}>Home</a>
                      : "Home"
                    }
                  </div>
                </div>
              </div>
              <div
                v-ilDropTarget={teamDropConfig("home")}
                class={teamDragAndDrop.home.hoveringTarget ? teamDragHoverTargetClass : !!currentDraggedTeamIfIsDroppable("home") ? teamDragHoverPotentialTargetClass : ""}
              >
                {uiData.form.homeTeam?.type === "placeholder" // hm, really this should just be "mode===new"
                  ? <FormKit
                    type="select"
                    onNode={props.fkNodeTracker_teamInputs.track}
                    validation={[["uniqueTeams"]]}
                    validationMessages={{uniqueTeams: "This team is used elsewhere on this bracket."}}
                    validationRules={{uniqueTeams: uniqueTeamsValidator.value}}
                    validationVisibility="live"
                    v-model={selectedHomeTeamIdDummySink.value}
                    disabled={teamOptions.value.disabled}
                    options={teamOptions.value.options}
                    onInput={(value: any) => ctx.emit("updateTeam", {which: "home", teamID: value})}
                  />
                  : teamBlurb(slot.sourceLeft, uiData.form.homeTeam)
                }
              </div>

              <div class={["font-medium", teamDragAndDrop.visitor.draggingSource ? teamDragSourceClass : ""]}>
                <div class="flex gap-1">
                  {props.allowDragAndDrop && uiData.form.visitorTeam && uiData.form.visitorTeam.type === "team"
                    ? <span
                      v-ilDraggable={teamDragConfig("visitor")}
                      style="padding: 0 .125em;"
                      class="cursor-grab"
                    >
                      <FontAwesomeIcon icon={faGripDotsVertical}/>
                    </span>
                    : null
                  }
                  {props.mode === "existing" && (uiData.form.gameID && slot.gameID === uiData.form.gameID)
                    ? <a class="il-link" onClick={() => ctx.emit("showEditTeamModal")}>Visitor</a>
                    : "Visitor"
                  }
                </div>
              </div>
              <div
                v-ilDropTarget={teamDropConfig("visitor")}
                class={teamDragAndDrop.visitor.hoveringTarget ? teamDragHoverTargetClass : !!currentDraggedTeamIfIsDroppable("visitor") ? teamDragHoverPotentialTargetClass : ""}
              >
                {uiData.form.visitorTeam?.type === "placeholder" // hm, really this should just be "mode===new"
                  ? <FormKit
                    type="select"
                    onNode={props.fkNodeTracker_teamInputs.track}
                    validation={[["uniqueTeams"]]}
                    validationMessages={{uniqueTeams: "This team is used elsewhere on this bracket."}}
                    validationRules={{uniqueTeams: uniqueTeamsValidator.value}}
                    validationVisibility="live"
                    v-model={selectedVisitorTeamIdDummySink.value}
                    disabled={teamOptions.value.disabled}
                    options={teamOptions.value.options}
                    onInput={(value: any) => ctx.emit("updateTeam", {which: "visitor", teamID: value})}
                  />
                  : teamBlurb(slot.sourceRight, uiData.form.visitorTeam)
                }
              </div>

              <div class="font-medium">Field</div>

              <FormKit
                type="select"
                options={props.fieldOptions}
                v-model={fk_selectedFieldUID_dummySink.value}
                data-test="fieldUID"
                onInput={(value: any) => {
                  if (fk_selectedFieldUID_dummySink.value === value) {
                    return
                  }
                  ctx.emit("updateFieldUID", value)
                }}
              />

              <div class={[
                `font-medium`,
                selectedGameIdIsDisabled.value ? "text-red-600 font-bold" : ""
              ]}>
                {selectedGameIdIsDisabled.value
                  ? "*Game*"
                  : "Game"
                }
              </div>
              <FormKit
                type="select"
                disabled={gameOptions.value.disabled}
                options={gameOptions.value.options}
                v-model={fk_selectedGameID_dummySink.value}
                data-test="gameID"
                onInput={(value: any) => {
                  if (fk_selectedGameID_dummySink.value === value) {
                    return
                  }
                  ctx.emit("updateGameID", value)
                }}
              />
              {props.mode === "new" && uiData.form.gameID === CreateAGame
                ? (() => {
                  const createForm = requireNonNull(uiData.form.create)
                  return <>
                    <div class="font-medium">Date</div>
                    <FormKit
                      type="date"
                      v-model={createForm.startDate}
                      data-test="startDate"
                      validation={[["required"]]}
                      validationLabel="Date"
                    />
                    <div class="font-medium">Time</div>
                    <div class="flex items-center gap-1">
                      <FormKit
                        type="select"
                        options={timeStartHourOptions}
                        v-model={createForm.startHr24}
                        data-test="startHr24"
                        validation={[["required"]]}
                        validationLabel="Hour"
                      />
                      :
                      <FormKit
                        type="select"
                        options={timeStartMinuteOptions}
                        v-model={createForm.startMin}
                        data-test="startMin"
                        validation={[["required"]]}
                        validationLabel="Minute"
                      />
                    </div>
                  </>
                })()
                : null
              }
            </div>
          </div>
          : uiData.form.type === "team"
          ? <div>
              <div class="my-1 text-sm">
                <div class="font-medium">Team</div>
                {isDuplicateTeamSelection.value
                  ? <div class="text-red-600">Selected more than once on this bracket</div>
                  : null
                }
              </div>
              <FormKit
                type="select"
                disabled={teamOptions.value.disabled}
                options={teamOptions.value.options}
                v-model={fk_selectedTeamID_dummySink.value}
                onInput={(value: any) => {
                  if (fk_selectedTeamID_dummySink.value === value) {
                    return
                  }
                  ctx.emit("updateTeamID", value)
                }}
              />
          </div>
          : exhaustiveCaseGuard(uiData.form)
        }
        {isDirty.value
          ? <div class="mt-2 flex gap-2 items-center">
            <Btn2 class="text-xs px-2 py-1" onClick={() => ctx.emit("persistChangesToExisting")}>Save change</Btn2>
            <Btn2 class="text-xs px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("discardChangesForExisting")}>Cancel</Btn2>
          </div>
          : null
        }
      </div>
    }
  }
})

// separate component mostly just for hmr consideration
export const BracketNameTitleBarElem = defineComponent({
  props: {
    mode: vReqT<"new" | "existing">(),
    bracket: vReqT<Bracket>()
  },
  emits: {
    openEditModal: () => true,
  },
  setup(props, ctx) {
    return () => {
      return (
        <div>
          {props.mode === "new"
            ? `Unsaved bracket - ${props.bracket.bracketRounds.length} rounds`
            : <div class="flex items-center gap-2">
              <button
                type="button"
                class="text-xs cursor-pointer rounded-md il-buttonlike-2"
                style="padding: .3em .5em;"
                onClick={() => ctx.emit("openEditModal")}
              >
                <FontAwesomeIcon icon={faPencil}/>
              </button>
              {props.bracket.bracketName} - {props.bracket.bracketRounds.length} rounds
            </div>
          }
        </div>
      )
    }
  }
})

export const EditBracketNameModal = defineComponent({
  props: {
    initialValue: vReqT<string>(),
  },
  emits: {
    saveChanges: (_: string) => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    const value = ref(props.initialValue)
    const focusAttr = useAutoFocusOnMount()
    return () => {
      return <div>
        <FormKit type="form" actions={false} onSubmit={() => ctx.emit("saveChanges", value.value)}>
          <FormKit type="text" v-model={value.value} {...focusAttr}  validation={[FK_validation_strlen(0, STRLEN_MAX_BRACKET_NAME)]} validationLabel={"Bracket name"}/>
          <div class="mt-4 flex gap-2 items-center">
            <Btn2 type="submit" class="px-2 py-1">OK</Btn2>
            <Btn2 class="px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("cancel")}>Cancel</Btn2>
          </div>
        </FormKit>
      </div>
    }
  }
})

// separate component mostly just for hmr consideration
export const BracketRoundNameColHeaderElem = defineComponent({
  props: {
    mode: vReqT<"new" | "existing">(),
    bracketRound: vReqT<BracketRound>()
  },
  emits: {
    openEditModal: () => true,
  },
  setup(props, ctx) {
    return () => {
      return (
        <div>
          {props.mode === "new"
            // in "new" mode, edit directly
            ? <FormKit type="text" v-model={props.bracketRound.bracketRoundName} class="w-full" validation={[FK_validation_strlen(0, STRLEN_MAX_BRACKET_ROUND_NAME)]} validationLabel={"Bracket round name"}/>
            // otherwise, just display it and offer a modal to edit it
            : <div class="flex items-center gap-2">
              <button
                type="button"
                class="text-xs cursor-pointer rounded-md il-buttonlike-2"
                style="padding: .3em .5em;"
                onClick={() => ctx.emit("openEditModal")}
              >
                <FontAwesomeIcon icon={faPencil}/>
              </button>
              <span>{props.bracketRound.bracketRoundName}</span>
            </div>
          }
        </div>
      )
    }
  }
})

export const EditBracketRoundNameModal = defineComponent({
  props: {
    initialValue: vReqT<string>(),
    zi_roundNum: vReqT<number>(),
  },
  emits: {
    saveChanges: (_: string) => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    const value = ref(props.initialValue)

    const focusAttr = useAutoFocusOnMount()

    return () => {
      return <div>
        <FormKit type="form" actions={false} onSubmit={() => ctx.emit("saveChanges", value.value)}>
          <div>Round {props.zi_roundNum+1}</div>
          <FormKit type="text" v-model={value.value} {...focusAttr} validation={[FK_validation_strlen(0, STRLEN_MAX_BRACKET_ROUND_NAME)]} validationLabel={"Bracket round name"}/>
          <div class="mt-4 flex gap-2 items-center">
            <Btn2 type="submit" class="px-2 py-1">OK</Btn2>
            <Btn2 class="px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("cancel")}>Cancel</Btn2>
          </div>
        </FormKit>
      </div>
    }
  }
})

export type BracketRoundSlotUiData = {
  busy: boolean,
  /**
   * simple flag saying if the last interaction with the server had some game scheduling error.
   */
  hadGameSchedulingConflict: boolean,
  selectedFieldUID: Guid
  gameOptionsForSelectedField: {value: ReifiedPromise<{
    uiOptions: UiOptions,
    // The contract is that all the games for which there are options in the uiOptions field are present here.
    // This should be considered deeply readonly.
    sourceGames: readonly GameForBracketBuilderView[]
  }>},
  teamOptions: ReactiveReifiedPromise<UiOptions & {source: BracketRoundSlotTeamOption[]}>,
  form:
    & {disabled: boolean}
    & (
      | BracketBuilderForm
      | {
        type: "team",
        teamID: Guid | "",
      }
    )
}

export type BracketBuilderForm = {
  type: "game",
  // All of these values are "just strings" so take care to discern what is an appropriate value at use sites.
  // Note that they will be used in forms as <select> values.
  // TODO: break this apart into separate subtypes,
  // it's super difficult to reason about if you've handled all the different versions of what a string means in this position
  gameID:
    | "" // nothing
    | Guid // some existing gameID
    | typeof NeedsAGame // tag for when we initially create a bracket locally, to tell subsequent program phases we need to look for a game
    | typeof CreateAGame // user requested to create a game for this
  homeTeam: null | BracketBuilderTeamWrapper,
  visitorTeam: null | BracketBuilderTeamWrapper,
  // present if gameID is "CreateAGame".
  create?: BracketRoundSlotCreateGame
}

/**
 * This helps represent the case where we build a bracket not from but teams but rather just a team count and we don't know a team ahead of time,
 * but we do know the expected seed value for wherever it is going to be placed.
 */
export type BracketBuilderTeamWrapper =
  | {type: "placeholder", seed: number, value: null | BracketBuilderFormTeam}
  | {type: "team", value: BracketBuilderFormTeam}

export const bracketBuilderTeamWrapper_seed = (v: BracketBuilderTeamWrapper) : number => {
  switch (v.type) {
    case "placeholder": return v.seed
    case "team": return parseIntOr(v.value.seed, Infinity)
    default: exhaustiveCaseGuard(v)
  }
}

export type BracketBuilderFormTeam = {teamID: Guid, teamDesignation: string, teamName: string, coaches: CoachTriple[], seed: number | null}

export function mungeGameTeamForUiData(teamObj: GameForGameSchedulerView_Team | null, coaches: GameForGameSchedulerView["coaches"][number][]) : BracketBuilderTeamWrapper | null {
  if (!teamObj) {
    return null
  }

  return {
    type: "team",
    value: {
      teamID: teamObj.teamID,
      teamDesignation: teamObj.teamDesignation,
      teamName: teamObj.teamName,
      seed: teamObj.seed,
      coaches: coaches.filter(v => v.teamID === teamObj.teamID)
    }
  }
}

export type BracketRoundSlotCreateGame = {
  startDate: Datelike,
  startHr24: Integerlike,
  startMin: Integerlike,
  gameLengthMinutes: Integerlike,
  // This exists just as a way to find this documentation:
  // the fieldUID we should be using here is the one contained in BracketRoundSlotUiData
  /**@deprecated*/
  fieldUID: "use-the-outer-fieldUID/read-the-comment",
}

export const NeedsAGame = "needs-a-game"
// TODO: we use this as a gameID -- probably should just have separate subtypes to make it harder to use improperly (right
// now you have to remember to handle the "truthy gameID isn't a gameID" case)
export const CreateAGame = "create-a-game"

export const STRLEN_MAX_BRACKET_NAME = 100
export const STRLEN_MAX_BRACKET_ROUND_NAME = 100

export const EditBracketRoundSlotTeamsModal = defineComponent({
  props: {
    bracketRoundSlot: vReqT<BracketRoundSlot>(),
    uiData: vReqT<BracketRoundSlotUiData>(),
    consumedTeamIDs: vReqT<Set<Guid>>(),
    initialHomeTeamID: vReqT<string>(),
    initialVisitorTeamID: vReqT<string>(),
  },
  emits: {
    saveChanges: (_: {home: null | BracketRoundSlotTeamOption, visitor: null | BracketRoundSlotTeamOption}) => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    const nodeTracker = FKNodeTracker()
    const uniqueTeamsValidator = UniqueTeamsValidator(nodeTracker)

    const homeTeamID = ref(props.initialHomeTeamID)
    const visitorTeamID = ref(props.initialVisitorTeamID)

    const teamOptions = computed(() => {
      if (props.uiData.teamOptions.underlying.status === "resolved") {
        const options = props.uiData.teamOptions.underlying.data.options
        const source = props.uiData.teamOptions.underlying.data.source
        return options.length === 0
          ? {
            ...noAvailableOptions("No available teams"),
            source: []
          }
          : {
            disabled: false,
            options: options.map(opt => {
              return {
                ...opt,
                attrs: {
                  disabled: props.consumedTeamIDs.has(opt.value)
                }
              }
            }),
            source,
          }
      }
      else {
        return {
          ...noAvailableOptions("Loading teams..."),
          source: [],
        }
      }
    })

    const handleSubmit = () => {
      const home = homeTeamID.value ? arrayFindOrFail(teamOptions.value.source, v => v.teamID === homeTeamID.value) : null
      const visitor = visitorTeamID.value ? arrayFindOrFail(teamOptions.value.source, v => v.teamID === visitorTeamID.value) : null
      ctx.emit("saveChanges", {home, visitor})
    }

    return () => {
      return (
        <div>
          <FormKit type="form" actions={false} onSubmit={handleSubmit}>
            <div style="display:grid; grid-template-columns: min-content auto; grid-gap:.5em; --fk-margin-outer: none; --fk-padding-input:.5em;">
              <div class="font-medium">Home</div>
              <FormKit
                type="select"
                disabled={teamOptions.value.disabled}
                options={teamOptions.value.options}
                v-model={homeTeamID.value}
                onNode={nodeTracker.track}
                validation={[["uniqueTeams"]]}
                validationRules={{uniqueTeams: uniqueTeamsValidator}}
                validationMessages={{uniqueTeams: "Duplicate selection."}}
                validationVisibility="live"
              />
              <div class="font-medium">Visitor</div>
              <FormKit
                type="select"
                disabled={teamOptions.value.disabled}
                options={teamOptions.value.options}
                v-model={visitorTeamID.value}
                onNode={nodeTracker.track}
                validation={[["uniqueTeams"]]}
                validationRules={{uniqueTeams: uniqueTeamsValidator}}
                validationMessages={{uniqueTeams: "Duplicate selection."}}
                validationVisibility="live"
              />
              <div class="flex gap-2 items-center mt-2">
                <Btn2 type="submit" class="px-2 py-1">OK</Btn2>
                <Btn2 class="px-2 py-1" enabledClasses={btn2_redEnabledClasses} onClick={() => ctx.emit("cancel")}>Cancel</Btn2>
              </div>
            </div>
          </FormKit>
        </div>
      )
    }
  }
})

const UniqueTeamsValidator = (nodeTracker: FKNodeTracker) => (node: FormKitNode) : boolean => {
  node.value // touch this for reactivity

  const root = node.at("$root")
  if (!root) {
    // shouldn't happen
    return false
  }

  const thisValue = root.at(node.address)?.value
  if (!thisValue || thisValue === Client.value.instanceConfig.byeteam) {
    return true;
  }

  for (const v of nodeTracker.allNodes()) {
    if (FKNodeTracker.isSameNode(v, node)) {
      continue;
    }

    // touch node to mark it as reactive inside FK
    const otherValue = root.at(v.address)?.value as string

    if (!otherValue || otherValue === Client.value.instanceConfig.byeteam) {
      continue;
    }

    if (thisValue === otherValue) {
      return false
    }
  }

  return true
}
