<template lang="pug">
PageItemChange(v-if="(showConfirmation)" @change="changeType(pageItemType)" @cancel="clearChange")

h1.text-4xl.font-medium.mb-5
  font-awesome-icon.mx-4.mt-2.cursor-pointer(:icon='["fas", "plus-circle"]')
  | New Registration Item

div.max-w-md
  FormKit.m-2(
    v-model='pageItemType',
    label-class='text-sm t-label w-full block mb-2',
    type='select',
    label='Registration Item Type',
    name='type',
    :options='typeOptions',
  )
div(v-if="pageItemType === ''")
  //- no-op, nothing selected
NewContentChunk(v-else-if="pageItemType === PageItemAndQuestionType.CONTENT_CHUNK && Object.keys(pageItem).length" :item="pageItem")
NewQuestion(
  v-else="Object.keys(pageItem).length"
  :item="pageItem"
  :key="pageItemType"
  :errors="errors"
)

</template>

<script lang="ts">
import { defineComponent, ref, watch, Ref, computed } from 'vue'
import NewContentChunk from 'src/components/Registration/admin/contentChunk/NewContentChunk.vue'
import NewQuestion from 'src/components/Registration/admin/question/NewQuestion.vue'
import { freshQuestionOption, GateFunctionClass, PageItem_ambiguously_QuestionOrContentChunk } from 'src/interfaces/Store/registration'
import PageItemChange from 'src/components/Registration/admin/pageItem/PageItemChange.vue'
import { PageItemType, QuestionType } from 'src/interfaces/InleagueApiV1'
import { QuestionFormErrors, questionFormErrorsFromQuestion } from './PageItem'

/**
 * We're blending the ideas of "page item type" and "question type" here.
 * Normally there is "question or content chunk", and then "questions have question types"
 * Here, possibilities are flat
 *
 * The question type values are meaningful to the api. The content chunk value of 0xFFFF
 * is meaningless, except that it MUST NOT OVERLAP with any question type value.
 *
 * These must be values of type number, primarily to not overlap with "" which is used as the
 * sentinel "no value"
 */
enum PageItemAndQuestionType {
  // content chunk, arbitrary value but must be non-overlapping with any other enumerator
  CONTENT_CHUNK = 0xFFFF,
  // question, note that the values are meaningful
  TEXT = QuestionType.TEXT,
  RADIO = QuestionType.RADIO,
  SELECT = QuestionType.SELECT,
  CHECKBOX = QuestionType.CHECKBOX,
  TEXTAREA = QuestionType.TEXTAREA,
}

type PageItemSelector = PageItemAndQuestionType | "";

export default defineComponent({
  name: 'New Page Item',
  components: {
    NewContentChunk,
    NewQuestion,
    PageItemChange
  },
  setup() {
    const pageItemType = ref<PageItemSelector>("")

    const typeOptions = [
      {value: "", label: '--Select Option--',},
      {value: PageItemAndQuestionType.CONTENT_CHUNK, label: 'Text/HTML',},
      {value: PageItemAndQuestionType.TEXT, label: 'Text Input',},
      {value: PageItemAndQuestionType.RADIO, label: 'Radio Buttons',},
      {value: PageItemAndQuestionType.SELECT, label: 'Select (drop-down)',},
      {value: PageItemAndQuestionType.CHECKBOX, label: 'Checkbox',},
      {value: PageItemAndQuestionType.TEXTAREA, label: 'Text Area (multi-line)'},
    ]
    const pageItem = ref({}) as Ref<PageItem_ambiguously_QuestionOrContentChunk>
    const previousPageItemType = ref<PageItemSelector>('')
    const showConfirmation = ref(false)


    const errors = computed<QuestionFormErrors | undefined>(() => {
      if (pageItemType.value === "") {
        return undefined;
      }
      else if (pageItemType.value === PageItemAndQuestionType.CONTENT_CHUNK) {
        return undefined;
      }
      else {
        return questionFormErrorsFromQuestion(pageItem.value);
      }
    })

    const createContentChunk = () => {
      pageItem.value = {
          defaultText:"",
          gateFunctionName:"",
          shortLabel:"",
          endDate:"",
          startDate:"",
          gateFunction:{} as GateFunctionClass,
          gateFunctionID:"",
          label:""
        }
    }

    const createQuestion = () => {
      const question : PageItem_ambiguously_QuestionOrContentChunk = {
        gateFunctionName: "",
        shortLabel:"",
        gateFunction: {} as GateFunctionClass,
        gateFunctionID:"",
        label:"",
        type: pageItemType.value as number,
        isRequired: false,
        isDisabled: false,
        isEditable: false,
        showThisByDefaultWhenBuildingTeams: false,
        questionOptions: [freshQuestionOption(1)],
      }

      pageItem.value = question
    }

    const clearChange = () => {
      pageItemType.value=previousPageItemType.value
      showConfirmation.value=false
    }

    const changeType = (val: PageItemSelector) => {
      showConfirmation.value=false
      if (val === "") {
        // ??
        // no-op?
        // the user selected "nothing"
      }
      else {
        switch (val) {
          case PageItemAndQuestionType.CONTENT_CHUNK:
            createContentChunk();
            return;
          case PageItemAndQuestionType.TEXT:
          case PageItemAndQuestionType.CHECKBOX:
          case PageItemAndQuestionType.SELECT:
          case PageItemAndQuestionType.RADIO:
          case PageItemAndQuestionType.TEXTAREA:
            createQuestion();
            return;
          default:
            // `val` doesn't narrow to "never" because it is a flag-like enum
            // anyway, this switch is intended to be exhaustive
            throw "non-exhaustive case or unintentional fallthrough";
        }
      }
    }

    watch(pageItemType, (val: PageItemSelector, prevVal: PageItemSelector) => {
      changeType(val)
    })

    return {
      pageItemType,
      previousPageItemType,
      typeOptions,
      pageItem,
      clearChange,
      changeType,
      PageItemAndQuestionType,
      showConfirmation,
      errors
    }
  },
})
</script>
