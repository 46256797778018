import { defineComponent, ref, computed, watch, onMounted, shallowRef } from 'vue'

import { Screen } from 'quasar'
import {
  PaymentRequestPaymentMethodEvent,
} from '@stripe/stripe-js'

import { axiosAuthBackgroundInstance, AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import { v4 as uuidv4 } from 'uuid'
import { assertNonNull, assertTruthy, exhaustiveCaseGuard, parseFloatOr, parseFloatOrFail, requireNonNull, unreachable } from 'src/helpers/utils'
import { propsDef, emitsDef, maybeGetCompRegLineItems, maybeGetTournamentTeamRegLineItem, maybeGetEventSignups } from "./PaymentTools.ilx"
import { LastStatus_t } from 'src/interfaces/Store/checkout'
import { Integerlike } from 'src/interfaces/InleagueApiV1'
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia"
import { PayInvoiceArgs } from 'src/composables/InleagueApiV1.Invoice'
import { isSubscriptionInvoice } from './InvoiceUtils'
import { Btn2 } from "src/components/UserInterface/Btn2"
import { type Stripe, stripe_getPaymentIntentForInvoice } from './Payments.io'

import { IL_PaymentMethod } from "./Payments.io"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import { CollectPaymentMethodDetailsType, UserPaymentMethodsManagerState, PaymentMethodUsability } from './UserPaymentMethodsManagerState'
import { UserPaymentMethodsManagerElement, UserPaymentMethodsManagerElementSlots } from './UserPaymentMethodsManagerElement'

export default defineComponent({
  name: 'PaymentTools',
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    const pmMgrState = shallowRef<UserPaymentMethodsManagerState | null>(null)
    const savePaymentMethod = ref(true)
    const paymentInProgress = ref(false)
    const error = ref('') // A message displayed in DOM for error cases
    const ready = ref(false)
    const paymentMethodExpirationColumnLabel = computed(() => {
      // TODO: remove dep on quasar for this, use a js-native window event handler that we make reactive
      return Screen.width < 768
        ? "Exp."
        : "Expiration"
    })

    const msg = computed(() => {
      const overallTotal = parseFloatOr(props.invoiceInstance.lineItemSum, null)?.toFixed(2) ?? props.invoiceInstance.lineItemSum;

      if (isSubscriptionInvoice(props.invoiceInstance)) {
        return <div>{props.paymentScheduleBlurb}</div>
      }
      else {
        return <div>Your total is: ${overallTotal}</div>
      }
    })

    const hasSomePaymentBlockedLineItem = computed(() => {
      for (const lineItem of props.invoiceInstance.lineItems) {
        if (lineItem.paymentBlock_isBlocked) {
          return true;
        }
      }
      return false;
    });

    async function submitPayment(
      args: {paymentMethodID: string, paymentMethodRequestEvent?: PaymentRequestPaymentMethodEvent}
    ) : Promise<{ok: boolean} | {ok: false, message?: string}>
    {
      paymentInProgress.value = true;

      try {
        if (hasSomePaymentBlockedLineItem.value) {
          // TODO: this exclusively handles a compreg case, where "the whole compreg is blocked",
          // so should be refactored to be explicit about that.
          const paymentMethodID = args.paymentMethodID
          const invoiceInstanceID = props.invoiceInstance.instanceID as Integerlike;
          const result = await props.attachTentativeStripePaymentMethodToInvoice({instanceID: invoiceInstanceID, paymentMethodID});
          if (result.ok) {
            args.paymentMethodRequestEvent?.complete("success")
          }
          else {
            args.paymentMethodRequestEvent?.complete("fail")
          }
          return result
        }
        else {
          const result = await props.payInvoice(getPayInvoiceArgs());
          if (result.ok) {
            args.paymentMethodRequestEvent?.complete("success")
          }
          else {
            args.paymentMethodRequestEvent?.complete("fail")
            error.value = result.message;
          }
          return result;
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        // hm, there WON'T be any NON-axios exceptions here right? Meaning, `rethrowIfNotAxiosError` will always rethrow here?
        // Because the invoked things return the equivalent of Either<OK,Err>, rather than throw axios errors.
        return {ok: false, message: "Sorry, something went wrong"}
      }
      finally {
        paymentInProgress.value = false;
      }

      function getPayInvoiceArgs() : PayInvoiceArgs {
        return {
        instanceID: props.invoiceInstance.instanceID,
        idempotencyKey: uuidv4(),
        paymentMethodID: (() => {
            if (!paymentState.value) {
              // shouldn't happen
              return undefined
            }

            if (paymentState.value.entireInvoiceIs === "qTournamentTeam(teamReg)") {
              // for tournTeam teamReg invoices,
              // where teamReg is free but there is an associated non-zero holdPayment,
              // we want to enforce collection of the paymentMethod for the hold payment invoice,
              // before we allow the free registration invoice to be "paid".
              // The backend handles both at once when pushing "payment" for a tournteam reg invoice.
              return args.paymentMethodID;
            }

            const parsedSum = parseFloatOrFail(props.invoiceInstance.lineItemSum)
            if (parsedSum >= 0.01) {
              return args.paymentMethodID
            }

            return undefined;
          })(),
          discardCard: !savePaymentMethod.value
        };
      }
    }

    const deletePaymentMethod = async (paymentMethod: IL_PaymentMethod) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        assertNonNull(pmMgrState.value)
        await pmMgrState.value.doDeletePaymentMethod(axiosInstance, paymentMethod)
      })
    }

    const payByCashOrCheck = () => {
      emit("payWithCashOrCheck");
    }

    /**
     * fixme: see propsDef for `total` -- it's undefined, and a string?
     */
    const mungedTotal = computed<number | null>(() => parseFloatOr(props.total, null));

    /**
     * Conceptually, an invoice can be composed of many heterogenous line item types,
     * like a compreg and 2 event signups and a tournament team registration all on one invoice.
     * But in practice, an invoice is always homegenous, with the exception of donation line items being included
     * on compreg invoices. This may change in the future; but for now this encodes the use case.
     *
     * `requiresPaymentMethod` and `isBlocked` are related but ultimately orthogonal:
     * requiresPaymentMethod=0 isBlocked=0 -> totally zero-fee invoice, click to finalize
     * requiresPaymentMethod=0 isBlocked=1 -> totally zero-fee invoice, can't finalize until block is removed (e.g. a waitlisted compreg)
     * requiresPaymentMethod=1 isBlocked=0 -> has-fee invoice, click to submit payment method and finalize
     * requiresPaymentMethod=1 isBlocked=1 -> has-fee invoice, need to collect payment method but cannot finalize
     */
    interface AggregateInvoiceTypeAndPaymentState {
      /**
       * whether we need to collect payment information, either to collect payment immediately or store for future use
       * (i.e. in the "is blocked" case)
       */
      requiresPaymentMethod: boolean,
      /**
       * whether we can positively attempt to pay/complete this invoice
       */
      isBlocked: boolean,
      entireInvoiceIs: "qEventSignup" | "qCompetitionRegistration" | "qTournamentTeam(teamReg)" | "invoiceTemplateWithNoLineItems"
      // could be separate decomposed flags, but want to handle them all as a single enum
      status: "fee/blocked" | "fee/not-blocked" | "zero-fee/blocked" | "zero-fee/not-blocked"
    }

    const paymentState = computed<AggregateInvoiceTypeAndPaymentState | null>(() => {
      const result = maybeGetCompRegState()
        || maybeGetEventSignupState()
        || maybeGetTournamentTeamRegState()
        || maybeGetInvoiceTemplateBasedPaymentState();

      if (result) {
        return result;
      }
      else {
        // this represents one of a few possible scenarios:
        // - bug on our part (we didn't search properly for the line items?)
        // - the invoice has no associated line items, generally because the invoice was voided (why did we mount `PaymentTools` if that's the case?)
        // - maybe a data race? as in, `props.invoiceInstance` is currently `undefined`, which can happen if one of:
        //   - router.params.invoiceID is invalid, or not yet bound
        //   - request to load invoice has fired but has not yet resolved
        return null;
      }

      function maybeGetInvoiceTemplateBasedPaymentState() : AggregateInvoiceTypeAndPaymentState | null {
        if (!props.invoiceInstance.invoiceID || props.invoiceInstance.lineItems.length !== 1 || props.invoiceInstance.lineItems[0].entity_type !== "invoiceTemplateDummyLine") {
          return null
        }

        const hasFee = true // TODO: handle zero-fee case
        const isBlocked = !!props.invoiceInstance.lineItems[0].paymentBlock_isBlocked // always false for such an invoice, yeah?

        return {
          requiresPaymentMethod: true,
          isBlocked: isBlocked,
          entireInvoiceIs: "invoiceTemplateWithNoLineItems",
          status: quadStateZeroFeeAndBlocked(hasFee, isBlocked)
        }
      }

      function maybeGetCompRegState() : AggregateInvoiceTypeAndPaymentState | null {
        const compRegLineItems = maybeGetCompRegLineItems(props.invoiceInstance);
        if (compRegLineItems) {
          assertTruthy(compRegLineItems.length > 0, "if we got an array it has at least one element");
          const hasFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          const isBlocked : boolean = compRegLineItems.some(v => !!v.paymentBlock_isBlocked);
          return {
            requiresPaymentMethod: hasFee,
            isBlocked,
            entireInvoiceIs: "qCompetitionRegistration",
            status: quadStateZeroFeeAndBlocked(hasFee, isBlocked)
          }
        }
        return null;
      }

      function maybeGetEventSignupState() : AggregateInvoiceTypeAndPaymentState | null {
        const maybeEventSignups = maybeGetEventSignups(props.invoiceInstance);
        if (maybeEventSignups) {
          const hasFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          // we don't currently block eventsignup invoice lineitems, but presumably we could at some point start doing so
          const isBlocked : boolean = !!maybeEventSignups.some(_ => _.paymentBlock_isBlocked);
          return {
            requiresPaymentMethod: hasFee,
            isBlocked,
            entireInvoiceIs: "qEventSignup",
            status: quadStateZeroFeeAndBlocked(hasFee, isBlocked)
          }
        }
        return null;
      }

      function maybeGetTournamentTeamRegState() : AggregateInvoiceTypeAndPaymentState | null {
        const maybeIsTournamentTeamReg = maybeGetTournamentTeamRegLineItem(props.invoiceInstance);
        if (maybeIsTournamentTeamReg) {
          if (props.tournamentTeamRegHoldPaymentInvoice === null) {
            throw Error("TournamentTeamReg invoice without associated hold payment invoice");
          }

          const hasRegFee : boolean = mungedTotal.value !== null && mungedTotal.value >= 0.01;
          const hasHoldPaymentFee = !!parseFloatOr(props.tournamentTeamRegHoldPaymentInvoice.lineItemSum, null)

          // right now it is not expected that a tournteamreg invoice is ever blocked, but let's not hardcode `blocked=false` here
          const isBlocked : boolean = !!maybeIsTournamentTeamReg.paymentBlock_isBlocked;

          return {
            // A tournament team registration requires payment info if it has a reg fee OR a hold payment fee,
            // where the hold payment fee is a separate invoice that may be
            // collected at some point the future, at a registrar's discretion, based on some
            // league based rules.
            requiresPaymentMethod: hasRegFee || hasHoldPaymentFee,
            isBlocked,
            entireInvoiceIs: "qTournamentTeam(teamReg)",
            status: quadStateZeroFeeAndBlocked(hasRegFee, isBlocked)
          }
        }
        return null;
      }

      function quadStateZeroFeeAndBlocked(hasFee: boolean, isBlocked: boolean) : AggregateInvoiceTypeAndPaymentState["status"] {
        if (!hasFee && !isBlocked) {
          return "zero-fee/not-blocked";
        }
        else if (!hasFee && isBlocked) {
          return "zero-fee/blocked"
        }
        else if (hasFee && !isBlocked) {
          return "fee/not-blocked";
        }
        else if (hasFee && isBlocked) {
          return "fee/blocked";
        }
        else {
          throw Error("unreachable");
        }
      }
    })

    const paymentButtonLabel = computed<string>(() => {
      const NO_BUTTON_SO_NO_LABEL = "";
      if (!paymentState.value) {
        return "";
      }

      switch (paymentState.value.entireInvoiceIs) {
        case "qCompetitionRegistration": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              return "Submit payment information";
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              return NO_BUTTON_SO_NO_LABEL;
            case "zero-fee/not-blocked":
              return NO_BUTTON_SO_NO_LABEL;
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        case "qEventSignup": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              throw new Error("expected unreachable in the current qEventSignup case (event signups aren't ever marked blocked)")
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              throw new Error("expected unreachable in the current qEventSignup case (event signups aren't ever marked blocked)")
            case "zero-fee/not-blocked":
              return NO_BUTTON_SO_NO_LABEL;
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        case "qTournamentTeam(teamReg)": {
          switch (paymentState.value.status) {
            case "fee/blocked":
              throw new Error("expected unreachable in the current qTournamentTeam(teamReg) case (tournament team registrations aren't ever marked blocked)")
            case "fee/not-blocked":
              return "Submit payment";
            case "zero-fee/blocked":
              throw new Error("expected unreachable in the current qTournamentTeam(teamReg) case (tournament team registrations aren't ever marked blocked)")
            case "zero-fee/not-blocked":
              return "Submit payment information";
            default: exhaustiveCaseGuard(paymentState.value.status);
          }
        }
        case "invoiceTemplateWithNoLineItems": {
          switch (paymentState.value.status) {
            case "fee/not-blocked":
              return "Submit payment";
            case "fee/blocked":
            case "zero-fee/blocked":
            case "zero-fee/not-blocked":
              unreachable()
            default: exhaustiveCaseGuard(paymentState.value.status)
          }
        }
        default: return exhaustiveCaseGuard(paymentState.value.entireInvoiceIs);
      }
    })

    const nextAction = ref<Stripe.PaymentIntent.NextAction | null>(null)
    watch(() => props.invoiceInstance.lastStatus, async () => {
      if (props.invoiceInstance.lastStatus === LastStatus_t.STRIPE_REQUIRES_ACTION) {
        const pi = await stripe_getPaymentIntentForInvoice(axiosAuthBackgroundInstance, {invoiceInstanceID: props.invoiceInstance.instanceID})
        if (pi.next_action) {
          nextAction.value = pi.next_action
        }
        else {
          nextAction.value = null
        }
      }
      else {
        nextAction.value = null
      }
    }, {immediate: true})

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        pmMgrState.value = await UserPaymentMethodsManagerState(axiosAuthBackgroundInstance, {
          inv: computed(() => props.invoiceInstance),
        })
        ready.value = true
      });
    })

    return () => {
      return <div data-test="PaymentToolsRoot">
        {pmMgrState.value && paymentState.value && paymentState.value.requiresPaymentMethod
          ? <div class="mt-4 m-2 px-2 py-2 border-2 border-solid border-gray-300 rounded-lg p-5 md:px-14 md:m-6 md:mt-6 md:py-6">
              <h2 class="my-4 text-lg leading-6 font-medium text-gray-900">
                {msg.value}
              </h2>
              {nextAction.value?.type === "verify_with_microdeposits" && nextAction.value.verify_with_microdeposits
                ? <div class="shadow-sm border rounded-md" style="display:grid; grid-template-columns: min-content auto; grid-gap: 0 .5em;" data-test="blurb/verify_with_microdeposits">
                  <div class="text-yellow-400 bg-black rounded-tl-md rounded-bl-md px-1 flex items-center">
                    <FontAwesomeIcon class="-mt-1" icon={faTriangleExclamation}/>
                  </div>
                  <div class="py-1">
                    <div>An existing payment is pending bank verification.</div>
                    <div>
                      You can
                      <a class="il-link" href={nextAction.value.verify_with_microdeposits.hosted_verification_url} target="_blank">verify your bank account</a>
                      or opt to pay with another payment method using the below form.
                    </div>
                  </div>
                </div>
                : null}
              <div class="flex flex-col min-w-full">
                <div class="align-middle inline-block min-w-full overflow-hidden border-gray-200 sm:rounded-lg">
                  <UserPaymentMethodsManagerElement
                    state={pmMgrState.value}
                    expiresColumnLabel={paymentMethodExpirationColumnLabel.value}
                    savePaymentMethod={
                      paymentState.value.entireInvoiceIs !== 'qTournamentTeam(teamReg)' && pmMgrState.value.selectedCollectPaymentMethodDetailsType.value !== CollectPaymentMethodDetailsType.ach
                        ? savePaymentMethod
                        : null
                    }
                    errors={error.value}
                    paymentInProgress={paymentInProgress.value}
                    cardSubmitLabel={paymentButtonLabel.value}
                    achStripeModalLabel="Submit payment by connecting a bank acount..."
                    achManualInputLabel="Submit Payment"
                    onDeletePaymentMethod={pm => deletePaymentMethod(pm)}
                    onCardElemChanged={() => { error.value = "" } }
                    onError={msg => error.value = msg}
                    onCreatedNewPaymentMethod={async evt => {
                      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
                        assertNonNull(pmMgrState.value)
                        await pmMgrState.value.rehydratePaymentMethods(axiosInstance)
                        if (pmMgrState.value.paymentMethods.find(v => v.id === evt.paymentMethodID)) {
                          pmMgrState.value.selectedPaymentMethodID.value = evt.paymentMethodID
                        }
                        evt.finally?.()
                      })
                    }}
                    onSubmitPayment={evt => submitPayment(evt)}
                  >
                    {{
                      afterPaymentMethodsListing: () => {
                        assertNonNull(pmMgrState.value)
                        return <div class="m-2 flex flex-row justify-between items-start">
                          {pmMgrState.value.selectedPaymentMethodID.value.startsWith('pm_')
                            ? <Btn2
                                class="px-2 py-1 mt-2"
                                disabled={paymentInProgress.value || pmMgrState.value.selectedPaymentMethodUnusable !== PaymentMethodUsability.ok}
                                onClick={() => submitPayment({paymentMethodID: requireNonNull(pmMgrState.value).selectedPaymentMethodID.value})}
                                data-test="submitPayment"
                              >
                                {paymentButtonLabel.value}
                              </Btn2>
                            : null}

                          <div style={{
                            // was v-show, can this become a conditional/ternary guarded thing?
                            display: props.showPayWithCashOrCheckButton ? undefined : "none"
                          }}>
                            <div class="ml-2 font-light">{error.value}</div>
                            <t-btn
                              class="my-4 w-full mr-8"
                              label={props.showPayWithCashOrCheckLabel}
                              disable={paymentInProgress.value}
                              onClick={() => payByCashOrCheck()}
                              margin={false}
                              data-test="payWithCashOrCheck-button"
                            />
                          </div>
                        </div>
                      }
                    } satisfies UserPaymentMethodsManagerElementSlots}
                  </UserPaymentMethodsManagerElement>
                </div>
              </div>
            </div>
          : <div class="flex flex-col items-center justify-center px-6 py-2">
            {ready.value && paymentState.value
              ? <>
                {paymentState.value.isBlocked
                  // we're blocked and do not require any payment info
                  // we currently expect that informational blurbs be put on the screen by some other component,
                  // generally the Checkout.vue component which will draw lineitem-state-specific content chunks.
                  ? null
                  : // no fee, but not blocked, "paying" is OK but "just" finalizes the order.
                    <>
                    <div>There weren't any fees associated with this invoice. Click below to finalize this order.</div>
                    <t-btn class="mt-2 w-full" margin={false} onClick={submitPayment} data-test="submit-zero-fee">
                      <span class="w-full text-center">Confirm</span>
                    </t-btn>
                  </>
                }
              </>
              : null
            }
          </div>}
      </div>
    }
  },
})
