<template lang="pug">
div
  h1.text-lg.font-medium.-mb-4.flex.flex-row.items-center(class="md:mt-4")
    font-awesome-icon.mr-2(:icon="['fas', 'receipt']")
    | Transactions
  .mt-6.flex.flex-col
  .-my-2.py-2.overflow-x-auto(class='sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8')
    .align-middle.inline-block.min-w-full.shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
      table.min-w-full.divide-y.divide-gray-200
        thead
          tr
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Transaction Date
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Type
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Payment Method
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Amount
            th.px-6.py-3.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider
              | Transaction ID
        tr(v-if="transactions.length === 0")
          td(colspan="999")
            div(class="p-2") No transactions on file.
        transactionView(v-for="transaction in transactions" :key="transaction.transactionID" :transaction="transaction")
</template>

<script lang="ts">
import { defineComponent, PropType, onMounted } from 'vue'
import transactionView from 'src/components/Payment/Transaction.vue'
import { Transaction } from 'src/interfaces/Store/checkout'

export default defineComponent({
  name: 'TransactionDisplay',
  components: {
    transactionView
  },
  props: {
    transactions: {
      type: Object as PropType<Transaction[]>
    }
  },
})
</script>
