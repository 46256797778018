<template lang="pug">
div(v-if='ready')
  div(v-if="isTournTeamHoldPaymentInvoice")
    | This invoice is a tournament team referee deposit payment invoice, and cannot be directly paid using checkout.
  div(v-else)
    ExpiredInvoiceNotification(
      class="mb-2"
      v-if="invoiceInstance.isExpired"
      :invoice="invoiceInstance"
    )

    //- this assumes the only possible blocked item is a competition registration
    template(v-if="hasSomeLineItemWithBlockedPayment")
      div(class="md:mx-6")
        h1.text-3xl.font-medium.flex.flex-row.items-center
          font-awesome-icon.mr-2(:icon='["fas", "triangle-exclamation"]')
          | Waitlisted registration

    .flex.justify-between.mx-2(class='md:mx-6')
      h1.text-3xl.font-medium.flex.flex-row.items-center
        font-awesome-icon.mr-2(:icon='["fas", "credit-card"]')
        | Checkout

      t-btn(@click='voidInvoice', :margin='false')
        | Cancel this invoice

    .mx-auto.mt-4
      RegistrationContentChunks(
        :lineItems='invoiceInstance.lineItems'
        v-if='invoiceContainsSomeCompetitionRegistrationLineItem',
      )
      template(v-if="showPayWithCashOrCheckDetails.doShow")
        div(v-if="showPayWithCashOrCheckDetails.kind === 'compReg'")
          |  If you are attending an in-person registration event, you may either pay by credit card (below) or pay by cash.
          |  To pay by cash or check, select 'I will pay by cash or check' below and complete the registration process for all players in your family.
          |  Then follow on-site instructions for cash or check payments. Thank you!
        div(v-if="showPayWithCashOrCheckDetails.kind === 'tournTeamReg'")
          |  To complete your tournament team registration, please submit payment below. To pay by via regional check, select 'I will pay via regional check' below.
          //- TODO: blurb about ref payment if ref payment invoice is non-zero

      div(v-if="isTournTeamRegInvoice")
        TournamentTeamRegContentChunks(
          :tournamentTeamRegInvoice="invoiceInstance"
          :tournamentTeamHoldPaymentInvoice="tournamentTeamRegHoldPaymentInvoice"
        )

    template(v-if="invoiceInstance.invoiceID")
      p(class="px-4" v-html="invoiceInstance.invoiceTemplateDesc || ''")
      p(class="px-4" v-html="invoiceInstance.instanceDesc || ''")

    //-
    //- don't show this if the invoice is expired
    //-
    template(v-if="invoiceInstance")
      LineItemsDisplay.mx-8(
        class='md:mx-0',
        :invoice='invoiceInstance',
        :masterInvoice='true'
        :doApplyCoupon="doApplyCoupon"
        :paymentScheduleBlurb="paymentScheduleBlurb"
      )
      PaymentTools(
        v-if="!invoiceInstance.isExpired"
        :total='invoiceInstance.lineItemSum'
        :showPayWithCashOrCheckButton="showPayWithCashOrCheckDetails.doShow"
        :showPayWithCashOrCheckLabel="showPayWithCashOrCheckDetails.label"
        v-on="paymentToolsEventHandlers"
        :tournamentTeamRegHoldPaymentInvoice="tournamentTeamRegHoldPaymentInvoice"
        :invoiceInstance="invoiceInstance"
        :attachTentativeStripePaymentMethodToInvoice="attachTentativeStripePaymentMethodToInvoice"
        :payInvoice="payInvoice"
        :paymentScheduleBlurb="paymentScheduleBlurb"
      )
    div(v-else)
      | nothing here
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import type { Ref } from "vue";
import { useRouter, useRoute } from 'vue-router'


import LineItemsDisplay from 'src/components/Payment/LineItemsDisplay.vue'
import C_PaymentTools from 'src/components/Payment/PaymentTools'
import * as M_PaymentTools from "src/components/Payment/PaymentTools.ilx"
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'
import RegistrationContentChunks from 'src/components/Registration/invoice/RegistrationContentChunks'
import type { Invoice, LineItem } from "src/interfaces/Store/checkout"
import { Season, CompetitionRegistration, Integerlike, Guid } from 'src/interfaces/InleagueApiV1';
import dayjs from 'dayjs';
import { assertTruthy, exhaustiveCaseGuard, parseFloatOrFail, vOptT, vReqT } from 'src/helpers/utils';

import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilpayment from "src/composables/InleagueApiV1.Payments"
import * as CheckoutJsx from "./Checkout.elems"
import { isTournamentTeamHoldPaymentInvoice, isTournamentTeamRegInvoice, maybeGetCompRegLineItems, maybeGetTournamentTeamRegLineItem } from 'src/components/Payment/PaymentTools.ilx';

import * as R_TournamentTeamCreate from "src/components/Tournaments/R_TournamentTeamCreate.route"
import * as iltournament from "src/composables/InleagueApiV1.Tournament"
import { Client } from 'src/store/Client';
import * as R_RegistrationComplete from "src/components/Registration/complete/R_RegistrationComplete.route"
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia';
import { AttachTentativeStripePaymentMethodToInvoiceArgs, PayInvoiceArgs, attachTentativeStripePaymentMethodToInvoice, payInvoice } from 'src/composables/InleagueApiV1.Invoice';
import { CheckoutStore } from "src/store/CheckoutStore"

export default defineComponent({
  name: 'Checkout',
  props: {
    invoiceInstanceID: vReqT<Integerlike>(),
    attachTentativeStripePaymentMethodToInvoice: vReqT<(_: AttachTentativeStripePaymentMethodToInvoiceArgs) => Promise<{ok: true} | {ok: false, message: string}>>(),
    payInvoice: vReqT<(_: PayInvoiceArgs) => Promise<{ok: true} | {ok: false, message: string}>>(),
    doApplyCoupon: vReqT<(_: {invoiceInstanceID: Integerlike, couponCode: string}) => Promise<void>>(),
    paymentScheduleBlurb: vReqT<string | undefined>(),
  },
  components: {
    LineItemsDisplay,
    PaymentTools: C_PaymentTools,
    RegistrationContentChunks,
    ExpiredInvoiceNotification: CheckoutJsx.ExpiredInvoiceNotification,
    TournamentTeamRegContentChunks: CheckoutJsx.TournamentTeamRegContentChunks,
  },
  emits: {
    voidInvoice: () => true,
  },
  setup(props, {emit}) {

    const route = useRoute()
    const router = useRouter()

    // there is no guarantee that we find this; and if we do, various destructive actions across
    // different components may delete it out from under us.
    // TODO: parent component can pass this in right? They should be responsible for loading it.
    const invoiceInstance = computed<Invoice | undefined>(() => {
      return CheckoutStore.value.invoice[props.invoiceInstanceID]
    });

    //
    // It would probably be enough to just check if `invoiceInstance.value` is truthy,
    // but we want to be clear that we "maybe need to load the invoice", if the invoice isn't loaded yet,
    // because we arrived here either: from a route having loaded it already, or freshly via a direct page nav
    // and we need to initialize everything.
    // The `invoiceInstance.value` can flip from truthy to falsy in response to voiding the invoice,
    // and possibly other actions, which delete it from the store. In such cases we can't really do anything but
    // jump back into the "not ready" state (so as to prevent property reads from undefined), and assume that the
    // code which deleted the invoice from the store has made some provision for navigating us elsewhere,
    // so that we're not stuck on a blank page (which, for the user, just had data, and now there's nothing, what happened?)
    //
    const loadedInvoiceOnce = ref(false);
    const ready = computed(() => {
      return loadedInvoiceOnce.value && !!invoiceInstance.value;
    })

    const isTournTeamRegInvoice = computed(() => invoiceInstance.value && isTournamentTeamRegInvoice(invoiceInstance.value));

    /**
     * We don't currently support paying this directly, but we do need to identify if we got here erroneously (user manually typed in the invoice instance ID?),
     * in which case we can issue an error
     */
    const isTournTeamHoldPaymentInvoice = computed(() => invoiceInstance.value && isTournamentTeamHoldPaymentInvoice(invoiceInstance.value));

    /**
     * After ready=true,
     * IFF isTournTeamRegInvoice=true, this MUST BE the associated hold payment invoice.
     */
    const tournamentTeamRegHoldPaymentInvoice = ref<null | Invoice>(null);

    const invoiceLocallyResolved = computed(() => {
      return !!invoiceInstance.value;
    })

    const asyncResolved_seasonsMap = ref<{[seasonUID: iltypes.Guid]: Season} | null>(null);
    const targetSeason = computed(() => {
      if (invoiceInstance.value && asyncResolved_seasonsMap.value) {
        return asyncResolved_seasonsMap.value[invoiceInstance.value.seasonUID];
      }
      else {
        return undefined;
      }
    });

    const showPayWithCashOrCheckDetails = computed<ShowPayWithCashOrCheckDetails>(() => {
      if (invoiceInstance.value) {
        if (isTournTeamRegInvoice.value) {
          const tournTeamLineItem = maybeGetTournamentTeamRegLineItem(invoiceInstance.value);
          if (!tournTeamLineItem) {
            // impossible case after checking isTournTeamRegInvoice
            throw Error("didn't find expected tournteam reg lineitem");
          }
          if (!tournamentTeamRegHoldPaymentInvoice.value) {
            throw Error("illegal state -- if we are a tournteam reg invoice, we require that we also load in the ref hold payment invoice")
          }

          if (parseFloatOrFail(invoiceInstance.value.lineItemSum) < 0.01) {
            return {doShow: false, label: ""}
          }
          else {
            return {
              doShow: true,
              kind: "tournTeamReg",
              tournamentTeamID: tournTeamLineItem.tournamentTeamID_teamReg,
              label: "I will pay by regional check",
            }
          }
        }
        else {
          return maybeGetShowForCompregPayWithCashOrCheckDetails(invoiceInstance.value, targetSeason.value);
        }
      }
      else {
        return {doShow: false, label: ""}
      }
    })

    const invoiceContainsSomeCompetitionRegistrationLineItem = computed<boolean>(() => {
      if (!invoiceInstance.value) {
        return false;
      }

      const invoiceInstanceWasPopulated = Object.keys(invoiceInstance.value).length > 0;
      if (invoiceInstanceWasPopulated) {
        // there is a competition registration lineitem somewhere on this invoice
        return !!invoiceInstance.value.lineItems.find(lineItem => lineItem.entity_type === "qCompetitionRegistration");
      }
      else {
        return false;
      }
    })

    // usages of this might more correctly be named "isExactlyACompRegInvoiceWhereTheSingleAssociatedLineItemIsBlocked"
    // (and the implementation would have to change a bit)
    const hasSomeLineItemWithBlockedPayment = computed(() => {
      if (invoiceInstance.value) {
        for (const lineItem of invoiceInstance.value.lineItems) {
            if (lineItem.paymentBlock_isBlocked) {
              return true;
            }
          }
      }
      return false;
    });

    const voidInvoice = async () => {
      emit("voidInvoice")
    }

    const paymentToolsEventHandlers : M_PaymentTools.Emits = {
      payWithCashOrCheck: async () => {
        if (showPayWithCashOrCheckDetails.value.doShow) {
          await handlePayWithCashOrCheckWorker(showPayWithCashOrCheckDetails.value);
        }
        else {
          // shouldn't get here
          // we should have told PaymentTools component "hey, for this run, don't ever emit this event"
          throw "IllegalStateException: We require a competition registration to support paying with cash or check, but none was found on the invoice."
        }
      }
    }

    const handlePayWithCashOrCheckWorker = async (v: ShowForCompReg | ShowForTournTeamReg) => {
      if (!invoiceInstance.value) {
        // nothing we can do; but also, we shouldn't ever be called when this is falsy
        return;
      }

      if (v.kind === "compReg") {
        // we push to "registration-complete" even though it sort of isn't
        // But, the user knows what they did so the jump should make sense
        // later, they will have to contact their league representative who can perform the actual "pay by cash or check"
        // which will mark the registration paid and delete the associated invoice
        await router.push(R_RegistrationComplete.routeDetailToRouteLocation({
          playerID: v.playerID,
          seasonUID: v.seasonUID,
          competitionUIDs: v.compRegs.map(v => v.competitionUID),
          invoiceInstanceIDs: [invoiceInstance.value.instanceID],
          wasDeferredForCashOrCheckPayment: true,
        }))
      }
      else if (v.kind === "tournTeamReg") {
        try {
          await iltournament.markIntentToPayByCheckCompletingTheRegistrationJourney(axiosInstance, {tournamentTeamID: v.tournamentTeamID})
          const goto = R_TournamentTeamCreate.routeDetailToRouteLocation({
            name: R_TournamentTeamCreate.RouteNames.Complete,
            tournamentTeamID: v.tournamentTeamID,
            query: {byCheck: true}
          });
          await router.push(goto)
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      }
      else {
        exhaustiveCaseGuard(v)
      }
    }

    onMounted(async () => {
      // we want side-effects of store-hydration here
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        asyncResolved_seasonsMap.value = await Client.getSeasonsMap()

        if (isTournTeamRegInvoice.value) {
          const instanceID = invoiceInstance.value?.instanceID;
          if (!instanceID) {
            throw new Error("Expected an instanceID here");
          }

          tournamentTeamRegHoldPaymentInvoice.value = await ilpayment.getAssociatedInvoice(axiosInstance, {rootInvoiceInstanceID: instanceID});
        }

        // legacy wart -- we used to "load at least once" here in onMounted, but parent component should do it now
        loadedInvoiceOnce.value = true;
      })
    });

    return {
      voidInvoice,
      invoiceInstance,
      router,
      route,
      paymentToolsEventHandlers,
      showPayWithCashOrCheckDetails,
      invoiceLocallyResolved,
      hasSomeLineItemWithBlockedPayment,
      invoiceContainsSomeCompetitionRegistrationLineItem,
      isTournTeamRegInvoice,
      isTournTeamHoldPaymentInvoice,
      tournamentTeamRegHoldPaymentInvoice,
      ready,
    }
  },
})

type ShowPayWithCashOrCheckDetails =
  | NoShow
  | ShowForCompReg
  | ShowForTournTeamReg

interface NoShow {
  doShow: false,
  label: string,
}

type ShowForCompReg = {
  doShow: true,
  kind: "compReg",
  playerID: Guid,
  seasonUID: Guid,
  compRegs: CompetitionRegistration[]
  label: string
}

type ShowForTournTeamReg = {
  doShow: true,
  kind: "tournTeamReg",
  tournamentTeamID: iltypes.Integerlike,
  label: string
}

/**
 * We want to show the "pay with cash or check button" if:
 *  - This invoice is a homogenous compreg invoice (modulo donations)
 *  - The target season's "in person date" is today
 */
function maybeGetShowForCompregPayWithCashOrCheckDetails(invoice: Invoice, targetSeason: Season | undefined) : ShowPayWithCashOrCheckDetails {
  if (!targetSeason) {
    return {doShow: false, label: ""};
  }

  const compRegLineItems = maybeGetCompRegLineItems(invoice)

  if (!compRegLineItems) {
    return {doShow: false, label: ""};
  }

  assertTruthy(compRegLineItems.length > 0, "if we got an array we got a non-zero amount of elements");
  assertTruthy(compRegLineItems.every(v => !!v.entity), "all `entity` props are truthy");
  assertTruthy(new Set(compRegLineItems.map(v => v.entity!.childID)).size === 1, "all line items for same player");
  assertTruthy(new Set(compRegLineItems.map(v => v.entity!.seasonUID)).size === 1, "all line items for same season");

  const inPersonDate = dayjs(targetSeason.inPersonDate as string);
  if (!inPersonDate.isValid()) {
    // maybe an error case, does inPersonDate always represent a valid date?
    // could be cfnull?
    return {doShow: false, label: ""};
  }

  const today = dayjs();
  if (today.isSame(inPersonDate, "d")) {
    return {
      playerID: compRegLineItems[0].entity!.childID,
      seasonUID: compRegLineItems[0].entity!.seasonUID,
      doShow: true,
      kind: "compReg",
      compRegs: compRegLineItems.map(v => /*see earlier assertTruthy*/ v.entity!),
      label: "I will pay by cash or check"
    }
  }
  else {
    return {doShow: false, label: ""};
  }
}
</script>
