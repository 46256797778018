import { type Portlet, type PlayersWithUnmetBirthCertificateRequirementPortlet, type UpcomingGamesPortlet, type VolunteerStatusPortlet, DivisionStatsPortlet, TeamAssignmentsPortlet } from 'src/interfaces/InleagueApiV1'
import { assertTruthy } from 'src/helpers/utils'
import { rpc } from 'src/composables/Rpc'
import { AxiosInstance } from 'axios'

const getPortlets = rpc<void, Portlet[]>("get", "v1/portlet")

export function getMungedPortlets(ax: AxiosInstance) {
  return getPortlets(ax).then(mungePortlets)
}

export type PortletsByID = ReturnType<typeof mungePortlets>
function mungePortlets(portlets: Portlet[]) {
  type FIXME_UNKNOWN = unknown

  const portletsByType = {
    volStatus: null as VolunteerStatusPortlet | null,
    divisionStats: null as DivisionStatsPortlet | null,
    upcomingGames: null as UpcomingGamesPortlet | null,
    playerTeamAssignments: null as TeamAssignmentsPortlet | null,
    playersWithUnmetBirthCertificateRequirement: null as PlayersWithUnmetBirthCertificateRequirementPortlet | null,
    // there's no types for the following, but we guarantee that `null` means "not present"
    certifications: null as FIXME_UNKNOWN,
    upcomingEvents: null as FIXME_UNKNOWN,
    refereeAssignments: null as FIXME_UNKNOWN,
  }

  for (const portlet of portlets) {
    // all portlets are expected to be some non-null object type
    assertTruthy(!!portlet && typeof portlet === "object")

    switch (portlet.portletID) {
      case 'volunteerStatus':
        portletsByType.volStatus = portlet
        break;
      case 'divisionStats':
        portletsByType.divisionStats = portlet
        break
      case 'upcomingGames':
        portletsByType.upcomingGames = portlet
        break
      case 'teamAssignments':
        portletsByType.playerTeamAssignments = portlet
        break
      case 'playersWithUnmetBirthCertificateRequirement':
        portletsByType.playersWithUnmetBirthCertificateRequirement = portlet;
        break;
      case 'certifications':
        portletsByType.certifications = portlet
        break
      case 'upcomingEvents':
        portletsByType.upcomingEvents = portlet
        break
      case 'refAssignments':
        portletsByType.refereeAssignments = portlet
        break
      default:
        // unknown portlet type?
        // no-op, shouldn't hit this though
    }
  }

  return portletsByType
}
