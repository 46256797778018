import { PropType, defineComponent, onMounted, ref } from "vue";
import type { IziToast } from "izitoast";
import { axiosInstance, AxiosErrorWrapper, defaultLoggedInErrorResponseHandler_noToastOn404, defaultSetGlobalSingletonLoadingStateFlagInterceptors, freshAxiosInstance } from "src/boot/axios";
import { exhaustiveCaseGuard, useIziToast } from "src/helpers/utils";
import { dayjsOr } from "src/helpers/formatDate";
import { tournamentStore } from "./Store/TournamentStore"
import { Client } from "src/store/Client";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { Guid } from "src/interfaces/InleagueApiV1";
import { TournamentForm, freshTournamentForm, tournamentFormFromExisting, TournamentForm_Base } from "./TournamentHostingManager.form";

export const TournamentHostingManagerImpl = defineComponent({
  name: "TournamentHostingManagerImpl",
  props: {
    seasonUID: {
      required: true,
      type: null as any as PropType<Guid>
    },
    competitionUID: {
      required: true,
      type: null as any as PropType<Guid>
    }
  },
  setup(props) {

    const iziToast = useIziToast()

    type AsyncResolver =
      | {readonly ready: false}
      | {
        readonly ready: true,
        readonly partialCompetition: {competitionUID: Guid, competitionName: string},
        readonly partialSeason: {seasonUID: Guid, seasonName: string},
        /**
         * null in the case of "no such tournament, therefore we have no form to show; the user can initialize a form but hasn't done so yet"
         */
        readonly tournamentForm: TournamentForm | null
      }

    const asyncResolver = ref<AsyncResolver>({ready: false})

    const initFormForCreateTournament = async () : Promise<void> => {
      if (!asyncResolver.value.ready) {
        throw Error("illegal/impossible state");
      }

      try {
        const compSeasonDivs = await tournamentStore.getCompSeasonDivListing(axiosInstance, {seasonUID: props.seasonUID, competitionUID: props.competitionUID})
        const tournamentForm = freshTournamentForm({
            ...asyncResolver.value.partialCompetition,
            ...asyncResolver.value.partialSeason,
          },
          compSeasonDivs.value
        );

        asyncResolver.value = {
          ...asyncResolver.value,
          tournamentForm
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const handleSubmitTournamentInfo = async () => {
      if (!asyncResolver.value.ready || !asyncResolver.value.tournamentForm) {
        throw Error("illegal/impossible state -- shouldn't submit the form in this case");
      }

      const {kind, form} = asyncResolver.value.tournamentForm;

      if (kind.mode === "create") {
        const maybeOK = await GlobalInteractionBlockingRequestsInFlight.withSpinner(() =>
          tournamentStore.createTournament(axiosInstance, {
            competitionUID: kind.competitionUID,
            seasonUID: kind.seasonUID,
            ...commonSpreadables(form)
          })
        );

        if (!maybeOK.ok) {
          // ???? we expect to have rendered some error test via an axios error handler at this point
          return;
        }

        const compSeasonDivs = await tournamentStore.getCompSeasonDivListing(axiosInstance, {seasonUID: props.seasonUID, competitionUID: props.competitionUID})

        asyncResolver.value = {
          ...asyncResolver.value,
          tournamentForm: tournamentFormFromExisting(maybeOK.result.value, compSeasonDivs.value, /*wasFreshlyGeneratedThisSession*/true)
        }

        iziToast.success({message: "Tournament enabled."});
      }
      else if (kind.mode === "update") {
        const maybeOK = await GlobalInteractionBlockingRequestsInFlight.withSpinner(() =>
          tournamentStore.updateTournament(axiosInstance, {
            tournamentID: kind.tournamentID,
            ...commonSpreadables(form)
          })
        );

        if (!maybeOK.ok) {
          // ???
          return;
        }

        iziToast.success({message: "Changes saved."});
      }
      else {
        exhaustiveCaseGuard(kind);
      }

      function commonSpreadables(v: TournamentForm_Base["form"]) {
        return {
          registrationStart: dayjsOr(v.registrationStart)?.toISOString() ?? null,
          registrationEnd: dayjsOr(v.registrationEnd)?.toISOString() ?? null,
          requireRefsOnSubmission: v.requireRefsOnSubmission,
          minRequiredTournTeamRefCount: v.minRequiredTournTeamRefCount,
          localTeamsSubjectToRegFee: v.localTeamsSubjectToRegFee,
          localTeamsSubjectToHoldPaymentFee: v.localTeamsSubjectToHoldPaymentFee,
          html_tournTeamReg_splash_A: v.html_tournTeamReg_splash_A,
          html_tournTeamReg_complete: v.html_tournTeamReg_complete,
          html_tournTeamReg_regConfirmationEmail_header: v.html_tournTeamReg_regConfirmationEmail_header,
          html_tournTeamReg_regConfirmationEmail_footer: v.html_tournTeamReg_regConfirmationEmail_footer,
          html_tournament_notifyAddOfficial_header: v.html_tournament_notifyAddOfficial_header,
          html_tournament_notifyAddOfficial_footer: v.html_tournament_notifyAddOfficial_footer,
          contactEmail: v.contactEmail,
          playerRosterSubmissionConfig: v.playerRosterSubmissionConfig,
          teamAdminEditCutoff: v.teamAdminEditCutoff,
          achEnabled: v.achEnabled,
          requireAchPaymentWhenFeeMeetsOrExceeds: v.requireAchPaymentWhenFeeMeetsOrExceeds
        } as const;
      }
    }

    onMounted(async () => {
      // TODO: lift all these network hops up into parent (we're not an "route-root" component like R_xyz here), so we're more a pure function of state, and hot reload works better.
      const loggedInAxiosNoToastOn404 = freshLoggedInAxiosNoToastOn404(iziToast);
      const tournament = await tournamentStore.maybeGetTournamentIfExists(loggedInAxiosNoToastOn404, {competitionUID: props.competitionUID, seasonUID: props.seasonUID})

      if (tournament) {
        const compSeasonDivs = await tournamentStore.getCompSeasonDivListing(axiosInstance, {seasonUID: props.seasonUID, competitionUID: props.competitionUID})
        asyncResolver.value = {
          ready: true,
          partialCompetition: {competitionUID: tournament.value.competitionUID, competitionName: tournament.value.competitionName},
          partialSeason: {seasonUID: tournament.value.seasonUID, seasonName: tournament.value.seasonName},
          tournamentForm: tournamentFormFromExisting(tournament.value, compSeasonDivs.value),
        }
      }
      else {
        // no such tournament, we can offer the user to create it (yes, just one per comp/season) though
        const competitionName = (await Client.getCompetitionByUID( props.competitionUID ))?.competition ?? "Undefined Program";
        const seasonName = (await Client.getSeasonByUID( props.seasonUID ))?.seasonName ?? "Undefined Season";

        asyncResolver.value = {
          ready: true,
          partialCompetition: {competitionUID: props.competitionUID, competitionName: competitionName},
          partialSeason: {seasonUID: props.seasonUID, seasonName: seasonName},
          tournamentForm: null,
        }
      }
    })

    return () => {
      if (!asyncResolver.value.ready) {
        return null;
      }
      else {
        return (
          <div data-test="Tournament">
            {
              asyncResolver.value.tournamentForm === null
                ? (
                  <div class="flex flex-col items-center justify-center gap-4">
                    {asyncResolver.value.partialCompetition.competitionName} is not currently hosting a tournament for {asyncResolver.value.partialSeason.seasonName}.

                    <t-btn margin={false} onClick={initFormForCreateTournament} data-test="enable-tournament">Enable tournament</t-btn>
                  </div>
                )
                : <TournamentForm
                    onSubmitTournamentInfo={handleSubmitTournamentInfo}
                    kind={asyncResolver.value.tournamentForm.kind}
                    mut_form={asyncResolver.value.tournamentForm.form}
                    aux={asyncResolver.value.tournamentForm.aux}
                  />
            }
          </div>
        )
      }
    }
  }
})

function freshLoggedInAxiosNoToastOn404(iziToast: IziToast) {
  return freshAxiosInstance({
    useCurrentBearerToken: true,
    requestInterceptors: [defaultSetGlobalSingletonLoadingStateFlagInterceptors.request],
    responseInterceptors: [
      {
        ok: defaultSetGlobalSingletonLoadingStateFlagInterceptors.responseOK,
        error: defaultLoggedInErrorResponseHandler_noToastOn404(iziToast)
      }

    ]}
  )
}
