import { User } from "src/store/User"

export function directionsToPlayingFieldURL(field: {fieldStreet: string, fieldCity: string, fieldState: string, fieldZip: string}) {
  const userHomeAddress = User.value.userAddress
  const fieldAddress = `${field.fieldStreet} ${field.fieldCity} ${field.fieldState} ${field.fieldZip}`
  const params = [
    `api=1`,
    `origin=${encodeURIComponent(userHomeAddress)}`,
    `destination=${encodeURIComponent(fieldAddress)}`,
  ].join("&")

  return `https://www.google.com/maps/dir/?${params}`
}
