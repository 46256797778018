import { computed, reactive, Ref, ref, watch } from "vue";
import { ExpandedTournamentTeam } from "./TournamentTeamListing.utils";
import { EscapedRegExp } from "src/helpers/utils-backend";
import { Reflike, unsafe_objectKeys } from "src/helpers/utils";
import { Props as SelectManyProps, OnEmits as SelectManyEmits } from "../RefereeSchedule/SelectManyPane";
import { Integerlike } from "src/interfaces/InleagueApiV1";
import { TournamentTeam } from "src/composables/InleagueApiV1.Tournament";

export interface TournTeamDisplayFilter {
  name: string,
  status: Record<StatusFilterKey, boolean>
}

export enum StatusFilterKey {
  // these 4 "pending*" are intended to be orthogonal
  PENDING = "pending",
  PENDING_HAS_INVOICE = "peding-has-invoice",
  PENDING_HAS_INTENT_TO_PAY_BY_CHECK = "pending-has-intent-to-pay-by-check",
  PENDING_PAYMENT_PROCESSING = "pending-payment-processing",
  APPROVED = "approved",
  PAID_AWAITING_APPROVAL = "paid",
  DROPPED = "dropped",
}

export function TournTeamDisplayFilter() : TournTeamDisplayFilter {
  return {
    name: "",
    status: {
      [StatusFilterKey.PENDING]: true,
      [StatusFilterKey.PENDING_HAS_INVOICE]: true,
      [StatusFilterKey.PENDING_HAS_INTENT_TO_PAY_BY_CHECK]: true,
      [StatusFilterKey.PENDING_PAYMENT_PROCESSING]: true,
      [StatusFilterKey.APPROVED]: true,
      [StatusFilterKey.PAID_AWAITING_APPROVAL]: true,
      [StatusFilterKey.DROPPED]: false,
    }
  }
}

export function computeTournTeamStatusForFiltering(v: TournamentTeam) : StatusFilterKey | null {
  if (v.status === "PENDING" && v.awaitingAsyncPaymentCompletion) {
    return StatusFilterKey.PENDING_PAYMENT_PROCESSING
  }
  else if (v.status === "PENDING" && !v.invoiceInstanceID_registration) {
    return StatusFilterKey.PENDING
  }
  else if (v.status === "PENDING" && v.invoiceInstanceID_registration && !v.hasDeclaredIntentToPayByCheck) {
    return StatusFilterKey.PENDING_HAS_INVOICE
  }
  else if (v.status === "PENDING" && v.invoiceInstanceID_registration && v.hasDeclaredIntentToPayByCheck) {
    return StatusFilterKey.PENDING_HAS_INTENT_TO_PAY_BY_CHECK
  }
  else if (v.status === "PAID_AWAITING_APPROVAL") {
    return StatusFilterKey.PAID_AWAITING_APPROVAL
  }
  else if (v.status === "APPROVED") {
    return StatusFilterKey.APPROVED
  }
  else if (v.status === "DROPPED_BY_HOST" || v.status === "DROPPED_BY_SUBMITTER") {
    return StatusFilterKey.DROPPED
  }
  else {
    // should have been exhaustive but can't really be proved with the arbitrary `and`s
    return null;
  }
}

export function FilterManager<T extends TournamentTeam>(filterForm: Reflike<TournTeamDisplayFilter>, tournamentTeams: Reflike<readonly T[]>) {
  const appliedFilters = ref({...filterForm.value});

  watch(() => filterForm.value.name, () => {
    // we intended to support some indirection between the form and the committed filters
    // as in, you can type and then press enter to commit the filter. But we don't currently do that, and just
    // directly apply the forms changes to the applied filters.
    appliedFilters.value = {
      name: filterForm.value.name,
      status: appliedFilters.value.status
    }
  })

  const filteredTournTeams = computed(() => {
    return tournamentTeams
      .value
      .filter(filterName())
      .filter(filterStatus())

    function filterName() {
      const pattern = appliedFilters.value.name.trim() === "" ? null : EscapedRegExp(appliedFilters.value.name.trim(), "i");
      return !pattern
        ? (_: T) => true // no pattern means no filter, everything passes
        : (v: T) => pattern.test(v.areaTeam?.teamname || "") // this is nonsense if areaTeam isn't expanded on the provided tournteams
    }

    function filterStatus() {
      return (v: T) => {
        const status = computeTournTeamStatusForFiltering(v)

        if (status === null) {
          // shouldn't really happen
          return false;
        }

        return appliedFilters.value.status[status]
      }
    }
  })

  const selectManyPropsAndHandlers : SelectManyProps & Partial<SelectManyEmits> = reactive((() => {
    return {
      offerAllOption: true,
      selectedKeys: computed<string[]>(() => {
        const result : string[] = []
        for (const k of unsafe_objectKeys(appliedFilters.value.status)) {
          if (appliedFilters.value.status[k]) {
            result.push(k)
          }
        }
        return result
      }),
      options: [
          {label: "Incomplete", value: StatusFilterKey.PENDING},
          {label: "Complete but not yet paid", value: StatusFilterKey.PENDING_HAS_INVOICE},
          {label: "Complete but not yet paid (with intent to pay by check)", value: StatusFilterKey.PENDING_HAS_INTENT_TO_PAY_BY_CHECK},
          {label: "Payment processing", value: StatusFilterKey.PENDING_PAYMENT_PROCESSING},
          {label: "Paid (awaiting approval)", value: StatusFilterKey.PAID_AWAITING_APPROVAL},
          {label: "Approved", value: StatusFilterKey.APPROVED},
          {label: "Dropped", value: StatusFilterKey.DROPPED}
      ],
      onCheckedAll: isChecked => {
        unsafe_objectKeys(appliedFilters.value.status)
          .forEach(k => appliedFilters.value.status[k] = isChecked)
      },
      onCheckedOne: (key, isChecked) => {
        const uncheckedCast_key = key as StatusFilterKey
        appliedFilters.value.status[uncheckedCast_key] = isChecked
      }
    }
  })());

  return {
    get filteredTournTeams() : readonly T[] { return filteredTournTeams.value },
    selectManyPropsAndHandlers,
    get statusOptionsMessage() {
      if (selectManyPropsAndHandlers.selectedKeys.length === 1) {
        return "Status options (1 option selected)"
      }
      else {
        return `Status options (${selectManyPropsAndHandlers.selectedKeys.length} options selected)`
      }
    }
  }
}

export interface TournTeamsWithManagePerms {
  tournTeams: readonly ExpandedTournamentTeam[],
  manageTournTeamPerms: {[tournamentTeamID: Integerlike]: undefined | 0 | 1}
}
