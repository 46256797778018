import { FormKit } from "@formkit/vue";
import { defineComponent } from "vue";
import { Btn2 } from "../UserInterface/Btn2";
import { QuillWrapper3 } from "../UserInterface/QuillWrapper3";
import { accentAwareCaseInsenstiveSortMany, dollarFormat, nextGlobalIntlike, parseFloatOr, parseIntOr, Reflike, SetEx, sortBy, sortByMany, vReqT } from "src/helpers/utils";
import { Floatlike, Guid } from "src/interfaces/InleagueApiV1";
import { FkDynamicValidation } from "src/helpers/FkUtils";
import { InvoiceInstanceForInvoiceTemplate, PlayerishPayorCandidate } from "./InvoiceTemplates.io";
import { RouterLink } from "vue-router";
import { ReifiedPromise } from "src/helpers/ReifiedPromise";
import { ColDef } from "src/modules/TableUtils";

import * as R_MasterInvoice from 'src/components/Payment/pages/MasterInvoice.route'

export const InvoiceTemplateRecipientFormElem = defineComponent({
  props: {
    form: vReqT<InvoiceTemplateRecipientForm>(),
    discountConfig: vReqT<DiscountConfig>(),
  },
  setup(props) {
    const FK_NeedAtLeastOnPayorSelected = FkDynamicValidation(() => {
      return props.form.childIDs.size === 0
        ? {status: "invalid", msg: props.form.mode === "singlePlayer" ? "Select a player" : "Select at least one player"}
        : {status: "valid"}
    })

    return () => {
      return <div data-test="InvoiceTemplateRecipientFormElem">
        <p class="mt-2 text-sm">
          The invoice template instructions will be displayed on the billing page.
          You may include optional instructions specific to this recipient, and
          indicate whether an email with those instructions and a payment link should
          be sent immediately upon creation.
        </p>
        <div style="display: grid; grid-template-columns: 25% auto">
          <div style="display:contents;" data-note-rowlike>
            <div class="my-2">Email on creation</div>
            <div class="my-2">
              <div class="inline-flex">
                <FormKit type="checkbox" v-model={props.form.emailOnCreation}/>
              </div>
            </div>
          </div>

          <div style="display: contents;">
            <div>Discount</div>
            <div>
              <div>
                <label class="inline-flex items-start gap-2">
                  <input
                    type="checkbox"
                    class="disabled:bg-gray-200 rounded-lg"
                    style="margin-top: 4px;"
                    name="discount-type"
                    disabled={!props.discountConfig.allowDollar.ok}
                    checked={props.form.discount?.type === "dollar"}
                    onInput={() => {
                      if (props.form.discount?.type === "dollar") {
                        props.form.discount = null
                      }
                      else {
                        props.form.discount = {type: "dollar", value: ""}
                      }
                    }}
                    data-test="discountType-radio/dollar"
                  />
                  <p>
                    Dollar
                    {!props.discountConfig.allowDollar.ok
                      ? <div class="text-sm">{" "}{props.discountConfig.allowDollar.why}</div>
                      : null}
                  </p>
                </label>
              </div>
              <div>
                <label class="inline-flex items-start gap-2">
                  <input
                    type="checkbox"
                    name="discount-type"
                    class="disabled:bg-gray-200 rounded-lg"
                    style="margin-top: 3.5px;"
                    checked={props.form.discount?.type === "percent"}
                    disabled={!props.discountConfig.allowPercentage.ok}
                    onInput={() => {
                      if (props.form.discount?.type === "percent") {
                        props.form.discount = null
                      }
                      else {
                        props.form.discount = {type: "percent", value: ""}
                      }
                    }}
                    data-test="discountType-radio/percent"
                  />
                  <p>
                    Percentage
                    {!props.discountConfig.allowPercentage.ok
                      ? <div class="text-sm">{" "}{props.discountConfig.allowPercentage.why}</div>
                      : null}
                  </p>
                </label>
              </div>
              {props.form.discount
                ? <div class="mt-2">
                    {props.form.discount.type === "dollar"
                      ? <>
                        <div class="max-w-32 flex gap-2 items-start">
                          <span>$</span>
                          <FormKit type="number" step=".01" data-test="discount-input/dollar" v-model={props.form.discount.value} validation={[["required"], ["min", 0]]} validationLabel="Value"/>
                        </div>
                      </>
                      : <>
                        <div class="max-w-32 flex gap-2 items-start">
                          <span>%</span>
                          <FormKit type="number" step="1" data-test="discount-input/percent" v-model={props.form.discount.value} validation={[["required"], ["min", 0], ["max", 100]]} validationLabel="Value"/>
                        </div>
                        <div class="text-xs">From 0-100</div>
                      </>
                    }
                </div>
                : null
              }
            </div>
            <div class="mt-2" style="grid-column: -1/1;">
              <div>Unique recipient instructions</div>
              <QuillWrapper3 class="bg-white" data-test="invoiceDesc-quillInput" v-model={props.form.instanceDesc}/>
            </div>

            <div style="grid-column: -1/1;">
              <Btn2 type="submit" data-test="submit-button" class="mt-4 px-2 py-1">Apply Invoice to Selected {props.form.childIDs.size <= 1 ? "Player" : "Players"}</Btn2>
              <FK_NeedAtLeastOnPayorSelected/>
            </div>
          </div>
        </div>
      </div>
    }
  }
})

export type DiscountConfig = {
  allowDollar: {ok: boolean, why?: string}
  allowPercentage: {ok: boolean, why?: string}
}

export interface InvoiceTemplateRecipientForm {
  __vueKey: string,
  mode: "singlePlayer" | "bulk",
  /**
   * When mode=singlePlayer, forms are responsible for ensuring this always contains at most 1 childID;
   * in bulk mode, it may contain many childIDs.
   */
	childIDs: SetEx<Guid>,
	instanceDesc: string,
	emailOnCreation: boolean,
  discount: null | {
    type: "dollar" | "percent",
    value: "" | Floatlike
  }
}

export function freshInvoiceTemplateRecipientForm(mode: "singlePlayer" | "bulk") : InvoiceTemplateRecipientForm {
  return {
    __vueKey: nextGlobalIntlike(),
    mode,
    childIDs: new SetEx(),
    instanceDesc: "",
    emailOnCreation: false,
    discount: null,
  }
}

type PayorCandidateColDefNames = "select" | "name" | "parent1Name" | "parent1Email"
export function PayorCandidatesColDefs(
  mode: "singlePlayer" | "bulk",
  form: Reflike<InvoiceTemplateRecipientForm>,
  alreadyAssignedChildIDs: Reflike<ReifiedPromise<Set<Guid>>>
) : ColDef<PlayerishPayorCandidate, PayorCandidateColDefNames>[] {
  return [
    {
      id: "select",
      label: "Select",
      html: v => {
        const alreadyAssigned = alreadyAssignedChildIDs.value.status === "resolved" && alreadyAssignedChildIDs.value.data.has(v.childID)
        const assignmentNotYetKnowable = alreadyAssignedChildIDs.value.status !== "resolved"

        if (alreadyAssigned && form.value.childIDs.has(v.childID)) {
          // make sure the form does not contain "selections" for disabled options.
          // Eww, render side-effects. But, should stabilize after 1 turn of the event loop.
          // And this seems like the central place to do this.
          form.value.childIDs.delete(v.childID)
        }

        if (alreadyAssigned || assignmentNotYetKnowable) {
          return <div class="text-xs flex items-center gap-2">
            <input
              type={mode === "singlePlayer" ? "radio" : "checkbox"}
              disabled
              class="disabled:bg-gray-200"
            />
            {assignmentNotYetKnowable
              ? <span class="text-xs">(loading related invoice info...)</span>
              : <span>(already assigned)</span>
            }
          </div>
        }
        else {
          return <input
            type={mode === "singlePlayer" ? "radio" : "checkbox"}
            name="PayorCandidatesSelector"
            data-test={`payorCandiate/id=${v.childID}`}
            class="disabled:bg-gray-200"
            value={v.childID}
            checked={form.value.childIDs.has(v.childID)}
            onInput={() => form.value.childIDs.invert(v.childID)}
          />
        }
      },
    },
    {
      id: "name",
      label: "Player Name",
      html: v => `${v.playerFirstName} ${v.playerLastName}`,
      sort: accentAwareCaseInsenstiveSortMany(v => v.playerLastName, v => v.playerFirstName)
    },
    {
      id: "parent1Name",
      label: "Parent 1",
      html: v => `${v.parent1FirstName} ${v.parent1LastName}`,
      sort: accentAwareCaseInsenstiveSortMany(v => v.parent1LastName, v => v.parent1FirstName)
    },
    {
      id: "parent1Email",
      label: "Email",
      html: v => v.parent1Email ?? "",
      sort: accentAwareCaseInsenstiveSortMany(v => v.parent1Email)
    }
  ]
}

export function InvoiceColDefs() : ColDef<InvoiceInstanceForInvoiceTemplate>[] {
  const nbsp = "\u00A0"

  const divNum = (v: InvoiceInstanceForInvoiceTemplate) => {
    return v.entity.type === "player"
      ? parseIntOr(v.entity.data.registration?.division.divNum, Infinity)
      : -1
  }

  const divGender = (v: InvoiceInstanceForInvoiceTemplate) => {
    return v.entity.type === "player"
      ? v.entity.data.registration?.division.gender
      : -1
  }

  const defs : ColDef<InvoiceInstanceForInvoiceTemplate>[] = [
    {
      id: "firstname",
      label: `First${nbsp}Name`,
      html: row => row.entity.data.firstName,
      sort: sortBy(row => row.entity.data.firstName)
    },
    {
      id: "lastname",
      label: `Last${nbsp}Name`,
      html: row => row.entity.data.lastName,
      sort: sortBy(row => row.entity.data.lastName)
    },
    {
      id: "division",
      label: "Division",
      html: row => row.entity.type === "player" ? (row.entity.data.registration?.division.division || "") : "",
      sort: sortByMany(
        sortBy(divNum),
        sortBy(divGender)
      )
    },
    {
      id: "email",
      label: "Email",
      html: row => row.entity.type === "player" ? row.entity.data.parent1.email : row.entity.data.email,
      sort: sortBy(row => row.entity.type === "player" ? row.entity.data.parent1.email : row.entity.data.email)
    },
    {
      id: "transactions",
      label: "Transactions",
      // TODO: link to invoice page
      html: row => row.transactionCount,
      sort: sortBy(row => row.transactionCount),
    },
    {
      id: "status",
      label: "Status",
      html: row => row.status,
      sort: sortBy(row => row.status)
    },
    {
      id: "amountDue",
      label: `Amount${nbsp}Due`,
      html: row => "$" + dollarFormat(row.lineItemSum - row.transactionBalance),
      sort: sortBy(row => row.lineItemSum - row.transactionBalance),
    },
    {
      id: "amountPaid",
      label: `Amount${nbsp}Paid`,
      html: row => "$" + dollarFormat(row.transactionBalance),
      sort: sortBy(row => row.transactionBalance)
    },
    {
      id: "balance",
      label: "Balance",
      ...(() => {
        const f = (row: InvoiceInstanceForInvoiceTemplate) => {
          const amountLessDiscount = row.lineItemSum // this is "discount aware"
          const transactionSum = row.transactionBalance
          return Math.max(0, amountLessDiscount - transactionSum)
        }

        return {
          html: (row: InvoiceInstanceForInvoiceTemplate) => "$" + dollarFormat(f(row)),
          sort: sortBy(f)
        }
      })(),
    },
    {
      id: "invoiceNo",
      label: `Invoice${nbsp}#`,
      html: row => <RouterLink class="il-link" to={R_MasterInvoice.routeDetailToRoutePath({name: "master-invoice", invoiceID: row.invoiceInstanceID})}>{row.invoiceInstanceNum}</RouterLink>,
      xlsx: row => row.invoiceInstanceNum,
    },
    {
      id: "discount-percent",
      label: `Discount${nbsp}(%)`,
      html: row => row.discount?.type === "percent"
        ? (() => {
          const v = parseIntOr(row.discount.value, null)
          return v === null ? "" : `${v}%`
        })()
        : "",
      sort: sortBy(row => row.discount?.type === "percent" ? parseIntOr(row.discount.value, 0) : Infinity)
    },
    {
      id: "discount-absolute",
      label: `Discount${nbsp}($)`,
      html: row => row.discount?.type === "dollar"
        ? (() => {
          const v = parseFloatOr(row.discount.value, null)
          return v === null ? "" : `$${dollarFormat(v)}`
        })()
        : "",
      sort: sortBy(row => row.discount?.type === "dollar" ? parseFloatOr(row.discount.value, 0) : Infinity)
    },
  ]

  return defs.map((v) : ColDef<InvoiceInstanceForInvoiceTemplate> => {
    return {
      ...v,
      headerClass: "px-2 border",
    }
  })
}
