import { axiosInstance } from 'src/boot/axios'
import { defineComponent, onMounted, ref, computed, Fragment } from 'vue'
import authService from 'src/helpers/authService'
import { type PlayerSearchResult } from 'src/composables/InleagueApiV1'
import { Competition, Guid, Integerlike, WithDefinite } from 'src/interfaces/InleagueApiV1'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { arrayFindOrFail, assertNonNull, exhaustiveCaseGuard, fkKey_reactToChangeValueLikeNormal, requireNonNull, vReqT } from 'src/helpers/utils'
import { Eligibility, GetEligibleCompetitions2Response, OpenCompetition, getOpenCompetitions, getEligibleCompetitions2, getInvoiceInstanceIDsByCompUID } from './Competitions.shared'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { FormKit } from '@formkit/vue'
import { Btn2 } from 'src/components/UserInterface/Btn2'
import { RouterLink } from 'vue-router'
import * as R_Checkout from 'src/components/Payment/pages/Checkout.route'
import * as MasterInvoice from "src/components/Payment/pages/MasterInvoice.route"

export default defineComponent({
  name: 'Select Competition',
  props: {
    seasonUID: vReqT<Guid>(),
    playerID: vReqT<Guid>(),
    player: vReqT<PlayerSearchResult>(),
  },
  inheritAttrs: false,
  emits: {
    competitionsSelected: (_: {competitions: Competition[]}) => true
  },
  setup(props, context) {
    const competitions = ref<readonly Competition[]>([])
    const openCompetitions = ref<OpenCompetition[]>([])
    const seasonName = ref("")

    const eligibilityInfo = ref<WithDefinite<GetEligibleCompetitions2Response, "forSelection">>(/*definitely assigned in onMounted*/ null as any)
    const competitionEligibilityMap = computed(() => eligibilityInfo.value?.forSelection || {})
    const currentlyEligibleCompUIDs = computed(() => {
      return Object.entries(competitionEligibilityMap.value).filter(([_,v]) => v?.status === "eligible").map(([compUID, _]) => compUID);
    })

    // invoice by compUID for component's (child, season)
    const invoiceInstanceIdByCompetitionUID = ref<{[competitionUID: Guid]: Integerlike}>({})
    const selectedCompetitionUIDs = ref(new Set<Guid>())
    const ready = ref(false);

    const toggleCompetitionSelection = async (userClickedThis: OpenCompetition) : Promise<void> => {
      /**
       * We need to update selections, re-run eligibility based the new selection,
       * and then reupdate the selections based on the results of the revised eligibility
       * (e.g. there is a circular dependency between computing eligibility status and what the valid selected values are)
       * We assume this reaches a stable state after a single iteration of
       * (update selections based on user input -> update eligibility -> update selections based on eligibility)
       */

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        updateSelectedCompetitionsBasedOnUserInput();
        await updateEligibilityBasedOnCurrentSelection();
        updateSelectedCompetitionsBasedOnEligibility();
      })

      function updateSelectedCompetitionsBasedOnUserInput() : void {
        if (selectedCompetitionUIDs.value.has(userClickedThis.competitionUID)) {
          selectedCompetitionUIDs.value.delete(userClickedThis.competitionUID)
        }
        else {
          selectedCompetitionUIDs.value.add(userClickedThis.competitionUID)
        }
      }

      async function updateEligibilityBasedOnCurrentSelection() : Promise<void> {
        const data = await getEligibleCompetitions2(axiosInstance, {seasonUID: props.seasonUID, playerID: props.playerID, forSelection: {
          allOpenCompetitionUIDs: openCompetitions.value.map(v => v.competitionUID),
          selectedCompetitionUIDs: [...selectedCompetitionUIDs.value]
        }})

        eligibilityInfo.value = {
          ...data,
          forSelection: requireNonNull(data.forSelection)
        }
      }

      function updateSelectedCompetitionsBasedOnEligibility() : void {
        for (const [compUID, eligibility] of Object.entries(competitionEligibilityMap.value)) {
          if (eligibility?.status !== Eligibility.eligible) {
            selectedCompetitionUIDs.value.delete(compUID);
          }
        }
      }
    }

    /**
     * Some ineligible comps we want to show, but not all.
     * E.g. a player is not considered "eligible" for a program they are already registered in, but we'd like to show that they are
     * already registered. Other cases may exist.
     * TODO: move this to the backend, as part of `getEligibleCompetitions2`
     */
    const competitionUIDsIneligibleButVisible = computed<Set<Guid>>(() => {
      if (!competitions.value) {
        return new Set();
      }

      const ineligibleButVisible = new Set<Guid>();

      for (const {competitionUID: compUID} of openCompetitions.value) {
        const comp = arrayFindOrFail(competitions.value, v => v.competitionUID === compUID)
        const eligibility = competitionEligibilityMap.value[compUID]
        if (!eligibility) {
          continue
        }

        switch (eligibility.status) {
          case Eligibility.alreadyRegistered:
          case Eligibility.alreadyWaitlisted_deprecated:
          case Eligibility.alreadyWaitlistedPendingPayment:
            // not eligible but we do want to show "hey you're registered in this"
            ineligibleButVisible.add(compUID);
            continue;
          case Eligibility.eligible:
            // it's eligible, so don't need to add it to ineligibleButVisible
            continue;
          case Eligibility.ineligible: {
            if (comp.hideIfIneligible) {
              // Is there a some comp that is clickable which, when clicked, will make this comp eligible?
              // If so, we'll show this comp
              const willBeEligibileViaOneMoreSelection = eligibilityInfo
                  .value
                  .transitiveEligibility
                  .find(v => v.applicability_only_if_registered && competitionEligibilityMap.value[v.comp1UID]?.status !== "ineligible" && v.comp2UID === compUID)

              if (willBeEligibileViaOneMoreSelection) {
                ineligibleButVisible.add(compUID)
              }
              else {
                // don't add it, it's NOT eligible and it's NOT visible
              }
            }
            else {
              ineligibleButVisible.add(compUID)
            }
            continue;
          }
          case Eligibility.eligibleButNotOnThisRegistration:
            // fallthrough
          case Eligibility.existingRegistrationPaymentProcessing:
            ineligibleButVisible.add(compUID)
            continue;
          default: exhaustiveCaseGuard(eligibility.status)
        }
      }

      return ineligibleButVisible;
    });

    const doSubmitSelectedCompetitions = () : void => {
      const selectedComps : Competition[] = [...selectedCompetitionUIDs.value]
        .map(compUID => arrayFindOrFail(competitions.value, comp => comp.competitionUID === compUID))

      context.emit("competitionsSelected", {competitions: selectedComps})
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await onMountedPhase1();
        await onMountedPhase2();
        ready.value = true;
      })

      /**
       * n.b. order matters here, for DOM layout purposes.
       * Do phase1 first so we resolve the season name for display purposes, and then we await the rest of the await stmts
       * TODO: pass season in as a prop, like with player
       */
      async function onMountedPhase1() : Promise<void> {
        seasonName.value = (await Client.getSeasonByUID(props.seasonUID))?.seasonName ?? "";
      }

      async function onMountedPhase2() : Promise<void> {
        competitions.value = (await getCompetitionsOrFail()).value;
        openCompetitions.value = await getOpenCompetitions(
          axiosInstance, {
            seasonUID: props.seasonUID,
            openingWithin: authService(User.value.roles, 'webmaster', 'registrar')
              ? 14
              : ""
          }
        );

        {
          const data = await getEligibleCompetitions2(axiosInstance, {seasonUID: props.seasonUID, playerID: props.playerID, forSelection: {
            allOpenCompetitionUIDs: openCompetitions.value.map(v => v.competitionUID),
            selectedCompetitionUIDs: []
          }})

          eligibilityInfo.value = {
            ...data,
            forSelection: requireNonNull(data.forSelection)
          }
        }

        invoiceInstanceIdByCompetitionUID.value = getInvoiceInstanceIDsByCompUID(eligibilityInfo.value);
      }
    })

    return () => {
      return (
        <div>
          <div class="t-page flex flex-col md:mx-2">
            <h1 class="ml-2 text-2xl font-medium md:text-4xl">
              {seasonName.value} Registration: Choose a Program - {props.player.playerFirstName} {props.player.playerLastName}
              {
                authService(User.value.roles, "webmaster", "registrar")
                  ? <p class="italic text-lg mt-2 font-normal">*Users with registrar permissions are shown competitions opening for registration within two weeks.</p>
                  : null
              }
            </h1>
          </div>
          {
            ready.value
              ? (
                <div class="max-w-2xl">
                  {
                    currentlyEligibleCompUIDs.value.length > 0
                      ? (
                        <div class="my-6">
                          <Btn2
                            onClick={() => doSubmitSelectedCompetitions()}
                            class="p-2"
                            disabled={selectedCompetitionUIDs.value.size === 0}
                            v-tooltip={{content: selectedCompetitionUIDs.value.size === 0 ? 'Select at least 1 program' : undefined}}>
                              Proceed with registration
                          </Btn2>
                        </div>
                      )
                      : null
                  }
                  {
                    openCompetitions.value.length > 0
                      ? (
                        <div>
                          <div class="grid gap-6 mt-4" role="list" style="grid-template-columns: min-content 1fr;">
                            {
                              openCompetitions.value.map(comp => {
                                const eligibility = competitionEligibilityMap.value[comp.competitionUID]
                                assertNonNull(eligibility)
                                if (eligibility.status === "eligible") {
                                  return (
                                    <Fragment key={`eligible/${comp.competitionUID}`}>
                                      <div class="flex items-center">
                                        <FormKit
                                          key={fkKey_reactToChangeValueLikeNormal('competitionUID', selectedCompetitionUIDs.value.has(comp.competitionUID))}
                                          value={selectedCompetitionUIDs.value.has(comp.competitionUID)} type="checkbox"
                                          onInput={() => toggleCompetitionSelection(comp)} data-test={`checkbox/competitionUID=${comp.competitionUID}`}>
                                        </FormKit>
                                      </div>
                                      <div
                                        class="col-span-1 bg-white rounded-lg il-box-shadow-1 cursor-pointer"
                                        data-test={`enabled/competitionUID=${comp.competitionUID}`}
                                      >
                                        <div class="p-4" onClick={() => toggleCompetitionSelection(comp)}>
                                          <div class="w-full flex items-center justify-between space-x-6">
                                            <div class="flex-1 break-words">
                                              <div class="flex items-center space-x-3">
                                                <h3 class="text-gray-900 text-lg font-medium truncate">{comp.competition}</h3>
                                              </div>
                                              <p class="mt-1 text-gray-500 text-base break-words" v-html={comp.registrationDesc}></p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  )
                                }
                                else {
                                  if (competitionUIDsIneligibleButVisible.value.has(comp.competitionUID)) {
                                    return (
                                      <Fragment key={`ineligible/${comp.competitionUID}`}>
                                        <div>{/*grid cell placeholder*/}</div>
                                        <div class="col-span-1 bg-white rounded-lg"
                                          style="background-color: rgba(0, 0, 0, 0.0625); color: rgba(0, 0, 0, 0.5)"
                                          data-test={`disabled/competitionUID=${comp.competitionUID}`}
                                        >
                                          <div class="p-4">
                                            <div class="w-full flex items-center justify-between space-x-6">
                                              <div class="flex-1 break-words">
                                                <div class="flex items-center space-x-3">
                                                  <h3 class="text-gray-900 text-lg font-medium truncate" style="color: inherit">
                                                    {comp.competition}
                                                  </h3>
                                                </div>
                                                <p class="mt-1 text-gray-500 text-base break-words" v-html={comp.registrationDesc}></p>
                                                {
                                                  eligibility.status === Eligibility.alreadyRegistered
                                                    ? <div class="text-sm">(Player is already registered)</div>
                                                    : (eligibility.status === Eligibility.alreadyWaitlistedPendingPayment || eligibility.status === Eligibility.alreadyWaitlisted_deprecated)
                                                    ? (
                                                      <div>
                                                        <div class="text-sm">(Player is currently waitlisted)</div>
                                                        <div class="text-sm">
                                                          <RouterLink
                                                            class="il-link"
                                                            // We could be paranoid and check that we really do have an invoice ...
                                                            // but the contract is that a "waitlisted pending payment" compreg definitely has an invoice.
                                                            to={R_Checkout.routeDetailToRouteLocation({invoiceInstanceIDs: [invoiceInstanceIdByCompetitionUID.value[comp.competitionUID]]})}>
                                                              Payment Information
                                                          </RouterLink>
                                                        </div>
                                                      </div>
                                                    )
                                                    : eligibility.status === Eligibility.existingRegistrationPaymentProcessing
                                                    ? <div>
                                                      <div class="text-sm">(Player's current registration payment is processing)</div>
                                                      <div class="text-sm">
                                                        <RouterLink
                                                          class="il-link"
                                                          // We could be paranoid and check that we really do have an invoice ...
                                                          // but the contract is that a "payment processing" compreg definitely has an invoice
                                                          to={MasterInvoice.routeDetailToRoutePath({name: "master-invoice", invoiceID: invoiceInstanceIdByCompetitionUID.value[comp.competitionUID]})}>
                                                            Payment Information
                                                        </RouterLink>
                                                      </div>
                                                    </div>
                                                    : eligibility.status === Eligibility.eligibleButNotOnThisRegistration
                                                    ? <div class="text-sm">(Player is eligible, but a separate registration is required)</div>
                                                    : eligibility.status === Eligibility.ineligible
                                                    ? <div class="text-sm">(Player is ineligible)</div>
                                                    : exhaustiveCaseGuard(eligibility.status)

                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Fragment>
                                    )
                                  }
                                  else {
                                    return null;
                                  }
                                }
                              })
                            }
                          </div>
                          <div class="my-6">
                            <Btn2
                              onClick={() => doSubmitSelectedCompetitions()}
                              class="p-2"
                              disabled={selectedCompetitionUIDs.value.size === 0}
                              v-tooltip={{content: selectedCompetitionUIDs.value.size === 0 ? 'Select at least 1 program' : undefined}}
                              data-test="submit"
                            >
                              Proceed with registration
                            </Btn2>
                          </div>
                        </div>
                      )
                      : <div class="text-lg italic">Program Registration Currently Closed</div>
                  }

                </div>
              )
              : <div class="mt-4 text-lg text-center">Looking for open programs...</div>
          }
        </div>
      )
    }
  }
})
