import authService from "src/helpers/authService";
import { Guid } from "src/interfaces/InleagueApiV1";
import { User } from "src/store/User";

const allAllowedRoles = ['director', 'refScheduler', 'Areadirector', 'playerAdmin', 'registrar', 'scholarshipAdmin', 'DDReporter', 'compManager', 'BirthCertAdmin'] as const
const rolesRequiringDivConstraint = allAllowedRoles.filter((v) : boolean => v === "director" || v === "DDReporter");
const rolesAllowingUnconstrainedDivSearch = (() => {
  const set_requiresDivConstraint = new Set(rolesRequiringDivConstraint)
  return allAllowedRoles.filter(v => !set_requiresDivConstraint.has(v))
})();

export function hasSomeRoutePermission() {
  const hasSomeRole = authService(User.value.roles, ...allAllowedRoles);
  const isCompManagerWithPaymentAdmin = !!User.userData?.competitionsMemento.find(v => v.isPaymentAdmin)
  return hasSomeRole || isCompManagerWithPaymentAdmin
}

export function allowsUnconstrainedDivSearch() {
  return authService(User.value.roles, ...rolesAllowingUnconstrainedDivSearch);
}

export function requiresDivConstrainedSearch() {
  return authService(User.value.roles, ...rolesRequiringDivConstraint) && !authService(User.value.roles, ...rolesAllowingUnconstrainedDivSearch);
}

export function getAllowedDivisions() : "*" | Guid[] {
  if (allowsUnconstrainedDivSearch()) {
    return "*"
  }
  else {
    return User.userData?.divisionsMemento.map(div => div.divID) ?? []
  }
}
