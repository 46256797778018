import { GetPublicationDatesResponse } from "src/composables/InleagueApiV1.Game";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { exhaustiveCaseGuard, vReqT } from "src/helpers/utils";
import { defineComponent } from "vue";
import { SoccerBall } from "../SVGs";
import { Client } from "src/store/Client";
import { dayjsFormatOr } from "src/helpers/formatDate";

export const PublishedSchedulesDisplay = defineComponent({
  props: {
    p_publishedSchedules: vReqT<ReactiveReifiedPromise<GetPublicationDatesResponse>>(),
  },
  setup(props) {
    const publishFormat = "MMM D, YYYY";

    return () => {
      return (
        <div class="relative">
          <div>Published schedules</div>
          {
            (() => {
              switch (props.p_publishedSchedules.underlying.status) {
                case "idle": {
                  // parent shouldn't pass an idle one to us (not really type enforcable)
                  return null;
                }
                case "error": {
                  throw props.p_publishedSchedules.underlying.error;
                }
                case "pending": {
                  return <div class="w-full flex gap-2 justify-end items-center absolute top-0">
                    <SoccerBall width="1.5em" height="1.5em" timeForOneRotation="1.5s" color={Client.value.clientTheme.color}/>
                  </div>
                }
                case "resolved": {
                  const data = props.p_publishedSchedules.underlying.data;
                  return (
                    <div>
                      <div>{data.competition.competition} &ndash; {dayjsFormatOr(data.competition.publishDate, publishFormat, "No Default Date (Individual Divisions Only)")}</div>
                      {
                        data.divisions.length === 0
                          ? null
                          : (
                            <>
                              <div>Single Division Schedules Published Through:</div>
                              <div class="gap-2" style="display: flex; flex-wrap: wrap;; grid-template-columns: repeat(auto-fit, minmax(.25fr, 1fr))">
                                {
                                  data.divisions.map(div => <div class="p-1 underline">{div.gender}{div.divNum} &ndash; {dayjsFormatOr(div.publishDate, publishFormat)}</div>)
                                }
                              </div>
                            </>
                          )
                      }
                    </div>
                  )
                }
                default: {
                  exhaustiveCaseGuard(props.p_publishedSchedules.underlying);
                }
              }
            })()
          }
        </div>
      )
    }
  }
})
