import { defineComponent, onMounted, ref } from "vue";
import { createInvoiceTemplate, getInvoiceTemplate, getInvoiceTemplateSeasonOptions, InvoiceTemplatePaymentMethodAction_Create, InvoiceTemplatePaymentMethodAction_Delete, updateInvoiceTemplate, UpdateInvoiceTemplateArgs } from "./InvoiceTemplates.io";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { arrayFindIndexOrFail, exhaustiveCaseGuard, FK_nodeRef, forceCheckedIndexedAccess, isGuid, noAvailableOptions, parseFloatOrFail, parseIntOrFail, routerGetQueryParamAsStringOrNull, UiOptions, useIziToast } from "src/helpers/utils";
import { FormKit, FormKitMessages } from "@formkit/vue";
import { RouterLink, useRouter } from "vue-router";
import { PaymentGateway, Season } from "src/interfaces/InleagueApiV1";
import { axiosInstance } from "src/boot/AxiosInstances";
import { Client } from "src/store/Client";
import { getPaymentGateways } from "src/composables/InleagueApiV1";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { Btn2 } from "../UserInterface/Btn2";
import { withNoScroll } from "src/router/RouterScrollBehavior";
import { freshInvoiceTemplateForm, freshInvoiceTemplatePaymentMethodForm, InvoiceTemplateEditorFormElement, InvoiceTemplateForm, InvoiceTemplatePaymentMethodForm, InvoiceTemplatePaymentMethodForm_New } from "./InvoiceTemplateEditor.elems";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";

import * as R_InvoiceTemplateListing from "./InvoiceTemplateListing.route"
import * as R_InvoiceTemplatePayors from "./InvoiceTemplatePayors.route"

export default defineComponent({
  setup(props) {
    const router = useRouter()
    const form = ref<InvoiceTemplateForm>(/*definitely assigned in onMounted*/ null as any)
    const iziToast = useIziToast()

    const seasons = ref<Season[]>([])
    const paymentGateways = ref<PaymentGateway[]>([])

    const seasonOptions = ref<UiOptions>(noAvailableOptions("Loading..."))
    const paymentGatewayOptions = ref<UiOptions>(noAvailableOptions("Loading..."))

    const ready = ref(false)

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        seasons.value = await getInvoiceTemplateSeasonOptions(axiosInstance).then(v => v.seasons)
        seasonOptions.value = seasons.value.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: seasons.value.map(v => ({label: v.seasonName, value: v.seasonUID}))}

        paymentGateways.value = await getPaymentGateways(axiosInstance)
        paymentGatewayOptions.value = paymentGateways.value.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: paymentGateways.value.map(v => ({label: v.accountName, value: v.clientGatewayID}))}

        const invoiceTemplateID = routerGetQueryParamAsStringOrNull(router.currentRoute.value, kQueryInvoiceTemplateID)?.toUpperCase()
        const mode = (invoiceTemplateID && isGuid(invoiceTemplateID)) ? "edit" : "new"

        if (mode === "new") {
          const seasonUID = (() => {
            const seasonUID = routerGetQueryParamAsStringOrNull(router.currentRoute.value, "seasonUID")
            return seasonUID && seasonOptions.value.options.find(opt => opt.value === seasonUID)
              ? seasonUID
              : Client.value.instanceConfig.currentseasonuid
          })();

          form.value = freshInvoiceTemplateForm({
            mode: "new",
            initialSeasonUID: seasonUID,
            initialClientGatewayID: forceCheckedIndexedAccess(paymentGatewayOptions.value.options, 0)?.value ?? ""
          })
        }
        else {
          form.value = freshInvoiceTemplateForm({
            mode: "edit",
            original: await getInvoiceTemplate(axiosInstance, {invoiceTemplateID: invoiceTemplateID!}),
          })
        }

        ready.value = true
      })
    })

    const doSubmitForm = async () : Promise<void> => {
      try {
        if (form.value.mode === "new") {
          const fresh = await createInvoiceTemplate(axiosInstance, {
            contactEmail: form.value.contactEmail,
            invoiceLabel: form.value.invoiceLabel,
            invoiceDesc: form.value.invoiceDesc,
            emailEveryReceipt: form.value.emailEveryReceipt,
            archived: form.value.archived,
            paymentGatewayID: form.value.paymentGatewayID,
            seasonUID: form.value.seasonUID,
            amount: parseFloatOrFail(form.value.amount),
            paymentMethods: form
              .value
              .paymentMethods
              .filter(v => v.type === "new")
              .map(mungeNewPaymentMethod),
            allowedPaymentMethods: form.value.allowedPaymentMethods,
          });

          form.value = freshInvoiceTemplateForm({mode: "edit", original: fresh})

          iziToast.success({message: "Invoice template created."})
          await withNoScroll(async () => {
            await router.replace({...router.currentRoute.value, query: {
              [kQueryInvoiceTemplateID]: fresh.invoiceID
            }})
          })
        }
        else if (form.value.mode === "edit") {
          const fresh = await updateInvoiceTemplate(axiosInstance, {
            invoiceTemplateID: form.value.original.invoiceID,
            contactEmail: form.value.contactEmail,
            invoiceLabel: form.value.invoiceLabel,
            invoiceDesc: form.value.invoiceDesc,
            emailEveryReceipt: form.value.emailEveryReceipt,
            archived: form.value.archived,
            paymentGatewayID: form.value.paymentGatewayID,
            seasonUID: form.value.seasonUID,
            amount: parseFloatOrFail(form.value.amount),
            paymentMethods: form
              .value
              .paymentMethods
              .map((pm) : InvoiceTemplatePaymentMethodAction_Create | InvoiceTemplatePaymentMethodAction_Delete | null => {
                return pm.type === "new" ? mungeNewPaymentMethod(pm)
                  : pm.type === "existing" && pm.markedForDelete ? {action: "delete", paymentMethodID: pm.object.methodID}
                  : null
              })
              .filter(pm => pm !== null),
            allowedPaymentMethods: form.value.allowedPaymentMethods,
          } satisfies UpdateInvoiceTemplateArgs);

          form.value = freshInvoiceTemplateForm({mode: "edit", original: fresh})

          iziToast.success({message: "Invoice template updated."})
        }
        else {
          exhaustiveCaseGuard(form.value)
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }

      function mungeNewPaymentMethod(pm: InvoiceTemplatePaymentMethodForm_New) : InvoiceTemplatePaymentMethodAction_Create{
        return {
          action: "create",
          amount: parseFloatOrFail(pm.amount),
          intervalUnit: pm.intervalUnit,
          intervalLength: parseIntOrFail(pm.intervalLength),
          totalOccurrences: parseIntOrFail(pm.totalOccurrences),
          trialAmount: pm.trialAmount,
          trialOccurrences: pm.trialOccurrences,
        }
      }
    }

    const pushTentativePaymentMethod = () : void => {
      form.value.paymentMethods.push(freshInvoiceTemplatePaymentMethodForm({mode: "new"}))
    }

    const dropTentativePaymentMethod = (pm: InvoiceTemplatePaymentMethodForm) : void => {
      const idx = arrayFindIndexOrFail(form.value.paymentMethods, v => v === pm)
      form.value.paymentMethods.splice(idx, 1);
    }

    const fk_formRef = FK_nodeRef()

    return () => {
      if (!ready.value) {
        return null;
      }

      return <div style="--fk-margin-outer:none; --fk-padding-input: .35em; --fk-bg-input:white;">
        <div class="flex items-center gap-2">
          <RouterLink to={R_InvoiceTemplateListing.route({seasonUID: form.value.seasonUID})} class="inline-block mb-4">
            <Btn2 class="px-2 py-1 flex gap-1 items-center text-sm">
              <FontAwesomeIcon icon={faArrowLeft}/>
              <p>Back to Invoice Templates Home</p>
            </Btn2>
          </RouterLink>

          {form.value.mode === "edit"
            ? <RouterLink to={R_InvoiceTemplatePayors.route({invoiceTemplateID: form.value.original.invoiceID})} class="inline-block mb-4">
              <Btn2 class="px-2 py-1 flex gap-1 items-center text-sm">
                <p>Payor Information</p>
              </Btn2>
            </RouterLink>
            : null}

        </div>
        <FormKit type="form" actions={false} onSubmit={doSubmitForm} ref={fk_formRef}>
          <div>
            {form.value.mode === "new" ? "New Invoice Template" : "Edit Invoice Template"}
            <div class="text-sm">An invoice template is the administrative unit for a payment request that will be applied to one or more families.</div>
          </div>

          <div class="mt-2 p-2 rounded-md border bg-white" style="width:max-content;">
            <InvoiceTemplateEditorFormElement
              form={form.value}
              seasonOptions={seasonOptions.value}
              paymentGatewayOptions={paymentGatewayOptions.value}
              onPushTentativePaymentMethod={() => pushTentativePaymentMethod()}
              onDropTentativePaymentMethod={pm => dropTentativePaymentMethod(pm)}
            />

            <div>
              <Btn2 type="submit" style="justify-self:start;" class="px-2 py-1" data-test="submit">
                {form.value.mode === "new" ? "Create" : "Save Changes"}
              </Btn2>
              <div>
                <FormKitMessages node={fk_formRef.value?.node}/>
              </div>
            </div>
          </div>
        </FormKit>
      </div>
    }
  }
})

const kQueryInvoiceTemplateID = "invoiceTemplateID"
