// This module extends the global `dayjs` object. For historical reasons it is run as part of "quasar app boot files".
// Investigate: build some kind of subpackage that does this, and re-exports dayjs, so that we do not have to remember to do the following dance:
// ```
// import {dayjs} from "dayjs"
// import <<this file for side-effects that patchup the above import>>
// ```
// Instead we'd like to do something like `import {dayjs} from "il-dayjs"
// And somehow make `import {dayjs} from "dayjs"` illegal, by not having a direct dependency on "dayjs" but rather only on our own "il-dayjs" wrapper
import dayjs from 'dayjs'
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { boot } from 'quasar/wrappers'

export default boot(() => {
  // no-op
  // Just the side-effect of importing this module and running its top-level code is what matters.
  // The quasar "boot file" mechanism requires we export this, though.
  // INVESTIGATE: get rid of quasar boot files, import this file manually at app root entry

  // debugger;
})

dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
