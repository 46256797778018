import { assertNonNull, assertTruthy, exhaustiveCaseGuard, useIziToast, vReqT } from "src/helpers/utils";
import { Integerlike, Invoice, InvoiceLineItem } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, onMounted, ref, watch } from "vue";

import Checkout from "./Checkout.vue"
import { AttachTentativeStripePaymentMethodToInvoiceArgs, PayInvoiceArgs, attachTentativeStripePaymentMethodToInvoice, getPaymentScheduleBlurb, payInvoice } from "src/composables/InleagueApiV1.Invoice";
import { AxiosErrorWrapper, axiosInstance, freshAxiosInstance, freshNoToastLoggedInAxiosInstance } from "src/boot/axios";
import { GlobalInteractionBlockingRequestsInFlight, PayableInvoicesResolver } from "src/store/EventuallyPinia";

import { System } from "src/store/System";
import { LastStatus_t, LineItemSpecialization } from "src/interfaces/Store/checkout";

import { tournamentTeamStore } from "src/components/Tournaments/Store/TournTeamStore"
import { RouteLocationRaw, RouterLink, useRouter } from "vue-router";
import { maybeGetCompRegLineItems, maybeGetTournamentTeamRegLineItem } from "../PaymentTools.ilx";

import * as R_RegistrationComplete from "src/components/Registration/complete/R_RegistrationComplete.route"
import * as R_TournamentTeamCreate from "src/components/Tournaments/R_TournamentTeamCreate.route"
import * as R_MasterInvoice from "./MasterInvoice.route";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { propsDef } from "./Checkout.route";
import { isAxiosInleagueApiError } from "src/composables/InleagueApiV1";
import { redeemCoupon } from "src/composables/InleagueApiV1.Coupon";
import { isSubscriptionInvoice } from "../InvoiceUtils";
import * as RegistrationJourneyBreadcrumb from "src/components/Registration/RegistrationJourneyBreadcrumb"
import { CheckoutStore } from "src/store/CheckoutStore"
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { InvoiceTemplatePaymentMethod, getInvoiceTemplatePaymentMethods } from "src/components/InvoiceTemplates/InvoiceTemplates.io";

import * as R_ChooseInvoiceTemplatePaymentMethod from "src/components/InvoiceTemplates/ChooseInvoiceTemplatePaymentMethod.route"
import { isOldStyleInvoiceTemplateBasedInvoiceInstanceHavingZeroLineItems, oldStyleInvoiceLink } from "./Checkout.elems";

export default defineComponent({
  props: propsDef,
  setup(props) {
    const router = useRouter();


    const ready = ref(false);
    const selectedInvoiceInstanceID = ref<"" | Integerlike>()
    const paymentScheduleBlurbsByInvoiceInstanceID = ref<{[invoiceInstanceID: Integerlike]: string | undefined}>({})

    /**
     * Slightly kludgy "force make sure we re-render at particular points" when such re-rendering isn't outwardly guaranteed by
     * a major change to the invoice (e.g. its lastStatus)
     * Introduced with intent to force-remount the invoice in response to an update in price due to coupon redemption.
     */
    const renderVersionForThisComponentLifecycle = ref(0)
    const invoiceRenderKey = computed(() => `${selectedInvoiceInstanceID.value}/v=${renderVersionForThisComponentLifecycle.value}`)

    const doVoidInvoice = async (invoiceID: Integerlike) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        // TODO: handle gracefully (backend serve up voided invoices...?)
        await CheckoutStore.voidInvoice(invoiceID.toString());

        // reload with voided/deleted state
        await CheckoutStore.getInvoice({invoiceID: invoiceID.toString(), expand: true});

        if (props.invoiceInstanceIDs.length === 1) {
          return;
        }

        await selectNextInvoiceOrAdvanceRoute();
      })
    }

    const doAttachTentativeStripePaymentMethodToInvoice = async (args: AttachTentativeStripePaymentMethodToInvoiceArgs) : Promise<{ok: true} | {ok: false, message: string}> => {
      return await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          const noToastLoggedInAx = freshAxiosInstance({useCurrentBearerToken: true})
          await attachTentativeStripePaymentMethodToInvoice(noToastLoggedInAx, args)
          await CheckoutStore
          .getInvoice({
            invoiceID: args.instanceID.toString(),
            expand: true
          });
          await selectNextInvoiceOrAdvanceRoute();
          return {ok: true}
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
          const message = isAxiosInleagueApiError(err) ? err.response.data.messages[0] : "Sorry, something went wrong."
          iziToast.error({message, timeout: false})
          return {ok: false, message}
        }
      });
    }

    const iziToast = useIziToast();

    const doPayInvoice = async (args: PayInvoiceArgs) : Promise<{ok: true} | {ok: false, message: string}> => {
      try {
        System.directCommit_setPaymentProcessing(true) // this is expected to set up a global interaction blocking spinner

        // this is correct even though the payment succeeds asynchronously, because the payment
        // should be synchronously marked at least "in flight", if not already "paid" (by some magic of a zero-fee invoice
        // or similar). If the payment immediately failed, we shouldn't get here, right? Worst case is have to reload
        // the page to re-retrieve "payable invoices", which doesn't seem like a big deal.
        PayableInvoicesResolver.removeInvoice(args.instanceID);

        // oof, punt in the tourn team store case; we need to the status of the associated tourn team, but that's done asynchronously,
        // based on results of polling the backend for stripe webhook arrivals ... so, just invalidate all the tournteam things
        // we could probably clear less, or do a targeted update at the appropriate time, or ....
        // Primary goal here is that when we nav to the "tourn team listing" after paying here, we want it to reflect any updates.
        tournamentTeamStore.clear();

        await payInvoice(freshAxiosInstance({useCurrentBearerToken: true}), args)
        await pollForInvoicePaymentCompletion(args.instanceID);
        await selectNextInvoiceOrAdvanceRoute();

        // here, the invoice payment succeed, but the backend may or may not have completed its
        // work with stripe; that is, we may have a "lastStatus" of either "in flight" or "paid and processed"
        return {ok: true};
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        const message = isAxiosInleagueApiError(err)
          ? err.response.data.messages[0]
          : "Sorry, something went wrong";

        iziToast.error({message, timeout: false})

        return {ok: false, message}
      }
      finally {
        System.directCommit_setPaymentProcessing(false)
      }
    }

    const selectNextInvoiceOrAdvanceRoute = async () => {
      for (const invoiceID of props.invoiceInstanceIDs) {
        if (!isInvoiceConsideredDealtWith(invoiceID)) {
          selectedInvoiceInstanceID.value = invoiceID;
          window.scrollTo(0,0);
          return;
        }
      }

      const invoices = props.invoiceInstanceIDs.map(invoiceID => CheckoutStore.value.invoice[invoiceID])

      assertTruthy(invoices.every(v => !!v), "we should find all of the invoices in the store");

      const nextRoute = tryGetParamsForNextRoute(invoices)
      if (nextRoute) {
        await router.push(nextRoute)
      }
      else {
        // couldn't figure out where to go
      }
    }

    const isInvoiceConsideredDealtWith = (instanceID: Integerlike) : boolean => {
      const invoice = CheckoutStore.value.invoice[instanceID];
      if (!invoice) {
        return false;
      }

      if (invoice.lineItems.some(v => v.paymentBlock_isBlocked)) {
        // a blocked invoice is considered complete if it has an associated paymentMethodID
        return !!invoice.stripe_paymentMethodID
      }
      else {
        switch (invoice.lastStatus) {
          case LastStatus_t.NULLISH:
          case LastStatus_t.CREATED:
            return false;
          case LastStatus_t.PROCESSING_ACH:
          case LastStatus_t.DELETED:
          case LastStatus_t.PAID_AND_PROCESSED:
          case LastStatus_t.REFUNDED:
          case LastStatus_t.IN_FLIGHT:
          case LastStatus_t.PAYMENT_REJECTED:
          case LastStatus_t.VOIDED:
          case LastStatus_t.PAID_OUT_OF_BAND:
            return true;
          case LastStatus_t.STRIPE_REQUIRES_ACTION:
            // REQUIRES_ACTION is a little funky here.
            // Currently we only expect to see the "verify_with_microdeposits" case,
            // we're "done", pending the user verifying their microdeposits.
            // Because this can take a few days, we consider us "done" here, for now,
            // and can move onto the "you're all done" screen, but that screen
            // will need to indicate something about this status.
            return true
          default: exhaustiveCaseGuard(invoice.lastStatus);
        }
      }
    }

    const doApplyCoupon = async (args: {invoiceInstanceID: Integerlike, couponCode: string}) : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          await redeemCoupon(axiosInstance, {invoiceID: args.invoiceInstanceID, couponCode: args.couponCode});
          // reload for update pricing
          await CheckoutStore.getInvoice({invoiceID: args.invoiceInstanceID.toString(), expand: true});

          const invoice = CheckoutStore.value.invoice[args.invoiceInstanceID];
          assertTruthy(invoice, "side effect of store call")

          if (isSubscriptionInvoice(invoice)) {
            paymentScheduleBlurbsByInvoiceInstanceID.value[invoice.instanceID] = await getPaymentScheduleBlurb(axiosInstance, {invoiceInstanceID: invoice.instanceID});
          }

          // force re-mount to rebuild any non-reactive 3rd party payment DOM things (e.g. paymentRequest API stuff for google/apple pay)
          renderVersionForThisComponentLifecycle.value += 1;
        } catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      })
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        for (const invoiceID of props.invoiceInstanceIDs) {
          try {
            await CheckoutStore
              .getInvoice({
                invoiceID: invoiceID.toString(),
                expand: true
              });

            const invoice = CheckoutStore.value.invoice[invoiceID]
            assertTruthy(invoice, "side effect of getInvoice()");

            if (isSubscriptionInvoice(invoice)) {
              paymentScheduleBlurbsByInvoiceInstanceID.value[invoiceID] = await getPaymentScheduleBlurb(axiosInstance, {invoiceInstanceID: invoiceID});
            }
          }
          catch (err) {
            // To be clear, we don't currently support failures to resolve some of the invoices but not the others.
            throw err;
          }
        }
      });

      selectedInvoiceInstanceID.value = props.invoiceInstanceIDs[0]

      ready.value = true;
    })

    const compregBreadcrumbProps = computed<RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElementProps | null>(() => {
      if (!selectedInvoiceInstanceID.value) {
        return null
      }

      const invoice = maybeGetInvoice(selectedInvoiceInstanceID.value)
      if (!invoice) {
        return null
      }

      const isCompRegLineItem = (v: InvoiceLineItem) : v is LineItemSpecialization<"qCompetitionRegistration"> => v.entity_type === "qCompetitionRegistration";
      const compRegLineItems = invoice.lineItems.filter(isCompRegLineItem)
      if (compRegLineItems.length === 0) {
        return null;
      }

      const competitionUIDs = compRegLineItems.map(v => {
        assertNonNull(v.entity, "invoice line items are expected to have 'definitely expanded' their associated entity")
        return v.entity.competitionUID
      });

      const {seasonUID, playerID} = (() => {
        // At this time, all compreg invoices will be for a single registration, meaning all line items may be for different competitions,
        // but all will share the same same playerID and seasonUID.
        const v = compRegLineItems[0]
        assertNonNull(v.entity, "invoice line items are expected to have 'definitely expanded' their associated entity")
        const seasonUID = v.entity.seasonUID
        const playerID = v.entity.childID
        return {seasonUID, playerID}
      })();

      return {
        detail: {
          step: RegistrationJourneyBreadcrumb.Step.reviewAndPay,
          competitionUIDs,
          seasonUID,
          playerID
        }
      }
    })

    // There will be times when the store is cleared out from under us
    // It can be deleted and then quickly restored
    // (in the case of a "void invoice, delete from store, ok now reload because we need to display it in its voided state"),
    // TODO: add undefined to checkout.invoice
    // TODO: don't store invoices in global store, tighten data ownership/lifecycle
    const maybeGetInvoice = (instanceID: Integerlike) : Invoice | null => {
      return CheckoutStore.value.invoice[instanceID] ?? null;
    }

    const MaybeChangeInvoiceTemplateBasedInvoiceInstancePaymentScheduleLink = defineComponent({
      props: {
        invoice: vReqT<Invoice>(),
      },
      setup(props) {
        const invoiceTemplateID = computed(() => {
          switch (props.invoice.lastStatus) {
            case LastStatus_t.CREATED:
            case LastStatus_t.NULLISH:
            case LastStatus_t.PAYMENT_REJECTED: {
              const inv = props.invoice
              // n.b. only considers "invoice-template based dummy line item invoice instances"
              if (inv.invoiceID && inv.lineItems.length === 1 && inv.lineItems[0].entity_type === "invoiceTemplateDummyLine") {
                return inv.invoiceID
              }
              else {
                return null
              }
            }
            default: return null
          }
        });

        const paymentMethodsResolver = ReactiveReifiedPromise<InvoiceTemplatePaymentMethod[]>()
        const needsLink = computed(() => {
          const p = paymentMethodsResolver.underlying
          switch (p.status) {
            case "resolved": {
              // if there are payment method options available, we want to show the link
              return p.data.length > 0
            }
            default: return false;
          }
        })

        watch(() => invoiceTemplateID.value, () => {
          if (invoiceTemplateID.value) {
            const v = invoiceTemplateID.value
            paymentMethodsResolver.run(() => getInvoiceTemplatePaymentMethods(freshNoToastLoggedInAxiosInstance(), {
              invoiceInstanceID: props.invoice.instanceID,
              invoiceTemplateID: v
            }));
          }
        }, {immediate: true})

        return () => {
          if (!needsLink.value) {
            return null
          }

          // we might want to do this for compreg invoices, too, but we will need to change
          // where we send the user for that, it is a separate api endpoint (maybe we can make them the same?)
          return <RouterLink
            class="inline-block il-link"
            to={R_ChooseInvoiceTemplatePaymentMethod.route({invoiceInstanceID: props.invoice.instanceID})}
          >
            Change payment schedule
          </RouterLink>
        }
      }
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        <div>
          {
            compregBreadcrumbProps.value
              ? (
                <div class="mb-6">
                  <RegistrationJourneyBreadcrumb.RegistrationJourneyBreadcrumbElement {...compregBreadcrumbProps.value}/>
                </div>
              )
              : null
          }
          {
            props.invoiceInstanceIDs.length > 1
              ? (
                <div>
                  <div>There are multiple invoices associated with this checkout:</div>
                  {
                    props.invoiceInstanceIDs.map(id => {
                      return <div class="flex items-center gap-2">
                        <input type="radio" name="invoiceID" value={id} checked={selectedInvoiceInstanceID.value === id} v-model={selectedInvoiceInstanceID.value}/>
                        <span>
                          {
                            isInvoiceConsideredDealtWith(id)
                              ? <span style="color: rgb(0,128,0);"><FontAwesomeIcon icon={faCheck}/></span>
                              : null
                          }
                        </span>
                        <span>{maybeGetInvoice(id)?.instanceLabel}</span>
                      </div>
                    })
                  }
                </div>
              )
              : null
          }
          <div key={invoiceRenderKey.value} class="relative p-2 rounded-md my-6 bg-white" style="box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);">
            {
              (() => {
                if (!selectedInvoiceInstanceID.value) {
                  // shouldn't happen
                  return null;
                }

                const invoice = maybeGetInvoice(selectedInvoiceInstanceID.value)

                if (!invoice) {
                  return null;
                }

                if (isOldStyleInvoiceTemplateBasedInvoiceInstanceHavingZeroLineItems(invoice)) {
                  return <div data-test="oldStyleInvoiceLink">
                    <p>
                      This invoice was generated using the legacy platform. It can be paid via the legacy platform by {" "}
                      <a class="il-link" target="_blank" href={oldStyleInvoiceLink()}>clicking here.</a>
                    </p>
                  </div>
                }

                switch (invoice.lastStatus) {
                  case LastStatus_t.NULLISH:
                  case LastStatus_t.CREATED:
                  case LastStatus_t.PAYMENT_REJECTED:
                  case LastStatus_t.STRIPE_REQUIRES_ACTION:
                    return <div>
                      <MaybeChangeInvoiceTemplateBasedInvoiceInstancePaymentScheduleLink class="ml-4 mb-4" invoice={invoice}/>
                      <Checkout
                        invoiceInstanceID={selectedInvoiceInstanceID.value}
                        paymentScheduleBlurb={paymentScheduleBlurbsByInvoiceInstanceID.value[invoice.instanceID]}
                        onVoidInvoice={() => doVoidInvoice(invoice.instanceID)}
                        payInvoice={doPayInvoice}
                        attachTentativeStripePaymentMethodToInvoice={doAttachTentativeStripePaymentMethodToInvoice}
                        doApplyCoupon={doApplyCoupon}
                      />
                    </div>
                  case LastStatus_t.IN_FLIGHT:
                    return <div class="p-6 flex items-center justify-center">Payment processing.</div>
                  case LastStatus_t.PROCESSING_ACH:
                    return <div class="p-6 flex items-center justify-center">Payment processing.</div>
                  case LastStatus_t.PAID_AND_PROCESSED:
                    return <div class="p-6 flex items-center justify-center">Invoice already paid.</div>
                  case LastStatus_t.PAID_OUT_OF_BAND:
                    return <div class="p-6 flex items-center justify-center">Invoice already paid.</div>
                  case LastStatus_t.REFUNDED:
                    return <div class="p-6 flex items-center justify-center">Invoice has been refunded in whole or in part.</div>
                  case LastStatus_t.VOIDED:
                    return <div class="p-6 flex items-center justify-center">Invoice was voided.</div>
                  case LastStatus_t.DELETED:
                    return <div class="p-6 flex items-center justify-center">Invoice was voided.</div>
                  default: exhaustiveCaseGuard(invoice.lastStatus)
                }
              })()
            }
            {
              selectedInvoiceInstanceID.value
                ? <div class="text-xs text-gray-300">Invoice #{selectedInvoiceInstanceID.value}</div>
                : null
            }
          </div>
        </div>
      )
    }
  }
})

async function pollForInvoicePaymentCompletion(invoiceID: Integerlike) : Promise<Invoice> {
  // lazy getter because `invoice` will be reassigned rather than mutated in-store
  const storeInvoices = () => CheckoutStore.value.invoice

  const maxIters = 10;
  let currentIter = 0;

  await CheckoutStore.getInvoice({invoiceID: invoiceID.toString(), expand: true})

  assertTruthy(storeInvoices()[invoiceID], "expected side effect of store.getInvoice");

  while (currentIter < maxIters) {
    switch (storeInvoices()[invoiceID].lastStatus) {
      case LastStatus_t.NULLISH:
      case LastStatus_t.CREATED:
      case LastStatus_t.IN_FLIGHT: {
        await new Promise(resolve => setTimeout(resolve, 3000));
        await CheckoutStore.getInvoice({invoiceID: invoiceID.toString(), expand: true})
        currentIter += 1;
        continue;
      }
      default: {
        return storeInvoices()[invoiceID];
      }
    }
  }

  return storeInvoices()[invoiceID]
}

const tryGetParamsForNextRoute = (invoiceInstances: Invoice[]) : RouteLocationRaw | null => {
  const completed = invoiceInstances.filter(inv => {
    switch (inv.lastStatus) {
      case LastStatus_t.NULLISH:
      case LastStatus_t.CREATED:
        const isAllCompRegAndAllAreWaitlisted = inv
          .lineItems
          // typical "donations don't count" filter
          .filter(li => li.entity_type != "qDonation" && li.entity_type !== "qDonation_PartialityShim")
          .every(li => li.entity_type === "qCompetitionRegistration" && li.paymentBlock_isBlocked)
        return isAllCompRegAndAllAreWaitlisted && inv.stripe_paymentMethodID
      case LastStatus_t.IN_FLIGHT:
      case LastStatus_t.PAID_AND_PROCESSED:
      case LastStatus_t.PAID_OUT_OF_BAND:
      case LastStatus_t.STRIPE_REQUIRES_ACTION:
      case LastStatus_t.PROCESSING_ACH:
        return true;
      case LastStatus_t.PAYMENT_REJECTED:
      case LastStatus_t.REFUNDED:
      case LastStatus_t.VOIDED:
      case LastStatus_t.DELETED:
        return false;
      default: exhaustiveCaseGuard(inv.lastStatus);
    }
  });

  if (completed.length === 0) {
    // could be 1+ voided invoices
    return null;
  }

  const truthy = <T,>(v: T | null) : v is T => !!v
  const maybeCompRegLineItemsPerInvoice = completed.map(v => maybeGetCompRegLineItems(v)).filter(truthy)
  const tournTeamRegLineItemPerInvoice = completed.map(v => maybeGetTournamentTeamRegLineItem(v)).filter(truthy);

  if (maybeCompRegLineItemsPerInvoice.length > 0) {
    // Sanity check that each compreg invoice contains line items all for the same (child, season).
    // Note that this does NOT check for consistency across invoices, in the case where we have more than 1 invoice.
    // In the case of more than 1 invoice, we do assume that they are all for the same (child, season), but this is driven by
    // URL query params which are user writeable, so it's possible that it might not hold true.
    // Using our assumption that in the compreg case all invoices AND all line items are for the same (child, season),
    // we can navigate to the "registration complete" route using any arbitrary invoice to source the (child, season) params.
    const sanityCheck = (lineItems: typeof maybeCompRegLineItemsPerInvoice[number]) => {
      assertTruthy(lineItems.length > 0, "if the array was truthy, we got at least 1 element");
      assertTruthy(lineItems.every(v => v.entity), "lineitems.entity must have been expanded at this point");
      assertTruthy(new Set(lineItems.map(v => v.entity!.childID)).size === 1, "all line items are for same child");
      assertTruthy(new Set(lineItems.map(v => v.entity!.seasonUID)).size === 1, "all line items are for same season");
    }

    maybeCompRegLineItemsPerInvoice.forEach(sanityCheck);

    return R_RegistrationComplete.routeDetailToRouteLocation({
      playerID: maybeCompRegLineItemsPerInvoice[0][0].entity!.childID,
      seasonUID: maybeCompRegLineItemsPerInvoice[0][0].entity!.seasonUID,
      // defensive paranoiac enforced uniqueness
      competitionUIDs: [...new Set(maybeCompRegLineItemsPerInvoice.flatMap(v => v.map(v => v.entity!.competitionUID)))],
      // defensive paranoiac enforced uniqueness
      invoiceInstanceIDs: [...new Set(maybeCompRegLineItemsPerInvoice.map(v => v[0].instanceID))],
    })
  }
  else if (tournTeamRegLineItemPerInvoice.length === 1) {
    // we only expect to support the "just one invoice" case here
    return R_TournamentTeamCreate.routeDetailToRouteLocation({
      name: R_TournamentTeamCreate.RouteNames.Complete,
      tournamentTeamID: tournTeamRegLineItemPerInvoice[0].tournamentTeamID_teamReg
    })
  }
  else {
    return R_MasterInvoice.routeDetailToRoutePath({
      name: "master-invoice",
      // do we want to support "multiple" invoices here?
      // when do we trigger this? When we do trigger it, do we have more than 1 invoice?
      //  - can get here for event invoices, those are currently 1-invoice-at-a-time
      invoiceID: invoiceInstances[0].instanceID,
    });
  }
}
