import authService from "src/helpers/authService";
import { User } from "src/store/User";

export const UserEditorAuthZ = {
  "permissions": () => authService(User.userData?.roles ?? [], 'webmaster','registrar','scholarshipAdmin'),
  "coach-data": () => {
    const roleOK = authService(User.userData?.roles ?? [], 'volunteerAdmin', 'registrar', 'Board', 'webmaster')
    const isRelevantCompManager = !!User.userData?.competitionsMemento.find(v => v.isVolunteerAdmin)
    const isRelevantDivManager = !!User.userData?.divisionsMemento.find(v => v.writeAccess)
    return roleOK || isRelevantCompManager || isRelevantDivManager
  },
  "merge-user": () => authService(User.userData?.roles ?? [], 'webmaster','registrar'),
  "scholarships": () => authService(User.userData?.roles ?? [], 'registrar', 'webmaster', 'scholarshipAdmin'),
  "volunteer-points": () => authService(User.userData?.roles ?? [], 'volunteerAdmin', 'registrar', 'webmaster', 'refScheduler'),
  "custom-fields": () => authService(User.userData?.roles ?? [], 'registrar', 'webmaster', 'Division Coordinator'),
} as const satisfies Record<string, () => boolean>;
