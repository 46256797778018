import type { AxiosInstance } from "axios";
import { k_DatetimeLike, k_GUID } from "src/boot/TypeBoxSetup";
import { PlayerForTeamAssignmentView } from "src/composables/InleagueApiV1.Teams";
import { checkedObjectEntries, zipExact, checkedObjectValues, copyViaJsonRoundTrip, unsafe_objectKeys, nextGlobalIntlike, assertIs, arrayFindOrFail } from "src/helpers/utils";
import { Guid, Competition } from "src/interfaces/InleagueApiV1";
import { PlayerForTeamAssignmentViewEx, ResolvedSelection, disableAssignOrLoanDueToMissingBirthCert } from "./TeamAssignments.shared";
import { Optional } from "src/helpers/utils"

import * as t from "@sinclair/typebox"
import dayjs from "dayjs";
import { DAYJS_FORMAT_IL_API_LOCALDATETIME } from "src/helpers/formatDate";

export type {
  Snapshot,
}

export {
  createSnapshot,
  loadSnapshot,
  snapshotSchema,
}

const loadSnapshot = async (ax: AxiosInstance, _: {
  snapshot: Snapshot
  seasonUID: Guid,
  competition: Competition,
  divID: Guid,
  unassignedPool: ResolvedSelection["assignments"]["unassignedPool"],
  currentAssignments: ResolvedSelection["assignments"]["byTeam"],
  currentLoans: ResolvedSelection["loans"]["byTeam"]
}) => {
  const {snapshot, seasonUID, competition, divID, unassignedPool, currentAssignments, currentLoans} = _;
  const competitionUID = competition.competitionUID

  const warnings : string[] = []

  if (snapshot.seasonUID !== seasonUID || snapshot.competitionUID !== competitionUID || snapshot.divID !== divID) {
    warnings.push("Snapshot season/competition/division does not match current selected season/competition/division. Players in the snapshot might not be found.")
  }

  const mungedSnapshot = [
    ...checkedObjectEntries(snapshot.assignments).flatMap(([teamID, team]) => team.data.map(vx => ({raw: vx, teamID, teamName: team.name, type: "assignment" as const, playerID: vx.playerID}))),
    ...checkedObjectEntries(snapshot.loans).flatMap(([teamID, team]) => team.data.map(vx => ({raw: vx, teamID, teamName: team.name, type: "loan" as const, playerID: vx.playerID})))
  ]

  const playersFromServer = await getCheckedSnapshotResult(ax, {seasonUID, competitionUID, divID, playerIDs: mungedSnapshot.map(v => v.playerID)});
  const zipped = zipExact(mungedSnapshot, playersFromServer).map(v => ({fromLocalSnapshot: v[0], fromServer: v[1]}))

  const freshAssignments : ResolvedSelection["assignments"]["byTeam"] = {}
  const freshLoans : ResolvedSelection["loans"]["byTeam"] = {}

  // Copy all unassigned and already-assigned players into "unassigned",
  // and drop them as we assign them. We expect that this means we have collected all potentially registerable players
  // for the current view config (all teams for the displayed season/comp/div).
  // The goal is to end up with a list of players, who weren't part of the snapshot, left in the unassigned mapping.
  // Make sure to make deep copies of data here since it's not fresh from the server but rather already from elsewhere in the app.
  const unassigned = (() => {
    const result = new Map<Guid, PlayerForTeamAssignmentViewEx>()
    for (const players of checkedObjectValues(currentAssignments)) {
      for (const player of players) {
        result.set(player.apiData.child.childID, copyViaJsonRoundTrip(player))
      }
    }
    for (const player of unassignedPool) {
      result.set(player.apiData.child.childID, copyViaJsonRoundTrip(player));
    }
    return result
  })()

  for (const teamID of unsafe_objectKeys(currentAssignments)) {
    freshAssignments[teamID] = []

    for (const x of zipped.filter(v => v.fromLocalSnapshot.teamID === teamID && v.fromLocalSnapshot.type === "assignment")) {
      if (!x.fromServer.player) {
        warnings.push(`Found no player matching snapshotted assignment for player '${x.fromLocalSnapshot.raw.name}' on team '${x.fromLocalSnapshot.teamName}' (playerID='${x.fromLocalSnapshot.playerID}')`)
        continue
      }

      unassigned.delete(x.fromLocalSnapshot.playerID)

      freshAssignments[teamID].push({
        id: nextGlobalIntlike(),
        type: "tentatively-assigned",
        apiData: x.fromServer.player,
        clientData: {
          type: "assignment",
          disableAssignOrLoanDueToMissingBirthCert: disableAssignOrLoanDueToMissingBirthCert(competition, x.fromServer.player),
        },
      })
    }
  }

  for (const teamID of unsafe_objectKeys(currentLoans)) {
    freshLoans[teamID] = []

    for (const x of zipped.filter(v => v.fromLocalSnapshot.teamID === teamID && v.fromLocalSnapshot.type === "loan")) {
      assertIs(x.fromLocalSnapshot.type, "loan")
      if (!x.fromServer.player) {
        warnings.push(`Found no player matching snapshotted loan for player '${x.fromLocalSnapshot.raw.name}' on team '${x.fromLocalSnapshot.teamName}' (playerID='${x.fromLocalSnapshot.playerID}')`)
        continue
      }

      if (x.fromLocalSnapshot.raw.expires) {
        const expires = dayjs(x.fromLocalSnapshot.raw.expires)
        if (expires.isBefore(dayjs())) {
          warnings.push(`Player loan for ${x.fromLocalSnapshot.raw.name} on team ${x.fromLocalSnapshot.teamName} was snapshotted with an expiration date that is now expired. The expiration date will be respected and the loan ignored.`)
          continue;
        }
      }

      freshLoans[teamID].push({
        id: nextGlobalIntlike(),
        type: "tentatively-assigned",
        apiData: x.fromServer.player,
        clientData: {
          type: "loan",
          disableAssignOrLoanDueToMissingBirthCert: disableAssignOrLoanDueToMissingBirthCert(competition, x.fromServer.player),
          expirationDate: Optional.of(x.fromLocalSnapshot.raw.expires || null),
        },
      })
    }
  }

  return {
    warnings,
    freshUnassignedPool: [...unassigned.values()].map((v) : PlayerForTeamAssignmentViewEx => {
      return {
        id: nextGlobalIntlike(),
        type: "unassigned",
        apiData: v.apiData,
        clientData: {
          type: "assignment",
          disableAssignOrLoanDueToMissingBirthCert: disableAssignOrLoanDueToMissingBirthCert(competition, v.apiData),
        },
      }
    }),
    freshAssignments,
    freshLoans,
  }
}

function createSnapshot(_: {
  seasonName: string,
  seasonUID: Guid,
  competition: string,
  competitionUID: Guid,
  division: string,
  divID: Guid,
  allKnownTeams: ResolvedSelection["teams"],
  currentAssignments: ResolvedSelection["assignments"]["byTeam"],
  currentLoans: ResolvedSelection["loans"]["byTeam"]
}) {
  const {
    seasonName,
    seasonUID,
    competitionUID,
    competition,
    division,
    divID,
    allKnownTeams,
    currentAssignments,
    currentLoans
  } = _

  const snapshot : Snapshot = {
    date: dayjs().format(DAYJS_FORMAT_IL_API_LOCALDATETIME),
    seasonUID: seasonUID,
    seasonName: seasonName,
    competitionUID: competitionUID,
    competition: competition,
    divID: divID,
    division: division,
    assignments: (() => {
      const result : Snapshot["assignments"] = {}
      for (const [teamID, xs] of checkedObjectEntries(currentAssignments)) {
        result[teamID] = {
          name: arrayFindOrFail(allKnownTeams, v => v.team.teamID === teamID).team.team,
          data: xs.map(v => ({
            playerID: v.apiData.child.childID,
            name: `${v.apiData.child.playerFirstName} ${v.apiData.child.playerLastName}`
          }))
        }
      }
      return result
    })(),
    loans: (() => {
      const result : Snapshot["loans"] = {}
      for (const [teamID, xs] of checkedObjectEntries(currentLoans)) {
        result[teamID] = {
          name: arrayFindOrFail(allKnownTeams, v => v.team.teamID === teamID).team.team,
          data: xs.map(v => {
            assertIs(v.clientData.type, "loan")
            return {
              playerID: v.apiData.child.childID,
              expires: Optional.getOr(v.clientData.expirationDate, null),
              name: `${v.apiData.child.playerFirstName} ${v.apiData.child.playerLastName}`
            }
          })
        }
      }
      return result
    })(),
  }

  return {
    snapshot,
    recommendedFileName: `team-snapshot-${seasonName}_${competition}_${division}.json`.replace(/\s+/g, "_"),
  }
}

function snapshotSchema() {
  return t.Object({
    date: t.String({format: k_DatetimeLike}),
    seasonUID: t.String({format: k_GUID}),
    seasonName: t.String(), // just for aiding at-a-glance-what-is-this
    competitionUID: t.String({format: k_GUID}),
    competition: t.String(), // just for aiding at-a-glance-what-is-this
    divID: t.String({format: k_GUID}),
    division: t.String(), // just for aiding at-a-glance-what-is-this
    assignments: t.Record(t.String({format: k_GUID}), t.Object({
      name: t.String(), // just for aiding at-a-glance-what-is-this
      data: t.Array(t.Object({
        playerID: t.String({format: k_GUID}),
        name: t.String() // just for aiding at-a-glance-what-is-this
    }))})),
    loans: t.Record(t.String({format: k_GUID}), t.Object({
      name: t.String(), // just for aiding at-a-glance-what-is-this
      data: t.Array(t.Object({
        playerID: t.String({format: k_GUID}),
        expires: t.Union([t.Null(), t.String({format: k_DatetimeLike})]),
        name: t.String() // just for aiding at-a-glance-what-is-this
    }))})),
  })
}

type Snapshot = t.Static<ReturnType<typeof snapshotSchema>>

async function getCheckedSnapshotResult(
  ax: AxiosInstance,
  args: {
    seasonUID: Guid,
    competitionUID: Guid,
    divID: Guid,
    playerIDs: Guid[]
  }
) : Promise<{
  player: PlayerForTeamAssignmentView | null
}[]> {
  const response = await ax.get(`v1/teams/teamAssignmentsView/checkedSnapshotResult`, {params: args})
  const data : {player: PlayerForTeamAssignmentView | null}[] = response.data.data
  return data.map(v => {
    // although a PlayerForTeamAssignmentView may potentially have a "current assignment", we're not interested in that here,
    // because any "current assignment" is irrelevant in the snapshot case. Therefore the app is not interested in this property being
    // present so we delete it here.
    delete v.player?.assignment
    return v;
  })
}
