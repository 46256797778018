import { computed, defineComponent, ExtractPropTypes, PropType, ref } from "vue";


import { buildLegacyLink } from "src/boot/auth";

import { ExpandedEvent, EventRosterEmailHelper } from "./R_EventRosterImpl.ilx";
import { Client } from "src/store/Client";
import { Btn2 } from "../UserInterface/Btn2";
import { SelectMany } from "../RefereeSchedule/SelectMany";
import { accentAwareCaseInsensitiveCompare, arrayDouble, SetEx, UiOption } from "src/helpers/utils";

const EventRosterEmailLegacyFormLinkProps = {
  event: {
    required: true,
    type: Object as PropType<ExpandedEvent>
  }
} as const;

export type EventRosterEmailLegacyFormLinkProps = ExtractPropTypes<typeof EventRosterEmailLegacyFormLinkProps>;

export const EventRosterEmailLegacyFormLink = defineComponent({
  props: EventRosterEmailLegacyFormLinkProps,
  emits: {
    complete: () => true,
  },
  setup(props, ctx) {
    const actionURL = computed(() => buildLegacyLink(Client.value.instanceConfig.appdomain, "/emailManager/email.cfm", ""));
    const initialSubject = computed(() => EventRosterEmailHelper.initialEmailSubject(props.event));
    const initialContent = computed(() => EventRosterEmailHelper.initialEmailContent(props.event));

    const targetEmailAddrs = computed<UiOption[]>(() => {
      const result : string[] = [];
      const seenTheseEmails = new Set<string>();
      const maybePushEmail = (email: string) : void => {
        const canonicalEmail = email.toLowerCase().trim()
        if (email && !seenTheseEmails.has(canonicalEmail)) {
          result.push(email);
          seenTheseEmails.add(canonicalEmail);
        }
      }

      for (const signup of props.event.signups) {
        if (!signup.paid || signup.canceled) {
          continue;
        }

        if (signup.userID) {
          maybePushEmail(props.event.rosterLayoutDetail.users[signup.userID].email);
        }
        else if (signup.childID) {
          maybePushEmail(props.event.rosterLayoutDetail.children[signup.childID].parent1Email);
          maybePushEmail(props.event.rosterLayoutDetail.children[signup.childID].parent2Email);
        }
        else {
          // unreachable
        }
      }
      return result
        .sort((l,r) => accentAwareCaseInsensitiveCompare(l,r))
        .map(email => ({label: email, value: email, attrs: {"data-value": email}}))
    })

    const selected = (() => {
      const v = ref(new SetEx<string>(targetEmailAddrs.value.map(opt => opt.value))) // all initially selected
      const asArray = computed(() => [...v.value])
      return {
        get _raw() { return v.value },
        get asArray() { return asArray.value }
      }
    })()

    return () => (
      <div>
        <div class="overflow-y-auto" style="max-height: 75vh;">
          <p class="text-sm">Select email addresses. Clicking "send to selected recipients" will load the email form with the selected addresses.</p>
          {targetEmailAddrs.value.length === 0
            ? <div>No participant emails available.</div>
            : <SelectMany
              selectedKeys={selected.asArray}
              options={targetEmailAddrs.value}
              offerAllOption={true}
              onCheckedOne={(email, isChecked) => {
                if (isChecked) {
                  selected._raw.add(email)
                }
                else {
                  selected._raw.delete(email)
                }
              }}
              onCheckedAll={checked => {
                if (checked) {
                  selected._raw.addMany(targetEmailAddrs.value.map(v => v.value))
                }
                else {
                  selected._raw.clear()
                }
              }}
            />}
        </div>
        <form method="post" target="_blank" action={actionURL.value} onSubmit={() => { ctx.emit("complete") }}>
          <input type="hidden" name="emailGroup" value={props.event.contactEmail}/>
          <input type="hidden" name="emailSubject" value={initialSubject.value}/>
          <input type="hidden" name="emailHTML" value={initialContent.value}/>
          {
            selected.asArray.map(emailAddr => <input type="hidden" name="emailList" value={emailAddr}/>)
          }
          <Btn2 class="mt-4 px-2 py-1" type="submit" data-test="submit-button" disabled={selected._raw.size === 0}>
            <div>Send to selected recipients</div>
          </Btn2>
        </form>
      </div>
    );
  }
})
