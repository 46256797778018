import { computed, reactive, ref } from "vue"
import axios from "axios"

import { UserData } from 'src/interfaces/Store/user'
import { maybeParseJSON } from 'src/helpers/utils'

// todo: functions needing an instance should be provided an instance from the caller
import {
  axiosNoAuthInstance,
  axiosBackgroundInstance,
  axiosInstance,
  AxiosErrorWrapper,
} from 'boot/axios'

import { updateApiUrl } from 'src/boot/axios'
import { RouteLocationNormalizedLoaded, Router } from 'vue-router'
import { getRouter } from 'src/router/CycleBreaker'
import { verifyUserAuth } from 'src/helpers/userAuth'
import * as ilapi from "src/composables/InleagueApiV1"

import { notSignedInUser } from 'src/helpers/emptyUserObject'

import { type AuthenticateResponse_Complete } from "src/composables/InleagueApiV1.Authenticate"

import * as EventuallyPinia from "src/store/EventuallyPinia"


import * as ilauth from "src/composables/InleagueApiV1.Authenticate"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { AxiosInstance } from "axios";
import * as ClearOnLogout from "./ClearOnLogout"

import { accountSetupBlockadeState } from 'src/store/EventuallyPinia'

export type SetUserDataArgs = AuthenticateResponse_Complete

export interface UpdateUserLoginCredsArgs {
  userEmail: string,
  pwd: string,
};

// OneSignal
//
// Import just the type.
//
// Otherwise, we bring in the module at runtime, which unconditionally defines window.plugins.OneSignal,
// which currently we use, by way of its truthiness, to determine if OneSignal is available.
// (i.e., it's truthy? oh, we must be in mobile, and Capacitor is initialized, and has installed the Cordova OneSignal plugin)
//
// The OneSignal plugin is not available in normal web app mode, so we don't want a check like `!!window.plugins.OneSignal`
// to be truthy when yes, it is available, but it wasn't Capacitor that installed it.
//
// If Capacitor didn't install the plugin (i.e. we just imported the module directly),
// then there will be no window.cordova, which the OneSignal plugin requires to jump between js<->native.
//
// Also note that if the module (as a normal non-type import) is imported, but its name is never uttered in a non-type position,
// the effect after various code transforms may be as-if it was only a type import; but this is not behavior we
// want to rely on.
//
import type { OneSignalPlugin } from "onesignal-cordova-plugin"
// seems like an implementation detail, but we do rely on it
import type { OpenedEvent as OneSignal_OpenedEvent } from "onesignal-cordova-plugin/dist/models/NotificationOpened"

import { exhaustiveCaseGuard } from "src/helpers/utils"
import { System } from "./System"
import { Client } from "./Client"
import { someCompThatDoesNotLimitRefAccessHasUpcomingGames } from "src/composables/InleagueApiV1.misc"

export const User = (() => {
  const state = ref(freshState())

  const jwt = (() => {
    const jwtToken = ref("")
    return {
      get: () => jwtToken.value,
      set: (v: string) => {
        jwtToken.value = v
        localStorage.setItem(LOCALSTORAGE_KEY_JWT, v)
      },
      clear: () => {
        jwtToken.value = "";
        localStorage.removeItem(LOCALSTORAGE_KEY_JWT)
      }
    }
  })();

  const loginState = ref<LoginState>({state: "logged-out"});

  const clear = () : void => {
    loginState.value = {state: "logged-out"}
  }

  async function updateUserLoginCreds(payload: UpdateUserLoginCredsArgs) : Promise<void> {
    directCommit_updateUserLoginCreds(payload);
  }

  /**
   * this should be run when a user logs in/out
   */
  function mayeReconfigureGlobalTheme() {
    // user data changed, which can change the value of the selected theme
    Client.maybeSetClientThemeByWayOfCurrentClientIDAndPublicPathAndUser();
    // update associated DOM in case theme changed. This should be a no-op if theme didn't change.
    Client.updateDOMThemingAttrs()
  }

  async function loginUser(payload: SetUserDataArgs) {
    jwt.set(payload.jwt);

    state.value.someCompThatDoesNotLimitRefAccessHasUpcomingGames = (await someCompThatDoesNotLimitRefAccessHasUpcomingGames(axiosInstance)).someCompThatDoesNotLimitRefAccessHasUpcomingGames

    payload.userData.roles = verifyUserAuth(payload, Client.value.instanceConfig)

    directCommit_setUserData(payload)
    directCommit_setLoggedOutErrorDisplayed(false)

    loginState.value = {state: "logged-in"};

    mayeReconfigureGlobalTheme()

    handleOnLoginAction(payload);

    // necessary?
    await updateUserPwd("")

    if ((window as any)?.plugins?.OneSignal) {
      activatePushNotifications()
    }
  }

  /**
   * login using the jwt we pull from local storage, if such a jwt exists and the server understands it as valid
   */
  async function maybeRestoreSessionFromLocalStorageJWT() : Promise<void> {
    const jwtToken = localStorage.getItem(LOCALSTORAGE_KEY_JWT)
    if (!jwtToken) {
      return
    }

    System.setLoading(true)

    try {
      const authResponse = await ilauth.loginUsingExistingJWT(axiosBackgroundInstance, {jwt: jwtToken})
      if (System.value.isMobile) {
        await updateApiUrl(`https://${authResponse.userData.appDomain}/api`)

        await loginUser(authResponse)

        // this seems redundant with `loginUser`
        updateUserLoginCreds({
          userEmail: state.value.userEmail, // this was just assigned in `loginUser` right?
          pwd: '', // in contrast with `userEmail`, this is a fresh assignment
        })

        await Client.mobileClientCustomization()
      }
      else {
        await loginUser(authResponse)
      }
    }
    catch (err) {
      AxiosErrorWrapper.rethrowIfNotAxiosError(err);
    }
    finally {
      System.setLoading(false)
    }
  }

  //
  // TODO: remove this, are all callers refactored to use Login.{loginMobile,loginWeb}?
  //
  async function login(user: LoginArgs) : Promise<AuthenticateResult> {
    if (!user.leagueSelected) {
      return await loginMobile(axiosNoAuthInstance, user);
    }
    else {
      return await loginWeb(axiosNoAuthInstance, user)
    }
  }

  async function logoutUser(
    routeDetails?: { clearRedirectOnLoginURL?: boolean | undefined, route?: RouteLocationNormalizedLoaded; router?: Router }
  ): Promise<void> {
    // defaults to true, unless explicitly set to false
    const clearRedirectOnLoginURL = routeDetails?.clearRedirectOnLoginURL ?? true;

    jwt.clear();

    {
      // also delete cookie containing jwt
      // If the cookie is httpOnly, this has no effect
      const hostName = new URL(window.origin).hostname;
      document.cookie = `GA_IL=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${hostName}`
      document.cookie = `GA_IL=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${hostName}`
      document.cookie = `GA_IL=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;`
    }

    loginState.value = {state: "logged-out"};

    // Logout from legacy, should also clear cookies
    const legacyLogoutURL = `https://${Client.value.instanceConfig.appdomain}/Main/login/logout/1`
    await axiosBackgroundInstance
    .get(legacyLogoutURL ? legacyLogoutURL : '', {
      withCredentials: true,
    })
    .then(response => {
      // console.log(response)
    })
    .catch(err => {
      // console.error(err)
    })
    directCommit_clearUserData()

    Client.directCommit_clearClientData()

    System.directCommit_setClientUrl('')

    if (clearRedirectOnLoginURL) {
      System.setRedirectOnLogin(null)
    }

    const savedLeague = maybeParseJSON(
      localStorage.getItem('savedLeague') ?? ""
    )
    if(!savedLeague && System.value.isMobile) {
      await updateApiUrl(`https://api.inleague.io`)
    }

    mayeReconfigureGlobalTheme()
  }
  //
  // This is a member of the store's actions, but is only dispatched to from other source files for testing purposes
  //
  async function notificationOpenedCallback(data: OneSignal_OpenedEvent) : Promise<void> {
    // console.log('notificationOpenedCallback: ', data)

    // is this a consistent type produced by the backend?
    const payload = data.notification.additionalData as any;

    switch (payload.appPage) {
      case 'messages':
        await getRouter().push({
          name: 'message-thread',
          params: {
            id: payload.conversationID,
          },
          query: {
            refreshed: Math.floor(Math.random() * 9999), // todo: clarify intent here
          }
        })
        break
      case 'teamEvent':
        await getRouter().push({
          name: 'event-signup',
          params: {
            eventID: payload.eventID,
          },
          query: {
            refreshed: Math.floor(Math.random() * 9999), // todo: clarify intent here
          }
        })
        break
      default:
        break
    }

    // this was in the Dashboard component for some time, seems useful to retain for a while.
    // It was intended to test mobile notifications.
    // const testPushPayload: any = {
    //   additionalData: {},
    //   subtitle: 'Test Subtitle',
    //   title: 'Test Title2',
    //   sound: 'default',
    //   body: 'Test Message',
    //   notificationID: 'de1a26fc-c8b1-4b82-bd86-e64b7c9f35ac',
    //   actionButtons: [{ id: 'test', text: 'Open Message' }],
    // }
    // const openMessagePushTest = async () => {
    //   testPushPayload.additionalData.appPage = 'messages'
    //   testPushPayload.additionalData.conversationID =
    //     'B3985884-741D-458C-BA48-D2756AC80785'
    //   await User.notificationOpenedCallback(testPushPayload)
    // }
    // const openEventPushTest = async () => {
    //   testPushPayload.additionalData.appPage = 'teamEvent'
    //   testPushPayload.additionalData.eventID =
    //     '5C6DF70C-D8A9-46C3-89EA-457C29D9099C'
    //   await User.notificationOpenedCallback(testPushPayload)
    // }
  }

  function activatePushNotifications() {
    // --------------
    // OneSignal
    const OneSignal : OneSignalPlugin = (window as any).plugins.OneSignal;

    // Setting External User Id with Callback Available in SDK Version 2.9.0+
    OneSignal.setExternalUserId(state.value.userID, (results: any) => {
      // The results will contain push and email success statuses
      // console.log('Results of setting external user id', results)

      // Push can be expected in almost every situation with a success status, but
      // as a pre-caution its good to verify it exists
      if (results.push && results.push.success) {
        // console.log(
        //   'Results of setting external user id push status:',
        //   results.push.success
        // )
      }

      // Verify the email is set or check that the results have an email success status
      if (results.email && results.email.success) {
        // console.log(
        //   'Results of setting external user id email status:',
        //   results.email.success
        // )
      }
    })

    // Remove this method to stop OneSignal Debugging
    OneSignal.setLogLevel(/*nsLogLevel*/ 6, /*visualLevel*/ 0);

    // Set your iOS Settings
    //
    // this was done with the cordova v2 plugin
    // but does not appear available on cordova v3
    //
    // const iosSettings: any = {}
    // iosSettings['kOSSettingsKeyAutoPrompt'] = false
    // iosSettings['kOSSettingsKeyInAppLaunchURL'] = false

    OneSignal.setAppId('c2830afa-f96f-4171-b79f-34f94425578a');
    OneSignal.setNotificationOpenedHandler(async (openedEvent) => {
      console.log("notification openedCallback", openedEvent);
      await notificationOpenedCallback(openedEvent)
    });

    // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt. We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
    OneSignal.promptForPushNotificationsWithUserResponse(function (
      accepted: any
    ) {
      // console.log('User accepted notifications: ' + accepted)
    })

    //
    // debug stuff
    // window.plugins.OneSignal.getDeviceState(v => console.log(v))
    //
  }

  async function getChild(args: string | {playerID: string, bustCache?: boolean}) : Promise<iltypes.Child> {
    if (typeof args === "string") {
      // when we added the "args can be an object" overload, this was the only existing code path
      if(state.value.children[args]) {
        return state.value.children[args]
      } else {
        const response = await axiosInstance.get(`v1/child/${args}`)
        return response.data.data
      }
    }
    else {
      if (state.value.children[args.playerID] && !args.bustCache) {
        return state.value.children[args.playerID];
      }
      else {
        const result = await ilapi.getPlayer(axiosInstance, {childID: args.playerID});
        directCommit_cacheInsertOrReplaceChild(result);
        return result;
      }
    }
  }

  /**
   * mostly for test
   */
  async function setAuthzRoles(roleNames: string[]) : Promise<void> {
    directCommit_setAuthzRoles(roleNames);
  }
  /**
   * mostly for test
   */
  async function forceSetUserData(userData: UserData) : Promise<void> {
    directCommit_forceSetUserData(userData);
  }

  async function updateUserEmail(email: string) : Promise<void> {
    directCommit_updateUserEmail(email);
  }
  async function updateUserPwd(pwd: string) : Promise<void> {
    directCommit_updateUserPwd(pwd);
  }

  function doAuthenticate(tag: "use-implied-3rd-party-oauth-flow", assertion: string) : Promise<AuthenticateResult>;
  function doAuthenticate(args: LoginArgs) : Promise<AuthenticateResult>;
  function doAuthenticate(args: "use-implied-3rd-party-oauth-flow" | LoginArgs, assertion?: string) : Promise<AuthenticateResult> {
    if (args === "use-implied-3rd-party-oauth-flow") {
      if (!assertion) {
        throw "`assertion` must be defined in this overload";
      }
      return loginImplied3rdPartyOauthFlow(assertion);
    }
    else {
      return User.login(args)
    }
  }

  /**
   * axios does not need credentials here, we might want `auth` to live under `public` in the api module hierarchy
   * "login web" is A.K.A "login when we have know we are configured to point at a specific league's domain"
   * maybe should be renamed "authenticateWeb", because this grabs credentials but does not perform application login
   */
  async function loginWeb(axiosInstance: AxiosInstance, user: LoginArgs) : Promise<AuthenticateResult_Single | AuthenticateResult_Error> {
    try {
      System.setLoading(true);

      const result = await ilauth.public_.authenticate(
        axiosInstance,
        {
          username: user.username,
          password: user.password,
          forMobileDevice: process.env.isMobile ? true : false
        },
      );

      return {
        ok: true,
        type: "single",
        data: result
      }
    }
    catch (error: any) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      const errorAsAny = error as any;
      const msg = errorAsAny.status === 401
          ? 'Wrong username/password'
          : errorAsAny.data && errorAsAny.data.messages && Array.isArray(errorAsAny.data.messages)
          ? errorAsAny.data.messages.join()
          : 'Error logging in'

      return {
        ok: false,
        msg
      }
    }
    finally {
      // older code did this, but we can't really know that this is true from here
      // there can be other requests in flight from elsewhere across the program
      System.setLoading(false);
    }
  }

  async function loginMobile(axiosInstance: AxiosInstance, loginArgs: LoginArgs) : Promise<AuthenticateResult> {
    await System.setLoading(true);

    try {
      const response = await ilauth.public_.authenticateMulti(axiosInstance, loginArgs);
      if (response.type === "single") {

        // necessary?
        await User.updateUserLoginCreds({userEmail: "", pwd: ""});

        await updateApiUrl(`https://${response.payload.league.appDomain}/api`)

        await Client.mobileClientCustomization();

        return {
          ok: true,
          type: "single",
          data: response.payload
        }
      }
      else if (response.type === "multi") {
        // this helps shuttle into any subsequent login screens
        await User.updateUserLoginCreds({userEmail: loginArgs.username, pwd: loginArgs.password});

        await System.setAccessPointOptions(response.payload)

        return {
          ok: true,
          type: "multi",
          data: response.payload
        }
      }
      else {
        exhaustiveCaseGuard(response);
      }
    }
    catch (error: any) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      const errorAsAny = error as any;
      const msg = errorAsAny.status === 401
          ? 'Wrong username/password'
          : errorAsAny.data && errorAsAny.data.messages && Array.isArray(errorAsAny.data.messages)
          ? errorAsAny.data.messages.join()
          : 'Error logging in'

      return {
        ok: false,
        msg
      }
    }
    finally {
      System.setLoading(false);
    }
  }

  /**
   * Requires that we have a cookie asserting that we are in this flow
   * See wrapped api call for details
   */
  async function loginImplied3rdPartyOauthFlow(assertion: string) : Promise<AuthenticateResult> {
    try {
      return {
        ok: true,
        type: "single",
        data: await ilauth.public_.authenticateUsingImplied3rdPartyOauthFlow(axiosNoAuthInstance, {assertion})
      }
    }
    catch (error: any) {
      if (!axios.isAxiosError(error)) {
        throw error;
      }

      const errorAsAny = error as any;
      const msg = errorAsAny.status === 401
          ? 'Wrong username/password'
          : errorAsAny.data && errorAsAny.data.messages && Array.isArray(errorAsAny.data.messages)
          ? errorAsAny.data.messages.join()
          : 'Error logging in'

      return {
        ok: false,
        msg
      }
    }
  }

  /**
   * At this time, "onLoginAction" implies "put up a modal in which to perform the action",
   * but it doesn't necessarily stop us from navigating to some target route behind the modal.
   */
  function handleOnLoginAction(authData: ilauth.AuthenticateResponse_Complete) : void {
    if (authData.onLoginAction) {
      switch (authData.onLoginAction) {
        case "validate-user-gender":
          accountSetupBlockadeState.enterGlobalCollectGenderState(authData.userData.userID);
          return;
        default: exhaustiveCaseGuard(authData.onLoginAction)
      }
    }
  }

  //
  // directCommit_*
  //

  function directCommit_updateUserEmail(userEmail: string) {
    state.value.userEmail = userEmail;
  }
  function directCommit_updateUserPwd(pwd: string) {
    state.value.pwd = pwd;
  }
  function directCommit_updateUserBirthday(userBirthday: string) {
    state.value.userBirthday = userBirthday
  }
  function directCommit_updateUserLoginCreds(user: UpdateUserLoginCredsArgs) {
    state.value.userEmail = user.userEmail
    state.value.pwd = user.pwd
  }
  /**
   * Takes login info and stores it. After this call we consider the user logged in.
   */
  function directCommit_setUserData(payload: SetUserDataArgs): void {
    loginState.value = {state: "logged-in"};
    state.value.userData = payload.userData
    state.value.userID = payload.userData.userID
    if((state.value.userData as UserData).middleName) {
      state.value.fullName = `${payload.userData.firstName} ${payload.userData.middleName} ${payload.userData.lastName}`
    } else {
      state.value.fullName = `${payload.userData.firstName} ${payload.userData.lastName}`
    }
    state.value.userEmail = payload.userData.email

    jwt.set(payload.jwt)

    state.value.roles = payload.userData.roles
    // compManager added to handle which links to display in the sidebar
    if((state.value.userData as UserData).competitionsMemento.length) {
      // state.value.roles.push('compManager');
      (state.value.userData as UserData).roles.push('compManager')
    }

    //
    // // @rmme Mar/22/2023 -- well typed this function and it became apparent that `userData.address` isn't a thing;
    // // state.userAddress is used in a couple places across the app, but only appears to be initialized here and in the default state constructor, so it
    // // seems it's always literally `""`, which strongly implies this thing is kruft.
    //
    // state.userAddress = ''
    // if (payload.userData.address) {
    //   state.userAddress = `${payload.userData.address.line1} ${payload.userData.address.line2} ${payload.userData.address.city} ${payload.userData.address.state} ${payload.userData.address.postal_code}`
    // }
    //

    if (payload.userData.playerAssignmentsMemento.length) {
      let unembargoedPlayer = false
      payload.userData.playerAssignmentsMemento.forEach(
        (player: { embargoed: boolean }) => {
          if (!player.embargoed) unembargoedPlayer = true
        }
      )
      if (unembargoedPlayer)
        state.value.roles = [...payload.userData.roles, 'hasUnembargoedPlayer']
    }
    // console.log('setUserData', state)
  }
  function directCommit_setAuthzRoles(roles: string[]) {
    state.value.roles = roles
  }
  function directCommit_setUserEmail(email: string) {
    state.value.userEmail = email
  }
  function directCommit_setPwd(pwd: string) {
    state.value.pwd = pwd
  }
  function directCommit_clearUserData() {
    loginState.value = {state: "logged-out"}

    jwt.clear();

    // Because we initialize this on login, we clear it on logout;
    // it is conceptually a "client-wide" thing though, so conceivably we could leave it unaffected here.
    // However, its value drives some authZ checks where it doesn't make sense to be true when the user is
    // logged out.
    state.value.someCompThatDoesNotLimitRefAccessHasUpcomingGames = false

    state.value.userID = ''
    state.value.userEmail = ''
    state.value.fullName = ''
    state.value.roles = []
    state.value.userBirthday = ''
    state.value.pwd=''
    state.value.userAddress=''
    state.value.userData = notSignedInUser()
  }
  function directCommit_setLoggedOutErrorDisplayed(errorDisplayed: boolean) {
    state.value.loggedOutErrorDisplayed = errorDisplayed
  }
  function directCommit_cacheInsertOrReplaceChild(child: iltypes.Child) {
    state.value.children[child.childID] = child
  }
  /**
   * updates "belongingChildrenIDs"
   */
  function directCommit_setChildren(child: iltypes.Child) : void {
    state.value.children[child.childID]=child
    if (typeof state.value.userData === "string") {
      // string representing cf-nullish, i.e. no data
      // probably shouldn't ever get here
    }
    else {
      // some callers call this because they added a child to the backend
      //  - the next "full refresh" of user data would arrive with this childID,
      //  - but we're not executing a full refresh so track it locally
      // some callers call this because they received a child from the backend from some other business process
      //  - in this case case, a call to this may be unnecessary (weren't all the user's `belongingChildrenIDs` received during login?)
      //
      const freshChildID = child.childID;
      for (const alreadyRecordedChildID of state.value.userData.belongingChildrenIDs) {
        if (alreadyRecordedChildID === freshChildID) {
          // already recorded, probably caller didn't need to call
          return;
        }
      }
      // `freshChildID` is new, track it locally
      state.value.userData.belongingChildrenIDs.push(child.childID);
      state.value.userData.belongingChildrenIDsAreSet = true;
    }
  }
  function directCommit_forceSetUserData(userData: UserData) {
    state.value.userData = userData
  }

  const isLoggedIn = computed(() => loginState.value.state === "logged-in");

  const onCreateTournamentTeam = () : void => {
    if (typeof state.value.userData === "string") {
      return;
    }

    if (state.value.userData.hasManageSomeTournamentTeamPerm) {
      return;
    }

    state.value.userData.hasManageSomeTournamentTeamPerm = true;
    System.rebuildNavLinks();
  }

  return {
    clear,
    /** should be deep const; preferably we don't expose this at all */
    get value() : UserI { return state.value },
    //
    // todo: replace all instances of value.userData and associated "is empty string" checks with properly nullable value
    //
    get userData() : UserData | null {
      return typeof state.value.userData === "string" ? null : state.value.userData;
    },
    get isInleague() : boolean {
      return !!this.userData?.isInleague
    },
    getChild,
    login,
    notificationOpenedCallback,
    maybeRestoreSessionFromLocalStorageJWT,
    updateUserLoginCreds,
    directCommit_clearUserData,
    directCommit_setChildren,
    directCommit_setUserEmail,
    directCommit_setPwd,
    directCommit_setLoggedOutErrorDisplayed,
    directCommit_updateUserEmail,
    loginWeb,
    doAuthenticate,
    loginUser,
    logoutUser,
    handleOnLoginAction,
    get loginState() : /*deep readonly*/ Readonly<LoginState> { return loginState.value; },
    set loginState(v: LoginState) { loginState.value = v; },
    get isLoggedIn() : boolean { return isLoggedIn.value },
    get isImpersonating() : boolean { return !!this.userData?.isImpersonating },
    get jwtToken() {
      return jwt.get();
    },
    set jwtToken(v: string) {
      jwt.set(v);
    },
    onCreateTournamentTeam,
  }
})()

ClearOnLogout.register(User);

export interface LoginArgs {
  username: string
  password: string
  leagueSelected?: boolean
}

export interface AuthenticateResult_Single {
  ok: true,
  type: "single",
  data: ilauth.AuthenticateResponse
}

export interface AuthenticateResult_Multi {
  ok: true,
  type: "multi",
  data: ilauth.LeagueDomainDetails[]
}

export interface AuthenticateResult_Error {
  ok: false,
  msg: string
}

export type AuthenticateResult =
  | AuthenticateResult_Single
  | AuthenticateResult_Multi
  | AuthenticateResult_Error

export interface UserI {
  // when this is string, is it always exactly `""`?
  userData: UserData | string
  userID: string
  userEmail: string
  roles: string[]
  fullName: string
  userBirthday: string
  pwd: string
  /**
   * Is this ever assigned to with a value other than `""` ?
   * Do we ever use it?
   * There some places that reference it but it's always `""`, yes?
   *
   * Referenced from google map directions-to-game-field URL, but that seems to work reasonably
   * well when this is empty string (google generating "directions from current location")
   */
  userAddress: string
  loggedOutErrorDisplayed: boolean,
  children: {[key:string]: iltypes.Child},
  /**
   * Really this should be on Client, but we need to know the clientID to ask for it,
   * and it's easiest to wait until the user logs in to be able to hit an authenticated endpoint
   * and we have a guarantee of knowing the target client.
   * This does drive authZ-like checks, guarding some routes.
   */
  someCompThatDoesNotLimitRefAccessHasUpcomingGames: boolean
}

function freshState(): UserI {
  return reactive<UserI>({
    userData: notSignedInUser(),
    userID: '',
    userEmail: '',
    roles: [],
    fullName: '',
    userBirthday: '',
    pwd: '',
    userAddress: '',
    loggedOutErrorDisplayed: false,
    children: {},
    someCompThatDoesNotLimitRefAccessHasUpcomingGames: false
  })
}

interface Login_StateBase {
  state:
    | "logged-in"
    | "logged-out"
    | "mfa-challenge-flow"
    | "mfa-init-flow"
    | "mfa-init-flow-complete"
    | "oauth-ack-multiple-leagues"
};

export interface Login_MfaInitFlow extends Login_StateBase {
  state: "mfa-init-flow"
  token: string,
  userID: iltypes.Guid,
  mfaDetails: ilapi.auth.MfaDetails
}

export interface Login_MfaChallengeFlow extends Login_StateBase {
  state: "mfa-challenge-flow",
  userID: iltypes.Guid,
  token: string,
  mfaDetails: ilapi.auth.MfaDetails
}

export interface Login_OauthAckMultipleLeagues extends Login_StateBase {
  state: "oauth-ack-multiple-leagues"
  email: string
  availableLeagues: ilauth.LeagueDomainDetails[]
}

interface Login_UnitaryStates extends Login_StateBase {
  state: "logged-in" | "logged-out" | "mfa-init-flow-complete"
}

export type LoginState = Login_UnitaryStates | Login_MfaInitFlow | Login_MfaChallengeFlow | Login_OauthAckMultipleLeagues

const LOCALSTORAGE_KEY_JWT = "jwt"
