import { Guid } from "src/interfaces/InleagueApiV1"
import { RouteLocationRaw } from "vue-router"
import { hasSomeRoutePermission as invoiceTemplateShared_hasSomeRoutePermission } from 'src/components/InvoiceTemplates/InvoiceTemplateListing.route'

export const routeName = "invoice-templates-bulk"
export const route = (keyType: "registrationID", keys: Guid[]) : RouteLocationRaw => {
  return {
    name: routeName,
    params: {},
    query: {
      keyType,
      keys: keys.join(",")
    }
  }
}

export function hasSomeRoutePermission() : boolean {
  return invoiceTemplateShared_hasSomeRoutePermission()
}
