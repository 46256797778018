import { Integerlike } from "src/interfaces/InleagueApiV1"

export const RouteName = "SubscriptionUpdate"
export function route(args: {invoiceInstanceID: Integerlike}) {
  return {
    name: RouteName,
    params: {
      invoiceInstanceID: args.invoiceInstanceID
    }
  }
}
