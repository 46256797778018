import { GetTeamPoolForEditTeamPoolViewResponse, TeamPoolForTeamPoolEditor } from "src/composables/InleagueApiV1.Teams"
import { SetEx, assertTruthy, nextGlobalIntlike, requireNonNull } from "src/helpers/utils"
import { Guid, Integerlike, TeamID } from "src/interfaces/InleagueApiV1"

export interface ResolvedSelection {
  seasonUID: Guid,
  competitionUID: Guid,
  divID: Guid,
  poolData: PoolData
}

export interface NewPoolForm {
  renderKey: string,
  name: string,
}

export function NewPoolForm() : NewPoolForm {
  return {
    renderKey: nextGlobalIntlike(),
    name: ""
  }
}

export interface PoolData {
  apiData: GetTeamPoolForEditTeamPoolViewResponse,
  uiData: TeamPoolUiDataCollection,
  selectedAddTheseTeamIDs: SetEx<Guid>,
  selectedRemoveTheseTeamIDs: {[poolUID: Guid]: undefined | SetEx<Guid>}
  createdThisSessionPoolUIDs: Guid[],
}

export type TeamPoolUiData = {
  dirty: {
    seed: Integerlike | ""
  }
}

export class TeamPoolUiDataCollection {
  // We assume that a team is on exactly zero-or-one pools, so we our key here can be just (teamID).
  // If teams were allowed to be on multiple pools our key would have to be (poolUID,teamID)
  __data: Map<TeamID, TeamPoolUiData>

  constructor(v: typeof TeamPoolUiDataCollection.prototype.__data) {
    this.__data = v
  }

  getOrFail({teamID}: {teamID: Guid}) {
    return requireNonNull(this.__data.get(teamID))
  }

  static create(pools: TeamPoolForTeamPoolEditor[]) {
    const v : PoolData["uiData"]["__data"] = new Map()
    for (const pool of pools) {
      for (const team of pool.teams) {
        assertTruthy(!v.has(team.teamID)) // all teams unique, no dupes across pools
        v.set(team.teamID, {
          dirty: {
            seed: team.seed || ""
          }
        })
      }
    }
    return new TeamPoolUiDataCollection(v)
  }

  resetFrom(pool: TeamPoolForTeamPoolEditor) {
    for (const team of pool.teams) {
      this.__data.set(team.teamID, {
        dirty: {
          seed: team.seed || ""
        }
      })
    }
  }
}
