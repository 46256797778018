import { FK_nodeRef, FK_validation_strlen, htmlIsEmpty, Reflike, vReqT } from "src/helpers/utils";
import { Competition, Division, Guid, Season } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, ref } from "vue";
import { CompSeasonDivWelcomeEmail, createOrUpdateCompSeasonDivWelcomeEmail, getCompSeasonDivWelcomeEmail } from "./CompSeasonDivWelcomeEmail.io";
import { FormKit, FormKitMessages } from "@formkit/vue";
import { QuillWrapper3 } from "../UserInterface/QuillWrapper3";
import { Btn2 } from "../UserInterface/Btn2";
import iziToast from "izitoast";
import { freshAxiosInstance, defaultLoggedInErrorResponseHandler } from "src/boot/axios";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances";
import { ReactiveReifiedPromise, UnresolvableReactiveReifiedPromiseError } from "src/helpers/ReifiedPromise";
import { useDefaultNoCloseModalIfBusy, DefaultModalController_r, DefaultTinySoccerballBusyOverlay } from "../UserInterface/Modal";

export const CompSeasonDivWelcomeEmailFormElem = defineComponent({
  props: {
    competition: vReqT<Competition>(),
    season: vReqT<Season>(),
    division: vReqT<Division>(),
    form: vReqT<CompSeasonDivWelcomeEmailForm>(),
  },
  emits: {
    submit: () => true,
  },
  setup(props, ctx) {
    const fkNode = FK_nodeRef()
    const htmlBodyIsEmpty = computed(() => htmlIsEmpty(props.form.htmlBody))
    const formElems = CompSeasonDivWelcomeEmailFormElems({get value() { return props.form }}, htmlBodyIsEmpty)

    /**
     * delay rendering the quill element by a few ms, otherwise it forces the modal (and we do assume we are being rendered in a modal)
     * to snap open, rather than animate open.
     */
    const formElem_quill_delayedRenderer = (() => {
      const f = ref<(() => JSX.Element) | null>(null)
      setTimeout(() => { f.value = formElems.htmlBody }, 0)
      return f
    })()

    return () => {
      return <div style="--fk-margin-outer: none; --fk-padding-input: .35em; --fk-padding-message: none; --fk-margin-message: none;">
        <div class="mb-4">When enabled, {props.competition.competition} registrations in {props.division.displayName || props.division.division} will be sent the below email when their registration is confirmed or activated off the waitlist.</div>
        <FormKit type="form" actions={false} onSubmit={() => ctx.emit("submit")}>
          <table>
            <tr>
              <td class="font-medium">Season</td>
              <td>{props.season.seasonName}</td>
            </tr>
            <tr>
              <td class="font-medium">Program</td>
              <td>{props.competition.competition}</td>
            </tr>
            <tr>
              <td class="font-medium">Division</td>
              <td>{props.division.displayName || props.division.division}</td>
            </tr>
            <tr>
              <td class="font-medium py-2">Enabled</td>
              <td>
                <label class="inline-flex items-center">
                  &nbsp; {/*weird not-understood layout issue -- without some kind of content here, the 'items-center' doesn't center the form elem here*/}
                  {formElems.enabled()}
                  <p class="text-sm">(An empty email body is effectively disabled.)</p>
                </label>
              </td>
            </tr>
            <tr>
              <td class="font-medium py-2">From (Email)</td>
              <td>{formElems.from.elem()}</td>
            </tr>
            <tr>
              <td colspan="999">{formElems.from.errors()}</td>
            </tr>
            <tr>
              <td class="font-medium py-2">Subject</td>
              <td>{formElems.subject.elem()}</td>
            </tr>
            <tr>
              <td colspan="999">{formElems.subject.errors()}</td>
            </tr>
            <tr>
              <td class="font-medium py-2" colspan="999">Mail Body</td>
            </tr>
            <tr>
              <td colspan="999 py-2">{formElem_quill_delayedRenderer.value?.()}</td>
            </tr>
            <tr>
              <td class="pt-2" colspan="999">
                <Btn2 type="submit" class="px-2 py-1" data-test="submit-button">
                  Submit
                </Btn2>
                <FormKitMessages node={fkNode.value?.node}/>
              </td>
            </tr>
          </table>
        </FormKit>
      </div>
    }
  }
})

export interface CompSeasonDivWelcomeEmailForm {
  mode: "new" | "existing",
  competitionUID: Guid,
  seasonUID: Guid,
  divID: Guid,
  enabled: boolean,
  from: string,
  subject: string,
  htmlBody: string
}

export function freshCompSeasonDivWelcomeEmailFormFromExisting(existing: CompSeasonDivWelcomeEmail) : CompSeasonDivWelcomeEmailForm {
  return {
    mode: "existing",
    competitionUID: existing.competitionUID,
    seasonUID: existing.seasonUID,
    divID: existing.divID,
    enabled: existing.enabled,
    from: existing.from,
    subject: existing.subject,
    htmlBody: existing.htmlBody
  }
}

export function freshCompSeasonDivWelcomeEmailForm(args: {competitionUID: Guid, seasonUID: Guid, divID: Guid}) : CompSeasonDivWelcomeEmailForm {
  return {
    mode: "new",
    competitionUID: args.competitionUID,
    seasonUID: args.seasonUID,
    divID: args.divID,
    enabled: false,
    from: "",
    subject: "",
    htmlBody: ""
  }
}

function CompSeasonDivWelcomeEmailFormElems(formData: Reflike<CompSeasonDivWelcomeEmailForm>, htmlBodyIsEmpty: Reflike<boolean>) {
  const fromRef = FK_nodeRef()
  const subjectRef = FK_nodeRef()

  const enabled = {
    get value() {
      if (htmlBodyIsEmpty.value) {
        return false
      }
      return !!formData.value.enabled
    },
    set value(v) {
      if (htmlBodyIsEmpty.value) {
        // no-op
        return
      }

      formData.value.enabled = v
    }
  }

  return {
    enabled: () => <FormKit
      type="checkbox"
      disabled={htmlBodyIsEmpty.value}
      v-model={enabled.value}
      data-test="enabled"
    />,
    from: {
      elem: () => <FormKit
        ref={fromRef}
        type="email"
        v-model={formData.value.from}
        validation={[["required"], FK_validation_strlen(0, STRLEN_FROM)]}
        validationLabel="From"
        data-test="from"
      />,
      errors: () => <FormKitMessages node={fromRef.value?.node}/>,
    },
    subject: {
      elem: () => <FormKit
        ref={subjectRef}
        type="text"
        v-model={formData.value.subject}
        validation={[["required"], FK_validation_strlen(0, STRLEN_SUBJECT)]}
        validationLabel="Subject"
        data-test="subject"
      />,
      errors: () => <FormKitMessages node={subjectRef.value?.node}/>,
    },
    htmlBody: () => <QuillWrapper3
      v-model={formData.value.htmlBody}
      allowImages={false}
      allowVideos={false}
      pinLinkEditorPopup={true}
      data-test="htmlBody"
    />,
  }
}

const STRLEN_FROM = 500
const STRLEN_SUBJECT = 1000

export function CompSeasonDivWelcomeEmailController() {
  const compSeasonDivWelcomeEmailFormResolver = ReactiveReifiedPromise<CompSeasonDivWelcomeEmailForm>(undefined, {defaultDebounce_ms: 250})
  const compSeasonDivWelcomeEmailModalController = (() => {
    const {busy, onCloseCB} = useDefaultNoCloseModalIfBusy()

    const handleSubmit = async (form: CompSeasonDivWelcomeEmailForm) => {
      try {
        try {
          busy.value = true

          const mungedForm = (() : CompSeasonDivWelcomeEmail => {
            const empty = htmlIsEmpty(form.htmlBody)
            return {
              competitionUID: form.competitionUID,
              seasonUID: form.seasonUID,
              divID: form.divID,
              enabled: empty ? false : form.enabled,
              from: form.from,
              subject: form.subject,
              htmlBody: empty ? "" : form.htmlBody,
            }
          })();

          await createOrUpdateCompSeasonDivWelcomeEmail(axiosAuthBackgroundInstance, mungedForm)

          iziToast.success({message: "Email configuration updated."})
        }
        finally {
          busy.value = false
        }

        compSeasonDivWelcomeEmailModalController.close()
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    return DefaultModalController_r<{form: CompSeasonDivWelcomeEmailForm, competition: Competition, season: Season, division: Division}>({
      title: () => <>
        <div>Manage Division Welcome Email</div>
        <div class="border-b my-2"/>
      </>,
      content: data => {
        if (!data) {
          return null
        }
        return <div>
          <CompSeasonDivWelcomeEmailFormElem
            competition={data.competition}
            division={data.division}
            season={data.season}
            form={data.form}
            onSubmit={() => handleSubmit(data.form)}
          />
          <DefaultTinySoccerballBusyOverlay if={busy.value}/>
        </div>
      }
    }, {onCloseCB})
  })();

  const tryOpenCompSeasonDivWelcomeEmailModal = async ({
    competition,
    season,
    division
  }: {
    competition: Competition,
    season: Season,
    division: Division,
  }) : Promise<void> => {
    if (compSeasonDivWelcomeEmailFormResolver.underlying.status === "pending") {
      return
    }

    const seasonUID = season.seasonUID
    const competitionUID = competition.competitionUID
    const divID = division.divID

    try {
      const form = await compSeasonDivWelcomeEmailFormResolver.run(async () => {
        const ax = freshAxiosInstance({
          useCurrentBearerToken: true,
          responseInterceptors: [{error: defaultLoggedInErrorResponseHandler(iziToast)}]
        })

        const data = await getCompSeasonDivWelcomeEmail(ax, {competitionUID, seasonUID, divID})

        return data
          ? freshCompSeasonDivWelcomeEmailFormFromExisting(data)
          : freshCompSeasonDivWelcomeEmailForm({competitionUID, seasonUID, divID})
      }).getResolvedOrFail()

      compSeasonDivWelcomeEmailModalController.open({
        form,
        competition,
        season,
        division
      })
    }
    catch (err) {
      if (err instanceof UnresolvableReactiveReifiedPromiseError) {
        return;
      }
      else {
        throw err
      }
    }
  }

  return {
    formResolver: compSeasonDivWelcomeEmailFormResolver,
    modalController: compSeasonDivWelcomeEmailModalController,
    tryOpenCompSeasonDivWelcomeEmailModal,
  }
}
