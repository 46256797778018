import { ref } from "vue";
import type { RouteLocationRaw, Router } from "vue-router";

import type { LeagueDomainDetails } from "src/composables/InleagueApiV1.Authenticate"
import { exhaustiveCaseGuard, type DeepConst, type CheckedOmit } from "src/helpers/utils";

export type Redirection =
  | {type: "vue-router-route", value: RouteLocationRaw}
  | {type: "lazy-vue-router-route", value: () => RouteLocationRaw}
  | {type: "url", value: string}

export interface SystemStore {
  validationErrors: any
  loading: boolean
  multipleCalls: boolean
  triggerCaptcha: boolean
  isMobile: boolean
  devicePlatform: string
  clientUrl: string
  clientLeague: string
  /**
   * Truthy if we have somewhere to route to after a succesful login
   */
  redirectOnLogin: Redirection | null
  accessPointOptions: LeagueDomainDetails[]
  leagueAccessPointDetails: LeagueDomainDetails,
  paymentProcessing: boolean
  refundProcessing: boolean
  tBtnClasses: string
  openSidebar: boolean
  recaptchaKey: string
}

function freshState(): SystemStore {
  return {
    loading: false,
    multipleCalls: false,
    triggerCaptcha: false,
    isMobile: false,
    devicePlatform: 'web',
    clientUrl: '',
    clientLeague: 'Austin',
    redirectOnLogin: null,
    accessPointOptions: [],
    leagueAccessPointDetails: {} as LeagueDomainDetails,
    paymentProcessing: false,
    refundProcessing: false,
    tBtnClasses: `inline-flex items-center text-center px-4 py-2 border border-transparent text-sm leading-5
    font-medium rounded-md text-white focus:outline-none
    disabled:bg-gray-200
    disabled:cursor-not-allowed
    transition
    ease-in-out duration-150`,
    openSidebar: false,
    validationErrors: [],
    recaptchaKey: ''
  }
}

export const System = (() => {
  const state = ref(freshState())

  const navLinkRebuildSignal = ref(0);
  const triggerNavLinkRebuild = () => { navLinkRebuildSignal.value += 1; }

  function selectLeague(appDomain: string) {
    let leagueDetails = {} as LeagueDomainDetails
    for (let i = 0; i < state.value.accessPointOptions.length; i++) {
      if (state.value.accessPointOptions[i].appDomain === appDomain) {
        leagueDetails = state.value.accessPointOptions[i]
      }
    }
    state.value.leagueAccessPointDetails = leagueDetails
    return leagueDetails
  }

  /**
   * multiple calls meaning
   *   - there will be 2 or more http calls during some span of time and while multiple calls is true
   *     the completion of any one of them does not guarantee the batch is complete.
   *   - only by setting this back to false is a "multiple call" batch considered complete
   *
   * This is primarily intended to drive the "global loading spinner", which is present on screen if this is true.
   */
  function setMultipleCalls(multipleCallsIsActiveFlag : boolean) : void {
    state.value.multipleCalls = multipleCallsIsActiveFlag
  }

  function setLoading(b: boolean) : void {
    if (!state.value.multipleCalls || b) {
      state.value.loading = b
    }
  }

  function setAccessPointOptions(options: LeagueDomainDetails[]) {
    state.value.accessPointOptions = options
  }

  function directCommit_setIsMobile(bool: boolean) {
    state.value.isMobile = bool
  }

  function directCommit_setDevicePlatform(platform: string) {
    state.value.devicePlatform = platform
  }

  function directCommit_setClientUrl(url: string) {
    state.value.clientUrl = url
  }

  function directCommit_setClientLeague(league: string) {
    state.value.clientLeague = league
  }

  function directCommit_setValidationErrors(errors: any) {
    state.value.validationErrors = errors
  }

  function directCommit_setTriggerCaptcha(bool: boolean) {
    state.value.triggerCaptcha = bool
  }

  // For situations were multiple axios calls are being made at once. Allows spinner to spin smoothly while calls are being made (instead of starting and stopping for each one). Set to false before the last call.
  function directCommit_setMultipleCalls(bool: boolean): void {
    state.value.multipleCalls = bool
  }

  // These determine whether the processing modal is displayed
  function directCommit_setPaymentProcessing(bool: boolean) {
    state.value.paymentProcessing = bool
  }

  function directCommit_setRefundProcessing(bool: boolean) {
    state.value.refundProcessing = bool
  }

  function directCommit_setOpenSidebar(bool: boolean) {
    state.value.openSidebar = bool
  }

  function directCommit_setRecaptchaKey(key: string) {
    state.value.recaptchaKey=key
  }

  function directCommit_setLeagueAccessPointDetails(leagueDetails: LeagueDomainDetails) {
    state.value.leagueAccessPointDetails = leagueDetails
  }

  /**
   * It is the caller's responsibility to ensure that there is an existing redirectOnLogin value
   * But, after this returns, the "current" redirectOnLogin will have been consumed (i.e. in this case, set to null)
   */
  const performAndConsumeLoginRedirect = async (router: Router) => {
    if (!state.value.redirectOnLogin) {
      throw Error("this shouldn't be called if there is no target redirect")
    }

    const target = state.value.redirectOnLogin
    state.value.redirectOnLogin = null;

    if (target.type === "vue-router-route") {
      return await router.push( target.value )
    }
    else if (target.type === "lazy-vue-router-route") {
      return await router.push(target.value())
    }
    else if (target.type === "url") {
      window.location.href = target.value
      return;
    }
    else {
      exhaustiveCaseGuard(target)
    }
  }

  return {
    // we intend to slowly replace reads like `.value.FOO` with "just" `.FOO`; as those properties are added, they can be CheckedOmit'd<> from the `.value` getter
    get value() : CheckedOmit<DeepConst<SystemStore>, "redirectOnLogin">
    {
      return state.value
    },
    /**
     * not a "getter" because we don't want to narrow the result at the callsite, to support calls that will null it out behind the scenes (e.g. performAndConsume)
     */
    getRedirectOnLogin() {
      // shallow copy for minor safety affordance
      return state.value.redirectOnLogin
        ? {...state.value.redirectOnLogin}
        : null
    },
    /**
     * warn: if pushing a route location, don't push live refs to "current route" here, only pass in copies (shallow is ... fine? it's been working)
     * Note that JSON.parse(JSON.stringify(router.currentRoute.value)) seems to work in dev
     * but in a production build it will throw an exception about the object being circular
     */
    setRedirectOnLogin(v : null | Redirection) {
      state.value.redirectOnLogin = v
    },
    performAndConsumeLoginRedirect,
    selectLeague,
    /**
     * @deprecated "are multiple HTTP calls happening" should be a derived value from how many http requests are outstanding. See `GlobalInteractionBlockingRequestsInFlight`.
     */
    setMultipleCalls,
    /**
     * @deprecated "are any HTTP requests in flight" should be a stack or counter of how many requests are outstanding. See `GlobalInteractionBlockingRequestsInFlight`.
     */
    setLoading,
    setAccessPointOptions,
    directCommit_setIsMobile,
    directCommit_setDevicePlatform,
    directCommit_setClientUrl,
    directCommit_setClientLeague,
    directCommit_setMultipleCalls,
    directCommit_setRecaptchaKey,
    directCommit_setTriggerCaptcha,
    directCommit_setOpenSidebar,
    directCommit_setRefundProcessing,
    directCommit_setPaymentProcessing,
    /**
     * returns unknown because it is not intended to introspectable. It triggers reactive updates and that's it
     */
    get navLinkRebuildSignal() : unknown { return navLinkRebuildSignal.value },
    rebuildNavLinks: triggerNavLinkRebuild,
  }
})()
