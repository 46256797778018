import { arrayFindOrFail, vReqT } from "src/helpers/utils";
import { ColName, JsxQuasarColumnRenderShim, AugmentedGamelikeForSchedule, ColDef } from "./page/schedules.ilx"
import { defineComponent } from "vue";
import { RouterLink } from "vue-router";
import { Route as R_BracketView } from "../GameScheduler/brackets/R_BracketView";

export const GamelikeScheduleTableSmall = defineComponent({
  props: {
    columns: vReqT<ColDef[]>(),
    rows: vReqT<AugmentedGamelikeForSchedule[]>(),
  },
  setup(props) {
    const colDef = {
      icons: arrayFindOrFail(props.columns, col => col.name === ColName.icons),
      fieldID: arrayFindOrFail(props.columns, col => col.name === ColName.fieldID),
      gameStart: arrayFindOrFail(props.columns, col => col.name === ColName.gameStart),
      Home: arrayFindOrFail(props.columns, col => col.name === ColName.Home),
      Visitor: arrayFindOrFail(props.columns, col => col.name === ColName.Visitor),
      Comment: arrayFindOrFail(props.columns, col => col.name === ColName.Comment),
      scoreInfo: arrayFindOrFail(props.columns, col => col.name === ColName.scoreInfo),
      refs: arrayFindOrFail(props.columns, col => col.name === ColName.refs),
    } as const;

    return () => {
      return (
        <div>
          {
            props.rows.map(row => {
              return (
                <div class="p-1 border rounded-md bg-white my-4 shadow-md" data-test={`gameID=${row.gameID}`}>
                  <div class="flex items-start">
                    <div class="m-1">
                      <div class="text-xs font-medium">Field</div>
                      <JsxQuasarColumnRenderShim fieldResult={colDef.fieldID.field(row)}/>
                    </div>
                    <div class="m-1">
                      <div class="text-xs font-medium">Date</div>
                      <JsxQuasarColumnRenderShim fieldResult={colDef.gameStart.field(row)}/>
                    </div>
                    <div class="m-1 ml-auto">
                      <JsxQuasarColumnRenderShim fieldResult={colDef.icons.field(row)}/>
                    </div>
                  </div>
                  <div class="border-b"/>
                  <div class="m-1">
                    <div class="text-xs font-medium">Teams</div>
                    <div class="p-1">
                      <div class="text-xs">Home</div>
                      <JsxQuasarColumnRenderShim fieldResult={colDef.Home.field(row)}/>
                    </div>
                    <div class="p-1">
                      <div class="text-xs">Visitor</div>
                      <JsxQuasarColumnRenderShim fieldResult={colDef.Visitor.field(row)}/>
                    </div>
                  </div>
                  {row.bracketInfo
                    ? (<>
                      <div class="border-b"/>
                      <div class="m-1">
                        <div class="text-xs font-medium">Bracket</div>
                        <RouterLink class="il-link" {...{target: "_blank"}} to={R_BracketView(row.bracketInfo)}>
                          {row.bracketInfo.bracketName}, {row.bracketInfo.bracketRoundName}
                        </RouterLink>
                      </div>
                    </>)
                    : null
                  }
                  {row.competition.showRefSchedule
                    ? <>
                      <div class="border-b"/>
                      <div class="m-1">
                        <div class="text-xs font-medium">Refs</div>
                        <JsxQuasarColumnRenderShim fieldResult={colDef.refs.field(row)}/>
                      </div>
                    </>
                    : null
                  }
                  <div class="border-b"/>
                  <div class="m-1">
                    <div class="text-xs font-medium">Comment</div>
                    <div class="ml-1">
                      <span>{row.comment || "<none>"}</span>
                    </div>
                  </div>
                  {
                    row.shouldDisplayScoreInfo
                      ? (
                        <>
                          <div class="border-b"/>
                          <div class="m-1">
                            <div class="text-xs font-medium">Score info</div>
                            <div class="ml-1">
                              <JsxQuasarColumnRenderShim fieldResult={colDef.scoreInfo.field(row)}/>
                            </div>
                          </div>
                        </>
                      )
                      : null
                  }
                </div>
              )
            })
          }
        </div>
      )
    }
  }
})
