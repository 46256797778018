import { rpc } from "src/composables/Rpc"
import { unreachable } from "src/helpers/utils"
import { Integerlike, Datelike, DateTimelike, Guid } from "src/interfaces/InleagueApiV1"
import Stripe from "stripe"

export const getSubscriptionForUpdate = rpc<
  {subscriptionID: Integerlike},
  SubscriptionForUpdate
>("get", "v1/subscriptionUpdate/getSubscriptionForUpdate")

export const updateSubscription = rpc<{subscriptionID: Integerlike, default_payment_method: string}, void>("post", "v1/subscriptionUpdate/updateSubscription")

export interface SubscriptionForUpdate {
  clientGatewayID: string,
  createConfirm: DateTimelike,
  createdBy: Guid,
  customerPaymentProfileID: `pm_${string}`,
  customerProfileID: `cus_${string}`,
  dateCreated: DateTimelike,
  description: string,
  gatewaySubscriptionID: `sub_${string}`,
  hostIP: string,
  instanceID: Integerlike,
  invoiceNo: string,
  localSubscriptionID: Integerlike,
  methodID: Integerlike,
  nextUpdate: DateTimelike,
  paymentNum: Integerlike,
  seasonID: Integerlike,
  /**
   * enum-like "active" | "suspended" | "completed" | ...
   * TODO: backend canonicalize these in the db, there are things like "active" "Active" etc.
   * left `unknown` to discourage direct use without canonicalization
   */
  status: unknown,
  statusConfirm: DateTimelike,
  totalOccurrences: Integerlike,
  trialOccurrences: Integerlike,
  receiptsCount: Integerlike,
  regionName: string,
  publicSite: string,
  appDomain: string,
  timeOffset: string,
  clientID: Guid,
  submitterFirstName: string,
  submitterLastName: string,
  submitterEmail: string,
  stripeSubscription:
    & Stripe.Subscription
    & {
      default_payment_method: Stripe.PaymentMethod,
      latest_invoice: Stripe.Invoice
    }
  /**
   * n.b. this is the "payment schedule" object (aka paymentMethod), not a "stripe paymentMethod"
   */
  paymentMethod: {
    tailoredDescription?: string,
    completionDate: Datelike,
  }
}

/**
 * ideally these values would be canonicalized in the db
 */
export const __fixme__canonicalizeInleagueSubscriptionStatus = (sub: Pick<SubscriptionForUpdate, "status">) => {
  const v = (sub.status as string).toLowerCase()
  switch (v) {
    case "active":
      // fallthrough
    case "cancelled":
      // fallthrough
    case "complete":
      // fallthrough
    case "suspended":
      return v
    default: unreachable()
  }
}
