import type { AxiosInstance } from "axios";
import type { Invoice } from "src/interfaces/Store/checkout";

import * as iltypes from "src/interfaces/InleagueApiV1"

/**
 * should exactly mirror qInvoiceInstance#LastStatus_t on server
 *
 * The "nullish" state means the same as "created"; both must be check to know determine if we're in the "created" stated.
 * Ask the db guys if we can collapse nullish into created, and make the nullish state impossible.
 */
export const InvoiceLastStatus_t = {
  NULLISH: "",
  CREATED: "Created",
  DELETED: "Deleted",
  PAID_AND_PROCESSED: "Paid and Processed",
  REFUNDED: "Refunded",
  IN_FLIGHT: "In flight",
  PROCESSING_ACH: "Processing/ACH",
  STRIPE_REQUIRES_ACTION: "Stripe/requires-action",
  PAYMENT_REJECTED: "Payment rejected",
  VOIDED: "Voided",
  PAID_OUT_OF_BAND: "paid-out-of-band",
} as const;
export type InvoiceLastStatus_t = (typeof InvoiceLastStatus_t)[keyof typeof InvoiceLastStatus_t];

/**
 * fixme: this is only relevant to a tournament team reg invoice, and should be named accordingly, or removed in place of direct lookup of invoice instance which
 * could be referenced by tournTeamRegLineItem->tournTeam->tournHoldPaymentInvoiceInstanceID->invoice
 */
export async function getAssociatedInvoice(axios: AxiosInstance, args: {rootInvoiceInstanceID: iltypes.Integerlike}) : Promise<Invoice> {
  const response = await axios.get(`v1/invoice/associated/tournamentTeamReg`, {params: {rootInvoiceInstanceID: args.rootInvoiceInstanceID}});
  return response.data.data;
}
