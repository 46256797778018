import { axiosInstance } from "src/boot/AxiosInstances";
import { getInvoice } from "src/composables/InleagueApiV1";
import { assertNonNull, dollarFormat, vReqT } from "src/helpers/utils";
import { Guid, Integerlike, Invoice } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent, onMounted, ref } from "vue";
import { getInvoiceTemplatePaymentMethods, InvoiceTemplatePaymentMethod, updateInvoiceTemplateBasedInvoiceInstancePaymentMethodID } from "./InvoiceTemplates.io";
import { FormKit } from "@formkit/vue";
import { Btn2 } from "../UserInterface/Btn2";
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper";
import { useRouter } from "vue-router";
import { LastStatus_t } from "src/interfaces/Store/checkout";
import * as R_Checkout from "src/components/Payment/pages/Checkout.route"
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import * as R_MasterInvoice from "src/components/Payment/pages/MasterInvoice.route"

export default defineComponent({
  props: {
    invoiceInstanceID: vReqT<Integerlike>(),
  },
  setup(props) {
    const router = useRouter()
    const invoice = ref<Invoice | null>(null)

    const kDefaultPaymentMethodID = "default"

    const paymentMethods = (() => {
      const v = ref<(InvoiceTemplatePaymentMethod & {tailoredDescription: string})[]>([])
      const augmentedWithDefault = computed(() => [
        "default" as const satisfies typeof kDefaultPaymentMethodID,
        ...v.value
      ])

      const load = async (args: {invoiceTemplateID: Guid}) => {
        v.value = await getInvoiceTemplatePaymentMethods(axiosInstance, {invoiceTemplateID: args.invoiceTemplateID, invoiceInstanceID: props.invoiceInstanceID})
      }

      return {
        load,
        get notIncludingDefaultMethod() { return v.value },
        get all() { return augmentedWithDefault.value },
      }
    })()

    const ready = ref(false)

    const selectedPaymentMethodID = ref<(typeof kDefaultPaymentMethodID) | Integerlike>(kDefaultPaymentMethodID)

    const doHandleSubmit = async () : Promise<void> => {
      try {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          assertNonNull(invoice.value)
          await updateInvoiceTemplateBasedInvoiceInstancePaymentMethodID(
            axiosInstance, {
              invoiceInstanceID: invoice.value.instanceID,
              paymentMethodID: selectedPaymentMethodID.value === kDefaultPaymentMethodID
                ? ""
                : selectedPaymentMethodID.value
            })

          await router.push(R_Checkout.routeDetailToRouteLocation({invoiceInstanceIDs: [props.invoiceInstanceID]}))
        })
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }
    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        invoice.value = await getInvoice(axiosInstance, {instanceID: props.invoiceInstanceID, expand: ["invoiceTemplateDesc"]});

        if (!invoice.value.invoiceID) {
          // not invoice template based, not really anything we can do
          // we shouldn't even be here
          await router.push(R_Checkout.routeDetailToRouteLocation({invoiceInstanceIDs: [props.invoiceInstanceID]}))
          return;
        }

        switch (invoice.value.lastStatus) {
          case LastStatus_t.NULLISH:
          case LastStatus_t.CREATED:
          case LastStatus_t.PAYMENT_REJECTED:
            break;
          default:
            // This invoice can't be interacted with in the way this page needs to interact with it
            // Fallback to the main invoice display page
            await router.push(R_MasterInvoice.routeDetailToRoutePath({name: "master-invoice", invoiceID: props.invoiceInstanceID}))
            return;
        }

        await paymentMethods.load({invoiceTemplateID: invoice.value.invoiceID})

        {
          const inv = invoice.value
          selectedPaymentMethodID.value = paymentMethods
            .notIncludingDefaultMethod
            .find(pm => pm.methodID /*weakEq*/ == inv.methodID)?.methodID
            || kDefaultPaymentMethodID
        }

        ready.value = true
      })
    })

    const defaultPaymentMethodDescription = computed(() => {
      if (invoice.value === null) {
        return ""
      }

      const amount = dollarFormat(invoice.value.lineItemSum as any)
      return `One-time payment of $${amount}`
    })

    const radioName = "il-paymentMethod-for-invoice-template-based-invoice"

    return () => {
      if (!ready.value || !invoice.value) {
        return null
      }

      return <div style="--fk-border:none; --fk-padding-fieldset:none; --fk-margin-outer:none; --fk-bg-input:white;">
        <h2>{invoice.value.invoiceLabel}</h2>
        <p class="my-2" v-html={invoice.value.invoiceTemplateDesc}/>
        <p class="my-2" v-html={invoice.value.instanceDesc}/>
        <p class="my-2">Choose one of the available payment schedules:</p>
        <div class="p-2 bg-white rounded-md border">
          <FormKit type="form" actions={false} onSubmit={() => { doHandleSubmit() }}>
            <FormKit type="hidden" v-model={selectedPaymentMethodID.value} validation={[["required"]]} validationMessages={{required: "Select a payment schedule."}}/>
            {paymentMethods.all.map(pm => {
              const effectiveMethodID = pm === kDefaultPaymentMethodID ? kDefaultPaymentMethodID : pm.methodID
              return <div>
                <label class="inline-flex gap-2 items-center ml-1 my-2">
                  <FormKit
                    type="radio"
                    class="transition"
                    options={[{
                      label: pm === kDefaultPaymentMethodID ? defaultPaymentMethodDescription.value : pm.tailoredDescription,
                      value: effectiveMethodID,
                      attrs: {
                        "data-test": `methodID=${effectiveMethodID}`
                      }
                    }]}
                    v-model={selectedPaymentMethodID.value}
                    name={radioName}
                  />
                </label>
              </div>
            })}
            <Btn2 type="submit" data-test="submit-button" class="my-2 px-2 py-1">Proceed to Payment</Btn2>
          </FormKit>
        </div>
      </div>
    }
  }
})
