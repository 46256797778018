<template lang="pug">
div
  div(v-if="busy")
  div(v-else class="flex flex-col")
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .shadow.overflow-hidden.border-b.border-gray-200(class='sm:rounded-lg')
          table.min-w-full.divide-y.divide-gray-200
            thead.bg-gray-50
              tr
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200(colspan="7")
                  | Transactions for this Player
              tr(v-if="playerDetails.transactions.length>0")
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200
                  | Date
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200
                  | Type
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200
                  | Amount
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200
                  | Paid Via
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200
                  | Detail
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200
                  | Submitted By
                th.px-6.py-3.text-center.text-sm.font-semibold.text-gray-700.uppercase.tracking-wider.bg-gray-200
                  | Invoice #
            tbody.divide-y.divide-gray-200(v-if='playerDetails.transactions.length>0')
              tr(v-for="transaction in playerDetails.transactions" :data-test="`transactionID=${transaction.transactionID}`")
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900.text-right
                  | {{ dayJSDate(transaction.transactionDate) }}, {{ dayJSTime(transaction.transactionDate) }}
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500.bg-stone-200
                  | {{ transaction.transactionType }} {{ transaction.transactionType == 'Check' ? `(${transaction.checkNum})`: ''}}
                td.px-6.py-4.whitespace-nowrap.text-sm.font-medium.text-gray-900.text-right
                  | ${{ transaction.absoluteAmount }}
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500.bg-stone-200
                  | {{ transaction.lastfour ? transaction.lastfour : '' }}
                  | {{ transaction.checkNum ? transaction.checkNum : ''}}
                  | {{ !transaction.lastfour && !transaction.checkNum ? 'Cash' : '' }}
                td.px-6.py-4.text-sm.text-gray-500.bg-stone-200.break-normal
                  | {{ transaction.transactionDetail }} ({{ transaction.isReceipt ? 'Receipt' : 'Credit'}})
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500.bg-stone-200
                  a(:href='`mailto:${transaction.submitterEmailAddr}`' class="il-link")
                    font-awesome-icon.mr-2(
                      :icon='["fas", "envelope-open"]',
                    )
                    | {{ transaction.submitterFirstName }} {{ transaction.submitterLastName }}
                td.px-6.py-4.whitespace-nowrap.text-sm.text-gray-500.bg-stone-200
                  //- not all transactions have an invoice instance id, don't show a link if there isn't an invoice instance id
                  div(
                    class="flex gap-1 items-center"
                    v-if="transaction.invoiceInstanceID && authZ_invoiceRoute(transaction)"
                  )
                    router-link(
                      class="il-link"
                      :to="{name: 'master-invoice', params: {invoiceID: transaction.invoiceInstanceID }}"
                    )
                      | Invoice {{ transaction.invoiceNo }}
                  div(v-else) {{ transaction.invoiceNo }}
                  div(class="my-1 border-b border-gray-400 border-dashed")
                  div
                    TransactionIDWithMaybeReceiptURLElement(:transaction="transaction")
            div.italic.text-center.my-4(v-else) No Transactions are Associated with this Registration
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue'
import type { PlayerDetailsI } from 'src/interfaces/Store/registration'
import { computed } from 'vue'
import { dayJSDate, dayJSTime } from 'src/helpers/formatDate'
import authService from 'src/helpers/authService'
import familyAuth from 'src/composables/familyAuth'
import { User } from 'src/store/User'
import { parseIntOr, parseIntOrFail, vReqT } from 'src/helpers/utils'
import { TransactionIDWithMaybeReceiptURLElement } from "src/components/Payment/TransactionIDWithMaybeReceiptURLElement"
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'
import { getCompetitionUIDsForInvoices } from '../PlayerEditor.io'
import { freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'
import { CompetitionUID, Guid, Integerlike } from 'src/interfaces/InleagueApiV1'

export default defineComponent({
  components: {
    TransactionIDWithMaybeReceiptURLElement
  },
  props: {
    playerDetails: vReqT<PlayerDetailsI>(),
  },
  setup(props) {
    const { isChildRelated } = familyAuth()

    type InvoiceInstanceID = number
    type PerInvoiceCompUIDs = Map<InvoiceInstanceID, Set<CompetitionUID>>
    const perInvoiceCompUIDsResolver = ReactiveReifiedPromise<PerInvoiceCompUIDs>()

    watch(() => props.playerDetails.transactions, () => {
      const invoiceInstanceIDs = props
        .playerDetails
        .transactions
        .map(v => parseIntOr(v.invoiceInstanceID, null))
        .filter(v => v !== null)

      perInvoiceCompUIDsResolver.run(async () => {
        const data = await getCompetitionUIDsForInvoices(freshNoToastLoggedInAxiosInstance(), {invoiceInstanceIDs})
        const result : PerInvoiceCompUIDs = new Map()

        for (const m of data) {
          const invoiceInstanceID = parseIntOrFail(m.invoiceInstanceID)
          const competitionUID = m.competitionUID
          const compUIDs = result.get(invoiceInstanceID) ?? new Set()
          result.set(invoiceInstanceID, compUIDs)
          compUIDs.add(competitionUID)
        }

        return result
      })
    }, {immediate: true})

    type Transaction = PlayerDetailsI["transactions"][number]
    const authZ_invoiceRoute = (xaction: Transaction) : boolean => {
      if (perInvoiceCompUIDsResolver.underlying.status !== "resolved") {
        return false;
      }

      if (isChildRelated({childID: props.playerDetails.childID, childList: User.userData?.belongingChildrenIDs ?? []})) {
        return true
      }

      if (authService(User.userData?.roles ?? [], 'registrar', 'webmaster')) {
        return true
      }

      if (xaction.invoiceInstanceID) {
        const invoiceInstanceID = parseIntOrFail(xaction.invoiceInstanceID)
        const relatedCompetitionUIDs = perInvoiceCompUIDsResolver.underlying.data.get(invoiceInstanceID)

        if (!relatedCompetitionUIDs) {
          return false
        }

        return !!User
          .userData
          ?.competitionsMemento
          .find(compMgr => compMgr.isPaymentAdmin && relatedCompetitionUIDs.has(compMgr.competitionUID))
      }

      return false
    }

    return {
      dayJSDate,
      dayJSTime,
      authZ_invoiceRoute,
      busy: computed(() => perInvoiceCompUIDsResolver.underlying.status === "pending")
    }
  }
})
</script>
