import authService from "src/helpers/authService";
import { User } from "src/store/User";
import type { RouteLocationRaw } from "vue-router"

export const RouteNames = {
  root: "PlayerRatings.main",
  clientConfig: "PlayerRatings.clientConfig",
  rate: "PlayerRatings.rate",
  report: "PlayerRatings.report",
} as const;

export type RouteName = (typeof RouteNames)[keyof typeof RouteNames]

interface RoutePropsBase {
  routeName: RouteName
}

export type RoutePropsVariant =
  | R_ClientConfig
  | R_Rate
  | R_Report

export interface R_ClientConfig extends RoutePropsBase {
  routeName: typeof RouteNames.clientConfig,
}

export interface R_Rate extends RoutePropsBase {
  routeName: typeof RouteNames.rate,
}

export interface R_Report extends RoutePropsBase {
  routeName: typeof RouteNames.report,
}

export function routeDetailToRouteLocation(v: RoutePropsVariant) : RouteLocationRaw {
  const {routeName, ...params} = v
  return {name: v.routeName, params}
}

// export function routeLocationToProps(route: RouteLocationNormalized) : Props {
//   return {}
// }

export function hasSomeRoutePermission() : boolean {
  return authZ_canDefineRatings() || authZ_canDoCoachPlayerRatings() || authZ_canDoRegionalPlayerRatings();
}

export function authZ_canDoCoachPlayerRatings() {
  // is coach now, or was coach in the past
  return !!User.userData?.isCoach
    || authZ_canDoRegionalPlayerRatings()
}

export function authZ_canDoRegionalPlayerRatings() {
  return authService(User.userData?.roles ?? [], "compManager", "CompetitionManager", "director", "Division Coordinator", "Division Director", "playerAdmin")
}

export function authZ_canDefineRatings() {
  return authService(User.userData?.roles ?? [], "webmaster", "inLeague")
}

export function authZ_accessRatingsReport() {
  return authService(User.userData?.roles ?? [], "compManager", "CompetitionManager", "director", "Division Coordinator", "Division Director", "playerAdmin")
}
