import { buildLegacyLink } from 'src/boot/auth';
import { makeLegacyTournamentRosterLink_justPathAndQuery } from './PlayerLookup.shared';

import * as iltypes from "src/interfaces/InleagueApiV1"

export function TeamAssignmentsLegacyLinkListing(props: {appDomain: string, teamAssignments: iltypes.TeamAssignment[]}) : JSX.Element {
  const makeHref = (teamAssignment: iltypes.TeamAssignment) => buildLegacyLink(
    props.appDomain,
    `https://${props.appDomain}${makeLegacyTournamentRosterLink_justPathAndQuery(teamAssignment)}`,
    ""
  );

  return (
    <>
      {
        props.teamAssignments.map(teamAssignment => {
          return (
            <div>
              <a
                href={makeHref(teamAssignment)} target="_blank"
                class="text-blue-700 underline cursor-pointer"
              >
                {teamAssignment.teamDesignation}
              </a>
            </div>
          )
        })
      }
    </>
  );
}
