import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { InvoiceLastStatus_t as LastStatus_t } from "src/composables/InleagueApiV1.Payments"
import { Floatlike, Guid, Integerlike, Numbool } from "src/interfaces/InleagueApiV1";
export { InvoiceLastStatus_t as LastStatus_t } from "src/composables/InleagueApiV1.Payments"

// ### api/v1/invoice
export interface Transaction {
  transactionDate: string;
  invoiceNo: string;
  refTransID: string;
  gatewaySubscriptionID: string;
  seasonName: string;
  customerProfileID: string;
  submitterFirstName: string;
  checkNum: string;
  transactionID: string;
  transactionType: string;
  customerPaymentProfileID: string;
  invoiceInstanceID: number;
  cardType: string;
  submitterEmailAddr: string;
  absoluteAmount: number;
  localSubscriptionID: string;
  transactionSubmitter: string;
  transactionAmount: number;
  lastfour: string;
  transactionDetail: string;
  gatewayTransactionID: string;
  clientID: string;
  hostIP: string;
  isReceipt: number;
  stripe_creditNoteID: string;
  submitterLastName: string;
  clientGatewayID: string;
  registrationID: string;
  gatewayInvoiceURL: string;
  seasonID: number;
  application_fee_amount: number,
}

export interface CreditNote {
  creditNoteLineItemID: number;
  createdBy: string;
  lineItemID: number;
  stripe_cnID: string;
  transactionID: string;
  stripe_cnLineItemID: string;
  stripe_lineItemID: string;
  amount: string;
}

/**
 * LineItem is the union of LineItemBase with all specializations
 */
export type LineItem = LineItemBase & TypeLookupLineItemSpecialization[LineItemEntityType];

/**
 * A single lineItem specialization, generically indexed by entity typename.
 * This represents a lineItem that "contains a competition registration",
 * or that "contains an event signup", or etc.
 */
export type LineItemSpecialization<K extends LineItemEntityType> = LineItemBase & TypeLookupLineItemSpecialization[K];

type LineItemEntityType = keyof TypeLookupLineItemSpecialization;

/**
 * `entity` is an expandable in those specializations that have an entity property, it is not currently guaranteed to
 * be expanded from all endpoints (really all specializations have an entity property but we don't care about some of them right now)
 *
 * Would be cool if we could constrain this to require that nested types satisfy some constraint
 * (has at least entity_type, if entity is present it is optional, and the value at entity_type is the same as that of the key "one level up")
 *
 * Because we cannot constrain (or just don't know how), we roughly specify the concept:
 * <S extends string> -> {[key: S]: {entity_type: S, entity?: unknown, ...rest}}
 *
 * We have to use `0` as a marker for {unknown,any} in the `entity?: T` position (until we get those entity types defined),
 * so that `LineItem["entity"]` is both valid AND does not collapse to {unknown,any}
 */
interface TypeLookupLineItemSpecialization {
  "invoiceTemplateDummyLine": {
    entity_type: "invoiceTemplateDummyLine"
    entity?: undefined, // well, we could make this a ref to the invoice template itself? The owning invoiceInstance references that though.
  },
  "qDonation_PartialityShim": {
    entity_type: "qDonation_PartialityShim"
    entity?: 0,
  },
  "qDonation": {
    entity_type: "qDonation"
    entity?: 0
  },
  /**
   * todo: the Event system does a lot of post processing on receipt to clean up an EventSignup and make it an app-centric
   * EventSignup, does that happen here? Do we get some shape that is conceptually an EventSignup but structurally very different?
   * Here we've tagged the EventSignup such that is not assignable (without a cast) to<->from an "actual" (read: properly post-processed) EventSignup,
   * which should make it very clear that "it might not have the properties we think it has"
   */
  "qEventSignup": {
    entity_type: "qEventSignup",
    /**
     * the invoiced event signup
     */
    eventSignupID: string,
    /**
     * the event signup currently "active" for this invoice line item
     * generally, will be nullish, meaning "the eventSignupID field is really the active signup",
     * otherwise, this represents the event signup that the earlier invoiced signup was moved to (always up to date with the most-recent move)
     */
    eventSignup_movedTo: "" | {
      eventSignupID: iltypes.Guid,
      eventName: string,
      /**
       * should always be the outer eventSignupID
       */
      eventSignupID_movedFrom: string,
      movedBy_userID: iltypes.Guid,
      movedBy_firstName: string,
      movedBy_lastName: string,
      movedDate: iltypes.DateTimelike,
      movedComment: string,
    },
    entity?: (Partial<ilapi.event.EventSignup> & {__thisTypeProbablyIsRawApiShape__: "check it first"});
  },
  "qCompetitionRegistration": {
    entity_type: "qCompetitionRegistration",
    competitionRegistrationID: string,
    entity?: iltypes.CompetitionRegistration
  },
  "qTournament_teamReg": {
    entity_type: "qTournamentTeam_teamReg",
    tournamentTeamID_teamReg: iltypes.Integerlike,
    entity?: 0
  },
  "qTournament_holdPayment": {
    entity_type: "qTournamentTeam_holdPayment",
    tournamentTeamID_holdPayment: iltypes.Integerlike,
    entity?: 0
  }
}

/**
 * LineItemBase is most of a lineItem, omitting the underlying entity information,
 * which is specialized per underlying type and defined elsewhere.
 */
interface LineItemBase {
  scholarshipID: string;
  creditNotes: CreditNote[];
  couponID: string;
  lineItemID: number;
  canceledDate: string;
  discount: number;
  stripe_lineItemID: string;
  discountable: number;
  finalAmount: number;
  description: string;
  amount: string;
  localSubscriptionID: string;
  invoiceTemplateID: string;
  stripe_priceID: string;
  stripe_invoiceItemID: string;
  amountPaidBalance: number;
  createdBy: string;
  instanceID: number;
  processed: boolean;
  canceled: boolean;
  creditNoteSum: number;
  teamID: string;
  registrationID: string;
  sponsorshipID: string;
  /**
   * number representing boolean
   */
  paymentBlock_isBlocked: number | boolean
  paymentBlock_comments: string,
  paymentBlock_removedBy: "" | iltypes.Guid,
  paymentBlock_removedOn: "" | iltypes.DateTimelike
  /**
   * expandable
   * the invoice that owns this line item
   */
  invoice?: Invoice
}

export interface Invoice {
  /**
   * PK
   */
  instanceID: number;
  /**
   * FK to "invoice templates"
   * If non-nullish, it means "is a subscription invoice using the referenced invoice template"
   */
  invoiceID: "" | Guid,
  /**
   * FK to payment method, only relevant when invoiceID is non nullish.
   */
  methodID: "" | Integerlike,
  couponCode: any;
  adminAccess: string;
  instanceDesc: string;
  /**
   * optional, expandable from some endpoints
   */
  invoiceTemplateDesc?: string;
  userLastName: string;
  lineItemSum: Floatlike;

  entityID: string;
  regionInvoiceNum: string;
  submitterFirstName: string;
  closed: number;
  status: string;
  emailEveryReceipt: string;
  /**
   * Date at which invoice was marked fully paid or voided.
   * Falsy means "hasn't happened yet"
   */
  closeDate: "" | iltypes.DateTimelike;
  dateCreated: string;
  invoiceAmount: number;
  childLastName: string;
  childFirstName: string;
  paymentGatewayID: string;
  transactionBalance: number;
  timeOffset: number;
  lastStatus: LastStatus_t;
  lastStatusDate: iltypes.DateTimelike | "";
  lastStatusDetail: string,
  transactions?: Transaction[];
  contactEmail: string;
  emailOnCreation: number;
  parent1Email: string;
  transactionCount: number;
  invoiceLabel: string;
  createdBy: string;
  clientID: string;
  stripe_invoiceNumber: string;
  instanceLabel: string;
  submitterLastName: string;
  childNum: string;
  userNum: number;
  paid: number;
  userFirstName: string;
  gatewayInvoiceURL: string;
  seasonUID: string;
  userEmail: string;
  percentDiscount: number;
  dollarDiscount: number,
  lineItems: LineItem[];
  /**
   * falsy means "none on file",
   * otherwise should be a stripe payment_method id
   */
  stripe_paymentMethodID: "" | `pm_${string}`,
  stripe_customer: "" | `cus_${string}`,
  /**
   * User was last sent an email at this datetime.
   * Implicitly, this notification is for "your competition registration is available for payment"
   *
   * Falsy means such a notification has never been sent (very often, such notifications don't need to be sent, anyway)
   */
  datetimeOfLastUserNotification: "" | iltypes.DateTimelike,
  /**
   * is this invoice expired?
   * An invoice can only expire if it hasn't been paid in some time limit.
   * A user should be notified of this state, and probably pushed back into the flow that generated it.
   */
  isExpired: iltypes.Numbool,
  /**
   * payment schedule info (how much, how often, how many times) if this is a subscription invoice
   */
  subscriptionPaymentSchedule: null | InvoiceTemplatePaymentMethod,
  hasActiveSubscription: number | boolean,
  /**
   * expandable
   */
  allowedPaymentMethods?: ("card" | "us_bank_account")[],
}

export interface InvoiceTemplatePaymentMethod {
  methodID: Integerlike,
  /**
   * FK to invoice template
   */
	invoiceID: Integerlike,
  /**
   * time between payments in `intervalUnit` units
   */
	intervalLength: Integerlike,
	intervalUnit: "days" | "months",
	totalOccurrences: Integerlike,
  /**
   * Amount per occurrence
   */
	amount: number,
	disabled: Numbool,
  trialOccurrences: Integerlike,
  /**
   * amount per trial occurence
   */
  trialAmount: number,
}

export function invoiceLastStatusAsUiString(status: LastStatus_t) {
  switch (status) {
    case LastStatus_t.NULLISH:
      // fallthrough (same as created ... right?)
    case LastStatus_t.CREATED:
      return "Created";
    case LastStatus_t.DELETED:
      return "Deleted";
    case LastStatus_t.PAID_AND_PROCESSED:
      return "Paid (complete)";
    case LastStatus_t.REFUNDED:
      return "Refunded"
    case LastStatus_t.IN_FLIGHT:
      return "Payment in progress (awaiting provider approval)"
    case LastStatus_t.PROCESSING_ACH:
      return "Payment in progress (ACH processing)"
    case LastStatus_t.PAYMENT_REJECTED:
      return "Rejected";
    case LastStatus_t.VOIDED:
      return "Voided";
    case LastStatus_t.PAID_OUT_OF_BAND:
      return "Paid (out of band, e.g. via check or cash)"
    case LastStatus_t.STRIPE_REQUIRES_ACTION:
      return "Needs bank account verification";
    default:
      // exhaustiveCaseGuard usually comes from an import, but trying to to not have that dependency in this file
      ((v:never) => {throw "non-exhaustive case or unintentional fallthrough"})(status);
  }
}

// POST /api/v1/invoice/:invoiceID/refund

export interface RefundLineItems {
  lineItemID: string
  cancel: boolean
  amount?: string
}

export interface RefundOptions {
  lineItems: RefundLineItems[]
  idempotencyKey: string
  creditType?: string
  checkNum?: string
  refundMsg?: string
  cancelSubscription?: boolean
  reconciliationOnly?: boolean,
}

export interface CheckoutI {
  eventsCart: { [eventID: iltypes.Guid]: /* eventSignupID */ iltypes.Guid[] }
  /**
   * map (questionID -> answers)
   * should this be (entityID -> questionID -> answers) ?
   * is this redundant, we have eventSignupID -> EventSignup, where signup has EventQuestionAnswer[]
   */
  //questionAnswers: { [evenSignupID: iltypes.Guid]: ilapi.event.EventQuestionAnswer[] }
  // This is like "eventSignups that constitue the current eventsignup checkout flow"
  // should probably support `null` being "no current flow"?
  // eventSignupID -> EventSignup
  registrants: { [eventSignupID: iltypes.Guid]: iltypes.WithDefinite<ilapi.event.EventSignup, "computed_feeAfterVisibleDiscounts"> }
  /**
   * will be expanded to include lineItems.entity
   */
  invoice: { [invoiceInstanceID: /*Integerlike*/ string]: Invoice }
  unpaidInvoices: { [invoiceInstanceID: /*Integerlike*/ string] : /*invoice instanceLabel*/ string }
  /**
   * map (eventSignupID -> eventID)
   * todo: refactor name ("eventIdByEventSignupID")
   * is this unnecessary? we have a map of eventSignupID -> EventSignup,
   * where an EventSignup has an eventID property ...
   */
  //pairedIDs: { [eventSignupID: iltypes.Guid]: /*eventID*/ iltypes.Guid },
  paymentAttempted: boolean,
  processed: {[key:string]: boolean}
}
