import { assertNonNull, assertTruthy, exhaustiveCaseGuard, requireNonNull, sortBy, unreachable } from "src/helpers/utils";
import { Bracket, BracketRound, BracketRoundSlot, BracketRoundSlotTeamSource } from "./Bracket.io";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { Client } from "src/store/Client";
import dayjs from "dayjs";
import { BracketRoundSlotUiData, NeedsAGame, BracketBuilderTeamWrapper, bracketBuilderTeamWrapper_seed } from "./BracketBuilderElems";
import { HardcodedSeedStrategy } from "./HardcodedSeedStrategy";

export function computeBracketTreeInfo(teamCount: number) {
  assertTruthy(teamCount >= 2, "Team count must be >= 2");

  const totalRounds = Math.ceil(Math.log2(teamCount));
  const overallRequiredTeamsFirstFullRound = 2 ** Math.floor(Math.log2(teamCount));

  // each team that doesn't fit onto the "first full round", represents 2 teams (1 matchup)
  // supplying it from the preceding incomplete round.
  const teamsOnIncompleteFirstRound = 2 * (teamCount - overallRequiredTeamsFirstFullRound)
  const needsPlayinGames = !!teamsOnIncompleteFirstRound
  const matchupsOnIncompleteFirstRound = teamsOnIncompleteFirstRound / 2;

  // just an alias, all the matchups on the first incomplete round are pulled forwards
  // as a single team each into the subsequent round
  const teamsMovedForwardFromFirstIncompleteRound = matchupsOnIncompleteFirstRound
  const teamsNeededForSeedingOnFirstFullRound = overallRequiredTeamsFirstFullRound - teamsMovedForwardFromFirstIncompleteRound

  const totalGamesRequired = (() => {
    if (needsPlayinGames) {
      const nonPlayinMatchups = (2 ** (totalRounds - 1)) - 1
      const playinMatchups = matchupsOnIncompleteFirstRound
      return nonPlayinMatchups + playinMatchups
    }
    else {
      const nonPlayinMatchups = (2 ** totalRounds) - 1
      return nonPlayinMatchups
    }
  })()

  // (teamCount - 1) is the better math; but, all the numbers should jive
  assertTruthy(totalGamesRequired === teamCount - 1)

  return {
    totalRounds,
    overallRequiredTeamsFirstFullRound,
    teamsOnIncompleteFirstRound,
    matchupsOnIncompleteFirstRound,
    teamsNeededForSeedingOnFirstFullRound,
    totalGamesRequired,
  }
}

/**
 * Convert a list of teams into a bracket.
 * Generates both the bracket proper, and the out-of-band "uiData".
 */
export function poolToBracket(
  seededTeams: readonly BracketBuilderTeamWrapper[],
  args: {
    seasonUID: Guid,
    competitionUID: Guid,
    divID: Guid,
    seedStrategy: SeedStrategy,
}) {
  const sortedTeams = [...seededTeams].sort(sortBy(v => bracketBuilderTeamWrapper_seed(v)))
  return poolToBracketWorker(sortedTeams, args)
}

function poolToBracketWorker(
  seededTeams: readonly BracketBuilderTeamWrapper[],
  args: {
    seasonUID: Guid,
    competitionUID: Guid,
    divID: Guid,
    seedStrategy: SeedStrategy,
}) {
  const builder = bracketBuilderFromSeedStrategy(seededTeams.length, args.seedStrategy)

  const uiDataByBracketRoundSlotID = new Map<Integerlike, BracketRoundSlotUiData["form"]>();

  const bracketInfo = computeBracketTreeInfo(seededTeams.length)

  const freshBracket : Bracket = {
    bracketID: nextLocalBracketIDLike(),
    seasonUID: args.seasonUID,
    competitionUID: args.competitionUID,
    divID: args.divID,
    clientID: Client.value.instanceConfig.clientid,
    bracketRounds: [],
    bracketName: `${Client.value.instanceConfig.regionname} bracket ${dayjs().format("MMM/DD/YYYY")}`,
    seedStrategyID: args.seedStrategy.seedStrategyID,
  }

  for (let roundIdx = 0; roundIdx < builder.length; roundIdx++) {
    const roundDriver = builder[roundIdx]
    const bracketRound : BracketRound = {
      bracketID: freshBracket.bracketID,
      bracketRoundNum: roundIdx + 1, // one indexed
      bracketRoundName: getBracketRoundName(roundIdx + 1, bracketInfo.totalRounds),
      bracketRoundSlots: []
    }

    freshBracket.bracketRounds.push(bracketRound)

    for (let slotIdx = 0; slotIdx < roundDriver.length; slotIdx++) {
      const slotDriver = roundDriver[slotIdx]
      const bracketRoundSlotID = nextLocalBracketIDLike()
      uiDataByBracketRoundSlotID.set(bracketRoundSlotID, {
        type: "game",
        gameID: slotDriver.schedule === "empty" ? "" : NeedsAGame,
        homeTeam: slotDriver.schedule === "empty" ? null
          : slotDriver.schedule === "pending" ? null
          : slotDriver.schedule === "visitor" ? null
          : (slotDriver.schedule === "both" || slotDriver.schedule === "home") ? seededTeams[slotDriver.oi_homeSeed - 1]
          : exhaustiveCaseGuard(slotDriver.schedule),
        visitorTeam: slotDriver.schedule === "empty" ? null
          : slotDriver.schedule === "pending" ? null
          : slotDriver.schedule === "home" ? null
          : (slotDriver.schedule === "both" || slotDriver.schedule === "visitor") ? seededTeams[slotDriver.oi_visitorSeed - 1]
          : exhaustiveCaseGuard(slotDriver.schedule),
        disabled: slotDriver.schedule === "empty",
      })
      const bracketRoundSlot : BracketRoundSlot = {
        bracketID: freshBracket.bracketID,
        bracketRoundNum: roundIdx + 1, // one indexed
        bracketRoundSlotID,
        type: "game",
        gameID: "",
        game: null,
        teamID: "",
        intraBracketRoundOrdinal: slotIdx + 1, // one indexed
        sourceLeft: bracketRoundSlotSourceOrNil(roundIdx, slotIdx, "left", bracketRoundSlotID),
        sourceRight: bracketRoundSlotSourceOrNil(roundIdx, slotIdx, "right", bracketRoundSlotID),
        disabled: slotDriver.schedule === "empty",
        __dev__seeds: `${slotDriver.oi_homeSeed}/${slotDriver.oi_visitorSeed}`
      }
      bracketRound.bracketRoundSlots.push(bracketRoundSlot)
    }
  }

  return {
    freshBracket,
    uiDataByBracketRoundSlotID,
  }

  function bracketRoundSlotSourceOrNil(
    roundIdx: number,
    slotIdx: number,
    which: "left" | "right",
    target_bracketRoundSlotID: number,
  ) : BracketRoundSlotTeamSource | null {
    if (roundIdx === 0) {
      return null
    }

    const offset = which === "left" ? 0 : 1
    const idx = (slotIdx * 2) + offset
    const priorSlot = freshBracket.bracketRounds[roundIdx-1].bracketRoundSlots[idx]

    return {
      source_bracketRoundSlotID: priorSlot.bracketRoundSlotID,
      target_bracketRoundSlotID: target_bracketRoundSlotID,
      sourceType: "winner",
      sourceDir: which,
    }
  }
}

const nextLocalBracketIDLike = (() => {
  let i = -1
  return () => i--
})()

function getBracketRoundName(oi_roundIdx: number, totalRounds: number) {
  return oi_roundIdx === totalRounds
    ? "Finals" // last round
    : oi_roundIdx === totalRounds - 1
    ? "Semifinals" // 2nd to last round
    : oi_roundIdx === totalRounds - 2
    ? "Quarterfinals" // 3rd to last round
    : `Round ${oi_roundIdx}` // everything else gets a generic name
}

export function bracketInfoMessage(teamCount: number) {
  const treeInfo = computeBracketTreeInfo(teamCount)
  const totalRounds = treeInfo.totalRounds
  const totalGames = treeInfo.totalGamesRequired

  const totalRoundsMsg = totalRounds === 1 ? "1 round" : `${totalRounds} rounds`
  const gamesMsg = totalGames === 1 ? "1 game" : `${totalGames} games`
  const roundNames = [...new Array(totalRounds)]
    .map((_,i) => i + 1)
    .map(roundIdx => getBracketRoundName(roundIdx, totalRounds)).join(", ")

  return `A bracket with ${totalRoundsMsg} (${roundNames}) and ${gamesMsg} will be generated in preview mode.`
}

/**
 * https://en.wikipedia.org/wiki/Gray_code#Constructing_an_n-bit_Gray_code
 */
export function computeGrayCode(bits: number) : string[] {
  if (bits === 0) {
    return []
  }

  let code : string[] = ["0", "1"] // represents n=1 case

  // start at 1 because n=1 case is reflected in the initialization
  for (let i = 1; i < bits; i++) {
    code = [
      ...code.map(v => `0${v}`),
      ...code.reverse().map(v => `1${v}`)
    ]
  }

  return code
}

type SlotSchedulingIntent =
  | "home"
  | "visitor"
  | "both"
  | "empty"
  | "pending"

/**
 * This uses a default algorithm in the general case, with some provision for using hardcoded brackets per teamcount.
 *
 * default algo:
 * For number of teams N, construct the "gray code" (see wikipedia for that) for the number of bits required to hold N
 * (so for N = 5 teams, you need the gray code for 3 bits, where 2**3 can hold up to 8 teams). Reverse all the gray codes
 * (so for a code like 100, reverse it to be 001). Then use each reversed gray code to traverse your tree, like a huffman table
 * where 0 means "go left / homeTeam" and 1 means "go right / visitorTeam". As you traverse the tree, mark each node with the home/visitor seed as per
 * the gray code being used to drive the current traversal. This marks all nodes with their expected optimal seed value, but really
 * you only need the "leaf" nodes marked (or leaf level and 1 level above, if there are playin games). If there are playin games,
 * leaf nodes that end up being marked with a seed value higher than the number of participating teams should be considered "empty nodes"
 * and shouldn't have anything scheduled on them, and parents of empty nodes should have their visitor set to TBD (the home of the parent
 * of an empty node is a bye, and this algorithm should guarantee that the empty node's sibling is itself non-empty, which will serve
 * as the eventual visitor of the empty node's parent).
 *
 * Working this out on paper for small N is pretty easy and gives a sense of what its doing.
 */
const bracketBuilderFromSeedStrategy = (teamCount: number, strategy: SeedStrategy)
: {oi_homeSeed: number, oi_visitorSeed: number, schedule: SlotSchedulingIntent}[][] => {
  type Builder = {
    schedule?: SlotSchedulingIntent,
    parent?: Builder,
    left?: Builder,
    right?: Builder,
    oi_home?: number,
    oi_visitor?: number,
  }
  const treeInfo = computeBracketTreeInfo(teamCount)

  const numRounds = treeInfo.totalRounds

  const builder : {rounds: {slots: Builder[]}[]} = {rounds: []}

  for (let roundIdx = 0; roundIdx < numRounds; roundIdx++) {
    const slots = 2 ** (numRounds - roundIdx - 1)
    builder.rounds.push({
      slots: [...new Array(slots)].map((_,slotIdx) => {
        return {
          __roundIdx: roundIdx,
          __slotIdx: slotIdx
        } as any
      })
    })
  }

  // bind all parent<->child edges
  for (let roundIdx = 0; roundIdx < numRounds-1; roundIdx++) {
    const round = builder.rounds[roundIdx]
    for (let slotIdx = 0; slotIdx < round.slots.length; slotIdx++) {
      const slot = round.slots[slotIdx];
      slot.parent = requireNonNull(builder.rounds[roundIdx + 1].slots[Math.floor(slotIdx / 2)])
      if (slotIdx % 2 === 0) {
        slot.parent.left = slot
        slot.parent.right = round.slots[slotIdx + 1]
      }
    }
  }

  // Are there "playin" games? That is, basically, was the number of teams not exactly a power of 2?
  const firstFullRoundSourcesFromIncompletePriorRound = treeInfo.matchupsOnIncompleteFirstRound > 0
  // The first full round is either round 0 or round 1
  // Games on "the first round when the first round is not a full round" may sometimes be referred to as "playin games"
  const zi_effectiveRoundIdxFirstFullRound = firstFullRoundSourcesFromIncompletePriorRound ? 1 : 0

  // The only "hardcoded" source right now is an object literal for r122, but conceptually it could come from anywhere.
  if (strategy.seedStrategy && teamCount in strategy.seedStrategy) {
    const seedConfig = strategy.seedStrategy[teamCount]
    assertTruthy(builder.rounds.length === seedConfig.length)
    for (let roundIdx = 0; roundIdx < seedConfig.length; roundIdx++) {
      const roundNode = builder.rounds[roundIdx]
      const hardcodedSeedsForRound = seedConfig[roundIdx]

      assertTruthy(roundNode.slots.length === hardcodedSeedsForRound.length)

      for (let slotIdx = 0; slotIdx < hardcodedSeedsForRound.length; slotIdx++) {
        const slot = roundNode.slots[slotIdx]
        const [lSeed, rSeed, intent] = hardcodedSeedsForRound[slotIdx]
        slot.oi_home = lSeed
        slot.oi_visitor = rSeed
        slot.schedule = intent
      }
    }
  }
  else {
    const rootNode = (() => {
      const slotsLastRound = builder.rounds[builder.rounds.length - 1].slots
      assertTruthy(slotsLastRound.length === 1)
      return slotsLastRound[0]
    })();

    const reverseGrayCode = computeGrayCode(Math.ceil(Math.log2(teamCount))).map(v => v.split("").reverse().join(""))

    // TODO: use the graycode to construct a "hardcoded" config and then feed it into the same algo used in the "hardcoded" case
    for (let i = 0; i < reverseGrayCode.length; i++) {
      let workingNode : Builder | undefined = rootNode
      const codeWord = reverseGrayCode[i]
      for (let codeBitIdx = 0; codeBitIdx < codeWord.length; codeBitIdx++) {
        const c = codeWord[codeBitIdx]

        // When we hit a leaf, working node will be undefined in the next iter,
        // but we will never iterate past a leaf.
        assertNonNull(workingNode)

        if (c === "0") {
          if (typeof workingNode.oi_home !== "number") {
            workingNode.oi_home = i+1
          }

          workingNode = workingNode.left
        }
        else if (c === "1") {
          if (typeof workingNode.oi_visitor !== "number") {
            workingNode.oi_visitor = i+1
          }

          workingNode = workingNode.right
        }
        else {
          unreachable()
        }
      }
    }

    if (zi_effectiveRoundIdxFirstFullRound === 1) {
      const firstRoundSlots = builder.rounds[0].slots
      for (const slot of firstRoundSlots) {
        const homeSeed = requireNonNull(slot.oi_home)
        const visitorSeed = requireNonNull(slot.oi_visitor)

        if (homeSeed > teamCount || visitorSeed > teamCount) {
          slot.schedule = "empty"
        }
        else {
          slot.schedule = "both"
        }
      }

      for (const slot of firstRoundSlots) {
        const parent = requireNonNull(slot.parent)
        if ((parent.left?.schedule === "empty" && parent.right?.schedule === "both")
          || (parent.left?.schedule === "both" && parent.right?.schedule === "empty")) {
          parent.schedule = "home"
        }
        else if (parent.left?.schedule === "both" && parent.right?.schedule === "both") {
          parent.schedule = "pending"
        }
        else {
          // nothing
        }
      }
    }
  }

  return builder.rounds.map((round, roundIdx) => {
    return round.slots.map(v => {
      const isSchedulable = roundIdx <= zi_effectiveRoundIdxFirstFullRound
      return {
        oi_homeSeed: requireNonNull(v.oi_home),
        oi_visitorSeed: requireNonNull(v.oi_visitor),
        // TODO: ensure schedule is definitely assigned along all branches
        schedule: v.schedule || (isSchedulable ? "both" : "pending")
      }
    })
  })
}

export type SeedStrategy = {
  seedStrategyID: string,
  name: string,
  /**
   * should only be null for the "default" strat, with the appropriate accompanying seedStrategyID
   */
  seedStrategy: null | HardcodedSeedStrategy,
  desc: string,
}

export const testExports = {
  bracketBuilderFromSeedStrategy
}
