import { Integerlike } from "src/interfaces/InleagueApiV1"
import { RouteLocationRaw, RouteRecordRaw } from "vue-router"

export const routeName = "invoice-template-based-invoice-choose-payment-method"
export const route = (_: {invoiceInstanceID: Integerlike}) : RouteLocationRaw => {
  return {
    name: routeName,
    params: {
      invoiceInstanceID: _.invoiceInstanceID
    }
  }
}
