import { dayjsOr } from "src/helpers/formatDate"
import { vReqT, Sorter, accentAwareCaseInsensitiveCompare, sortByDayJS, sortBy, parseFloatOr, exhaustiveCaseGuard, clamp, vOptT, parseIntOr, UiOption, forceCheckedIndexedAccess, assertNonNull, zipExact } from "src/helpers/utils"
import { defineComponent, ref, computed, ExtractPropTypes } from "vue"
import { freshSortState, BasicTable, ColDef, downloadAsXlsxFactory, SortState } from "./TableUtils"
import { Paginated } from "./PaginationUtils"
import { Integerlike } from "src/interfaces/InleagueApiV1"
import { FormKit } from "@formkit/vue"
import { Btn2 } from "src/components/UserInterface/Btn2"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faFileExport } from "@fortawesome/pro-solid-svg-icons"

// todo: name should be something like "server-driven-basic-auto-table"
// the props are defined to mostly be coming from a server side configuration/data blob
// and then this should translate between the 2 config shapes, and ultimately be implemented in terms of the "client-driven" one
export const BasicAutoTable = defineComponent({
  props: {
    tableDef: vReqT<BasicAutoTableDef>(),
    rowAttrs: vOptT<(e: BasicAutoTableRow, i: number, a: BasicAutoTableRow[]) => Record<string, string>>(),
    paginationOptions: vOptT<(number | "ALL")[]>(),
  },
  setup(props) {
    const colDefs = makeColDefs(props.tableDef)

    const sortState = ref(freshSortState(colDefs))

    // initialize sort state as per table def
    ;(() => {
      const vs : {colID: string, dir: "asc" | "desc", priority: number}[] = []

      // We iterate over both locally generated coldefs and serverside coldefs at the same time.
      // The lists are expected to be the exact same length.
      const zippedColDefs = zipExact(colDefs, props.tableDef.colDefs)

      for (let i = 0; i < zippedColDefs.length; i++) {
        const localGenColDef = zippedColDefs[i][0];
        const serversideColDef = zippedColDefs[i][1];

        if (!localGenColDef.sort || !serversideColDef.initialSort) {
          continue;
        }

        // sanity checking for backend provided sort dirs
        if (serversideColDef.initialSort.dir !== "asc" && serversideColDef.initialSort.dir !== "desc") {
          continue;
        }

        vs.push({
          colID: colDefID(i),
          dir: serversideColDef.initialSort.dir,
          priority: serversideColDef.initialSort.priority
        })
      }

      vs.sort(sortBy(v => v.priority, "asc"))

      if (vs.length > 0) {
        sortState.value.reconfigure(vs)
      }
    })()

    const sortedData = computed(() => [...props.tableDef.rows].sort(sortState.value.asSorter()))

    const usePagination = computed(() => props.paginationOptions && props.paginationOptions.length > 0)
    const itemsPerPageOptions = computed<UiOption<Integerlike | "ALL">[]>(() => {
      return props.paginationOptions?.map(v => {
        return v === "ALL"
          ? {label: "All", value: "ALL"}
          : {label: `${v}`, value: `${v}`}
      }) ?? []
    });

    const selectedItemsPerPageOption = ref<Integerlike | "ALL">(forceCheckedIndexedAccess(itemsPerPageOptions.value, 0)?.value ?? "ALL")

    // always source from "pagination" info, but if we're not paginating, the page length will always be "ALL"
    const pagination = computed(() => Paginated(parseIntOr(selectedItemsPerPageOption.value, "ALL"), sortedData))

    const downloadAsXLSX = async () : Promise<void> => {
      assertNonNull(props.tableDef.asXLSX)
      const filename = props.tableDef.asXLSX.filename
      const getIt = downloadAsXlsxFactory(colDefs)
      await getIt(pagination.value.pageData.allItems, filename);
    }

    return () => <div>

      {/*
      -- TODO: implementation in terms of the "client-driven" one
      <BasicAutoTable2
        colDefs={colDefs}
        sortState={sortState.value}
        rows={props.tableDef.rows}
        paginationOptions={props.paginationOptions}
        asXLSX={props.tableDef.asXLSX}
        rowAttrs={props.rowAttrs}
      /> */}
      <div class="flex items-center gap-2 [&:has(*)]:mb-2">
        {usePagination.value
          ? <div class="flex items-center gap-2 text-sm" style="--fk-padding-input:.375em; --fk-margin-outer: none; --fk-max-width-input:none; --fk-width-input:none;">
            Show <FormKit outerClass="inline-flex" type="select" options={itemsPerPageOptions.value} v-model={selectedItemsPerPageOption.value}/> per page
          </div>
          : null
        }
        {props.tableDef.asXLSX
          ? <Btn2 class="px-2 py-1 flex items-center gap-1" onClick={downloadAsXLSX}>
              <FontAwesomeIcon icon={faFileExport}/>
              <span>Excel</span>
            </Btn2>
          : null
        }
      </div>
      <BasicTable
        rowData={pagination.value.pageData.itemsThisPage}
        colDefs={colDefs}
        sortState={sortState.value}
        rowKey={row => row.__rowKey}
        rowAttrs={props.rowAttrs}
        noData={() => <div class="p-4">No data.</div>}
        class="w-full"
      />
      <div class="mt-2 text-sm">
        {pagination.value.pageData.count_itemsThisPage > 0
          ? <div>Showing {pagination.value.pageData.zi_currentPageFirstIndex + 1} to {pagination.value.pageData.zi_currentPageLastIndex} of {pagination.value.pageData.count_totalItems} entries</div>
          : null
        }
        {usePagination.value
          ? <div class="flex items-center gap-2">
            <a class={pagination.value.pageData.hasPrev ? "il-link" : "text-gray-300"} onClick={() => pagination.value.pageData.hasPrev ? (pagination.value.prevPage()) : void 0}>Previous</a>
            <a class={pagination.value.pageData.hasNext ? "il-link" : "text-gray-300"} onClick={() => pagination.value.pageData.hasNext ? (pagination.value.nextPage()) : void 0}>Next</a>
          </div>
          : null
        }
      </div>
    </div>
  }
})

function BasicAutoTable2PropsDef<T = any>() {
  return {
    colDefs: vReqT<ColDef<T, any>[]>(),
    sortState: vReqT<SortState<T, any>>(),
    rows: vReqT<T[]>(),
    paginationOptions: vOptT<(number | "ALL")[]>(),
    asXLSX: vOptT<{filename: string}>(),
    rowKey: vReqT<(e: T, i: number, a: any[]) => string>(),
    rowAttrs: vOptT<(e: T, i: number, a: any[]) => Record<string, any>>(),
    /**
     * TODO: this should be a slot
     */
    noData: vOptT<null | (() => JSX.Element | null)>(),
  }
}

export function typedBasicAutoTable2Props<T>(args: ExtractPropTypes<ReturnType<typeof BasicAutoTable2PropsDef<T>>>) {
  return args;
}

export interface BasicAutoTable2Slots {
  aboveTable?: () => JSX.Element | null
}

export const BasicAutoTable2 = defineComponent({
  props: BasicAutoTable2PropsDef(),
  setup(props, ctx) {
    const sortedData = computed(() => [...props.rows].sort(props.sortState.asSorter()))

    const usePagination = computed(() => props.paginationOptions && props.paginationOptions.length > 0)
    const itemsPerPageOptions = computed<UiOption<Integerlike | "ALL">[]>(() => {
      return props.paginationOptions?.map(v => {
        return v === "ALL"
          ? {label: "All", value: "ALL"}
          : {label: `${v}`, value: `${v}`}
      }) ?? []
    });

    const selectedItemsPerPageOption = ref<Integerlike | "ALL">(forceCheckedIndexedAccess(itemsPerPageOptions.value, 0)?.value ?? "ALL")

    // always source from "pagination" info, but if we're not paginating, the page length will always be "ALL"
    const pagination = computed(() => Paginated(parseIntOr(selectedItemsPerPageOption.value, "ALL"), sortedData))

    const downloadAsXLSX = async () : Promise<void> => {
      assertNonNull(props.asXLSX)
      const filename = props.asXLSX.filename
      const getIt = downloadAsXlsxFactory(props.colDefs)
      await getIt(pagination.value.pageData.allItems, filename);
    }

    return () => <div>
      <div class="flex items-center gap-2 [&:has(*)]:mb-2">
        {props.asXLSX
          ? <Btn2 class="px-2 py-1 flex items-center gap-1" onClick={downloadAsXLSX}>
              <FontAwesomeIcon icon={faFileExport}/>
              <span>Excel</span>
            </Btn2>
          : null
        }
        {/*TODO: excel button passed as arg to `aboveTable`*/}
        {(ctx.slots as BasicAutoTable2Slots).aboveTable?.()}
      </div>
      <BasicTable
        rowData={pagination.value.pageData.itemsThisPage}
        colDefs={props.colDefs}
        sortState={props.sortState}
        rowKey={props.rowKey}
        rowAttrs={props.rowAttrs}
        noData={props.noData}
        class="w-full"
      />
      <div class="mt-2 text-sm">
        {usePagination.value
          ? <div class="my-2 flex items-center gap-2 text-sm" style="--fk-padding-input:.375em; --fk-margin-outer: none; --fk-max-width-input:none; --fk-width-input:none;">
            Show <FormKit outerClass="inline-flex" type="select" options={itemsPerPageOptions.value} v-model={selectedItemsPerPageOption.value}/> per page
          </div>
          : null
        }
        {pagination.value.pageData.count_itemsThisPage > 0
          ? <div>Showing {pagination.value.pageData.zi_currentPageFirstIndex + 1} to {pagination.value.pageData.zi_currentPageLastIndex} of {pagination.value.pageData.count_totalItems} entries</div>
          : null
        }
        {usePagination.value
          ? <div class="flex items-center gap-2">
            <a class={pagination.value.pageData.hasPrev ? "il-link" : "text-gray-300"} onClick={() => pagination.value.pageData.hasPrev ? (pagination.value.prevPage()) : void 0}>Previous</a>
            <a class={pagination.value.pageData.hasNext ? "il-link" : "text-gray-300"} onClick={() => pagination.value.pageData.hasNext ? (pagination.value.nextPage()) : void 0}>Next</a>
          </div>
          : null
        }
      </div>
    </div>
  }
})

export type BasicAutoTableColDef = {
  prop: string,
  label: string,
  initialSort?: {
    /**
     * To support multiple columns being sorted, we need to know
     * the order the sort should initially be applied.
     *
     * If priorities are not unique, the intra-duplicate priority of
     * the columns sharing duplicate priorites is not defined.
     *
     * As an example, consider an sql sort:
     *  - order by a, b -- a is priority 1, b is priority 2
     *  - order by b, a -- b is priority 1, a is priority 2
     */
    priority: number,
    dir: "asc" | "desc"
  }
}

export type BasicAutoTableRow = Record<string, any> & {__rowKey: string}

export type BasicAutoTableDef = {
  colDefs: BasicAutoTableColDef[],
  rows: BasicAutoTableRow[],
  /**
   * If truthy, offer to download as XLSX
   */
  asXLSX?: {
    filename: string,
  },
}

function colDefID(colIndex: number) {
  return `col_${colIndex}`
}

function makeColDefs(config: BasicAutoTableDef) : ColDef<BasicAutoTableRow, string>[] {
  return config.colDefs.map((colDef,i) => {
    const inferredType = inferColumnSortType(config.rows, colDef);
    return {
      id: colDefID(i),
      label: colDef.label,
      headerClass: "p-1 border",
      html: row => row[colDef.prop],
      sort: colTypeToSorter(inferredType, colDef)
    }
  })
}

enum ColType {
  string = "string",
  date = "date",
  number = "number",
  unknown = "unknown"
}

function colTypeToSorter(v: ColType, colDef: BasicAutoTableColDef) : Sorter<BasicAutoTableRow> | undefined {
  switch (v) {
    case ColType.string: return (l, r) => accentAwareCaseInsensitiveCompare(l[colDef.prop], r[colDef.prop])
    case ColType.date: return sortByDayJS(v => v[colDef.prop])
    case ColType.number: return sortBy(v => parseFloatOr(v[colDef.prop], Infinity))
    case ColType.unknown: return undefined
    default: exhaustiveCaseGuard(v)
  }
}

/**
 * Sample a particular column, for some number of rows from the provided rows, and try
 * to determine its "type" for sorting purposes.
 */
function inferColumnSortType(rows: BasicAutoTableRow[], colDef: BasicAutoTableColDef) {
  if (rows.length === 0) {
    return ColType.unknown
  }

  const samplePercent = .25;

  const sampleCount = clamp(
    Math.floor(rows.length * samplePercent), {min: 1, max: rows.length - 1}
  )

  const samples = [...new Array(sampleCount)].map(() => {
    const idx = Math.floor((Math.random() * rows.length))
    return rows[idx][colDef.prop]
  })

  const m = new Map<ColType, number>()
  const bump = (type: ColType) => {
    const current = m.get(type)
    if (current === undefined) {
      m.set(type, 1)
    }
    else {
      m.set(type, current + 1)
    }
  }

  for (const sample of samples) {
    if (dayjsOr(sample)) {
      bump(ColType.date)
    }
    else if (parseFloatOr(sample, null) !== null) {
      bump(ColType.number)
    }
    else if (typeof sample === "string") {
      bump(ColType.string);
    }
    else {
      // nothing
    }
  }

  const sortedByScoreDesc = [...m.entries()].map(v => ({coltype: v[0], score: v[1]})).sort(sortBy(v => v.score, "desc"))

  if (sortedByScoreDesc.length === 0) {
    return ColType.unknown
  }
  else if (sortedByScoreDesc.length > 1 && sortedByScoreDesc[0].score === sortedByScoreDesc[1].score) {
    // there are at least two different inferred types, and the first 2 had matching scores
    return ColType.unknown
  }
  else {
    return sortedByScoreDesc[0].coltype
  }
}
