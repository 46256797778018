import { defineComponent } from "vue"

/**
 * This is a dev thing.
 *
 * Wraps blocks of JSX elems that shouldn't be rendered.
 * This is slightly more ergonomic than a comment because JSX comments cannot be recursive.
 *
 * Usage is like:
 * ```
 * <div>
 *   <div>...content...</div>
 *   <NoRender>
 *       ...big dom tree that will be fully ignored...
 *   </NoRender>
 * </div>
 * ```
 */
export const NoRender = defineComponent(() => () => null)
