import { ExtractPropTypes, PropType } from "vue";
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"
import { ExtractEmitsHandlers, nextGlobalIntlike, parseIntOr } from "src/helpers/utils";

export const propsDef = {
  v: {
    required: true,
    type: Object as PropType<PropsVariant>,
  }
} as const;

type PropsVariant =
  | {type: "make-new", formData: CreateQuestionFormEntry}
  | {type: "update-existing", formData: UpdateQuestionFormEntry}

export const emitsDef = {
  doSave: (_: QuestionFormEntry) => true,
  /**
   * @param questionID null if the thing being canceled is "make-new", otherwise it is the target questionID
   */
  doCancel: (questionID: iltypes.Guid | null) => true
}

export type Props = ExtractPropTypes<typeof propsDef>
export type Emits = ExtractEmitsHandlers<typeof emitsDef>

export type QuestionFormEntry = CreateQuestionFormEntry | UpdateQuestionFormEntry

interface QuestionFormEntryBase {
  readonly __vuekey: string, // opaque key for vue purposes
  readonly isNewQuestion: boolean,
  readonly questionID: iltypes.Guid | null, // null if fresh
  label: string,
  isRequired: boolean,
  questionOptions: QuestionOptionFormEntry[],
  shortLabel: string,
  sortOrder: string
  type: ilapi.event.EventQuestionType["string"]
}
export interface CreateQuestionFormEntry extends QuestionFormEntryBase {
  readonly isNewQuestion: true,
  readonly questionID: null
}
export interface UpdateQuestionFormEntry extends QuestionFormEntryBase {
  readonly isNewQuestion: false,
  readonly questionID: iltypes.Guid
}

export interface QuestionOptionFormEntry {
  readonly __vueKey: string, // opaque key for vue purposes
  readonly isNew: boolean,
  readonly id: iltypes.Guid | null, // null if fresh
  markedForDelete: boolean,
  optionText: string,
  optionValue: string,
}

export function makeQuestionFormEntry(v: "new") : CreateQuestionFormEntry;
export function makeQuestionFormEntry(v: iltypes.WithDefinite<ilapi.event.EventQuestion, "questionOptions">) : UpdateQuestionFormEntry;
export function makeQuestionFormEntry(v: "new" | iltypes.WithDefinite<ilapi.event.EventQuestion, "questionOptions">) : QuestionFormEntry {
  if (v === "new") {
    return {
      __vuekey: nextGlobalIntlike(),
      isNewQuestion: true,
      questionID: null,
      label: "",
      isRequired: false,
      questionOptions: [makeQuestionOptionFormEntry("new")],
      shortLabel: "",
      sortOrder: "",
      type: "text"
    }
  }
  else {
    return {
      __vuekey: v.questionID,
      isNewQuestion: false,
      questionID: v.questionID,
      label: v.label,
      isRequired: v.isRequired,
      questionOptions: v.questionOptions.map(makeQuestionOptionFormEntry),
      shortLabel: v.shortLabel,
      sortOrder: v.sortOrder?.toString() || "",
      type: v.type
    }
  }
}

export function makeQuestionOptionFormEntry(v: "new" | ilapi.event.EventQuestionOption) : QuestionOptionFormEntry {
  if (v === "new") {
    return {
      __vueKey: nextGlobalIntlike(),
      isNew: true,
      id: null,
      markedForDelete: false,
      optionText: "",
      optionValue: "",
    }
  }
  else {
    return {
      __vueKey: v.id,
      isNew: false,
      id: v.id,
      markedForDelete: false,
      optionText: v.optionText,
      optionValue: v.optionValue,
    }
  }
}

export function questionFormToEndpointArgs(v: CreateQuestionFormEntry) : ilapi.event.CreateEventQuestionArgs;
export function questionFormToEndpointArgs(v: UpdateQuestionFormEntry) : ilapi.event.UpdateEventQuestionArgs;
export function questionFormToEndpointArgs(v: CreateQuestionFormEntry | UpdateQuestionFormEntry) : ilapi.event.CreateEventQuestionArgs | ilapi.event.UpdateEventQuestionArgs {
  if (v.isNewQuestion) {
    const t : ilapi.event.CreateEventQuestionArgs = {
      isRequired: v.isRequired,
      label: v.label,
      questionOptions: sanitizeQuestionOptions(v.questionOptions),
      shortLabel: v.shortLabel,
      sortOrder: parseIntOr(v.sortOrder, null),
      type: v.type
    }
    return t;
  }
  else {
    const t : ilapi.event.UpdateEventQuestionArgs = {
      questionID: v.questionID,
      isRequired: v.isRequired,
      label: v.label,
      questionOptions: sanitizeQuestionOptions(v.questionOptions),
      shortLabel: v.shortLabel,
      sortOrder: parseIntOr(v.sortOrder, null),
      type: v.type
    }
    return t;
  }

  function sanitizeQuestionOptions(v: QuestionOptionFormEntry[]) : ilapi.event.CreateUpdateQuestionOptionArgs[] {
    // If it was marked for deletion, we don't push it over the wire, and it will just disappear forever.
    // (the endpoint deletes all the existing question options, and then rebuilds them based on what options were sent)
    return v.filter(v => !v.markedForDelete).map(sanitizeQuestionOption);
  }

  function sanitizeQuestionOption(v: QuestionOptionFormEntry) : ilapi.event.CreateUpdateQuestionOptionArgs {
    // keep only relevant properties. The server should also discard irrelevant properites, but this is our expectation
    // of a minimum viable shape.
    return {
      optionText: v.optionText,
      optionValue: v.optionValue
    }
  }
}
