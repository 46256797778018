import authService from "src/helpers/authService";
import { exhaustiveCaseGuard } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { User } from "src/store/User";
import { RouteLocationRaw } from "vue-router";

export const RouteName = {
  main: "player-editor",
} as const;
export type RouteName = typeof RouteName;

type RouteDetail = R_Player

interface R_Player {
  name: RouteName["main"],
  playerID: Guid,
  registrationID?: Guid
}

export function routeDetailToRoutePath(detail: RouteDetail) : RouteLocationRaw {
  switch (detail.name) {
    case "player-editor": {
      if (detail.registrationID) {
        return {name: detail.name, params: {playerID: detail.playerID, registrationID: detail.registrationID}};
      }
      else {
        return {name: detail.name, params: {playerID: detail.playerID}};
      }
    }
    default: exhaustiveCaseGuard(detail.name);
  }
}

export function hasRoutePerms() {
  return authService(User.value.roles, 'registrar', 'webmaster', 'director', 'playerAdmin', 'CompetitionManager', 'scholarshipAdmin', 'DirectorReporter', 'BirthCertAdmin', 'Parent');
}
