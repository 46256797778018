import ContentChunkDisplay from "src/components/Admin/ContentChunks/ContentChunkDisplay";
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { ReifiedPromise } from "src/helpers/ReifiedPromise";
import { vReqT } from "src/helpers/utils";
import { ContentChunk } from "src/interfaces/InleagueApiV1";
import { defineComponent, onMounted } from "vue";

export const EpicConfirmationModal = defineComponent({
  props: {
    contentChunk: vReqT<ReifiedPromise<ContentChunk>>(),
  },
  emits: {
    confirm: () => true,
    cancel: () => true,
  },
  setup(props, ctx) {
    return () => {
      if (props.contentChunk.status !== "resolved") {
        return <div>Loading...</div>;
      }

      return <div>
        <ContentChunkDisplay
          id={props.contentChunk.data.id} // uh why is this required if we are supplying the obj itself
          contentChunkObj={props.contentChunk.data}
        />
        <div class="mt-4 flex gap-2 items-center">
          <Btn2 data-test="confirm-button" onClick={() => ctx.emit("confirm")} class="px-2 py-1">Proceed with EPIC Registration</Btn2>
          <Btn2 data-test="cancel-button" onClick={() => ctx.emit("cancel")} class="px-2 py-1" enabledClasses={btn2_redEnabledClasses}>Cancel</Btn2>
        </div>
      </div>
    }
  }
})
