import { arrayFindOrFail, assertIs, dollarFormat, exhaustiveCaseGuard, FK_nodeRef, forceCheckedIndexedAccess, noAvailableOptions, parseFloatOr, requireNonNull, UiOptions, useIziToast } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1"
import { computed, defineComponent, onMounted, ref } from "vue"
import { bulkOps_getDataFromToken, createInvoiceInstancesForPlayerPayors, getInvoiceTemplateBulkAssignInfo, getInvoiceTemplateSeasonOptions, InvoiceInstanceForInvoiceTemplate, InvoiceTemplate, listInvoiceInstancesForInvoiceTemplate, listInvoiceTemplates, PlayerishPayorCandidate } from "./InvoiceTemplates.io"
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances"
import { FormKit, FormKitMessages } from "@formkit/vue"
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise"
import { freshSortState } from "src/modules/TableUtils"
import { BasicAutoTable2 } from "src/modules/BasicAutoTable"
import { DiscountConfig, freshInvoiceTemplateRecipientForm, InvoiceTemplateRecipientFormElem } from "./InvoiceTemplatePayors.elems"
import { RouterLink, useRouter } from "vue-router"
import { InvoiceColDefs, PayorCandidatesColDefs } from "./InvoiceTemplatePayors.elems"
import { FkDynamicValidation } from "src/helpers/FkUtils"
import { AxiosErrorWrapper } from "src/boot/AxiosErrorWrapper"
import { freshNoToastLoggedInAxiosInstance } from "src/boot/axios"

import * as t from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value";
import { queryGuid } from "src/boot/TypeBoxSetup"
import * as R_InvoiceTemplateListing from "./InvoiceTemplateListing.route"
import { Btn2 } from "../UserInterface/Btn2"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"

export default defineComponent({
  setup() {
    const iziToast = useIziToast()
    const router = useRouter()

    const form = ref(freshInvoiceTemplateRecipientForm("bulk"))

    type BulkConfig = {ok: false, msg: string} | {ok: true, data: BulkConfig_RegIDs}
    type BulkConfig_RegIDs = {
      keyType: "registrationID" | "childID",
      keys: Guid[],
      distinctKeys: Guid[],
      serverInfo: {
        inferredInitialSeasonUID: string,
        keysHavingNoMatch: string[],
        playerishPayorCandidates: PlayerishPayorCandidate[]
      }
    }

    const tryGetBulkConfigFromQueryParams = async () : Promise<BulkConfig> => {
      const dataToken = router.currentRoute.value.query.dataToken;

      if (!dataToken || typeof dataToken !== "string") {
        return {ok: false, msg: "Query params were misconfigured. Please contact inLeague if this problem persists."}
      }

      let data : any = null

      try {
        data = await bulkOps_getDataFromToken(freshNoToastLoggedInAxiosInstance(), {dataToken: dataToken})
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }

      const shape = t.Object({
        keyType: t.Union([t.Literal("registrationID"), t.Literal("childID")]),
        keys: t.Array(queryGuid())
      })

      if (Value.Check(shape, data)) {
        if (data.keys.length === 0) {
          return {
            ok: false,
            msg: "The list of values sent from the prior page was empty."
          }
        }

        switch (data.keyType) {
          case "registrationID":
            // fallthrough
          case "childID": {
            const keysCanonicalized = data.keys.map(v => v.trim().toUpperCase())
            return {
              ok: true,
              data: {
                keyType: "registrationID",
                keys: keysCanonicalized,
                distinctKeys: [...new Set(keysCanonicalized)],
                serverInfo: await getInvoiceTemplateBulkAssignInfo(axiosInstance, {keyType: data.keyType, keys: data.keys})
              }
            }
          }
          default: exhaustiveCaseGuard(data.keyType)
        }
      }
      else {
        return {
          ok: false,
          msg: "Something went wrong loading data from the prior page. Please retry the navigation, and if the problem persists contact your inLeague representative."
        }
      }
    }

    const bulkConfig = ref<BulkConfig>({ok: false, msg: "Loading data..."})
    const seasonOptions = ref<UiOptions>(noAvailableOptions("Loading..."))
    const selectedSeasonUID = ref("")

    const invoiceTemplatesForSelectedSeason = (() => {
      const invoiceTemplatesForSelectedSeason = ReactiveReifiedPromise<InvoiceTemplate[]>()

      const options = computed<UiOptions>(() => {
        if (invoiceTemplatesForSelectedSeason.underlying.status === "resolved") {
          if (invoiceTemplatesForSelectedSeason.underlying.data.length === 0) {
            return noAvailableOptions()
          }
          else {
            return {
              disabled: false,
              options: invoiceTemplatesForSelectedSeason.underlying.data.map(v => ({label: v.invoiceLabel, value: v.invoiceID}))
            }
          }
        }
        else {
          return noAvailableOptions("Loading...")
        }
      })

      const selected = (() => {
        const invoiceTemplateID = ref("")
        const invoiceTemplate = ref<InvoiceTemplate | null>(null)
        return {
          get invoiceTemplateID() {
            return invoiceTemplateID.value
          },
          set invoiceTemplateID(v) {
            invoiceTemplateID.value = v
            if (v) {
              assertIs(invoiceTemplatesForSelectedSeason.underlying.status, "resolved")
              invoiceTemplate.value = arrayFindOrFail(invoiceTemplatesForSelectedSeason.underlying.data, inv => inv.invoiceID === v)
            }
            else {
              invoiceTemplate.value = null
            }
          },
          get invoiceTemplate() {
            return invoiceTemplate.value
          }
        }
      })()

      const relatedInvoiceInstances = ReactiveReifiedPromise<InvoiceInstanceForInvoiceTemplate[]>()
      const existingPayorIDs = relatedInvoiceInstances.xMap(data => new Set(data.map(v => v.entity.entityID)))

      const doUpdateInvoiceOptions = (args: {seasonUID: Guid}) : void => {
        invoiceTemplatesForSelectedSeason.run(async () => {
          return await listInvoiceTemplates(axiosAuthBackgroundInstance, {seasonUID: args.seasonUID})
        })
        .getResolvedOrFail()
        .then(data => {
          if (!data.find(v => v.invoiceID === selected.invoiceTemplateID)) {
            // n.b. setter impl invoked here relies on this running after the invoiceTemplatesForSelectedSeason have resolved
            selected.invoiceTemplateID = forceCheckedIndexedAccess(data, 0)?.invoiceID ?? ""
            if (selected.invoiceTemplateID) {
              relatedInvoiceInstances.run(() => listInvoiceInstancesForInvoiceTemplate(axiosAuthBackgroundInstance, {invoiceTemplateID: selected.invoiceTemplateID}))
            }
            else {
              relatedInvoiceInstances.reset()
            }
          }
        })
      }

      return {
        resolver: invoiceTemplatesForSelectedSeason,
        selected: selected,
        get options() { return options.value },
        relatedInvoiceInstances: {
          _getReifiedPromise() { return relatedInvoiceInstances.underlying },
          get value() {
            return relatedInvoiceInstances.underlying.status === "resolved"
              ? relatedInvoiceInstances.underlying.data
              : []
          },
          unshift: (data: InvoiceInstanceForInvoiceTemplate[]) => {
            assertIs(relatedInvoiceInstances.underlying.status, "resolved")
            relatedInvoiceInstances.underlying.data.unshift(...data)
          }
        },
        doUpdateInvoiceOptions,
        existingPayorIDs: existingPayorIDs,
      }
    })();

    const payorTableConfig = (() => {
      const colDefs = PayorCandidatesColDefs("bulk", {get value() { return form.value }}, invoiceTemplatesForSelectedSeason.existingPayorIDs)
      const sortState = freshSortState(colDefs)
      sortState.reconfigure([{colID: "name", dir: "asc"}])
      return {colDefs, sortState}
    })();

    const currentInvoiceInstancesTableConfig = (() => {
      const colDefs = InvoiceColDefs()
      const sortState = freshSortState(colDefs)
      return {colDefs, sortState}
    })()

    const discountConfig = computed<DiscountConfig>(() => {
      const invTemplate = invoiceTemplatesForSelectedSeason.selected.invoiceTemplate
      if (!invTemplate) {
        // default shape, shouldn't be read from anyway though,
        // when the "selected invoiceTemplate" is null
        return {
          allowDollar: {ok: false, msg: ""},
          allowPercentage: {ok: false, msg: ""}
        }
      }
      else {
        return {
          allowDollar: invTemplate.paymentMethods.length === 0
            ? {ok: true}
            : {ok: false, why: "This invoice template has recurring payment plans. Only percentage discounts are supported."},
          allowPercentage: {ok: true},
        }
      }
    })

    const ready = ref(false)

    onMounted(async () => {
      bulkConfig.value = await tryGetBulkConfigFromQueryParams()

      if (bulkConfig.value.ok) {
        const data = bulkConfig.value.data
        seasonOptions.value = await getInvoiceTemplateSeasonOptions(axiosInstance).then(obj => {
          return obj.seasons.length === 0
            ? noAvailableOptions()
            : {disabled: false, options: obj.seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))}
        })

        selectedSeasonUID.value = seasonOptions.value.options.find(opt => opt.value === data.serverInfo.inferredInitialSeasonUID)?.value
          || forceCheckedIndexedAccess(seasonOptions.value.options, 0)?.value
          || "" // shouldn't happen

        if (selectedSeasonUID.value) {
          invoiceTemplatesForSelectedSeason.doUpdateInvoiceOptions({seasonUID: selectedSeasonUID.value})
        }

        // on page mount, all the players are selected
        form.value.childIDs.addMany(data.serverInfo.playerishPayorCandidates.map(v => v.childID))
      }
      else {
        // no-op, no bulk config, url was misconfigured
      }

      ready.value = true;
    })

    const doApplyInvoiceToSelectedPlayers = async () : Promise<void> => {
      const invoiceTemplate = requireNonNull(invoiceTemplatesForSelectedSeason.selected.invoiceTemplate)
      invoiceTemplatesForSelectedSeason.relatedInvoiceInstances

      try {
        const freshInvoices = await createInvoiceInstancesForPlayerPayors(axiosInstance, {
          invoiceTemplateID: invoiceTemplate.invoiceID,
          childIDs: [...form.value.childIDs],
          dollarDiscount: form.value.discount?.type === "dollar" ? parseFloatOr(form.value.discount.value, undefined) : undefined,
          percentDiscount: form.value.discount?.type === "percent" ? parseFloatOr(form.value.discount.value, undefined) : undefined,
          instanceDesc: form.value.instanceDesc,
          emailOnCreation: form.value.emailOnCreation,
        })

        form.value = freshInvoiceTemplateRecipientForm("bulk")
        invoiceTemplatesForSelectedSeason.relatedInvoiceInstances.unshift(freshInvoices)
        if (freshInvoices.length === 1) {
          iziToast.success({message: "Invoice created."})
        }
        else {
          iziToast.success({message: `${freshInvoices.length} invoices created.`})
        }
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const fkAddPayorFormRef = FK_nodeRef()
    const FK_RequireThatPayorsAreKnownBeforeSubmit = FkDynamicValidation(() => {
      return invoiceTemplatesForSelectedSeason.existingPayorIDs.value.status === "resolved"
        ? {status: "valid"}
        : {status: "invalid", msg: "Form submit disabled while loading existing payor info..."}
    })

    return () => {
      if (!ready.value) {
        return null
      }

      if (!bulkConfig.value.ok) {
        return <p>{bulkConfig.value.msg}</p>
      }

      return <div style="--fk-bg-input: white; --fk-margin-outer:none; --fk-margin-decorator: none; --fk-padding-input:.35em; max-width:1400px;">
        <div class="flex items-center gap-2">
          <RouterLink to={R_InvoiceTemplateListing.route({seasonUID: invoiceTemplatesForSelectedSeason.selected.invoiceTemplate?.season.seasonUID || undefined})} class="inline-block mb-4">
            <Btn2 class="px-2 py-1 flex gap-1 items-center text-sm">
              <FontAwesomeIcon icon={faArrowLeft}/>
              <p>Back to Invoice Templates Home</p>
            </Btn2>
          </RouterLink>
        </div>
        <h2 class="my-2">Invoice Management: Bulk Invitations</h2>
        <p class="my-2">This utility will bulk assign (or invite) one or more players to an existing invoice template. All newly-created invoice invitations will have the same discount settings and instructions.</p>
        <p class="my-2">Received {bulkConfig.value.data.keys.length} player records.</p>
        {bulkConfig.value.data.distinctKeys.length !== bulkConfig.value.data.keys.length
          ? <p class="my-2 text-sm">Note: request contained {Math.abs(bulkConfig.value.data.distinctKeys.length - bulkConfig.value.data.keys.length)} duplicate keys. Of the duplicates, only the first of each will be considered.</p>
          : null
        }
        <FormKit type="form" ref={fkAddPayorFormRef} actions={false} onSubmit={() => { doApplyInvoiceToSelectedPlayers() }} key={form.value.__vueKey}>
          <div class="my-2">
            <FormKit
              type="select"
              label="Season"
              options={seasonOptions.value.options}
              disabled={seasonOptions.value.disabled}
              v-model={selectedSeasonUID.value}
              onInput={(val: any) => {
                if (selectedSeasonUID.value === val) {
                  return
                }
                selectedSeasonUID.value = val
                invoiceTemplatesForSelectedSeason.doUpdateInvoiceOptions({seasonUID: selectedSeasonUID.value})
              }}
            />
          </div>

          <div class="my-2">
            <FormKit
              label="Invoice Template"
              type="select"
              disabled={invoiceTemplatesForSelectedSeason.options.disabled}
              options={invoiceTemplatesForSelectedSeason.options.options}
              v-model={invoiceTemplatesForSelectedSeason.selected.invoiceTemplateID}
            />
          </div>

          {invoiceTemplatesForSelectedSeason.selected.invoiceTemplate
            ? <div>
              <div class="my-4 border-b"/>
              <h2>{invoiceTemplatesForSelectedSeason.selected.invoiceTemplate.invoiceLabel} (${dollarFormat(invoiceTemplatesForSelectedSeason.selected.invoiceTemplate.amount)})</h2>
              <p>Current invoice recipients: {invoiceTemplatesForSelectedSeason.relatedInvoiceInstances.value.length}</p>
              <div class="mt-4 border rounded-md p-2 bg-white">
                <p>Players to be added:</p>
                <BasicAutoTable2
                  colDefs={payorTableConfig.colDefs}
                  sortState={payorTableConfig.sortState}
                  rows={bulkConfig.value.data.serverInfo.playerishPayorCandidates}
                  rowKey={(row : (typeof bulkConfig.value.data.serverInfo.playerishPayorCandidates)[number]) => row.childID}
                  paginationOptions={[10, 25, "ALL"]}
                  rowAttrs={(row : (typeof bulkConfig.value.data.serverInfo.playerishPayorCandidates)[number]) => ({
                    "data-test": row.childID,
                  })}
                />
              </div>
            </div>
            : null
          }

          {invoiceTemplatesForSelectedSeason.selected.invoiceTemplate
            ? <div class="mt-4 p-2 bg-white rounded-md border">
              <InvoiceTemplateRecipientFormElem
                form={form.value}
                discountConfig={discountConfig.value}
              />
              <FK_RequireThatPayorsAreKnownBeforeSubmit/>
              <FormKitMessages node={fkAddPayorFormRef.value?.node}/>
            </div>
            : null
          }

          <div class="mt-4 bg-white p-2 rounded-md border" style="width: max-content;">
            <div>
              Existing Payors
            </div>
            <BasicAutoTable2
              paginationOptions={[50, "ALL"]}
              colDefs={currentInvoiceInstancesTableConfig.colDefs}
              sortState={currentInvoiceInstancesTableConfig.sortState}
              rows={invoiceTemplatesForSelectedSeason.relatedInvoiceInstances.value}
              rowKey={(row : (typeof invoiceTemplatesForSelectedSeason.relatedInvoiceInstances.value)[number]) => row.invoiceInstanceID.toString()}
              rowAttrs={(row : (typeof invoiceTemplatesForSelectedSeason.relatedInvoiceInstances.value)[number]) => ({
                "data-test": row.invoiceInstanceID,
              })}
              noData={() => {
                const classes = "p-4"
                const p = invoiceTemplatesForSelectedSeason.relatedInvoiceInstances._getReifiedPromise()
                switch (p.status) {
                  case "resolved": return <div class={classes}>No current payors.</div>
                  case "idle": return null
                  case "pending": return <div class={classes}>Loading...</div>
                  case "error": return <div class={classes}>Sorry, something went wrong.</div>
                  default: exhaustiveCaseGuard(p)
                }
              }}
            />
          </div>
        </FormKit>
      </div>
    }
  }
})
