import { RegisteredSeasonsForChildrenResponse } from "src/composables/InLeagueApiV1.Child";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { exhaustiveCaseGuard, vReqT } from "src/helpers/utils";
import { Guid } from "src/interfaces/InleagueApiV1";
import { computed, defineComponent } from "vue";
import { SoccerBall } from "../SVGs";
import { Client } from "src/store/Client";
import { routeDetailToRoutePath } from "../PlayerEditor/PlayerEditor.route";
import { RouterLink } from "vue-router";

export const SeasonsListingByChildIDResolver = defineComponent({
  props: {
    childID: vReqT<Guid>(),
    p_seasonsListingByChildID: vReqT<Promise<RegisteredSeasonsForChildrenResponse>>()
  },
  setup(props) {
    const resolver = computed(() => ReactiveReifiedPromise(() => props.p_seasonsListingByChildID))

    return () => {
      return (
        <div data-test="SeasonsListingByChildIDResolver">
          <Impl/>
        </div>
      )
    }

    function Impl() : JSX.Element {
      switch (resolver.value.underlying.status) {
        case "idle": {
          // we don't expect to ever be in idle state here
          return <span/>;
        }
        case "pending": {
          return (
            <div class="flex items-center justify-start">
              <SoccerBall color={Client.value.clientTheme.color} timeForOneRotation="1.5s" width="1.675em" height="1.675em"/>
            </div>
          )
        }
        case "resolved": {
          const listingForChild = resolver.value.underlying.data[props.childID]

          if (!listingForChild || listingForChild.length === 0) {
            return <div class="text-sm">No registered seasons</div>
          }

          return (
            <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(10em, 1fr));">
              {
                listingForChild.map(v => {
                  return (
                    <div class="text-sm px-2 my-1" style="white-space:break-spaces;">
                      <RouterLink to={routeDetailToRoutePath({name: "player-editor", playerID: props.childID, registrationID: v.registrationID})}>
                        <span class="il-link" data-test={`seasonUID=${v.seasonUID}`}>{v.seasonName}</span>
                      </RouterLink>
                    </div>
                  )
                })
              }
            </div>
          )
        }
        case "error": {
          throw Error(resolver.value.underlying.error)
        }
        default: exhaustiveCaseGuard(resolver.value.underlying)
      }
    }
  }
})
