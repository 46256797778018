<template lang="pug">
div(data-test="R_EventRosterImpl")
  AutoModal(:controller="emailLegacyFormLinkBuilderModalController" data-test="EmailLegacyFormLinkBuilderModal" style="max-height: 95vh;")
  MoveEventSignupModal(v-bind="moveEventSignupModalProps", v-on="moveEventSignupModalHandlers")
  ConfirmEventSignupCancellationModal(
    v-if="confirmEventSignupCancellationModalController.visible"
    v-bind="confirmEventSignupCancellationModalController.props"
    v-on="confirmEventSignupCancellationModalController.handlers"
  )
  div(class="hidden 2xl:block") Event roster for
  div.flex.items-center
    h1(class="lg:text-4xl self-end font-medium")
      span {{ event.eventName }}
    span.inline.cursor-pointer.p-1.rounded.ml-1(
      class="hover:bg-black/[.1] active:bg-black/[.15] text-[1em]"
      v-tooltip.top='{ content: `Edit this event` }'
    )
      router-link(
        :to='editEventRouteLocation',
      )
        font-awesome-icon(:icon='["fas", "pencil"]')

  form(class="p-1" @submit.prevent="() => { adultOrPlayerNameInput.committed = adultOrPlayerNameInput.input; }")
    div(class="flex items-center")
      input(type="checkbox" v-model="includeIncompleteSignups" data-test="includeIncompleteSignups")
      span(class="ml-1") Include incomplete signups
    div(class="flex items-center")
      input(type="checkbox" :checked="includeCanceled" data-test="includeCanceled" @input="() => $emit('update:includeCanceled', !includeCanceled)")
      span(class="ml-1") Include canceled signups
    div(class="flex")
      input(type="text" placeholder="filter by adult or player name..." class="w-full" v-model="adultOrPlayerNameInput.input")
      t-btn(type="submit"  style="margin:0; margin-left:.25em;")
        div(class="text-xs") Filter
    div(
      v-if="adultOrPlayerNameInput.committed.trim() != ''"
      class="text-xs underline text-blue-700 mt-1 cursor-pointer"
      @click = "() => { adultOrPlayerNameInput.committed = adultOrPlayerNameInput.input = ''; }"
    ) Clear filter
    div(v-if="maybeVolunteerPointsLink" class="my-2" class="text-sm")
      a(:href="maybeVolunteerPointsLink.url" class="il-link" target="_blank")
        | {{ maybeVolunteerPointsLink.label }}


  div(class="p-1 w-full block 2xl:hidden")
    template(v-if="expandedEventTrackerState.expandedEvent.value.signups.length === 0")
      div No signups for this event yet.
    template(v-else)
      TinySignupsListing(v-bind="C_TinySignupsListing_props")
    //-
    //- footer
    //-
    div.flex.w-full.text-sm
      div.ml-auto
        div.flex.justify-end.text-gray-800(v-if="pagination.detail.count_totalItems > 0") Items {{ pagination.detail.zi_currentPageFirstIndex + 1 }} - {{ pagination.detail.zi_currentPageLastIndex }} of {{ pagination.detail.count_totalItems }}
        div(v-else)
          //- nothing
        div.flex.justify-end
          span.text-blue-700.cursor-pointer(v-if="pagination.detail.hasPrev" @click="pagination.zi_currentPage--") Prev {{ pagination.detail.count_itemsPerPage }}
          span.border-r.border-gray-400(v-if="pagination.detail.hasPrev && pagination.detail.hasNext" style="margin: 3px 4px;")
          span.text-blue-700.cursor-pointer(v-if="pagination.detail.hasNext" @click="pagination.zi_currentPage++") Next {{ pagination.detail.count_itemsNextPage }}


  //- display:table is so the div grows to the width of the contained table
  div(class="shadow-md p-1 table w-full hidden 2xl:block")
    table.w-full.my-2
      thead
        tr
          template(v-for="colDef in colDefs" :key="colDef.name")
            th(v-if="(colDef.name !== 'comments' && colDef.name !== 'move') && (colDef.html.if?.() ?? true)" nowrap).text-sm.font-medium.text-gray-900.text-left
              | {{ colDef.label }}
      tbody
        tr(v-if="expandedEventTrackerState.expandedEvent.value.signups.length === 0")
          td(colspan="9999")
            div.p-4.w-full.flex.justify-center.text-gray-900 No signups for this event yet.
        tr(v-else-if="pagination.detail.count_totalItems === 0")
          td(colspan="9999")
            div.p-4.w-full.flex.justify-center.text-gray-900 No signups found.
        template(v-for="(signup, index) in pagination.detail.itemsThisPage" :key="`${signup.eventSignupID}_row1`")
          tr(:data-test="`entityID=${signup.childID || signup.userID}`" :class="isModalFocus[signup.eventSignupID] ? 'bg-yellow-100' : index % 2 ? '' : 'bg-gray-100'")
            template(v-for="colDef in colDefs")
              //- this "define columns and just render 'em out" abstraction is really breaking down
              //- we now need conditional logic in the column defs about "what render type are we, if we're in big mode, maybe don't show it"
              template(v-if="colDef.name !== 'comments' && colDef.name !== 'move'")
                td.text-sm.text-gray-900.font-light.whitespace-break-spaces.break-words(v-if="colDef.html.if?.() ?? true")
                  SignupColumnRenderer(:colDef="colDef" :signup="signup")
          //-
          //- We put the following on separate rows to ease layout problems
          //-
          tr(:class="isModalFocus[signup.eventSignupID] ? 'bg-yellow-100' : index % 2 ? '' : 'bg-gray-100'")
            td(colspan="9999" class="")
              div.text-sm.text-gray-900.font-light
                QuestionAnswersListing(:eventSignupID="signup.eventSignupID" :eventData="expandedEvent")
                  template(#header)
                    div(class="text-xs.font-light") Question Answers

          tr(:class="isModalFocus[signup.eventSignupID] ? 'bg-yellow-100' : index % 2 ? '' : 'bg-gray-100'")
            td(colspan="9999")
              div.text-xs.font-light Comments:
              div.text-sm.text-gray-900.font-light
                SignupColumnRenderer(:colDef="colDefsMap.comments" :signup="signup")
              div.text-xs.text-gray-900.font-light.flex.justify-end(class="mb-2")
                SignupColumnRenderer(:colDef="colDefsMap.move" :signup="signup")
          //-
          //- bottom border for one group of rows
          //-
          tr(class="group-sep" style="visibility: collapse;")
            td(colspan="9999" class="p-0")
              //- nothing
    //-
    //- footer
    //-
    div.flex.w-full.text-xs
      div.ml-auto
        div.flex.justify-end.text-gray-800(v-if="pagination.detail.count_totalItems > 0") Items {{ pagination.detail.zi_currentPageFirstIndex + 1 }} - {{ pagination.detail.zi_currentPageLastIndex }} of {{ pagination.detail.count_totalItems }}
        div(v-else)
          //- nothing
        div.flex.justify-end
          span.text-blue-700.cursor-pointer(v-if="pagination.detail.hasPrev" @click="pagination.zi_currentPage--") Prev {{ pagination.detail.count_itemsPerPage }}
          span.border-r.border-gray-400(v-if="pagination.detail.hasPrev && pagination.detail.hasNext" style="margin: 3px 4px;")
          span.text-blue-700.cursor-pointer(v-if="pagination.detail.hasNext" @click="pagination.zi_currentPage++") Next {{ pagination.detail.count_itemsNextPage }}
  //-
  //- misc. UI things
  //-
  div.text-xs
    div.mt-2

    div.flex.gap-2
      Btn2(type="button" class="px-2 py-1" @click="emailLegacyFormLinkBuilderModalController.open()" data-test="emailParticipants-button")
        div Email Participants
      Btn2(type="button" class="px-2 py-1" @click="downloadAsExcel")
        div Download as Excel

    div.mt-2.inline-block.divide-y
      div
        ForceAddSignupSearcher(v-bind="userSearchProps" v-on="userSearchHandlers" v-slot="{hasResults, clearResults}" data-test="forceAddUser")
          div
            div Add user
            div(v-if="hasResults" @click="clearResults").text-xs.text-blue-700.cursor-pointer Clear Search
      div.mt-2.pt-2
        ForceAddSignupSearcher(v-bind="playerSearchProps" v-on="playerSearchHandlers" v-slot="{hasResults, clearResults}" data-test="forceAddPlayer")
          div
            div Add player
            div(v-if="hasResults" @click="clearResults").text-xs.text-blue-700.cursor-pointer Clear Search
</template>

<script lang="tsx">
import { computed, defineComponent, getCurrentInstance, reactive, ref, watch } from "vue"
import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";


import * as R_EventEditor from "./R_EventEditor.ilx";
import { SignupColumnRenderer, EventSignupColDefs, downloadEventSignupsAsXLSX, colDefsAsMap, C_TinySignupsListing, C_TinySignupsListing_Props, QuestionAnswersListing, maybeGetVolunteerPointsLink } from "./R_EventRosterImpl.iltsx"
import { EventRosterEmailLegacyFormLink } from "./EventRoster.emailLegacyFormLink"
import { ExpandedEvent, ExpandedEventTrackerState, EventRosterListingEventBus } from "./R_EventRosterImpl.ilx"

import { ForceAddSignupSearcher as C_ForceAddSignupSearcher } from "./EventRosterForceAddSignupSearcher";
import * as M_ForceAddSignupSearcher from "./EventRosterForceAddSignupSearcher"

import { propsDef } from "./R_EventRosterImpl.ilx"
import { paginationData } from "src/modules/PaginationUtils";
import { accentAwareCaseInsensitiveCompare, escapeRegExp, sortByMany, useIziToast } from "src/helpers/utils";

import C_ConfirmEventSignupCancellationModal from "./ConfirmEventSignupCancellationModal.vue"
import * as M_ConfirmEventSignupCancellationModal from "./ConfirmEventSignupCancellationModal.ilx"

import * as MoveEventSignupModal from "./MoveEventSignupModal"
import { Btn2 } from "../UserInterface/Btn2";
import { AutoModal, DefaultModalController_r } from "../UserInterface/Modal";

export default defineComponent({
  components: {
    EventRosterEmailLegacyFormLink,
    SignupColumnRenderer,
    ForceAddSignupSearcher: C_ForceAddSignupSearcher,
    TinySignupsListing: C_TinySignupsListing,
    ConfirmEventSignupCancellationModal: C_ConfirmEventSignupCancellationModal,
    MoveEventSignupModal: MoveEventSignupModal.MoveEventSignupModal,
    QuestionAnswersListing,
    Btn2,
    AutoModal,
  },
  props: propsDef,
  emits: {
    "update:includeCanceled": (_: boolean) => true,
  },
  setup(props) {
    const iziToast = useIziToast();

    const adultOrPlayerNameInput = reactive({
      input: "",
      committed: ""
    })
    const includeIncompleteSignups = ref(false);

    const expandedEventTrackerState = computed(() => {
      return ExpandedEventTrackerState(axiosInstance, props.expandedEvent);
    });

    const event = computed<ExpandedEvent>(() => {
      return expandedEventTrackerState.value.expandedEvent.value
    });

    const filteredSignups = computed(() => {
      return applyIncludeIncompleteSignups(applyNameFilter(event.value.signups));

      function applyIncludeIncompleteSignups(vs: ilapi.event.EventSignup[]) : ilapi.event.EventSignup[] {
        if (includeIncompleteSignups.value) {
          // include incomplete currently means "show everything we have",
          // because we pull all non-canceled (complete or incomplete) signups from the backend
          return vs;
        }
        else {
          // DO NOT include incomplete signups
          // as in, only show those that are "paid=true"
          return vs.filter(signup => !!signup.paid)
        }
      }

      function applyNameFilter(vs: ilapi.event.EventSignup[]) : ilapi.event.EventSignup[] {
        if (adultOrPlayerNameInput.committed.trim()) {
          const pattern = new RegExp(escapeRegExp(adultOrPlayerNameInput.committed.trim()), "i");
          return vs.filter(signup => {
            const name = signup.childID
              ? `${signup.childFirstName} ${signup.childLastName}`
              : `${signup.userFirstName} ${signup.userLastName}`;
            return pattern.test(name);
          });
        }
        else {
          return vs;
        }
      }
    })

    watch(() => filteredSignups.value, () => {
      pagination.zi_currentPage = 0;
    });

    const pagination = reactive((() => {
      const itemsPerPage = 25;
      const zi_currentPage = ref(0);
      return {
        zi_currentPage,
        detail: computed(() => {
          return paginationData(filteredSignups.value, zi_currentPage.value, itemsPerPage)
        })
      }
    })());

    const userSearchProps : M_ForceAddSignupSearcher.Props = reactive({
      entitiesHavingSignups: computed(() : Set<string> => expandedEventTrackerState.value.usersHavingActiveSignups.value),
      entitySearcher: async (search: string) => {
        return (await ilapi.findVolunteers(axiosInstance, {search}))
        .sort(sortByMany(
            (l,r) => accentAwareCaseInsensitiveCompare(l.lastName, r.lastName),
            (l,r) => accentAwareCaseInsensitiveCompare(l.firstName, r.firstName),
          ))
          .map(result => {
            return {
              name: `${result.firstName} ${result.lastName}`,
              entityID: result.ID
            }
          });
      },
    });

    const userSearchHandlers : M_ForceAddSignupSearcher.Emits = {
      entitySelected: async (entityID) => {
        if (expandedEventTrackerState.value.usersHavingActiveSignups.value.has(entityID)) {
          // shouldn't happen
          return;
        }
        await expandedEventTrackerState.value.forceCreateActiveUnpaidSignup(entityID, "user");
      }
    }

    const playerSearchProps : M_ForceAddSignupSearcher.Props = reactive({
      entitiesHavingSignups: computed(() : Set<string> => expandedEventTrackerState.value.playersHavingActiveSignups.value),
      entitySearcher: async (search: string) => {
        return (await ilapi.findPlayers(axiosInstance, {search}))
          .sort(sortByMany(
            (l,r) => accentAwareCaseInsensitiveCompare(l.playerLastName, r.playerLastName),
            (l,r) => accentAwareCaseInsensitiveCompare(l.playerFirstName, r.playerFirstName),
          ))
          .map(result => {
            return {
              name: `${result.playerFirstName} ${result.playerLastName}`,
              entityID: result.childID
            }
          });
      },
    })

    const playerSearchHandlers : M_ForceAddSignupSearcher.Emits = {
      entitySelected: async (entityID) => {
        if (expandedEventTrackerState.value.playersHavingActiveSignups.value.has(entityID)) {
          // shouldn't happen
          return;
        }
        await expandedEventTrackerState.value.forceCreateActiveUnpaidSignup(entityID, "player");
      }
    }

    const eventRosterListingBus = (() => {
      const ret = new EventRosterListingEventBus();
      ret.onCancelSignup(eventSignup => confirmEventSignupCancellationModalController.show(eventSignup));
      ret.onMoveSignup(eventSignup => {
        isModalFocus[eventSignup.eventSignupID] = true;
        moveEventSignupModalProps.value = {
          isOpen: true,
          eventSignup
        }
      });
      ret.onUpdateComment(async (eventSignup, freshComment) => {
        try {
          await ilapi.event.updateSignup(axiosInstance, {eventSignupID: eventSignup.eventSignupID, comments: freshComment});
          eventSignup.comments = freshComment;
          iziToast.success({message: "Comments updated."})
        }
        catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err);
        }
      })
      return ret;
    })();

    const colDefs = computed(() => {
      return EventSignupColDefs(eventRosterListingBus, expandedEventTrackerState);
    });

    const colDefsMap = computed(() => {
      return colDefsAsMap(colDefs.value);
    })

    const downloadAsExcel = async () : Promise<void> => {
      await downloadEventSignupsAsXLSX(colDefs.value, event.value.signups.filter( ( signup ) => {
        return ( signup.paid || includeIncompleteSignups.value )
      }), event.value.rosterLayoutDetail);
    }

    const isModalFocus = reactive<{[eventID: iltypes.Guid]: boolean}>({})

    const C_TinySignupsListing_props = computed<C_TinySignupsListing_Props>(() => {
      return reactive({
        colDefs: colDefs,
        signups: pagination.detail.itemsThisPage,
        highlighted: isModalFocus,
        eventData: props.expandedEvent,
      })
    })

    const confirmEventSignupCancellationModalController = M_ConfirmEventSignupCancellationModal.DefaultController({
      handlers: {
        confirmCancellation: async (eventSignup) => {
          await expandedEventTrackerState.value.cancelEventSignup(eventSignup);
          confirmEventSignupCancellationModalController.hide();
        },
        doNotCancel: () => {
          confirmEventSignupCancellationModalController.hide();
        }
      },
      preShow: (props) => {
        isModalFocus[props.eventSignup.eventSignupID] = true;
      },
      preHide: (props) => {
        delete isModalFocus[props.eventSignup.eventSignupID];
      }
    })

    const moveEventSignupModalProps = ref<MoveEventSignupModal.Props>({isOpen: false, eventSignup: null})
    const moveEventSignupModalHandlers : MoveEventSignupModal.Emits = {
      close: () => {
        delete isModalFocus[
          // `eventSignup` is expected to be definitely truthy here -- can't be being shown unless it's truthy
          moveEventSignupModalProps.value.eventSignup!.eventSignupID
        ];
        moveEventSignupModalProps.value = {
          isOpen: false,
          // either we shouldn't ever set this back to null, or we need a smarter "set to null after it's really closed and all the animation is done"
          // because it looks jarring to clear this, while the close animation is playing
          eventSignup: moveEventSignupModalProps.value.eventSignup
        }
      },
      doMove: async ({eventSignup, freshEventID, comments}) => {
        const result = await expandedEventTrackerState.value.moveEventSignup({eventSignup, moveToThisEventID: freshEventID, comments});
        if (result.ok) {
          moveEventSignupModalHandlers.close();
        }
        else {
          // no-op; we assume axios error handlers have issued any releveant toasts
        }
      }
    }

    const maybeVolunteerPointsLink = computed(() => maybeGetVolunteerPointsLink(props.expandedEvent))

    const emailLegacyFormLinkBuilderModalController = (() => {
      return DefaultModalController_r({
        title: () => <>
          <div>Email Participants</div>
          <div class="my-2 border-b"/>
        </>,
        content: () => <div class="my-2">
          <EventRosterEmailLegacyFormLink
            event={expandedEventTrackerState.value.expandedEvent.value}
            onComplete={() => emailLegacyFormLinkBuilderModalController.close()}
          />
        </div>
      })
    })();

    return {
      colDefs,
      colDefsMap,

      adultOrPlayerNameInput,
      includeIncompleteSignups,

      editEventRouteLocation: R_EventEditor.routeDetailToRoutePath({name: R_EventEditor.RouteName.edit, eventID: event.value.eventID}),

      expandedEventTrackerState: expandedEventTrackerState,
      event,
      pagination,

      userSearchProps,
      userSearchHandlers,
      playerSearchProps,
      playerSearchHandlers,

      downloadAsExcel,

      C_TinySignupsListing_props,

      confirmEventSignupCancellationModalController,

      moveEventSignupModalProps,
      moveEventSignupModalHandlers,
      isModalFocus,
      maybeVolunteerPointsLink,
      emailLegacyFormLinkBuilderModalController,
    }
  }
})

</script>

<style scoped>
th, td {
  border-collapse: collapse;
  border: 1px solid #DDD;
}
th, td {
  padding:.25em;
  vertical-align: top;
}

tr:first-child th {
  border-top: none;
}

tr > th:first-child, tr > td:first-child {
  border-left: none;
}

tr > th:last-child, tr > td:last-child {
  border-right: none;
}

tr:last-child > td {
  border-bottom: none;
}

tr.group-sep:not(:last-child) > td {
  border: 1px solid #555;
}
</style>
