<template lang="pug">
div(v-if='loaded', data-cy='confirmVoid')
  h1.m-2(class='md:mx-6') Are you sure you want to void this invoice?
  div
    h3.m-2(class='md:m-6') Invoice # {{ invoiceID }}
    LineItemsDisplay(:invoice='invoices[invoiceID]')
    .w-full.flex.justify-between(class='md:w-auto md:mx-4')
      t-btn.mt-2.mb-16(
        class='md:mt-6 md:mb-2',
        @click='$router.go(-1)',
        data-cy='backBtn'
      )
        font-awesome-icon.mr-2(:icon='["fas", "arrow-left"]')
        | Go Back
      .flex.flex-col(class='md:flex-row')
        t-btn.mt-2(class='md:mt-6', @click='payInvoice(invoiceID)')
          | Pay Invoice # {{ invoiceID }}
        t-btn.mt-2(class='md:mt-6', @click='voidInvoice(invoiceID)', data-test='do-void')
          | Void Invoice # {{ invoiceID }}
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'


import LineItemsDisplay from 'src/components/Payment/LineItemsDisplay.vue'
import { axiosInstance } from 'src/boot/axios'

import * as R_Checkout from 'src/components/Payment/pages/Checkout.route'
import { Integerlike } from 'src/interfaces/InleagueApiV1'
import { CheckoutStore } from "src/store/CheckoutStore"

export default defineComponent({
  name: 'ReviewAndVoidInvoice',
  components: {
    LineItemsDisplay,
  },
  setup() {
    const loaded = ref(false)
    const invoiceID = ref('')

    const route = useRoute()
    const router = useRouter()


    const invoices = computed(() => {
      return CheckoutStore.value.invoice
    })

    const voidInvoice = async (invoiceID: string) => {
      loaded.value = false

      await CheckoutStore.voidInvoice(invoiceID);

      // hardcoded assumption about where we want to return to
      router.go(-1)
    }

    const payInvoice = async (invoiceID: Integerlike) => {
      await router.push(R_Checkout.routeDetailToRouteLocation({
        invoiceInstanceIDs: [invoiceID]
      }))
    }

    const getInvoice = async () => {
      if (!invoices.value[invoiceID.value]) {
        const response = await axiosInstance.get(`v1/invoice/${invoiceID.value}`)
        CheckoutStore.setInvoice(response.data.data)
      }
      // console.log('invoice received')
      loaded.value = true
    }

    onMounted(async () => {
      invoiceID.value = route.params.invoiceID as string
      await getInvoice()
    })

    return {
      loaded,
      invoiceID,
      voidInvoice,
      payInvoice,
      getInvoice,
      invoices,
    }
  },
})
</script>
