import { FormKit } from "@formkit/vue"
import dayjs from "dayjs"
import { ReifiedPromise } from "src/helpers/ReifiedPromise"
import { vReqT, parseIntOr, uniqueBy, sortByDayJS, accentAwareCaseInsenstiveSortMany, maybePrettifyUSPhoneNumber, parseFloatOr, FkOwnedUiOptions } from "src/helpers/utils"
import { Competition, Division, Guid, Season } from "src/interfaces/InleagueApiV1"
import { BasicAutoTable2, BasicAutoTable2Slots, typedBasicAutoTable2Props } from "src/modules/BasicAutoTable"
import { ColDef, freshSortState } from "src/modules/TableUtils"
import { computed, defineComponent } from "vue"
import { sortBy } from "src/helpers/utils"
import * as PlayerEditor from "src/components/PlayerEditor/PlayerEditor.route"
import { RouterLink } from "vue-router"
import { registrationGradeOrdering } from "src/helpers/RegistrationUtils"
import { DashboardRegistrationOverview, DashboardRegistrationOverview_PerCompReg } from "./Dashboard.io"
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons"
import { SoccerBall } from "../SVGs"

export const RegistrationOverviewElem = defineComponent({
  props: {
    seasonUidOptions: vReqT<FkOwnedUiOptions>(),
    competitionUidOptions: vReqT<FkOwnedUiOptions>(),
    divIdOptions: vReqT<FkOwnedUiOptions>(),
    selectedSeason: vReqT<Season | null>(),
    selectedCompetition: vReqT<Competition | null>(),
    selectedDiv: vReqT<Division | null>(),
    report: vReqT<ReifiedPromise<DashboardRegistrationOverview>>(),
  },
  emits: {
    "update:selectedSeasonUID": (_: Guid) => true,
    "update:selectedCompetitionUID": (_: Guid) => true,
    "update:selectedDivID": (_: Guid) => true,
  },
  setup(props, ctx) {
    const model_selectedSeasonUID = {
      get value() { return props.selectedSeason?.seasonUID || "" },
      set value(fresh) {
        if (fresh === props.selectedSeason?.seasonUID || "") {
          return
        }
        ctx.emit("update:selectedSeasonUID", fresh)
      }
    }

    const model_selectedCompetitionUID = {
      get value() { return props.selectedCompetition?.competitionUID || "" },
      set value(fresh) {
        if (fresh === props.selectedCompetition?.competitionUID || "") {
          return
        }
        ctx.emit("update:selectedCompetitionUID", fresh)
      }
    }

    const model_selectedDivID = {
      get value() { return props.selectedDiv?.divID || "" },
      set value(fresh) {
        if (fresh === props.selectedDiv?.divID || "") {
          return
        }
        ctx.emit("update:selectedDivID", fresh)
      }
    }

    const completeSelection = computed<boolean>(() => {
      return !!props.selectedSeason && !!props.selectedCompetition && !!props.selectedDiv
    })

    const getCertsForChildID = (childID: Guid, category: "Coach" | "Referee") => {
      const certs = props.report.getOrNull()?.volCertsByChildID[childID]?.filter(v => v.category === category)
      if (!certs || certs.length === 0) {
        return ""
      }

      return certs
        .filter(uniqueBy(v => v.code))
        .sort(sortBy(v => parseIntOr(v.code, "zzz" + v.code))) // sort by integer codes first, then by non-integer codes
        .map(v => v.certificationDesc)
        .join(", ")
    }

    const hasPlayerEditorRoutePerms = computed<boolean>(() => PlayerEditor.hasRoutePerms())

    const NameWithLink = defineComponent({
      props: {
        which: vReqT<"playerFirstName" | "playerLastName">(),
        obj: vReqT<DashboardRegistrationOverview_PerCompReg>(),
      },
      setup(props) {
        return () => {
          const uiName = props.obj[props.which]
          if (hasPlayerEditorRoutePerms.value) {
            return <RouterLink to={PlayerEditor.routeDetailToRoutePath({name: "player-editor", playerID: props.obj.childID, registrationID: props.obj.registration.registrationID})} {...{target: "_blank"}} class="il-link">
              <span>{uiName}</span>
            </RouterLink>
          }
          else {
            return <span>{uiName}</span>
          }
        }
      }
    })

    const colDefs = computed(() : ColDef<DashboardRegistrationOverview_PerCompReg>[] => {
      const defs : ColDef<DashboardRegistrationOverview_PerCompReg>[] = [
        {
          id: "playerName-first",
          label: "Player First Name",
          html: v => <NameWithLink which="playerFirstName" obj={v}/>,
          xlsx: v => v.playerFirstName,
          sort: accentAwareCaseInsenstiveSortMany(v => v.playerFirstName),
        },
        {
          id: "playerName-last",
          label: "Player Last Name",
          html: v => <NameWithLink which="playerLastName" obj={v}/>,
          xlsx: v => v.playerLastName,
          sort: accentAwareCaseInsenstiveSortMany(v => v.playerLastName),
        },
        {
          id: "dob",
          label: "DOB",
          html: v => dayjs(v.playerBirthDate).format("MMM/DD/YYYY"),
          sort: sortByDayJS(v => v.playerBirthDate),
        },
        {
          id: "gender",
          label: "Gender",
          html: v => v.playerGender,
        },
        {
          id: "waitlistStatus",
          label: "Waitlisted",
          html: v => v.competitionRegistration.waitlistStatus === "waitlisted"
            ? "WL"
            : v.competitionRegistration.waitlistStatus === "waitlistedButUnpaid"
            ? "WL (pending payment)"
            : "",
          sort: sortBy(v => v.competitionRegistration.waitlistStatus === "waitlisted" ? 2
            : v.competitionRegistration.waitlistStatus === "waitlistedButUnpaid" ? 1
            : 0,
          )
        },
        {
          id: "registrationDate",
          label: "Registration Date",
          html: v => <div>
            <div>{dayjs(v.competitionRegistration.registrationDate).format("MMM/DD/YYYY")}</div>
            <div>{dayjs(v.competitionRegistration.registrationDate).format("h:mm a")}</div>
          </div>,
          xlsx: v => dayjs(v.competitionRegistration.registrationDate).format("MMM/DD/YYYY h:mm a"),
          sort: sortByDayJS(v => v.competitionRegistration.registrationDate)
        },
        {
          id: "comments",
          cellStyle: "min-width:16em;",
          label: "Registration Comments",
          html: v => <div class="text-sm">{v.registration.comments}</div>, // not per-compreg?
          xlsx: v => v.registration.comments,
        },
        {
          id: "parent1name",
          label: "Parent",
          html: v => `${v.parent1.firstName} ${v.parent1.lastName}`,
          sort: accentAwareCaseInsenstiveSortMany(v => v.parent1.lastName, v => v.parent1.firstName)
        },
        {
          id: "parent1Email",
          label: "Parent Email",
          html: v => v.parent1.email
        },
        {
          id: "parent1phone",
          label: "Parent Phone",
          cellStyle: "text-wrap: nowrap;",
          html: v => maybePrettifyUSPhoneNumber(v.parent1.phone),
        },
        {
          id: "playerRating",
          label: "Most Recent Rating",
          html: v => v.playerRatingRecent
            ? <div>
              <div>{v.playerRatingRecent.ratingOverall}</div>
              <div class="text-xs">({v.playerRatingRecent.seasonName})</div>
            </div>
            : "",
          xlsx: v => v.playerRatingRecent ? `${v.playerRatingRecent.ratingOverall} (${v.playerRatingRecent?.seasonName})` : "",
          sort: sortBy(v => parseFloatOr(v.playerRatingRecent?.ratingOverall, Infinity))
        },
        {
          id: "school",
          label: "School",
          html: v => v.registration.playerSchool
        },
        {
          id: "grade",
          label: "Grade",
          html: v => v.registration.grade,
          sort: sortBy(v => registrationGradeOrdering(v.registration.grade)),
        },
        {
          id: "familialCoachCountForThisSeasonDiv",
          label: props.selectedDiv
            ? `Volunteers: Coaches (${props.selectedDiv.displayName || props.selectedDiv.division})`
            : "Volunteers: Coaches",
          html: v => v.familialCoachVolunteerCountForThisSeasonDiv,
          sort: sortBy(v => parseIntOr(v.familialCoachVolunteerCountForThisSeasonDiv, Infinity)),
        },
        {
          id: "familialRefCountForThisSeason",
          label: "Volunteers: Refs",
          html: v => v.familialRefVolunteerCountForThisSeason,
          sort: sortBy(v => parseIntOr(v.familialRefVolunteerCountForThisSeason, Infinity)),
        },
        {
          id: "coachesAllTime",
          label: "Coach Assignments (all time)",
          html: v => v.familialCoachAssignmentCountForAllTime,
          sort: sortBy(v => parseIntOr(v.familialCoachAssignmentCountForAllTime, Infinity))
        },
        {
          id: "coachCerts",
          label: "Coach Certs",
          html: v => getCertsForChildID(v.childID, "Coach"),
        },
        {
          id: "refCerts",
          label: "Ref Certs",
          html: v => getCertsForChildID(v.childID, "Referee")
        },
        ...props.report.status === "resolved"
          ? props.report.data.registrationQuestions.map((q) : ColDef<DashboardRegistrationOverview_PerCompReg> => {
            return {
              id: `questionID-${q.id}`,
              label: q.shortLabel,
              html: v => v.registrationAnswers.find(a => a.questionID === q.id)?.answer || ""
            }
          })
          : []
      ]

      return defs.map(def => ({
        ...def,
        headerClass: "whitespace-nowrap p-1 border",
        cellClass: "p-1 border",
        cellStyle: (def.cellStyle || "") + "vertical-align: top;",
      }))
    })

    /**
     * prop to pass to table element, to specify generated xlsx name (or if undefined "do not offer to create an xlsx file")
     */
    const xlsxConfig = computed(() => {
      if (props.report.status !== "resolved") {
        return undefined
      }

      const season = props.report.data.season
      const competition = props.report.data.competition
      const division = props.report.data.division

      return {
        filename: `reg-overview-${season.seasonName}-${competition.competition}-${division.division}.xlsx`.replace(/\s+/g, "_")
      }
    })

    const colors = {
      familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCertAndHasSomeCoachAssignment: "#a2f9ff",
      familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCert: "#c3d7fa",
      familiallyRelatedUserHasVolunteeredAsACoachForThisDiv: "#cfe8ca",
      familiallyRelatedUserHasSomeCoachCertButHasNotVolunteeredAsACoachThisSeasonDiv: "#ecce9c",
    }

    // note: questions on coldefs might not be present when we get here (i.e. computed saw report.status != "resolved"),
    // but we assume we won't ever sort on question columns anyway.
    const sortState = freshSortState(colDefs.value)
    sortState.reconfigure([{colID: "playerName-last", dir: "asc"}])

    return () => {
      return <div style="--fk-bg-input: white; --fk-margin-outer:none; --fk-padding-input:.35em;" data-test="DashboardRegistrationOverview">
        <div class="my-1">This is a summary report of all players in the selected program and division. Volunteer-related columns like coaches or refs apply to all adults in the player's family.</div>
        <table
          // fk: need a key so that placeholders are re-engaged when necessary after the first interaction
          key={`${model_selectedSeasonUID.value}/${model_selectedCompetitionUID.value}/${model_selectedDivID.value}`}
        >
          <tr>
            <td class="p-1 font-medium text-sm">Season</td>
            <td>
              <div class="my-1">
                <FormKit
                  type="select"
                  disabled={props.seasonUidOptions.disabled}
                  options={props.seasonUidOptions.options}
                  v-model={model_selectedSeasonUID.value}
                  data-test="seasonUID"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="p-1 font-medium text-sm">Program</td>
            <td>
              <div class="my-1">
                <FormKit
                  type="select"
                  disabled={props.competitionUidOptions.disabled}
                  options={props.competitionUidOptions.options}
                  v-model={model_selectedCompetitionUID.value}
                  data-test="competitionUID"
                />
              </div>
            </td>
          </tr>
          <tr>
            <td class="p-1 font-medium text-sm">Division</td>
            <td>
              <div class="my-1">
                <FormKit
                  type="select"
                  disabled={props.divIdOptions.disabled}
                  options={props.divIdOptions.options}
                  v-model={model_selectedDivID.value}
                  placeholder="-- Select a Division for a Summary Report --"
                  data-test="divID"
                />
              </div>
            </td>
          </tr>
        </table>

        {props.report.status === "resolved"
          ? (() => {
            const agg = props.report.data.aggregates
            return <div class="my-1">
              <div class="flex items-center gap-2 flex-wrap">
                <div class="inline-block rounded border p-1">
                  <span class="text-sm">Number of Head Coach Volunteers:</span>
                  <span> {agg.headCoachVols}</span>
                </div>
                <div class="inline-block rounded border p-1">
                  <span class="text-sm">Number of Ref Volunteers:</span>
                  <span> {agg.refVols}</span>
                </div>
                <div class="inline-block rounded border p-1">
                  <span class="text-sm">Total Relevant Family Members:</span>
                  <span> {agg.totalRelatedFamilyMembers}</span>
                </div>
                <div class="inline-block rounded border p-1">
                  <span class="text-sm">Number of Family Members Having Any Coach Cert:</span>
                  <span> {agg.familyMembersHavingAnyCoachCert}</span>
                </div>
                <div class="inline-block rounded border p-1">
                  <span class="text-sm">Number of Family Members Having Any Ref Cert:</span>
                  <span> {agg.familyMembersHavingAnyRefCert}</span>
                </div>
                <div class="inline-block rounded border p-1">
                  <span class="text-sm">Number of Family Members Ever Volunteered as a Coach:</span>
                  <span> {agg.familyMembersHavingAnyCoachVolunteerRole}</span>
                </div>
              </div>


              <Disclosure>
                {({open}: {open: boolean, close: () => void}) => (
                    <>
                      <DisclosureButton
                        class="flex items-center text-sm my-2"
                      >
                        <FontAwesomeIcon icon={faChevronDown} class={`${open ? '' : '-rotate-90 transform'} transition-transform`}/>
                        <span class="ml-1">Color Key</span>
                      </DisclosureButton>
                      <DisclosurePanel class="px-4 pb-2 text-sm text-gray-800">
                        <p>In order of precedence:</p>
                        <div class="block text-sm rounded border p-1" style={{backgroundColor: colors.familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCertAndHasSomeCoachAssignment}}>
                          1. <span class="text-sm">Family has at least one user who: volunteered as a coach for this division, and has some coach cert, and has some coach assignment</span>
                        </div>
                        <div class="my-1 block text-sm rounded border p-1" style={{backgroundColor: colors.familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCert}}>
                          2. <span class="text-sm">Family has at least one user who: volunteered as a coach for this division, and has some coach cert</span>
                        </div>
                        <div class="my-1 block text-sm rounded border p-1" style={{backgroundColor: colors.familiallyRelatedUserHasVolunteeredAsACoachForThisDiv}}>
                          3. <span class="text-sm">Family has at least one user who: volunteered as a coach for this division</span>
                        </div>
                        <div class="my-1 block text-sm rounded border p-1" style={{backgroundColor: colors.familiallyRelatedUserHasSomeCoachCertButHasNotVolunteeredAsACoachThisSeasonDiv}}>
                          4. <span class="text-sm">Family has at least one user who: has a coach cert, but has not volunteered for this season+division</span>
                        </div>
                      </DisclosurePanel>
                    </>
                  )
                }
              </Disclosure>
            </div>
          })()
          : null}

        <BasicAutoTable2
          class="my-2"
          {...typedBasicAutoTable2Props({
            colDefs: colDefs.value,
            rows: props.report.getOrNull()?.perCompReg ?? [],
            sortState: sortState,
            rowKey: row => row.competitionRegistration.competitionRegistrationID.toString(),
            asXLSX: xlsxConfig.value,
            rowAttrs: row => {
              return {
                style: {
                  backgroundColor: row.familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCertAndHasSomeCoachAssignment
                    ? colors.familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCertAndHasSomeCoachAssignment
                    : row.familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCert
                    ? colors.familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCert
                    : row.familiallyRelatedUserHasVolunteeredAsACoachForThisDiv
                    ? colors.familiallyRelatedUserHasVolunteeredAsACoachForThisDiv
                    : row.familiallyRelatedUserHasSomeCoachCertButHasNotVolunteeredAsACoachThisSeasonDiv
                    ? colors.familiallyRelatedUserHasSomeCoachCertButHasNotVolunteeredAsACoachThisSeasonDiv
                    : "white"
                }
              }
            },
            paginationOptions: [10, 25, 50, "ALL"],
            noData: () => !completeSelection.value ? null
              : props.report.status === "pending" ? <div class="p-2 flex gap-2 items-center"><SoccerBall size="1.25em"/>Loading...</div>
              : props.report.status === "error" ? <div class="p-2">An error occured while loading this data.</div>
              : <div class="p-2">No program registrations found.</div>
          })}
        >
          {{
            aboveTable: (ctx.slots as RegistrationOverviewElemSlots).aboveTable
          } satisfies BasicAutoTable2Slots}
        </BasicAutoTable2>
      </div>
    }
  }
})

export interface RegistrationOverviewElemSlots {
  aboveTable?: BasicAutoTable2Slots["aboveTable"]
}
