import { Guid } from "src/interfaces/InleagueApiV1";
import { RouteLocationRaw } from "vue-router";

import { hasSomeRoutePermission as invoiceTemplateShared_hasSomeRoutePermission } from 'src/components/InvoiceTemplates/InvoiceTemplateListing.route'

export const RouteName = "invoice-template-editor"

export function route(args: {mode: "new", seasonUID: Guid} | {mode: "edit", invoiceTemplateID: Guid}) : RouteLocationRaw {
  return {name: RouteName, query: args};
}

export function hasSomeRoutePermission() : boolean {
  return invoiceTemplateShared_hasSomeRoutePermission()
}
