import { rpc } from "src/composables/Rpc"
import { Guid, DateTimelike, Numbool, Integerlike, Floatlike, Competition, Division, Season } from "src/interfaces/InleagueApiV1"

export interface DashboardRegistrationOverview {
  registrationQuestions: {
    id: Guid,
    label: string,
    shortLabel: string,
  }[],
  perCompReg: DashboardRegistrationOverview_PerCompReg[],
  volCertsByChildID: {
    [childID: Guid]: undefined | {
      category: "Coach" | "Referee",
      certDate: DateTimelike,
      code: string,
      dateAdded: DateTimelike,
      provisional: Numbool,
      stackRecordKey: Guid,
      validated: Numbool,
      vCertID: Integerlike,
      childID: Guid,
      userID: Guid,
      discipline: string,
      certificationDesc: string,
      isSafeHaven: Numbool,
      isConcussion: Numbool,
      requiresValidation: Numbool
    }[]
  },
  aggregates: {
    headCoachVols: number,
    asstCoachVols: number,
    asstCoachOnlyVols: number,
    refVols: number,
    totalRelatedFamilyMembers: number
    familyMembersHavingAnyCoachCert: number,
    familyMembersHavingAnyRefCert: number,
    familyMembersHavingAnyCoachVolunteerRole: number,
  },
  competition: Competition,
  season: Season,
  division: Division,
}

export interface DashboardRegistrationOverview_PerCompReg {
  playerFirstName: string,
  playerLastName: string,
  playerBirthDate: string,
  playerGender: string,
  childID: Guid,
  competitionRegistration: {
    competitionRegistrationID: Integerlike,
    waitlistStatus: "waitlistedButUnpaid" | "waitlisted" | null,
    registrationDate: DateTimelike,
  }
  competition: {
    competitionUID: Guid,
    competitionID: Integerlike,
    competition: string,
  },
  division: {
    divID: Guid,
    gender: string,
    divNum: Integerlike,
  },
  parent1: {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
  },
  playerRatingRecent: null | {
    ratingOverall: Floatlike,
    seasonUID: Guid,
    seasonID: Integerlike,
    seasonName: string,
  },
  registration: {
    registrationID: Guid,
    comments: string,
    playerSchool: string,
    grade: string,
  },
  registrationAnswers: {
    questionID: Guid,
    answer: string,
  }[]
  familialCoachVolunteerCountForThisSeasonDiv: number,
  familialRefVolunteerCountForThisSeason: number,
  familialCoachAssignmentCountForAllTime: number,
  familiallyRelatedUserHasSomeCoachCertButHasNotVolunteeredAsACoachThisSeasonDiv: Numbool,
  familiallyRelatedUserHasVolunteeredAsACoachForThisDiv: Numbool,
  familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCert: Numbool,
  familiallyRelatedUserHasVolunteeredAsACoachForThisDivAndHasSomeCoachCertAndHasSomeCoachAssignment: Numbool,
}

export type DashboardRegistrationOverview_MenuOptions = {
  /**
   * If false, the user doesn't have permission to access the dashboard registration overview
   */
  authZ: boolean,
  competitions: Competition[],
  divisions: Division[],
  seasons: Season[],
  validTuples: {seasonUID: Guid, competitionUID: Guid, divID: Guid}[]
}

export const getRegistrationOverview = rpc<{competitionUID: Guid, seasonUID: Guid, divID: Guid}, DashboardRegistrationOverview>("get", "v1/dashboard/getRegistrationOverview")
export const getRegistrationOverviewMenuOptions = rpc<void, DashboardRegistrationOverview_MenuOptions>("get", "v1/dashboard/getRegistrationOverviewMenuOptions")
