<template lang="pug">
h1.text-4xl.self-end.font-medium.mb-5
  i.mr-2.fas.fa-users-cog
  | Content Administration.
div
  div(v-if='hasRequiredContentChunks')
    ContentChunkEditor(
      v-for='id in contentChunkIDs',
      :key='id',
      :contentChunkId='id'
    )
</template>


<script lang="ts">
import { defineComponent, ref, Ref, onMounted } from 'vue'

import { useRoute } from 'vue-router'
import ContentChunkEditor from 'src/components/Admin/ContentChunks/ContentChunkEditor.vue'

import { ContentChunkStore } from "src/store/ContentChunkStore"
import { axiosInstance } from 'src/boot/AxiosInstances';

export default defineComponent({
  name: 'ContentChunkAdministrator',
  components: {
    ContentChunkEditor,
  },
  setup() {
    const contentChunkIDs = ref([]) as Ref<any[]>

    const route = useRoute()

    // don't mount the editor until we resolve all required content chunks
    const hasRequiredContentChunks = ref(false);

    // Methods
    //! When calling axios to get content chunks, loading = false must be set within the axios call in the function itself, not after the await in this function (used bool specific to content chunks so contentChunk doesn't try to render before the call has completed)
    const callGetContentChunk = async () => {
      if (
        contentChunkIDs.value.length === 1 &&
        route.path.includes('content-chunk-result')
      ) {
        // console.log('opt 1')
        await ContentChunkStore.getContentChunk(axiosInstance, {
          id: contentChunkIDs.value[0],
          override: false,
        })
        ContentChunkStore.setLoadingContentChunks(false)
        // console.log('committed', loadingContentChunks())
      } else if (contentChunkIDs.value.length === 1) {
        // console.log('opt 2')

        await ContentChunkStore.getContentChunk(axiosInstance, {
          id: contentChunkIDs.value[0],
          override: true,
        })
      } else if (contentChunkIDs.value.length > 1) {
        // console.log('opt 3')

        ContentChunkStore.setLoadingContentChunks(true)
        await ContentChunkStore.getContentChunks(axiosInstance, contentChunkIDs.value)
      }
    }

    // Lifecycle Hooks
    onMounted(async () => {
      if (route.params.id) {
        contentChunkIDs.value = parseInt(route.params.id as string)
          ? [parseInt(route.params.id as string)]
          : [route.params.id]
        ContentChunkStore.setLoadingContentChunks(true)
      }
      await callGetContentChunk()
      hasRequiredContentChunks.value = true;
    })

    return {
      contentChunkIDs,
      route,
      hasRequiredContentChunks,
      callGetContentChunk,
    }
  },
})
</script>
