import { nextGlobalIntlike, noAvailableOptions, UiOptions, vReqT } from "src/helpers/utils"
import { Guid } from "src/interfaces/InleagueApiV1"
import { defineComponent, onMounted, ref, watch } from "vue"
import { getBasicPlayerRatingsReport, getBasicPlayerRatingsReportOptions } from "./PlayerRatings.io"
import { axiosAuthBackgroundInstance, axiosInstance } from "src/boot/AxiosInstances"
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia"
import { FormKit } from "@formkit/vue"
import { k_GUID } from "src/boot/TypeBoxSetup"

import * as t from "@sinclair/typebox"
import { Value } from "@sinclair/typebox/value";
import { LocationQuery } from "vue-router"
import { BasicAutoTable, BasicAutoTableDef } from "src/modules/BasicAutoTable"
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise"
import { SoccerBall } from "../SVGs"
import { Client } from "src/store/Client"

export const PlayerRatingsReport = defineComponent({
  props: {
    q: vReqT<QueryParams_PlayerRatingsReport>(),
  },
  emits: {
    updateQueryParams: (_: QueryParams_PlayerRatingsReport) => true,
  },
  setup(props, ctx) {
    const seasonOptions = ref<UiOptions>(noAvailableOptions("Loading season options..."))
    const divOptions = ref<UiOptions>(noAvailableOptions("Loading division options..."));

    const selectedDivID = ref("" as "" | Guid)
    const selectedSeasonUID_rosterYear = ref("" as "" | Guid)
    const selectedSeasonUID_ratingsYear = ref("" as "" | Guid)

    const reportGetter = ReactiveReifiedPromise<{__renderKey: string, data: BasicAutoTableDef}>()
    const ready = ref(false)

    watch([() => selectedDivID.value, () => selectedSeasonUID_rosterYear.value, () => selectedSeasonUID_ratingsYear.value], () => {
      maybeUpdateQueryParams();
    })

    const maybeUpdateQueryParams = () : void => {
      const divID = selectedDivID.value
      const rosterYear_seasonUID = selectedSeasonUID_rosterYear.value
      const ratingsYear_seasonUID = selectedSeasonUID_ratingsYear.value

      if (!divID || !rosterYear_seasonUID || !ratingsYear_seasonUID) {
        return
      }

      ctx.emit("updateQueryParams", {divID, seasonUID_rosterYear: rosterYear_seasonUID, seasonUID_ratingsYear: ratingsYear_seasonUID})
    }

    const maybeLoadReport = async () : Promise<void> => {
      const divID = selectedDivID.value
      const rosterYear_seasonUID = selectedSeasonUID_rosterYear.value
      const ratingsYear_seasonUID = selectedSeasonUID_ratingsYear.value

      if (!divID || !rosterYear_seasonUID || !ratingsYear_seasonUID) {
        return
      }

      reportGetter.run(async () => {
        return {
          __renderKey: nextGlobalIntlike(),
          data: await getBasicPlayerRatingsReport(axiosAuthBackgroundInstance, {divID, rosterYear_seasonUID, ratingsYear_seasonUID})
        }
      })
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const {seasons, divisions} = await getBasicPlayerRatingsReportOptions(axiosInstance);

        seasonOptions.value = seasons.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: [{label: "", value: ""}, ...seasons.map(v => ({label: v.seasonName, value: v.seasonUID}))]};

        divOptions.value = seasons.length === 0
          ? noAvailableOptions()
          : {disabled: false, options: [{label: "", value: ""}, ...divisions.map(v => ({label: v.division, value: v.divID}))]};

        {
          const q = props.q
          if (q?.divID && divOptions.value.options.find(v => v.value === q?.divID)) {
            selectedDivID.value = q.divID
          }
          else {
            selectedDivID.value = divOptions.value.options.find(v => !!v.value)?.value ?? ""
          }

          if (q?.seasonUID_rosterYear && seasonOptions.value.options.find(v => v.value === q?.seasonUID_rosterYear)) {
            selectedSeasonUID_rosterYear.value = q.seasonUID_rosterYear
          }
          else {
            selectedSeasonUID_rosterYear.value = seasonOptions.value.options.find(v => !!v.value)?.value ?? ""
          }

          if (q?.seasonUID_ratingsYear && seasonOptions.value.options.find(v => v.value === q?.seasonUID_ratingsYear)) {
            selectedSeasonUID_ratingsYear.value = q.seasonUID_ratingsYear
          }
          else {
            selectedSeasonUID_ratingsYear.value = seasonOptions.value.options.find(v => !!v.value)?.value ?? ""
          }
        }

        await maybeLoadReport()

        ready.value = true
      })
    })

    return () => {
      if (!ready.value) {
        return null;
      }

      return (
        <div style="--fk-padding-input: .5em; --fk-bg-input:white;" data-test="PlayerRatingsReport">
          <FormKit type="select" label="Division" disabled={divOptions.value.disabled} options={divOptions.value.options} v-model={selectedDivID.value} data-test="divID" onInput={(v:any) => {
            if (selectedDivID.value === v) {
              return
            }
            selectedDivID.value = v;
            maybeLoadReport();
          }}/>
          <FormKit type="select" label="Roster Year Season" disabled={seasonOptions.value.disabled} options={seasonOptions.value.options} v-model={selectedSeasonUID_rosterYear.value} data-test="seasonUID_rosterYear" onInput={(v: any) => {
            if (selectedSeasonUID_rosterYear.value === v) {
              return
            }
            selectedSeasonUID_rosterYear.value = v;
            maybeLoadReport();
          }}/>
          <FormKit type="select" label="Ratings Year Season" disabled={seasonOptions.value.disabled} options={seasonOptions.value.options} v-model={selectedSeasonUID_ratingsYear.value} data-test="seasonUID_ratingsYear" onInput={(v: any) => {
            if (selectedSeasonUID_ratingsYear.value === v) {
              return
            }
            selectedSeasonUID_ratingsYear.value = v
            maybeLoadReport();
          }}/>

          {reportGetter.underlying.status === "pending"
            ? <div class="my-4 p-2 w-full rounded-md border overflow-auto bg-white flex items-center gap-2">
              <SoccerBall width="1em" height="1em" color={Client.value.clientTheme.color}/>
              Loading...
            </div>
            : reportGetter.underlying.status === "resolved"
            ? <div
              class="my-4 p-2 w-full rounded-md border overflow-auto bg-white"
              data-test={`divID=${selectedDivID.value},seasonUID_roster=${selectedSeasonUID_rosterYear.value},seasonUID_ratings=${selectedSeasonUID_ratingsYear.value}`}
            >
              <BasicAutoTable
                key={reportGetter.underlying.data.__renderKey}
                tableDef={reportGetter.underlying.data.data}
                rowAttrs={row => ({"data-test": row.__rowKey})}
                paginationOptions={[100, "ALL"]}
                class="w-full"
              />
            </div>
            : null
          }

        </div>
      )
    }
  }
})

export function queryParams_playerRatingsReport(q: LocationQuery) {
  const shape = t.Object({
    divID: t.Optional(t.String({format: k_GUID})),
    seasonUID_rosterYear: t.Optional(t.String({format: k_GUID})),
    seasonUID_ratingsYear: t.Optional(t.String({format: k_GUID})),
  })

  if (Value.Check(shape, q)) {
    const {divID, seasonUID_rosterYear, seasonUID_ratingsYear} = q;
    return {
      divID,
      seasonUID_rosterYear,
      seasonUID_ratingsYear,
    }
  }
  else {
    return undefined
  }
}

export type QueryParams_PlayerRatingsReport = ReturnType<typeof queryParams_playerRatingsReport>
