<template lang="pug">
.t-page.flex.flex-col(class='md:mx-2')
  h1.ml-2.text-2xl.font-medium(class='md:text-4xl')
    font-awesome-icon.mr-2(:icon='["fas", "search"]')
    | Player Lookup
  PlayerLookup.w-full(
    v-on:selected='toPlayerView',
    :displaySeasons='true'
  )
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'

import /**/ C_PlayerLookup from 'src/components/Lookup/Player/PlayerLookup.form.vue'
import * as M_PlayerLookup from "src/components/Lookup/Player/PlayerLookup.main-ts-shim"

import * as R_PlayerEditor from "src/components/PlayerEditor/PlayerEditor.route"

export default defineComponent({
  name: 'PlayerLookupPage',
  components: {
    PlayerLookup: C_PlayerLookup,
  },
  setup() {
    const router = useRouter()

    const toPlayerView : M_PlayerLookup.Emits["selected"] = async playerDetails => {
      //
      // we probably don't want to do this; because it's in response to a user clicking some link-like text;
      // but our manual handling of the event prevents the browser from treating it like a link;
      // so there's no way to rightclick and "open in new tab" or "copy address" or etc.
      //
      if (playerDetails.seasonID === "ALL") {
        // if we're looking at "any/all" seasons, we can use the most recent registrationID, if it exists
        await router.push(
          R_PlayerEditor.routeDetailToRoutePath({
            name: "player-editor",
            playerID: playerDetails.row.childID,
            registrationID: playerDetails.row.mostRecentRegistrationID
          })
        );
      }
      else {
        // if we're looking at some specific seasonID, we use the registration for that particular season, if it exists
        await router.push(
          R_PlayerEditor.routeDetailToRoutePath({
            name: "player-editor",
            playerID: playerDetails.row.childID,
            registrationID: playerDetails.row.registrations?.[0]?.registrationID
          })
        );
      }
    }

    return {
      router,
      toPlayerView,
    }
  },
})
</script>
