import { defineComponent, ref, Ref, computed, onMounted } from 'vue'
import Portlet from 'src/components/Portlets/Portlet.vue'
import { formatDateAsNums, formatTime, dayjsOr, dateMin } from 'src/helpers/formatDate'
import { getCurrentInstance } from 'vue'

import { GameID, Guid, Integerlike, UpcomingGamesPortlet, UpcomingGamesPortlet_Player, UpcomingGamesPortlet_Team, UpcomingGamesPortlet_UpcomingGame } from 'src/interfaces/InleagueApiV1'
import { System } from 'src/store/System'
import { Client } from 'src/store/Client'
import { exhaustiveCaseGuard, max, objOr, parseIntOr, sortBy, sortByMany, vOptT, vReqT } from 'src/helpers/utils'
import { AreaCoachDetail_camelCase, AreaCoachGameData } from 'src/composables/InleagueApiV1.Game'
import { getAreaCoaches } from '../Schedule/page/schedules.ilx'
import { AxiosInstance } from 'axios'
import { freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'
import { teamScheduleURL } from '../Schedule/TeamSchedule'
import { Dayjs } from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faAngleDoubleRight, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

export const UpcomingGamesPortletElement = defineComponent({
  name: 'UpcomingGames',
  props: {
    showContent: vOptT<boolean>(false),
    portlet: vReqT<UpcomingGamesPortlet>(),
  },
  setup(props) {
    const teamDetails = ref({}) as Ref<any[]>
    const showTeamDetails = ref(false)
    const show = ref(props.showContent)
    const ready = ref(false);

    /**
     * generates the expected key type for `userChildren` map
     */
    const userChildrenKeyGen = (player: UpcomingGamesPortlet_Player, team: UpcomingGamesPortlet_Team) => {
      return `${player.firstName} ${player.lastName} ${team.teamID}`;
    }
    /**
     * map of ((child, team) -> <game-info>)
     * todo/clarify: there's no `user` here; what does it mean to say `userChildren`?
     */
    const userChildren = ref<{[key: string]: MungedUpcomingGamesPerChild}>({})
    const localInstance = getCurrentInstance()



    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const clientUrl = computed(() => {
      return Client.value.instanceConfig.appdomain
    })

    const showPopup = (gameDetails: any) => {
      teamDetails.value = gameDetails
      showTeamDetails.value = true
    }

    const toggleExpand = (expand: boolean) => {
      show.value = expand
    }

    const sortGames = () : void => {
      const children : {[key: string]: MungedUpcomingGamesPerChild} = {}

      for (let i = 0; i < props.portlet.upcomingGames.length; i++) {
        const gameListing = props.portlet.upcomingGames[i];
        const homeTeam = gameListing.homeTeam;
        const visitorTeam = gameListing.visitorTeam;

        if (!homeTeam) {
          // ?
          // no homeTeam, generally meaning "TBD"
        }
        else {
          homeTeam.players.forEach(
            (player) => {
              const key = userChildrenKeyGen(player, homeTeam);
              if (children[key]) {
                children[key].games.push(props.portlet.upcomingGames[i])
              } else {
                children[key] = {
                  child: player,
                  team: {
                    team: homeTeam.team,
                    teamName: homeTeam.teamName,
                    teamID: homeTeam.teamID,
                    competitionID: gameListing.competitionID,
                    division: gameListing.division,
                  },
                  games: [gameListing],
                }
              }
            }
          )
        }

        if (!visitorTeam) {
          // ?
          // no visitorTeam, generally meaning "TBD"
        }
        else {
          visitorTeam.players.forEach((player) => {
            const key = userChildrenKeyGen(player, visitorTeam);
            if (children[key]) {
              children[key].games.push(props.portlet.upcomingGames[i])
            } else {
              children[key] = {
                child: player,
                team: {
                  team: visitorTeam.team,
                  teamName: visitorTeam.teamName,
                  teamID: visitorTeam.teamID,
                  competitionID: gameListing.competitionID,
                  division: gameListing.division,
                },
                games: [gameListing],
              }
            }
          })
        }
      }

      userChildren.value = children
    }

    const getCoachesForPortletTeam = (gameID: Guid, team: "" | UpcomingGamesPortlet_Team, which: "home" | "visitor") : string => {
      const teamCoaches = typeof team === "object" ? team?.coaches ?? [] : [];

      const areaTeamCoaches = getAreaCoachInfo(gameID, which);

      const coachNames = [...teamCoaches, ...areaTeamCoaches]
        .filter(coach => coach.title === "Head Coach" || coach.title === "Co-Coach" || coach.title === "Assistant")
        .sort(sortByMany(sortBy(_ => _.lastName), sortBy(_ => _.firstName)))
        .map(coach => `${coach.firstName} ${coach.lastName}`)

      return coachNames.length ? coachNames.join(', ') : 'TBD'
    }

    const viewScheduleLink = (childDetails: MungedUpcomingGamesPerChild) : string => {
      // Figure out season info - but, the games might span more than 1 season.
      // The common case is that all the games are for a single season, but it's not necessarily always true.
      const seasonStart = (() : Dayjs | undefined => {
        const seasonStarts : Dayjs[] = childDetails
          .games
          .map(v => dayjsOr(typeof v.competitionSeason === "object" ? v.competitionSeason.seasonStart : undefined))
          .filter(v => v !== undefined);

        return seasonStarts.length === 0
          ? undefined
          : dateMin(seasonStarts)
      })();

      const seasonWeeks = (() : number | undefined => {
        const seasonWeeks : number[] = childDetails
          .games
          .map(v => parseIntOr(typeof v.competitionSeason === "object" ? v.competitionSeason.seasonWeeks : null, null))
          .filter(v => v !== null);

        return seasonWeeks.length === 0
          ? undefined
          : max(seasonWeeks)
      })();

      return teamScheduleURL({
        teamID: childDetails.team.teamID,
        competitionID: childDetails.team.competitionID,
        division: childDetails.team.division,
        seasonStart,
        seasonWeeks
      });
    }

    const areaCoachInfo = ref<Map<GameID, AreaCoachGameData<AreaCoachDetail_camelCase>>>(new Map())
    const getAreaCoachInfo = (gameID: Guid, which: "home" | "visitor") => {
      switch (which) {
        case "home": return areaCoachInfo.value.get(gameID)?.homeAreaCoaches ?? []
        case "visitor": return areaCoachInfo.value.get(gameID)?.visitorAreaCoaches ?? []
        default: exhaustiveCaseGuard(which);
      }
    }

    onMounted(async () => {
      areaCoachInfo.value = await buildAreaCoachDetailMap(freshNoToastLoggedInAxiosInstance(), props.portlet.upcomingGames)
      sortGames()
      ready.value = true;
    })

    return () => {
      return <Portlet label="Upcoming Games" onExpand={toggleExpand} icon={props.portlet.fontAwesome || "calendar"} prefix="fas" data-test="UpcomingGames">
        {ready.value && ((isMobile.value && show.value) || !isMobile.value)
          ? <div>
              <div class="flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  {Object.keys(userChildren.value).length > 0
                    ? <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        {Object.values(userChildren.value).map(childDetails => {
                          return <div class="font-bold">
                            <a
                              class="py-1 px-6 w-full bg-gray-100 border-b border-gray-200 cursor-pointer text-lg flex flex-wrap items-center gap-1 border-black"
                              href={viewScheduleLink(childDetails)}
                              target="_blank"
                            >
                              <span>{childDetails.child.firstName} {childDetails.child.lastName}</span>
                              <span class="font-normal text-sm">{childDetails.team.team}</span>
                              {childDetails.team.teamName
                                ? <span>({childDetails.team.teamName})</span>
                                : null}
                              <span class="il-link flex items-center">
                                <span>Schedule</span>
                                <FontAwesomeIcon class="ml-1" icon={faAngleDoubleRight}/>
                              </span>
                            </a>
                            {childDetails.games.length > 0
                              ? <div class="overflow-hidden border-b border-gray-200 font-normal text-sm sm:rounded-lg">
                                  <table class="mt-2 min-w-full divide-y divide-gray-200">
                                    <thead class="bg-white italic">
                                      <td class="px-2">Date</td>
                                      <td class="px-2">Field</td>
                                      <td class="px-2">H/A</td>
                                      <td class="px-2 row-span-2">Teams</td>
                                    </thead>
                                    <tbody class="bg-white divide-y divide-gray-200">
                                      {childDetails.games.map(game => {
                                        return <tr data-test={`gameID=${game.gameID}`}>
                                          <td class="px-2 py-4 whitespace-nowrap">
                                            <div>{formatDateAsNums(game.gameStart)}</div>
                                            <div class="text-xs">{formatTime(game.gameStart)}-{formatTime(game.gameEnd)}</div>
                                          </td>
                                          <td class="px-2 py-4 whitespace-nowrap">
                                            <div>{game.fieldName}</div>
                                          </td>
                                          <td class="px-2 py-4 whitespace-nowrap">
                                            <div>{objOr(game.homeTeam)?.team === childDetails.team.team ? "(H)" : "(A)"}</div>
                                          </td>
                                          <td class="px-2 py-4 whitespace-nowrap">
                                            <div>
                                              <span>
                                                {objOr(game.homeTeam)?.team || "TBD"}
                                                <button
                                                  class="ml-1 cursor-pointer"
                                                  v-tooltip={{content: `Coaches: ${getCoachesForPortletTeam(game.gameID, game.homeTeam, "home")}`, delay: isMobile ? {show: 5, hide: 1500} : {show: 200, hide: 0}}}
                                                >
                                                  <FontAwesomeIcon class="mr-2 text-gray-400" icon={faInfoCircle}/>
                                                </button>
                                              </span>
                                              <span class="ml-1 mr-2">vs.</span>
                                              <div>{objOr(game.visitorTeam)?.team || "TBD"}
                                              <button
                                                class="cursor-pointer ml-1"
                                                v-tooltip={[
                                                  {content: `Coaches: ${getCoachesForPortletTeam(game.gameID, game.visitorTeam, "visitor")}`,  delay: isMobile ? {show: 5, hide: 1500} : {show: 200, hide: 0}},
                                                  undefined,
                                                  ["bottom"]
                                                ]}
                                              >
                                                <FontAwesomeIcon class="mr-2 text-gray-400" icon={faInfoCircle}/>
                                              </button>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              : <div class="italic text-center p-4">You have no upcoming games.</div>}
                          </div>
                        })}
                      </div>
                    : <div class="italic text-center p-4">You have no upcoming games.</div>}
                </div>
              </div>
            </div>
        : null}
      </Portlet>
    }
  }
})

async function buildAreaCoachDetailMap(axios: AxiosInstance, portletGamelikes: UpcomingGamesPortlet_UpcomingGame[]) : Promise<Map<GameID, AreaCoachGameData<AreaCoachDetail_camelCase>>> {
  const areaCoachInfos = (await getAreaCoaches(axios, portletGamelikes)) || [];
  return new Map(areaCoachInfos.map(v => [v.gameID, v]));
}

interface MungedUpcomingGamesPerChild {
  child: {
    lastName: string,
    firstName: string,
    seasonID: Integerlike
  },
  team: {
    team: string,
    teamName: string,
    teamID: string,
    competitionID: Integerlike,
    division: string
  },
  games: UpcomingGamesPortlet_UpcomingGame[]
}
