<template lang="pug">
div
  .fix-notch-top.flex.flex-wrap.items-center.content-center.bg-white.shadow-sm.border-b(
    class='md:left-64'
  )
    div(
      class="hidden md:block p-1"
      v-if="offerBigModeSidebarCollapseExpand"
    )
      FontAwesomeIcon(
        v-if="bigModeSideBarOpen"
        :icon="faChevronsLeft"
        class="rounded-md hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] cursor-pointer text-black p-2"
        @click="() => { collapseBigModeSideBar(); }"
      )
      FontAwesomeIcon(
        v-else
        :icon="faChevronsRight"
        class="rounded-md hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] cursor-pointer text-black p-2"
        @click="() => { expandBigModeSideBar(); }"
      )
    .flex-1.items-center.justify-center.flex.justify-between.items-end.h-16(
      class='md:justify-end'
    )
      div(class="flex items-center")
        button.border-r.border-gray-200.text-gray-500.pl-3.pr-3(
          @click='openSidebar',
          class='focus:outline-none focus:bg-gray-100 focus:text-gray-600 md:hidden md:px-4',
          aria-label='Open sidebar',
          data-cy='hamburger'
        )
          svg.h-6.w-6(stroke='currentColor', fill='none', viewbox='0 0 24 24')
            path(
              stroke-linecap='round',
              stroke-linejoin='round',
              stroke-width='2',
              d='M4 6h16M4 12h16M4 18h7'
            )
        router-link.ml-4.flex.text-xl(class='md:hidden', :to='{ name: "home" }')
          h1
            span.font-extrabold in
            | League
      div(v-if='!isLoggedIn')
        div(@click='login', :class='"mt-3 " + tBtnClasses') Login
      .ml-4.flex.items-center.justify-center.items-end.p-1(v-else, class='md:ml-6')
        //-
        //- nav icon group
        //-
        div(class="flex items-center gap-2")
          //-
          //- legacy link
          //-
          div(class="hidden md:block mr-2")
            TLegacyAppLink(url='Main/index')
              Btn2(class="p-2")
                | To&nbsp;inLeague&nbsp;Classic

          //-
          //- email manager link, if the user has such permission
          //- n.b. v-tooltip directly on font-awsome-icon will cause difficult-to-debug errors in vue internals when rendering
          //-
          TLegacyAppLink(v-if="userData.hasEmailManagerLink" url='emailManager/email.cfm')
            .text-xl.text-green-700.cursor-pointer(v-tooltip.bottom='{ content: `Email Manager` }')
              font-awesome-icon(:icon='["fas", "envelope"]')

          //-
          //- eTrainU link
          //-
          router-link.text-xl.ml-1.text-green-700(:to="{name: 'e-train-u'}")
            div(v-tooltip.bottom='{ content: `eTrainU` }')
              font-awesome-icon(:icon='["fas", "graduation-cap"]')

          //-
          //- payable invoices, visible in all layout sizes
          //-
          template(v-if="isLoggedIn")
            router-link(:to="{name:'payable-invoice-listing'}")
              .text-xl.text-green-700.cursor-pointer(v-tooltip.bottom='{content: payableInvoices.tooltip}' class="relative")
                font-awesome-icon(:icon='["fas", "usd"]')
                //- "beacon" is more like "dot that also flashes, and we show the dot if the user has invoices to pay, and it beacons if the user isn't on a 'checkout' route"
                Beacon(
                  v-if="payableInvoices.invoices.length > 0"
                  style="position: absolute; top:0; right:0; margin-top:-.125em; margin-right:-.25em;" size=".4em" :color="themeColor"
                  :disable="!payableInvoices.doBeacon"
                )

          //-
          //- "info/help" link
          //-
          .text-xl.text-green-700.cursor-pointer
            .span.cursor-pointer(
              v-tooltip='{ content: `Help` }'
              @click="navToFreshTab('https://gitlab.inleague.io/content/guides-and-documents/-/wikis/inLeague-Community-Guide')"
            )
              font-awesome-icon(:icon='["fas", "info-circle"]')
        RestoreUserButton.ml-1(v-if="userData.isImpersonating")
        .text-gray-400.rounded-full.items-end(
          class='hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring focus:text-gray-500',
          aria-label='Notifications',
          @click='showNotifications = !showNotifications',
          v-click-outside='closeNotifications',
          v-if='false'
        )
          h2.text-xl.mx-2(
            :class='Object.keys(unpaidInvoices).length>0 ? "text-red-600" : "text-green-700"'
          )
            font-awesome-icon(
              :icon='["fas", "bell"]',
              v-if='!(Object.keys(unpaidInvoices).length>0)'
            )
            font-awesome-icon(:icon='["fas", "bell-exclamation"]', v-else)
          transition(
            enter-active-class='transition ease-out duration-100',
            enter-class='transform opacity-0 scale-95',
            enter-to-class='transform opacity-100 scale-100',
            leave-active-class='transition ease-in duration-75',
            leave-class='transform opacity-100 scale-100',
            leave-to-class='transform opacity-0 scale-95'
          )
            .origin-top-right.absolute.right-0.mt-2.mr-2.w-48.rounded-md.shadow-lg.z-80.bg-gray-300(
              v-if='showNotifications'
            )
              .py-1.rounded-md.ring-1.ring-black.ring-opacity-5.max-h-64.overflow-y-auto(
                role='menu',
                aria-orientation='vertical',
                aria-labelledby='user-menu'
              )
                router-link.block.px-4.py-2.text-xs.text-gray-700.transition.ease-in-out.duration-150.bg-white.notifications(
                  v-if='Object.keys(unpaidInvoices).length',
                  v-for='invoice in unpaidInvoices',
                  :key='invoice.instanceID',
                  :to='{ name: "master-invoice", params: { invoiceID: invoice.instanceID } }',
                  class='hover:bg-gray-100',
                  role='notificationitem'
                )
                  .flex.justify-between.font-medium
                    div Unpaid Invoice:
                    .text-red-600 {{ invoice.lineItemSum ? `$${invoice.lineItemSum}` : "Void" }}
                  .italic {{ invoice.instanceLabel }}
                .block.px-4.py-2.text-xs.text-gray-700.transition.ease-in-out.duration-150(
                  v-if='!Object.keys(unpaidInvoices).length'
                )
                  | No notification at this time.

        //- Profile dropdown
        .ml-3.relative.py-1
          div(
            @click='showMenu = !showMenu',
            data-test='showProfileMenu',
            v-click-outside='closeMenuConfig'
          )
            button#user-menu.max-w-xs.flex.items-end.text-sm(
              class='rounded-fullfocus:outline-none focus:ring',
              aria-label='User menu',
              aria-haspopup='true'
            )
              | {{ getName }}
              svg.ml-1.h-5.w-6(fill='currentColor', viewbox='0 0 20 20')
                path(
                  fill-rule='evenodd',
                  d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z',
                  clip-rule='evenodd'
                )
          transition(
            enter-active-class='transition ease-out duration-100',
            enter-class='transform opacity-0 scale-95',
            enter-to-class='transform opacity-100 scale-100',
            leave-active-class='transition ease-in duration-75',
            leave-class='transform opacity-100 scale-100',
            leave-to-class='transform opacity-0 scale-95'
          )
            //- HEADER
            .origin-top-right.absolute.right-0.mt-2.w-48.rounded-md.shadow-lg.z-40(
              v-if='showMenu',
              @click='showMenu = !showMenu'
            )
              .py-1.rounded-md.bg-white.ring-1.ring-black.ring-opacity-5(
                role='menu',
                aria-orientation='vertical',
                aria-labelledby='user-menu'
              )

                router-link.click-ignore.block.px-4.py-2.text-sm.text-gray-700.transition.ease-in-out.duration-150(
                  :to="{name: 'user-editor', params: {userID: userData.userID}}"
                  class='hover:bg-gray-100',
                  role='menuitem',
                  v-if='!isMobile'
                ) Your Profile
                TLegacyAppLink(
                  url='Main/index'
                  role='menuitem',
                )
                  .cursor-pointer.click-ignore.block.px-4.py-2.text-sm.text-gray-700.transition.ease-in-out.duration-150.pointer-cursor(class='hover:bg-gray-100',) To inLeague Classic
                a.click-ignore.block.px-4.py-2.text-sm.text-gray-700.transition.ease-in-out.duration-150.cursor-pointer(
                  @click='logOut()',
                  class='hover:bg-gray-100',
                  role='menuitem',
                  data-test='logOut'
                ) Sign out
</template>

<script lang="ts">
import { defineComponent, computed, watch, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import RestoreUserButton from 'src/components/Admin/ImpersonateUsers/RestoreUserButton.vue'
import { UserData } from 'src/interfaces/Store/user'
import { Invoice } from 'src/interfaces/Store/checkout'

import Beacon from "./Beacon.vue"
import { PayableInvoicesResolver } from "src/store/EventuallyPinia"
import { freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'

import { System } from 'src/store/System'
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'
import { Btn2 } from '../UserInterface/Btn2'
import { CheckoutStore } from "src/store/CheckoutStore"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronsLeft, faChevronsRight } from '@fortawesome/pro-solid-svg-icons'
import { RouteWithInleagueMetadata } from 'src/router/routes'
import { bigModeSideBarOpen } from 'src/store/Sidebar'

export default defineComponent({
  name: 'Header',
  components: {
    RestoreUserButton,
    Beacon,
    Btn2,
    FontAwesomeIcon
  },
  setup() {

    const showMenu = ref(false)
    const showNotifications = ref(false)
    const route = useRoute() as RouteWithInleagueMetadata
    const router = useRouter()

    const offerBigModeSidebarCollapseExpand = computed(() => {
      return !!route.meta.allowBigModeSidebarCollapse
    })

    const themeColor = computed(() => {
      return Client.value.clientTheme.color
    })

    // Computed
    const getName = computed(() => {
      return User.value.fullName
    })

    const userData = computed(() => {
      return User.value.userData
    })

    const isMobile = computed(() => {
      return System.value.isMobile
    })

    const isLoggedIn = computed(() => {
      return User.isLoggedIn
    })

    const tBtnClasses = computed(() => {
      return System.value.tBtnClasses
    })

    const unpaidInvoices = computed(() => {
      return CheckoutStore.value.unpaidInvoices
    })

    // Methods
    const closeNotifications = () => {
      showNotifications.value = false
    }
    const closeMenu = () => {
      showMenu.value = false
    }
    const openSidebar = () => {
      System.directCommit_setOpenSidebar(true)
    }
    const logOut = async () => {
      await User.logoutUser({
        route,
        router,
      })
    }

    const login = async () => {
      await router.push({ name: 'login' })
    }

    const outsideClickMiddleware = (e: any) => {
      return e?.target?.className?.search?.('click-ignore') === -1 ? true : false
    }
    const closeMenuConfig = {
      middleware: outsideClickMiddleware,
      handler: closeMenu,
      events: ['dblclick', 'click', 'touchstart'],
    }

    type PayableInvoicesConfig = {invoices: Invoice[], doBeacon: boolean, tooltip: string}
    const payableInvoices = computed<PayableInvoicesConfig>(() => {
      if (User.isLoggedIn) {
        const v = PayableInvoicesResolver.getOnce(freshNoToastLoggedInAxiosInstance());
        if (v.status === "resolved" && v.data.length > 0) {
          const currentRouteName = router.currentRoute.value.name;
          return {
            invoices: v.data,
            // no beacon on the pages where the context is "the user is checking out"
            doBeacon: currentRouteName !== `payable-invoice-listing`
              && currentRouteName !== `checkout`,
            tooltip: v.data.length === 0 ? "Invoices" : `Invoices (${v.data.length})`
          }
        }
      }

      return {
        invoices: [],
        doBeacon: false,
        tooltip: ""
      };
    })

    return {
      closeMenuConfig,
      isMobile,
      showMenu,
      showNotifications,
      closeNotifications,
      closeMenu,
      openSidebar,
      logOut,
      getName,
      isLoggedIn,
      unpaidInvoices,
      tBtnClasses,
      login,
      userData,
      navToFreshTab: (url: string) => window.open(url),
      payableInvoices,
      themeColor,
      System,
      faChevronsLeft,
      faChevronsRight,
      offerBigModeSidebarCollapseExpand,
      bigModeSideBarOpen,
      collapseBigModeSideBar: () => bigModeSideBarOpen.value = false,
      expandBigModeSideBar: () => bigModeSideBarOpen.value = true
    }
  },
})
</script>

<style>
.noMargin {
  margin: 0 !important;
}
</style>
