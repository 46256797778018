<template lang="pug">
div
  div(class="il-EventListing-quasar-table-kludge")
    q-table.q-pa-md.quasar-style-wrap(
      :rows='augmentedEvents',
      :columns='columnDefs',
      row-key='eventId',
      :rows-per-page-options='[0]',
      :pagination="{rowsPerPage: 50}"
      :visible-columns="visibleColumns"
      :binary-state-sort="true"
      ref="table"
    )
      template(v-slot:top)
        div.flex.w-full
          div.ml-auto
            t-btn
              div.text-xs(@click="downloadAsExcel") Download as Excel
      //- We want to say `th(someStylesOrClasses...)` to align text and set column widths but if we do that,
      //- we take on the responsibility of drawing our own sort arrows,
      //- which is maybe not too difficult
      //- template(v-slot:header="props")
      //-   q-th
      //-     div.styling1.styling2.etc {{ <column name> }}
      //-     SortArrow(dir="props.getTheCurrentSortDirSomehow")
      template(v-slot:body="props")
        q-tr(:data-test="`eventID=${props.row.eventID}`" :class="{'bg-slate-100': props.rowIndex % 2, 'white': !(props.rowIndex % 2)}")
          q-td(style="font-size:1rem;")
            div.flex.flex-col.items-start
              div
                router-link(:to="{name: 'event-signup', params: {eventID: props.row.eventID}}")
                  div.text-blue-700.underline.cursor-pointer
                    | {{ props.row.eventName }}
              div(v-if="isRegistrar || currentUserIsEventContactForEvent(props.row) || currentUserIsCoachForEvent[props.row.eventID]")
                div.inline.cursor-pointer.p-1.rounded(
                  class="hover:bg-black/[.1] active:bg-black/[.15]"
                  v-tooltip.top='{ content: `Edit this event` }'
                )
                  router-link(
                    :to='eventEditorRoutes.edit(props.row.eventID)'
                    data-test="to-event-editor"
                  )
                    font-awesome-icon(:icon='["fas", "pencil"]')
                div.inline.cursor-pointer.p-1.rounded(
                  class="hover:bg-black/[.1] active:bg-black/[.15]"
                  v-tooltip.top='{ content: `Event roster` }'
                )
                  router-link(
                    :to='{ name: "event-roster", params: {eventID: props.row.eventID} }'
                    data-test="to-event-roster"
                  )
                    font-awesome-icon(:icon='["fas", "magnifying-glass"]')
                div.inline.cursor-pointer.p-1.rounded(
                  v-if="isRegistrar"
                  class="hover:bg-black/[.1] active:bg-black/[.15]"
                  v-tooltip.top='{ content: `Clone` }'
                )
                  router-link(
                    :to='eventEditorRoutes.clone(props.row.eventID)'
                  )
                    font-awesome-icon(:icon='["fas", "clone"]')
                div.inline.cursor-pointer.p-1.rounded(
                  v-if="doShowCouponLink(props.row)"
                  class="hover:bg-black/[.1] active:bg-black/[.15]"
                  v-tooltip.top='{ content: `Coupons` }'
                )
                  router-link(
                    :to='couponManagerRouteForEvent(props.row)'
                  )
                    font-awesome-icon(:icon='["fas", "tags"]')
                div.inline.cursor-pointer.p-1.rounded(
                  class="hover:bg-black/[.1] active:bg-black/[.15]"
                  v-tooltip.top='{ content: `Sign-in sheet` }'
                )
                  router-link(
                    :to='{ name: "event-signin-sheet", params: {eventID: props.row.eventID} }'
                    data-test="to-event-signin-sheet"
                  )
                    font-awesome-icon(:icon='["fas", "clipboard-list"]')

          q-td(style="font-size:1rem;")
            div.flex.flex-col.justify-start.items-start
              | {{ props.row.meta.uiDateRange }}
          q-td(style="font-size:1rem;")
            div.flex.items-start.whitespace-nowrap
              | {{ props.row.meta.uiTimeRange }}
          q-td(style="font-size:1rem;")
            div.flex.items-start
              | {{ props.row.address }}
          q-td(style="font-size:1rem;")
            div.flex.items-start
              | {{ props.row.contactName }}
          q-td(style="font-size:1rem;")
            div.flex.items-start
              | {{ props.row.eventCategory }}
          q-td(style="font-size:1rem;" data-test="signupStatus")
            div.flex.items-start
              | {{ props.row.meta.signupStatusUiString }}
          q-td(style="font-size:1rem;")
            div.flex.items-start
              | {{ props.row.signedUpCount }}
          q-td(v-if="isRegistrar" style="font-size:1rem;")
            //- table header row should have also been filtered on isRegistrar via `visibleColumns`
            div.flex.items-start
              t-btn(type="button" color="green" @click="doDelete(props.row.eventID)")
                div Delete
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import { downloadFromObjectURL, exhaustiveCaseGuard } from "src/helpers/utils";
import { emitsDef, isWaitlistOnly, propsDef } from "./EventListing.ilx";
import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

import { SignupAllowanceType } from "src/composables/InleagueApiV1.Event";
import dayjs, { Dayjs } from "dayjs";
import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";

import { UserData } from "src/interfaces/Store/user";
import authService from "src/helpers/authService";
import * as XlsxUtils from "src/modules/XlsxUtils"

import * as R_EventEditor from "src/components/Events/R_EventEditor.ilx"
import { dayjsFormatOr } from "src/helpers/formatDate";
import * as R_CouponManager from "../Coupons/R_CouponManager.route";
import { User } from "src/store/User";
import { QuasarColDef } from "src/helpers/Quasar";

interface AugmentedEvent extends ilapi.event.Event {
  meta: {
    selected: boolean,
    signupStatusUiString: string
    uiDateRange: string,
    uiTimeRange: string,
    sortDate: Dayjs,
    sortTime: Dayjs
  }
}

interface ColDef extends QuasarColDef<AugmentedEvent, AugmentedEvent> {
  [key: string]: any,
  //
  // `field` is identity (i.e. v=>v).
  // To get "the displayable value", pull the target value from T in the format method.
  // This allows to use a strongly typed sort method.
  // Maybe we should instead just return a string here, and we can reparse times or etc. inside `sort`.
  // We'd like to say {field: (v: T) => unknown, sort: <U extends ReturnType<typeof this.field>>(l:U,r:U) => -1|0|1}
  //
  // How do we return HTML elements?
  // How do we specify column widths?
  //
  field: (v: AugmentedEvent) => AugmentedEvent
  format: (v: AugmentedEvent) => string,
  sort?: (l: AugmentedEvent, r: AugmentedEvent, v: any, xr: any) => -1 | 0 | 1
  label: string,
  name: ColName,
}

function dateCompare(l: string | dayjs.Dayjs, r: string | dayjs.Dayjs, unit?: dayjs.UnitType) {
  const xl = typeof l === "string" ? dayjs(l) : l;
  const xr = typeof r === "string" ? dayjs(r) : r;

  if (!xl.isValid() || !xr.isValid()) {
    // arbitrary choice in failure condition
    return -1;
  }
  else {
    return xl.isBefore(xr, unit) ? -1 : xl.isSame(xr, unit) ? 0 : 1;
  }
}

function timeCompare(l: string | dayjs.Dayjs, r: string | dayjs.Dayjs, unit?: dayjs.UnitType) {
  const xl = typeof l === "string" ? dayjs(l) : l;
  const xr = typeof r === "string" ? dayjs(r) : r;

  return dateCompare(
    // normalize to same month/day/year so we're only comparing time
    xl.month(0).date(1).year(1970),
    xr.month(0).date(1).year(1970),
    unit
  );
}

function compare(l: any, r: any) {
  return l < r ? -1 : l == r ? 0 : 1
}

const ColNames = {
  selected: "selected",
  date: "date",
  time: "time",
  name: "name",
  eventLocation: "eventLocation",
  category: "category",
  contact: "contact",
  status: "status",
  signedUpCount: "signedUpCount",
  delete: "delete",
} as const;

type ColName = keyof typeof ColNames;

const columnDefs : ColDef[] = [
  {
    name: ColNames.name,
    label: 'Event name',
    align: 'left',
    field: v => v,
    format: v => v.eventName,
    sortable: true,
    sort: (l,r) => compare(l.eventName, r.eventName),
  },
  {
    name: ColNames.date,
    label: 'Date',
    align: 'left',
    sortable: true,
    field: v => v,
    format: v => v.meta.uiDateRange,
    sort: (l,r) => dateCompare(l.meta.sortDate, r.meta.sortDate),
  },
  {
    name: ColNames.time,
    label: 'Time',
    align: 'left',
    field: v => v,
    format: v => v.meta.uiDateRange,
    sortable: true,
    sort: (l,r) => timeCompare(l.meta.sortTime, r.meta.sortTime),
  },
  {
    name: ColNames.eventLocation,
    label: 'Event location',
    align: 'left',
    field: v => v,
    format: event => event.address,
    sortable: true,
    sort: (l,r) => compare(l.address, r.address)
  },
  {
    name: ColNames.contact,
    label: 'Contact',
    align: 'left',
    field: v => v,
    format: event => {
      return event.contactName;
    },
    sortable: true,
    sort: (l,r) => compare(l.contactName, r.contactName)
  },
  {
    name: ColNames.status,
    label: 'Status',
    align: 'left',
    field: v => v,
    format: event => event.meta.signupStatusUiString,
    sortable: true,
    sort: (l,r) => compare(l.meta.signupStatusUiString, r.meta.signupStatusUiString)
  },
  {
    name: ColNames.category,
    label: 'Category',
    align: 'left',
    field: v => v,
    format: event => event.eventCategory,
    sortable: true,
    sort: (l,r) => compare(l.eventCategory, r.eventCategory)
  },
  {
    name: ColNames.signedUpCount,
    label: 'Signed up',
    align: 'left',
    field: v => v,
    format: event => event.signedUpCount.toString(),
    sortable: true,
    sort: (l,r) => compare(l.signedUpCount, r.signedUpCount),
  },
  {
    name: ColNames.delete,
    label: 'Delete',
    align: 'center',
    field: v => v,
    format: event => "",
    sortable: false,
    xlsx: {
      visible: false,
    }
  },
];

export default defineComponent({
  components: {
  },
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    const table = ref<{filteredSortedRows: AugmentedEvent[]} | null>(null)
    const augmentedEvents = computed(() => {
      return props.events.map(eventAsAugmentedEvent);
    });


    const isRegistrar = authService((User.value.userData as UserData).roles, "registrar", "eventAdmin");

    /**
     * this doesn't cascade into row definitions, it only seems to affect the header row
     * So in the table definition we also need to guard particular columns by similar logic
     *
     *  todo: find a way to not need to repeat "is column visible" logic in script and template
     */
    const visibleColumns = (() => {
      const result : ColName[] = [];
      for (const colName of Object.keys(ColNames) as (ColName[])) {
        switch (colName) {
          case "selected":
            // fallthrough
          case "delete":
            // fallthrough
          case "signedUpCount":
            if (!isRegistrar) {
              continue;
            }
            result.push(colName);
            break;
          default:
            result.push(colName);
        }
      }
      return result;
    })();

    const isVisible = (colName: ColName) => {
      return visibleColumns.includes(colName);
    }

    const doDelete = async (eventIdToDelete: iltypes.Guid) => {
      emit("doDelete", eventIdToDelete);
    }

    const handleSignUp = (v: AugmentedEvent) => {
      emit("requestSignup", v.eventID);
    }

    const downloadAsExcel = async () => {
      if (table.value === null) {
        // table hasn't mounted yet, we can't do anything
        return;
      }

      const isVisibleLookup = new Map(columnDefs.map(colDef => [colDef.name, isVisible(colDef.name)]));

      const header = (() => {
        const result : string[] = [];
        for (const colDef of columnDefs) {
          if (isVisibleLookup.get(colDef.name)) {
            // we should make the property `xlsx` mandatory?
            // Until then, if the `xlsx` property is not present, or it is and it is explicitly marked visible,
            // include it.
            if (!colDef.xlsx || colDef.xlsx.visible) {
              result.push(colDef.label);
            }
          }
        }
        return result;
      })();

      const builder = XlsxUtils.builderWithKludgyAutoWidths(header);

      for (const domRow of table.value.filteredSortedRows) {
        const xlsxRow : string[] = [];
        for (const colDef of columnDefs) {
          if (isVisibleLookup.get(colDef.name)) {
            xlsxRow.push(colDef.format(domRow));
          }
        }
        builder.pushRow(xlsxRow);
      }

      downloadFromObjectURL(await builder.build(), "Events Calendar.xlsx");
    };

    const currentUserIsEventContactForEvent = (event: ilapi.event.Event) : boolean => {
      const canonicalize = (s:string) => s.toLowerCase().trim();
      const canonicalContactEmail = canonicalize(event.contactEmail);
      if (!canonicalContactEmail) {
        return false;
      }
      else {
        return canonicalize(User.value.userEmail) === canonicalContactEmail;
      }
    }

    const doShowCouponLink = (event: ilapi.event.Event) : boolean => {
      return event.couponLinkCount > 0 && (isRegistrar || currentUserIsEventContactForEvent(event));
    }

    const couponManagerRouteForEvent = (event: ilapi.event.Event) => {
      return R_CouponManager.routeDetailToRoutePath({name: "coupon-manager.main", eventID: event.eventID});
    }

    return {
      table,
      augmentedEvents,
      doDelete,
      columnDefs,
      visibleColumns,
      isRegistrar,
      handleSignUp,
      downloadAsExcel,
      eventEditorRoutes: {
        edit: (eventID: iltypes.Guid) => R_EventEditor.routeDetailToRoutePath({name: R_EventEditor.RouteName.edit, eventID}),
        clone: (eventID: iltypes.Guid) => R_EventEditor.routeDetailToRoutePath({name: R_EventEditor.RouteName.clone, sourceEventID: eventID})
      },
      currentUserIsEventContactForEvent,
      doShowCouponLink,
      couponManagerRouteForEvent,
    }
  }
})

function eventAsAugmentedEvent(event: ilapi.event.Event) : AugmentedEvent {
  const eventStartDate = dayjsFormatOr(event.eventStart, "MM/DD/YYYY");
  const eventEndDate = dayjsFormatOr(event.eventEnd, "MM/DD/YYYY");

  const eventStartTime = dayjsFormatOr(event.eventStart, "h:mm a");
  const eventEndTime = dayjsFormatOr(event.eventEnd, "h:mm a");

  return {
    ...event,
    meta: {
      selected: false,
      signupStatusUiString: getSignupStatusUiString(event),
      uiDateRange: `${eventStartDate} - ${eventEndDate}`,
      uiTimeRange: event.allDay ? "all day event" : `${eventStartTime} - ${eventEndTime}`,
      sortDate: dayjs(event.eventEnd),
      sortTime: dayjs(event.eventEnd)
    }
  }
}

function getSignupStatusUiString(event: ilapi.event.Event) : string {
  if (isWaitlistOnly(event)) {
    return "At capacity";
  }
  switch (event.allowSignups) {
    case SignupAllowanceType.closed:
      return "";
    case SignupAllowanceType.playersOnly:
    case SignupAllowanceType.usersAndPlayers:
    case SignupAllowanceType.usersOnly:
      return "Open";
    default:
      exhaustiveCaseGuard(event.allowSignups);
  }
}
</script>

<!-- scoped css doesn't work on dynamically generated elements -->
<style>
.il-EventListing-quasar-table-kludge th {
  /*
    this is primarily to keep the following as a single line
    it will probably break if we have long column heading names
    or on small screens. Not sure what to do about that.
    <th>
      <span>Label...</span>
      <span>...sort arrow...</span> <!-- don't break this onto a new line! -->
    </th>
  */
  white-space: nowrap;
  font-size: 1rem !important;
}
</style>
