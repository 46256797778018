import { parseIntOrFail } from "./utils";

export function registrationGradeOrdering(grade: string) : number {
  if (/pre-school/i.test(grade)) {
    return -3
  }
  else if (/^pre-k$/i.test(grade)) {
    return -2;
  }
  else if (/^k$/i.test(grade)) {
    return -1;
  }
  else if (/^\d+$/.test(grade)) {
    return parseIntOrFail(grade)
  }
  else if (/college/i.test(grade)) {
    return 99;
  }
  else {
    return 999; // shouldn't happen
  }
}
