import { exhaustiveCaseGuard, ExtractEmitsHandlers, parseFloatOr, parseIntOr } from "src/helpers/utils";
import { reactive, computed, ExtractPropTypes, PropType, ref } from "vue"
import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"
import { SignupAllowanceType } from "src/composables/InleagueApiV1.Event";
import dayjs from "dayjs";
import { Dayjs } from "dayjs"
import { setTimeParts } from "src/helpers/formatDate"

import { EventFormUserType } from "src/composables/InleagueApiV1.Event";
import { Floatlike, Numbool } from "src/interfaces/InleagueApiV1";
export { type EventFormUserType } from "src/composables/InleagueApiV1.Event"; // todo: importers of this can import direct from InleagueApi

export const propsDef = {
  detail: {
    required: true,
    type: Object as PropType<FormDetail>
  }
} as const;

export const emitsDef = {
  /**
   * we emit what we were passed in; note that it's not a copy of the event form data,
   * but a reference to it, so the parent already has it. It can help to include this info
   * in the event to do some sanity checks at the receiving side (did we get out of sync somehow?
   * is the form object still the same?)
   */
  doSubmit : (_detail: FormDetail) => true
} as const;

export type Props = ExtractPropTypes<typeof propsDef>;
export type Emits = ExtractEmitsHandlers<typeof emitsDef>;

export const Mode_t = {
  NEW: "make-new",
  EDIT: "edit-existing",
  CLONE: "clone-existing"
} as const;

export type Mode_t = (typeof Mode_t)[keyof typeof Mode_t];

export type FormDetail =
  | FormDetail_New
  | FormDetail_Edit
  | FormDetail_Clone

interface FormDetailBase {
  readonly type: Mode_t
  readonly eventFormUserType: EventFormUserType,
}

export interface FormDetail_New extends FormDetailBase {
  readonly type: "make-new",
  data: EventFormData
}
export interface FormDetail_Edit extends FormDetailBase {
  readonly type: "edit-existing",
  readonly eventID: iltypes.Guid,
  data: EventFormData
}
export interface FormDetail_Clone extends FormDetailBase {
  readonly type: "clone-existing",
  readonly sourceEventID: iltypes.Guid,
  data: EventFormData
}

export function freshFormData(forUserType: EventFormUserType) : EventFormData {
  if (forUserType === "EventContact") {
    throw "An EventFormUserType of type EventContact should never be generating a new event."
  }

  return {
    achEnabled: 0,
    adultLimitSeasons: [],
    allDay: false,
    allowSignups: SignupAllowanceType.closed,
    comments: "",
    // competitionUID should be initialized to the "core program" competitionUID on form init
    // see details in the form component's onMounted
    competitionUID: "",
    contactEmail: "",
    contactName: "",
    contactPhone: "",
    divisions: [],
    emailText: "",
    eventCategory: "General",
    eventEnd: makeDateTimeInputProxy(null, dayjs("10:00", "HH:mm")),
    eventLocation: {type: "venueID", venueID: "", eventLoc: ""},
    eventName: "",
    eventStart: makeDateTimeInputProxy(null, dayjs("09:00", "HH:mm")),
    feePlayer: "",
    feeUser: "",
    gatewayID: "",
    gCalID: "",
    isTeamEvent: (() => {
      switch (forUserType) {
        case "Registrar": return false;
        case "Coach": return true;
        default: exhaustiveCaseGuard(forUserType);
      }
    })(),
    maxCount: "",
    notificationEmails: "",
    playerLimitSeasons: [],
    questions: [],
    regionSponsor: "",
    requireAchPaymentWhenFeeMeetsOrExceeds: "",
    seasonUID: "",
    teamID: "",
  }
}

export function formDataFromExistingEvent(event: ilapi.event.Event) : EventFormData {
  return {
    achEnabled: event.achEnabled,
    adultLimitSeasons: event.adultLimitSeasons,
    allDay: event.allDay,
    allowSignups: event.allowSignups,
    competitionUID: event.competitionUID,
    comments: event.comments,
    contactEmail: event.contactEmail,
    contactName: event.contactName,
    contactPhone: event.contactPhone,
    divisions: event.divisions,
    emailText: event.emailText,
    eventCategory: event.eventCategory,
    eventEnd: makeDateTimeInputProxy(dayjs(event.eventEnd), dayjs(event.eventEnd)),
    eventLocation: event.venueID
      ? {type: "venueID", venueID: event.venueID, eventLoc: ""}
      : {type: "freeform", venueID: "OTHER", eventLoc: event.eventLoc},
    eventName: event.eventName,
    eventStart: makeDateTimeInputProxy(dayjs(event.eventStart), dayjs(event.eventStart)),
    feePlayer: parseFloatOr(event.feePlayer, ""),
    feeUser: parseFloatOr(event.feeUser, ""),
    gatewayID: event.gatewayID ?? "",
    gCalID: event.gCalID ?? "",
    isTeamEvent: !!event.teamID,
    maxCount: parseIntOr(event.maxCount, ""),
    notificationEmails: event.notificationEmails,
    playerLimitSeasons: event.playerLimitSeasons,
    questions: event.questions.map(question => question.questionID),
    regionSponsor: event.regionSponsor,
    requireAchPaymentWhenFeeMeetsOrExceeds: event.requireAchPaymentWhenFeeMeetsOrExceeds,
    seasonUID: event.seasonUID,
    teamID: event.teamID ?? "",
  }
}

export interface EventFormData {
  achEnabled: Numbool,
  adultLimitSeasons: iltypes.Integerlike[],
  allDay: boolean,
  allowSignups: iltypes.Integerlike<ilapi.event.SignupAllowanceType["int"]>,
  comments: string,
  competitionUID: iltypes.Guid,
  contactEmail: string,
  contactName: string,
  contactPhone: string,
  divisions: iltypes.DivisionID[],
  emailText: string,
  eventCategory: ilapi.event.EventCategoryType,
  eventEnd: FormDateTimeInputProxy,
  eventLocation:
    | {type: "freeform", venueID: "OTHER", eventLoc: string}
    | {type: "venueID", venueID: iltypes.Guid | "", eventLoc: ""},
  eventName: string,
  eventStart: FormDateTimeInputProxy,
  feePlayer: "" | iltypes.Numeric,
  feeUser: "" | iltypes.Numeric,
  // primarily UI related, couples with "teamID", which won't be an available option if "isTeamEvent" is false
  isTeamEvent: boolean,
  gatewayID: "" | iltypes.Guid,
  /**
   * google calendarID, or empty string meaning "none"
   */
  gCalID: string,
  maxCount: "" | iltypes.Integerlike,
  notificationEmails: string,
  playerLimitSeasons: iltypes.Integerlike[],
  questions: iltypes.Guid[],
  regionSponsor: string,
  requireAchPaymentWhenFeeMeetsOrExceeds: "" | Floatlike,
  seasonUID: iltypes.Guid,
  teamID: iltypes.Guid | "",
}

/**
 * wrapper around getters/setters for some date/time input field
 */
export function makeDateTimeInputProxy(initialDate : null | Dayjs, initialTime: null | Dayjs) {
  interface InternalDateTime { date: string, time: string };

  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm";

  const data = ref<InternalDateTime>({date: "", time: ""});

  const ret = reactive({
    clearAll: () => {data.value = {date:"", time: ""}},
    clearDate: () => { data.value.date = ""; },
    clearTime: () => { data.value.time = ""; },
    getDayjsDate: () => {
      const v = dayjs(data.value.date);
      return v.isValid() ? v : null;
    },
    getDayjsTime: () => {
      const v = dayjs(data.value.time, timeFormat);
      return v.isValid() ? v : null;
    },
    getDayjsDatetime: () => {
      const date = dayjs(data.value.date)
      const time = dayjs(data.value.time, timeFormat)
      if (!date.isValid() || !time.isValid()) {
        return null;
      }
      return setTimeParts(date, time);
    },
    date: computed({get() { return data.value.date }, set(v: string) { data.value.date = v; }}),
    time: computed({get() { return data.value.time }, set(v: string) { data.value.time = v; }})
  })

  ret.date = initialDate?.format(dateFormat) ?? "";
  ret.time = initialTime?.format(timeFormat) ?? "";

  return ret;
}

type FormDateTimeInputProxy = ReturnType<typeof makeDateTimeInputProxy>;

/**
 * Wrangle form data into api shape. No validation is performed, all transformations assume the
 * supplied data form is 100% valid
 *
 * (though we attempt to gracefully fail on the frontend by coercing missing things to
 * empty string or etc., such that the client does not crash but the backend will return 4xx)
 */
export function eventFormToApiShape(form: EventFormData, userType: EventFormUserType) : ilapi.event.SubmittableEvent {
  const DATE_ONLY = "MMM-DD-YYYY";
  const DATE_AND_IMPLIED_LOCAL_TIME = "MMM-DD-YYYY HH:mm";

  const eventStart = form.allDay
    ? (form.eventStart.getDayjsDate()?.format(DATE_ONLY) ?? "")
    : (form.eventStart.getDayjsDatetime()?.format(DATE_AND_IMPLIED_LOCAL_TIME) ?? "");

  // in the "all day" case, eventEnd can be blank, in which case it is implied to be the same as eventStart
  const eventEnd = form.allDay
  ? (form.eventEnd.getDayjsDate()?.format(DATE_ONLY) ?? form.eventStart.getDayjsDate()?.format(DATE_ONLY) ?? "")
  : (() => {
    const endDateTime = form.eventEnd.getDayjsDatetime();
    const endTime = form.eventEnd.getDayjsTime();
    const startDateTime = form.eventStart.getDayjsDatetime();
    if (endDateTime) {
      // Form hand end date+time
      return endDateTime.format(DATE_AND_IMPLIED_LOCAL_TIME);
    }
    else if (endTime && startDateTime) {
      // form has (start date + time) but only end time (implying, same date as start date)
      return setTimeParts(startDateTime, endTime).format(DATE_AND_IMPLIED_LOCAL_TIME);
    }
    else if (startDateTime) {
      // form has no end date or time (this is probably a bug? if we're not "all day" we should require an end time?)
      return startDateTime.format(DATE_AND_IMPLIED_LOCAL_TIME);
    }
    else {
      // there is no valid data, nothing we can do here (bug in the form)
      return "";
    }
  })();

  // eventLoc/venueID are mutually exclusive
  const eventLoc = form.eventLocation.type === "freeform" ? form.eventLocation.eventLoc : undefined;
  const venueID = form.eventLocation.type === "venueID" ? form.eventLocation.venueID : undefined;

  //
  // If a user is "coach", they cannot set or make changes to fees.
  // The backend will reject requests by non-registrar users to set or change fees.
  //
  // If it doesn't make sense for a signup allowance type to have some type of fee, then we discard that fee.
  //
  const feePlayer = (() => {
    switch (userType) {
      case "Coach":
        return undefined;
      case "EventContact":
        return undefined;
      case "Registrar":
        switch (form.allowSignups.toString()) {
          case SignupAllowanceType.playersOnly.toString():
          case SignupAllowanceType.usersAndPlayers.toString():
            return form.feePlayer;
          default:
            return "";
        }
      default: exhaustiveCaseGuard(userType);
    }
  })();

  const feeUser = (() => {
    switch (userType) {
      case "Coach":
        return undefined;
      case "EventContact":
        return undefined;
      case "Registrar":
        switch (form.allowSignups.toString()) {
          case SignupAllowanceType.usersOnly.toString():
          case SignupAllowanceType.usersAndPlayers.toString():
            return form.feeUser;
          default:
            return "";
        }
      default: exhaustiveCaseGuard(userType);
    }
  })();

  return {
    achEnabled: form.achEnabled,
    adultLimitSeasons: form.adultLimitSeasons,
    allDay: form.allDay,
    allowSignups: form.allowSignups,
    comments: form.comments,
    competitionUID: form.competitionUID,
    contactEmail: form.contactEmail,
    contactName: form.contactName,
    contactPhone: form.contactPhone,
    divisions: form.divisions,
    emailText: form.emailText,
    eventCategory: form.eventCategory,
    eventEnd: eventEnd,
    eventLoc: eventLoc,
    eventName: form.eventName,
    eventStart: eventStart,
    feePlayer: feePlayer,
    feeUser: feeUser,
    gatewayID: form.gatewayID,
    gCalID: form.gCalID,
    maxCount: form.maxCount,
    notificationEmails: form.notificationEmails,
    playerLimitSeasons: form.playerLimitSeasons,
    questions: form.questions,
    regionSponsor: form.regionSponsor,
    requireAchPaymentWhenFeeMeetsOrExceeds: form.requireAchPaymentWhenFeeMeetsOrExceeds,
    seasonUID: form.seasonUID,
    teamID: form.isTeamEvent ? form.teamID : "",
    venueID: venueID,
  }
}
