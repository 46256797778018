import { FormKit } from "@formkit/vue"
import { Schema, SerializedQueryRequest, WhereClause, FunctionlikeWhereArgs, Selectable, Selectable_ArgDef, Filterable, SerializedSelection, ColumnToLabelMapping, QueryResult } from "src/composables/InleagueApiV1.ReportBuilder"
import { FormKitValidationRule, Reflike, UiOption, clamp, exhaustiveCaseGuard, fkKey_reactToChangeValueLikeNormal, sortBy, unsafe_objectKeys, vReqT } from "src/helpers/utils"
import { computed, defineComponent, ref, Transition, watch } from "vue"
import { QueryAsDomTree, QueryNodeElement } from "./ReportBuilder.node"
import { UiQNode, Connective, Predicate, NodeType } from "./UiQNode"
import { QueryResultTable } from "./ReportBuilder.queryResultTable"
import { TabDef, Tabs } from "src/components/UserInterface/Tabs"
import { FilterableFormData } from "./QueryData"
import { faCopy } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

export const ReportBuilderImpl = defineComponent({
  name: "ReportBuilderImpl",
  props: {
    schema: vReqT<Schema>(),
    mut_selectedQueryRoot: vReqT<Reflike<string>>(),
    queryRootOptions: vReqT<UiOption[]>(),
    query: vReqT<SerializedQueryRequest | null>(),
    /**
     * returns null on failure. Dealing with failure with respect to UI is parent's responsibility.
     */
    doRunQuery: vReqT<(v: SerializedQueryRequest) => Promise<QueryResult | null>>(),
  },
  setup(props) {
    /**
     * The root node of the the ands-and-ors tree
     */
    const filterableTreeRoot = ref<Connective>((() => {
      const v = Connective.freshRootNode("and")
      v.pushFreshPredicate();
      return v;
    })());

    const queryResult = ref<
      | null
      | {
        data: Record<string, string>[],
        labels: ColumnToLabelMapping,
        /**
         * only available if user is inLeague=1
         */
        __debug__sql?: string
      }>(null)

    const getSharedOptionsByName = (name: string) => props.schema.sharedOptions[name]

    // might have more of these, per "core" fields and etc.
    const selectedFields = ref((() => {
      const ret : Record<string, null | SerializedSelection> = {}
      props.schema.selectables.forEach(selectable => {
        if (!selectable.__dev__disabled && selectable.selected) {
          ret[selectable.name] = {
              args: {},
              columnGroup: selectable.columnGroup ?? -1,
              columnGroupPosition: selectable.columnGroupPosition ?? -1,
            }
        }
        else {
          ret[selectable.name] = null;
        }
      })
      return ret;
    })());

    const justSelectedFields = () => {
      const ret : {[fieldName: string]: SerializedSelection} = {}
      for (const name of Object.keys(selectedFields.value)) {
        const value = selectedFields.value[name]
        if (value) {
          ret[name] = value
        }
      }
      return ret;
    }

    const schemaFilterableOptions = computed(() => {
      return props.schema.filterables.map(v => {
        const result : UiOption = {label: v.label, value: v.name}
        if (v.__dev__disabled) {
          result.attrs = {disabled: true}
          result.label += " (no v4 resolver)"
        }
        return result;
      });
    })

    /**
     * "where function" options available at each filterable tree leaf
     */
    const schemaOptionsWithNilOption = computed(() => {
      return [{value: "", label: ""}, ...schemaFilterableOptions.value]
    })

    const doRunQuery = async () => {
      const data = await props.doRunQuery({
        coreEntity: props.mut_selectedQueryRoot.value,
        select: justSelectedFields(),
        where: transformUiQTreeToRequestTree(filterableTreeRoot.value)
      });
      if (data) {
        queryResult.value = {
          data: data.queryResult,
          labels: data.labels,
          __debug__sql: data.__debug__sql
        }
      }
    }

    /**
     * Select a selectable -- init any args and column ordering information and etc.
     * Unselect a selectable -- set the value to null for that field in `selectedFields`
     */
    const toggleSelectedFieldByName = (name: string, hydrationSource: SerializedSelection | null) => {
      {
        const def = getSelectableDefOrFail(name)
        if (def.__dev__disabled) {
          // never allow checking "dev disabled" things,
          // because they'll just fail when we try to run the query on the backend (which is why they're dev disabled)
          selectedFields.value[name] = null
          return;
        }
      }

      if (selectedFields.value[name]) {
        selectedFields.value[name] = null
      }
      else {
        const sel = getSelectableDefOrFail(name)
        selectedFields.value[name] = {
          // need to initialize this based on argDef types
          // see `maybeInitSelectableArg`
          args: hydrationSource?.args ?? {},
          columnGroup: hydrationSource?.columnGroup ?? sel.columnGroup ?? -1,
          columnGroupPosition: hydrationSource?.columnGroupPosition ?? sel.columnGroupPosition ?? -1
        }
      }
    }

    const maybeInitSelectableArg = (selectable: string, arg: Selectable_ArgDef) => {
      switch (arg.type) {
        case "select-many": {
          const valuesMap = selectedFields.value[selectable]
          if (!valuesMap || typeof valuesMap !== "object") {
            throw Error("illegal state")
          }

          if (!Array.isArray(valuesMap.args[arg.name])) {
            valuesMap.args[arg.name] = [] as string[]
          }
        }
      }
    }

    const groupedSelectables = computed(() => groupifySelectables(props.schema.selectables));

    // keepAlive=true to prevent reinitializing associated <FormKit> instances in each tab, which is noticably slow (~100ms or so)
    const selectableGroupingsTabDefs : TabDef[] = [
      {
        label: "Core fields",
        keepAlive: true,
        render: () => <SelectablesGroupCheckboxes
          selectedFields={selectedFields.value}
          selectables={groupedSelectables.value.find(g => g.groupName === defaultGroup)?.selectables ?? []}
          maybeInitSelectableArg={maybeInitSelectableArg}
          invertSelectedFieldByName={name => toggleSelectedFieldByName(name, null)}
          getSharedOptionsByName={getSharedOptionsByName}
        />
      },
      ...groupedSelectables
        .value
        .filter(g => g.groupName !== defaultGroup)
        .map(g => {
          return {
            label: g.groupName,
            keepAlive: true,
            render: () => <SelectablesGroupCheckboxes
              selectedFields={selectedFields.value}
              selectables={g.selectables}
              maybeInitSelectableArg={maybeInitSelectableArg}
              invertSelectedFieldByName={name => toggleSelectedFieldByName(name, null)}
              getSharedOptionsByName={getSharedOptionsByName}
            />
          }
        })
    ];

    const getSelectableDefOrFail = (name: string) => {
      const v = props.schema.selectables.find(v => v.name === name)
      if (!v) {
        throw Error("no such selectable")
      }
      return v;
    }

    const getFilterableDefOrFail = (name: string) => {
      const v = props.schema.filterables.find(v => v.name === name)
      if (!v) {
        throw Error("no such filterable")
      }
      return v;
    }

    const hydrateFromExistingQuery = (q: SerializedQueryRequest) => {
      for (const k of Object.keys(selectedFields.value)) {
        selectedFields.value[k] = null
      }
      for (const k of Object.keys(q.select)) {
        toggleSelectedFieldByName(k, q.select[k])
      }

      filterableTreeRoot.value = transformRequestTreeToUiQTree(
        q.where,
        getSharedOptionsByName,
        getFilterableDefOrFail,
      );
    }

    // temporary provision to see what resolvers we need to implement to fully satisfy some loaded query
    const unselectableResolversFromHydratedQuery = ref<string[]>([]);

    watch(() => props.query, () => {
      if (props.query === null) {
        unselectableResolversFromHydratedQuery.value = []
        // there's some init logic in setup() that we should factor out and invoke from here
        // (or maybe just inline it, because this is an immediate watch)
      }
      else {
        unselectableResolversFromHydratedQuery.value = Object
          .keys(props.query.select)
          .filter(k => getSelectableDefOrFail(k).__dev__disabled)
          .sort();

        hydrateFromExistingQuery(props.query);
      }
    }, {
      immediate: true,
      deep: /*only respond to rebindings, e.g. a new query has been loaded from the server*/ false
    })

    const debug_copySqlTextFlashKey = ref(0);

    return () => {
      return (
        <div data-test="ReportBuilderImpl">
          <FormKit type="select" label="Root entity" options={props.queryRootOptions} v-model={props.mut_selectedQueryRoot.value}/>
          <FormKit type="form" actions={false} onSubmit={doRunQuery}>
            {
              // dev provision
              unselectableResolversFromHydratedQuery.value.length
                ? <pre style="white-space:break-spaces;" class="my-2 text-xs bg-black text-yellow-200 p-2">
                    {`Missing resolvers needed for this query:\n${unselectableResolversFromHydratedQuery.value.join(", ")}`}
                  </pre>
                : null
            }
            <Tabs tabDefs={selectableGroupingsTabDefs}/>
            <QueryNodeElement
              node={filterableTreeRoot.value}
              getters={{
                getFilterablesOptions: () => schemaOptionsWithNilOption.value,
                getFilterableDefOrFail,
                getSharedOptionsByName,
              }}
            />
            <t-btn class="mt-4" margin={false} type="submit">Get</t-btn>
            <div style="font-family:monospace;" class="my-4">
              <QueryAsDomTree node={filterableTreeRoot.value} getSharedOptionsByName={getSharedOptionsByName}/>
            </div>
          </FormKit>
          {
            queryResult.value
              ? <div>
                <QueryResultTable class="mt-4" data={queryResult.value.data} labels={queryResult.value.labels}/>
                {queryResult.value.__debug__sql
                  ? <div class="my-4 text-xs rounded-md border bg-white">
                    <div class="p-2 text-white bg-green-800 rounded-t-md">Debug (il=1)</div>
                    <div>
                    <div class="ml-2 text-xs flex items-center gap-1">
                      <Transition
                        enterFromClass="bg-gray-300"
                        enterActiveClass="duration-500"
                        leaveActiveClass="hidden"
                      >
                        <span key={debug_copySqlTextFlashKey.value}>Copy to clipboard</span>
                      </Transition>
                      <span
                        class="cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-[2px] rounded-sm"
                        onClick={() => {
                          debug_copySqlTextFlashKey.value++
                          navigator.clipboard.writeText(queryResult.value?.__debug__sql || "<<no data (shouldn't happen)>>")
                        }}
                      >
                        <FontAwesomeIcon icon={faCopy}/>
                      </span>
                    </div>
                    </div>
                    <pre class="p-2 whitespace-break-spaces">{queryResult.value.__debug__sql}</pre>
                  </div>
                  : null
                }
              </div>
              : null
          }
        </div>
      )
    }
  }
})

const SelectablesGroupCheckboxes = defineComponent({
  props: {
    selectables: vReqT<Selectable[]>(),
    selectedFields: vReqT<Record<string, null | SerializedSelection>>(),
    invertSelectedFieldByName: vReqT<(name: string) => void>(),
    maybeInitSelectableArg: vReqT<(name: string, arg: Selectable_ArgDef) => void>(),
    getSharedOptionsByName: vReqT<(name: string) => UiOption[]>()
  },
  setup(props) {
    const subGrouped = (() => {
      const grouped : {[idx: number]: Selectable[]} = {};

      props.selectables.forEach(sel => {
        grouped[sel.displayGroup ?? 1] ??= []
        grouped[sel.displayGroup ?? 1].push(sel);
      });

      return unsafe_objectKeys(grouped).sort(sortBy(num => num)).map(num => grouped[num])
    })();

    return () => (
      <div style="--fk-margin-outer: none; display: grid; grid-auto-flow: column;">
        {
          subGrouped.map(selectables => {
            return (
              <div class="p-1">
                {
                  selectables.map(selectable => {
                    if (!selectable.argsDef?.length) {
                        return <div class={`my-2 flex items-start ${selectable.__dev__disabled ? "bg-yellow-100" : ""}`}>
                          <FormKit
                            key={fkKey_reactToChangeValueLikeNormal(selectable.name, !!props.selectedFields[selectable.name])}
                            outer-class="mt-1" type="checkbox"
                            {...{value: selectable.__dev__disabled ? false : !!props.selectedFields[selectable.name]}}
                            onInput={() => props.invertSelectedFieldByName(selectable.name)}
                            disabled={selectable.__dev__disabled}
                          />
                          <span class="ml-1 -mt-1">{selectable.label}</span>
                        </div>
                    }
                    else {
                      return <div>
                        <div class={`my-2 flex items-start ${selectable.__dev__disabled ? "bg-yellow-100" : ""}`}>
                          <label>
                            <FormKit
                              key={fkKey_reactToChangeValueLikeNormal(selectable.name, !!props.selectedFields[selectable.name])}
                              type="checkbox"
                              {...{value: selectable.__dev__disabled ? false : !!props.selectedFields[selectable.name]}}
                              onInput={() => props.invertSelectedFieldByName(selectable.name)}
                              disabled={selectable.__dev__disabled}
                            />
                            <span class="ml-1 -mt-1">{selectable.label} (group={selectable.group || "default"})</span>
                          </label>
                        </div>
                        {
                          props.selectedFields[selectable.name]
                            ? (
                              <div class="ml-2">
                                {
                                  selectable.argsDef.map(arg => {
                                    props.maybeInitSelectableArg(selectable.name, arg);
                                    switch (arg.type) {
                                      case "select-many":
                                      case "select":
                                        const options = !arg.options
                                          ? [] // shouldn't happen
                                          : Array.isArray(arg.options)
                                          ? arg.options
                                          : props.getSharedOptionsByName(arg.options.name);

                                        return (
                                          <div>
                                            <FormkitSpreadBugUnnecessaryGroup>
                                              <FormKit
                                                style="--fk-padding-input:.25em;"
                                                type="select"
                                                multiple={arg.type === "select-many" ? true : undefined}
                                                {...{
                                                  size: arg.type === "select-many" ? clamp(options.length ?? 0, {min: 1, max: 5}) : undefined,
                                                  ...defaultQueryFormNameAndValidation
                                                }}
                                                v-model={props.selectedFields[selectable.name]!.args[arg.name]}
                                                options={options ?? []}
                                              />
                                            </FormkitSpreadBugUnnecessaryGroup>
                                          </div>
                                        );
                                      default: return <div>unsupported arg type '{arg.type}'</div>
                                    }
                                  })
                                }
                              </div>
                            )
                            : null
                        }

                      </div>
                    }
                  })
                }
              </div>
            )
          })
        }
      </div>
    )
  }
})

const defaultGroup = "__defaultGroup"

function groupifySelectables(selectables: Selectable[]) {
  const result : {groupName: string, selectables: Selectable[]}[] = [
    {groupName: defaultGroup, selectables: []}
  ];

  for (const selectable of selectables) {
    const key = selectable.group || defaultGroup;
    const hit = result.find(v => v.groupName === key)
    if (hit) {
      hit.selectables.push(selectable)
    }
    else {
      result.push({groupName: key, selectables: [selectable]});
    }
  }

  return result;
}

function transformUiQTreeToRequestTree(root: Connective) : WhereClause {
  return workConnective(root);

  function worker(node: UiQNode) : WhereClause | FunctionlikeWhereArgs {
    switch (node.type) {
      case NodeType.connective:
        return workConnective(node)
      case NodeType.predicate:
        return workPredicate(node)
      default: exhaustiveCaseGuard(node)
    }
  }

  function workConnective(node: Connective) : WhereClause {
    const subnodes = node
      .children
      .map(worker)

    switch (node.which) {
      case "and": return {and: subnodes}
      case "or": return {or: subnodes}
      default: exhaustiveCaseGuard(node.which)
    }
  }

  function workPredicate(node: Predicate) : FunctionlikeWhereArgs {
    if (!node.data) {
      throw Error("incomplete query form; UI should prevent this");
    }

    return {
      [node.data.filterableDef.name]: (() => {
        const result : Record<string, string | number> = {}
        node.data.values.forEach(v => {
          if (typeof v.value === "string" || typeof v.value === "number") {
            result[v.name] = v.value;
          }
          else {
            exhaustiveCaseGuard(v.value)
          }
        })
        return result;
      })()
    }
  }
}

function transformRequestTreeToUiQTree(
  pojo: WhereClause,
  getSharedOptionsByName: (id: string) => UiOption[],
  getFilterableDefOrFail: (id: string) => Filterable,
) : Connective {
  const result = worker(null, pojo);

  switch (result.type) {
    case NodeType.connective:
      return result;
    case NodeType.predicate:
      const root = Connective.freshRootNode("and")
      root.children.push(result)
      return root;
    default:
      exhaustiveCaseGuard(result)
  }

  function worker(parent: null | Connective, pojoNode: any) : UiQNode {
    const what = singularKeyOrFail(pojoNode);
    switch (what) {
      case "and":
      case "or":
        const value = pojoNode[what]
        if (!Array.isArray(value)) {
          throw Error("bad node");
        }

        const freshRoot = parent === null
          ? Connective.freshRootNode(what)
          : new Connective(parent, what);

        freshRoot.children.push(...value.map(v => worker(freshRoot, v)));

        return freshRoot;
      default:
        const root = parent ?? Connective.freshRootNode("and");
        const p = new Predicate(root);
        p.data = FilterableFormData(
          getFilterableDefOrFail(what),
          pojoNode[what],
          getSharedOptionsByName,
        )
        return p;
    }
  }

  function singularKeyOrFail(v: any) {
    if (typeof v === "object" && v !== null && Object.keys(v).length === 1) {
      const key = Object.keys(v)[0]
      if (typeof key === "string") {
        if (key.toLowerCase() === "and") {
          return "and"
        }
        else if (key.toLowerCase() === "or") {
          return "or"
        }
        else {
          // retaining case (__not__ lower cased)
          return key;
        }
      }
    }
    throw Error("bad node")
  }
}

export const defaultQueryFormNameAndValidation = {
  name: "Field",
  validation: [["required"]] as FormKitValidationRule[]
} as const

/*
  TODO: @formkit-spread-bug-unnecessary-group
  can't spread `defaultNameAndValidation` a second time like

  <>
    <FormKit type="select" v-model={props.leaf.operation} {...defaultNameAndValidation}/>
    <FormKit type="text" v-model={props.leaf.value} {...defaultNameAndValidation}/>
  </>

  ... bug in JSX compiler? In Formkit?

  If we do the buggy spread, we end up somehow binding props.leaf.value to props.leaf.operation
  Bug is almost certainly in FormKit, adding an (otherwise unnecessary) <FormKit type="group">
  container around other elements works around it (but adding "just" a div doesn't work, it has to be a <FormKit type=group>)
*/
export const FormkitSpreadBugUnnecessaryGroup = defineComponent({
  setup(_, {slots}) {
    return () => <FormKit type="group">{slots.default?.()}</FormKit>
  }
})
