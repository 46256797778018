import { AreaCoachDetail_lowercase, CoachDetail, GameTeamDetail } from "src/composables/InleagueApiV1.Game";
import { defineComponent } from "vue";
import { AugmentedGamelikeForSchedule } from "./page/schedules.ilx";

import * as ilapi from "src/composables/InleagueApiV1"
import { exhaustiveCaseGuard, parseIntOr, sortBy, sortByMany, vReqT } from "src/helpers/utils";
import { Datelike, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { teamScheduleURL } from "./TeamSchedule";
import dayjs, { Dayjs } from "dayjs";
import { dateMin, dayjsOr } from "src/helpers/formatDate";

type GameScheduleInfoEx = AugmentedGamelikeForSchedule<ilapi.LoggedOutGame>;

export const MultiGameCoachInfo = defineComponent({
  props: {
    games: vReqT<GameScheduleInfoEx[]>(),
  },
  setup(props) {
    const teamNameHeader = (team: GameTeamDetail) => {
      if (team.team && team.teamName && team.team.trim() !== team.teamName.trim()) {
        return `${team.team} (${team.teamName})`
      }
      else {
        return team.team;
      }
    }

    return () => {
      return (
        <div style="display:grid; grid-template-columns: repeat(auto-fit, minmax(16em, 1fr)); gap:1em;">
          {
            uniqueTeams(props.games)
              .map((uniquifiedInfo) => {
                const {team, coaches, competitionID, division, minSeasonStart, maxSeasonWeeks} = uniquifiedInfo
                return (
                  <div class="bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5 p-2">
                    <a class="inline-block il-link" target="_blank" href={teamScheduleURL({teamID: team.teamID, competitionID, division, seasonStart: minSeasonStart, seasonWeeks: maxSeasonWeeks})}>{teamNameHeader(team)}</a>
                    <div>
                      {
                        coaches.map(coach => {
                          if (coach.email) {
                            return <div>{headOrAsstCoach(coach)} <a href={`mailto:${coach.email}`} class="il-link">{coach.firstname} {coach.lastname}</a></div>
                          }
                          else {
                            return <div>{headOrAsstCoach(coach)} {coach.firstname} {coach.lastname}</div>
                          }
                        })
                      }
                    </div>
                  </div>
              )
            })
          }
        </div>
      )
    }

    function headOrAsstCoach(coach: MinCoachDetail) {
      switch (coach.title) {
        case "Head Coach":
          return "Head Coach"
        case "Co-Coach":
          return "Co-Coach"
        case "Assistant":
          return "Asst. Coach";
        case "Administrator":
          throw Error(`expected administrators to have been filtered away`);
        default: exhaustiveCaseGuard(coach.title);
      }
    }
  }
})

type MinCoachDetail = Pick<CoachDetail, "firstname" | "lastname" | "title" | "email">

/**
 * There can be duplicates across games, for games sharing home or visitor teams. This keeps only the unique as per teamID.
 * Coaches are the same per team, not per game.
 */
function uniqueTeams(gamesEx: GameScheduleInfoEx[]) {
  const coachFilter = (c: MinCoachDetail) => c.title === "Head Coach" || c.title === "Co-Coach" || c.title === "Assistant";
  const coachPosSort = sortBy<MinCoachDetail>(_ => _.title === "Head Coach" ? 0 : _.title === "Co-Coach" ? 1 : 2)
  const coachNameSort = sortByMany<MinCoachDetail>(sortBy(_ => _.lastname), sortBy(_ => _.firstname))
  const coachSort = sortByMany(coachPosSort, coachNameSort);

  const xmax = (args: (number | undefined)[]) => {
    const z = args.filter(v => v !== undefined)
    if (z.length === 0) {
      return undefined
    }
    return Math.max(...z)
  }

  //
  // e.g. 2 games both have the same homeTeam, which is natural and reasonable; we don't want _the same_ coach list (for that same team) being pushed twice
  //
  const result = new Map<Guid, {
    team: GameTeamDetail,
    coaches: MinCoachDetail[],
    competitionID: Integerlike,
    division: string,
    // The list of games may span multiple seasons, and this value is derived from competitionSeason,
    // so we are just interested in the overall max value
    minSeasonStart: undefined | Dayjs,
    maxSeasonWeeks: undefined | number,
  }>();

  function runOne(gameEx: GameScheduleInfoEx, team: GameTeamDetail, coaches: CoachDetail[], areaCoaches: AreaCoachDetail_lowercase[]) {
    const teamID = team.teamID;

    const entry = result.get(teamID);

    if (entry) {
      entry.minSeasonStart = dateMin([entry.minSeasonStart, dayjsOr(gameEx.competition_season_seasonStart)])
      entry.maxSeasonWeeks = xmax([entry.maxSeasonWeeks, parseIntOr(gameEx.competition_season_seasonWeeks, undefined)]);
    }
    else {
      const mergedCoaches = [
        ...coaches.filter(coachFilter),
        ...areaCoaches.filter(coachFilter),
      ]

      if (mergedCoaches.length > 0) {
        result.set(teamID, {
          team: team,
          coaches: mergedCoaches.sort(coachSort),
          competitionID: gameEx.competitionID,
          division: gameEx.division,
          minSeasonStart: dayjsOr(gameEx.competition_season_seasonStart),
          maxSeasonWeeks: parseIntOr(gameEx.competition_season_seasonWeeks, undefined),
        })
      }
    }
  }

  for (const gameEx of gamesEx) {
    if (gameEx.homeTeam) {
      runOne(gameEx, gameEx.homeTeam, gameEx.homeCoaches, gameEx.areaCoachDetail?.homeAreaCoaches ?? [])
    }
    if (gameEx.visitorTeam) {
      runOne(gameEx, gameEx.visitorTeam, gameEx.visitorCoaches, gameEx.areaCoachDetail?.visitorAreaCoaches ?? [])
    }
  };

  // this is reasonable for common team descriptor lists like ['B 9-A', 'B 9-B', 'B 9-C']
  // which can be sorted lexically
  return [...result.values()].sort(sortBy(_ => _.team.team));
}
