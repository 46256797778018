<template lang="pug">
div(v-if='eventLoaded' data-test="purchaseConfirmationRoot")
  h1.text-2xl.font-medium.mb-2(class='md:text-4xl md:self-end')
    i.mr-4.far.fa-check-square
    | Confirm Selections
  .flex.flex-col(v-if='hasItemsInCart', data-cy='confirmation')
    VoidInvoiceModal(
      v-if='!isSameInvoice',
      :invoice='draftInvoice',
      data-cy='voidInvoiceModal',
      @popupClosed='handleClose'
    )
    .-my-2.overflow-x-auto(class='sm:-mx-6 lg:-mx-8')
      .py-2.align-middle.inline-block.min-w-full(class='sm:px-6 lg:px-8')
        .consistent-box-shadow.overflow-hidden.border-b.border-gray-200(
          class='sm:rounded-lg'
        )
          div(v-if='eventSignupIDs.length && eventLoaded')
            EventConfirmation(
              :eventID='$route.query.eventID',
              :eventSignupIDs='eventSignupIDs',
              v-on="eventConfirmationHandlers"
            )
          .row-span-1.px-3.py-3.bg-white.text-right.text-s.leading-4.font-medium.text-gray-700.tracking-wider.row-span-1.w-full.bg-gray-50(
            class='md:px-24',
            v-if='total > 0'
          )
            | TOTAL: ${{ total }}
      .flex.flex-row.justify-end(
        v-if='total === 0 && eventSignupIDsInCart.length && isSameInvoice',
        class='sm:px-6 md:px-8'
      )
        h3.italic Your registration is complete, Thank you.
      .flex.flex-row.justify-between(
        class='sm:px-6 lg:px-8',
        v-else-if='isSameInvoice'
      )
        t-btn(@click='$router.go(-1)', :margin='false', data-cy='edit')
          .flex.items-center.font-semibold.text-lg.tracking-widest
            font-awesome-icon.m-2(:icon='["fas", "arrow-left"]')
            p Change Selections
        t-btn(@click='createInvoice', :margin='false', data-test='pay')
          .flex.items-center.font-semibold.text-lg.tracking-widest
            p Pay
            font-awesome-icon.m-2(:icon='["fas", "arrow-right"]')
  .flex.flex-row.justify-end(
    v-if='total === 0 && eventSignupIDsInCart.length === 0',
    class='sm:px-6 md:px-8'
  )
    h3.italic You have nothing left to purchase.
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  Ref,
  onMounted,
  getCurrentInstance,
} from 'vue'
import { useRouter, useRoute } from 'vue-router'


import VoidInvoiceModal from 'src/components/Payment/VoidInvoiceModal.vue'
import { DeleteDetails } from 'src/interfaces/Store/events'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'

import C_EventConfirmation from 'src/components/Events/EventConfirmation.vue'
import * as M_EventConfirmation from "src/components/Events/EventConfirmation.ilx"

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

import * as R_Checkout from 'src/components/Payment/pages/Checkout.route'
import { Integerlike } from 'src/interfaces/InleagueApiV1'

import { EventStore } from "src/store/EventStore"
import { CheckoutStore } from "src/store/CheckoutStore"

export default defineComponent({
  name: 'PurchaseConfirmation',
  components: {
    EventConfirmation: C_EventConfirmation,
    VoidInvoiceModal,
  },
  setup() {
    const total = ref(0)
    const signupIDs = ref([])
    const test = ref([])

    const eventSignupIDs = ref<iltypes.Guid[]>([])

    /**
     * nullish | (invoice instance id)
     */
    const draftInvoice = ref(/* "" | invoiceInstanceID */'')

    /**
     * ???? controls a modal display?
     */
    const isSameInvoice = ref(true)
    const eventLoaded = ref(false)
    const deleteDetails = ref({}) as Ref<DeleteDetails>

    const route = useRoute()
    const router = useRouter()


    const localInstance = getCurrentInstance()
    const toast = localInstance?.appContext.config.globalProperties.$toast

    const eventSignupIDsInCart = computed</*EventSignupID*/ iltypes.Guid[]>(() => {
      if (
        route.query.eventID &&
        CheckoutStore.value.eventsCart[route.query.eventID as string]
      ) {
        return CheckoutStore.value.eventsCart[route.query.eventID as string]
      } else {
        return []
      }
    })

    const hasItemsInCart = computed<boolean>(() => {
      return eventSignupIDsInCart.value.length > 0;
    })

    const events = computed(() => {
      return EventStore.value.registeringEvents
    })

    const updateTotal = (num: number) => {
      total.value += num
    }

    // Checks to make sure that items in the cart are either all for the same invoice or no invoice at all
    //TODO: When items are added, will need to modify this to accomodate
    const verifyInvoiceStatus = async () => {
      if (route.query.eventID) {
        //
        // Getting all registrants who have an invoice, and check status
        //
        let invoiceInstanceID = ''
        const eventSignupIDsHavingUnpaidInvoices: string[] = []
        const eventSignupIDsHavingClosedInvoices: string[] = []
        const eventSignupIDsInPendingSignupState: string[] = []
        const eventSignupIDsHavingCanceledInvoices: string[] = []
        for (let i = 0; i < eventSignupIDsInCart.value.length; i++) {
          // console.log('for loop, events cart', i, eventSignupIDsInCart.value[i])
          invoiceInstanceID =
            (CheckoutStore.value.registrants[eventSignupIDsInCart.value[i]]
              .invoiceInstanceID ?? "").toString()
          //// console.log('invoiceInstanceID', invoiceInstanceID)
          if (invoiceInstanceID) {
            await CheckoutStore.getInvoice({
              invoiceID: invoiceInstanceID,
              expand: false,
            })
            if (
              CheckoutStore.value.invoice[invoiceInstanceID].status ===
                'draft' ||
              CheckoutStore.value.invoice[invoiceInstanceID].status === 'open'
            ) {
              if (
                draftInvoice.value === '' ||
                draftInvoice.value === invoiceInstanceID
              ) {
                draftInvoice.value = invoiceInstanceID
              } else {
                //// console.log('in draft invoice if statement')
                isSameInvoice.value = false
              }
              eventSignupIDsHavingUnpaidInvoices.push(eventSignupIDsInCart.value[i])
            } else if (
              CheckoutStore.value.invoice[invoiceInstanceID].status ===
                'paid' ||
              CheckoutStore.value.invoice[invoiceInstanceID].status === 'void'
            ) {
              eventSignupIDsHavingClosedInvoices.push(eventSignupIDsInCart.value[i])
            }
          } else if (
            CheckoutStore.value.registrants[eventSignupIDsInCart.value[i]].canceled
          ) {
            // console.log('canceled')
            eventSignupIDsHavingCanceledInvoices.push(eventSignupIDsInCart.value[i])
          } else {
            // console.log('enrolling')
            eventSignupIDsInPendingSignupState.push(eventSignupIDsInCart.value[i])
          }
        }

        // console.log(
        //   'unpaidInvoices',
        //   unpaidInvoices,
        //   'closed',
        //   closedInvoices,
        //   'enrolling',
        //   enrolling
        // )

        if (eventSignupIDsHavingUnpaidInvoices.length && eventSignupIDsInPendingSignupState.length) {
          //// console.log('two lengths')
          isSameInvoice.value = false
        } else if (
          eventSignupIDsHavingUnpaidInvoices.length &&
          eventSignupIDsHavingUnpaidInvoices.length !=
            CheckoutStore.value.invoice[draftInvoice.value].lineItems.length
        ) {
          isSameInvoice.value = false
        } else if (eventSignupIDsHavingUnpaidInvoices.length) {
          isSameInvoice.value = true
          eventSignupIDs.value = eventSignupIDsHavingUnpaidInvoices
        } else if (eventSignupIDsInPendingSignupState.length) {
          //// console.log('enrolling, true')
          isSameInvoice.value = true
          eventSignupIDs.value = eventSignupIDsInPendingSignupState
        } else {
          eventSignupIDs.value = []
        }
      }
    }

    const deleteItem = async (detail: DeleteDetails) => {
      const type = detail.type;
      const group = detail.group;
      const registrationID = detail.registrationID;
      const fee = detail.fee;

      //// console.log('sanity check', $checkout.registrants[registrationID])
      // console.log(
      //   'in DELETE ITEM',
      //   type,
      //   group,
      //   registrationID,
      //   fee,
      //   store.state.checkout.registrants[registrationID].lineItemID,
      //   !store.state.checkout.registrants[registrationID].paid,
      //   isSameInvoice.value
      // )

      // Displays modal if user is trying to delete a registrant on an invoice
      if (
        CheckoutStore.value.registrants[registrationID].lineItemID &&
        !CheckoutStore.value.registrants[registrationID].paid
      ) {
        // console.log('in first if')
        isSameInvoice.value = false
        deleteDetails.value = { type, group, registrationID, fee }
      }

      // delete event
      if (type === 'event' && isSameInvoice.value) {
        // non-null assertion OK here --- the "event" type variant
        // MUST contain an eventSignup (see definition of DeleteDetails on why
        // this is not yet fully enforced by the type system)
        const eventSignup : ilapi.event.EventSignup = detail.eventSignup!;

        // remove from this component
        const idx = eventSignupIDs.value.indexOf(registrationID)
        //// console.log('idx', idx)
        eventSignupIDs.value.splice(idx, 1)

        // make axios call to delete
        try {
          const entityID = CheckoutStore.value.registrants[registrationID]
            .userID
            ? CheckoutStore.value.registrants[registrationID].userID
            : CheckoutStore.value.registrants[registrationID].childID
          const entityType = CheckoutStore.value.registrants[registrationID]
            .userID
            ? 'userID'
            : 'childID'
          await axiosInstance.delete(`v1/event/${group}/signup`, {
            data: { [entityType]: entityID },
          })
          updateTotal(fee)
          toast.info({
            message: `Succesfully removed ${
              CheckoutStore.value.registrants[registrationID].userID
                ? CheckoutStore.value.registrants[registrationID]
                    .userFirstName +
                  ' ' +
                  CheckoutStore.value.registrants[registrationID].userLastName
                : CheckoutStore.value.registrants[registrationID]
                    .childFirstName +
                  ' ' +
                  CheckoutStore.value.registrants[registrationID].childLastName
            } from ${events.value[group].eventName}`,
          })

          //
          // a lot of global state to remember to update
          //
          await EventStore.putEntityByEvent({
            entity: {
              ...EventStore.value.registeringEntities[eventSignup.eventID]![eventSignup.entityID]!,
              isEnrolled: false,
            }
          });
          await EventStore.removeSignup({type: "from-signup", eventSignup});
          await CheckoutStore.removeEventSignup({type: "from-signup", eventSignup});

          // await typeWrappedStore(store, "checkout").removeEventRegistrant({
          //   event: group,
          //   eventSignupID: registrationID,
          // })
          // console.log('at end of delete function')
          deleteDetails.value = {} as DeleteDetails
        } catch (err) {
          toast.error({
            message: `Sorry, we had an error removing ${
              CheckoutStore.value.registrants[registrationID].userID
                ? CheckoutStore.value.registrants[registrationID]
                    .userFirstName +
                  ' ' +
                  CheckoutStore.value.registrants[registrationID].userLastName
                : CheckoutStore.value.registrants[registrationID]
                    .childFirstName +
                  ' ' +
                  CheckoutStore.value.registrants[registrationID].childLastName
            } from ${events.value[group].eventName}`,
          })
          //// console.error(err)
        }
      }
    }

    const eventConfirmationHandlers : M_EventConfirmation.Emits = {
      deleteItem: (args) => deleteItem(args),
      updateTotal: (args) => updateTotal(args)
    }

    const handleClose = async () => {
      // console.log('in handle close', deleteDetails.value)
      if (Object.keys(deleteDetails.value).length) {
        // console.log('in deleteDetails.value')
        isSameInvoice.value = true
        await deleteItem(deleteDetails.value)
        // console.log('after deleteDetails')
      }
      await verifyInvoiceStatus()
    }

    const navigateToCheckout = async (invoiceID: string) => {
      // if(Capacitor.platform==='ios') {
      //   const { Browser } = Plugins
      //   await Browser.open({
      //     // url: 'https://inleague-test-quasar-1989.loca.lt/app/',
      //     url: `https://inleague-test-quasar-linnae.loca.lt/checkout/${invoiceID}`,
      //     presentationStyle: 'fullscreen',
      //   })
      // } else {
      await router.push(R_Checkout.routeDetailToRouteLocation({
        invoiceInstanceIDs: [invoiceID as Integerlike]
      }))
      // }
    }

    const createInvoice = async () => {
      const eventSignupIDs: string[] = []
      for (let i = 0; i < eventSignupIDsInCart.value.length; i++) {
        if (
          CheckoutStore.value.registrants[eventSignupIDsInCart.value[i]].computed_feeAfterVisibleDiscounts &&
          !CheckoutStore.value.registrants[eventSignupIDsInCart.value[i]].paid &&
          !CheckoutStore.value.registrants[eventSignupIDsInCart.value[i]].canceled
        ) {
          eventSignupIDs.push(eventSignupIDsInCart.value[i])
        }
      }

      try {
        if (isSameInvoice.value && draftInvoice.value) {
          await navigateToCheckout(draftInvoice.value)
        } else {
          // we create an invoice, and then immediately repull it.
          // Is it necessary to re-get like this? Does it pull additional fields?
          // Code's been doing this so we'll keep it double-requesting
          const invoice_butMaybeMissingOptionalProperties = await ilapi.createInvoiceFromEventSignups(axiosInstance, {eventSignupIDs});
          const invoice = await ilapi.getInvoice(axiosInstance, {instanceID: invoice_butMaybeMissingOptionalProperties.instanceID});

          CheckoutStore.setInvoice(invoice)
          await CheckoutStore.getUnpaidInvoices();

          // update any events we have locally to reflect their new invoice status
          const signups = await EventStore.getSignupsByEventSignupID(eventSignupIDs);
          for (const signup of signups) {
            await EventStore.putSignupByEntityByEvent({signup: {...signup, invoiceInstanceID: invoice.instanceID}});
          }

          await navigateToCheckout(invoice.instanceID.toString())
        }
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    onMounted(async () => {
      eventLoaded.value = false

      if (route.query.eventID) {
        // we want side effects of this call
        await EventStore.getEvent({eventID: route.query.eventID as string});
        // we want side effects of this call
        await CheckoutStore.getRegistrants(route.query.eventID as string)
      }

      await verifyInvoiceStatus()

      eventLoaded.value = true;
    })

    return {
      total,
      signupIDs,
      test,
      eventSignupIDs,
      draftInvoice,
      isSameInvoice,
      eventLoaded,
      deleteDetails,
      verifyInvoiceStatus,
      handleClose,
      createInvoice,
      navigateToCheckout,
      hasItemsInCart,
      eventSignupIDsInCart,
      events,
      eventConfirmationHandlers,
    }
  },
})
</script>
