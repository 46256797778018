import { defineComponent, onMounted, onUnmounted, ref } from "vue"

// side-effects only, globally affects the Quill instance
// we don't necesarrily want these side-effects, but we DO want for deterministic effects
// (otherwise whether this has been imported or not by the time we get here would potentially affect what quill does here)
import "src/components/UserInterface/quill-wrapper.vue"
import 'quill/dist/quill.snow.css'

import Quill, { QuillOptions } from 'quill'
import MagicUrl from "quill-magic-url"

Quill.register('modules/magicUrl', MagicUrl)

import { assertNonNull, assertTruthy, nextGlobalIntlike, useWatchLater, vOptT } from "src/helpers/utils";
import { Delta } from "quill/core"
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

/**
 * This is a copy of the Quill configuration from the Quill usage in the legacy Email manager.
 *
 * For testing, the selector/locator you probably want is:
 * `[data-test="..."][contenteditable]`
 * Or in English: "the element you think you want to select, but additionally narrowed down further into its child
 * element having a 'contenteditable' attribute".
 */
export const QuillWrapper3 = defineComponent({
  props: {
    modelValue: vOptT<string>(),
    placeholder: vOptT<string>(),
    allowImages: vOptT<boolean>(true),
    allowVideos: vOptT<boolean>(true),
    pinLinkEditorPopup: vOptT<boolean>(false),
  },
  setup(props, ctx) {
    const quillElemID = `il-quillWrapper3-${nextGlobalIntlike()}`;
    let quill : Quill | undefined;

    /**
     * Typically we want to ignore pasted images, but in some cases we do want to allow them
     * (namely, when "pasting" text from the DB, that contains images, where those images
     * presumably came from an earlier "safe" input source)
     * n.b. not reactive (i.e. not a ref)
     */
    let ignorePastedImageHTML = true
    /**
     * n.b. not reactive (i.e. not a ref)
     */
    let lastKnownEditorContent = ""

    const initQuill = () : void => {
      const linksAndImagesAndVideos = (() => {
        const what = new Set(['link' as const, 'image' as const, 'video' as const])
        if (!props.allowImages) {
          what.delete("image")
        }
        if (!props.allowVideos) {
          what.delete("video")
        }
        return [...what]
      })();

      const toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        linksAndImagesAndVideos,
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean']                                         // remove formatting button
      ];

      const options : QuillOptions = {
        debug: 'warn',
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: () => {
                assertNonNull(quill)
                const range = quill.getSelection();
                if (!range) {
                  // Even in the "has no selection" case, we should still get an "empty" range like from X to X
                  // where it means "this is just where the cursor is, but there is no selection".
                  // So it's not clear why we might get a null selection.
                  return;
                }
                const value = prompt('Image URL:');
                if (value) {
                  quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
                }
              }
            }
          },
          history: true,
          clipboard: true,
          magicUrl: true,
        },
        placeholder: props.placeholder || "",
        formats: null,
        theme: 'snow'
      };

      quill = new Quill(`#${quillElemID}`, options);
      unsafeForceUpdate({unsafeRawHTML: props.modelValue || ""})

      // try to stop pastes of "just images"
      quill.uploader.upload = () => {
        // do nothing
      };

      // try to stop pastes of text containing images
      quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        if (ignorePastedImageHTML) {
          if ((node as Element).tagName === "IMG") {
            return new Delta().insert('')
          }
        }

        return delta;
      })

      quill.on('text-change', () => {
        assertNonNull(quill)
        const html = getInlineStyledHtml(quill)
        lastKnownEditorContent = html
        ctx.emit("update:modelValue", html)
      })
    }

    const unsafeForceUpdate = (v: {unsafeRawHTML: string}) : void => {
      if (!quill) {
        return
      }

      try {
        // pasting raw HTML, skip the ignore (otherwise, html containing image tags from the db will not include the values coming out of the db)
        ignorePastedImageHTML = false
        lastKnownEditorContent = v.unsafeRawHTML
        quill.disable() // https://github.com/slab/quill/issues/2156
        dangerouslyPastHTML_noFocusStealing(quill, v.unsafeRawHTML)
      }
      finally {
        ignorePastedImageHTML = true
        quill.enable() // undo pre-paste disablement
      }
    }

    const updateOnModelValueChangeWatcher = useWatchLater(() => props.modelValue, () => {
      if (props.modelValue === lastKnownEditorContent) {
        return;
      }
      unsafeForceUpdate({unsafeRawHTML: props.modelValue || ""})
    }, {immediate: true})

    onMounted(() => {
      assertTruthy(document.getElementById(quillElemID));
      initQuill();
      assertNonNull(quill)
      unsafeForceUpdate({unsafeRawHTML: props.modelValue || ""})
      updateOnModelValueChangeWatcher.start()
    })

    /**
     * There is a little popup element that enables editing link targets.
     * But its position is not controllable, and sometimes falls out of whatever container we're mounted in.
     * This (kludgily) tries to identify when the popup has been mounted, and then force updates its position
     * whenever it has been observed to change.
     *
     * This is all tied to implementation details of quill and is entirely a hack.
     */
    const kludgyLinkEditorPopupPositionFixer = (() => {
      const rootRef = ref<HTMLElement | null>(null)
      let linkEditorPopupElem : HTMLElement | null = null

      const fixIt = () => {
        if (!linkEditorPopupElem) {
          return
        }

        linkEditorPopupElem.style.left = "0px"
      }

      // intended to fire when the target element is initially mounted (and possibly whenver it is deleted/remounted?...)
      const rootObserver = new MutationObserver((mutationList, observer) => {
        if (!rootRef.value) {
          return
        }

        const elems = rootRef.value.querySelectorAll("[data-mode=link]")
        if (!elems || elems.length !== 1) {
          return
        }

        const elem = elems[0]

        if (!(elem instanceof HTMLElement) || elem === linkEditorPopupElem) {
          return
        }

        linkEditorPopupElem = elem

        fixIt()
        linkEditorPopupObserver.disconnect()
        linkEditorPopupObserver.observe(linkEditorPopupElem, {attributes: true, attributeFilter: ["style"]})
      })

      // intended to fire when the position of the link element is adjusted (via its "style" attribute)
      const linkEditorPopupObserver = new MutationObserver((mutationList, observer) => {
        if (!linkEditorPopupElem) {
          return
        }
        fixIt()
      })

      onMounted(() => {
        if (props.pinLinkEditorPopup) {
          setTimeout(() => {
            assertNonNull(rootRef.value)
            // For whatever reason, just subtree/childist is not sufficient to identify when a child is added to the subtree,
            // which is what we are interested in (to see when does the link popup first get mounted)
            // So we also listen for changes to attributes in the subtree, which will fire when the target element has its style changed,
            // which occurs when it is moved by quill. Listening for attributes is probably super noisy, alot of changes will be occuring.
            // But -- our observer doesn't iterate over changes to the tree, but just does constant time work, so this shouldn't be too bad.
            rootObserver.observe(rootRef.value, {subtree: true, childList: true, attributes: true})
          }, 0)
        }
      })

      onUnmounted(() => {
        rootObserver.disconnect()
        linkEditorPopupObserver.disconnect()
      })

      return {
        rootRef
      }
    })()

    return () => <div ref={kludgyLinkEditorPopupPositionFixer.rootRef}>
      {/*
        need the outer wrapper div so that vue destroys everything on unMounted,
        otherwise we end up with many elements "at the component root level" and not all get destroyed
      */}
      <div id={quillElemID}></div>
    </div>
  }
})

function getInlineStyledHtml(quill: Quill) {
  const delta = quill.getContents();
  const converter = new QuillDeltaToHtmlConverter(delta.ops, {inlineStyles: true});
  const html = converter.convert();
  return html;
}

/**
 * copy of Quill's `dangerouslyPasteHTML` but we've removed the side-effect of altering global document focus.
 * see: https://github.com/slab/quill/issues/2156
 */
function dangerouslyPastHTML_noFocusStealing(quill: Quill, html: string) {
  const delta = quill.clipboard.convert({
    html: html,
    text: ""
  });

  quill.setContents(delta, undefined);

  // This is what we avoid here
  // quill.setSelection(0, Quill.sources.SILENT);
}

/*
<div class="ql-tooltip ql-editing" style="left: -88.3594px; top: 28px;" data-mode="link">
  <a class="ql-preview" rel="noopener noreferrer" target="_blank" href="http://www.google.com">http://www.google.com</a><input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL" placeholder="https://quilljs.com"><a class="ql-action"></a><a class="ql-remove"></a>
</div>
*/
