import { defineComponent, ref, computed, CSSProperties, watch, reactive } from "vue";
import { faFilterCircleXmark, faTrash } from "@fortawesome/free-solid-svg-icons";
import { faGripDotsVertical, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { SoccerBall, X } from "src/components/SVGs";
import { GetRegistrationQuestionAnswersResponse } from "src/composables/InleagueApiV1.Registration";
import { TeamForTeamAssignmentsView } from "src/composables/InleagueApiV1.Teams";
import { DAYJS_FORMAT_HTML_DATETIME, dayjsFormatOr } from "src/helpers/formatDate";
import { vReqT, vOptT, arrayFindOrFail, assertNonNull, exhaustiveCaseGuard, sortByMany, parseFloatOr, sortBy, sortByDayJS, parseIntOrFail, SortDir, SetEx, accentAwareCaseInsensitiveCompare, assertIs, Optional } from "src/helpers/utils";
import { Guid, RegistrationQuestion } from "src/interfaces/InleagueApiV1";
import { FontAwesomeSortArrow, freshSortState } from "src/modules/TableUtils";
import { Client } from "src/store/Client";
import { wellKnownCols, getTeamName, assignedPlayerDragMimeType, unassignedPlayerDragMimeType, globalTeamAssignmentsDragData, SelectedQuestions, PlayerForTeamAssignmentViewEx, isTentativeAssignment, PlayerForTeamAssignmentViewExFilter } from "./TeamAssignments.shared";
import { FormKit } from "@formkit/vue";
import dayjs from "dayjs";
import { ilDraggable, vueDirective_ilDraggable } from "src/modules/ilDraggable"

import { DynamicScroller, DynamicScrollerSlots, DynamicScrollerItem } from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { wellKnownRegistrationGrades } from "src/composables/registration";
import { Btn2 } from "src/components/UserInterface/Btn2";
import { AutoModal, DefaultModalController } from "src/components/UserInterface/Modal";
import { registrationGradeOrdering } from "src/helpers/RegistrationUtils";

export const CommonTeamAssignmentsPlayerListing = defineComponent({
  props: {
    selectedPlayerIDs: vReqT<SetEx<Guid>>(),
    players_unfiltered: vReqT<PlayerForTeamAssignmentViewEx[]>(),
    players: vReqT<PlayerForTeamAssignmentViewEx[]>(),
    /**
     * It is a bug if selectedQuestions.customQuestionIDs contains IDs for questions not contained in `registrationQuestions`
     */
    selectedQuestions: vReqT<SelectedQuestions>(),
    registrationQuestions: vReqT<RegistrationQuestion[]>(),
    registrationQuestionAnswers: vReqT<GetRegistrationQuestionAnswersResponse>(),
    busyByPlayerID: vReqT<Set<Guid>>(),
    mode: vReqT<"unassigned-listing" | "unassigned-listing/loan" | "already-assigned-listing">(),
    showTentativeAssignmentInfo: vReqT<boolean>(),
    // todo: make this dependent on typeof `mode` (not required for unassigned listing)
    team: vOptT<TeamForTeamAssignmentsView>(),
    // todo: make this dependent on typeof `mode` (not required for unassigned listing)
    showDragGrip: vReqT<boolean>(),
    hasSomeNonEmptyFilters: vReqT<boolean>(),
    filter: vReqT<PlayerForTeamAssignmentViewExFilter | null>(),
  },
  emits: {
    undeleteLoans: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true,
    undeletePlayers: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true,
    deleteAssignments: (_: {players: PlayerForTeamAssignmentViewEx[]}) => true,
    clearFilters: () => true,
  },
  directives: {
    ilDraggable: vueDirective_ilDraggable
  },
  setup(props, {emit}) {
    // need api support for this (add-many, remove-many) and then update a few misc. places
    const supportMultiPlayerSelect = true;

    watch(() => [props.mode, props.team], () => {
      if (props.mode === "already-assigned-listing" && !props.team) {
        throw new Error("assertion failure");
      }
    }, {immediate: true})

    /**
     * unused? we had been doing updates "per player" but now its a batch process
     */
    const hasSomeBusyPlayerID = computed<boolean>(() => {
      for (const isBusy of props.busyByPlayerID) {
        if (isBusy) {
          return true;
        }
      }
      return false;
    })

    const selectedPlayers = computed(() => {
      return [...props.selectedPlayerIDs].map(playerID => arrayFindOrFail(props.players, v => v.apiData.child.childID === playerID))
    })

    function DragHandle({players} : DragHandleProps) {
      if (players.length === 0) {
        // shouldn't happen
        return null
      }

      const teamName = props.team ? getTeamName(props.team) : ""
      const head = `${players[0].apiData.child.playerFirstName} ${players[0].apiData.child.playerLastName}`
      const remaining = players.length > 1 ? `...and ${players.length - 1} more` : ""

      return (
        <div class="text-sm rounded-md il-box-shadow-1 p-3 inline-flex flex-col bg-blue-500 text-white">
          <div>{teamName}</div>
          <div>{head}</div>
          <div class="text-xs">{remaining}</div>
          {
            props.mode === "unassigned-listing/loan"
              ? <div class="text-xs">(as loan)</div>
              : null
          }
          {
            globalTeamAssignmentsDragData.showDuplicateWarning
              ? (
                <div class="text-xs bg-yellow-200 rounded-md text-black p-1 mt-1">
                  <div>Note: some players in this drag operation</div>
                  <div>already exist in the target team. The duplicates will be discarded.</div>
                </div>
              )
              : null
          }
        </div>
      )
    }

    const rowBgColorStyle = (rowIdx: number) : CSSProperties => {
      return (rowIdx % 2) ? {backgroundColor: "white"} : {backgroundColor: "#F8F8F8"};
    }

    const draggableConfig = (player: PlayerForTeamAssignmentViewEx) : ilDraggable | null => {
      if (player.clientData.disableAssignOrLoanDueToMissingBirthCert) {
        // We shouldn't be showing the draggable element anyway, either.
        return null;
      }

      // if there are selected players, use those;
      // otherwise, there are no selected players, and the player object passed in is "implicitly selected"
      // by virtue of being the thing that kicked of a dragstart event
      const getEffectivelySelectedPlayers = () => {
        return selectedPlayers.value.length > 0
          ? selectedPlayers.value
          : [player]
      }

      const onLeaveOrEnd = () => {
        globalTeamAssignmentsDragData.clear()
        globalTeamAssignmentsDragData.showDuplicateWarning = false
      }

      const maybeUpdatePlayerSelection = () => {
        if (props.selectedPlayerIDs.has(player.apiData.child.childID)) {
          // drag handle was for a player already selected
          return;
        }
        else {
          // drag handle was for a player NOT already selected
          props.selectedPlayerIDs.clear()
          props.selectedPlayerIDs.add(player.apiData.child.childID)
        }
      }

      switch (props.mode) {
        case "already-assigned-listing":
          assertNonNull(props.team, "requires a team in the 'already-assigned-listing' case");
          const team = props.team;
          return {
            dragHandleJsxFunc: () => <DragHandle players={getEffectivelySelectedPlayers()}/>,
            onDragStart: dataTransfer => {
              maybeUpdatePlayerSelection()
              dataTransfer.setData(assignedPlayerDragMimeType, "")
              dataTransfer.effectAllowed = "move"
              dataTransfer.dropEffect = "move"
              globalTeamAssignmentsDragData.assignedPlayersBeingDragged = {originTeam: team, players: getEffectivelySelectedPlayers()};
              return true;
            },
            onLeaveOrEnd,
            onDrop: () => {
              props.selectedPlayerIDs.clear()
            }
          }
        case "unassigned-listing":
          // fallthrough
        case "unassigned-listing/loan":
          return {
            dragHandleJsxFunc: () => <DragHandle players={getEffectivelySelectedPlayers()}/>,
            onDragStart: dataTransfer => {
              maybeUpdatePlayerSelection()
              dataTransfer.setData(unassignedPlayerDragMimeType, "")
              dataTransfer.effectAllowed = "move"
              dataTransfer.dropEffect = "move"
              globalTeamAssignmentsDragData.unassignedPlayersBeingDragged = getEffectivelySelectedPlayers();
              return true;
            },
            onLeaveOrEnd,
            onDrop: () => {
              props.selectedPlayerIDs.clear()
            }
          }
        default: exhaustiveCaseGuard(props.mode);
      }
    }

    const sortableCols = {...wellKnownCols} as const;

    /**
     * This list is mutable and will be extended with custom questions as necessary,
     * where the `id` of the sorter is the questionID
     */
    const sortState = ref(freshSortState<PlayerForTeamAssignmentViewEx, string>([
      {
        id: sortableCols.status,
        sort: sortByMany(
          sortBy(_ => _.clientData.type === "assignment" ? 1 : 2),
          sortBy(
            _ => {
              switch (_.type) {
                case "assigned-but-tentatively-unassigned":
                  return 0
                case "unassigned":
                  return 1
                case "tentatively-assigned":
                  return 2
                case "assigned-but-tentatively-moved":
                  return 3
                case "assigned":
                  return 4
                default: exhaustiveCaseGuard(_)
              }
            }
          ),
        )
      },
      {
        id: sortableCols.playerName,
        sort: sortByMany(
          (l,r) => accentAwareCaseInsensitiveCompare(l.apiData.child.playerLastName, r.apiData.child.playerLastName),
          (l,r) => accentAwareCaseInsensitiveCompare(l.apiData.child.playerFirstName, r.apiData.child.playerFirstName)
        ),
      },
      {
        id: sortableCols.ratingAvg,
        sort: sortBy(_ => parseFloatOr(_.apiData.registration.ratingAvg, 0))
      },
      {
        id: sortableCols.newestRating,
        sort: sortBy(_ => parseFloatOr(_.apiData.registration.ratingRecent, 0)),
      },
      {
        id: sortableCols.dob,
        sort: sortByDayJS(_ => _.apiData.child.playerBirthDate)
      },
      {
        id: sortableCols.regDate,
        sort: sortByDayJS(_ => _.apiData.registration.dateCreated),
      },
      {
        id: sortableCols.height,
        sort: sortBy(_ => parseFloatOr(_.apiData.registration.playerHeight, 0))
      },
      {
        id: sortableCols.weight,
        sort: sortBy(_ => parseFloatOr(_.apiData.registration.playerWeight, 0)),
      },
      {
        id: sortableCols.grade,
        sort: sortBy(_ => {
          const grade = _.apiData.registration.grade;
          return registrationGradeOrdering(grade)
        })
      },
      {
        id: sortableCols.school,
        sort: sortBy(_ => _.apiData.registration.playerSchool)
      },
      {
        id: sortableCols.waitlisted,
        sort: sortBy(_ => Number(_.apiData.registration.WL == 1))
      },
      {
        id: sortableCols.city,
        sort: sortBy(_ => _.apiData.child.parent1City)
      },
      {
        id: sortableCols.zip,
        sort: sortBy(_ => _.apiData.child.parent1Zip)
      },
      {
        id: sortableCols.street,
        sort: sortBy(_ => `${_.apiData.child.parent1Street} ${_.apiData.child.parent1Street2}`)
      },
      {
        id: sortableCols.memberOfCoachesFamily,
        sort: sortBy(_ => {
          return props.mode === "unassigned-listing"
            ? (_.apiData.registration.hasFamiliallyRelatedCoachesOnTheseTeams.length > 0) ? 0 : 1
            : _.apiData.registration.hasFamiliallyRelatedCoachesOnTheseTeams.find(teamish => props.team?.team.teamID === teamish.teamID) ? 0 : 1
        })
      },
      {
        id: sortableCols.noPracticeDay,
        sort: sortBy(_ => _.apiData.registration.noPracticeDay)
      }
    ]))

    // Hm, well I guess we could just add ALL questionIDs from the initial mount.
    // But then we'd still need to watch for updates? However, all the possible questionIDs are resolved prior to mount?
    watch(() => props.selectedQuestions.customQuestionIDs, () => {
      for (const questionID of props.selectedQuestions.customQuestionIDs) {
        installSorterIfNecessary(questionID);
      }

      /**
       * Note we don't have "delete sorter (if necessary)" anywhere because "unused" sorters aren't a problem,
       * we simply stop referencing them.
       */
      function installSorterIfNecessary(questionID: Guid) : void {
        if (sortState.value.sortersByColID[questionID]) {
          return;
        }
        else {
          // yes, find or fail -- we shouldn't be getting invoked if there's no questions (where did we get the questionID from if there are no questions?)
          const q = arrayFindOrFail(props.registrationQuestions, q => q.id === questionID);
          sortState.value.addSorter(q.id, (lPlayer, rPlayer) => {
            // TODO: particular comparators based on question types
            const lAnswer = props.registrationQuestionAnswers[lPlayer.apiData.child.childID]?.find(a => a.questionID === questionID)?.answer || "";
            const rAnswer = props.registrationQuestionAnswers[rPlayer.apiData.child.childID]?.find(a => a.questionID === questionID)?.answer || "";
            return lAnswer < rAnswer ? -1 : lAnswer === rAnswer ? 0 : 1;
          });
        }
      }
    }, {deep:true, immediate: true});

    // default initial sort
    sortState.value.reconfigure([{colID: sortableCols.playerName, dir: "asc"}])

    const getSortDirOrAsc = (colID: string) => {
      return sortState.value.sortersByColID[colID]?.dir || "asc"
    }

    const SortArrow = ({sortID}: {sortID: string}) => {
      return <SortArrowImpl dir={getSortDirOrAsc(sortID)} onClick={() => sortState.value.sortersByColID[sortID]?.sortAndPrioritize()}/>
    }

    // uh ... virtualScroller does something dumb with the indexes ... and we need them to zebra stripe rows
    // we always need to sort, but we tack on a "dense index" here too (index of items after having been filtered)
    const sortedPlayersWithDenseIdx = computed(() => {
      return sortState.value.sort([...props.players]).map((v,i) => ({idx: i, ...v}))
    })

    watch(() => props.players, () => {
      // if the provided list of players changes, only retain local selections for players that remain in the list
      // investigate: this is sort of weird, if all the values were using here are props, why are we computing this here?
      // the props aren't owned by us so why would they be out of sync?
      props.selectedPlayerIDs.intersect(props.players.map(v => v.apiData.child.childID));
    })

    const headerCellClasses = "align-top"
    const headerCellContentClasses = "flex gap-1 items-start py-[.125em] px-[.125em]"
    const headerCellLabelStyle = "margin-top:3px;";

    // we had been rendering in <TransitionRoot>, and this would be used to flash all the cells in a row when they change,
    // but with a virtual scroll that doesn't really work too well.
    const CellFlasher = () => <div style={{
      transition: `background-color 400ms cubic-bezier(0.000, 0.0, 0.0, 1.000)`,
      pointerEvents: "none",
      position: `absolute`,
      width: `100%`,
      height: `100%`,
      top: `0`,
      left: `0`,
      backgroundColor: `var(--il-cellflasher-bg)`,
    }}></div>

    const cssUnit_leftMostWidth = "1in"
    const cssUnit_rowWidth = computed<{[K in keyof typeof wellKnownCols]: string}>(() => {
      return {
        // the "already assigned" listing needs room for the "loan expires" input in the status column
        status: props.mode === "already-assigned-listing" ? "2.5in" : "1.5in",
        playerName: "3in",
        ratingAvg: "1.5in",
        newestRating: "1.5in",
        dob: "1.5in",
        regDate: "2in",
        height: "1.5in",
        weight: "1.5in",
        grade: "1.5in",
        school: "2in",
        waitlisted: "2in",
        city: "2in",
        zip: "2in",
        street: "2in",
        memberOfCoachesFamily: "2in",
        noPracticeDay: "1in",
        comments: "4in",
      };
    })

    const cssUnit_questionAnswerWidth = "2in"

    // we wanted a popover, but it gets cut off inside the offsetParent of the virtual scroll container ...
    // best we can do for now
    const gradesFilterModalController = reactive((() => {
      return DefaultModalController<void>({
        title: () => (
          <>
            <div>Player grade</div>
            <div class="border-b my-2"/>
          </>
        ),
        content: () => {
          if (!props.filter) {
            // shouldn't happen
            return null
          }
          return <div style="max-height: 50vh; overflow-y:auto; --fk-border:none;">
            <div>
              <Btn2 disabled={props.filter.grades.length === 0} class="px-2 py-1" onClick={() => {props.filter!.grades = []}}>Clear</Btn2>
            </div>
            <FormKit type="checkbox" options={wellKnownRegistrationGrades()} v-model={props.filter.grades}/>
          </div>
        }
      })
    })());

    const zipCodeFilterModalController = reactive((() => {
      const zipCodeOptions = computed(() => {
        // zip codes, de-duplicated, sorted (lexical sort on zipcode strings is fine for this)
        const uniqueSorted = [...new Set(props.players_unfiltered.map(v => v.apiData.child.parent1Zip))].sort()
        return uniqueSorted.map(v => ({label: v, value: v}))
      });

      return DefaultModalController<void>({
        title: () => (
          <>
            <div>Zip Codes</div>
            <div class="border-b my-2"/>
          </>
        ),
        content: () => {
          if (!props.filter) {
            // shouldn't happen
            return null
          }
          return <div style="max-height: 50vh; overflow-y:auto; --fk-border:none;">
            <div>
              <Btn2 disabled={props.filter.zip.length === 0} class="px-2 py-1" onClick={() => {props.filter!.zip = []}}>Clear</Btn2>
            </div>
            <FormKit type="checkbox" options={zipCodeOptions.value} v-model={props.filter.zip}/>
          </div>
        }
      })
    })());

    return () => {
      return (
        <div class="w-full overflow-auto" style="--fk-margin-outer:none; --fk-padding-input:.3em; --fk-bg-input:white;">
          <AutoModal controller={gradesFilterModalController}/>
          <AutoModal controller={zipCodeFilterModalController}/>
          <style>
            {`
              .R_TeamAssignments div.row {
                display:inline-flex;
              }
              .R_TeamAssignments div.header.row .cell {
                --il-header-border: #AAA;
                border-top: 1px solid var(--il-header-border);
                border-left: 1px solid var(--il-header-border);
                border-bottom: 1px solid var(--il-header-border);
              }
              .R_TeamAssignments div.header.row .cell:last-child {
                border-right: 1px solid var(--il-header-border);
              }
              .R_TeamAssignments div.body.row .cell {
                display:flex;
                align-items:center;
                padding: 0 .25em;
              }
              .R_TeamAssignments .vue-recycle-scroller__slot:first-child {
                top:0;
                position:sticky;
                z-index:1;
                background-color:white;
              }

              .R_TeamAssignments .vue-recycle-scroller__item-wrapper {
                  overflow: visible !important;
              }
              .R_TeamAssignments .vue-recycle-scroller {
                  overflow: auto !important;
              }
            `}
          </style>
          <div style="width:100%;">
            <div class="R_TeamAssignments w-full relative">
              <DynamicScroller
                style="max-height:50vh; overflow:auto;"
                items={sortedPlayersWithDenseIdx.value}
                minItemSize={40}
              >
                {{
                  after: () => {
                    if (props.players.length === 0) {
                      // todo: render this at the "absolute" center of the viewport container, disregarding current viewport scroll position
                      return (
                        <div class="text-lg w-full h-full mt-4" style="z-index:1;">
                          {props.mode === "already-assigned-listing"
                            ? <div class="p-4">There are no players currently assigned to this team.</div>
                            : props.mode === "unassigned-listing"
                            ? props.players_unfiltered.length === 0
                              ? <div class="p-4">There are no unassigned players.</div>
                              : <div class="p-4">
                                  <div>No matching players.</div>
                                  {props.hasSomeNonEmptyFilters
                                    ? <Btn2 class="p-1" onClick={() => emit("clearFilters")}>Clear filters</Btn2>
                                    : null}
                                </div>
                            : props.mode === "unassigned-listing/loan"
                            ? <div>No players found.</div>
                            : exhaustiveCaseGuard(props.mode)
                          }
                        </div>
                      )
                    }
                    else {
                      return null
                    }
                  },
                  before: () => {
                    return (
                      <div class="header row" style={{...rowBgColorStyle(0), position: "sticky", top: 0, backgroundColor: "white", zIndex: 1}}>
                        <div class="cell" style={{width: cssUnit_leftMostWidth}}>{/*drag handle, checkboxes, etc.*/}</div>
                        {
                          props.showTentativeAssignmentInfo
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.status}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.status}/>
                                  <span style={[headerCellLabelStyle, "padding-right:.25em;"]}>Status</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.playerName}}>
                          <div class={headerCellContentClasses}>
                            <SortArrow sortID={sortableCols.playerName}/>
                            <span style={headerCellLabelStyle}>Player Name</span>
                          </div>
                          {props.filter
                            ? <div class="w-full p-1 font-normal flex gap-1 items-center">
                              <input type="text" class="text-xs min-w-0 flex-grow rounded-md p-1" v-model={props.filter.playerName}/>
                              <button type="button" class={`${!props.filter.playerName ? "invisible" : ""} cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-1 rounded-md`} onClick={() => {props.filter!.playerName = ""}}>
                                <X width=".825em" height=".825em"/>
                              </button>
                            </div>
                            : null}
                        </div>
                        {
                          props.selectedQuestions.wellKnown.ratingAvg
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.ratingAvg}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.ratingAvg}/>
                                  <span style={headerCellLabelStyle}>Rating Avg</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.newestRating
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.newestRating}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.newestRating}/>
                                  <span style={headerCellLabelStyle}>Newest Rating</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.dob
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.dob}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.dob}/>
                                  <span style={headerCellLabelStyle}>DOB</span>
                                </div>
                                {props.filter
                                  ? <div class="w-full p-1 font-normal flex gap-1 items-center">
                                    <input type="date" class="text-xs min-w-0 flex-grow rounded-md p-1" v-model={props.filter.dob}/>
                                    <button type="button" class={`${!props.filter.dob ? "invisible" : ""} cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-1 rounded-md`} onClick={() => {props.filter!.dob = ""}}>
                                      <X width=".825em" height=".825em"/>
                                    </button>
                                  </div>
                                  : null}
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.regDate
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.regDate}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.regDate}/>
                                  <span style={headerCellLabelStyle}>Reg Date</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.height
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.height}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.height}/>
                                  <span style={headerCellLabelStyle}>Height</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.weight
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.weight}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.weight}/>
                                  <span style={headerCellLabelStyle}>Weight</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.grade
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.grade}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.grade}/>
                                  <span style={headerCellLabelStyle}>Grade</span>
                                </div>
                                {props.filter
                                  ? <div class="p-1 items-center">
                                      <Btn2 class="text-sm p-1 px-2 flex gap-1 items-center" onClick={() => gradesFilterModalController.open()}>
                                        {props.filter.grades.length > 0
                                          ? <FontAwesomeIcon icon={faFilterCircleXmark}/>
                                          : null}
                                        Grades...
                                      </Btn2>
                                  </div>
                                  : null}
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.school
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.school}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.school}/>
                                  <span style={headerCellLabelStyle}>School</span>
                                </div>
                                {props.filter
                                  ? <div class="p-1 font-normal flex gap-1 items-center">
                                    <input type="text" class="min-w-0 text-xs flex-grow rounded-md p-1" v-model={props.filter.school}/>
                                    <button type="button" class={`${!props.filter.school ? "invisible" : ""} cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-1 rounded-md`} onClick={() => {props.filter!.school = ""}}>
                                      <X width=".825em" height=".825em"/>
                                    </button>
                                  </div>
                                  : null}
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.waitlisted
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.waitlisted}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.waitlisted}/>
                                  <span style={headerCellLabelStyle}>Waitlisted</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.city
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.city}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.city}/>
                                  <span style={headerCellLabelStyle}>City</span>
                                </div>
                                {props.filter
                                  ? <div class="p-1 font-normal flex gap-1 items-center">
                                    <input type="text" class="min-w-0 text-xs flex-grow rounded-md p-1" v-model={props.filter.city}/>
                                    <button type="button" class={`${!props.filter.city ? "invisible" : ""} cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-1 rounded-md`} onClick={() => {props.filter!.city = ""}}>
                                      <X width=".825em" height=".825em"/>
                                    </button>
                                  </div>
                                  : null}
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.zip
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.zip}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.zip}/>
                                  <span style={headerCellLabelStyle}>Zip</span>
                                </div>
                                {props.filter
                                  ? <div class="p-1 items-center">
                                      <Btn2 class="text-sm p-1 px-2 flex gap-1 items-center" onClick={() => zipCodeFilterModalController.open()}>
                                        {props.filter.zip.length > 0
                                          ? <FontAwesomeIcon icon={faFilterCircleXmark}/>
                                          : null}
                                        Zip Codes...
                                      </Btn2>
                                  </div>
                                  : null}
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.street
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.street}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.street}/>
                                  <span style={headerCellLabelStyle}>Street</span>
                                </div>
                                {props.filter
                                  ? <div class="p-1 font-normal flex gap-1 items-center">
                                    <input type="text" class="min-w-0 text-xs flex-grow rounded-md p-1" v-model={props.filter.street}/>
                                    <button type="button" class={`${!props.filter.street ? "invisible" : ""} cursor-pointer hover:bg-[rgba(0,0,0,.0625)] active:bg-[rgba(0,0,0,.125)] p-1 rounded-md`} onClick={() => {props.filter!.street = ""}}>
                                      <X width=".825em" height=".825em"/>
                                    </button>
                                  </div>
                                  : null}
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.memberOfCoachesFamily
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.memberOfCoachesFamily}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.memberOfCoachesFamily}/>
                                  <span style={headerCellLabelStyle}>Member of Coach's Family</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.comments
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.comments}}>
                                <div class={headerCellContentClasses}>
                                  <span style={headerCellLabelStyle}>Comments</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.wellKnown.noPracticeDay
                            ? (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_rowWidth.value.noPracticeDay}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={sortableCols.noPracticeDay}/>
                                  <span style={headerCellLabelStyle}>No Practice Day</span>
                                </div>
                              </div>
                            )
                            : null
                        }
                        {
                          props.selectedQuestions.customQuestionIDs.map(questionID => {
                            const q = arrayFindOrFail(props.registrationQuestions, q => q.id === questionID).shortLabel;
                            return (
                              <div class={[headerCellClasses, "cell"]} style={{width: cssUnit_questionAnswerWidth}}>
                                <div class={headerCellContentClasses}>
                                  <SortArrow sortID={questionID}/>
                                  <span style={headerCellLabelStyle}>{q}</span>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  },
                  default: ({item: player, index, active}) => {
                    return (
                      <DynamicScrollerItem
                        key={`dynitem/${player.apiData.child.childID}`}
                        item={player}
                        active={active}
                        data-index={index}
                      >
                        {/* bg style on "row" to extend all the way to end of scroll container, not just outer visual container*/}
                        {/* bg style on absolute inner element to extend all the way to end of visual container in case row does not extend that far*/}
                        <div key={player.apiData.child.childID} class="body row" style={rowBgColorStyle(player.idx)} data-test={`playerID=${player.apiData.child.childID}`}>
                          <div class="absolute w-full h-full bg-yellow-700" style={rowBgColorStyle(player.idx)}></div>
                          <div class="cell relative" style={{width: cssUnit_leftMostWidth}}>
                            <CellFlasher/>
                            <span class="flex items-center justify-center" style="--fk-margin-outer:2px; --fk-margin-decorator: none;">
                              {
                                props.busyByPlayerID.has(player.apiData.child.childID)
                                  ? <span class="px-2"><SoccerBall color={Client.value.clientTheme.color} width="1.25em" height="1.25em"/></span>
                                  : (
                                    <span class="flex items-center">
                                      {
                                        props.showDragGrip && !player.clientData.disableAssignOrLoanDueToMissingBirthCert
                                          ? (
                                            <div v-ilDraggable={draggableConfig(player)} class="flex flex-col items-center" data-test="dragHandle">
                                              <span
                                                class="p-2 cursor-grab hover:bg-[rgb(0,0,0,.0625)] rounded-md text-center"
                                              >
                                                <FontAwesomeIcon icon={faGripDotsVertical}/>
                                              </span>
                                            </div>
                                          )
                                          : null
                                      }
                                      {
                                        props.mode === "already-assigned-listing"
                                          ? (() => {
                                            const disabled = player.clientData.type === "loan" && player.type === "assigned-but-tentatively-unassigned"
                                            return <button type="button" disabled={disabled}
                                              class="p-1 cursor-pointer hover:bg-[rgb(0,0,0,.0625)] rounded-md text-center relative" onClick={() => {
                                              // if player X becomes selected, when players X,Y,Z are already selected -- maintain X,Y,Z
                                              // if player X becomes selected, when players Y,Z are already selected -- remove Y,Z and only select X
                                              if (!props.selectedPlayerIDs.has(player.apiData.child.childID)) {
                                                props.selectedPlayerIDs.clear()
                                                props.selectedPlayerIDs.add(player.apiData.child.childID)
                                              }

                                              // Does this do the right thing in the "restore to original state" case?
                                              emit("deleteAssignments", {players: selectedPlayers.value});
                                            }}>
                                              <FontAwesomeIcon
                                                icon={player.clientData.type === "assignment" && isTentativeAssignment(player) ? faUndo : faTrash}
                                                {...{style:"outline:none;"}}
                                                v-tooltip={{
                                                  content: player.clientData.type === "assignment"
                                                    ? isTentativeAssignment(player)
                                                      ? "Restore to original state"
                                                      : "Unassign"
                                                    : "Delete loan"
                                                }}
                                              />
                                              {
                                                disabled
                                                  ? <div class="absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,.75)]"></div>
                                                  : null
                                              }
                                            </button>
                                          })()
                                          : null
                                      }
                                    </span>
                                  )
                              }
                              {
                                !player.clientData.disableAssignOrLoanDueToMissingBirthCert && supportMultiPlayerSelect && !hasSomeBusyPlayerID.value
                                  ? (
                                    <div class="text-xs mx-1">
                                      <input class="rounded-lg" type="checkbox"
                                        data-test="rowCheck"
                                        checked={props.selectedPlayerIDs.has(player.apiData.child.childID)}
                                        onInput={() => props.selectedPlayerIDs.invert(player.apiData.child.childID)}
                                      />
                                    </div>
                                  )
                                  : null
                              }
                              {
                                player.clientData.disableAssignOrLoanDueToMissingBirthCert
                                  ? <span class="ml-1 text-xs">(BC)</span>
                                  : null
                              }
                            </span>
                          </div>
                          {
                            props.showTentativeAssignmentInfo
                              ? (
                                <div class={[
                                  `cell relative`,
                                  isTentativeAssignment(player)
                                    ? "bg-yellow-100"
                                    : undefined
                                ]} style={{width: cssUnit_rowWidth.value.status}}>
                                  <div>
                                    <CellFlasher/>
                                    {
                                      player.type === "unassigned"
                                        ? <div class="text-sm">Unassigned</div>
                                        : player.type === "tentatively-assigned"
                                        ? (
                                          <div>
                                            <div class="text-sm">Tentatively {player.clientData.type === "assignment" ? "assigned" : "loaned"}</div>
                                          </div>
                                        )
                                        : player.type === "assigned"
                                        ? <div class="text-sm">{player.apiData.assignment.baseType === "assignment"
                                                             ? player.apiData.assignment.dateAssigned
                                                               ? `Assigned on ${dayjs(player.apiData.assignment.dateAssigned).format("M/DD/YYYY")}`
                                                               : "Assigned"
                                                             : `Loaned on ${dayjs(player.apiData.assignment.dateLoaned).format("M/DD/YYYY")}`
                                                           }</div>
                                        : player.type === "assigned-but-tentatively-moved"
                                        ? (
                                          <div>
                                            <div class="text-xs">
                                              {player.clientData.type === "loan" ? `Loan tentatively moved from` : `Tentatively moved from:`}
                                            </div>
                                            <div class="text-sm">{player.apiData.assignment.teamName || player.apiData.assignment.team}</div>
                                          </div>
                                        )
                                        : player.type === "assigned-but-tentatively-unassigned"
                                        ? (
                                          <div>
                                            {
                                              player.clientData.type === "loan"
                                                // in the loan case, deleted ("assigned but tentatively unassigned") loans will remain in place on the team they are being removed from.
                                                // Also note that in the loan coase, an "unassigned" loan will always be paired to particular team, there is
                                                // not an "unassigned loan" pool that is not associated with some team (as there is with assignments).
                                                ? (
                                                  <>
                                                    <div class="text-xs">Loan tentatively deleted</div>
                                                    <div class="text-xs il-link" onClick={() => emit("undeleteLoans", {players: [player]})}>No, don't delete</div>
                                                  </>
                                                )
                                                : (
                                                  // in the non-loan case, "unassigned" things will be in the unassigned player pool where it makes sense
                                                  // to orient the user with where this thing came from
                                                  <div class="text-xs">
                                                    <div>Tentatively removed from</div>
                                                    <div>{player.apiData.assignment.teamName || player.apiData.assignment.team}</div>
                                                    <div class="text-xs il-link" onClick={() => emit("undeletePlayers", {players: [player]})}>Restore to original</div>
                                                  </div>
                                                )
                                            }
                                          </div>
                                        )
                                        :null
                                    }
                                    {
                                      player.clientData.type === "loan"
                                        ? (
                                          <div class="flex flex-col gap-1 mt-1">
                                            {
                                              (() => {
                                                const clientData = player.clientData;
                                                const existingLoan = player.apiData.assignment;

                                                const {
                                                  offerRestoreExpDate,
                                                  dateInputDisabled,
                                                  dateRestorer
                                                } = (() => {
                                                  if (existingLoan) {
                                                    assertIs(existingLoan?.baseType, "loan")
                                                    const pristineExpDate = dayjs(existingLoan.expirationDate);
                                                    return {
                                                      offerRestoreExpDate: pristineExpDate.isValid() && !pristineExpDate.isSame(dayjs(Optional.getOr(clientData.expirationDate, null)), "minute"),
                                                      dateInputDisabled: player.type === "assigned-but-tentatively-unassigned",
                                                      dateRestorer: () => {clientData.expirationDate = Optional.of(pristineExpDate?.format(DAYJS_FORMAT_HTML_DATETIME))},
                                                    }
                                                  }
                                                  else {
                                                    return {
                                                      offerRestoreExpDate: false,
                                                      dateInputDisabled: false,
                                                      dateRestorer: null,
                                                    }
                                                  }
                                                })();

                                                return <div>
                                                  <div class="flex gap-1 items-center text-xs">
                                                    <input
                                                      disabled={dateInputDisabled}
                                                      type="checkbox"
                                                      checked={clientData.expirationDate.type === "some"}
                                                      onInput={() => {
                                                        if (clientData.expirationDate.type === "some") {
                                                          clientData.expirationDate = {type: "none"}
                                                        }
                                                        else {
                                                          clientData.expirationDate = {type: "some", value: ""}
                                                        }
                                                      }}
                                                    />
                                                    <div class="text-xs">Loan expires</div>
                                                  </div>
                                                  {
                                                    clientData.expirationDate.type === "some"
                                                      ? (
                                                        <div class="my-1" style="--fk-max-width-input:none;">
                                                          <FormKit type="datetime-local"
                                                            v-model={clientData.expirationDate.value}
                                                            disabled={dateInputDisabled}
                                                            validation={[["required"]]}
                                                            // If we scroll this out of the dom (because of virtual scroll),
                                                            // it is not going to be properly validated on form submit.
                                                            // An incomplete solution is to tell the user immediately that this is not in a valid state,
                                                            // hopefully they don't scroll it away.
                                                            // TODO: validate all form elements on form submit, regardless of presence in DOM.
                                                            validationVisibility="live"
                                                            validationMessages={{required: "Expiration date is required"}}
                                                          />
                                                          {
                                                            !dateInputDisabled && dateRestorer
                                                              ? <div class={[`mt-1 text-xs il-link`, offerRestoreExpDate ? "" : "hidden"]} onClick={dateRestorer}>Restore expiration</div>
                                                              : null
                                                          }
                                                        </div>
                                                      )
                                                      : null
                                                  }
                                                </div>
                                              })()
                                            }
                                          </div>
                                        )
                                        : null
                                    }
                                  </div>
                                </div>
                              )
                              : null
                          }
                          <div class="cell relative" style={{width: cssUnit_rowWidth.value.playerName}}>
                            <CellFlasher/>
                            {player.apiData.child.playerFirstName} {player.apiData.child.playerLastName}
                          </div>
                          {
                            props.selectedQuestions.wellKnown.ratingAvg
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.ratingAvg}}>
                                  <CellFlasher/>
                                  {player.apiData.registration.ratingAvg}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.newestRating
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.newestRating}}>
                                  <CellFlasher/>
                                  {player.apiData.registration.ratingRecent}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.dob
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.dob}}>
                                  <CellFlasher/>
                                  {dayjsFormatOr(player.apiData.child.playerBirthDate, "MMM/DD/YYYY")}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.regDate
                              ? (
                                // We're using "dateCreated" as date-of-registration,
                                // but there are many program registrations.
                                // Do we know when one (or "the relevant one") was activated?
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.regDate}}>
                                  <CellFlasher/>
                                  {dayjsFormatOr(player.apiData.registration.dateCreated, "MMM/DD/YYYY")}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.height
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.height}}>
                                  <CellFlasher/>
                                  {player.apiData.registration.playerHeight}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.weight
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.weight}}>
                                  <CellFlasher/>
                                  {player.apiData.registration.playerWeight}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.grade
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.grade}}>
                                  <CellFlasher/>
                                  {player.apiData.registration.grade}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.school
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.school}}>
                                  <CellFlasher/>
                                  {player.apiData.registration.playerSchool}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.waitlisted
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.waitlisted}}>
                                  <CellFlasher/>
                                  {/*see notes on `programRegistrations` property*/}
                                  {/*if any of the (potentially many) relevant program registrations are waitlisted, output "yes"*/}
                                  {!!player.apiData.registration.programRegistrations.find(v => v.waitlist) ? "Yes" : "No"}
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.city
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.city}}>
                                  <CellFlasher/>
                                  <div>{player.apiData.child.parent1City}</div>
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.zip
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.zip}}>
                                  <CellFlasher/>
                                  <div>{player.apiData.child.parent1Zip}</div>
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.street
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.street}}>
                                  <CellFlasher/>
                                  <div>
                                    <div>{player.apiData.child.parent1Street}</div>
                                    <div>{player.apiData.child.parent1Street2}</div>
                                  </div>
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.memberOfCoachesFamily
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.memberOfCoachesFamily}}>
                                  <CellFlasher/>
                                  {
                                    props.mode === "unassigned-listing"
                                      ? emptyIsNull(player.apiData.registration.hasFamiliallyRelatedCoachesOnTheseTeams)?.map(teamish => teamish.teamName).join(", ") || "No"
                                      : player.apiData.registration.hasFamiliallyRelatedCoachesOnTheseTeams.find(teamish => props.team?.team.teamID === teamish.teamID) ? "Yes" : "No"
                                  }
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.wellKnown.comments
                            ? (
                              <div class="cell relative" style={{width: cssUnit_rowWidth.value.comments}}>
                                <CellFlasher/>
                                <div class="whitespace-break-spaces">{player.apiData.registration.comments}</div>
                              </div>
                            )
                            : null
                          }
                          {
                            props.selectedQuestions.wellKnown.noPracticeDay
                              ? (
                                <div class="cell relative" style={{width: cssUnit_rowWidth.value.noPracticeDay}}>
                                  <CellFlasher/>
                                  <div class="whitespace-break-spaces">{player.apiData.registration.noPracticeDay}</div>
                                </div>
                              )
                              : null
                          }
                          {
                            props.selectedQuestions.customQuestionIDs.map(questionID => {
                              const a = props.registrationQuestionAnswers[player.apiData.child.childID]?.find(a => a.questionID === questionID)?.answer || "(no answer)";
                              return <div class="cell relative" style={{width: cssUnit_questionAnswerWidth}}>
                                <CellFlasher/>
                                {a}
                              </div>
                            })
                          }
                        </div>
                      </DynamicScrollerItem>
                    )
                  }
                } satisfies DynamicScrollerSlots<(typeof sortedPlayersWithDenseIdx.value)[number]>}
              </DynamicScroller>
            </div>
          </div>
        </div>
      )
    }
  }
})

function emptyIsNull<T>(vs: T[]) : T[] | null {
  return vs.length === 0 ? null : vs;
}

const SortArrowImpl = defineComponent({
  props: {
    dir: vReqT<SortDir | "not-sorted">(),
  },
  emits: {
    click : () => true,
  },
  setup(props, {emit}) {
    return () => (
      <span class={"p-1 rounded-md hover:bg-[rgb(0,0,0,.06125)] active:bg-[rgb(0,0,0,.125)] cursor-pointer"} onClick={() => emit("click")}>
        <FontAwesomeSortArrow dir={props.dir}/>
      </span>
    )
  }
})

export type DragHandleProps = {players: PlayerForTeamAssignmentViewEx[]}
