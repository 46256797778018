import { computed, defineComponent, Ref, ref, StyleValue, watch } from "vue";
import { CalendarUiState, DateFieldLayoutable, k_dayGroupKeyFormat, teamDesignationAndMaybeName } from "./GameScheduler.shared";
import { assertNonNull, assertTruthy, exhaustiveCaseGuard, gatherByKey_manyPerKey, nextGlobalIntlike, parseIntOrFail, rangeInc, requireNonNull, sortByDayJS, UiOption, UiOptions, unreachable, vReqT } from "src/helpers/utils";
import { listPracticeSlotAssignments, listPracticeSlots, MinDivision, MinTeam, PracticeSchedulerSeason, PracticeSchedulerTeamInfo, PracticeSlot, PracticeSlotAssignment } from "./PracticeScheduler.io";
import { Datelike, Division, Guid, Integerlike, TeamID } from "src/interfaces/InleagueApiV1";
import dayjs, { Dayjs } from "dayjs";
import { Field } from "src/composables/InleagueApiV1";
import * as cal from "./CalendarLayout"
import { Btn2, btn2_redEnabledClasses } from "src/components/UserInterface/Btn2";
import { AlreadyHasARecurringAssignmentThisSeasonTeam, computeStartWeekOptions, defaultSeasonDateRange, MutUiSelection, SlotAssignmentUserBinding, SlotSchedulingDataStore } from "./PracticeSchedulerActions";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronLeft, faChevronRight, faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { ReactiveReifiedPromise } from "src/helpers/ReifiedPromise";
import { axiosAuthBackgroundInstance } from "src/boot/AxiosInstances";
import { SoccerBall } from "src/components/SVGs";
import { FormKit } from "@formkit/vue";
import { DAYJS_FORMAT_HTML_DATE, dayjsOr } from "src/helpers/formatDate";
import { makeTabDefMapping, TabDef, Tabs } from "src/components/UserInterface/Tabs";
import { Client } from "src/store/Client";
import { SelectMany } from "src/components/RefereeSchedule/SelectMany";
import { FkDynamicValidation } from "src/helpers/FkUtils";

export interface ConfirmPracticeSlotAssignmentsEvent {
  practiceSlotIDs: number[],
  practiceSlotAssignmentPermitID: number | null
}

export const ConfirmPracticeSlotAssignmentsModal = defineComponent({
  props: {
    mode: vReqT<"self" | "admin">(),
    slot: vReqT<PracticeSlotEx>(),
    teamInfo: vReqT<MinTeam>(),
    hasExistingRecurringSeasonalSelection: vReqT<boolean>(),
    availableOneOffPermits: vReqT<{practiceSlotAssignmentPermitID: number}[]>(),
  },
  emits: {
    cancel: () => true,
    commit: (_: ConfirmPracticeSlotAssignmentsEvent) => true,
  },
  setup(props, ctx) {
    /**
     * To __include__ the one provided via props.
     * If the props-provided slot has no associated slots, then just the props-provided slot.
     */
    const associatedSlotsResolver = ReactiveReifiedPromise<(PracticeSlot | PracticeSlotEx)[]>(async () => {
      if (props.slot.practiceSlotGroupID === null) {
        return props.availableOneOffPermits.length > 0
          ? [props.slot]
          : [] // bug in parent element, shouldn't have gotten here -- this is a one-off slot, but there are no available permits
      }
      else {
        return !props.hasExistingRecurringSeasonalSelection
          ? await listPracticeSlots(axiosAuthBackgroundInstance, {
            seasonUID: props.slot.seasonUID,
            practiceSlotGroupID: props.slot.practiceSlotGroupID,
            includeDeleted: false
          }).then(v => {
            // n.b. should contain the props-provided slot because this is the list of ALL slots sharing this group
            return v
              // n.b. remove those that are already full, in case there are some recurrence groups where people have already picked a few slots out of it, which can happen in the case of someone using a permit to do a one-off signup
              // But also this can be confusing if user clicks on a slot to open this modal, and at the moment they do that, someone else signs up for it, so they clicked on a thing that was
              // available and then we load this and it's no longer available.
              // So, we drop those group members that are atCapacity; but, retain "this slot" even if "this slot" is atCapacity.
              // If we try to sign up for this slot and it is at capacity we should get an error from the backend.
              .filter(v => v.practiceSlotID === props.slot.practiceSlotID || !v.atCapacity)
              .sort(sortByDayJS(v => v.start))
          })
          : props.availableOneOffPermits.length > 0
          ? [props.slot] // already has a recurring selection, but they do have a permit for one-offs, so we can allow them to signup for just this one ... we might want to enforce that permits can only signup for non-recurring
          : [] // bug in parent element, shouldn't have gotten here -- this is a recurring slot, but the user has already made their "recurring seasonal selection"
      }
    }, {defaultDebounce_ms: 250})

    const commit = (slots: (PracticeSlot | PracticeSlotEx)[]) => {
      assertTruthy(slots.length > 0)
      if (props.hasExistingRecurringSeasonalSelection) {
        assertTruthy(props.availableOneOffPermits.length > 0)
        assertTruthy(slots.length === 1)
        ctx.emit("commit", {practiceSlotIDs: slots.map(v => v.practiceSlotID), practiceSlotAssignmentPermitID: props.availableOneOffPermits[0].practiceSlotAssignmentPermitID})
      }
      else {
        ctx.emit("commit", {practiceSlotIDs: slots.map(v => v.practiceSlotID), practiceSlotAssignmentPermitID: null})
      }
    }

    return () => {
      switch (associatedSlotsResolver.underlying.status) {
        case "idle": return null
        case "pending": {
          return <div class="flex items-center gap-2">
            <SoccerBall/>
            Loading...
          </div>
        }
        case "error": {
          return <div>Sorry, something went wrong.</div>
        }
        case "resolved": {
          const slots = associatedSlotsResolver.underlying.data
          return <div>
            <div class="my-2">Sign up for the following {slots.length === 1 ? "slot" : "slots"}:</div>
            <div class="my-2 flex justify-center">
              <table>
                <tr>
                  <td class="border p-1">Season:</td>
                  <td class="border p-1">{props.slot.season.seasonName}</td>
                </tr>
                <tr>
                  <td class="border p-1">Field:</td>
                  <td class="border p-1">{props.slot.field.fieldAbbrev}</td>
                </tr>
                <tr>
                  <td class="border p-1">Team:</td>
                  <td class="border p-1">{teamDesignationAndMaybeName(props.teamInfo)}</td>
                </tr>
              </table>
            </div>
            <ul class="my-2 ml-2 list-disc list-inside max-h-64 overflow-y-auto rounded-md border p-1">
              {slots.map(slot => {
                return <li>{slotTimeForUI(slot)}</li>
              })}
            </ul>
            <div class="my-2 flex gap-2 items-center">
              <Btn2
                class="px-2 py-1"
                onClick={() => commit(slots)}
                data-test="submit-button"
              >Confirm</Btn2>
              <Btn2
                class="px-2 py-1"
                enabledClasses={btn2_redEnabledClasses}
                onClick={() => ctx.emit("cancel")}
                data-test="cancel-button"
              >
                Cancel
              </Btn2>
            </div>
          </div>
        }
        default: exhaustiveCaseGuard(associatedSlotsResolver.underlying)
      }
    }
  }
})

export interface ConfirmPracticeSlotAssignmentDeleteEvent {
  deleteAllGroupMembers: boolean,
  generateLooseSignupPermit: boolean,
  permitComment: string
}

export const ConfirmPracticeSlotAssignmentDeleteModal = defineComponent({
  props: {
    mode: vReqT<"self" | "admin">(),
    slot: vReqT<PracticeSlotEx>(),
    assignment: vReqT<PracticeSlotAssignment>(),
  },
  emits: {
    cancel: () => true,
    commit: (_: ConfirmPracticeSlotAssignmentDeleteEvent) => true,
  },
  setup(props, ctx) {
    /**
     * only offered to admin users, "self" users won't see or be able to adjust it, and it defaults to:
     *  - if there is an associated group, true
     *  - otherwise, false
     */
    const deleteAllGroupMembers = ref(props.assignment.practiceSlotAssignmentGroupID !== null)
    /**
     * when an admin user cancels a single slot, they can generate a "permit" to allow a single signup for this user at some later time
     */
    const generateLooseSignupPermit = ref(false)

    const defaultPermitComment = `Cancellation of practice scheduled for ${dayjs(props.slot.start).format("MMM/DD/YYYY @ h:mm a")}.`
    const permitComment = ref(defaultPermitComment)

    const commit = (associatedAssignments: PracticeSlotAssignment[]) => {
      const doPermit = props.mode === "admin"
        ? generateLooseSignupPermit.value
        : false
      ctx.emit("commit", {
        deleteAllGroupMembers: props.mode === "admin"
          ? (associatedAssignments.length === 0 ? false : deleteAllGroupMembers.value)
          : true,
        generateLooseSignupPermit: doPermit,
        permitComment: doPermit ? permitComment.value : "",
      })
    }

    const associatedAssignmentsResolver = ReactiveReifiedPromise<PracticeSlotAssignment[]>(async () => {
      if (props.assignment.practiceSlotAssignmentGroupID === null) {
        return []
      }

      return listPracticeSlotAssignments(axiosAuthBackgroundInstance, {
        seasonUID: props.assignment.seasonUID,
        //userID: props.assignment.userID,
        practiceSlotAssignmentGroupID: props.assignment.practiceSlotAssignmentGroupID,
        includeDeleted: false,
        expand: ["practiceSlot"]
      }).then(vs => vs
          .filter(v => v.practiceSlotAssignmentID !== props.assignment.practiceSlotAssignmentID)
          .sort(sortByDayJS(v => requireNonNull(v.practiceSlot).start, "s", "asc"))
      );
    })

    return () => {
      switch (associatedAssignmentsResolver.underlying.status) {
        case "idle": return null
        case "pending": {
          return <div class="flex items-center gap-2">
            <SoccerBall/>
            Loading...
          </div>
        }
        case "error": {
          return <div>Sorry, something went wrong.</div>
        }
        case "resolved": {
          const associatedAssignments = associatedAssignmentsResolver.underlying.data
          return <div style="--fk-margin-outer:none;">
            {props.mode === "admin"
              ? <p>Remove {possessivify(props.assignment.team.teamDesignation)} assignment for this slot?</p>
              : <p>Remove your assignment for this slot?</p>}
            <ul class="ml-2 list-disc list-inside">
              <li>{teamDesignationAndMaybeName(props.assignment.team)}</li>
              <li>{slotTimeForUI(props.slot)}</li>
              <li>{props.slot.field.fieldName}</li>
            </ul>
            {associatedAssignments.length === 0
              ? null
              : <div>
                {props.mode === "self"
                  ? <div class="my-2 text-sm">Note: Also removes the following associated assignments:</div>
                  : null}

                {props.mode === "admin"
                  ? <div class="my-2 text-sm flex items-center gap-2">
                    <FormKit
                      id="deleteAllGroupMembers"
                      data-test="deleteAllGroupMembers-checkbox"
                      type="checkbox"
                      v-model={deleteAllGroupMembers.value}
                      onInput={(value) => {
                        if (typeof value !== "boolean") {
                          unreachable()
                        }

                        if (deleteAllGroupMembers.value === value) {
                          return
                        }

                        deleteAllGroupMembers.value = value

                        if (deleteAllGroupMembers.value) {
                          generateLooseSignupPermit.value = false
                        }
                      }}
                    />
                    <label for="deleteAllGroupMembers">Remove associated recurring assignments:</label>
                  </div>
                  : null}

                <ul class="my-2 ml-2 list-disc list-inside max-h-64 overflow-y-auto rounded-md border p-1">
                  {associatedAssignments.map(v => {
                    const slot = requireNonNull(v.practiceSlot) // safe null access because we positively expanded this field
                    return <li>{slotTimeForUI(slot)}</li>
                  })}
                </ul>
              </div>}

            {props.mode === "admin"
              ? <>
                <div class="relative flex items-center my-2" style="padding:2px;">
                  <div style="margin-top: 2px; margin-left: 2px;">
                    <FormKit
                      id="generateLooseSignupPermit"
                      type="checkbox"
                      data-test="generateLooseSignupPermit-checkbox"
                      v-model={generateLooseSignupPermit.value}
                      onInput={() => {
                        permitComment.value = defaultPermitComment
                      }}
                    />
                  </div>
                  <label for="generateLooseSignupPermit" class="text-sm">
                    Generate a raincheck permit, to allow this user to signup for some other slot in this season
                  </label>

                  {
                  // if there are associated assignments, and we are deleting them all,
                  // put an overlay that disables the "permit" checkbox
                  associatedAssignments.length > 0 && deleteAllGroupMembers.value
                    ? <div class="absolute top-0 left-0 w-full h-full" style="background-color: rgba(255,255,255,.75);"/>
                    : null}
                </div>
                {generateLooseSignupPermit.value
                  ? <div>
                    <label>Comment</label>
                    <textarea class="block rounded-md w-full" data-test="permitComment" v-model={permitComment.value}/>
                  </div>
                  : null
                }
              </>
              : null}

            <div class="mt-4 flex gap-2 items-center">
              <Btn2
                class="px-2 py-1"
                onClick={() => commit(associatedAssignments)}
                data-test="submit-button"
              >Remove Assignment</Btn2>
              <Btn2
                class="px-2 py-1"
                enabledClasses={btn2_redEnabledClasses}
                onClick={() => ctx.emit("cancel")}
                data-test="cancel-button"
              >
                Cancel
              </Btn2>
            </div>
          </div>
        }
        default: exhaustiveCaseGuard(associatedAssignmentsResolver.underlying)
      }
    }
  }
})

let PracticeSlotDetailModal_tabID = "" // maybe should be a data member of the component that "owns" the modal instance; but it's good enough to be a global
export const PracticeSlotDetailModal = defineComponent({
  props: {
    maybeConfirmAssignment: vReqT<PracticeSchedulerConfirmAssignmentEvent | null>(),
    maybeConfirmDelete: vReqT<PracticeSchedulerConfirmDeleteEvent | null>(),
    madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity: vReqT<boolean>(),
    slot: vReqT<PracticeSlotEx>(),
  },
  emits: {
    cancel: () => true,
    unlink: () => true,
    link: (_: {practiceSlotGroupID: number}) => true,
    delete: (_: {practiceSlotIDs: number[]}) => true,
    updateSlot: (_: {allowableTeamCount: number, visibleOnOrAfter: Datelike | ""}) => true,
    updateDivisions: (_: {newDivIdList: Guid[]}) => true,
    confirmAssignmentSignup: (_: ConfirmPracticeSlotAssignmentsEvent) => true,
    confirmAssignmentDelete: (_: ConfirmPracticeSlotAssignmentDeleteEvent) => true,
  },
  setup(props, ctx) {
    /**
     * n.b. will NOT contain the slot from props (as in it is all associated slots for slot X, but not X itself)
     */
    const associatedSlotsResolver = ReactiveReifiedPromise<PracticeSlot[]>()
    const relevantRelinkingGroups = ReactiveReifiedPromise<null | {target: {seasonName: string, dateFrom: string, dateTo: string, groupDescriptor: string}, groups: Map<number, PracticeSlot[]>}>()
    const divResolver = ReactiveReifiedPromise<Division[]>(() => Client.getDivisions(axiosAuthBackgroundInstance))

    /**
     * slot will be fully swapped out for some changes that will force a reload of many slots,
     * so reload associated things when that happens.
     */
    watch(() => props.slot, () => {
      associatedSlotsResolver.run(async () => {
        if (props.slot.practiceSlotGroupID !== null) {
          const practiceSlotGroupID = props.slot.practiceSlotGroupID
          return await listPracticeSlots(
            axiosAuthBackgroundInstance,
            {seasonUID: props.slot.seasonUID, practiceSlotGroupID, includeDeleted: false}
          ).then(vs => vs.filter(v => v.practiceSlotID !== props.slot.practiceSlotID).sort(sortByDayJS(v => v.start)))
        }
        else {
          return []
        }
      })

      relevantRelinkingGroups.run(async () => {
        if (props.slot.practiceSlotGroupID !== null) {
          return null
        }
        else {
          // we consider groups by day-of-week-and-time-of-day (e.g. all Mondays at 8am are a group)
          // there can potentially be more than 1 group of such things (e.g. 5 slots in group A and 5 slots in group B, even though both groups are Mon at 8am)
          const groupingDescriptor = (slot: PracticeSlot | PracticeSlotEx) => {
            assertNonNull(slot.field)
            const start = dayjs(slot.start)
            const day = start.format("dddd") + "s"
            const time = start.format("h:mm a")
            return `${day} @ ${time} on ${slot.field.fieldAbbrev}`
          }

          const isEffectivelySame = (a: PracticeSlot | PracticeSlotEx, b: PracticeSlot | PracticeSlotEx) => {
            const xa = dayjs(a.start)
            const xb = dayjs(b.start)
            return xa.isSame(xb, "minute") // xa.day() === xb.day() && xa.hour() === xb.hour() && xa.minute() === xb.minute()
          }

          const primaryDescriptor = groupingDescriptor(props.slot)
          const seasonDateRange = defaultSeasonDateRange(props.slot.season)
          // a little slop on extents here
          const start = dayjs(seasonDateRange.dateFrom).subtract(1, "week").format(DAYJS_FORMAT_HTML_DATE)
          const end = dayjs(seasonDateRange.dateTo).add(1, "week").format(DAYJS_FORMAT_HTML_DATE)

          const otherSlots = await listPracticeSlots(axiosAuthBackgroundInstance, {
            seasonUID: props.slot.seasonUID,
            dateRange: {start, end},
            includeDeleted: false,
            expand: ["field"]
          }).then(vs => {
            // those slots that have a group, and omitting the "current" slot, and which share a grouping key
            return vs.filter(v => {
              if (typeof v.practiceSlotGroupID !== "number" || isEffectivelySame(v, props.slot)) {
                return false
              }
              return groupingDescriptor(v) === primaryDescriptor
            })
          })

          const byGroupID = gatherByKey_manyPerKey(otherSlots, v => parseIntOrFail(v.practiceSlotGroupID))
          for (const vs of byGroupID.values()) {
            vs.sort(sortByDayJS(v => v.start))
          }

          return {
            target: {
              seasonName: props.slot.season.seasonName,
              dateFrom: start,
              dateTo: end,
              groupDescriptor: primaryDescriptor,
            },
            groups: byGroupID
          }
        }
      })
    }, {immediate: true, deep: false})

    const DeleteTab = defineComponent(() => {
      const deleteAssociatedSlots = ref(false)
      const commit = () => {
        if (deleteAssociatedSlots.value) {
          // when we get here the associatedSlots resolver should be resolved
          const associated = requireNonNull(associatedSlotsResolver.underlying.getOrNull()).map(v => v.practiceSlotID)
          return ctx.emit("delete", {practiceSlotIDs: [props.slot.practiceSlotID, ...associated]})
        }
        else {
          return ctx.emit("delete", {practiceSlotIDs: [props.slot.practiceSlotID]})
        }
      }

      return () => {
        return <div>
          {associatedSlotsResolver.underlying.status === "idle"
              ? null
              : associatedSlotsResolver.underlying.status === "pending"
              ? <div class="flex items-center gap-2"><SoccerBall/>Loading associated practice slots...</div>
              : associatedSlotsResolver.underlying.status === "error"
              ? <div>Sorry, something went wrong</div>
              : associatedSlotsResolver.underlying.status === "resolved"
              ? (() => {
                const associatedSlots = associatedSlotsResolver.underlying.data
                return <div style="--fk-margin-outer:none;">
                  {associatedSlots.length > 0
                    ? <div class="border rounded-md p-2" style="display:grid; grid-template-columns: max-content auto;">
                      <div style="align-self: center;">
                        <FormKit id="deleteAssociatedSlots" disabled={associatedSlots.length === 0} type="checkbox" v-model={deleteAssociatedSlots.value}/>
                      </div>
                      <label style="align-self: center;" for="deleteAssociatedSlots">Also delete all associated slots:</label>
                      <div>{/*grid cell placeholder*/}</div>
                      <div>
                        {associatedSlots.length === 0
                          ? <div class="" >No associated slots.</div>
                          : <ul class="my-2 ml-2 list-disc list-inside max-h-64 overflow-y-auto p-1">
                            {associatedSlots.map(slot => {
                              return <li>{slotTimeForUI(slot)}</li>
                            })}
                        </ul>}
                      </div>
                    </div>
                    : <div>Note: No associated slots to delete. Deletion will delete just this slot.</div>}


                  <div class="mt-4 flex items-center gap-2">
                    <Btn2 class="px-2 py-1" onClick={() => commit()}>{deleteAssociatedSlots.value ? "Delete This and Associated Slots" : "Delete This Slot"}</Btn2>
                    <Btn2 class="px-2 py-1" onClick={() => ctx.emit("cancel")} enabledClasses={btn2_redEnabledClasses}>Cancel</Btn2>
                  </div>
                </div>
              })()
              : exhaustiveCaseGuard(associatedSlotsResolver.underlying)}
        </div>
      }
    })

    const GroupLinkageTab = defineComponent(() => {
      return () => {
        if (props.slot.practiceSlotGroupID !== null) {
          return <div>
            {associatedSlotsResolver.underlying.status === "idle"
              ? null
              : associatedSlotsResolver.underlying.status === "pending"
              ? <div class="flex items-center gap-2"><SoccerBall/>Loading associated practice slots...</div>
              : associatedSlotsResolver.underlying.status === "error"
              ? <div>Sorry, something went wrong</div>
              : associatedSlotsResolver.underlying.status === "resolved"
              ? (() => {
                const associatedSlots = associatedSlotsResolver.underlying.data
                return <div>
                  <div>Unlink from these associated slots:</div>
                  <ul class="my-2 ml-2 list-disc list-inside max-h-64 overflow-y-auto p-1">
                    {associatedSlots.length === 0
                      ? <div>Although this slot has an associated recurring practice, it is the only slot in its group. Unlinking will delete the group.</div>
                      : associatedSlots.map(slot => {
                      return <li>{slotTimeForUI(slot)}</li>
                    })}
                  </ul>

                  <div class="mt-4 flex items-center gap-2">
                    <Btn2 class="px-2 py-1" onClick={() => ctx.emit("unlink")}>Unlink</Btn2>
                    <Btn2 class="px-2 py-1" onClick={() => ctx.emit("cancel")} enabledClasses={btn2_redEnabledClasses}>Cancel</Btn2>
                  </div>
                </div>
              })()
              : exhaustiveCaseGuard(associatedSlotsResolver.underlying)}
          </div>
        }
        else {
          return <div>
            {relevantRelinkingGroups.underlying.status === "idle"
              ? null
              : relevantRelinkingGroups.underlying.status === "pending"
              ? <div class="flex items-center gap-2"><SoccerBall/>Loading associated practice slots...</div>
              : relevantRelinkingGroups.underlying.status === "error"
              ? <div>Sorry, something went wrong</div>
              : relevantRelinkingGroups.underlying.status === "resolved"
              ? (() => {
                const availableGroups = relevantRelinkingGroups.underlying.data
                if (!availableGroups) {
                  // should return null only in cases where we'll never even get here
                  return null
                }

                if (availableGroups.groups.size === 0) {
                  // TODO: "create group"?...
                  return <div>
                    <div>Found no relevant groups to link to.</div>
                    <div>Looked at {availableGroups.target.seasonName}, {availableGroups.target.groupDescriptor}, from {availableGroups.target.dateFrom} {availableGroups.target.dateTo}</div>
                  </div>
                }

                return <div>
                  <div>Link to one of the following groups:</div>
                  {[...availableGroups.groups.values()].map((group, idx) => {
                    return <div class="border rounded-md p-1">
                      <div>Group {idx+1}</div>
                      <ul class="ml-2 list-disc list-inside max-h-64 overflow-y-auto p-1">
                        {group.map(slot => {
                          return <li>{slotTimeForUI(slot)}</li>
                        })}
                      </ul>
                      <Btn2
                        class="my-1 px-2 py-1"
                        onClick={() => {
                          assertTruthy(new Set(group.map(v => v.practiceSlotGroupID)).size === 1) // we should have grouped existing slots by their groupID for our purposes here
                          const practiceSlotGroupID = group[0].practiceSlotGroupID
                          if (typeof practiceSlotGroupID !== "number") {
                            unreachable()
                          }

                          ctx.emit("link", {practiceSlotGroupID: practiceSlotGroupID})}
                        }
                      >
                        Link
                      </Btn2>
                    </div>
                  })}

                  <div class="mt-2 flex items-center gap-2">
                    <Btn2 class="px-2 py-1" onClick={() => ctx.emit("cancel")} enabledClasses={btn2_redEnabledClasses}>Cancel</Btn2>
                  </div>
                </div>
              })()
              : exhaustiveCaseGuard(relevantRelinkingGroups.underlying)}
          </div>
        }
      }
    })

    const EditDivisionsTab = defineComponent(() => {
      const selectedDivIDs = ref<Guid[]>([...props.slot.divIDs])
      const options = computed<UiOption[]>(() => {
        return divResolver
          .underlying
          .getOrNull()
          ?.map(div => ({label: div.displayName || div.division, value: div.divID}))
          ?? []
      })

      return () => {
        return <div>
          {props.slot.practiceSlotGroupID === null
            ? <div>This slot is not associated with any recurring practices, so the divisions associated with it are unique to this slot.</div>
            : <div>This slot shares its divisions list with its associated recurring practices; updating the division list will update all associated practice slots.</div>}

          {divResolver.underlying.status === "idle"
            ? null
            : divResolver.underlying.status === "pending"
            ? <div class="flex items-center gap-2"><SoccerBall/>Loading...</div>
            : divResolver.underlying.status === "error"
            ? <div>Sorry, something went wrong.</div>
            : divResolver.underlying.status === "resolved"
            ? (() => {
              return <div>
                <SelectMany
                  offerAllOption={true}
                  options={options.value}
                  selectedKeys={selectedDivIDs.value}
                  onCheckedOne={(divID, checked) => {
                    if (checked) {
                      selectedDivIDs.value.push(divID)
                    }
                    else {
                      selectedDivIDs.value = selectedDivIDs.value.filter(v => v !== divID)
                    }
                  }}
                  onCheckedAll={checked => {
                    if (checked) {
                      selectedDivIDs.value = options.value.map(v => v.value)
                    }
                    else {
                      selectedDivIDs.value = []
                    }
                  }}
                />
                {selectedDivIDs.value.length === 0
                  ? <div class="text-sm">Selected at least 1 division.</div>
                  : null}
              </div>
            })()
            : exhaustiveCaseGuard(divResolver.underlying)}
            <div class="mt-4 flex items-center gap-2">
              <Btn2 class="px-2 py-1" disabled={selectedDivIDs.value.length === 0} onClick={() => ctx.emit("updateDivisions", {newDivIdList: selectedDivIDs.value})}>Update Divisions</Btn2>
              <Btn2 class="px-2 py-1" onClick={() => ctx.emit("cancel")} enabledClasses={btn2_redEnabledClasses}>Cancel</Btn2>
            </div>
        </div>
      }
    })

    const MiscConfig = defineComponent(() => {
      const allowableTeamCount = ref<Integerlike>(props.slot.allowableTeamCount)
      const visibleOnOrAfter = ref<"" | Datelike>(props.slot.visibleOnOrAfter?.format(DAYJS_FORMAT_HTML_DATE) || "")

      const submit = () => {
        ctx.emit("updateSlot", {
          allowableTeamCount: parseIntOrFail(allowableTeamCount.value),
          visibleOnOrAfter: visibleOnOrAfter.value,
        })
      }

      return () => {
        return <div class="px-1" style="--fk-padding-input: .35em;">
          <FormKit type="form" actions={false} onSubmit={() => submit()}>
            {props.slot.practiceSlotGroupID === null
              ? <div>This slot is not associated with any recurring practices, so the data edited here is unique to this slot.</div>
              : <div>This slot shares its configuration with its associated recurring practices; updating data here will update all associated practice slots.</div>}
            <FormKit type="number" label="Max Teams" validation={[["required"], ["min", 0]]} v-model={allowableTeamCount.value} data-test="allowableTeamCount"/>
            <FormKit type="date" label="Visible On Or After" v-model={visibleOnOrAfter.value} data-test="visibleOnOrAfter"/>
            <Btn2 type="submit" class="mt-2 px-2 py-1" data-test="submit-button">Save</Btn2>
          </FormKit>
        </div>
      }
    })

    const VerticalScroll = defineComponent({
      setup(_, ctx) {
      return () => <div style="max-height: 500px; overflow-y: auto;">{ctx.slots.default?.()}</div>
      }
    })

    type TabID = "assignSlot" | "unassignSlot" | "delete" | "updateDivisions" | "groupLinkage" | "miscConfig"
    const tabDefs = computed<TabDef<TabID>[]>(() => {
      const tabs : TabDef<TabID>[] = [
        {
          id: "updateDivisions",
          label: "Edit Divisions",
          "data-test": "editDivisions-tab",
          render: () => <VerticalScroll><EditDivisionsTab/></VerticalScroll>
        },
        {
          id: "miscConfig",
          label: "Misc. Slot Settings",
          "data-test": "miscConfig-tab",
          render: () => <VerticalScroll><MiscConfig/></VerticalScroll>
        },
        {
          id: "groupLinkage",
          label: props.slot.practiceSlotGroupID === null ? "Link" : "Unlink",
          render: () => <VerticalScroll><GroupLinkageTab/></VerticalScroll>
        },
        {
          id: "delete",
          label: "Delete",
          "data-test": "delete-tab",
          render: () => <VerticalScroll><DeleteTab/></VerticalScroll>,
        }
      ]

      // if we made changes to linkage/divisions, we want to NOT show the assign/delete stuff because it may have become stale
      if (!props.madeSomeChangePotentiallyInvalidatingAssignOrDeleteValidity) {
        if (props.maybeConfirmAssignment) {
          assertTruthy(!props.maybeConfirmDelete) // only 1 or the other
          const v = props.maybeConfirmAssignment
          tabs.unshift({
            id: "assignSlot",
            label: "Assign Slot",
            "data-test": "assign-tab",
            render: () => <ConfirmPracticeSlotAssignmentsModal
              mode={v.mode} // well, should always be "admin", yeah?...
              slot={v.slot}
              teamInfo={v.userInfo.teamInfo}
              hasExistingRecurringSeasonalSelection={v.hasExistingRecurringSeasonalSelection}
              availableOneOffPermits={v.availableOneOffPermits}
              onCommit={args => ctx.emit("confirmAssignmentSignup", args)}
            />
          })
        }

        if (props.maybeConfirmDelete) {
          assertTruthy(!props.maybeConfirmAssignment) // only 1 or the other
          const v = props.maybeConfirmDelete
          tabs.unshift({
            id: "unassignSlot",
            label: "Unassign Slot",
            "data-test": "unassign-tab",
            render: () => <ConfirmPracticeSlotAssignmentDeleteModal
              mode="admin"
              slot={v.slot}
              assignment={v.assignment}
              onCancel={() => ctx.emit("cancel")}
              onCommit={args => ctx.emit("confirmAssignmentDelete", args)}
            />
          })
        }
      }

      return tabs;
    })

    const tabMapping = computed(() => makeTabDefMapping(tabDefs.value))
    const selectedTabIdx = ref(tabMapping.value.id2Idx[PracticeSlotDetailModal_tabID as TabID] || 0)

    return () => {
      return <div>
        <div class="my-1">
          <div>{props.slot.field.fieldAbbrev}, {props.slot.start.format("dddd")}, {props.slot.start.format("MMM/DD/YY")}, {props.slot.start.format("h:mm a")} - {props.slot.end.format("h:mm a")}</div>
          <div>{props.slot.practiceSlotGroupID ? "Recurring Weekly" : "Non-Recurring"}</div>
        </div>
        <Tabs
          tabDefs={tabDefs.value}
          selectedIndex={selectedTabIdx.value}
          onChangeSelectedIndex={idx => {
            selectedTabIdx.value = idx
            PracticeSlotDetailModal_tabID = tabMapping.value.idx2Id[idx] ?? ""
          }}
        />
      </div>
    }
  }
})

/**
 * Almost a `practiceSlot`, but with some modifications that don't admit of extension.
 */
export interface PracticeSlotEx {
  practiceSlotID: number,
  practiceSlotGroupID: number | null,
  fieldUID: Guid,
  seasonUID: Guid,
  divIDs: Guid[],
  start: Dayjs,
  end: Dayjs,
  visibleOnOrAfter: Dayjs | null,
  field: Field,
  season: PracticeSchedulerSeason,
  divisions: Division[],
  hasSomeIntraFieldPracticeSlotConflict: boolean,
  activeAssignments: null | PracticeSlotAssignment[],
  currentSignedUpTeamIDs: TeamID[],
  atCapacity: boolean,
  allowableTeamCount: number,
  effectiveAllowableTeamCount: number,
}

export function PracticeSlotEx(slot: PracticeSlot, related: {fields: Map<Guid, Field>, divs: Map<Guid, Division>, seasons: Map<Guid, PracticeSchedulerSeason>}) : PracticeSlotEx {
  return {
    practiceSlotID: slot.practiceSlotID,
    practiceSlotGroupID: slot.practiceSlotGroupID === "" ? null : slot.practiceSlotGroupID,
    fieldUID: slot.fieldUID,
    seasonUID: slot.seasonUID,
    divIDs: slot.divIDs,
    start: dayjs(slot.start),
    end: dayjs(slot.end),
    visibleOnOrAfter: dayjsOr(slot.visibleOnOrAfter) ?? null,
    field: requireNonNull(related.fields.get(slot.fieldUID)),
    season: requireNonNull(related.seasons.get(slot.seasonUID)),
    divisions: slot.divIDs.map(divID => requireNonNull(related.divs.get(divID))),
    hasSomeIntraFieldPracticeSlotConflict: !!slot.hasSomeIntraFieldPracticeSlotConflict,
    activeAssignments: slot.activeAssignments || null,
    currentSignedUpTeamIDs: slot.currentSignedUpTeamIDs,
    atCapacity: slot.atCapacity,
    allowableTeamCount: slot.allowableTeamCount,
    effectiveAllowableTeamCount: slot.effectiveAllowableTeamCount,
  }
}

export type PracticeSchedulerUiElement =
  & PracticeSlotEx
  & DateFieldLayoutable
  & {
    uiState: CalendarUiState
  }

export function PracticeSchedulerUiElement(slotEx: PracticeSlotEx) : PracticeSchedulerUiElement {
  return {
    ...slotEx,
    calKeys: {
      fieldUID: slotEx.fieldUID,
      date: slotEx.start.format(k_dayGroupKeyFormat)
    },
    uiState: {
      __vueKey: nextGlobalIntlike(),
      isModalOrOverlayFocus: false,
      isBeingVerticallyResized: false,
      dragState: null,
      time: {
        start: dayjs(slotEx.start),
        end: dayjs(slotEx.end),
        isEffectivelyAllDay: false,
      },
      isSaving: false,
      isBusy: false,
      isOpeningEditPane: false,
      isBulkSelected: false,
      noBulkSelect: null,
      testID:`practiceSlotID=${slotEx.practiceSlotID}`
    }
  }
}

export function PracticeSchedulerLayoutNode(root: cal.LayoutNodeRoot<any>, uiElem: PracticeSchedulerUiElement) : cal.LayoutNode<PracticeSchedulerUiElement> {
  return {
    parent: root,
    children: [],
    start: uiElem.start.unix(),
    end: uiElem.end.unix(),
    precedence: 1,
    data: uiElem,
  }
}

export interface PracticeSchedulerConfirmAssignmentEvent {
  mode: "admin" | "self",
  slot: PracticeSchedulerUiElement,
  userInfo: SlotAssignmentUserBinding,
  hasExistingRecurringSeasonalSelection: boolean,
  availableOneOffPermits: {practiceSlotAssignmentPermitID: number}[],
}

export interface PracticeSchedulerConfirmDeleteEvent {
  ctx: SlotSchedulingDataStore,
  slot: PracticeSchedulerUiElement,
  assignment: PracticeSlotAssignment,
}

export interface PracticeSchedulerAdminModalEvent {
  maybeConfirmAssignment: PracticeSchedulerConfirmAssignmentEvent | null,
  /**
   * TODO: probably this is not necessary, we now have a "click the particular name you want to delete the assignment for",
   * rather than "click the slot and then choose which assignment to remove" (which itself wasn't fully fleshed out, it didn't support multiple assignments per slot)
   * @deprecated
   */
  maybeConfirmDelete: PracticeSchedulerConfirmDeleteEvent | null,
  slot: PracticeSlotEx,
}

export const PracticeSchedulerCalendarBodyElement = defineComponent({
  props: {
    elementStyle: vReqT<StyleValue>(),
    isPracticeSchedulerSuperUser: vReqT<boolean>(),
    node: vReqT<cal.LayoutNode<PracticeSchedulerUiElement>>(),
    manageMode: vReqT<"admin" | "self">(),
    slotSchedulingData: vReqT<SlotSchedulingDataStore>(),
    tree: vReqT<Map<Guid, Map<Guid, cal.LayoutNodeRoot<PracticeSchedulerUiElement>>>>(),
  },
  emits: {
    confirmDelete: (_: PracticeSchedulerConfirmDeleteEvent) => true,
    signup: (_: PracticeSchedulerConfirmAssignmentEvent) => true,
    adminModal: (_: PracticeSchedulerAdminModalEvent) => true,
  },
  setup(props, ctx) {
    const slot = computed(() => props.node.data)

    const targetTeam = computed(() => {
      const team = props.slotSchedulingData.team.value.underlying.getOrNull()?.teamID.selectedObj ?? null
      if (!team) {
        return null
      }
      else {
        return {
          ...team,
          teamDesignationAndMaybeName: teamDesignationAndMaybeName(team.team)
        }
      }
    })

    const existingAssignmentForFocusedTeam = computed<PracticeSlotAssignment | null>(() => {
      const team = targetTeam.value
      if (!team) {
        return null
      }

      return props
        .slotSchedulingData
        .workingCalendarInfoForSomeTargetUserSeason
        .slotsAlreadyAssignedForSelectedUser
        .find(v => team.team.teamID === v.team.teamID && v.practiceSlotID === slot.value.practiceSlotID) ?? null
    })

    const seasonTeamRecurringSelectionInfo = computed(() => AlreadyHasARecurringAssignmentThisSeasonTeam(props.slotSchedulingData)) // can we lift this up a component

    // TODO: make this on the store itself, less `computed`s to be generated (1 per calendar instead of 1 per slot)
    const targetUserTeamInfo = computed(() => {
      const teamInfo = props.slotSchedulingData.team.value.underlying.getOrNull()?.teamID.selectedObj

      if (!teamInfo) {
        return null
      }

      return {
        teamInfo,
        availablePermits: props.slotSchedulingData.workingCalendarInfoForSomeTargetUserSeason.getAvailableOneOffSignupPermits({teamID: teamInfo.team.teamID})
      }
    })

    const CannotSignupFact = {
      noUserTeamInfo: "noUserTeamInfo",
      currentUserAlreadyHasRecurringSelection: "alreadyHasRecurringSelection", // probably can be replaced with "just" `targetTeamAlreadyHasRecurringSelection`
      targetTeamAlreadyHasRecurringSelection: "targetHasRecurringSelection", // a recurring selection exists for the focused team, but it is not for this slot
      targetTeamAlreadyOnThisSlot: "targetTeamAlreadyOnThisSlot", // team exists on this slot (and possibly other associated slots)
      nonRecurringNoPermits: "nonRecurringNoPermits",
      atCapacity: "atCapacity",
    } as const;
    type CannotSignupFact = (typeof CannotSignupFact)[keyof typeof CannotSignupFact]

    const targetTeamSignupFacts = computed<{
      facts: Set<CannotSignupFact>,
      action: "signup" | "cancel" | null,
      dataTestAttrs: Record<string, string>,
    }>(() => {
      const facts = new Set<CannotSignupFact>()
      if (!targetUserTeamInfo.value) {
        facts.add(CannotSignupFact.noUserTeamInfo)
      }
      else {
        const isRecurring = props.node.data.practiceSlotGroupID !== null
        const permits = targetUserTeamInfo.value.availablePermits

        if (props.node.data.practiceSlotGroupID === null && permits.length === 0) {
          facts.add(CannotSignupFact.nonRecurringNoPermits)
        }
        if (props.node.data.currentSignedUpTeamIDs.find(teamID => teamID === targetTeam.value?.team.teamID)) {
          facts.add(CannotSignupFact.targetTeamAlreadyOnThisSlot)
        }
        if (props.node.data.atCapacity) {
          facts.add(CannotSignupFact.atCapacity)
        }
        if (isRecurring && seasonTeamRecurringSelectionInfo.value.hasRecurringSelection && permits.length === 0) {
          facts.add(CannotSignupFact.currentUserAlreadyHasRecurringSelection)
        }
        if (isRecurring && targetTeam.value?.hasSomeExistingRecurringAssignment && permits.length === 0) {
          facts.add(CannotSignupFact.targetTeamAlreadyHasRecurringSelection)
        }
      }

      const action = facts.size === 0 ? "signup"
        : facts.has(CannotSignupFact.targetTeamAlreadyOnThisSlot) ? "cancel"
        : null

      return {
        facts,
        dataTestAttrs: (() => {
          const result : Record<string, string> = {}
          for (const fact of facts) {
            result[`data-test-${fact}`] = "" // e.g. <div data-test-foo></div> (no attr value)
          }

          if (action !== "signup") {
            result["data-test-unavailable"] = ""
          }
          else {
            result["data-test-available"] = ""
          }

          return result;
        })(),
        action,
      }
    })

    const wholeElemAsButton = computed<boolean>(() => {
      if (props.isPracticeSchedulerSuperUser) {
        return true
      }

      if (targetTeamSignupFacts.value.action === "signup" || targetTeamSignupFacts.value.action === "cancel") {
        return true
      }
      else if (targetTeamSignupFacts.value.action === null) {
        return false
      }
      else {
        exhaustiveCaseGuard(targetTeamSignupFacts.value.action)
      }
    })

    const handleElemClick = () : void => {
      if (props.isPracticeSchedulerSuperUser) {
        ctx.emit("adminModal", {
          maybeConfirmAssignment: maybeTargetUserSignupEvent(),
          maybeConfirmDelete: null,
          slot: props.node.data,
        } satisfies PracticeSchedulerAdminModalEvent)
      }
      else {
        if (!targetTeamSignupFacts.value.action) {
          return
        }

        if (targetTeamSignupFacts.value.action === "signup") {
          const evt = maybeTargetUserSignupEvent()
          if (!evt) {
            return; // shouldn't happen
          }
          ctx.emit("signup", evt)
        }
        else if (targetTeamSignupFacts.value.action === "cancel") {
          const evt = maybeTargetUserConfirmDeleteEvent()
          if (!evt) {
            return // shouldn't happen
          }
          ctx.emit("confirmDelete", evt)
        }
        else {
          exhaustiveCaseGuard(targetTeamSignupFacts.value.action)
        }
      }

      function maybeTargetUserSignupEvent() : PracticeSchedulerConfirmAssignmentEvent | null {
        if (!targetUserTeamInfo.value) {
          return null
        }

        const teamInfo = targetUserTeamInfo.value.teamInfo
        const availableOneOffSignupPermits = targetUserTeamInfo.value.availablePermits

        if (targetTeamSignupFacts.value.action !== "signup") {
          return null
        }

        return {
            mode: props.slotSchedulingData.mode,
            slot: props.node.data,
            userInfo: {
              teamInfo: teamInfo.team
            },
            hasExistingRecurringSeasonalSelection: seasonTeamRecurringSelectionInfo.value.hasRecurringSelection,
            availableOneOffPermits: !seasonTeamRecurringSelectionInfo.value.hasRecurringSelection
              ? [] // if there is no current seasonal recurring selection, do not consume any permits (it is unlikely they exist in this case anyway, but to be sure)
              : availableOneOffSignupPermits,
          }
      }

      function maybeTargetUserConfirmDeleteEvent() : PracticeSchedulerConfirmDeleteEvent | null {
        if (!targetUserTeamInfo.value) {
          return null
        }

        if (targetTeamSignupFacts.value.action !== "cancel") {
          return null
        }

        const assignment = existingAssignmentForFocusedTeam.value
        if (!assignment) {
          return null
        }
        return {
          ctx: props.slotSchedulingData,
          slot: props.node.data,
          assignment,
        }
      }
    }

    const SignupViaPermitInfo = defineComponent(() => () => {
      if (!targetUserTeamInfo.value) {
        return null
      }

      const permits = targetUserTeamInfo.value.availablePermits
      if (permits.length === 0) {
        return null
      }

      if (targetTeamSignupFacts.value.action !== "signup") {
        return false;
      }

      const isGroupSlotAndAlreadySignedUpForGroupButNotForThisSlot = props.node.data.practiceSlotGroupID !== null && seasonTeamRecurringSelectionInfo.value.hasRecurringSelection
      const isNotGroupSlot = props.node.data.practiceSlotGroupID === null

      if (isGroupSlotAndAlreadySignedUpForGroupButNotForThisSlot || isNotGroupSlot) {
        return <div class="text-sm" data-test={`rainchecks=${permits.length}`}>
          <p>Signup will consume raincheck</p>
          <p class="text-xs">({permits.length} remaining)</p>
        </div>
      }
    })

    return () => <div
      role={wholeElemAsButton.value ? "button" : undefined}
      class="text-left px-1 h-full flex flex-col items-start overflow-y-auto"
      data-test="practiceSlotElem"
      {...targetTeamSignupFacts.value.dataTestAttrs}
      style={
        targetTeamSignupFacts.value.facts.has(CannotSignupFact.targetTeamAlreadyOnThisSlot) ? "background-color: lightgray; color: black;"
          : (targetUserTeamInfo.value && targetTeamSignupFacts.value.action !== "signup") ? "background-color: gray;"
          : null}
      onClick={handleElemClick}
    >
      <div>{props.node.data.start.format("ddd, MMM DD")}</div>
      <div>{props.node.data.start.format("h:mm a")}</div>
      <div>{props.node.data.practiceSlotGroupID === null ? "Non-Recurring" : "Recurring Weekly"}</div>
      <div>{
        targetTeamSignupFacts.value.facts.has(CannotSignupFact.targetTeamAlreadyOnThisSlot)
          ? `${targetTeam.value?.teamDesignationAndMaybeName || "Team"} already signed up for this slot.`
          : null
      }</div>
      {props.isPracticeSchedulerSuperUser
        ? props.node.data.activeAssignments?.map(v => {
          return <Btn2
              commonClasses=""
              class="text-left text-white px-1 border rounded-md"
              onClick={evt => {
                evt.stopImmediatePropagation();
                evt.preventDefault();
                ctx.emit("confirmDelete", {assignment: v, ctx: props.slotSchedulingData, slot: props.node.data} satisfies PracticeSchedulerConfirmDeleteEvent);
              }}
              data-test={`deleteAssignment-button/practiceSlotAssignmentID=${v.practiceSlotAssignmentID}`}
            >{teamDesignationAndMaybeName(v.team)}</Btn2>
        })
        : null}
      {props.isPracticeSchedulerSuperUser
        ? <div>{(() => {
          if (!props.node.data.visibleOnOrAfter) {
            return <div v-tooltip={{content:"Slot is Visible"}}><FontAwesomeIcon icon={faEye}/></div>
          }
          const vDate = props.node.data.visibleOnOrAfter
          return vDate.isAfter(dayjs(), "minute")
            ? <div v-tooltip={{content: `Becomes visible ${vDate.format("MMM/DD/YY @ h:mm a")}`}}><FontAwesomeIcon icon={faEyeSlash}/></div>
            : <div v-tooltip={{content: `Became visible ${vDate.format("MMM/DD/YY @ h:mm a")}`}}><FontAwesomeIcon icon={faEye}/></div>
        })()}</div>
        : null}
      <SignupViaPermitInfo/>
    </div>
  }
})

function slotTimeForUI(slotlike: PracticeSlot | PracticeSlotEx) {
  return `${dayjs(slotlike.start).format("dddd, MMM D")}, ${dayjs(slotlike.start).format("h:mm a")} - ${dayjs(slotlike.end).format("h:mm a")}`
}

function possessivify(v: string) {
  return v.endsWith("s")
    ? v + "'"
    : v + "'s"
}

export const PracticeSchedulerViewOptions = defineComponent({
  props: {
    seasonOptions: vReqT<UiOptions>(),
    fieldOptions: vReqT<UiOptions>(),
    selectedSeason: vReqT<MutUiSelection<Guid, PracticeSchedulerSeason | null>>(),
    selectedField: vReqT<MutUiSelection<Guid[], Field[]>>(),
    dateFrom: vReqT<Ref<Datelike>>(),
    dateTo: vReqT<Ref<Datelike>>(),
    onlyShowSelectedDatesAndFieldsHavingContent: vReqT<Ref<boolean>>(),
    minMaxHr24: vReqT<{readonly min: Integerlike, readonly max: Integerlike}>(),
  },
  emits: {
    refresh: (_: {minHr24: number, maxHr24: number}) => true,
  },
  setup(props, ctx) {
    const minHr24 = ref(props.minMaxHr24.min)
    const maxHr24 = ref(props.minMaxHr24.max)
    watch(() => props.minMaxHr24, () => {
      minHr24.value = props.minMaxHr24.min
      maxHr24.value = props.minMaxHr24.max
    }, {deep: false})

    const hrOptions : UiOption<Integerlike>[] = rangeInc(0, 23).map(i => ({label: dayjs().hour(i).format("h a"), value: i.toString() as Integerlike}))

    const commit = () => {
      ctx.emit("refresh", {minHr24: parseIntOrFail(minHr24.value), maxHr24: parseIntOrFail(maxHr24.value)})
    }

    const offerHourControl = false // disabled but we could offer it
    const CoherentHourValidation = FkDynamicValidation(() => {
      const min = parseIntOrFail(minHr24.value)
      const max = parseIntOrFail(maxHr24.value)
      if (min <= max) {
        return {status: "valid"}
      }
      else{
        return {status: "invalid", msg: "'from' hour must be <= 'to' hour"}
      }
    })

    const bumpDates = (n: number, unit: dayjs.ManipulateType) => {
      props.dateFrom.value = dayjs(props.dateFrom.value).add(n, unit).format(DAYJS_FORMAT_HTML_DATE);
      props.dateTo.value = dayjs(props.dateTo.value).add(n, unit).format(DAYJS_FORMAT_HTML_DATE);
    }

    return () => {
      return <div style="background-color:white; width:100%; max-width: var(--fk-max-width-input);">
        <FormKit type="form" actions={false} onSubmit={() => commit()}>
          <div class="mb-2">
            <FormKit
              type="select"
              disabled={props.seasonOptions.disabled}
              options={props.seasonOptions.options}
              v-model={props.selectedSeason.selectedKey}
              data-test="seasonUID"
              delay={0} // we want this synchronous (TODO: make this the default somehow)
              onInput={(value) => {
                if (typeof value !== "string") {
                  unreachable()
                }

                if (props.selectedSeason.selectedKey === value) {
                  return
                }

                props.selectedSeason.selectedKey = value
                const timespan = computeStartWeekOptions(props.selectedSeason.selectedObj)
                if (!timespan.disabled && timespan.options.length > 0) {
                  props.dateFrom.value = timespan.options[0].value
                  // add a week because we're using the "start week options" which is a list of "when weeks in the season start" and we want all the way through
                  props.dateTo.value = dayjs(timespan.options[timespan.options.length - 1].value).add(1, "week").format(DAYJS_FORMAT_HTML_DATE)
                }
              }}
            />
          </div>
          <div class="my-2">
            <div class="text-sm border rounded-md w-full" style="max-height: 10em; overflow-y: auto;">
              <SelectMany
                selectedKeys={props.selectedField.selectedKey}
                options={props.fieldOptions.options}
                offerAllOption={true}
                onChecked={newVals => {props.selectedField.selectedKey = newVals}}
              />
            </div>
          </div>
          <div class="my-2">
            <div class="flex gap-2">
              <div class="flex-grow">
                From:
                <FormKit type="date" v-model={props.dateFrom.value} data-test="dateFrom-input"/>
              </div>
              <div class="flex-grow">
                To:
                <FormKit type="date" v-model={props.dateTo.value} data-test="dateTo-input"/>
              </div>
            </div>
            <div class="mt-2 flex gap-2">
              <Btn2 style="padding-top: 2px; padding-bottom: 2px;" onClick={() => bumpDates(-1, "week")} class="px-2 text-sm"><FontAwesomeIcon icon={faChevronLeft}/> Week</Btn2>
              <Btn2 style="padding-top: 2px; padding-bottom: 2px;" onClick={() => bumpDates(+1, "week")} class="px-2 text-sm">Week <FontAwesomeIcon icon={faChevronRight}/></Btn2>
            </div>
          </div>

          {offerHourControl
            ? <div class="my-2">
              <div class="flex gap-2 items-center">
                Show
                <FormKit type="select" options={hrOptions} v-model={minHr24.value}/>
                to
                <FormKit type="select" options={hrOptions} v-model={maxHr24.value}/>
              </div>
              <CoherentHourValidation/>
            </div>
            : null}
          <div class="my-2 flex items-center gap-2">
            <FormKit id="practiceSchedulerView-onlyShow" type="checkbox" v-model={props.onlyShowSelectedDatesAndFieldsHavingContent.value}/>
            <label for="practiceSchedulerView-onlyShow">Only show fields/dates having practices</label>
          </div>
          <Btn2
            class="px-2 py-1"
            type="submit"
            data-test="refreshView-button"
          >Refresh View</Btn2>
        </FormKit>
      </div>
    }
  }
})
