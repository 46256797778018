import authService from "src/helpers/authService"
import { exhaustiveCaseGuard, parseIntOr } from "src/helpers/utils"
import { User, type UserI } from "src/store/User"
import { Client } from "src/store/Client"
import { TournamentTeam, TournamentTeamFromListTournamentTeamsEndpoint, TournamentTeamOverviewReport, TournamentTeamOverviewReport_Ref } from "src/composables/InleagueApiV1.Tournament"
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1"

export function hasCreateTournamentTeamRoutePermission() : boolean {
  const userData = typeof User.value.userData === "string" ? null : User.value.userData;
  if (!userData) {
    // "not logged in" means "sure, they have permission ... probably."
    // "create tourn team" routes should be logged-in/logged-out aware, and act accordingly.
    // Specifically, a logged-in user may or may not have permission to create a team, but we always offer the ability to log in using this flow,
    // mostly to offer a non-inleague user to create an account via their AYSOID. See tournament components for details.
    return true;
  }

  if (authService(User.value.roles, "registrar")) {
    // super user always has it
    return true;
  }

  // If either of `clientRegion` or `userRegion` does not parse as an integer it's probably a bug,
  // but also unexpected so we just try to gracefully handle a bad parse.
  const clientRegion = parseIntOr(Client.value.instanceConfig.region, null);
  const userRegion = parseIntOr(userData.region, null);

  if (clientRegion !== null && userRegion !== null && clientRegion !== userRegion) {
    // both client and user as region numbers, and both represent the same region.
    // A "non-local" user always has permission, so that users who are here for "some other league" can always register their teams.
    return true;
  }

  // Local users who can "create tournament teams" will be offered a list of local teams to choose from;
  // this means the user needs to have permissions to hit the "get teams for competition" endpoint
  const localUserHasSomeTeamLookupPermissions = userData.competitionsMemento.length > 0 || authService(User.value.roles, "DD", "compManager", "playerAdmin", "inLeague");

  if (localUserHasSomeTeamLookupPermissions) {
    // a local user with some competition manager gets this link
    return true;
  }

  if (userData.hasCreateSomeTournamentTeamPerm) {
    return true;
  }

  return false;
}

export function isAuthorizedToManageSomeTournament() : boolean {
  // when hasManageSomeTournamentPerm is no longer optional, this can be implemented entirely in terms of userData
  // see details on hasManageSomeTournamentPerm
  if (typeof User.userData?.hasManageSomeTournamentPerm === "boolean") {
    return User.userData.hasManageSomeTournamentPerm;
  }

  // handles "logged out" case, which shouldn't generally happen
  const competitionsThisUserManagesInSomeWay = User.userData?.competitionsMemento ?? []

  const hasSomeRole = authService(User.userData?.roles ?? [], "registrar", "webmaster");
  const managesAnyCompetition = competitionsThisUserManagesInSomeWay.some(v => !!v.isSuperAdmin);

  return hasSomeRole || managesAnyCompetition;
}

export function isAuthorizedToManageTournament(competitionUID: Guid, user: UserI) : boolean {
  // handles "logged out" case, which shouldn't generally happen
  const competitionsThisUserManagesInSomeWay = typeof user.userData === "string" ? [] : user.userData.competitionsMemento

  const hasSomeRole = authService(user.roles, "registrar", "webmaster");
  const managesTargetCompetition = competitionsThisUserManagesInSomeWay.some(v => !!v.isSuperAdmin && v.competitionUID === competitionUID);

  return hasSomeRole || managesTargetCompetition;
}

export function isAuthorizedToManageSomeTournamentTeam() : boolean {
  return isAuthorizedToManageSomeTournament()
    || hasCreateTournamentTeamRoutePermission()
    || !!User.userData?.hasManageSomeTournamentTeamPerm
}

export function tournTeamStatusUiString(tournTeam: TournamentTeam) : string {
  switch (tournTeam.status) {
    case "APPROVED": return "Approved";
    case "DROPPED_BY_HOST": return "Dropped by host";
    case "DROPPED_BY_SUBMITTER": return "Dropped by submitter";
    case "PAID_AWAITING_APPROVAL": return "Paid (awaiting approval)";
    case "PENDING": {
      if (tournTeam.awaitingAsyncPaymentCompletion) {
        return "Payment processing"
      }
      if (tournTeam.invoiceInstanceID_registration && tournTeam.hasDeclaredIntentToPayByCheck) {
        return "Complete awaiting payment (intending to pay by check)";
      }
      if (tournTeam.invoiceInstanceID_registration) {
        return "Complete awaiting payment"
      }
      return "Incomplete"
    }
    default: exhaustiveCaseGuard(tournTeam.status);
  }
}

// candidate for inclusion server-side, but we would still want all the decomposed info to support granular sorting or etc.
export function teamDesignation(tournTeam: Pick<TournamentTeamFromListTournamentTeamsEndpoint, "div_gender" | "div_divNum" | "mungedTeamLetter">) : string {
  return [
    tournTeam.div_gender,
    tournTeam.div_divNum,
    "-",
    tournTeam.mungedTeamLetter.region,
    "-",
    tournTeam.mungedTeamLetter.id,
  ].join("");
}

export type RefAssignmentsByUserID = Record<Guid, TournamentTeamOverviewReport_Ref["refereeAssignments"] | /*no-unchecked-indexed-access*/ undefined>

export function refAssignmentsByUserID(report: TournamentTeamOverviewReport) : RefAssignmentsByUserID {
  const result : RefAssignmentsByUserID = {}
  report.tournamentTeams.forEach(tournTeam => {
    tournTeam.tournamentTeamOfficialReferees.forEach(ref => {
      ref.refereeAssignments.forEach(refAssignment => {
        (result[refAssignment.userID] ??= []).push(refAssignment)
      })
    })
  })

  return result;
}
