import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilapi from "src/composables/InleagueApiV1"
import { type AxiosInstance } from "axios";
import { RouteLocationRaw } from "vue-router";
import { exhaustiveCaseGuard } from "src/helpers/utils";
import authService from "src/helpers/authService";

import { User } from "src/store/User";

/**
 * type of a objects as we expect to deal with on this page
 * At runtime, if it's not expanded in this way, retrieval was wrong
 */
export type ExpandedCompetitionRegistration = iltypes.WithDefinite<iltypes.CompetitionRegistration, "competitionSeason">
export type ExpandedRegistration = iltypes.Registration & {competitions: ExpandedCompetitionRegistration[]};
export type ExpandedChild = iltypes.Child & {
  mostRecentRegistration: ExpandedRegistration,
  permLeagueComment: Exclude<iltypes.Child["permLeagueComment"], undefined>
}

// todo: tight return type with some WithDefinite or similar
export async function getExpandedRegistration(axios: AxiosInstance, registrationID: iltypes.Guid) : Promise<iltypes.Registration> {
  return await ilapi.getRegistration(
    axios, {
      registrationID: registrationID,
      expand: [
        "parent1ID",
        "parent2ID",
        "competitions.competitionSeason",
        "leagueComment",
        "player",
        "player.permLeagueComment", // this is not expanded in getPlayerDetails, is it auto-expanded?
        "registrationAnswers",
        "transactions",
        "lastModifiedByUser",
        "contactPhone",
        "contactName",
        "contact2Phone",
        "contact2Name",
        "photoURL"
      ]});
}

export const TabID_t = {
  'registration-data': 'registration-data',
  'ratings-history': 'ratings-history',
  'player-loan': 'player-loan',
  'scholarship-data': 'scholarship-data',
  'player-logs': 'player-logs',
  'player-merge': 'player-merge',
} as const;
export type TabID_t = (typeof TabID_t)[keyof typeof TabID_t];
