import authService from "src/helpers/authService"
import { Guid } from "src/interfaces/InleagueApiV1"
import { User } from "src/store/User"
import { RouteLocationRaw } from "vue-router"

export const routeName = "invoice-template-listing-main"

export function hasSomeRoutePermission() : boolean {
  const hasSomeRole = authService(User.value.roles, "treasurer", "registrar", "webmaster")
  const isRelevantCompetitionMgr = !!User.userData?.competitionsMemento.find(v => v.isPaymentAdmin)
  return hasSomeRole || isRelevantCompetitionMgr
}

export function route(args: {seasonUID: Guid | undefined}) : RouteLocationRaw {
  return {
    name: routeName,
    query: {
      seasonUID: args.seasonUID
    }
  }
}
