<template lang="pug">
div(data-test="playerLookup-form" v-if="ready")
  .flex.flex-col.items-center.w-full.mt-2(class='md:items-start')
    FormKit(type="form" :actions="false" @submit="search")
      .w-full(class='md:w-96')
        FormKit.m-4.w-full(
          type="text"
          v-model='searchParam',
          label='Player name',
          data-test='playerLookup-input',
          :validation="[['required'], ['matches', /\\S{3}/]]"
          :validation-messages="{matches: `Must be at least 3 characters.`}"
          :class='"m-2 md:w-96"',
        )
      .w-full(class='md:w-96')
        FormKit.m-4(
          v-model='selectedSeason',
          v-if='displaySeasons',
          placeholder='Season (optional)',
          label="Season"
          data-test='selectSeason',
          type='select',
          :options='seasonOptions',
          :class='"m-2 md:w-96"'
        )
      .w-full(class='md:w-96 my-4')
        div(class="flex gap-2 items-center" style="--fk-margin-outer:none;")
          FormKit(
            v-if="showDivisionFilterSwitch"
            type="toggle"
            v-model="enableDivisionFilter"
          )
          div(class="relative")
            DivisionFilter(
              :divisionOptions="divisionOptions"
              :selectedDivIDs="selectedDivIDs"
            )
              template(#errorMessage)
                //- it would be nice to bind to a nodeRef, then emit the messages via `formkitmessages` by way of that noderef,
                //- but it seems like if we do that, it gets rid of the top-level "sorry not all fields are filled out properly" message?
                FormKit(type="group" :plugins="[fkPlugin_externalValidation_divisions]")
                  FormKitMessages()
            div(
              v-if="!enableDivisionFilter"
              class="absolute top-0 left-0 w-full h-full bg-[rgba(255,255,255,.75)]"
            )
      div.w-full
        div.flex.justify-between
          Btn2(type="submit" class="px-2 py-1" data-test="submit")
            div {{ searchButtonLabel }}
          slot(name="cancel")
  .m-1
    .quasar-style-wrap.mt-8(
      v-if='searchResults.length',
      data-cy='searchResultsTable'
    )
      slot(name="aboveTable")
      .q-py-md
        q-table(
          :rows='searchResults',
          :columns='columns',
          row-key='individualID',
          :pagination='initialPagination',
          :rows-per-page-options='[50]',
        )
          template(v-slot:body-cell-Player='cellProperties')
            q-td
              div.cursor-pointer.underline(
                @click='evt => emitSelected(evt, cellProperties.row)'
                :class="{'text-grey-13' : eligibleIDs.includes(cellProperties.row.childID)}"
                :props='cellProperties'
                :data-test="`player-id-${cellProperties.row.childID}/cell=name`"
              )
                //-
                //- We offer a link to the player editor for some (child, registrationID) if there is a target registrationID;
                //- otherwise, we do not offer such a link.
                //-
                //- Note that in either case, we emit a selected event from this HTML element when clicked, in response to which
                //- a parent component might perform a navigation anyway. However, in such a case (i.e. we do not offer a link and emit a selected event
                //- and the parent performs a route navigation in response to that selected event), the user will not be able to right-click and "open in new window",
                //- because the text is not a link. Also note that we emit a selected event even in the case where the user did click the router-link element, and we
                //- performed the navigation from here; in that case, the parent component doesn't have a great way (has no way?) to know that we're already
                //- performing some navigation.
                //-
                router-link(
                  v-if="collapseToMaybeTargetRegistration(cellProperties.row)"
                  :to="{path: `/player-editor/${cellProperties.row.childID}/${collapseToMaybeTargetRegistration(cellProperties.row)}`}"
                )
                  | {{ cellProperties.row.playerFirstName }} {{ cellProperties.row.playerLastName }}
                div(v-else)
                  | {{ cellProperties.row.playerFirstName }} {{ cellProperties.row.playerLastName }}
              div(v-if="belowPlayerNameSlotlike")
                component(:is="belowPlayerNameSlotlike" :playerSearchResult="cellProperties.row")
          template(v-slot:body-cell-Team='cellProperties')
            //-
            //- n.b. in the "target season === 'ALL'" case, there will be no row.registrations
            //-
            q-td(
              :props='cellProperties',
              v-if='cellProperties.row.registrations',
              :data-test="`player-id-${cellProperties.row.childID}/cell=team-or-division-listing`"
            )
              //-
              //- does a loop here make sense? if it's truthy, it's always of length zero-or-one, right?
              //-
              span(
                v-for='reg in cellProperties.row.registrations',
                :key='reg.registrationID'
              )
                template(v-if="uniqueTeamAssignmentsByRegistrationID[reg.registrationID]?.length")
                  component(
                    :is="TeamAssignmentsLegacyLinkListing"
                    :appDomain="appDomain" :teamAssignments="uniqueTeamAssignmentsByRegistrationID[reg.registrationID]"
                  )
                template(v-else)
                  p {{ reg.divName }}
          template(v-slot:body-cell-familyProfile='cellProperties')
            q-td(
              :props='cellProperties',
            )
              router-link(
                :to='familyProfileRouteLocationRawForChild(cellProperties.row.childID)'
              )
                Btn2(class="px-2 py-1")
                  | Family Profile
    div(v-else-if='hasSearched', data-cy='noResults') Sorry, there are no players that match
      span.ml-2.italic "{{ searchParam }}"
</template>

<script lang="tsx">
import { defineComponent, ref, Ref, onMounted, watch, computed } from 'vue'

import { axiosInstance } from 'src/boot/axios'
import { dayJSDate } from 'src/helpers/formatDate'
import * as FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"
import { propsDef, emitsDef } from "./PlayerLookup.main-ts-shim"

import { TeamAssignmentsLegacyLinkListing } from "./PlayerLookup.elems"
import { doPlayerSearch, ExpandedPlayerSearchResult } from "./PlayerLookup.shared"

import * as iltypes from "src/interfaces/InleagueApiV1"
import { Client } from 'src/store/Client'
import { arrayFindIndexOrFail, arrayFindOrFail, assertTruthy, UiOption, vReqT } from 'src/helpers/utils'
import { SelectManyPane, SlotProps as SelectManyPaneSlotProps } from 'src/components/RefereeSchedule/SelectManyPane'
import { Guid } from 'src/interfaces/InleagueApiV1'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faListCheck } from '@fortawesome/pro-solid-svg-icons'
import { allowsUnconstrainedDivSearch, getAllowedDivisions, requiresDivConstrainedSearch } from './PlayerLookup.route'
import { FormKitNode } from "@formkit/core";
import { FormKitMessages } from "@formkit/vue"
import { Btn2 } from 'src/components/UserInterface/Btn2'

// We write additional properties into Registration objects in some code paths.
// This can probably be made not necessary.
type AugmentedRegistration = iltypes.Registration & {divName?: string};

const DivisionFilter = defineComponent({
  props: {
    divisionOptions: vReqT<UiOption[]>(),
    selectedDivIDs: vReqT<Guid[]>(),
  },
  setup(props, {slots}) {
    const selectedDivisionsUiString = computed(() => {
      if (props.divisionOptions.length === 0) {
        return ""
      }
      if (props.selectedDivIDs.length === 0) {
        return ""
      }
      if (allowsUnconstrainedDivSearch() && props.selectedDivIDs.length === props.divisionOptions.length) {
        // Separately, in the "requires div constrained search" case, the user can still "select all", but the "all" we offer is not a full list of
        // divs but rather the divs available to them as per their permissions. So this message only makes sense in the allowsUnconstrainedDivSearch case.
        return "Any division"
      }

      const firstSelectedDivLabel = arrayFindOrFail(props.divisionOptions, opt => opt.value === props.selectedDivIDs[0]).label
      if (props.selectedDivIDs.length === 1) {
        return firstSelectedDivLabel
      }
      else {
        const howManyOthers = props.selectedDivIDs.length - 1
        const other = howManyOthers === 1 ? "other" : "others";
        return `${firstSelectedDivLabel} and ${howManyOthers} ${other}`
      }
    })

    return () => {
      return (
        <SelectManyPane
          selectedKeys={props.selectedDivIDs}
          options={props.divisionOptions}
          offerAllOption={true}
          useModalOnSmallScreens={true}
          modalTitle={() => <>
            <div>Player has program registrations in...</div>
            <div class="border-b border-gray-200 my-2"/>
          </>}
          onCheckedAll={checked => {
            if (checked) {
              props.selectedDivIDs.splice(0, props.selectedDivIDs.length, ...props.divisionOptions.map(v => v.value));
            }
            else {
              props.selectedDivIDs.splice(0, props.selectedDivIDs.length);
            }
          }}
          onCheckedOne={(divID, checked) => {
            if (checked) {
              props.selectedDivIDs.push(divID)
            }
            else {
              props.selectedDivIDs.splice(arrayFindIndexOrFail(props.selectedDivIDs, isSelected => isSelected === divID), 1);
            }
          }}
        >
          {{
            default: (props : SelectManyPaneSlotProps["default"]) => {
              return (
                <div class="flex items-center flex-grow gap-2">
                  <button
                    type="button"
                    onClick={() => props.toggle()}
                    class={`p-1 rounded-md text-blue-700 cursor-pointer underline bg-white ${props.isOpen.value ? 'bg-slate-200' : 'hover:bg-slate-100 active:bg-slate-200'}`}
                  >
                    <FontAwesomeIcon icon={faListCheck} v-tooltip={{content: 'Select multiple'}} {...{tabindex: "-1", style: "outline:none;"}}/>
                  </button>
                  <div>
                    <div class="text-sm">Player has program registration in division...</div>
                    <div>{selectedDivisionsUiString.value}</div>
                    <div>{slots.errorMessage?.()}</div>
                  </div>
                </div>
              )
            }
          }}
        </SelectManyPane>
      );
    }
  }
})

export default defineComponent({
  name: 'PlayerLookup',
  props: propsDef,
  emits: emitsDef,
  components: {
    DivisionFilter,
    FormKitMessages,
    Btn2,
  },
  setup(props, { emit }) {
    const ready = ref(false)
    const searchResults = ref([]) as Ref<ExpandedPlayerSearchResult[]>
    const initialPagination = {
      sortBy: 'asc',
      rowsPerPage : 25
    };
    const searchParam = ref('')
    const uniqueTeamAssignmentsByRegistrationID = ref<{[registrationID: iltypes.Guid]: undefined | iltypes.TeamAssignment[]}>({})

    /**
     * 0 means "ALL", otherwise it is the seasonID but prefixed w/ `season`
     */
    const selectedSeason = ref<'0' | `season${iltypes.Integerlike}`>('0')
    /**
     * keys here are either "0" (i.e. ALL) or `season${int}`
     */
    const seasonOptions = ref<{[K in "0" | `season${iltypes.Integerlike}`]?: /*uiLabel*/ string}>({})
    const hasSearched = ref(false)

    const columns = computed(() => {
      const hasFamilyProfileCol = FamilyProfile.authZ_canViewAnyNonOwnFamily();
      const col : {name: string, [key: string]: any}[] = [
        {
          name: 'Player',
          required: false,
          label: 'Player',
          align: 'left',
          sortable: true,
          field: (player: ExpandedPlayerSearchResult) => {
            return `${player.playerFirstName} ${player.playerLastName}`
          },
        },
        {
          name: 'Parent',
          align: 'left',
          label: 'Parent',
          field: (player: ExpandedPlayerSearchResult) => {
            return `${player.parent1FirstName} ${player.parent1LastName}`
          },
          sortable: true,
        },
        {
          name: 'Email',
          align: 'left',
          label: 'Email',
          field: (player: ExpandedPlayerSearchResult) => {
            return player.parent1Email
          },
          sortable: true,
        },
        {
          name: 'DOB',
          align: 'left',
          label: 'Birthdate',
          field: (player: ExpandedPlayerSearchResult) => {
            return `${dayJSDate(player.playerBirthDate)}`
          },
          sortable: true,
        },
        {
          name: 'ID',
          align: 'left',
          label: 'ID',
          field: (player: ExpandedPlayerSearchResult) => {
            return player.stackSID
          },
          sortable: false
        },
        ...hasFamilyProfileCol
          ? [{
            name: 'familyProfile',
            align: 'left',
            label: 'Family Profile',
            field: (player: ExpandedPlayerSearchResult) => {
              return player.familyID
            },
            sortable: true,
          }]
          : []
      ]

      // if Season has been selected add Team field
      if (parseInt(selectedSeason.value.replace('season', ''))) {
        col.push({
          name: 'Team',
          align: 'left',
          label: 'Team',
          field: (player: ExpandedPlayerSearchResult) => {
            return ''
          },
          sortable: true,
        })
      }

      return col
    })



    /**
     * This appears to want to augment a Registration with it's associated division's division name.
     *
     * This behavior is probably not correct, because a single registration is composed of many competition registrations,
     * all of which may be for some separate division. A registration can sometimes be considered to have some "primary"
     * division, but that "primary division" is usually "just" the division of the most recently generated competition registration.
     */
    const addDivisions = async (searchResults: ExpandedPlayerSearchResult[]) => {
      if (selectedSeason.value && searchResults[0].registrations) {
        for (let i = 0; i < searchResults.length; i++) {
          const registrations = searchResults[i].registrations as AugmentedRegistration[]
          const division = await Client.getDivisionByID(registrations[0].divID)
          // we don't expect to hit the righthand side fallback case here, but let's not crash if we do.
          registrations[0].divName = division?.division || "(no division name on file)";
        }
      }
      return searchResults
    }

    const search = async () => {
      uniqueTeamAssignmentsByRegistrationID.value = {}

      if (enableDivisionFilter.value) {
        // form should have constrained this
        assertTruthy(selectedDivIDs.value.length > 0);
      }

      if (searchParam.value) {
          const maybeSeasonIdTarget = seasonIdOrAll_fromSelectedSeasonID(selectedSeason.value);
          const response = await doPlayerSearch(
            axiosInstance,
            searchParam.value,
            maybeSeasonIdTarget,
            enableDivisionFilter.value
              ? selectedDivIDs.value
              : [] // empty meaning "no constraint"
          );

          //
          // two operations:
          //  - maybe augment with divisions (TODO: clarify why? what is the meaning of checking for the first result's registrations property?)
          //    - something like "if `registrations` is defined then we can infer that were searching for a specific seasonID (which somehow means 'need to augment with div info')" ??
          //  - apply some filter, if a filter has been provided
          //
          searchResults.value = await (async () => {
            const v = response[0]?.registrations
              ? await addDivisions([...response])
              : response

            return props.displayFilter ? v.filter(props.displayFilter) : v;
          })();

          hasSearched.value = true

          //
          // Extract team assignments and uniquify (we had gotten duplicates in the past ... are duplicates really [still] possible?).
          //

          for (const searchResult of searchResults.value) {
              // in the "ALL" case, we don't have a "target season", so we rely on the "team assignments current" value
              // otherwise, there was some specific target season, which means there may have been a registration that would auto-expand
              const teamAssignments : iltypes.TeamAssignment[] = maybeSeasonIdTarget === "ALL"
                ? searchResult.teamAssignmentsCurrent
                : (searchResult.registrations?.[0]?.teamAssignments ?? []); // if there is a registration there's exactly zero-or-one

              const seenTeamIDs = new Set<iltypes.Guid>();
              for (const teamAssignment of teamAssignments) {
                if (seenTeamIDs.has(teamAssignment.teamID)) {
                  // skip duplicates (do we expect to get duplicates? we've seen some)
                  continue;
                }
                (uniqueTeamAssignmentsByRegistrationID.value[teamAssignment.registrationID] ??= []).push(teamAssignment)
                seenTeamIDs.add(teamAssignment.teamID);
              }
          }

          emit("gotLookupResults", {"liveRef!": searchResults.value});
      }
    }

    const emitSelected = (evt: Event, row: ExpandedPlayerSearchResult) => {
      emit('selected', { row: row, seasonID: seasonIdOrAll_fromSelectedSeasonID(selectedSeason.value) })
    }

    const enableDivisionFilter = ref(false);
    const showDivisionFilterSwitch = ref(false);
    const divisionOptions = ref<UiOption[]>([])
    const selectedDivIDs = ref<Guid[]>([])

    onMounted(async () => {
      await Client.loadSeasons()

      {
        const allowedDivisions = getAllowedDivisions();
        divisionOptions.value = (await Client.getDivisions())
          .filter(div => {
            return allowedDivisions === "*" || allowedDivisions.find(divID => div.divID === divID);
          })
          .map((div) : UiOption => {
            return {
              label: div.displayName || div.division,
              value: div.divID,
            }
          });

        selectedDivIDs.value = divisionOptions.value.map(opt => opt.value)

        const canDisableDivFilter = allowsUnconstrainedDivSearch();

        // Users who can disable it usually just want it disabled.
        // So, initially enabled IFF they are not allowed to disable it.
        enableDivisionFilter.value = !canDisableDivFilter
        // Show the ability to toggle the filter IFF they are allowed to disable it
        showDivisionFilterSwitch.value = canDisableDivFilter
      }

      seasonOptions.value = (() => {
        const result : (typeof seasonOptions.value) = {}
        for (const season of Client.value.seasons) {
          result[`season${season.seasonID}`] = season.seasonName;
        }
        return result;
      })()

      seasonOptions.value[0] = 'All Seasons'

      if (props.defaultSeason === "all") {
        selectedSeason.value = "0";
      }
      else if (props.defaultSeason === "auto") {
        const desiredInitialSelectedValue = `season${Client.value.instanceConfig.registrationseasonid as iltypes.Integerlike}` as const;
        if (seasonOptions.value[desiredInitialSelectedValue]) {
          selectedSeason.value = desiredInitialSelectedValue;
        }
      }
      else {
        // unreachable (see prop type def)
      }
      Client.value.instanceConfig.appdomain

      ready.value = true
    })

    watch(
      selectedSeason,
      async () => {
        await search()
      },
      { immediate: false }
    )

    watch(searchParam, () => {
      hasSearched.value = false
      searchResults.value = []
    })

    /**
     * there's a different source for the "target" registration for a player search result, based on
     * how the search was performed. This is the unifying getter.
     */
    const collapseToMaybeTargetRegistration = (v: ExpandedPlayerSearchResult) : string => {
      //
      // a search focusing on a specific season ID will try to return the player's registration (and only that single registration)
      // for that season. Such a registration may not exist.
      //
      const someSpecificPrimaryRegistrationIdYieldedViaSearchAgainstOneSeason : string | undefined = v.registrations?.[0]?.registrationID;
      //
      // a search focusing on "all/any" seasons will return a registrationID in the `mostRecentRegistrationID` property; such a registration may not exist.
      //
      const someSpecificPrimaryRegistrationIdYieldedViaSearchAcrossAllSeasons : string | undefined = v.mostRecentRegistrationID;
      return someSpecificPrimaryRegistrationIdYieldedViaSearchAgainstOneSeason
        || someSpecificPrimaryRegistrationIdYieldedViaSearchAcrossAllSeasons
        || "";
    }

    const fkPlugin_externalValidation_divisions = (() => {
      const key = "playerLookup/divisions"
      const node = ref<FormKitNode | null>(null)

      const plugin = (xnode: FormKitNode) => {
        node.value = xnode;
        return false;
      }

      const update = () => {
        if (!node.value) {
          return;
        }

        if (selectedDivIDs.value.length === 0) {
          // does "type" matter?
          // the `blocking` prop seems to be the important part
          node.value.store.set({
            type: "il-validation",
            blocking: true,
            value: "Select at least 1 division",
            visible: true,
            key,
            meta: {}
          })
        }
        else {
          node.value.store.remove(key)
        }
      }

      watch(() => selectedDivIDs.value, update, {immediate: true, deep: true});
      watch(() => node.value, update, {immediate: true, deep: false});

      return plugin;
    })();

    return {
      ready,
      searchResults,
      uniqueTeamAssignmentsByRegistrationID,
      initialPagination,
      searchParam,
      selectedSeason,
      seasonOptions,
      columns,
      search,
      emitSelected,
      hasSearched,
      familyProfileRouteLocationRawForChild: (childID: string) => {
        return FamilyProfile.asRouteLocationRaw({name: FamilyProfile.RouteName.child, childID})
      },
      TeamAssignmentsLegacyLinkListing,
      appDomain: computed(() => {
        return Client.value.instanceConfig.appdomain
      }),
      collapseToMaybeTargetRegistration,
      divisionOptions,
      selectedDivIDs,
      fkPlugin_externalValidation_divisions,
      enableDivisionFilter,
      showDivisionFilterSwitch,
    }
  },
})

/**
 * We want to be clear about the "selected seasonID", where options are either "some integer seasonID or all seasons"
 * This information is currently encoded in a stringly typed representation that callers must parse out.
 *
 * Currently, "0" means "ALL" and `seasonX` means seasonID X, but the "season" prefix must be removed to extract the ID.
 */
function seasonIdOrAll_fromSelectedSeasonID(seasonID: `0` | `season${iltypes.Integerlike}`) : iltypes.Integerlike | "ALL" {
  return /^season(?:\d+)$/.test(seasonID)
    ? parseInt(seasonID.replace('season', ''))
    : "ALL";
}

</script>

<style>
.underline {
  text-decoration: underline;
}
</style>
