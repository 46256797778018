import { rpc } from "src/composables/Rpc";
import { Child, DateTimelike, Floatlike, Guid, Integerlike, Invoice, Numbool, Season, SeasonTriple } from "src/interfaces/InleagueApiV1";

export interface InvoiceTemplate {
	invoiceID: Guid,
	invoiceNum: Integerlike,
	allowedPaymentMethods: ("card" | "us_bank_account")[],
	invoiceDesc: string,
	invoiceLabel: string,
	contactEmail: string,
	createdBy: Guid | {
    firstName: string,
    lastName: string,
    userID: Guid,
    email: string,
  },
	paymentGatewayID: Guid,
	dateCreated: DateTimelike,
	amount: Floatlike,
	relevantUntil: DateTimelike,
	archived: Numbool,
	season: SeasonTriple,
	competitionUID: Guid,
	teamID: Guid,
	allowSinglePayment: Numbool,
	emailEveryReceipt: Numbool,
	fixedDueDate: DateTimelike,
	regScholarship: Numbool,
	eventScholarship: Numbool,
	teamScholarship: Numbool,
	clientID: Guid,
	regionInvoiceNum: Integerlike,
	isTemplate: Numbool,
	stripe_productDate: DateTimelike,
  instanceCount: Integerlike,
  paidInstancesCount: Integerlike,
  /**
   * Expandabled-or-auto-included from some endpoints
   */
  paymentGateway?: {paymentGatewayID: Guid, name: string},
	paymentMethods: InvoiceTemplatePaymentMethod[]
}

export type InvoiceTemplatePaymentMethodIntervalUnit = "months" | "days"

export interface InvoiceTemplatePaymentMethod {
	methodID: Integerlike,
	invoiceID: Guid,
	amount: Integerlike,
	disabled: Numbool,
	intervalLength: Integerlike,
	intervalUnit: InvoiceTemplatePaymentMethodIntervalUnit,
	totalOccurrences: Integerlike,
	trialAmount: "" | Floatlike,
	trialOccurrences: "" | Integerlike,
}

export interface InvoiceTemplatePaymentMethodAction_Create {
	action: "create",
	amount: Integerlike,
	intervalLength: Integerlike,
	intervalUnit: InvoiceTemplatePaymentMethodIntervalUnit,
	totalOccurrences: Integerlike,
	trialAmount: undefined | "" | Floatlike,
	trialOccurrences: undefined | "" | Integerlike,
}

export interface InvoiceTemplatePaymentMethodAction_Delete {
	action: "delete",
	paymentMethodID: Integerlike
}

export interface CreateInvoiceTemplateArgs {
	contactEmail: string,
	invoiceLabel: string,
	invoiceDesc: string,
	emailEveryReceipt: boolean,
	paymentGatewayID: Guid,
	seasonUID: Guid,
	amount: Floatlike,
	archived: boolean,
	paymentMethods: InvoiceTemplatePaymentMethodAction_Create[],
	allowedPaymentMethods: ("card" | "us_bank_account")[],
}

export interface UpdateInvoiceTemplateArgs {
	invoiceTemplateID: Guid,
	contactEmail: string,
	invoiceLabel: string,
	invoiceDesc: string,
	emailEveryReceipt: boolean,
	archived: boolean,
	paymentGatewayID: Guid,
	seasonUID: Guid,
	amount: Floatlike,
	paymentMethods: (
		| InvoiceTemplatePaymentMethodAction_Create
		| InvoiceTemplatePaymentMethodAction_Delete
	)[],
	allowedPaymentMethods: CreateInvoiceTemplateArgs["allowedPaymentMethods"]
}

export interface InvoiceInstanceForInvoiceTemplate {
  invoiceInstanceID: Integerlike,
	invoiceTemplateID: Guid,
	invoiceInstanceNum: string,
	/**
	 * investigate: endpoint would send ID here; and map to lookup into, and then we wire it up here?
	 * We'd save some bandwidth, but otherwise not too big a concern. They will often be the
	 * same for large requests ('every invoice instance for some invoice template' means the results
	 * will all share the same invoice template). gzip probably does a good enough job figuring out
	 * the dupes anyway.
	 */
  invoiceTemplate: InvoiceTemplate,
  status: string,
  lineItemSum: number,
	lineItems: {lineItemID: number}[],
	transactionBalance: number,
  transactionCount: Integerlike | "",
	paymentGatewayID: Guid,
  discount: null | {
    type: "dollar" | "percent",
    value: Floatlike
  },
  entity:
    | {
      type: "player",
			entityID: Guid,
      data: {
        firstName: string,
        lastName: string,
        parent1: {
          firstName: string,
          lastName: string,
          email: string,
        },
        registration: null | {
          registrationID: Guid,
          division: {
            divID: Guid,
            division: string
						divNum: Integerlike,
						gender: string,
          }
        }
      }
    }
    | {
      type: "user",
			entityID: Guid,
      data: {
        firstName: string,
        lastName: string,
        email: string,
      }
    }
}

export type PlayerishPayorCandidate = {
  childID: Guid,
  playerFirstName: string,
  playerLastName: string,
  parent1FirstName: string,
  parent1LastName: string,
  parent1Email: string
}

export const getInvoiceTemplateSeasonOptions = rpc<void, {seasons: Season[]}>("get", "v1/invoiceTemplates/getInvoiceTemplateSeasonOptions");
export const listInvoiceTemplates = rpc<{seasonUID: Guid}, InvoiceTemplate[]>("get", "v1/invoiceTemplates/listInvoiceTemplates")
export const listInvoiceInstancesForInvoiceTemplate = rpc<{invoiceTemplateID: Guid}, InvoiceInstanceForInvoiceTemplate[]>("get", "v1/invoiceTemplates/listInvoiceInstancesForInvoiceTemplate")
export const getInvoiceTemplateBasedInvoiceInstance = rpc<{invoiceInstanceID: Guid}, InvoiceInstanceForInvoiceTemplate>("get", "v1/invoiceTemplates/getInvoiceTemplateBasedInvoiceInstance")
export const getInvoiceTemplate = rpc<{invoiceTemplateID: Guid}, InvoiceTemplate>("get", "v1/invoiceTemplates/getInvoiceTemplate")
export const createInvoiceTemplate = rpc<CreateInvoiceTemplateArgs, InvoiceTemplate>("post", "v1/invoiceTemplates/createInvoiceTemplate")
export const updateInvoiceTemplate = rpc<
	Pick<UpdateInvoiceTemplateArgs, "invoiceTemplateID"> & Partial<UpdateInvoiceTemplateArgs>,
	InvoiceTemplate
>("post", "v1/invoiceTemplates/updateInvoiceTemplate")
export const findInvoicePayorCandidates = rpc<{invoiceTemplateID: Guid, playerName: string, registeredOnly: boolean}, Child[]>("get", "v1/invoiceTemplates/findInvoicePayorCandidates")
export const createInvoiceInstancesForPlayerPayors = rpc<{
	invoiceTemplateID: Guid,
	childIDs: Guid[],
	instanceDesc: string,
	emailOnCreation: boolean,
	dollarDiscount: Floatlike | undefined,
	percentDiscount: Integerlike | undefined, // integer in range [0, 100]
}, InvoiceInstanceForInvoiceTemplate[]>("post", "v1/invoiceTemplates/createInvoiceInstancesForPlayerPayors")
export const getInvoiceTemplatePaymentMethods = rpc<
	{invoiceTemplateID: Guid, invoiceInstanceID: Integerlike | undefined},
	(InvoiceTemplatePaymentMethod & {tailoredDescription: string})[]
>("get", "v1/invoiceTemplates/getInvoiceTemplatePaymentMethods")
export const updateInvoiceTemplateBasedInvoiceInstancePaymentMethodID = rpc<{
	invoiceInstanceID: Integerlike,
	paymentMethodID: "" | Integerlike
}, void>("post", "v1/invoiceTemplates/updateInvoiceTemplateBasedInvoiceInstancePaymentMethodID")
export const getInvoiceTemplateBulkAssignInfo = rpc<
{keyType: "registrationID" | "childID", keys: Guid[]},
{
	inferredInitialSeasonUID: Guid,
	keysHavingNoMatch: Guid[],
	playerishPayorCandidates: PlayerishPayorCandidate[],
}>("get", "v1/invoiceTemplates/getInvoiceTemplateBulkAssignInfo")

export const bulkOps_getDataFromToken = rpc<{dataToken: string}, unknown>("get", "v1/invoiceTemplates/bulkOps_getDataFromToken");
