import type { Guid, Integerlike } from 'src/interfaces/InleagueApiV1'
import { clientsThemes, maybeFindClientThemeVariant } from 'src/helpers/clientsThemes'
import { updateDOMThemingAttrs_ } from 'src/helpers/clientCustomization'
import { axiosBackgroundInstance, axiosInstance, axiosNoAuthInstance } from 'src/boot/axios'
import { type LeagueDomainDetails } from "src/composables/InLeagueApiV1.Authenticate.Common";

import * as ilapi from "src/composables/InleagueApiV1"

import { System } from './System'
import { getCompetitionsOrFail } from "./Competitions"

import { ChildDivisionsForUserSeason, InstanceConfig } from 'src/interfaces/InleagueApiV1'
import { ClientI, RefSlotConfig, RefSlotConfigMapping, RefSlotConfigMappingRaw, RefSlotConfigRaw, TeamI } from 'src/interfaces/Store/client'
import type { Season, Competition, Division } from 'src/interfaces/InleagueApiV1'
import * as ClearOnLogout from "src/store/ClearOnLogout"
import { ref } from 'vue';
import { DeepConst, isGuidUpper, isObject } from 'src/helpers/utils';

import { User } from "./User"
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise';
import { Field, getPlayingFields } from 'src/composables/InleagueApiV1';
import { AxiosInstance } from 'axios';

export const Client = (() => {
  const state = ref(freshState())

  /**
   * Lifecycle considerations dictate that we predicate success here on a truthy/non-empty instanceConfig.
   * Conceptually this makes a copy of a theme from the list in clientThemes.ts and places the result into the store as "the current theme"
   * Consider: it should be an error to read `.instanceConfig` when it has not been set yet (via a getter or a proxy or etc.), and callers
   * should be forced to consider application lifecycle in case of such errors.
   */
  function maybeSetClientThemeByWayOfCurrentClientIDAndPublicPathAndUser() : void {
    const instanceConfig = state.value.instanceConfig
    const publicPath = state.value.publicPath

    if (instanceConfig && Object.keys(instanceConfig).length && clientsThemes[instanceConfig.clientid]) {
      const theme = (() => {
        const themeOrThemes = clientsThemes[instanceConfig.clientid]
        if (Array.isArray(themeOrThemes)) {
          // prefer an instanceConfig variant override, then a user specific theme override, then just choose the "default" theme if nothing matched
          return maybeFindClientThemeVariant(instanceConfig.frontendThemeVariant, themeOrThemes)
            ?? maybeFindClientThemeVariant(User.userData?.frontendThemeVariant, themeOrThemes)
            ?? themeOrThemes[0]
        }
        else {
          // the only one available
          return themeOrThemes
        }
      })()

      state.value.clientTheme = {
        //
        // definitely set
        //
        variant: theme.variant,
        name: theme.name,
        color: theme.color,
        banner: publicPath + theme.banner,
        //
        // maybe set -- if there is no "favIcon" or publicPath available, we leave the target property effectively unmodified
        //
        favicon: (theme.favicon && publicPath)
          ? publicPath + theme.favicon
          : state.value.clientTheme.favicon
      }
    }
  }

  /**
   * Hit the API `getDivisions` endpoint and assign the result to local cache
   */
  async function loadDivisions(ax: AxiosInstance = axiosInstance) {
      const divisions = await ilapi.getDivisions(ax);
      state.value.divisions = divisions
      return divisions
  }

  /**
   * Like `loadDivisions` but reuses a cached list if we have it
   */
  async function getDivisions(ax: AxiosInstance = axiosInstance) {
    if (!state.value.divisions.length) {
      await loadDivisions(ax)
    }
    return state.value.divisions
  }

  const __seasons = ReactiveReifiedPromise<Season[]>()
  async function loadSeasons(ax?: AxiosInstance) {
    if (state.value.seasons.length === 0) {
      const seasons = await (__seasons.underlying.status === "idle"
        ? __seasons.run(() => ilapi.getSeasons(ax || axiosInstance)).getResolvedOrFail()
        : __seasons.getResolvedOrFail())

      directCommit_setSeasons(seasons)

      return seasons
    } else {
      return state.value.seasons
    }
  }

  async function loadTeams() {
    const response = await axiosInstance.get('v1/teams')
    directCommit_setTeams(response.data.data)
  }

  const __fields = ReactiveReifiedPromise<Field[]>();
  async function loadFields(ax : AxiosInstance = axiosInstance) {
    // @TODO: "runIfIdle" member function on ReactiveReifiedPromise
    // @TODO: remove exposed `fields` property
    return state.value.fields = await (__fields.underlying.status === "idle"
      ? __fields.run(() => getPlayingFields(ax)).getResolvedOrFail()
      : __fields.getResolvedOrFail())
  }

  async function getSeasonByUID(seasonUID: string, ax?: AxiosInstance) {
    if (!state.value.seasons.length) {
      await loadSeasons(ax)
    }
    for (let i = 0; i < state.value.seasons.length; i++) {
      if (state.value.seasons[i].seasonUID === seasonUID) {
        return state.value.seasons[i]
      }
    }
  }

  async function getSeasonByUidOrFail(seasonUID: string, ax?: AxiosInstance) : Promise<Season> {
    const season = await getSeasonByUID(seasonUID, ax)
    if (season) {
      return season
    }
    else {
      throw Error(`no season having seasonUID=${seasonUID}`)
    }
  }

  async function getSeasons(ax?: AxiosInstance) : Promise<Season[]> {
    if (!state.value.seasons.length) {
      await loadSeasons(ax)
    }
    return state.value.seasons;
  }

  async function getSeasonsMap() : Promise<{[seasonUID: Guid]: Season}> {
    if (!state.value.seasons.length) {
      await loadSeasons()
    }
    // at this point `state.value.seasons` is updated
    const result : {[seasonUID: Guid]: Season} = {};
    for (const season of state.value.seasons) {
      result[season.seasonUID] = season;
    }
    return result;
  }

  async function getDivisionByID(divID: string) {
    if (!state.value.divisions.length) {
      await loadDivisions()
    }
    // console.log('before for loop')
    for (let i = 0; i < state.value.divisions.length; i++) {
      if (state.value.divisions[i].divID === divID) {
        return state.value.divisions[i]
      }
    }
  }

  async function getDivisionByIdOrFail(divID: string) : Promise<Division> {
    const division = await getDivisionByID(divID)
    if (division) {
      return division;
    }
    else {
      throw Error(`no division having divID=${divID}`)
    }
  }

  async function getTeamByID(teamID: string) {
    if (!state.value.teams.length) {
      await loadTeams()
    }
    // console.log('before for loop')
    for (let i = 0; i < state.value.teams.length; i++) {
      if (state.value.teams[i].teamID === teamID) {
        return state.value.teams[i]
      }
    }
  }

  async function getDivisionByNum(divNum: number) {
    if (!state.value.divisions.length) {
      await loadDivisions()
    }
    // console.log('before for loop')
    for (let i = 0; i < state.value.divisions.length; i++) {
      if (state.value.divisions[i].divNum === divNum) {
        return state.value.divisions[i]
      }
    }
  }

  async function getCompetitionByUID(compUID: Guid) : Promise<Competition | undefined> {
    const competitions = (await getCompetitionsOrFail()).value;
    return competitions.find(v => v.competitionUID === compUID)
  }

  async function getCompetitionByID(compID: Integerlike) : Promise<Competition | undefined> {
    const competitions = (await getCompetitionsOrFail()).value;
    return competitions.find(v => v.competitionID /*weakEq*/ == compID)
  }

  async function getCompetitionByUidOrFail(competitionUID: string) : Promise<Competition> {
    const maybeCompetition = await getCompetitionByUID(competitionUID)
    if (maybeCompetition) {
      return maybeCompetition;
    }
    else {
      throw Error(`No competition having competitionUID=${competitionUID}`)
    }
  }

  async function getCompetitionByIdOrFail(competitionID: Integerlike) : Promise<Competition> {
    const maybeCompetition = await getCompetitionByID(competitionID)
    if (maybeCompetition) {
      return maybeCompetition;
    }
    else {
      throw Error(`No competition having competitionID=${competitionID}`)
    }
  }

  const __refSlotConfigResolver = ReactiveReifiedPromise<RefSlotConfigMapping>()
  async function getRefSlotConfigLookup() {
    const mapping = __refSlotConfigResolver.underlying.status === "resolved"
      ? __refSlotConfigResolver.underlying.data
      : __refSlotConfigResolver.underlying.status === "pending"
      ? await __refSlotConfigResolver.getResolvedOrFail()
      : await __refSlotConfigResolver.run(() => getRefSlotConfigMapping(axiosBackgroundInstance)).getResolvedOrFail();

    return new RefSlotConfigLookup(mapping)
  }

  function updateDOMThemingAttrs() : void {
    updateDOMThemingAttrs_(state.value.clientTheme)
  }

  async function getInstanceConfig(): Promise<InstanceConfig> {
    return await ilapi.getInstanceConfig(axiosNoAuthInstance);
  }

  /**
   * - caller is expected to have updated the relevant axios instance's API url to the specific mobile URL
   * - this asks for that client's instanceConfig (implicitly by way of the axios instance's target URL)
   * - forward results to `customizeLeagueDisplay`
   */
  async function mobileClientCustomization() {
    const instanceConfig = await getInstanceConfig()
    directCommit_setInstanceConfig(instanceConfig)
    await customizeLeagueDisplay(
      { appDomain: instanceConfig.appdomain, clientID: instanceConfig.clientid, regionName: instanceConfig.shortname }
    )
  }

  async function customizeLeagueDisplay(leagueDetails: LeagueDomainDetails) {
    // "setting clientUrl" this has a big side effect: when `clientUrl` is truthy, it means we've selected a particular target league and have emerged from a context
    // where we on mobile and did not yet know which league to target
    System.directCommit_setClientUrl(leagueDetails.appDomain)

    System.directCommit_setClientLeague(leagueDetails.regionName)

    directCommit_setClientID(leagueDetails.clientID)

    maybeSetClientThemeByWayOfCurrentClientIDAndPublicPathAndUser()
    updateDOMThemingAttrs_(state.value.clientTheme)
  }

  async function getActiveDivisionsForSeason(seasonUID: string): Promise<Division[]> {
    // There are some cases where this is called in quick succession, by separate components, and if we hadn't fully resolved the result yet,
    // we'd initiate another request, because we couldn't discern the difference between "request for season S is in flight" and "have never been asked for season S"
    // So since this method was already async, we'll store the promise in the state, and callers just always await (well, they were already required to)
    // The possible downside is that we have to await even a direct property lookup like `store.state.value.client.activeDivisionsForSeason[S]`
    const maybePromise = state.value.activeDivisionsForSeason[seasonUID];
    if (!maybePromise) {
      const resultPromise = ilapi.getActiveDivisionsForSeason(axiosInstance, seasonUID);
      directCommit_setActiveDivisionsBySeason({ seasonUID, divisions: resultPromise })
      return resultPromise;
    }
    else {
      return maybePromise;
    }
  }

  // probably should be on user store ?
  async function loadChildDivisionsForUserSeason(args: { userID: string, seasonUID: string }) {
    const results = await ilapi.getChildDivisionsForUserSeason(axiosInstance, args.userID, args.seasonUID);

    directCommit_setChildDivisionsForUserSeason({
      userID: args.userID,
      seasonUID: args.seasonUID,
      data: results
    });

    return results;
  }

  // probably should be on user store ?
  async function getChildDivisionsForUserSeason(args: { userID: string, seasonUID: string }) {
    return state.value.childDivisionsForUserSeason[args.userID]?.[args.seasonUID] ?? (await loadChildDivisionsForUserSeason(args));
  }

  //
  // from mutations
  //

  function directCommit_setPublicPath(path: string) {
    state.value.publicPath = path
  }

  function directCommit_setInstanceConfig(info: InstanceConfig): void {
    state.value.instanceConfig = info
    state.value.stripePublicKey = info.isproduction
      ? process.env.stripePublicKey_live
      : process.env.stripePublicKey_test;
  }

  function directCommit_setClientID(id: string): void {
    state.value.instanceConfig.clientid = id
  }

  function directCommit_setSeasons(seasons: Season[]) {
    state.value.seasons = seasons
  }

  function directCommit_setTeams(teams: TeamI[]) {
    state.value.teams = teams
  }

  function directCommit_clearClientData() {
    state.value.divisions = []
    state.value.seasons = []

    __refSlotConfigResolver.reset();

    state.value.teams = []
    state.value.fields = []

    ClearOnLogout.runAll(); // this should be earlier in src/store/user/actions.ts#logoutUser
  }

  function directCommit_setActiveDivisionsBySeason(payload: { seasonUID: string, divisions: Promise<Division[]> }) {
    state.value.activeDivisionsForSeason[payload.seasonUID] = payload.divisions;
  }

  function directCommit_setChildDivisionsForUserSeason(payload: { userID: string, seasonUID: string, data: ChildDivisionsForUserSeason }) {
    state.value.childDivisionsForUserSeason ??= {};
    state.value.childDivisionsForUserSeason[payload.userID] ??= {};
    state.value.childDivisionsForUserSeason[payload.userID][payload.seasonUID] = payload.data;
  }

  return {
    get value() : DeepConst<ClientI> { return state.value },
    customizeLeagueDisplay,
    updateDOMThemingAttrs,
    maybeSetClientThemeByWayOfCurrentClientIDAndPublicPathAndUser,
    getChildDivisionsForUserSeason,
    getActiveDivisionsForSeason,
    getInstanceConfig,
    getRefSlotConfigLookup,
    getSeasonByUID,
    getSeasonByUidOrFail,
    getSeasonsMap,
    getSeasons,
    getCompetitionByUID,
    getCompetitionByID,
    getCompetitionByUidOrFail,
    getCompetitionByIdOrFail,
    getDivisions,
    getDivisionByID,
    getDivisionByIdOrFail,
    loadFields,
    loadDivisions,
    loadSeasons,
    mobileClientCustomization,
    directCommit_clearClientData,
    directCommit_setClientID,
    directCommit_setInstanceConfig,
    directCommit_setPublicPath,
  }
})()

function defaultRefSlotConfigMapping() : RefSlotConfigMapping {
  return {
    competitions: {},
    default: {
      competitionUID: null,
      divID: null,
      pos1Val: 1,
      pos2Val: 1,
      pos3Val: 1,
      pos4Val: 1,
      pos1Name: "CR",
      pos2Name: "AR",
      pos3Name: "AR2",
      pos4Name: "MENTOR",
      numSlots: 4,
    }
  }
}

function freshState() : ClientI {
  const $publicPath = process.env.MODE === 'capacitor' ? '' : '/app'

  return {
    INLEAGUE_URL: '',
    publicPath: '/',
    clientTheme: {
      name: "inLeague",
      color: 'rgb(25, 118, 55)',
      banner: `${$publicPath}/clientAssets/inLeague/banner_sm.png`,
      favicon: `${$publicPath}/clientAssets/inLeague/soccer_ball.svg`,
    },
    // TODO: make null on initialization, and provide property getter that throws on read of null
    instanceConfig: {} as InstanceConfig,
    divisions: [],
    activeDivisionsForSeason: {},
    childDivisionsForUserSeason: {},
    seasons: [],
    teams: [],
    fields: [],
    // we want this to break when used if we fail to init it, but it is always a string
    // TODO: null-with-throw-on-read-null like instanceConfig
    stripePublicKey: "<<UNINITIALIZED>>",
  }
}

async function getRefSlotConfigMappingRaw(ax: AxiosInstance) : Promise<RefSlotConfigMappingRaw> {
  const response = await ax.get('public/refSlotConfig')
  return response.data.data;
}

async function getRefSlotConfigMapping(ax: AxiosInstance) : Promise<RefSlotConfigMapping> {
  const raw = await getRefSlotConfigMappingRaw(ax)

  const result : RefSlotConfigMapping = {
    default: isObject(raw.default) ? mungeOne(raw.default) : defaultRefSlotConfigMapping().default,
    competitions: (() => {
      const builder : RefSlotConfigMapping["competitions"] = {}
      for (const compUID of Object.keys(raw)) {
        if (!isGuidUpper(compUID)) {
          continue;
        }

        const compTierRefSlotConfig = raw[compUID]
        builder[compUID] = {
          ...mungeOne(compTierRefSlotConfig),
          divisions: {}
        }

        for (const divID of Object.keys(compTierRefSlotConfig)) {
          if (!isGuidUpper(divID)) {
            continue;
          }
          const divTierRefSlotConfig = compTierRefSlotConfig[divID]
          if (!isObject(divTierRefSlotConfig)) {
            // shouldn't happen
            continue;
          }
          else {
            builder[compUID].divisions[divID] = mungeOne(divTierRefSlotConfig)
          }
        }
      }

      return builder
    })(),
  }

  return result

  function mungeOne(v: RefSlotConfigRaw) : RefSlotConfig {
    return {
      competitionUID: v.COMPETITIONUID || v.competitionUID || null,
      divID: v.DIVID || v.divID || v.divisionID || null,
      pos1Val: v.POS1VAL || v.pos1Val || 0,
      pos2Val: v.POS2VAL || v.pos2Val || 0,
      pos3Val: v.POS3VAL || v.pos3Val || 0,
      pos4Val: v.POS4VAL || v.pos4Val || 0,
      pos1Name: v.POS1NAME || v.pos1Name || "",
      pos2Name: v.POS2NAME || v.pos2Name || "",
      pos3Name: v.POS3NAME || v.pos3Name || "",
      pos4Name: v.POS4NAME || v.pos4Name || "",
      numSlots: v.NUMSLOTS || v.numSlots || 0,
    }
  }
}

export class RefSlotConfigLookup {
  // "private", using "leading space in prop name" trick.
  // We would like to be private at comp time but visible at runtime (only for debugging's sake though).
  // But private modifier (which would acheive that) doesn't play nice at comptime with vue's ref<> types.
  // Anyway, don't modify this from outside of this class.
  readonly [" mapping"] : RefSlotConfigMapping

  constructor(mapping_: RefSlotConfigMapping) {
    this[" mapping"] = mapping_;
  }

  find(args: {competitionUID: "" | Guid | null, divID: "" | Guid | null}) {
    return RefSlotConfigLookup.lookup(this[" mapping"], args)
  }

  static lookup(mapping: RefSlotConfigMapping, args: {competitionUID: "" | Guid | null, divID: "" | Guid | null}) {
    const byCompDiv = mapping.competitions[args.competitionUID || ""]?.divisions[args.divID || ""]
    const byComp = mapping.competitions[args.competitionUID || ""]
    const default_ = mapping.default

    return byCompDiv || byComp || default_
  }

  static defaultLookup() {
    return new RefSlotConfigLookup(defaultRefSlotConfigMapping())
  }
}
