//
// this is "t-autosearch" but with stronger types and it is not coupled to the shape of the user endpoint
//
import { nextGlobalIntlike, UiOption } from "src/helpers/utils";
import { computed, defineComponent, ExtractPropTypes, PropType, ref, watch } from "vue"

function propsDef<T>() {
  return {
    formData: {
      required: true,
      type: Object as PropType<{
        input: string,
        selected: T | null,
        options: (UiOption<T> & {key: string})[],
      }>
    },
    // element to display when there are no options
    noOptionsElement: {
      required: false,
      type: Object as PropType<JSX.Element>,
    },
    placeholder: {
      required: false,
      type: String
    },
  } as const;
}

export type Props<T> = ExtractPropTypes<ReturnType<typeof propsDef<T>>>

export function AutoSearch2<T>() {
  return defineComponent({
    props: propsDef<T>(),
    setup(props) {
      const mutableFormRef = computed(() => props.formData);

      const showDropDown = ref(false);
      const selectedIndex = ref(0)

      watch(() => props.formData.options, () => { selectedIndex.value = 0; })

      const selectOptionByIndex = (index: number) : void => {
        mutableFormRef.value.selected = props.formData.options[index].value;
        mutableFormRef.value.input = props.formData.options[index].label;
      }

      // we've gone to using a custom scrolling list display, so if we move to the next or previous list element,
      // and that element is outside of the scrollport, we need to adjust the scroll port
      const maybeUpdateScrollTop = () => {
        const container = listRoot();
        if (!container) {
          return;
        }
        const selected = container.querySelectorAll<HTMLElement>("li")[selectedIndex.value];
        if (!selected) {
          return;
        }
        container.scrollTop = selected.offsetTop;

      }
      const keydownHandler = (e: KeyboardEvent) : void => {
        switch (e.code.toLowerCase()) {
          case "enter": {
            e.preventDefault();
            selectOptionByIndex(selectedIndex.value);
            showDropDown.value = false;
            return;
          }
          case "arrowdown": {
            // down the screen, towards the end of the options list
            e.preventDefault();
            selectedIndex.value = Math.min(props.formData.options.length - 1, selectedIndex.value + 1);
            maybeUpdateScrollTop();
            return;
          }
          case "arrowup": {
            // up the screen, towards the start of the options list
            e.preventDefault();
            selectedIndex.value = Math.max(0, selectedIndex.value - 1);
            maybeUpdateScrollTop();
            return;
          }
          default: {
            return;
          }
        }
      }

      // can we use template refs with jsx?
      const listRootID = `listRoot-${nextGlobalIntlike()}`;
      const listRoot = () => document.getElementById(listRootID);

      return () => (
        <div class={`${showDropDown.value ? "relative" : ""}`}>
          <input
            type="text"
            class="rounded-md shadow-md text-black block w-full"
            v-model={props.formData.input}
            autocomplete="off"
            onKeydown={(e: KeyboardEvent) => keydownHandler(e)}
            placeholder={props.placeholder || ""}
            onFocus={() => {showDropDown.value = true;}}
            onBlur={(e: FocusEvent) => {
              if (e.relatedTarget instanceof Node && listRoot()?.contains(e.relatedTarget)) {
                // we blurred, but into the listing
                return;
              }
              else {
                showDropDown.value = false;
              }
            }}
          />
          <ul
            id={listRootID}
            class={`
              ${showDropDown.value ? "": "invisible"}
              absolute mt-1 min-w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto
              focus:outline-none sm:text-sm
            `}
            tabindex={-1}
            role='listbox'
            aria-labelledby='listbox-label'
            aria-activedescendant='listbox-option-3'
            onClick={() => { showDropDown.value = false; }}
            onKeydown={(e: KeyboardEvent) => void (e.code.toLowerCase() === "enter" ? (showDropDown.value = false) : 0)}
          >
            {
              props.formData.options.length === 0
                ? (
                  <li class="flex items-center justify-center">
                    {props.noOptionsElement ?? "No options"}
                  </li>
                )
                : props.formData.options.map((option, index) => {
                    return (
                      <li
                        key={option.key + "/" + index}
                        class={`${index === selectedIndex.value ? "bg-gray-200" : ""} text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9`}
                        onMouseenter={() => { selectedIndex.value = index }}
                        onClick={() => { selectOptionByIndex(index); showDropDown.value = false; }}
                      >
                        {option.label}
                      </li>
                    )
                })
            }
          </ul>
        </div>
      )
    }
  })
}
