import { defineComponent } from "vue";
import { FormKit } from "@formkit/vue";
import { vReqT } from "src/helpers/utils";

export const EditTeamSeasonFormElement = defineComponent({
  props: {
    form: vReqT<TeamSeasonForm>(),
    doUpdate: vReqT<() => Promise<void>>(),
  },
  setup(props) {
    const submit = async () => {
      await props.doUpdate()
    }

    return () => {
      return (
        <div data-test="EditTeamSeasonFormElement">
          <div class="text-lg">Team Details</div>
          <div class="text-md">"Team Name" below is an optional, cosmetic, seasonal team name that is added to the shorter team designation above.</div>
          <div class="text-md">Practice times and locations are displayed to families on their dashboard.</div>
          <div class="text-md mt-4">* If your league uses the Practice Scheduler to assign practice times and your team has a practice assignment, then any values entered here will not be used.</div>
          <FormKit type="form" actions={false} onSubmit={submit}>
            <div class="p-2">
              <FormKit type="text" v-model={props.form.teamName} label="Team Name" data-test="teamName"/>
            </div>
            <div class="p-2">
              <FormKit type="text" v-model={props.form.practiceTime} label="Practice Time (*) (e.g. Tuesdays at 5:00pm)"/>
            </div>
            <div class="p-2">
              <FormKit type="text" v-model={props.form.practiceLoc} label="Practice Location (*)"/>
            </div>
            <div class="p-2">
              <FormKit type="text" v-model={props.form.colorJersey} label="Jersey Color"/>
            </div>
            <div class="p-2">
              <FormKit type="text" v-model={props.form.colorJerseySolidStripe} label="Jersy Stripe Color"/>
            </div>
            <div class="p-2">
              <FormKit type="text" v-model={props.form.colorShorts} label="Shorts Color"/>
            </div>
            <div class="p-2">
              <FormKit type="text" v-model={props.form.colorSocks} label="Socks Color"/>
            </div>
            <div class="p-2 flex">
              <t-btn class="ml-auto" type="submit" margin={false}>Save changes</t-btn>
            </div>
          </FormKit>
        </div>
      )
    }
  }
})

export interface TeamSeasonForm {
  colorJersey: string,
  colorJerseySolidStripe: string,
  colorShorts: string,
  colorSocks: string,
  teamName: string,
  practiceTime: string,
  practiceLoc: string,
}
