import { defineComponent, onMounted, ref } from 'vue'
import AllRefAssignments from '../allRefAssignments.vue'
import RefAssignments from '../refAssignments.vue'
import LeagueGames from '../leagueGames.vue'
import UserGames from '../userGames.vue'

import { propsDef } from './schedules.ilx'
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client } from 'src/store/Client'
import { Btn2 } from 'src/components/UserInterface/Btn2'
import { withNoScroll } from 'src/router/RouterScrollBehavior'
import { RouteLocationNamedRaw, useRouter } from 'vue-router'
import { CommonGameScheduleUrlParts, parseQuery_main, TabID } from '../R_GameSchedules.shared'
import { assertTruthy, exhaustiveCaseGuard, parseIntOr, parseIntOrFail, requireNonNull, sortBy, sortByMany } from 'src/helpers/utils'
import { Competition, Division, Guid } from 'src/interfaces/InleagueApiV1'
import { axiosInstance } from 'src/boot/AxiosInstances'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { getGameScheduleCompDivPairs } from './GameSchedules.io'

export default defineComponent({
  name: 'R_GameSchedules',
  props: propsDef(),
  setup(props) {
    const router = useRouter()
    const ready = ref(false);

    const menuOptions = ref<{competition: Competition, divisions: Division[]}[]>([])

    const selectedTabID = ref<TabID>(
      (() => {
        if (User.isLoggedIn) {
          if (props.competitionID) {
            return TabID.allGames
          }
          else {
            return TabID.userGames
          }
        }
        else {
          return TabID.allGames
        }
      })()
    );

    const handleUpdateURL = async (args: CommonGameScheduleUrlParts) : Promise<void> => {
      await withNoScroll(async () => {
        const obj : RouteLocationNamedRaw = {
          name: requireNonNull(router.currentRoute.value.name),
          params: args.path,
          query: {
            ...router.currentRoute.value.query,
            ...args.query,
            tab: selectedTabID.value,
          }
        };

        await router.replace(obj)
      })
    }

    const updateTabURL = async () : Promise<void> => {
      await withNoScroll(async () => {
        const obj = {
          ...router.currentRoute.value,
          query: {
            ...router.currentRoute.value.query,
            tab: selectedTabID.value
          }
        };
        await router.replace(obj)
      })
    }

    const mungeMenu = (competitions: readonly Competition[], divisions: readonly Division[], validPairs: {competitionUID: Guid, divID: Guid}[]) => {
      // all the comps/divs are unique in their respective lists,
      // meaning we can use object identity semantics rather than ID based
      assertTruthy(new Set(competitions.map(v => v.competitionUID)).size === competitions.length);
      assertTruthy(new Set(divisions.map(v => v.divID)).size === divisions.length);

      const result = new Map<Competition, Set<Division>>()

      for (const {competitionUID, divID} of validPairs) {
        const comp = competitions.find(v => v.competitionUID === competitionUID)
        const div = divisions.find(v => v.divID === divID)
        if (!comp || !div) {
          // shouldn't happen in prod;
          // in test, we might get a listing of pairs that doesn't jive with our listings of comps/divs,
          // if we've stubbed comps/divs to only return a particular list. I guess we should stub the pairs thing, too;
          // but then we wouldn't be testing it.
          continue;
        }

        if (!result.has(comp)) {
          result.set(comp, new Set())
        }

        result.get(comp)!.add(div)
      }

      return [...result.entries()]
        .sort(sortBy(([comp, divs]) => parseIntOrFail(comp.competitionID), "asc"))
        .map(([comp, divs]) => {
          const sortedDivs = [...divs]
            .sort(sortByMany(
              sortBy(div => parseIntOr(div.divNum, 999), "asc"),
              sortBy(div => div.gender, "asc"),
            ))
          return {competition: comp, divisions: sortedDivs}
        })
        .filter(v => v.divisions.length > 0)
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const comps = (await getCompetitionsOrFail()).value
        const divs = (await Client.loadDivisions())

        menuOptions.value = mungeMenu(
          comps,
          divs,
          (await getGameScheduleCompDivPairs(axiosInstance)).validPairs
        );

        {
          const q = parseQuery_main(router.currentRoute.value)
          if (q?.tab) {
            if (!hasTabPermission(q.tab)) {
              selectedTabID.value = TabID.allGames
            }
            else {
              selectedTabID.value = q.tab
            }
          }
        }



        ready.value = true;
      })
    })

    const hasTabPermission = (v: TabID) => {
      switch (v) {
        case TabID.allGames:
          return true;
        case TabID.allRefAssignments:
          return true;
        case TabID.userGames:
          return User.isLoggedIn
        case TabID.userRefAssignments:
          return User.isLoggedIn
        default: exhaustiveCaseGuard(v)
      }
    }

    return () => {
      if (!ready.value) {
        return null;
      }

      return <div style="--fk-bg-input: white;">
        <div class="flex flex-col sm:flex-row gap-1">
          {hasTabPermission(TabID.allGames)
            ? <Btn2
              class="p-2 w-1/2"
              enabledClasses={`${selectedTabID.value === TabID.allGames ? "bg-green-800" : "bg-green-400"} hover:bg-green-700 active:bg-green-500 cursor-pointer`}
              onClick={() => { selectedTabID.value = TabID.allGames; updateTabURL() }}
            >
              All Games
            </Btn2>
            : null
          }

          {hasTabPermission(TabID.userGames)
            ? <Btn2
              class="p-2 w-1/2"
              enabledClasses={`${selectedTabID.value === TabID.userGames ? "bg-green-800" : "bg-green-400"} hover:bg-green-700 active:bg-green-500 cursor-pointer`}
              onClick={() => { selectedTabID.value = TabID.userGames; updateTabURL(); }}
            >
              My Games
            </Btn2>
            : null
          }

          {hasTabPermission(TabID.allRefAssignments)
            ? <Btn2
              class="p-2 w-1/2"
              enabledClasses={`${selectedTabID.value === TabID.allRefAssignments ? "bg-green-800" : "bg-green-400"} hover:bg-green-700 active:bg-green-500 cursor-pointer`}
              onClick={() => { selectedTabID.value = TabID.allRefAssignments; updateTabURL(); }}
            >
              All Ref Assignments
            </Btn2>
            : null
          }

          {hasTabPermission(TabID.userRefAssignments)
            ? <Btn2
              class="p-2 w-1/2"
              enabledClasses={`${selectedTabID.value === TabID.userRefAssignments ? "bg-green-800" : "bg-green-400"} hover:bg-green-700 active:bg-green-500 cursor-pointer`}
              onClick={() => { selectedTabID.value = TabID.userRefAssignments; updateTabURL(); }}
            >
              My Ref Assignments
            </Btn2>
            : null
          }
        </div>

        {hasTabPermission(TabID.allGames) && selectedTabID.value === TabID.allGames
          ? <LeagueGames
            menu={menuOptions.value}
            initialCompetitionID={props.competitionID}
            initialDivID={props.divID}
            onUpdateURL={handleUpdateURL}
          />
          : null
        }

        {hasTabPermission(TabID.userGames) && selectedTabID.value === TabID.userGames
          ? <UserGames/>
          : null
        }

        {hasTabPermission(TabID.allRefAssignments) && selectedTabID.value === TabID.allRefAssignments
          ? <AllRefAssignments
            menu={menuOptions.value}
            initialCompetitionID={props.competitionID}
            initialDivID={props.divID}
            onUpdateURL={handleUpdateURL}
          />
          : null
        }

        {hasTabPermission(TabID.userRefAssignments) && selectedTabID.value === TabID.userRefAssignments
          ? <RefAssignments/>
          : null
        }
      </div>
    }
  },
})
