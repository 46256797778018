import { Guid } from "src/interfaces/InleagueApiV1";
import { RouteLocationRaw } from "vue-router";

import { hasSomeRoutePermission as invoiceTemplateShared_hasSomeRoutePermission } from 'src/components/InvoiceTemplates/InvoiceTemplateListing.route'

export const routeName = "invoice-template-manager"

export const route = (args: {invoiceTemplateID: Guid}) : RouteLocationRaw => {
  return {name: routeName, params: {invoiceTemplateID: args.invoiceTemplateID}}
}

export function hasSomeRoutePermission() : boolean {
  return invoiceTemplateShared_hasSomeRoutePermission()
}
