import { rpc } from "src/composables/Rpc"
import { Guid } from "src/interfaces/InleagueApiV1"

export interface CompSeasonDivWelcomeEmail {
  competitionUID: Guid,
  seasonUID: Guid,
  divID: Guid,
  enabled: boolean,
  from: string,
  subject: string,
  htmlBody: string
}

const __getCompSeasonDivWelcomeEmail = rpc<{competitionUID: Guid, seasonUID: Guid, divID: Guid}, CompSeasonDivWelcomeEmail | null>("get", "v1/compSeasonDivWelcomeEmail/getCompSeasonDivWelcomeEmail")
export const getCompSeasonDivWelcomeEmail = async (...args: Parameters<typeof __getCompSeasonDivWelcomeEmail>) => {
  return await __getCompSeasonDivWelcomeEmail(...args).then(v => v || null) // convert "" response (meaning "no match") to null
}

export const createOrUpdateCompSeasonDivWelcomeEmail = rpc<CompSeasonDivWelcomeEmail, CompSeasonDivWelcomeEmail>("post", "v1/compSeasonDivWelcomeEmail/createOrUpdateCompSeasonDivWelcomeEmail")
export const deleteCompSeasonDivWelcomeEmail = rpc<{competitionUID: Guid, seasonUID: Guid, divID: Guid}, void>("post", "v1/compSeasonDivWelcomeEmail/delteCompSeasonDivWelcomeEmail")
