<template lang="pug">
.flex.flex-col.items-center(data-test="leagueGames" v-if="ready")
  h1.mt-4 All Games
  div(class='sm:mx-auto sm:w-full sm:max-w-md' style="--fk-margin-outer: none;")
    div(class="mt-2")
      FormKit(
        type='select',
        :options='competitionSelectOptions',
        v-model='fkDummySink_selectedCompID',
        label='Competition',
        data-cy='competitions'
        @input="(value) => handleUpdateSelectedCompetitionID(value)"
      )
    div(class="mt-2")
      FormKit.m-2(
        type='select',
        :options='divisionSelectOptions',
        v-model='fkDummySink_selectedDivID',
        label='Division',
        :disabled='!fkDummySink_selectedCompID',
        data-test='divID',
        label-class='pb-4'
        @input="(value) => handleUpdateSelectedDivID(value)"
      )
    div(style="width:100%; max-width: var(--fk-max-width-input);" class="mt-2")
      //- we could probably pull more reasonable min/max dates from compSeason or season.seasonStart
      LeagueGamesDatePicker(
        :initialValue="selectedOnOrAfterDate"
        :minDate="minLeagueGamesDatePickerDate"
        @commit="handleDatePickerChange"
      )

    div(v-if="divisionStandingsURL" class="mt-2")
      a(
        :href="divisionStandingsURL"
        target="_blank"
        class="text-blue-700 underline cursor-pointer"
      )
        | View Division Standings
    div(:class="[`mt-2 flex gap-1 items-center`, offerUnpublishedCheckbox ? '' : 'invisible']")
      FormKit(
        type="checkbox"
        id="elem-unpublishedCheckbox"
        v-model="showUnpublishedGames"
        data-test="showUnpublishedGames"
        @input="() => updateUrlFromCurrentState(true)"
      )
      label(for="elem-unpublishedCheckbox" class="text-sm") Include unpublished games in results

  .quasar-style-wrap.mt-8(
    v-if='games.length',
    data-cy='fieldKeys',
  )
    .q-pa-md
      q-table(
        :rows='keys',
        :columns='keyColumns',
        row-key='fieldID',
        @row-click='toMap',
        :rows-per-page-options='[0]',
        hide-pagination,
        dense
      )
        template(v-slot:body-cell-fieldID='props')
          q-td(:auto-width='true')
            div {{ props.row.fieldAbbrev }}
        template(v-slot:body-cell-fieldName='props')
          q-td.row.full-width(:props='props')
            .col-auto.q-pr-sm
              font-awesome-icon(:icon='["fas", "map-marked-alt"]')
            .col {{ props.row.fieldName }}

  div(v-if="fkDummySink_selectedDivID !== 'ALL' && games.length" class="max-w-6xl w-full")
    MultiGameCoachInfo(:games="games")

  div(class="w-full")
    .quasar-style-wrap(
      v-if='games.length',
      data-cy='allGamesTable',
    )
      GamelikeScheduleTable(:columns="columns" :rows="games")
    div(v-else-if='isDataLoaded', data-cy='noGames')
      h4.text-center.italic.mt-2 Sorry, no games matched your query
</template>

<script lang="ts">
import { axiosAuthBackgroundInstance, axiosInstance } from 'src/boot/axios'
import { useRouter } from 'vue-router'
import { defineComponent, ref, Ref, watch, computed, onMounted } from 'vue'
import { dayjsOr, formatDateAsNums, formatTime } from 'src/helpers/formatDate'
import { Competition, Division, Guid, Integerlike, RefFieldDetails } from 'src/interfaces/InleagueApiV1'
import { propsDef, Props, JsxQuasarColumnRenderShim, GamelikeColDefs, AugmentedGamelikeForSchedule, augmentGamelikesForSchedule, getAreaCoaches, maybeComputeDivisionStandingsURL } from "./page/schedules.ilx"

import * as ilapi from "src/composables/InleagueApiV1"
import * as iltypes from "src/interfaces/InleagueApiV1"

import GamelikeScheduleTable from './GamelikeScheduleTable.vue'
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client, RefSlotConfigLookup } from 'src/store/Client'
import dayjs from 'dayjs'
import { DAYJS_FORMAT_HTML_DATE } from 'src/helpers/formatDate'
import { arrayFindOrFail, forceCheckedIndexedAccess, sortBy, UiOption, vOptT, vReqT } from 'src/helpers/utils'
import { initInOnMountedRef } from './utils'

import { MultiGameCoachInfo } from './MultiGameCoachInfo'
import { LeagueGamesDatePicker } from "./LeagueGamesDatePicker"
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { areaCoachCamelToLower, canViewUnpublishedGames } from 'src/composables/InleagueApiV1.Game'
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'
import { CommonGameScheduleUrlParts, CompDivMenu, parseQuery_leagueGames, GameScheduleMenu } from './R_GameSchedules.shared'
import { QuasarColDef, quasarColumnSortAdapter } from 'src/helpers/Quasar'

export default defineComponent({
  name: 'leagueGames',
  props: {
    menu: vReqT<GameScheduleMenu>(),
    initialCompetitionID: vOptT<"" | Integerlike>(),
    initialDivID: vOptT<"ALL" | Guid>(),
  },
  components: {
    GamelikeScheduleTable,
    MultiGameCoachInfo,
    LeagueGamesDatePicker,
  },
  emits: {
    updateURL: (_: CommonGameScheduleUrlParts) => true,
  },
  setup(props, ctx) {
    const router = useRouter()
    const compDivMenu = CompDivMenu({get value() { return props.menu }})

    const refSlotConfigLookup = initInOnMountedRef<RefSlotConfigLookup>()

    const selectedOnOrAfterDate = ref(dayjs().format(DAYJS_FORMAT_HTML_DATE))

    const ready = ref(false);

    const games = ref([]) as Ref<AugmentedGamelikeForSchedule<ilapi.LoggedOutGame>[]>
    const isDataLoaded = ref(false)

    const p_canViewUnpublishedGames = ReactiveReifiedPromise<boolean>({
      deps: () => [User.isLoggedIn, User.userData?.userID],
      f: async () => {
        if (User.isLoggedIn) {
          return await canViewUnpublishedGames(axiosAuthBackgroundInstance)
        }
        else {
          return false
        }
      }
    })

    const offerUnpublishedCheckbox = p_canViewUnpublishedGames.map(p => {
      switch (p.status) {
        case "resolved": return p.data;
        case "error": throw p.error;
        default: return false;
      }
    })

    const showUnpublishedGames = (() => {
      const val = ref(false)
      return computed({
        get() {
          return offerUnpublishedCheckbox.value ? val.value : false
        },
        set(v) {
          val.value = v;
        }
      })
    })();

    /**
     * a url to "division standings" if such a link exists as per current selections and listed games,
     * or null if nothing
     */
    const divisionStandingsURL = computed<string | null>(() => {
      return maybeComputeDivisionStandingsURL({
        competition: compDivMenu.selectedCompetition,
        division: compDivMenu.selectedDivision,
        gamelikes: games.value,
      })
    })

    // todo: clarify what this is (keys are usually strings into a map?...)
    const keys = ref([]) as Ref<ilapi.LoggedOutGame[]>

    const columns = ref(GamelikeColDefs());

    const keyColumns = ref<QuasarColDef<RefFieldDetails>[]>([
      {
        name: 'fieldID',
        required: true,
        label: 'Field',
        align: 'left',
        field: (ref: RefFieldDetails) => ref.fieldAbbrev,
        style: 'vertical-align: top; font-size: 16px',
        headerStyle: 'font-size: 16px',
        classes: 'q-table',
        sortable: true,
        sort: quasarColumnSortAdapter(sortBy(v => v.fieldAbbrev))
      },
      {
        name: 'fieldName',
        required: false,
        label: 'Full Name',
        align: 'left',
        field: (ref: RefFieldDetails) => {
          return ref.fieldName
        },
        style: 'vertical-align: top; font-size: 16px',
        headerStyle: 'font-size: 16px',
        classes: 'q-table',
        sortable: true,
        sort: quasarColumnSortAdapter(sortBy(v => v.fieldName))
      },
    ])

    const userAddress = computed(() => {
      return User.value.userAddress
    })

    // this is really "get one random game per unique fieldID out of the current games list"
    // see `oneRandomGamePerField` in "userGames.vue". We might be able to have only one implementation
    // if they are exactly the same.
    const createKeys = () => {
      const gameKeys: { [fieldID: string]: ilapi.LoggedOutGame } = {}
      for (let i = 0; i < games.value.length; i++) {
        const game = games.value[i]
        gameKeys[game.fieldAbbrev] = game
      }
      keys.value = []
      for (const key in gameKeys) {
        keys.value.push(gameKeys[key])
      }
    }

    const toMap = (evt: Event, row: ilapi.LoggedOutGame) => {
      const fieldAddress = `${row.fieldStreet} ${row.fieldCity} ${row.fieldZip}`
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
          userAddress.value
        )}&destination=${encodeURIComponent(fieldAddress)}`,
        '_blank'
      )
    }

    const updateUrlFromCurrentState = () : void => {
      ctx.emit("updateURL", {
        path: {
          competitionID: compDivMenu.selectedCompetitionID,
          divID: compDivMenu.selectedDivID || undefined,
        },
        query: {
          gamesOnOrAfter: dayjsOr(selectedOnOrAfterDate.value)?.format(DAYJS_FORMAT_HTML_DATE),
          includeUnpublished: showUnpublishedGames.value ? "1" : "0",
        }
      })
    }

    /**
     * gets games from a network request
     * mutates component state, in particular the list of displayed games
     */
    const getGamesAndUpdateGameDisplay = async () : Promise<void> => {
      if (!compDivMenu.selectedCompetitionID || !compDivMenu.selectedDivID) {
        // probably shouldn't have been called
        return
      }

      const competitionID = compDivMenu.selectedCompetitionID
      const divID = compDivMenu.selectedDivID === "ALL" ? undefined : compDivMenu.selectedDivID

      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        const onOrAfter = selectedOnOrAfterDate.value;

        const competitions = (await getCompetitionsOrFail()).value
        const gameScheduleInfo = await ilapi.getGamesForPublicView(axiosInstance, {
          competitionID,
          divID,
          onOrAfter,
          showUnpublished: showUnpublishedGames.value,
          expand: ["refereeDetails"],
        });

        games.value = augmentGamelikesForSchedule(
          gameScheduleInfo,
          competitions,
          (await getAreaCoaches(axiosInstance, gameScheduleInfo))?.map(areaCoachCamelToLower) ?? [],
          refSlotConfigLookup.value,
        );

        isDataLoaded.value = true

        createKeys()
      })
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        refSlotConfigLookup.value = await Client.getRefSlotConfigLookup()

        {
          if (props.initialCompetitionID && compDivMenu.competitionSelectOptions.value.find(v => v.value /*weakEq*/ == props.initialCompetitionID)) {
            compDivMenu.updateSelectedCompetitionID(props.initialCompetitionID.toString())
          }
          else {
            compDivMenu.updateSelectedCompetitionID(forceCheckedIndexedAccess(props.menu, 0)?.competition.competitionID.toString() ?? "")
          }

          if (props.initialDivID && compDivMenu.divisionSelectOptions.value.find(v => v.value === props.initialDivID)) {
            compDivMenu.updatedSelectedDivID(props.initialDivID)
          }
          else {
            compDivMenu.updatedSelectedDivID("ALL")
          }

          const q = parseQuery_leagueGames(router.currentRoute.value)
          const date = dayjsOr(q?.gamesOnOrAfter)
          if (date) {
            selectedOnOrAfterDate.value = date.format(DAYJS_FORMAT_HTML_DATE)
          }
          if (q?.includeUnpublished === "1") {
            const canViewUnpublishedGames = await p_canViewUnpublishedGames.getResolvedOrFail()
            if (canViewUnpublishedGames) {
              showUnpublishedGames.value = true
            }
          }
        }

        await getGamesAndUpdateGameDisplay()

        ready.value = true;
      })
    })

    return {
      ready,
      fkDummySink_selectedCompID: compDivMenu.fkDummySink_selectedCompID,
      competitionSelectOptions: compDivMenu.competitionSelectOptions,
      fkDummySink_selectedDivID: compDivMenu.fkDummySink_selectedDivID,
      divisionSelectOptions: compDivMenu.divisionSelectOptions,
      selectedOnOrAfterDate,
      games,
      isDataLoaded,
      keys,
      columns,
      keyColumns,
      createKeys,
      toMap,
      userAddress,
      getGamesAndUpdateGameDisplay,
      formatDateAsNums,
      formatTime,
      divisionStandingsURL,
      JsxQuasarColumnRenderShim,
      // this tracks a backend value in the api that we have static knowledge of
      minLeagueGamesDatePickerDate: dayjs().subtract(2, "years").toISOString(),
      offerUnpublishedCheckbox,
      showUnpublishedGames,
      handleDatePickerChange: (freshDate: string) => {
        selectedOnOrAfterDate.value = freshDate;
        updateUrlFromCurrentState()
        getGamesAndUpdateGameDisplay();
      },
      updateUrlFromCurrentState: (asyncish?: boolean) => {
        if (asyncish) {
          // for use in formkit input handlers where you will get the old value until after the handler has completed
          setTimeout(updateUrlFromCurrentState, 0);
        }
        else {
          updateUrlFromCurrentState()
        }
      },
      handleUpdateSelectedCompetitionID: async (value: string) => {
        await compDivMenu.updateSelectedCompetitionID(value)
        await updateUrlFromCurrentState()
        await getGamesAndUpdateGameDisplay()
      },
      handleUpdateSelectedDivID: async (value: string) => {
        await compDivMenu.updatedSelectedDivID(value)
        await updateUrlFromCurrentState()
        await getGamesAndUpdateGameDisplay()
      }
    }
  },
})

</script>

<style scoped>
@media (min-width: 640px) {
  .tableWidth {
    width: 75vw !important;
  }
}

@media (min-width: 768px) {
  .tableWidth {
    width: 50vw !important;
  }
}

@media (min-width: 1024px) {
  .tableWidth {
    width: 600px !important;
  }
}
</style>
