import dayjs, { Dayjs } from "dayjs"
import { parseIntOrFail, rangeInc, JS_DayOfWeek, assertTruthy, requireNonNull, gatherByKey_manyPerKey, sortBy, sortByDayJS } from "src/helpers/utils"
import { Datelike } from "src/interfaces/InleagueApiV1"
import { CreatePracticeSlotsFormStore } from "./PracticeSchedulerActions"
import { CreatePracticeSlotArgs, CreatePracticeSlotArgs_SlotDef } from "./PracticeScheduler.io"
import { DAYJS_FORMAT_HTML_DATE } from "src/helpers/formatDate"

export function ExtractFormSlots(
  form: Pick<
    // just pick "pure data" fields, mostly for test affordance
    CreatePracticeSlotsFormStore,
      | "initialWeekStart" | "slotCountPerDay" | "startHour24" | "startMinute"
      | "slotDurationMinutes" | "repeatWeeks" | "daysOfWeekPerWeek" | "generateAsRecurrenceGroups"
      | "visibleOnOrAfter" | "allowableTeamCount"
  >
) : CreatePracticeSlotArgs_SlotDef[] {
  const baseDate = dayjs(form.initialWeekStart).hour(0).minute(0).second(0).millisecond(0)
  const slotCountPerDay = (() => {
    const v = parseIntOrFail(form.slotCountPerDay)
    assertTruthy(v > 0)
    return v;
  })()
  const startHour24 = parseIntOrFail(form.startHour24)
  const startMin = parseIntOrFail(form.startMinute)
  const slotDurationMinutes = parseIntOrFail(form.slotDurationMinutes)

  const xoffset = Xoffset(form.initialWeekStart)

  return rangeInc(0, parseIntOrFail(form.repeatWeeks)).flatMap(weekIdx => {

    const slots : CreatePracticeSlotArgs_SlotDef[] = []

    for (const jsday_ of form.daysOfWeekPerWeek) {
      const jsday : JS_DayOfWeek = parseIntOrFail(jsday_)
      assertTruthy(0 <= jsday && jsday <= 6)

      let workingSlotStart = baseDate
        .add(weekIdx, "weeks")
        .add(requireNonNull(xoffset.jsDay2BaseOffset.get(jsday)), "days")
        .hour(startHour24)
        .minute(startMin)

        for (let i = 0; i < slotCountPerDay; i++) {
          const start = workingSlotStart
          const end = workingSlotStart.add(slotDurationMinutes, "minutes")

          // unique id per (day-of-week, start-time), e.g. all (mon @ 9am), (mon @ 10am), etc.
          const recurrenceID = ((jsday + 1) * 100_000) // in range [100_000, 700_000]
            + ((start.hour() + 1) * 1_000) // in range [1_000, 23_0000]
            + start.minute() // in range [0, 59]

          slots.push({
            startDate: start.format(DAYJS_FORMAT_HTML_DATE),
            startHr24: start.hour(),
            startMinute: start.minute(),
            endDate: end.format(DAYJS_FORMAT_HTML_DATE),
            endHr24: end.hour(),
            endMinute: end.minute(),
            allowableTeamCount: form.allowableTeamCount,
            visibleOnOrAfter: form.visibleOnOrAfter,
            recurrenceGroupID: form.generateAsRecurrenceGroups
              ? recurrenceGroupID(recurrenceID)
              : undefined
          })

          workingSlotStart = end
        }
    }

    return slots
  })

  function Xoffset(baseDate: Datelike) {
    const base = dayjs(baseDate)
    return {
      jsDay2BaseOffset: new Map<JS_DayOfWeek, number>([
        [base.add(0, "days").day(), 0], // e.g. if base date is saturday (6), offset to saturday is 0
        [base.add(1, "days").day(), 1], // e.g. if base date is saturday (6), offset to sunday is 1
        [base.add(2, "days").day(), 2], // ...
        [base.add(3, "days").day(), 3],
        [base.add(4, "days").day(), 4],
        [base.add(5, "days").day(), 5],
        [base.add(6, "days").day(), 6],
      ])
    }
  }
}

/**
 * convert a number (arbitrary, some freshly generated groupID) into a string that contains no numbers
 * Backend will use this to identify "generate a new group" as opposed to receiving an ID and going "oh, add to existing group"
 */
function recurrenceGroupID(v: number) : string {
  const conversion : Record<string, string> = {
    "0": "a",
    "1": "b",
    "2": "c",
    "3": "d",
    "4": "e",
    "5": "f",
    "6": "g",
    "7": "h",
    "8": "i",
    "9": "j",
    "a": "k",
    "b": "l",
    "c": "m",
    "d": "n",
    "e": "o",
    "f": "p",
    "g": "q",
    "h": "r",
    "i": "s",
    "j": "t",
    "k": "u",
    "l": "v",
    "m": "w",
    "n": "x",
    "o": "y",
    "p": "z",
  }

  return [...v.toString(26)]
    .map(c => requireNonNull(conversion[c]))
    .join("")
}

export const testExports = {
  recurrenceGroupID,
}
