import { computed, defineComponent, ExtractPropTypes, PropType, ref, watch } from "vue";

import { PlayerSearchResult } from 'src/composables/InleagueApiV1';

import * as iltypes from "src/interfaces/InleagueApiV1"

import C_PlayerLookup from "src/components/Lookup/Player/PlayerLookup.form.vue"
import * as M_playerLookup from "src/components/Lookup/Player/PlayerLookup.main-ts-shim"
import { exhaustiveCaseGuard, ExtractEmitsHandlers } from "src/helpers/utils";

declare const PlayerLookup : M_playerLookup.jsx_sfc_shim__PlayerLookup;

const propsDef = {
  keepPlayer: {
    required: true,
    type: Object as PropType<iltypes.Child>
  }
} as const;

interface DoMergeArgs {
  keepPlayer: Pick<iltypes.Child, "childID" | "playerFirstName" | "playerLastName">,
  mergePlayer: Pick<iltypes.Child, "childID" | "playerFirstName" | "playerLastName">,
}
/**
 * Return true on success, false on failure.
 * Is not expected to throw (any http failures should be handled internally to the cb).
 */
type DoMergeCb = (args: DoMergeArgs) => boolean | Promise<boolean>;

const emitsDef = {
  doMerge: (_cbRunner: (cb: DoMergeCb) => void) => true,
}

export type Props = ExtractPropTypes<typeof propsDef>
export type Emits = ExtractEmitsHandlers<typeof emitsDef>

export const MergePlayers = defineComponent({
  components: {
    PlayerLookup: C_PlayerLookup
  },
  props: propsDef,
  emits: emitsDef,
  setup(props, {emit}) {
    const awaitingFinalMergeConfirmation = ref(false);

    const selectedMergeSourceChildID = ref("");
    const liveRef_lookupResults = ref<PlayerSearchResult[]>([]);
    const mergeSourcePlayer = computed<PlayerSearchResult | null>(() => {
      return liveRef_lookupResults.value.find(v => v.childID === selectedMergeSourceChildID.value) ?? null;
    })

    enum MergeState {
      NO_SELECTION, AWAITING_CONFIRM_1, AWAITING_FINAL_CONFIRM, MERGING
    }
    const mergeState = ref(MergeState.NO_SELECTION);

    watch(() => selectedMergeSourceChildID.value, () => {
      if (selectedMergeSourceChildID.value === "") {
        // selection was cleared
        mergeState.value = MergeState.NO_SELECTION
      }
      else {
        // selection was changed
        mergeState.value = MergeState.AWAITING_CONFIRM_1;
      }
    })

    // kinda kludgy filter we pass to search component, to stop from a match against
    // the player we're merging into;
    const doNotShowKeepPlayerInSearchResults = (v: PlayerSearchResult) => {
      return v.childID !== props.keepPlayer.childID;
    };

    const doMerge = () => {
      awaitingFinalMergeConfirmation.value = false;

      if (!mergeSourcePlayer.value) {
        return;
      }

      // get a copy, it could maybe change in an async callback scenario
      const asyncGuardCopy_mergeSourceChildID = mergeSourcePlayer.value.childID;
      const savedMergeState = mergeState.value;
      mergeState.value = MergeState.MERGING;

      emit(
        "doMerge",
        async cb => {
          const ok = await cb({
            keepPlayer: props.keepPlayer,
            // non-null assertion -- this callback must be run synchronously in response to the emit,
            // and so mergeSourcePlayer.value will remain truthy
            mergePlayer: mergeSourcePlayer.value!
          })
          if (ok) {
            const selectionChangedFromTimeOfEmit = selectedMergeSourceChildID.value !== asyncGuardCopy_mergeSourceChildID;
            if (!selectionChangedFromTimeOfEmit) {
              selectedMergeSourceChildID.value = "";
              mergeState.value = MergeState.NO_SELECTION;
            }
            const idx = liveRef_lookupResults.value.findIndex(v => v.childID === asyncGuardCopy_mergeSourceChildID)
            if (idx !== -1) {
              // it should always be safe to do this, after succesfull merge, the merge source player is no longer valid
              liveRef_lookupResults.value.splice(idx, 1);
            }
          }
          else {
            mergeState.value = savedMergeState;
          }
        }
      );
    }

    return () => (
      <div data-test="MergePlayers">
        <div class="text-xl mb-6">Merge another player record with { props.keepPlayer.playerFirstName } { props.keepPlayer.playerLastName }</div>
        <div class="mb-8 flex-col items-center">
          <div>
            <PlayerLookup
              searchButtonLabel = "Find player"
              displayFilter = { doNotShowKeepPlayerInSearchResults }
              onGotLookupResults = { (results) => {liveRef_lookupResults.value = results["liveRef!"]} }
              defaultSeason="all"
              belowPlayerNameSlotlike={({playerSearchResult}) => {
                return (
                  <div>
                    <input
                      type="radio"
                      name="mergePlayerChoice"
                      value={playerSearchResult.childID}
                      v-model={selectedMergeSourceChildID.value}
                    />
                    <span class="text-sm ml-1">Merge</span>
                  </div>
                )
              }}
            >
              <slot name="aboveTable">
                <div class="text-left text-xs">Click player name to choose player for merging.</div>
              </slot>
            </PlayerLookup>
          </div>
          <div>
            {
              mergeSourcePlayer.value
                ? (
                  <div class="mb-4">
                    <div>Merge { mergeSourcePlayer.value.playerFirstName } { mergeSourcePlayer.value.playerLastName } into { props.keepPlayer.playerFirstName } { props.keepPlayer.playerLastName }</div>
                  </div>
                )
                : null
            }

            {
              (() => {
                if (!mergeSourcePlayer.value) {
                  return;
                }
                switch (mergeState.value) {
                  case MergeState.NO_SELECTION:
                    return;
                  case MergeState.AWAITING_CONFIRM_1:
                    return (
                      <div class="flex mb-4">
                        <t-btn data-test="merge-1" type="button" color="green" onClick={() => mergeState.value = MergeState.AWAITING_FINAL_CONFIRM}>
                          <div>Merge</div>
                        </t-btn>
                      </div>
                    )
                  case MergeState.AWAITING_FINAL_CONFIRM:
                    return (
                      <div class="mb-4">
                        <div class="mb-4">Merge cannot be undone after complete.</div>
                        <div class="mb-8">Really merge?</div>
                        <t-btn data-test="merge-2" onClick={() => doMerge()}>
                          <div>Merge</div>
                        </t-btn>
                      </div>
                    )
                  case MergeState.MERGING:
                    return (
                      <t-btn data-test="merge-3" disabled>
                        <div>Merging...</div>
                      </t-btn>
                    )
                  default:
                    exhaustiveCaseGuard(mergeState.value)
                }
              })()
            }
          </div>
        </div>
      </div>
    );
  }
})
