import { defineComponent, ref, onBeforeUnmount, onMounted, computed, reactive, watch, nextTick } from "vue"
import { Stripe as StripeJS, StripeElements, StripeCardElement, PaymentMethod, PaymentRequestPaymentMethodEvent } from "@stripe/stripe-js"
import { vReqT, Reflike, exhaustiveCaseGuard, requireNonNull, useIziToast, assertNonNull } from "src/helpers/utils"
import { Btn2 } from "../UserInterface/Btn2"
import { CollectPaymentMethodDetailsType, AllowedPaymentMethods, UserPaymentMethodsManagerState, StripeConfig } from "./UserPaymentMethodsManagerState"
import { IL_PaymentMethod, stripe_createAchSetupIntent_forClientBankAccountInfoCollectionModal, stripe_createAchSetupIntent_manualAcctNumber, stripe_trackMultiUseActiveMandate, stripeObjId } from "./Payments.io"
import { PaymentMethodListingTableCells } from "./PaymentToolsElems"
import { AchMandate, AchMandateModal } from "./AchMandateModal"
import { FormKit } from "@formkit/vue"
import { FormKitNode } from "@formkit/core";
import { isSubscriptionInvoice } from "./InvoiceUtils"
import { Invoice } from "src/composables/InleagueApiV1.Invoice"
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia"
import { axiosInstance } from "src/boot/AxiosInstances"
import { AutoModal, DefaultModalController_r } from "../UserInterface/Modal"
import { FALLBACK_ERROR_MESSAGE } from "src/boot/axios"
import { getLogger, maybeLog } from "src/modules/LoggerService"
import { LoggedinLogWriter } from "src/modules/Loggers"
import { User } from "src/store/User"

export const UserPaymentMethodsManagerElement = defineComponent({
  props: {
    state: vReqT<UserPaymentMethodsManagerState>(),
    expiresColumnLabel: vReqT<string>(),
    savePaymentMethod: vReqT<null | Reflike<boolean>>(),
    errors: vReqT<string>(),
    paymentInProgress: vReqT<boolean>(),
    cardSubmitLabel: vReqT<string>(),
    achStripeModalLabel: vReqT<string>(),
    achManualInputLabel: vReqT<string>()
  },
  emits: {
    deletePaymentMethod: (_: IL_PaymentMethod) => true,
    cardElemChanged: () => true,
    createdNewPaymentMethod: (_: {paymentMethodID: string, finally?: () => void}) => true,
    submitPayment: (_: {paymentMethodID: string, paymentRequestPaymentMethodEvent?: PaymentRequestPaymentMethodEvent}) => true,
    error: (_: string) => true,
  },
  setup(props, ctx) {
    const iziToast = useIziToast()
    const cardElemID = "card-element"
    const stripeConfig = ref<StripeConfig | null>(null)
    const stripeCard = ref<StripeCardElement | null>(null)
    const appleOrGooglePay = ref<null | "A" | "G">(null)
    const paymentRequest = ref<null | import('@stripe/stripe-js').PaymentRequest>(null)
    const stripeCardElementReady = ref(false)
    const ready = ref(false)

    onBeforeUnmount(() => {
      stripeCard.value?.destroy() // necessary? maybe a mem leak if we don't do this?
    })

    /**
     * n.b. caller should make sure they waited for any DOM changes that vue had scheduled to have been completed
     * (i.e. if we've switched to "card" mode, we need to have rendered the card element container before we run this)
     * Typically this means caller should wait a tick via setTimeout or similar when they observe they need to invoke this.
     */
    const updateCardElem = () => {
      assertNonNull(stripeConfig.value)

      if (props.state.selectedCollectPaymentMethodDetailsType.value === CollectPaymentMethodDetailsType.card) {
        const elem = document.getElementById(cardElemID)
        if (!elem) {
          return
        }
        stripeCard.value = UserPaymentMethodsManagerState.initCardElement({
          container: elem,
          elements: stripeConfig.value.elements,
          onReady: () => { stripeCardElementReady.value = true },
          onChange: () => { ctx.emit("cardElemChanged") },
        })
      }
      else if (props.state.selectedCollectPaymentMethodDetailsType.value === CollectPaymentMethodDetailsType.ach) {
        stripeCard.value?.destroy()
        stripeCard.value = null
      }
      else {
        exhaustiveCaseGuard(props.state.selectedCollectPaymentMethodDetailsType.value)
      }
    }

    watch(() => props.state.selectedCollectPaymentMethodDetailsType.value, async () => {
      await nextTick().then(updateCardElem)
    }, {
      immediate: false // need to wait until initial render to have some DOM to put stripe elements into
    })

    const showPaymentRequest = () => {
      // This is only for the GooglePay case, yeah?
      // It seems the ApplePay case will be triggered by some stripe callback responsible for the apple case
      // This is not called in the "default" stripe payment case.
      paymentRequest.value?.show()
    }

    const checkoutVia_collectUsBankAccountInfo_manualAcctNumber = async (event: CreateManualAchPaymentMethodEvent) : Promise<void> => {
      if (isSubscriptionInvoice(props.state.invoice)) {
        await setupIntentFlow("ACH Debit payment methods requires bank account verification prior to being used for an installment plan.")
      }
      else {
        // well, we could use the paymentIntent flow, but that produces
        // paymentMethods that will not yet be verified, meaning attempting to confirm a paymentIntent
        // with it on the backend will enter it into the "requires_action" state, which we don't currently want to
        // support.
        await setupIntentFlow("ACH Debit payment methods requires bank account verification prior to being used for this invoice.")
      }

      async function setupIntentFlow(onVerifyWithMicrodepositsMsg: string) {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const pm = await makePaymentMethod()

          if (pm.error) {
            iziToast.error({message: pm.error.message, timeout: false})
            return;
          }

          const setupIntent = await stripe_createAchSetupIntent_manualAcctNumber(axiosInstance, {clientGatewayID: props.state.invoice.paymentGatewayID, bankAccount_paymentMethodID: pm.paymentMethod.id})

          if (setupIntent.status === "requires_action" && setupIntent.next_action?.type === "verify_with_microdeposits") {
            ctx.emit("createdNewPaymentMethod", {
              paymentMethodID: pm.paymentMethod.id,
              finally: () => iziToast.warning({message: onVerifyWithMicrodepositsMsg, timeout: 30_000})
            })
            return;
          }
          else {
            // Alot of other error states on the setupIntent are unhandled,
            // but are expected to bubble out of this call with an error about being unable to pay
            ctx.emit("submitPayment", {paymentMethodID: pm.paymentMethod.id})
          }
        })
      }

      async function makePaymentMethod() {
        assertNonNull(User.userData)
        assertNonNull(stripeConfig.value)
        return await stripeConfig.value.stripeJS.createPaymentMethod({
            type: "us_bank_account",
            us_bank_account: {
              account_number: event.accountNumber,
              routing_number: event.routingNumber,
              account_holder_type: event.accountHolderType,
            },
            billing_details: {
              email: User.userData?.email,
              name: `${User.userData?.firstName} ${User.userData?.lastName}`
            },
          });
      }
    }

    const checkoutVia_collectUsBankAccountInfo_stripeModal = async () : Promise<void> => {
      // there's also a paymentIntent flow we might want to enable at some point,
      // but because we don't use it, in order to avoid allowing paymentIntents entering the "requires_action=verify_with_microdeposits"
      // state.
      await subscription_stripeModal_setupIntentFlow()

      async function subscription_stripeModal_setupIntentFlow() {
        const clientGatewayID = props.state.invoice.paymentGatewayID
        const setupIntent = await stripe_createAchSetupIntent_forClientBankAccountInfoCollectionModal(axiosInstance, {clientGatewayID});

        if (!setupIntent.client_secret) {
          iziToast.error({message: FALLBACK_ERROR_MESSAGE})
          // don't expect this to happen --- when does a setup_intent not have a client secret?
          maybeLog(getLogger(LoggedinLogWriter), "warning", "paymentTools/no-client-secret", {setupIntent: setupIntent.id, invoiceInstanceID: props.state.invoice.instanceID})
          return;
        }

        const clientSecret = setupIntent.client_secret

        assertNonNull(User.userData)
        assertNonNull(stripeConfig.value)

        const result = await stripeConfig.value.stripeJS.collectBankAccountForSetup({
          clientSecret,
          params: {
            payment_method_type: "us_bank_account",
            payment_method_data: {
              billing_details: {
                name: `${User.userData.firstName} ${User.userData.lastName}`,
                email: User.userData.email,
              }
            },
          },
          expand: ["payment_method"],
        })

        if (result.error) {
          iziToast.error({message: result.error.message, timeout: 60 * 1000})
          return;
        }

        if (result.setupIntent.status === "requires_payment_method") {
          // canceled by user, flow incomplete, bail. We'll be left with an abandoned SetupIntent. Oh well, no big deal.
          return
        }
        else if (result.setupIntent.status === "requires_confirmation") {
          if (result.setupIntent.client_secret && typeof result.setupIntent.payment_method === "object" && result.setupIntent.payment_method?.us_bank_account) {
            const secret = result.setupIntent.client_secret
            stripeModal_afterStripeModal_inleagueAchMandateModalController.open({
              bankAccount: result.setupIntent.payment_method.us_bank_account,
              onMandateAccepted: async () => {
                GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
                  stripeModal_afterStripeModal_inleagueAchMandateModalController.close()
                  await confirmBankAccountForSetupIntent(secret)
                })
              }
            })
          }
          else {
            // shouldn't happen, but nothing we can do here
            maybeLog(getLogger(LoggedinLogWriter), "warning", "paymentTools/ach/setupIntent/unexpected-state-1", result)
            return
          }
        }
        else {
          maybeLog(getLogger(LoggedinLogWriter), "warning", "paymentTools/ach/setupIntent/unexpected-state-2", result)
          return
        }

        // this means "we've offered them the mandate, and they confirmed it. Finish setting up the setup intent."
        async function confirmBankAccountForSetupIntent(setupIntent_clientSecret: string) {
          assertNonNull(stripeConfig.value)
          const result = await stripeConfig.value.stripeJS.confirmUsBankAccountSetup(setupIntent_clientSecret)

          if (result.error) {
            iziToast.error({message: result.error.message, timeout: false})
            maybeLog(getLogger(LoggedinLogWriter), "warning", "paymentTools/mandate-modal/setup-intent", result)
            return
          }
          else {
            const paymentMethodID = stripeObjId(result.setupIntent.payment_method)
            if (!paymentMethodID) {
              // shouldn't happen
              return;
            }

            await stripe_trackMultiUseActiveMandate(axiosInstance, {clientGatewayID: props.state.invoice.paymentGatewayID, setupIntentID: setupIntent.id})

            if (result.setupIntent.status === "succeeded") {
              props.state.selectedPaymentMethodID.value = paymentMethodID
              await ctx.emit("submitPayment", {paymentMethodID})
            }
            else {
              ctx.emit("createdNewPaymentMethod", {paymentMethodID})
            }
          }
        }
      }
    }

    /**
     * A modal we show _after_ the stripe "choose your bank account" modal,
     * to display our mandate. If this modal is dismissed, we do not proceed
     * with setupIntent confirmation or attempt any payment.
     *
     * In the non-stripeModal case (where we offer our own form that collects routing/account number),
     * our mandate is inline with the form, and this modal is not necessary.
     */
    const stripeModal_afterStripeModal_inleagueAchMandateModalController = (() => {
      return DefaultModalController_r<
        {
          // callback is responsible for closing the modal in the "on accepted" case
          onMandateAccepted: () => Promise<void>,
          bankAccount: PaymentMethod.UsBankAccount
        }
      >({
        title: () => <>
          <div>Direct Debit Authorization</div>
          <div class="my-2 border-b"/>
        </>,
        content: data => {
          if (!data) {
            return null
          }

          return <AchMandateModal
            bankAccount={data.bankAccount}
            merchantOfRecord={props.state.merchantOfRecord}
            onOk={async () => {
              await data.onMandateAccepted()
            }}
            onCancel={() => stripeModal_afterStripeModal_inleagueAchMandateModalController.close()}
          />
        }
      })
    })()

    const tryCreateNewCardPaymentMethod = async () : Promise<PaymentMethod | null> => {
      return await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        assertNonNull(stripeCard.value)

        try {
          assertNonNull(stripeConfig.value)
          const response = await stripeConfig.value.stripeJS.createPaymentMethod({
            type: 'card',
            card: stripeCard.value,
          })

          if (response.error) {
            iziToast.error({message: response.error.message})
            ctx.emit("error", response.error.message || "Sorry, something went wrong.")
            return null
          }
          else if (response.paymentMethod) {
            ctx.emit("createdNewPaymentMethod", {paymentMethodID: response.paymentMethod.id})
            return response.paymentMethod
          }
          else {
            exhaustiveCaseGuard(response)
          }
        }
        catch (err: any) {
          const msg = err.response.message
          iziToast.error({message: msg})
          ctx.emit("error", msg)
          return null
        }
      })
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        stripeConfig.value = await UserPaymentMethodsManagerState.loadStripe({stripeAccountID: props.state.stripeAccountID})
        if (!stripeConfig.value) {
          return
        }

        {
          const z = await UserPaymentMethodsManagerState.tryConfigureAppleOrGooglePay(props.state.invoice, stripeConfig.value)
          appleOrGooglePay.value = z.appleOrGooglePay
          paymentRequest.value = z.paymentRequest
          z.paymentRequest.on(
            "paymentmethod",
            (evt: PaymentRequestPaymentMethodEvent) => {
              if (evt.paymentMethod.id) {
                props.state.selectedPaymentMethodID.value = evt.paymentMethod.id
                ctx.emit("submitPayment", {paymentMethodID: evt.paymentMethod.id, paymentRequestPaymentMethodEvent: evt})
              }
              else {
                evt.complete("fail")
              }
            }
          )
        }

        ready.value = true

        await nextTick().then(updateCardElem)
      })
    })

    return () => {
      if (!ready.value) {
        return null
      }

      return <div data-test="UserPaymentMethodsManagerElement">
        <AutoModal class="max-w-lg" controller={stripeModal_afterStripeModal_inleagueAchMandateModalController} data-test="mandateModal"/>
        {stripeCardElementReady.value
          ? <div data-test="stripeCardElementReady">
            {/*
              This div is intentionally empty

              stripeCardElementReady -- empty element that serves as a playwright hook;
              testing for this element's presence in DOM lets us know if stripe has completed its
              own mounting logic for its card element.
              We can't test directly for the stripe DOM element's presence because its container is ALWAYS present,
              even before being fully ready, because it is a container for stripe to place its own content into.
            */}
          </div>
          : null}
        <table class="my-4 min-w-full divide-y divide-gray-200">
        {props.state.paymentMethods.length > 0
          ? <thead>
            <tr>
              <th class="px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider md:px-6"></th>
              <th class="px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider md:px-6"></th>
              <th class="px-2 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider md:px-6">Last 4 Digits</th>
              <th class="px-2 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-left md:px-6">{props.expiresColumnLabel}</th>
              <th class="px-2 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-left md:px-6">Remove</th>
            </tr>
          </thead>
          : null}

        <tbody class="bg-white divide-y divide-gray-200">
          {props.state.paymentMethods.map((paymentMethod) => {
            return <tr>
              <PaymentMethodListingTableCells
                paymentMethod={paymentMethod}
                selectedPaymentMethodID={props.state.selectedPaymentMethodID}
                onDeletePaymentMethod={() => ctx.emit("deletePaymentMethod", paymentMethod)}
                radioName="il-pm-listing"
              />
            </tr>
          })}

          <tr>
            {props.state.paymentMethods.length > 0
              ? <td class="px-2 py-4 whitespace-nowrap text-right text-sm flex justify-center items-center leading-5 font-medium align-top md:px-6">
                <input
                  type="radio"
                  class="w-4 transition"
                  value="addCard"
                  v-model={props.state.selectedPaymentMethodID.value}
                  data-test="addPaymentMethod"
                  id="il-pm-addPaymentMethod"
                  name="il-pm-listing"
                />
              </td>
              : null
            }

            {props.state.paymentMethods.length && props.state.selectedPaymentMethodID.value !== "addCard"
              ? <td class="text-sm" colspan="4">
                <label for="il-pm-addPaymentMethod">Add Payment Method</label>
              </td>
              : null}

            <td
              colspan="5"
              style={{
                // this was a v-show=<expr>, can it become a conditional/ternary thing? (what are effects of display:none on <td>'s...?)
                display: props.state.selectedPaymentMethodID.value === "addCard" || props.state.paymentMethods.length === 0
                  ? undefined
                  : "none"
              }}
            >
              {/*
                subscription invoices (for now, just those tournamentTeam(teamReg) invoices),
                need to have their paymentMethod saved, so we don't offer a choice in the matter.
                We __could__ allow ACH paymentMethods to not be saved, but this means allowing
                paymentIntents to enter the "verify_via_microdeposits" state, which we currently
                do not want to support (it introduces an additional layer of asynchronicity
                the verify_via_microdeposits phase) on top of the already additional ach-pending-processing phase)
              */}
              {props.savePaymentMethod
                ? <label class="ml-2 inline-flex items-center gap-2">
                  <input
                    class="rounded text-green-600 focus:ring-green-600"
                    type="checkbox"
                    v-model={props.savePaymentMethod.value}
                    data-cy="saveCard"
                  />
                  <span>Save payment method</span>
                </label>
                : null
              }

              <div class="ml-2 flex gap-3 items-center my-1">
                {props.state.allowedPaymentMethods.card
                  ? <label class="flex items-center gap-2">
                    <input
                      type="radio"
                      name="collectPaymentMethodDetailsType"
                      v-model={props.state.selectedCollectPaymentMethodDetailsType.value}
                      value={CollectPaymentMethodDetailsType.card}
                      checked={props.state.selectedCollectPaymentMethodDetailsType.value === CollectPaymentMethodDetailsType.card}
                      data-test="cardMode"
                    />
                    <span>Card</span>
                  </label>
                  : null}

                {props.state.allowedPaymentMethods.us_bank_account
                  ? <label class="flex items-center gap-2">
                    <input
                      type="radio"
                      name="collectPaymentMethodDetailsType"
                      v-model={props.state.selectedCollectPaymentMethodDetailsType.value}
                      value={CollectPaymentMethodDetailsType.ach}
                      checked={props.state.selectedCollectPaymentMethodDetailsType.value === CollectPaymentMethodDetailsType.ach}
                      data-test="achMode"
                    />
                    <span>ACH Debit</span>
                  </label>
                  : null}
              </div>
              <div class="grid grid-cols-1 gap-4 mx-2">
                <div class="border-solid border border-gray-200 rounded-md px-2 mr-4 md:mr-0">
                  {props.state.selectedCollectPaymentMethodDetailsType.value === CollectPaymentMethodDetailsType.ach
                    ? <div class="px-2 py-4 text-sm leading-5 text-gray-500">
                      Bank Account / ACH Debit
                      <div>
                        <div>
                          <label class="my-2 flex items-center gap-2">
                            <input
                              type="radio"
                              v-model={props.state.selectedManuallyEnterAchDetails.value}
                              value={false}
                              name="PaymentTools-achMethod"
                              data-test="stripeModalAchDetails"
                            />
                            <div>
                              {/*
                                TODO: user-visible verbiage that clearly differentiates "instant" vs "non-instant".
                                like "connect a bank account (generally instantly) | (using legacy ACH microdeposits mechanism)"
                                And that takes into consideration the fact that using the stripe modal will not always
                                result in instant verification, if the bank they are looking for is not in their provider list
                                and they fallback to the stripe modal's version of manual account number entry. Though,
                                it did seem there was a way to configure the modal to not allow manual entry, something like
                                'require instant verification when using the modal' in one of the stripe calls that kicks off the
                                modal (or was it the setupIntent/paymentIntent paymentMethodOptions that configures that...)
                              */}
                              <p>Pay by connecting a bank account.</p>
                              <p>Connecting your bank account in this way is typically faster than manually entering your routing and account number.</p>
                            </div>
                          </label>
                          {!props.state.selectedManuallyEnterAchDetails.value
                            ? <Btn2
                              class="my-2 px-2 py-1"
                              onClick={() => checkoutVia_collectUsBankAccountInfo_stripeModal()}
                              data-test="addAchPaymentMethodViaStripeModal"
                            >
                              {props.achStripeModalLabel}
                            </Btn2>
                            : null
                          }
                        </div>
                        <div class="border-b border-dashed"></div>
                        <div>
                          <label class="my-2 flex items-center gap-2">
                            <input
                              type="radio"
                              v-model={props.state.selectedManuallyEnterAchDetails.value}
                              value={true}
                              name="PaymentTools-achMethod"
                              data-test="manuallyEnterAchDetails"
                            />
                            <div>
                              <p>Manually enter a routing and account number.</p>
                              <p>Account verification via this method may take a few days.</p>
                            </div>
                          </label>
                        </div>
                        {props.state.selectedManuallyEnterAchDetails.value
                          ? <ManualAchInputForm
                            merchantOfRecord={props.state.merchantOfRecord}
                            acceptLabel={props.achManualInputLabel}
                            onCreatePaymentMethod={evt => checkoutVia_collectUsBankAccountInfo_manualAcctNumber(evt)}
                          />
                          : null}
                      </div>
                    </div>
                    : null}

                  {props.state.selectedCollectPaymentMethodDetailsType.value === CollectPaymentMethodDetailsType.card
                    ? <div class="px-2 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                      Credit Card
                      <div class="mt-4 rounded-md p-2 border w-full" id={cardElemID} data-test="cardElement">
                        {/*A Stripe Element will be inserted here.*/}
                      </div>
                      <div id="card-errors" class="text-red-600 text-sm my-2" role="alert">
                        {/*Used to display form errors.*/}
                      </div>
                      <div>
                        <div class="mt-2 font-light text-red-600">{props.errors}</div>
                        <Btn2
                          class="px-2 py-1 mt-2"
                          disabled={props.paymentInProgress}
                          onClick={async () => {
                            const pm = await tryCreateNewCardPaymentMethod()
                            if (pm) {
                              ctx.emit("submitPayment", {paymentMethodID: pm.id})
                            }
                          }}
                          data-test="submitPayment/newPaymentMethod"
                        >
                          {props.cardSubmitLabel}
                        </Btn2>
                      </div>
                    </div>
                    : null
                  }
                </div>
                <div>
                  {appleOrGooglePay.value
                    ? <div class="px-2 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 border-solid border-2 border-gray-200 rounded-md md:px-6">
                        <div>{appleOrGooglePay.value === "A" ? "Apple" : appleOrGooglePay.value === "G" ? "Google" : ""} Pay</div>
                        {appleOrGooglePay.value === "G"
                          ? <div class="mx-15">
                            <img
                              class="mt-4 self-center"
                              src="/app/google-pay-mark.svg"
                              data-cy="googlePay"
                              onClick={() => showPaymentRequest()}
                            />
                          </div>
                          : appleOrGooglePay.value === "A"
                          ? <div class="pr-40">
                            <div class="my-4" id="payment-request-button"></div>
                          </div>
                          : null}
                      </div>
                    : null}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {/*
        This slot isn't so necessary DOM-structure wise, but it does participate in the local 'ready.value=true' check.
        alternatively we could expose or emit an "isReady" value from this component.
      */}
      {(ctx.slots as UserPaymentMethodsManagerElementSlots).afterPaymentMethodsListing?.()}
      </div>
    }
  }
})


export type CreateManualAchPaymentMethodEvent = {
  routingNumber: string,
  accountNumber: string
  accountHolderType: "individual" | "company"
}

export interface UserPaymentMethodsManagerElementSlots {
  afterPaymentMethodsListing?: () => JSX.Element | null
}

const ManualAchInputForm = defineComponent({
  props: {
    merchantOfRecord: vReqT<string>(),
    /**
     * n.b. the actual button label and the text in the mandate need to share this exactly.
     */
    acceptLabel: vReqT<string>(),
  },
  emits: {
    createPaymentMethod: (_: CreateManualAchPaymentMethodEvent) => true,
  },
  setup(props, ctx) {
    const fk_sameAs = (node: FormKitNode, otherNodeName: string) => {
      const a = node.value;
      const b = node.root.find(otherNodeName)?.value
      return a === b;
    }

    const manualBankInfoAchMandateText = computed(() => {
      return AchMandate({
        acceptLabel: props.acceptLabel,
        merchantOfRecord: props.merchantOfRecord,
      })
    });

    const handleSubmit = (formData: any) => {
      const routingNumber = formData.routingNumber
      const accountNumber = formData.accountNumber
      const accountHolderType = formData.accountHolderType
      ctx.emit("createPaymentMethod", {routingNumber, accountNumber, accountHolderType})
    }

    // we rely on the form to "manage its own data";
    // but there are some fields we want to dynamically control in test mode
    const controlledData = reactive({
      routingNumber: "",
      accountNumber: "",
      accountNumberVerify: "",
    })

    return () => {
      return (
        <FormKit type="form" actions={false} onSubmit={handleSubmit}>
          <FormKit
            type="text"
            {...{inputmode: "numeric"}}
            label="Routing No."
            name="routingNumber"
            validation={[["required"], ["matches", /^[0-9]{9}$/]]}
            validationMessages={{matches: "A routing number should be a 9-digit number."}}
            data-test="ach_routingNumber"
            v-model={controlledData.routingNumber}
          />

          {process.env.NODE_ENV === "development"
            ? <div class="rounded-md border inline-block mb-4">
              <div class="p-1 bg-black rounded-t-md text-white">dev stuff</div>
              <div class="p-1">
                <div>
                  <a class="il-link" onClick={() => {
                    controlledData.routingNumber = "110000000"
                    controlledData.accountNumber = controlledData.accountNumberVerify = "000123456789"
                  }}>
                    pm_usBankAccount_success
                  </a>
                </div>
                <div>
                  <a class="il-link" onClick={() => {
                    controlledData.routingNumber = "110000000"
                    controlledData.accountNumber = controlledData.accountNumberVerify = "000000000009"
                  }}>
                    pm_usBankAccount_processing
                  </a>
                </div>
              </div>
            </div>
            : null}

          {/*TODO: validation -- are bank accounts always 'just digits'?*/}
          <FormKit
            type="text"
            label="Account No."
            name="accountNumber"
            validation={[["required"]]}
            data-test="ach_accountNumber"
            v-model={controlledData.accountNumber}
          />
          <FormKit
            type="text"
            label="Account No. Verify"
            name="accountNumberVerify"
            validation={[["required"], ["sameAs", "accountNumber"]]}
            validationRules={{sameAs: fk_sameAs}}
            validationMessages={{sameAs: "Must be same as accountNumber."}}
            data-test="ach_accountNumberVerify"
            v-model={controlledData.accountNumberVerify}
          />

          <FormKit
            type="select"
            label="Account Type"
            name="accountHolderType"
            options={[
              {label: "Individual", value: "individual"},
              {label: "Business", value: "company"}
            ]}
            data-test="ach_accountHolderType"
          />

          <div class="text-xs my-2" style="text-wrap:wrap; max-width: calc(5em + var(--fk-max-width-input));">
            {manualBankInfoAchMandateText.value}
          </div>
          <Btn2
            type="submit"
            class="px-2 py-1"
            data-test="ach_submit"
          >
            {props.acceptLabel}
          </Btn2>
        </FormKit>
      )
    }
  },
})
