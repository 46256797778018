<template lang="pug">
div(:data-test="`event-confirmation/eventID=${eventID}`")
  .grid.grid-rows-1.border-b.border-gray-200
    .row-span-1.px-6.py-3.bg-white.text-left.text-xl.leading-4.text-lg.text-gray-700.tracking-wider.row-span-1.w-full.font-medium
      | {{ events[eventID].eventName }}
    .row-span-1.px-6.py-1.bg-white.text-left.text-sm.text-gray-500.italic.tracking-wider
      | {{ formatDates(events[eventID].eventStart, events[eventID].eventEnd) }}
    .row-span-1.px-6.py-1.bg-white.text-left.text-sm.leading-4.text-gray-500.tracking-wider
      | {{ events[eventID].eventLoc }}
    .row-span-1.px-6.py-1.bg-white.text-left.text-sm.leading-4.text-gray-500.tracking-wider.mb-4
      | {{ events[eventID].venueStreet }}, {{ events[eventID].venueCity }}, {{ events[eventID].venueState }} {{ events[eventID].venueZip }}
  div(v-for='eventSignupID in eventSignupIDs', :data-test='`eventSignupID=${eventSignupID}`')
    //- Name and cost
    .bg-gray-50.flex.flex-row.justify-between.grid.grid-cols-6.border-b.border-gray-200.font-medium.text-gray-500
      .px-6.py-4.whitespace-nowrap.text-sm.leading-5.font-medium.col-span-1.flex.content-end.justify-around
        span.cursor-pointer(
          v-if="eventSignupByEventSignupID[eventSignupID].awaitingAsyncPaymentCompletion"
        )
          | Payment processing
        span.cursor-pointer.text-red-600(
          v-else
          @click='deleteEventSignup(eventSignupByEventSignupID[eventSignupID].eventSignupID)',
          data-cy='delete'
        )
          font-awesome-icon.mr-2(:icon='["fas", "times-circle"]')
          | Remove
        //- Maybe use the code below when multiple things are being confirmed?
        //- span.cursor-pointer.text-gray-800(
        //-   @click='editRegistration',
        //-   data-cy='edit'
        //- )
      .px-6.py-4.text-sm.leading-5.font-medium.text-gray-900.col-span-3
        | {{ getEventSignupUiEntityName(eventSignupID) }}
      .px-6.py-4.text-sm.leading-5.text-gray-500.col-span-2.justify-self-end
        component(:is="getEventSignupUiFee(eventSignupID)")
    //- Question/Answer info
    .bg-white.flex.flex-row.grid.grid-cols-6.border-b.border-gray-200(
      v-for='info in (eventSignupByEventSignupID[eventSignupID].userID ? eventSignupByEventSignupID[eventSignupID].questionAnswersUser : eventSignupByEventSignupID[eventSignupID].questionAnswersChild)'
    )
      .col-span-1.hidden(class='md:flex')
      .ml-8.col-span-6.flex.flex-row(class='md:col-span-5')
        .px-6.py-4.text-sm.leading-5.font-medium.text-gray-700
          | {{ info.question.label }}
        .px-6.py-4.text-sm.leading-5.text-gray-500
          | {{ (info.answer + "").slice(0, 1).toUpperCase() + (info.answer + "").slice(1) }}
</template>


<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'


import { formatDates } from 'src/helpers/formatDate'

import { propsDef, emitsDef, FeeBeforeAndAfterVisibleDiscounts } from "./EventConfirmation.ilx"

import * as iltypes from "src/interfaces/InleagueApiV1"
import { EventStore } from "src/store/EventStore"
import { CheckoutStore } from "src/store/CheckoutStore"

export default defineComponent({
  name: 'EventConfirmation',
  props: propsDef,
  emits: emitsDef,
  setup(props, { emit }) {
    const total = ref(0)

    const route = useRoute()


    const events = computed(() => {
      return EventStore.value.registeringEvents
    })

    const eventSignupByEventSignupID = computed(() => {
      return CheckoutStore.value.registrants
    })

    const eventSignupIDsByEventIdInCart = computed(() => {
      return CheckoutStore.value.eventsCart
    })

    const calculateTotal = () => {
      let currentTotal = 0
      for (const eventSignupID of props.eventSignupIDs) {
        currentTotal += eventSignupByEventSignupID.value[eventSignupID].computed_feeAfterVisibleDiscounts || 0;
      }
      const diff = currentTotal - total.value
      emit('updateTotal', diff)
      total.value = currentTotal
    }


    const deleteEventSignup = (eventSignupID: iltypes.Guid) => {
      const fee = -(eventSignupByEventSignupID.value[eventSignupID].computed_feeAfterVisibleDiscounts || 0)
      emit('deleteItem', {
        type: 'event',
        group: props.eventID,
        registrationID: eventSignupID,
        fee,
        eventSignup: eventSignupByEventSignupID.value[eventSignupID]
      })
    }

    const getEventSignupUiFee = (eventSignupID: iltypes.Guid) => {
      return FeeBeforeAndAfterVisibleDiscounts({eventSignup:eventSignupByEventSignupID.value[eventSignupID]});
    }

    const getEventSignupUiEntityName = (eventSignupID: iltypes.Guid) => {
      const signup = eventSignupByEventSignupID.value[eventSignupID];
      if (signup.childID) {
        return `${signup.childFirstName} ${signup.childLastName}`;
      }
      else {
        return `${signup.userFirstName} ${signup.userLastName}`;
      }
    }


    onMounted(() => {
      calculateTotal()
    })

    return {
      total,
      events,
      eventSignupByEventSignupID,
      calculateTotal,
      deleteEventSignup,
      formatDates,
      getEventSignupUiFee,
      getEventSignupUiEntityName,
    }
  },
})
</script>

