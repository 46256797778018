import authService from "src/helpers/authService"
import { User } from "src/store/User"

export const RouteName = "practice-scheduler"

export function AuthZ_practiceSchedulerSuperUser() : boolean {
  return authService(User.value.roles, "registrar", "webmaster", "gameScheduler")
}

export function AuthZ_practiceSchedulerSelfScheduler() : boolean {
  return !!User.userData?.coachAssignmentsMemento.find(v => v.title === "Head Coach" || v.title === "Co-Coach")
}
