import { AreaCoachDetail_lowercase, AreaCoachGameData, CoachDetail, GameBracketRoundSlotSource, GameTeamDetail, getAreaCoachesForGames } from "src/composables/InleagueApiV1.Game";
import { ExtractPropTypes, PropType } from "vue";
import * as iltypes from "src/interfaces/InleagueApiV1"
import dayjs from "dayjs";
import authService from "src/helpers/authService";

import { arrayFindOrFail, flowCapture, parseIntOr, parseIntOrFail, sortBy, sortByDayJS, sortByMany, Sorter } from "src/helpers/utils";

import * as ilapi from "src/composables/InleagueApiV1"
import { dayjsFormatOr } from "src/helpers/formatDate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faInfoCircle, faTally } from "@fortawesome/pro-solid-svg-icons";
import { Client, RefSlotConfigLookup } from "src/store/Client";
import { AxiosInstance } from "axios";
import { Competition, Division, Guid, Integerlike } from "src/interfaces/InleagueApiV1";
import { WellKnownRefSlotIndex } from "src/interfaces/Store/client";
import { GameScheduleLayoutCompSeasonDivInfoThatMayCrossLeagueBoundaries } from "src/composables/InleagueApiV1";
import { teamDesignationAndMaybeName } from "src/components/GameScheduler/calendar/GameScheduler.shared";
import { RouterLink } from "vue-router";
import { Route as R_BracketView } from "src/components/GameScheduler/brackets/R_BracketView"
import { User } from "src/store/User";
import { QuasarColDef, quasarColumnSortAdapter } from "src/helpers/Quasar";

export function propsDef() {
  return {
    // optional
    competitionID: {
      type: null as any as PropType<iltypes.Integerlike>,
      required: false
    },
    // optional, but part of a path param wherein it cannot be provided without competitionID being provided
    divID: {
      type: String as PropType<iltypes.Guid | "ALL">,
      required: false
    }
  }
}

export type Props = ExtractPropTypes<ReturnType<typeof propsDef>>;

/**
 * If either competition or division is null, we cannot do anything, and return null.
 * @param gamelikes all are expected to be for the same supplied `competition` and `division` (if both are truthy). If the list is empty, this function returns null.
 */
export function maybeComputeDivisionStandingsURL(args: {
  competition: null | Pick<Competition, "standingsDivnum">,
  division: null | Pick<Division, "divNum">,
  gamelikes:
    (
      & {gameID: Guid, division: string}
      & GameScheduleLayoutCompSeasonDivInfoThatMayCrossLeagueBoundaries
    )[],
}) : string | null {
  if (args.gamelikes.length === 0
    || !args.competition
    || !args.division
  ) {
    return null;
  }

  if (!shouldOfferDivisionStandings(args.competition, args.division)) {
    return null
  }

  return computeDivisionStandingsURL({
    appDomain: Client.value.instanceConfig.appdomain,
    gamelike: args.gamelikes[0],
    invokingUserRoles: User.value.roles,
  })
}

function shouldOfferDivisionStandings(comp: Pick<Competition, "standingsDivnum">, division: Pick<Division, "divNum">) : boolean {
  const ALWAYS_OFFER = 0
  const NEVER_OFFER = 99

  // TODO: this should be parseIntOrFail, but for now standingsDivNum may not be present
  const standingsDivNum = parseIntOr(comp.standingsDivnum, ALWAYS_OFFER)
  const targetDivNum = parseIntOrFail(division.divNum)

  if (standingsDivNum === ALWAYS_OFFER) {
    return true;
  }
  else if (standingsDivNum === NEVER_OFFER) {
    return false
  }
  else {
    return targetDivNum >= standingsDivNum
  }
}

/**
 * also see views/gameSchedules/index.cfm
 */
function computeDivisionStandingsURL(
  props: {
    appDomain: string,
    //
    // Caller probably wants to pass a list of gamelikes, but:
    // - we assume caller is focused on a singular competition
    // - we assume caller is focused on a singular division
    // - we assume all the games in the caller's list of games are for a single season
    // - so, we implicitly have a (comp, season, div)
    // - therefore, we assume all caller's comp/season/div centric values are the same
    // So we accept a singular "gamelike" which is good enough and represents all the caller's game's (comp, season, div).
    //
    gamelike:
      & {gameID: Guid, division: string}
      & GameScheduleLayoutCompSeasonDivInfoThatMayCrossLeagueBoundaries
    invokingUserRoles: string[]
  }
) : string | null {
  const isAvailableToAllUsers = props.gamelike.competition_useScores;
  const hasSomeElevatedAuthz = authService(props.invokingUserRoles, "DD","DDReporter","ChiefRef","playerAdmin","registrar","webmaster","inLeague");

  if (!isAvailableToAllUsers && !hasSomeElevatedAuthz) {
    return null;
  }

  const cfm = props.gamelike.hasSomePoolForCompSeasonDivision ? "rankings-pool.cfm" : "rankings.cfm";
  const startDate = dayjs(props.gamelike.competition_season_seasonStart).format("YYYY-MM-DD")
  // legacy code computes endDate by "last game date for some competition", this is a rough surrogate
  const endDate = dayjs(props.gamelike.competition_season_seasonStart).add(props.gamelike.competition_season_seasonWeeks, "weeks").format("YYYY-MM-DD");
  const urlParams = [
    `startDate=${startDate}`,
    `endDate=${endDate}`,
    `competition=${props.gamelike.competition_competitionID}`, // ID, not UID
    `division=${props.gamelike.division}` // division, not divID
  ]
  // TODO: Division standings do not require a login and so do not need main/in - replace with a regular link until we move division standings to the 20th anniversary app
 /* const targetURL = buildLegacyLink(
    props.appDomain,
    `/scoring/${cfm}?${urlParams.join("&")}`,
    "",
  )*/
  const targetURL = 'https://' + props.appDomain + `/scoring/${cfm}?${urlParams.join("&")}`;
  return targetURL;
}

export type GamelikeForSchedule = ilapi.LoggedInUserGame | ilapi.LoggedOutGame;
export type AugmentedGamelikeForSchedule<T extends GamelikeForSchedule = GamelikeForSchedule> = T & {
  competition: Competition,
  shouldDisplayScoreInfo: boolean,
  /**
   * Null means "area coaches aren't applicable here", whereas the non-null case ("area coaches are applicable here") can still be empty
   */
  areaCoachDetail: null | AreaCoachGameData<AreaCoachDetail_lowercase>,
  refSlotConfigLookup: RefSlotConfigLookup,
}

export function augmentGamelikesForSchedule<T extends GamelikeForSchedule>(gamelikes: T[], competitions: readonly Competition[], areaCoaches: null | AreaCoachGameData<AreaCoachDetail_lowercase>[], refSlotConfigLookup: RefSlotConfigLookup) : AugmentedGamelikeForSchedule<T>[] {
  return gamelikes.map((gamelike) : AugmentedGamelikeForSchedule<T> => {
    return {
      ...gamelike,
      competition: arrayFindOrFail(competitions, comp => comp.competitionUID === gamelike.competitionUID),
      shouldDisplayScoreInfo: (() => {
        const hideScores = !!gamelike.competition_hideScores;
        const hasSomeScore = !isNaN(parseInt(gamelike.homeGoals as any));
        return hasSomeScore && !hideScores;
      })(),
      areaCoachDetail: areaCoaches?.find(v => v.gameID === gamelike.gameID) ?? null,
      refSlotConfigLookup,
    }
  })
}

// there are many representations of "no {home,visitor}Team" across the app,
// but it doesn't really matter which one a caller supplies, so long as the object case is the same
export type GetAreaCoaches_MinGamelike = {
  gameID: Guid,
  homeTeam?: undefined | null | "" | {
    region: Integerlike
  },
  visitorTeam?: undefined | null | "" | {
    region: Integerlike
  },
}

/**
 * Lookup area coaches for those games "maybe having area teams" (which currently is "does the game have at least one 'foreign' team")
 */
export async function getAreaCoaches(axios: AxiosInstance, games: GetAreaCoaches_MinGamelike[]) {
  const gameOwningClient = {
    clientID: Client.value.instanceConfig.clientid,
    region: parseIntOrFail(Client.value.instanceConfig.region)
  } as const;

  const gamesMaybeHavingAreaCoaches = games.filter(game => maybeHasAreaCoaches(game));

  if (gamesMaybeHavingAreaCoaches.length === 0) {
    return null;
  }
  else {
    return await getAreaCoachesForGames(axios, {gameIDs: gamesMaybeHavingAreaCoaches.map(game => game.gameID)});
  }

  function maybeHasAreaCoaches(game: GetAreaCoaches_MinGamelike) : boolean {
    // not parseIntOrFail because it is possible for a game to be "TBD" for one or both of the teams
    const homeTeamRegion = typeof game.homeTeam === "object" ? parseIntOr(game.homeTeam?.region, null) : null;
    const visitorTeamRegion = typeof game.visitorTeam === "object" ? parseIntOr(game.visitorTeam?.region, null) : null;

    const homeTeamIsForeign = homeTeamRegion !== null && homeTeamRegion !== gameOwningClient.region;
    const visitorTeamIsForeign = visitorTeamRegion !== null && visitorTeamRegion !== gameOwningClient.region;

    return homeTeamIsForeign || visitorTeamIsForeign;
  }
}

export interface ColDef extends QuasarColDef<AugmentedGamelikeForSchedule> {
  field: (game: AugmentedGamelikeForSchedule) => string | ((forwardedProps?: {class?: string, style?: string}) => JSX.Element)
}

/**
 * a column def's evaluted field may return a string or another function that will return JSX
 * This does the right thing with either type of result.
 */
export function JsxQuasarColumnRenderShim(props: {fieldResult: string | ((forwardedProps?: Record<string, any>) => JSX.Element)}) : JSX.Element {
  if (typeof props.fieldResult === "string") {
    return <span>{props.fieldResult}</span>
  }
  else {
    const forwardedProps : {class?: string, style?: string} = {}
    if ("class" in props) {
      forwardedProps.class = props.class as string;
    }
    if ("style" in props) {
      forwardedProps.style = props.style as string;
    }
    return props.fieldResult(forwardedProps);
  }
}

function teamWithMaybeTeamName(game: GamelikeForSchedule, which: "home" | "visitor", gameTeamDetail: GameTeamDetail, coachInfo: readonly CoachDetail[], areaCoachInfo: readonly AreaCoachDetail_lowercase[]) : JSX.Element {
  const coachAndAreaCoaches = [...coachInfo, ...areaCoachInfo]

  const headAndCoCoaches = (() => {
    if (coachAndAreaCoaches.length === 0) {
      return "";
    }

    return coachAndAreaCoaches
      .filter(coach => coach.title === "Head Coach" || coach.title === "Co-Coach")
      .sort(sortByMany(sortBy(_ => _.lastname)))
      .map(v => v.lastname)
      .join(", ");
  })();

  const asstCoachBlurb = (() => {
    return coachAndAreaCoaches
      .filter(coach => coach.title === "Assistant")
      .sort(sortByMany(sortBy(_ => _.lastname)))
      .map((e,i,a) => `Asst. Coach ${e.lastname}`)
      .join(", ");
  })();

  const showInfoPopup = !!asstCoachBlurb

  return (
    <div>
      <div style={`display:grid; grid-template-columns: ${showInfoPopup ? "auto 1fr" : "1fr"}; white-space:break-spaces; align-items:start;`}>
        {showInfoPopup
          ? <button
            type="button"
            class="ml-1.cursor-pointer px-2"
            v-tooltip={{content: asstCoachBlurb}}
          >
            <FontAwesomeIcon class="text-gray-400" icon={faInfoCircle}/>
          </button>
          : null
        }
        <div class="-mt-[2px]">
          <div>
            {bracketAwareTeamName(
              {teamDesignation: gameTeamDetail.team, teamName: /*TODO: we don't have this here, need to get it*/"", teamID: gameTeamDetail.teamID},
              (which === "home" ? game.bracketInfo?.sourceLeft : game.bracketInfo?.sourceRight) ?? null
            )}
          </div>
          {headAndCoCoaches}
        </div>
      </div>
    </div>
  )
}

/**
 * non-uniform casing here (camel and pascal case) is intentional to maintain existing names and object shapes
 */
export const ColName = {
  icons: "icons",
  fieldID: "fieldID",
  gameStart: "gameStart",
  Home: "Home",
  Visitor: "Visitor",
  Comment: "Comment",
  scoreInfo: "scoreInfo",
  refs: "refs",
} as const;

/**
 * coldefs for typical gamelike table layout
 */
export function GamelikeColDefs() : ColDef[] {
  return [
    {
      name: ColName.icons,
      required: true,
      label: '',
      align: 'left',
      field: game => {
        if (game.doPointsCount) {
          return () => <FontAwesomeIcon icon={faTally} class="outline-none" v-tooltip={{content: "Points count"}}/>
        }
        else {
          return ""
        }
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
      sortable: false,
    },
    {
      name: ColName.fieldID,
      required: true,
      label: 'Field',
      align: 'left',
      field: game => {
        const maybeFromLoggedInUserGame = (game as ilapi.LoggedInUserGame).fieldAbbreviation;
        const maybeFromGameScheduleInfo = (game as ilapi.LoggedOutGame).fieldAbbrev;
        return maybeFromLoggedInUserGame || maybeFromGameScheduleInfo;
      },
      sortable: true,
      sort: quasarColumnSortAdapter(sortBy(v => {
        return (v as ilapi.LoggedInUserGame).fieldAbbreviation
          || (v as ilapi.LoggedOutGame).fieldAbbrev
          || "zzz";
      })),
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
    },
    {
      name: ColName.gameStart,
      required: false,
      label: 'Date',
      align: 'left',
      sortable: true,
      sort: quasarColumnSortAdapter(sortByDayJS(v => v.gameStart)),
      field: game => {
        return forwardedProps => (
          <>
            <div {...forwardedProps}>{dayjsFormatOr(game.gameStart, "M/D/YY")}</div>
            <div {...forwardedProps}>{dayjsFormatOr(game.gameStart, "h:mm a")}</div>
          </>
        )
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
    },
    {
      name: ColName.Home,
      align: 'left',
      label: 'Home',
      field: game => {
        const homeTeam = flowCapture(game.homeTeam);
        return homeTeam
          ? () => teamWithMaybeTeamName(game, "home", homeTeam, game.homeCoaches, game.areaCoachDetail?.homeAreaCoaches ?? [])
          : () => bracketAwareTeamName(null, game.bracketInfo?.sourceLeft ?? null)
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
      sortable: false,
    },
    {
      name: ColName.Visitor,
      align: 'left',
      label: 'Visitor',
      field: game => {
        const visitorTeam = game.visitorTeam;
        return visitorTeam
          ? () => teamWithMaybeTeamName(game, "visitor", visitorTeam, game.visitorCoaches, game.areaCoachDetail?.visitorAreaCoaches ?? [])
          : () => bracketAwareTeamName(null, game.bracketInfo?.sourceRight ?? null)
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
      sortable: false,
    },
    {
      name: ColName.refs,
      align: 'left',
      label: 'Refs',
      field: game => () => {
        const refSlotConfig = game.refSlotConfigLookup.find(game)

        const NO_REF = "N/A"
        const info : {slotName: string, refLastName: string}[] = []

        for (let i = 0; i < refSlotConfig.numSlots; i++) {
          const ref = game[`ref${(i+1) as WellKnownRefSlotIndex}`]

          const refLastName = typeof ref === "object"
            ? ref.LastName || NO_REF
            : NO_REF;

          const slotName = refSlotConfig[`pos${(i+1) as WellKnownRefSlotIndex}Name`]

          info.push({slotName, refLastName})
        }

        return <table class="border-collapse">{info.map(v => <tr>
          <td style="padding: 0 .25em;">{v.slotName}</td>
          <td style="padding: 0 .25em;">{v.refLastName}</td>
        </tr>)}</table>
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
      sortable: false,
    },
    {
      name: ColName.Comment,
      align: 'left',
      label: 'Comment',
      field: game => {
        return () => <div>
          {game.bracketInfo
            ? <div>
                <div class="flex items-center gap-1">
                  <span>Bracket:</span>
                  {" "}
                  <RouterLink class="il-link" {...{target: "_blank"}} to={R_BracketView(game.bracketInfo)}>
                    {game.bracketInfo.bracketName}, {game.bracketInfo.bracketRoundName}
                  </RouterLink>
                </div>
                <div class="my-1 border-b"/>
              </div>
            : null}
          {game.comment}
        </div>
      },
      style: 'vertical-align: top; font-size: 16px',
      headerStyle: 'font-size: 16px',
      sortable: false,
    },
    {
      name: ColName.scoreInfo,
      field: game => {
        return () => (
          <div>
            <div class="flex items-center">
              <span class="text-xs">Score:</span>
              <span class="ml-1">{ game.homeGoals } - { game.visitorGoals }</span>
            </div>
          </div>
        )
      }
    },
  ]
}

const bracketAwareTeamName = (teamlike: {teamDesignation: string, teamName: string, teamID: string} | null, bracketSlotSource: GameBracketRoundSlotSource | null) => {
  const dateformat = "MMM DD, 'YY"
  const winnerLoserText = (v: "winner" | "loser") => v === "winner" ? "Winner of" : "Loser of";
  return <div class="flex items-center p-1">
    {teamlike
      ? teamDesignationAndMaybeName(teamlike)
      : bracketSlotSource
      ? (bracketSlotSource.sourceGame
        ? <div>
          {winnerLoserText(bracketSlotSource.sourceType)} #{bracketSlotSource.sourceGame.gameNum}
          <div class="text-xs">
            {dayjs(bracketSlotSource.sourceGame.gameStart).format(dateformat)}, {bracketSlotSource.sourceGame.fieldAbbrev}
          </div>
        </div>
        : <div>{winnerLoserText(bracketSlotSource.sourceType)} prior game</div>
      )
      : "TBD"
    }
  </div>
}
