import { defineComponent, onMounted, ref, watch, computed, reactive, Fragment, Ref } from "vue";
import * as ilapi from "src/composables/InleagueApiV1"
import { AxiosErrorWrapper, axiosInstance } from "src/boot/axios";
import * as iltypes from "src/interfaces/InleagueApiV1"
import { unsafe_objectKeys, exhaustiveCaseGuard, parseFloatOr, ArrayElement_t, UiOption, EscapedRegExp, downloadFromObjectURL, isGuid, sortByDayJS, routeGetQueryParamAsStringOrNull, forceCheckedIndexedAccess } from "src/helpers/utils";
import { paginationData } from "src/modules/PaginationUtils"

import { invoiceLastStatusAsUiString, LastStatus_t } from "src/interfaces/Store/checkout";
import * as PlayerEditor from "src/components/PlayerEditor/PlayerEditor.route"
import { dayjsFormatOr } from "src/helpers/formatDate";

import { FormKit } from "@formkit/vue"
import iziToast from "izitoast";

import { Action_t } from "./R_CompRegsWithBlockedPaymentIntents.ilx"

import { RouterLink, useRouter } from "vue-router";
import authService from "src/helpers/authService";
import * as XlsxUtils from "src/modules/XlsxUtils"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dayjs from "dayjs";

import { propsDef, routeDetailToRoutePath, RouteName } from "./R_CompRegsWithBlockedPaymentIntents.route"

import * as MasterInvoice from "src/components/Payment/pages/MasterInvoice.route"
import { User } from "src/store/User"
import { Client } from "src/store/Client";
import { getInitiallySelectedSeasonMenuOption } from "./WaitlistManager.api";
import { GlobalInteractionBlockingRequestsInFlight } from "src/store/EventuallyPinia";
import { Guid } from "src/interfaces/InleagueApiV1";

type ExpandedCompReg = ArrayElement_t<Awaited<ReturnType<typeof ilapi.getActiveCompRegsThatAreOrWereBlocked>>>

/**
 * Answers: "Is some compreg selectable? If not, why not?"
 *
 * We might want to fold the entire "detail" column into here, so we get {checkable: boolean, detailColumn: JSX.Element}
 * and then this is "rowState()" or similar
 */
function selectabilityState(compReg: ExpandedCompReg, action: Action_t) : {checkable: true} | {checkable: false, detail: JSX.Element} {
  const alreadyPaid = () => <div class="p-2 flex flex-col justify-center items-start">this registration is marked paid</div>

  switch (action) {
    case Action_t.IMMEDIATE_ATTEMPT_PAYMENT: {
      if (compReg.paid) {
        return {checkable: false, detail: alreadyPaid()}
      }

      if (compReg.invoice_lastStatus === LastStatus_t.IN_FLIGHT || compReg.invoice_lastStatus === LastStatus_t.PROCESSING_ACH) {
        return {checkable: false, detail: <div class={`p-2 flex flex-col justify-center items-start`}>... payment in process ...</div>}
      }
      else if (!compReg.invoice_stripe_paymentMethodID && parseFloatOr(compReg.invoiceLineItem_finalAmount, -1) >= 0.01) {
        // no paymentMethodID AND there is an associated fee, meaning we can't pay-attempt it
        // n.b. that if there is no paymentMethodID BUT there IS NO associated fee, we CAN pay-attempt it, because we don't need a paymentMethod to "pay" nothing.
        return {
          checkable: false,
          detail: (
              <div class={`p-2 flex flex-col justify-center items-start`} data-test="unactionable">
                <div class="text-sm">Unactionable</div>
                <div class="text-xs">(no payment info on file)</div>
              </div>
          )
        }
      }
      else {
        // this thing is pay-attemptable
        return {checkable: true};
      }
    }
    case Action_t.PROMPT_USER: {
      if (compReg.paid) {
        return {checkable: false, detail: alreadyPaid()}
      }

      if (compReg.invoice_lastStatus === LastStatus_t.IN_FLIGHT || compReg.invoice_lastStatus === LastStatus_t.PROCESSING_ACH) {
        return {checkable: false, detail: <div class={`p-2 flex flex-col justify-center items-start`}>... payment in process ...</div>}
      }
      else {
        return {checkable: true}
      }
    }
    case Action_t.FORCE_CANCEL: {
      if (compReg.paid) {
        return {checkable: false, detail: alreadyPaid()}
      }

      if (compReg.invoice_lastStatus === LastStatus_t.IN_FLIGHT || compReg.invoice_lastStatus === LastStatus_t.PROCESSING_ACH) {
        return {
          checkable: false,
          detail: (
            <div>
              <div>... payment in process ...</div>
              <div class="text-xs">(cannot force cancel)</div>
            </div>
          )
        }
      }
      else {
        return {checkable: true};
      }
    }
    default: exhaustiveCaseGuard(action)
  }
}

function copyOnly<T>(vs: T[]) {
  const localCopy = JSON.parse(JSON.stringify(vs));
  return {
    copy: () => JSON.parse(JSON.stringify(localCopy))
  }
}
interface Copyable<T> {
  copy: () => T
}

export default defineComponent({
  props: propsDef,
  setup(props) {
    const ready = ref(false);

    const router = useRouter();

    //
    // Select options form a dag: season -> competition -> division
    // an invalidation of an ancestor invalidates all descendants
    // descendants should not be selectable until its immediate ancestor is selected.
    //
    // Season options are "global", we pull a bunch of seasons and that's that
    //
    // We might sometimes want to alter this list; we guarantee that we can return to the onMounted state
    // by making a copy of the "asOfOnMounted" version.
    //
    const asOfOnMounted_seasonOptions = ref<Copyable<(UiOption & {key: string})[]>>({copy: () => []});
    const seasonOptions = ref<(UiOption & {key: string})[]>(asOfOnMounted_seasonOptions.value.copy());

    // Competition options are refreshed on change of selected season
    const competitionOptions = ref<UiOption[]>([]);
    // Division options are pulled along with refresh of competition options, but will possibly be different per selected competition
    const divisionOptions = ref<{[competitionUID: iltypes.Guid]: UiOption[]}>({});

    // sometimes we want to programmatically change comp/div/season options, for which watchers are registered
    // We want to not fire those watchers when making such changes.
    // Some paranoiac support for many async frames performing such work; we keep a count of these frames rather than just a single "yes/no" bool
    // Probably this is unnecessary but it's not expensive or much more complex
    const __depth_isProgrammaticallyConfiguringCompSeasonDivSelections = ref(0)
    const isProgrammaticallyConfiguringCompSeasonDivSelections = computed(() => __depth_isProgrammaticallyConfiguringCompSeasonDivSelections.value > 0);
    const withDisabledCompSeasonDivWatchers = async (f: () => void) : Promise<void> => {
      try {
        __depth_isProgrammaticallyConfiguringCompSeasonDivSelections.value += 1;
        await f();
      }
      finally {
        __depth_isProgrammaticallyConfiguringCompSeasonDivSelections.value -= 1;
      }
    }

    const selectedAction = ref<Action_t>(Action_t.IMMEDIATE_ATTEMPT_PAYMENT)
    const shouldSendCancellationNotification = ref(false);

    const cancellationNotificationText = ref("");
    const cancellationNotificationMaxLength = 1000;

    // User input for registrationID.
    // On init, we default it to the provided query parameter, if one exists.
    const tentativeRegistrationID = ref(props.detail.query?.registrationID ?? "");
    const commitRegistrationIDChange = async () => {
      await router.replace(routeDetailToRoutePath({name: RouteName.main, query: {registrationID: tentativeRegistrationID.value}}))
    }

    const RetrievalFilterType_t = {
      ONLY_INCOMPLETE: "ONLY_INCOMPLETE",
      ARE_BLOCKED_OR_WERE_BLOCKED: "ARE_BLOCKED_OR_WERE_BLOCKED",
    } as const;
    type RetrievalFilterType_t = (typeof RetrievalFilterType_t)[keyof typeof RetrievalFilterType_t];
    const retrievalFilterTypeOptions : UiOption<RetrievalFilterType_t>[] = [
      {label: "Not yet paid", value: RetrievalFilterType_t.ONLY_INCOMPLETE},
      {label: "All Waitlisted (Inc'l Paid)", value: RetrievalFilterType_t.ARE_BLOCKED_OR_WERE_BLOCKED},
    ];

    const filter = reactive({
      playerName: "",
      selectedRetrievalFilterType: RetrievalFilterType_t.ONLY_INCOMPLETE as RetrievalFilterType_t
    });

    /**
     * holds response from api, the compregs this page is interested in.
     * This list should only be mutated by assignment as a result of an api request.
     * Any client side filtering / sorting should be as a computed view into this.
     */
    const data = ref<ExpandedCompReg[]>([])
    const zi_currentPage = ref(0);

    // janky repaint lag on showing all for ~500+
    // Need a virtual scroll
    const itemsPerPageOptions : UiOption<"ALL" | number>[] = [
      {label: "All", value: "ALL"},
      {label: "25", value: 25},
      {label: "50", value: 50},
    ]
    const itemsPerPage = ref<number | "ALL">(itemsPerPageOptions[0].value);

    // this could be `filteredSortedRows`, if we want sorting
    const filteredRows = computed<ExpandedCompReg[]>(() => {
      return applyRetrievalType(applyPlayerName(data.value));

      function applyRetrievalType(compRegs: ExpandedCompReg[]) {
        switch (filter.selectedRetrievalFilterType) {
          case RetrievalFilterType_t.ARE_BLOCKED_OR_WERE_BLOCKED:
            return compRegs;
          case RetrievalFilterType_t.ONLY_INCOMPLETE:
            return compRegs.filter(compReg => {
              if (compReg.paid) {
                // somtimes paid=1 can get a little out of sync with LastStatus_t in cases where
                // a compreg is manually activated. But this seems reasonable -- if it's paid=1,
                // then it is NOT incomplete
                return false
              }
              switch (compReg.invoice_lastStatus) {
                case LastStatus_t.NULLISH:
                  // fallthrough
                case LastStatus_t.CREATED:
                  // fallthrough
                case LastStatus_t.PAYMENT_REJECTED:
                  // fallthrough
                case LastStatus_t.IN_FLIGHT:
                  return true;
                default:
                  return false;
              }
            });
          default:
            exhaustiveCaseGuard(filter.selectedRetrievalFilterType);
        }
      };

      function applyPlayerName (compRegs: ExpandedCompReg[]) {
        if (filter.playerName.trim() === "") {
          return compRegs;
        }
        else {
          const regex = EscapedRegExp(filter.playerName, "i");
          return compRegs.filter(compReg => regex.test(compReg.playerDisplayName));
        }
      }
    });

    /**
     * comparator should always sort ascending, and the result is flipped after being run if the "current dir" is desc
     */
    class Sorter<T = any, Id = string> {
      readonly id: Id;
      private readonly comparator_: (l: T, r: T) => number;
      private dir_: "asc" | "desc"

      constructor(id: Id, comparator_: (l:T, r:T) => number, dir: "asc" | "desc" = "asc") {
        this.id = id;
        this.comparator_ = comparator_;
        this.dir_ = dir;
      }

      compare(l: T, r: T) : number {
        const result = this.comparator_(l,r);
        if (this.dir_ === "asc") {
          return result;
        }
        else {
          return result === 1 ? -1 : result === -1 ? 1 : 0;
        }
      }

      flipDir() : void {
        this.dir_ = this.dir_ === "asc" ? "desc" : "asc";
      }

      setDir(dir: "asc" | "desc") : void {
        this.dir_ = dir;
      }

      getDir() {
        return this.dir_;
      }
    }

    type SortableIDs = "playerName" | "competitionName" | "divisionName" | "dateOfRegistration"

    // investigate: position of type annotation (ref<T>([]) vs. ref([]) as Ref<T>) is meaningful here,
    // it ends up forgetting private class members in the ref<T>([]) case
    const sorters = ref([]) as Ref<Sorter<ExpandedCompReg, SortableIDs>[]>;

    const pushAndOrToggleSorter = (id: SortableIDs) : void => {
      const existingIdx = sorters.value.findIndex(v => v.id === id);
      if (existingIdx === -1) {
        switch (id) {
          case "playerName":
            sorters.value.unshift(new Sorter(id, (l,r) => {
              if (l.playerLastName < r.playerLastName) {
                return -1;
              }
              else if (l.playerLastName === r.playerLastName) {
                if (l.playerFirstName < r.playerFirstName) {
                  return -1;
                }
                else if (l.playerFirstName === r.playerFirstName) {
                  return 0;
                }
                else {
                  return 1;
                }
              }
              else {
                return 1;
              }
            }));
            return;
          case "competitionName":
            sorters.value.unshift(new Sorter(id, (l,r) => {
              return l.competitionName < r.competitionName ? -1 : l.competitionName === r.competitionName ? 0 : 1;
            }))
            return;
          case "divisionName":
            sorters.value.unshift(new Sorter(id, (l,r) => {
              const targetL = l.divisionDisplayName_primary || l.divisionDisplayName_secondary;
              const targetR = r.divisionDisplayName_primary || r.divisionDisplayName_secondary;
              return targetL < targetR ? -1 : targetL === targetR ? 0 : 1;
            }))
            return;
          case "dateOfRegistration":
            sorters.value.unshift(new Sorter(id, sortByDayJS(_ => _.invoice_dateCreated)))
            return;
          default:
            exhaustiveCaseGuard(id)
        }
      }
      else {
        const existing = sorters.value[existingIdx];
        existing.flipDir();
        sorters.value.splice(existingIdx, 1);
        sorters.value.unshift(existing);
      }
    }

    // lifted into its own method so it has a name in perf traces.
    // takes ~5ms to sort 100 things and then ~300ms to redraw all of them
    const __doSort = () => {
      return [...filteredRows.value].sort((l,r) => {
        for (const sorter of sorters.value) {
          const compare = sorter.compare(l,r);
          if (compare === 0) {
            continue;
          }
          else {
            return compare;
          }
        }
        return 0;
      })
    }

    const filteredSortedRows = computed(__doSort)

    const maybeGetSorterByID = (id: SortableIDs) : Sorter<ExpandedCompReg, SortableIDs> | undefined => {
      return sorters.value.find(v => v.id === id);
    }
    const getSortArrowProps = (id: SortableIDs) : {engaged: false} | {engaged: true, dir: "asc" | "desc"} => {
      const sorter = maybeGetSorterByID(id);
      return sorter
        ? {engaged: true, dir: sorter.getDir()}
        : {engaged: false}
    }

    /**
     * when filtered rows change, reset to first page and clear selected items
     */
    watch(() => filteredRows.value, () => {
      zi_currentPage.value = 0;
      checkedActionTargets.value = {};
    });
    /**
     * when filteredSortedRows changes just reset page
     * (behavior for "just filtered rows changed" is in filteredRows watcher)
     * Instead of this, we might want to watch for changes to sort configuration ("oh desc changed to asc" or etc.)
     */
    watch(() => filteredSortedRows.value, () => {
      zi_currentPage.value = 0;
    });
    watch(() => itemsPerPage.value, () => {
      zi_currentPage.value = 0;
    })

    const pagedView = computed(() => {
      return paginationData(filteredSortedRows.value, zi_currentPage.value, itemsPerPage.value)
    });

    /**
     * any errors resulting from attempts to perform an actions against particular compRegs
     */
    const requestErrorsByInvoiceInstanceID = ref<{[invoiceInstanceID: iltypes.Integerlike]: string | JSX.Element}>({})

    const requestsInFlight = ref(0);
    const networkBusy = computed(() => requestsInFlight.value > 0);

    /**
     * empty string is "indeterminate" (nothing yet selected)
     * "ALL" isn't valid for seasons, but is OK for comp or div
     * If the values aren't "ALL" or "", then they should be GUIDs
     */
    const selectedSeasonUID = ref<iltypes.Guid | "">("");
    const selectedCompetitionUID = ref<iltypes.Guid | "" | "ALL">("")
    const selectedDivID = ref<iltypes.Guid | "" | "ALL">("")

    /**
     * Sometimes we need an array view, sometimes we need a map, this helps work over the differences.
     * Returns a function that does the maplike lookup, but is only valid until the next time the underlying compreg array
     * is mutated such that it changes length or elements move positions.
     */
    const compRegByInvoiceInstanceIdLookup = computed(() => {
      const result : {[instanceID: iltypes.Integerlike]: /*no unchecked index access*/ undefined | {idx: number, compReg: ExpandedCompReg}} = {};
      for (let i = 0; i < data.value.length; ++i) {
        const compReg = data.value[i];
        result[compReg.invoice_instanceID] = {idx: i, compReg}
      }
      return result;
    })

    function selectTargetCompRegs(expandedCompRegs: ExpandedCompReg[], compRegIDs: iltypes.Integerlike[]) : ExpandedCompReg[] {
      // probably premature optimization but intent is to avoid repeated linear scans,
      // and also unify "integerlike" to "definitely string"
      const ids = new Set(compRegIDs.map(id => id.toString().trim()));
      return expandedCompRegs.filter(compReg => ids.has(compReg.competitionRegistrationID.toString().trim()));
    }

    /**
     * Update local copies of our expanded compregs fresh from the backend.
     * This has the side effect of clearing errors for the target compRegs.
     */
    async function refreshTargetCompRegs(competitionRegistrationIDs: iltypes.Integerlike[]) : Promise<void> {
      competitionRegistrationIDs.forEach(compRegID => { delete requestErrorsByInvoiceInstanceID.value[compRegID]; });
      updateTargetCompRegs(
        await ilapi.getActiveCompRegsThatAreOrWereBlocked(axiosInstance, {competitionRegistrationIDs})
      );
    }

    function updateTargetCompRegs(freshes: ExpandedCompReg[]) : void {
      for (const fresh of freshes) {
        const v = compRegByInvoiceInstanceIdLookup.value[fresh.invoice_instanceID];
        if (v) {
          data.value[v.idx] = fresh;
        }
      }
    }

    /**
     * If a competitionRegistrationID maps to `true` here, it is selected and
     * is part of the desired bulk action on submit.
     *
     * See comment on `targetCompetitionRegistrationIDs` for why this is often not the thing to be reading from.
     */
    const checkedActionTargets = ref<{[competitionRegistrationID: iltypes.Integerlike]: undefined | boolean}>({});
    /**
     * A key can be present in `checkedActionTargets`, but map to `false | undefined`, which means it is not selected.
     * n.b. the above means that a present key (even if mapping to falsy) shows up in `Object.keys(x)`.
     * This is a filtered view of `checkedActionTargets` where we include only keys that map to true.
     */
    const targetCompetitionRegistrationIDs = computed(() => {
      const result : iltypes.Integerlike[] = [];
      for (const key of unsafe_objectKeys(checkedActionTargets.value)) {
        if (checkedActionTargets.value[key] === true) {
          result.push(key);
        }
      }
      return result;
    })

    //
    // We perform "bulk" operations here, but do so synchronously, 1 request at a time;
    // So the bulk operation happens on the client, primarily because the server behavior is fully synchronous;
    // we're synchronous here, too, but it seems like we could maybe get some parallelism out of Promise.all, though we'd quickly
    // eat up available client sockets (can we limit that if we do `Promise.all([100 things])`?) and probably max out server requests-per-second-per-client.
    //
    const handleSubmit = async () => {
      if (networkBusy.value) {
        return;
      }
      try {
        requestsInFlight.value += 1;

        // make copy for async paranoia
        const copyTargetCompetitionRegistrationIDs = [...targetCompetitionRegistrationIDs.value];
        const targetInvoiceInstanceIDs = selectTargetCompRegs(data.value, copyTargetCompetitionRegistrationIDs)
          .map(expandedCompReg => expandedCompReg.invoice_instanceID);

        switch (selectedAction.value) {
          case "IMMEDIATE_ATTEMPT_PAYMENT": {
            const results : (Awaited<ReturnType<typeof ilapi.bulkPayInvoicesHavingAttachedStripePaymentMethods>>) = {};
            for (const invoiceInstanceID of targetInvoiceInstanceIDs) {
              try {
                const thisResult = await ilapi.bulkPayInvoicesHavingAttachedStripePaymentMethods(axiosInstance, {invoiceInstanceID: invoiceInstanceID});
                Object.assign(results, thisResult);
              }
              catch (err) {
                AxiosErrorWrapper.rethrowIfNotAxiosError(err);
                results[invoiceInstanceID] = {ok: false, type: "inLeague", detail: "Sorry, something went wrong."}
              }
            }



            const toRefresh : iltypes.Integerlike[] = []

            for (const invoiceInstanceID of unsafe_objectKeys(results)) {
              const payload = results[invoiceInstanceID];
              if (payload.ok) {
                const competitionRegistrationID = compRegByInvoiceInstanceIdLookup.value[invoiceInstanceID]?.compReg.competitionRegistrationID;
                if (competitionRegistrationID) {
                  toRefresh.push(competitionRegistrationID);
                }
              }
              else {
                if (payload.type === "inLeague") {
                  // some specific error message we generated serverside
                  requestErrorsByInvoiceInstanceID.value[invoiceInstanceID] = payload.detail;
                }
                else if (payload.type === "stripe") {
                  requestErrorsByInvoiceInstanceID.value[invoiceInstanceID] = (
                    <div data-test="stripe-error">
                      <div>Stripe rejected this payment, with message:</div>
                      <pre class="whitespace-normal p-[.25em] bg-slate-200 border border-slate-400">{payload.detail.message}</pre>
                      <div>
                        <a class="text-blue-700 underline" target="_blank" href={payload.detail.request_log_url}>Stripe detail is available here.</a>
                      </div>
                      <div>Consider sending an invitation for the user to pay, or cancelling the registration.</div>
                    </div>
                  );
                }
                else {
                  exhaustiveCaseGuard(payload);
                }
              }
            }

            await refreshTargetCompRegs(toRefresh);
            break;
          }
          case "PROMPT_USER": {
            const succesfullyHandledCompRegIDs : iltypes.Integerlike[] = [];
            for (const invoiceInstanceID of targetInvoiceInstanceIDs) {
              try {
                const thisResult = await ilapi.bulkNotifyCustomersHavingAttachedStripePaymentMethods(axiosInstance, {invoiceInstanceID: invoiceInstanceID});
                if (thisResult[invoiceInstanceID].ok) {
                  const compRegID = compRegByInvoiceInstanceIdLookup.value[invoiceInstanceID]?.compReg.competitionRegistrationID;
                  if (compRegID) {
                    succesfullyHandledCompRegIDs.push(compRegID)
                  }
                }
              }
              catch (err) {
                AxiosErrorWrapper.rethrowIfNotAxiosError(err);
                requestErrorsByInvoiceInstanceID.value[invoiceInstanceID] = "Sorry, something went wrong.";
              }
            }

            const totalOK = succesfullyHandledCompRegIDs.length;
            const totalAttempted = targetInvoiceInstanceIDs.length;

            await refreshTargetCompRegs(succesfullyHandledCompRegIDs);

            if (totalOK === totalAttempted) {
              iziToast.success({message: `Successfully notified all selected recipients.`})
            }
            else if (totalOK > 0) {
              const totalFailed = totalAttempted - totalOK;
              const pluralOK = totalOK === 1 ? "recipient" : "recipients";
              iziToast.warning({message: `Notified ${totalOK} ${pluralOK}, but something went wrong with the other ${totalFailed}.`});
            }
            else {
              iziToast.error({message: `Sorry, something went wrong. No notifications have been issued.`});
            }

            break;
          }
          case "FORCE_CANCEL": {
            const succesfullyCanceledCompRegIDs : iltypes.Integerlike[] = [];
            const sendCancellationNotificationEmails = shouldSendCancellationNotification.value
              ? {doSend: true, userSuppliedText: cancellationNotificationText.value} as const
              : undefined;
            for (const competitionRegistrationID of copyTargetCompetitionRegistrationIDs) {
              try {
                const thisResult = await ilapi.bulkCancelCompetitionRegistrations(axiosInstance, {competitionRegistrationID: competitionRegistrationID, sendCancellationNotificationEmails});
                if (thisResult[competitionRegistrationID].ok) {
                  succesfullyCanceledCompRegIDs.push(competitionRegistrationID);
                }
              }
              catch (err) {
                AxiosErrorWrapper.rethrowIfNotAxiosError(err);
              }
            }

            {
              // remove, from data, the compregs which were succesfully cancelled
              const succesfullyCanceledCompRegIDs_comparableStrings = new Set<string>(succesfullyCanceledCompRegIDs.map(v => v.toString()));
              data.value = data.value.filter(v => !succesfullyCanceledCompRegIDs_comparableStrings.has(v.competitionRegistrationID.toString()));
            }

            {
              const canceled = succesfullyCanceledCompRegIDs.length;
              const canceledCompRegPlural = canceled === 1 ? "program registration" : "program registrations";
              const errors = copyTargetCompetitionRegistrationIDs.length - canceled;
              const errorsPlural = errors === 1 ? "error" : "errors";
              if (errors === 0) {
                iziToast.success({message: `Canceled ${succesfullyCanceledCompRegIDs.length} ${canceledCompRegPlural}`});
              }
              else {
                iziToast.error({message: `Canceled ${succesfullyCanceledCompRegIDs.length} ${canceledCompRegPlural}, with ${errors} ${errorsPlural}`});
              }
            }

            break;
          }
          default:
            exhaustiveCaseGuard(selectedAction.value);
        }

        checkedActionTargets.value = {};
      }
      finally {
        requestsInFlight.value -= 1;
      }
    }

    /**
     * using the currently selected seasonUID,
     * reinitialize the competition + division options and their associated select state
     *
     * Caller is responsible for guarding that "the currently selected seasonUID" is in a valid state
     */
    const reinitCompDivOptionsAndAssociatedSelectState_forSelectedSeasonCompDiv = async (args?: {competitionUID?: Guid | null, divID?: Guid | null}) => {
      const {pairs, competitionDetail, divisionDetail} = await ilapi.get_compDivOptionsBySeason_for_ActiveCompRegsThatAreOrWereBlocked(axiosInstance, selectedSeasonUID.value);

      const isRegistrar = authService(User.value.roles, "registrar");

      competitionOptions.value = (() => {
        const v : UiOption[] = competitionDetail.map(v => ({label: v.displayName, value: v.competitionUID}));

        v.sort((l,r) => l.label < r.label ? -1 : 1);

        if (isRegistrar) {
          v.unshift({label: "All", value: "ALL"});
        }
        v.unshift({label: "Select a competition", value: "", attrs:{disabled:true}});
        return v;
      })();

      divisionOptions.value = (() => {
        const result : {[competitionUID: iltypes.Guid]: UiOption[]} = {};

        for (const {competitionUID, divID} of pairs) {
          result[competitionUID] ??= [];
          result[competitionUID].push({
            // lists should be short, this is ok
            label: divisionDetail.find(v => v.divID === divID)!.displayName,
            value: divID
          })
        }

        for (const key of Object.keys(result)) {
          // sorting here is OK, everything else is placed into the front of the list
          result[key].sort((l,r) => l.label < r.label ? -1 : 1);

          if (isRegistrar) {
            result[key].unshift({label: "All", value: "ALL"});
          }
          result[key].unshift({label: "Select a division", value: "", attrs:{disabled:true}});
        }

        if (isRegistrar) {
          const uniqueDivOptionsByDivID = [
            ...(new Map(divisionDetail.map(v => [v.divID, {label: v.displayName, value: v.divID}])).values())
          ].sort((l,r) => l.label < r.label ? -1 : 1);

          result["ALL"] = [
            {label: "Select a division", value: "", attrs: {disabled: true}},
            {label: "All", value: "ALL"},
            ...uniqueDivOptionsByDivID
          ];
        }

        return result;
      })();

      selectedCompetitionUID.value = (args?.competitionUID && competitionOptions.value.find(opt => opt.value === args.competitionUID)) ? args?.competitionUID : "";
      selectedDivID.value = (args?.divID && divisionOptions.value[selectedCompetitionUID.value]?.find(opt => opt.value === args.divID)) ? args.divID : "";
    }

    const reinitCompDivOptionsAndAssociatedSelectState_forSingularRegistration = () => {
      if (!props.detail.query?.registrationID) {
        // no work to do; bug in caller?
        return;
      }

      withDisabledCompSeasonDivWatchers(() => {
        seasonOptions.value = asOfOnMounted_seasonOptions.value.copy();
        seasonOptions.value.unshift({
          label: "Focused on singular registration",
          value: "",
          key: "<<focused-on-single-reg>>",
          attrs: {
            disabled: true
          }
        })

        competitionOptions.value = [{label: "All", value: "ALL"}];
        divisionOptions.value = {"ALL": [{label: "All", value: "ALL"}]};

        selectedSeasonUID.value = "";
        selectedCompetitionUID.value = "ALL";
        selectedDivID.value = "ALL";

        // do we need to await vue next tick here?
      });
    }

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        await onMountedWorker()
      });
    })

    const onMountedWorker = async () => {
      asOfOnMounted_seasonOptions.value = copyOnly(
        await (async () => {
          const seasons = await ilapi.getSeasons(axiosInstance);
          const result : (UiOption & {key:string})[] = [];
          for (const season of seasons) {
            result.push({label: season.seasonName, value: season.seasonUID, key: season.seasonUID});
          }
          return result;
        })()
      );

      seasonOptions.value = asOfOnMounted_seasonOptions.value.copy();

      selectedSeasonUID.value = routeGetQueryParamAsStringOrNull(router.currentRoute.value.query.seasonUID)
        || (await getInitiallySelectedSeasonMenuOption(axiosInstance)).seasonUID
        || Client.value.instanceConfig.currentseasonuid;

      if (!seasonOptions.value.find(opt => opt.value === selectedSeasonUID.value)) {
        selectedSeasonUID.value = forceCheckedIndexedAccess(seasonOptions.value, 0)?.value ?? "";
      }

      if (props.detail.query?.registrationID) {
        reinitCompDivOptionsAndAssociatedSelectState_forSingularRegistration();
        data.value = await ilapi.getActiveCompRegsThatAreOrWereBlocked(axiosInstance, {registrationID: props.detail.query.registrationID})
        checkedActionTargets.value = {};
      }
      else if (selectedSeasonUID.value) {
        const competitionUID = routeGetQueryParamAsStringOrNull(router.currentRoute.value.query.competitionUID)
        const divID = routeGetQueryParamAsStringOrNull(router.currentRoute.value.query.divID)
        await reinitCompDivOptionsAndAssociatedSelectState_forSelectedSeasonCompDiv({competitionUID, divID});
        if (selectedSeasonUID.value && selectedCompetitionUID.value && selectedDivID.value) {
          data.value = await ilapi.getActiveCompRegsThatAreOrWereBlocked(axiosInstance, {seasonUID: selectedSeasonUID.value, competitionUID: selectedCompetitionUID.value, divID: selectedDivID.value})
          checkedActionTargets.value = {};
        }
      }

      //
      // register UI selection watchers
      //
      {
        watch(() => selectedSeasonUID.value, async () => {
          if (isProgrammaticallyConfiguringCompSeasonDivSelections.value) {
            return;
          }

          // having selected a season means we are no longer intending to focus on a particular registrationID
          tentativeRegistrationID.value = "";

          selectedCompetitionUID.value = "";
          selectedDivID.value = "";

          if (!selectedSeasonUID.value) {
            // shouldn't happen, we do not currently push a "nil" option into the options list
            return;
          }

          data.value = [];
          checkedActionTargets.value = {};
          await reinitCompDivOptionsAndAssociatedSelectState_forSelectedSeasonCompDiv();
        });

        watch(() => selectedCompetitionUID.value, async () => {
          if (isProgrammaticallyConfiguringCompSeasonDivSelections.value) {
            return;
          }

          selectedDivID.value = "";
        });

        watch(() => selectedDivID.value, async () => {
          if (isProgrammaticallyConfiguringCompSeasonDivSelections.value) {
            return;
          }

          if (!selectedCompetitionUID.value || !selectedDivID.value) {
            // shouldn't happen ... if there is a div option, it means there is a selected competition option
            return;
          }

          // we're here actively pulling data for some (comp,season,div)
          // we want to (possibly, if necessary) clear out seasonOptions that aren't valid any more,
          // for example the "Focused on singular registration" option
          seasonOptions.value = asOfOnMounted_seasonOptions.value.copy();

          data.value = await ilapi.getActiveCompRegsThatAreOrWereBlocked(axiosInstance, {seasonUID: selectedSeasonUID.value, competitionUID: selectedCompetitionUID.value, divID: selectedDivID.value})
          checkedActionTargets.value = {};
        });

        watch(() => props.detail.query?.registrationID, async () => {
          if (!props.detail.query?.registrationID) {
            // query param disappeared or turned into something invalid, nothing we can do
            return;
          }
          reinitCompDivOptionsAndAssociatedSelectState_forSingularRegistration();
          data.value = await ilapi.getActiveCompRegsThatAreOrWereBlocked(axiosInstance, {registrationID: props.detail.query.registrationID})
          checkedActionTargets.value = {};
        });

        watch(selectedAction, () => {
          // when selected action changes, we need to clear the checked action targets
          // (e.g. the action is different now, so the old selected targets don't make sense to preserve)
          checkedActionTargets.value = {};
          requestErrorsByInvoiceInstanceID.value = {};
        });
      }

      ready.value = true;
    }

    const downloadAsXLSX = async () : Promise<void> => {
      const buffer = await eventSignupsAsXlsxBuffer(
        data.value,
        checkedActionTargets.value,
        instanceID => {
          const path = router.resolve(MasterInvoice.routeDetailToRoutePath({name: "master-invoice", invoiceID: instanceID})).href;
          const url = `${window.location.origin}${path}`
          return url
        }
      );

      const selectedSeasonName = seasonOptions.value.find(v => v.value === selectedSeasonUID.value)?.label || undefined;
      const fileName = ["WaitlistPayments", selectedSeasonName].filter(v => !!v).join("-") + ".xlsx";

      downloadFromObjectURL(buffer, fileName);
    }

    const checkAll = () : void => {
      checkedActionTargets.value = {};
      for (const compReg of filteredRows.value) {
        if (selectabilityState(compReg, selectedAction.value).checkable) {
          checkedActionTargets.value[compReg.competitionRegistrationID] = true;
        }
      }
    }

    const uncheckAll = () : void => {
      checkedActionTargets.value = {};
    }

    return {
      retrievalFilterTypeOptions,

      seasonOptions,
      competitionOptions,
      divisionOptions,

      selectedSeasonUID,
      selectedCompetitionUID,
      selectedDivID,

      requestErrorsByInvoiceInstanceID,

      Action_t,
      selectedAction,
      shouldSendCancellationNotification,
      cancellationNotificationText,
      cancellationNotificationMaxLength,

      checkedActionTargets,
      targetCompetitionRegistrationIDs,

      tentativeRegistrationID,
      commitRegistrationIDChange,

      getSortArrowProps,
      pushAndOrToggleSorter,

      handleSubmit,
      downloadAsXLSX,
      checkAll,
      uncheckAll,

      zi_currentPage,
      pagedView,
      filter,
      itemsPerPage,
      itemsPerPageOptions,

      ready,
      networkBusy,

      dayjsFormatOr,
      invoiceLastStatusAsUiString,

      LastStatus_t,
    }
  },
  render() {
    function SortArrow(props: {engaged: true, dir: "asc" | "desc"} | {engaged: false}) {
      if (!props.engaged) {
        return <FontAwesomeIcon icon={["fas", "arrows-up-down"]} class="text-gray-400"/>
      }
      else {
        return <FontAwesomeIcon icon={["fas", "arrow-up"]} class={`${props.dir === "asc" ? '' : 'transform rotate-180'}`}/>
      }
    }

    if (!this.ready) {
      return <div></div>
    }
    return (
      <div data-test="CompRegsWithBlockedPaymentIntents">
        <div class="shadow-md">
          <div class="p-2 bg-green-800 flex items-center">
            <div class="text-lg text-white">Waitlist Manager - Registrations Pending Payment</div>
            <div class="ml-auto flex flex-wrap justify-end gap-1">
              <select v-model={this.filter.selectedRetrievalFilterType} data-test="select-filterType">
                {
                  this.retrievalFilterTypeOptions.map(option => {
                    return <option value={option.value}>{option.label}</option>
                  })
                }
              </select>
              <select v-model={this.selectedSeasonUID} data-test="select-season">
                {
                  this.seasonOptions.map(option => {
                    return <option key={option.key} value={option.value} {...option.attrs}>{option.label}</option>
                  })
                }
              </select>
            </div>
          </div>
          <div class="grid grid-cols-4 p-2">
            <>
              {/*header row*/}
              <div class="p-2">
                <div>
                  <FormKit type="form" onSubmit={() => { this.commitRegistrationIDChange(); }} actions={false}>
                    <div class="text-sm flex justify-between">
                      <span>RegistrationID:</span>
                      <FontAwesomeIcon icon={["fas", "circle-question"]} v-tooltip={{ content: `A specific registrationID may be provided in lieu of a search for some season/program/division.` }}/>
                    </div>
                    <div class="flex gap-2 items-start">
                      <FormKit
                        type="text"
                        v-model={this.tentativeRegistrationID}
                        outer-class="$reset"
                        validationRules={{isGuid: (v:any) => isGuid(v.value?.trim() || "")}}
                        validationMessages={{isGuid: "RegistrationID should match the pattern 00000000-0000-0000-0000-00000000"}} validation={[["isGuid"]]}
                      />
                      <t-btn type="submit" class="mt-1" margin={false}>
                        <FontAwesomeIcon icon={["fas", "magnifying-glass"]} />
                      </t-btn>
                    </div>
                  </FormKit>
                </div>
                <div>
                  <div class="text-sm">Program:</div>
                  <FormKit type="select" data-test="competition" v-model={this.selectedCompetitionUID} options={this.competitionOptions} outer-class="$reset"></FormKit>
                </div>
                <div>
                  <div class="text-sm">Division:</div>
                  {
                    this.selectedCompetitionUID
                      ? <FormKit key="hasComp" type="select" data-test="division" v-model={this.selectedDivID} options={this.divisionOptions[this.selectedCompetitionUID]} outer-class="$reset"></FormKit>
                      : <FormKit disabled key="noCompYet" type="select" data-test="division" v-model={this.selectedDivID} outer-class="$reset"></FormKit>

                  }
                </div>
              </div>
              <div class="p-2">
                <div class="text-sm">Player:</div>
                <FormKit type="text" v-model={this.filter.playerName} outer-class="$reset"></FormKit>
              </div>
              <div class="p-2 flex flex-col">
                <div class="flex items-center justify-between flex-wrap">
                  <span>Action</span>
                  <t-btn type="button" margin={false} class="text-sm" style="padding:.25em .75em" onClick={() => this.downloadAsXLSX()} data-test="download-as-excel">
                    <FontAwesomeIcon icon="fa-file-export" class="-ml-0.5 mr-2 h-4 w-4" />
                    <span>As excel</span>
                  </t-btn>
                </div>
                <div class="p-1 grow flex-flex-col">
                  <div class="flex items-center justify-start">
                    <input type="radio" name="action" id="actionInvite" v-model={this.selectedAction} value={this.Action_t.PROMPT_USER}/>
                    <label for="actionInvite" class="text-sm ml-2">Invite submitter to complete payment</label>
                  </div>
                  <div class="flex items-center justify-start">
                    <input type="radio" name="action" id="actionAutopay" v-model={this.selectedAction} value={this.Action_t.IMMEDIATE_ATTEMPT_PAYMENT}/>
                    <label for="actionAutopay" class="text-sm ml-2">Attempt automatic payment</label>
                  </div>
                  <div class="flex items-center justify-start">
                    <input type="radio" name="action" id="actionCancel" v-model={this.selectedAction} value={this.Action_t.FORCE_CANCEL}/>
                    <label for="actionCancel" class="text-sm ml-2">Cancel Registration without payment</label>
                  </div>
                </div>
                <div class="flex items-center justify-end gap-2 flex-wrap">
                  <t-btn type="button" margin={false} class="text-sm" style="padding:.25em .75em" onClick={() => this.checkAll()}>
                    <span>Check all</span>
                  </t-btn>
                  {
                    this.targetCompetitionRegistrationIDs.length > 0
                      ? (
                        <t-btn type="button" margin={false} class="text-sm" style="padding:.25em .75em" onClick={() => this.uncheckAll()}>
                          <span>Uncheck all</span>
                        </t-btn>
                      )
                      : null
                  }
                </div>
              </div>
              <div class="p-2">
                <div>Detail</div>
              </div>
            </>
          </div>
          {/* really considering just using <table> here; semantically it definitely is*/}
          <div class="grid grid-cols-6 p-2 gap-1" style="grid-template-columns: 1fr 1fr 1fr 1fr min-content 1fr">
            {
              this.selectedAction === this.Action_t.FORCE_CANCEL
                ? (
                  <div class="text-sm col-span-6 p-2 shadow-sm border border-gray-200 mb-2">
                    <div class="flex items-center">
                      <input type="checkbox" v-model={this.shouldSendCancellationNotification} data-test="sendCancellationNotification" />
                      <span class="ml-2">Send cancellation notification emails to registration submitters</span>
                    </div>
                    {
                      this.shouldSendCancellationNotification
                        ? (
                          <div>
                            <div class="text-sm font-medium">
                                <span>Text to include in cancellation email (can be left blank)</span>
                                <span class="ml-1 text-blue-700 cursor-pointer font-normal" onClick={() => {this.cancellationNotificationText = ""}}>(clear)</span>
                            </div>
                            <textarea v-model={this.cancellationNotificationText} maxlength={this.cancellationNotificationMaxLength} class="w-full" data-test="cancellationNotificationText"/>
                            <div class="text-xs flex justify-end">{this.cancellationNotificationText.length}/{this.cancellationNotificationMaxLength}</div>
                          </div>
                        )
                        : null
                    }
                  </div>
                ) : null
            }

            {
              this.pagedView.count_totalItems === 0
                ? <div class="mt-4 col-span-6 flex items-center justify-center">Nothing found</div>
                : (
                  <>
                    <div class="flex p-2">
                        <span class="cursor-pointer" onClick={() => this.pushAndOrToggleSorter("dateOfRegistration")}>
                          <SortArrow {...this.getSortArrowProps("dateOfRegistration")}/>
                        </span>
                        <span class="ml-2">Registration date</span>
                    </div>
                    <div class="flex p-2">
                        <span class="cursor-pointer" onClick={() => this.pushAndOrToggleSorter("competitionName")}>
                          <SortArrow {...this.getSortArrowProps("competitionName")}/>
                        </span>
                        <span class="ml-2">Program</span>
                    </div>
                    <div class="flex p-2">
                        <span class="cursor-pointer" onClick={() => this.pushAndOrToggleSorter("divisionName")}>
                          <SortArrow {...this.getSortArrowProps("divisionName")}/>
                        </span>
                        <span class="ml-2">Division</span>
                    </div>
                    <div class="flex p-2">
                        <span class="cursor-pointer" onClick={() => this.pushAndOrToggleSorter("playerName")}>
                          <SortArrow {...this.getSortArrowProps("playerName")}/>
                        </span>
                        <span class="ml-2">Player</span>
                    </div>
                    <div class="pl-2 pr-2">Check</div>
                    <div>Detail</div>
                  </>
                )
            }

            {
              this.pagedView.itemsThisPage.map((row, i) => {
                const checkState = selectabilityState(row, this.selectedAction);
                return (
                  <Fragment key={row.competitionRegistrationID}>
                    <div class={`p-2 ${i%2 ? "bg-gray-200" : ""}`}>{ dayjsFormatOr(row.invoice_dateCreated, "MMM/DD/YY h:mm a") }</div>
                    <div class={`p-2 ${i%2 ? "bg-gray-200" : ""}`}>{ row.competitionName }</div>
                    <div class={`p-2 ${i%2 ? "bg-gray-200" : ""}`}>{ row.divisionDisplayName_primary || row.divisionDisplayName_secondary }</div>
                    <div class={`p-2 ${i%2 ? "bg-gray-200" : ""}`}>
                        <div>
                          <RouterLink {...{target:"_blank"}} to={
                            PlayerEditor.routeDetailToRoutePath({
                              name: "player-editor",
                              playerID: row.childID,
                              registrationID: row.registrationID
                            })}
                            v-tooltip={{content: "Nav to player editor"}}
                            class="text-blue-700 underline"
                          >
                            { row.playerDisplayName }
                          </RouterLink>
                        </div>
                        <div class="text-xs">Submitter: { row.submitterFirstName } { row.submitterLastName }</div>
                    </div>
                    <div class={`p-2 ${i%2 ? "bg-gray-200" : ""} flex justify-center items-center`} data-test={`competitionRegistrationID=${row.competitionRegistrationID}/cell=action`}>
                      {
                        checkState.checkable
                          ? <input type="checkbox" v-model={this.checkedActionTargets[row.competitionRegistrationID]} key={`row-check/${row.competitionRegistrationID}`} />
                          : null
                      }
                    </div>

                    <div class={`p-2 divide-y divide-black ${i%2 ? 'bg-gray-200' : ''}`} data-test={`competitionRegistrationID=${row.competitionRegistrationID}/cell=detail`}>
                      {/*all this detail can probably come from checkability status?*/}
                      {
                        (() => {
                          const RejectedDetail = () => {
                            const maybeObj = jsonOrNull(row.invoice_lastStatusDetail)

                            // if the obj is an obj, and has a property named charge that is a string,
                            // then we assume that this is a json stripe response object, which was stored as a result of the rejection.
                            const isInferredAsStripeObjectBlob = typeof maybeObj?.request_log_url === "string"

                            return <div>
                              <div>Status: Last payment attempt rejected</div>
                              {
                                isInferredAsStripeObjectBlob
                                  ? <div><a target="_blank" href={maybeObj.request_log_url} class="text-blue-700 underline">Stripe detail</a></div>
                                  : <div class="text-red-700" style="word-break: break-words">{ row.invoice_lastStatusDetail }</div>
                              }
                            </div>
                          }

                          if (row.invoice_lastStatus) {
                            return row.invoice_lastStatus === LastStatus_t.PAYMENT_REJECTED
                              ? <RejectedDetail/>
                              : <div>Status: { invoiceLastStatusAsUiString(row.invoice_lastStatus) }</div>
                          }
                          else {
                            return null;
                          }
                        })()
                      }

                      {
                        row.invoice_datetimeOfLastUserNotification
                          ? (
                            <div class="text-sm p-1">
                              <div class="text-xs">Payment invitation sent on</div>
                              <div>{ dayjsFormatOr(row.invoice_datetimeOfLastUserNotification, "MMM DD YYYY @ h:mm a") }</div>
                            </div>
                          )
                          : null
                      }

                      {
                        row.invoice_lastStatus === LastStatus_t.IN_FLIGHT
                          ? (
                            <div class="text-sm p-1">
                              <div class="text-xs">Payment being processed as of</div>
                              <div>{ dayjsFormatOr(row.invoice_lastStatusDate, "MMM/DD/YYYY @ h:mm a") }</div>
                            </div>
                          )
                          : row.invoice_paid
                          ? (
                            <div class="text-sm p-1">
                              <div class="text-xs">Paid as of</div>
                              <div>{ dayjsFormatOr(row.invoice_closeDate, "MMM/DD/YYYY @ h:mm a") }</div>
                            </div>
                          )
                          : null
                      }

                      <div class="flex items-baseline p-1">
                        <span class="text-xs">Total</span>
                        <span class="pl-2">${ parseFloatOr(row.invoiceLineItem_finalAmount, null)?.toFixed(2) }</span>
                      </div>

                      {
                        // unactionability detail, if any
                        !checkState.checkable
                          ? checkState.detail
                          : <div style="display:none;" data-test="actionable"/> // element for testing purposes only
                      }

                      <div>
                        <RouterLink
                          class="text-blue-700 cursor-pointer underline text-sm"
                          to={MasterInvoice.routeDetailToRoutePath({name: "master-invoice", invoiceID: row.invoice_instanceID})}
                          {...{target: "_blank"}}
                        >
                          Invoice {row.invoice_instanceID}
                        </RouterLink>
                      </div>

                      {
                        (() => {
                          // error info, if any
                          if (this.requestErrorsByInvoiceInstanceID[row.invoice_instanceID]) {
                            return (
                              <div class="bg-white text-xs shadow-md rounded-lg">
                                <div class="bg-black text-white rounded-t-lg pt-1 relative h-5">
                                  <div class="absolute flex items-center pl-2 w-full h-full top-0 left-0">
                                    <span class="text-yellow-400">!!</span>
                                    <span class="text-xl ml-auto cursor-pointer mb-[2px] pr-[.25em]" onClick={() => { delete this.requestErrorsByInvoiceInstanceID[row.invoice_instanceID] }}>&times;</span>
                                  </div>
                                </div>
                                <div class="p-2">{ this.requestErrorsByInvoiceInstanceID[row.invoice_instanceID] }</div>
                              </div>
                            )
                          }
                          else {
                            // nothing
                          }
                        })()
                      }
                    </div>
                  </Fragment>
                )
              })
            }

            {/* footer */}
            <div class="col-span-6 flex mt-4 items-center">
              {/*submit button*/}
              <t-btn type="button"
                onClick={() => this.handleSubmit()}
                disable={(this.targetCompetitionRegistrationIDs.length === 0) || this.networkBusy}
                class={( (this.targetCompetitionRegistrationIDs.length === 0) || this.networkBusy) ? 'bg-gray-300' : ''}
                data-test="submit"
              >
                {
                  (() => {
                    if (this.selectedAction === this.Action_t.IMMEDIATE_ATTEMPT_PAYMENT) {
                      return <div class="text-xs">Attempt automatic completion for { this.targetCompetitionRegistrationIDs.length } payment{ this.targetCompetitionRegistrationIDs.length === 1 ? '' : 's' }</div>
                    }
                    else if (this.selectedAction === this.Action_t.PROMPT_USER) {
                      return <div class="text-xs">Send payment invitations for { this.targetCompetitionRegistrationIDs.length } payment{ this.targetCompetitionRegistrationIDs.length === 1 ? '' : 's' }</div>
                    }
                    else if (this.selectedAction === this.Action_t.FORCE_CANCEL) {
                      return <div class="text-xs">Cancel { this.targetCompetitionRegistrationIDs.length } program registration{ this.targetCompetitionRegistrationIDs.length === 1 ? '' : 's' }</div>
                    }
                    else {
                      return null;
                    }
                  })()
                }
              </t-btn>
              {/*pagination info*/}
              <div class="text-xs ml-auto self-end flex flex-col items-end">
                {
                  this.pagedView.count_totalPages > 0
                    ? (
                      <>
                        <div>Items { this.pagedView.zi_currentPageFirstIndex + 1 } &ndash; { this.pagedView.zi_currentPageLastIndex } of { this.pagedView.count_totalItems }</div>
                        <div class={`${this.pagedView.hasNext && this.pagedView.hasPrev ? "divide-x-2" : ""}`}>
                          <span class={`${this.pagedView.hasNext ? '' : 'invisible'} text-blue-700 cursor-pointer underline pr-2`} onClick={() => this.zi_currentPage += 1}>Next { this.pagedView.count_itemsNextPage }</span>
                          <span class={`${this.pagedView.hasPrev ? '' : 'invisible'} text-blue-700 cursor-pointer underline pl-2`} onClick={() => this.zi_currentPage -= 1}>Prev { this.pagedView.count_itemsPerPage }</span>
                        </div>
                      </>
                    )
                    : null
                }
                <div>
                  <span class="mr-2">Page size:</span>
                  <select v-model={this.itemsPerPage} style="all: revert; padding:.25em;">
                    {
                      this.itemsPerPageOptions.map(option => <option value={option.value}>{option.label}</option>)
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
})

export async function eventSignupsAsXlsxBuffer(
  compRegs: ExpandedCompReg[],
  checkedTargets: {[competitionRegistrationID: iltypes.Integerlike]: boolean | undefined},
  invoiceUrlMaker: (instanceID: iltypes.Integerlike) => string
) : Promise<ArrayBuffer> {
  // maybe parse a float to fixed precision, otherwise empty string
  const maybeToFixed = (v: "" | iltypes.Numeric) => isNaN(parseFloat(v as any)) ? "" : parseFloat(v as any).toFixed(2);

  const headerAndDataGetterTuples : [string, (_: ExpandedCompReg) => string][] = [
    ["Registration date", compReg => dayjsFormatOr(compReg.registrationDate, "MMM/DD/YY h:mm a")],
    ["Season", compReg => compReg.seasonName],
    ["Competition", compReg => compReg.competitionName],
    ["Division", compReg => compReg.divisionDisplayName_primary || compReg.divisionDisplayName_secondary],
    ["Player", compReg => compReg.playerDisplayName],
    ["Submitter", compReg => compReg.submitterFirstName + " " + compReg.submitterLastName],
    ["Line item amount", compReg => maybeToFixed(compReg.invoiceLineItem_amount)],
    ["Line item amount (final after discounts etc.)", compReg => maybeToFixed(compReg.invoiceLineItem_finalAmount)],
    ["Invoice ID", compReg => compReg.invoice_instanceID.toString()],
    ["Stripe paymentMethodID", compReg => compReg.invoice_stripe_paymentMethodID],
    ["Is fully paid", compReg => compReg.invoice_paid ? "yes" : "no"],
    ["Invoice paid (closed) on", compReg => compReg.invoice_closeDate],
    ["Invoice status", compReg => compReg.invoice_lastStatus],
    ["Invoice status date", compReg => compReg.invoice_lastStatusDate],
    ["Date of last email notification inviting user to pay", compReg => compReg.invoice_datetimeOfLastUserNotification],
    ["Checked", compReg => checkedTargets[compReg.competitionRegistrationID] ? "x" : ""],
    ["Invoice URL", compReg => invoiceUrlMaker(compReg.invoice_instanceID)]
  ]

  const headers = headerAndDataGetterTuples.map(v => v[0])
  const getters = headerAndDataGetterTuples.map(v => v[1]);

  const builder = XlsxUtils.builderWithKludgyAutoWidths(headers);

  for (const compReg of compRegs) {
    builder.pushRow(getters.map(getter => getter(compReg)));
  }

  return builder.build();
}

function jsonOrNull(v: any) : any {
  try {
    if (/^\s*\{/.test(v)) {
      return JSON.parse(v)
    }
  }
  catch {
    return null;
  }
}
